import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Helmet from 'react-helmet';
import callApi from '../../../../util/apiCaller.js';
import { getRolesFromFields, showNotification } from '../../../../util/utility.js';
import AdminHead from '../../components/AdminHead.js';
import InvestorEditView from './components/InvestorEditView.js';
import InvestorDetailView from './components/InvestorDetailView.js';
import AddNewBankModal from "../../../../components/AddNewBankModal";
import { API_STATUS, ROLES_CONST, TOAST_TYPES } from '../../../../constants/enums.js';
import { fetchAllRoles } from '../../../App/AppActions.js';
import { isEmpty } from 'lodash';
import { getRoleObjectMap } from '../../../App/AppReducer.js';
import { connect } from 'react-redux';

class InvestorViewPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            investorstatsDetails:{},
            investorpdftemplates: {},
            showEditPage: false,
            show: false,
            file: null,
            fileContents: '',
            selectedSegment: 'details',
            members: [],
            contracts: [],
            tradebook: [],
            finances: [],
            banklist: [],
            contractPageNum: 1,
            tradeBookPageNum: 1,
            financePageNum: 1,
            contractPageSize: 25,
            tradeBookPageSize: 25,
            financePageSize: 25,
            isAddBankModalVisible: false,
            orgRegistration: [],
            investors: [],
            counterSignAuthority: ""
        }
    }

    componentDidMount() {
        this.fetchInvestorStats();
        this.fetchInvestorPdfTemplates();
        this.fetchContentByCode("investor-types");
        this.props.dispatch(fetchAllRoles());
    }

    componentDidUpdate(prevProps, prevState){
        const roleObjectMap = this.props.roleObjectMap;
        if (!isEmpty(this.props.roleObjectMap) && prevProps.roleObjectMap !== roleObjectMap) {
            const id = this?.props?.match?.params?.id;
            this.fetchInvestorData(id);
        }
    }

    fetchContentByCode = (code) => {
        callApi(`/contents/code/${code}`, "get").then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    orgRegistration: res.data.content ? res.data.content.params: [],
                })
            }
        });
    };

    toggleSegment = (val) => {

        this.setState({
            selectedSegment: val
        }, () => {
            this.loadSegment(val);
        })
    };

    loadSegment = (val) => {
        if (val === 'users') {
            const id = this.props?.match?.params?.id;
            callApi(`/admin/investor-organization/get-user`, "post",{investor_organization_id:id}).then((res) => {
                if (res.status === 'Success') {
                    this.setState({
                        members: res.data.investors,
                    })
                }
            });
        } 
        // else if (val === 'trades') {
        //     const id = this.props.match.params.id;
        //     callApi(`contract/get-filtered-contract`, "post", {
        //         "pageNum": this.state.contractPageNum,
        //         "pageSize":this.state.contractPageSize,
        //         "sortBy": this.state.contractSortBy,
        //         "filters": {
        //             "isFiltering": "true",
        //             "investor_organization_id" : id,
        //             "is_contract_listed": false,
        //             "is_contract_traded": false
        //         }
        //     }).then((res) => {
        //         if (res.status === 'Success') {
        //             this.setState({
        //                 contracts: res.data.contracts
        //             });
        //         }
        //     });
        // } else if (val === 'investbook') {
        //     const id = this.props.match.params.id;
        //     callApi(`investor/investment-list`, "post", {
        //         filters: {},
        //         investor_organization_id:id
        //     }).then((res) => {
        //         if (res.status === 'Success') {
        //             this.setState({
        //                 tradebook: res.data.investmentData.traded_contracts
        //             });
        //         }
        //     });
        // } 
        // else if (val === 'finances') {

        //     const id = this.props.match.params.id;
        //     callApi(`investor/contract-transaction-list`, "post", {
        //         filters: {},
        //         investor_organization_id:id
        //     })
        //     .then((res) => {
        //         if (res.status === 'Success') {
        //             this.setState({
        //                 finances: res.data.contracts.transaction
        //             });
        //         }
        //     });
        // }
        else if (val === 'banks') {
            this.fetchBankList()
        }
    };

    fetchBankList = () => {
        const id = this.props.match.params.id;
        callApi(`admin/repayments/organization/${id}/bank-list`, "post", {
            organization_type: "investor"
        })
            .then((res) => {
                if (res.status === 'Success') {
                    this.setState({
                        banklist: res.data.banklist
                    });
                }
            });
    }

    fetchInvestorStats = () => {
        const id = this.props.match.params.id;
        callApi(`/admin/investor-organization-stats/`, "post",{investor_organization_id:id}).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                   investorstatsDetails:res.data.investor_organization_stats
                })
            } else {
                showNotification("error", "Some error occured");
            }
        });
    }

    fetchInvestorPdfTemplates = () => {
        callApi(`/admin/investor-pdf-templates/`, "post").then((res) => {
            if (res.status === 'Success') {
                this.setState({
                   investorpdftemplates:res.data.investor_pdf_templates
                })
            } else {
                showNotification("error", "Some error occured");
            }
        });
    }

    toggleEditView = (val) => this.setState({showEditPage: val})

    handleShow = () => this.setState({show: true});
    handleClose = () => this.setState({show: false});


    updateFields = (investorOrganization) => {
        if (investorOrganization?.repayment_date < 1 || investorOrganization?.repayment_date > 28) {
            showNotification(TOAST_TYPES.ERROR, "Repayment day must be between 1 and 28");
            return;
        }
        if (!investorOrganization.organization_registration)
            investorOrganization.organization_registration = "Individual";
        const id = this.props.match.params.id;
        let body = {
            ...investorOrganization,
            investor_organization_id: id,
            investor_address: investorOrganization.address,
        };
        callApi("/admin/investor-organization/stats-update", "post", body).then((res) => {
            if (res.status === API_STATUS.SUCCESS) {
                this.toggleEditView(false);
                showNotification(TOAST_TYPES.SUCCESS, "Successfully updated data");
                this.setState({ investor_organization: res.data.investeeorganization });
                this.fetchInvestorStats();
            } else {
                showNotification(TOAST_TYPES.ERROR, "Some error occured");
            }
        });
        this.toggleEditView(false);
    };

    createZeusUser = () =>{
        const id = this.props.match.params.id;
        callApi(`admin/create-zeus-user`, "post", {
         orgId:id,
         user_type:'investor'
        }).then((res) => {
          if(res.status === 'Success'){
            showNotification("Success", "User Created Successfully");
            const data=res?.data?.result;
            if(data?.orgId && data?.userId && data?.cerberusToken){
                const url = `${process.env.REACT_APP_CUSTOMERAPP_URI}/investor/dashboard?orgId=${data.orgId}&userId=${data.userId}&cerberusToken=${data.cerberusToken}&type=INVESTOR`;
                window.open(url, '_blank');
            }
          }
          else{
            showNotification("Error", res.data.message);
          }
        });
      }

    createEscrowAccount = (orgId) => {
        this.setState({
            isCreatingAccount: true
        })
        callApi(`repayments/investorOrganization/${orgId}/create-escrow-pay-account`, 'get')
            .then((res) => {
                this.setState({
                    isCreatingAccount: false
                });
                if (res.status === "Success") {
                    this.fetchInvestorStats();
                    showNotification("Success", "Successfully created document");
                } else {
                    showNotification("Error", res.data.message);
                }
            })
    }

    addBankAccount = (bank) => {
        this.setState({
            isCreatingAccount: true
        });
        const id = this.props.match.params.id;
        callApi(`repayments/organization/${id}/create-bank-account`, 'post', {
            "organization_type": "investor",
            "bank": bank
        })
            .then((res) => {
                this.setState({
                    isCreatingAccount: false,
                    isAddBankModalVisible: false,
                });
                if (res.status === "Success") {
                    showNotification("Success", "Successfully created bank");
                } else {
                    showNotification("Error", res.data.message);
                }
                this.fetchBankList();

            })
    }


    toggleAddBank = () => {
        this.setState({
            isAddBankModalVisible: !this.state.isAddBankModalVisible
        })
    };

    fetchInvestorData = async (investorOrganizationId) => {
        const rolesData = getRolesFromFields(this.props.roleObjectMap, ROLES_CONST['NAME_FILED'], ROLES_CONST['COUNTERSIGN_NAME']);    // Get roles data, where {name : Countersign Auth By Admin}
        const counterSignRoleId = rolesData[0]?._id;
        const filters = {};
        filters.query = {is_investor: true, is_deleted: false, investor_organization_id: investorOrganizationId, roles: {$all:  [counterSignRoleId]}};
        filters.selectFrom = {roles: 1, investor_organization_id: 1, contact_name: 1};
        filters.getAllRecords = true;
        const res = await callApi(
          `admin/investor-organization-id/get-all-investors`,
          "post",
          {filters}
        );
        if (res.status === API_STATUS.SUCCESS) {
          this.setState({
            investors: res.data,
            counterSignAuthority : this.getCountersignInvestor(res.data, this.props.roleObjectMap)?._id ?? ''
          })
        } else {
          this.setState({
            investors: []
          })
        }
       }

       updateCSAForInvestorOrganiation = async (investorOrganizationId, csaUserId) => {
        const body = {
            requestId: `${investorOrganizationId}${+new Date()}`,
            userId: isEmpty(csaUserId) ? null : csaUserId
        }
        const res = await callApi(
          `admin/investor-organization-id/${investorOrganizationId}/edit-countersign-role`,
          "post",
          body
        );
        if (res.status === API_STATUS.SUCCESS) {
          this.setState({
            counterSignAuthority : csaUserId
          })
        } else {
          showNotification("Error", "Couldn't update countersign authority");
        }
       }


    getCountersignInvestor = (investors, roleObjectMap) => {
        const counterSignIdData = getRolesFromFields(
            roleObjectMap,
            ROLES_CONST["NAME_FILED"],
            ROLES_CONST["COUNTERSIGN_BY_ADMIN_NAME"]
        );
        const counterSignInvestor = investors.filter((investor) => {
            if (investor && !isEmpty(investor.roles) && !isEmpty(counterSignIdData)) {
                const isCSARole = investor.roles.includes(counterSignIdData[0]._id.toString());
                if (isCSARole && !isEmpty(investor.contact_name)) {
                    return investor._id;
                }
            }
            return false;
        });
        return counterSignInvestor[0];
    }

    render() {
        let renderSegment;
        if (this.state.showEditPage) {
            renderSegment = <InvestorEditView
                toggleEditView={this.toggleEditView}
                company_status={this.state.company_status}
                investorstatsDetails = {this.state.investorstatsDetails}
                investorpdftemplates = {this.state.investorpdftemplates}
                show={this.state.show}
                orgRegistration={this.state.orgRegistration}
                handleShow={this.handleShow}
                handleClose={this.handleClose}
                updateFields={this.updateFields}
                uploadProfileImage={this.uploadProfileImage}
                removeProfileImage={this.removeProfileImage}
                uploadDocument={this.uploadDocument}
                handleDocUploadBtn={this.handleDocUploadBtn}
                removeDocument={this.removeDocument}
                updateCSAForInvestorOrganiation={this.updateCSAForInvestorOrganiation}
                counterSignAuthority={this.state.counterSignAuthority}
                investors={this.state.investors}
            />
        } else {
            renderSegment = <InvestorDetailView
                toggleEditView={this.toggleEditView}
                investee={this.state.investee}
                members={this.state.members}
                contracts={this.state.contracts}
                tradebook={this.state.tradebook}
                finances={this.state.finances}
                banklist={this.state.banklist}
                selectedSegment={this.state.selectedSegment}
                toggleSegment={this.toggleSegment}
                createEscrowAccount={this.createEscrowAccount}
                createZeusUser = {this.createZeusUser}
                isCreatingAccount={this.state.isCreatingAccount}
                investorOrganization={this.state.investor_organization}
                investorstatsDetails = {this.state.investorstatsDetails}
                toggleAddBank={this.toggleAddBank}
                user={this.state.user}
                investors={this.state.investors}
                counterSignAuthority={this.state.counterSignAuthority}
            />;
        }
        return (
            <section className="row main-body">
                <Helmet>
                    <title>{'Admin-Company-Detail | Recur Club'}</title>
                </Helmet>
                <AdminHead/>
                {renderSegment}
                {this.state.isAddBankModalVisible ?
                    <AddNewBankModal
                        isCreatingAccount={this.state.isCreatingAccount}
                        show={this.state.isAddBankModalVisible}
                        addBank={this.addBankAccount}
                        closeModal={() => this.toggleAddBank()}
                    />:
                    null
                }
            </section>
        );
    }
}

function mapStateToProps(state, props) {
    return {
      roleObjectMap : getRoleObjectMap(state)
    };
  }
  export default connect(mapStateToProps)(withRouter(InvestorViewPage));
  

