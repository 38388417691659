import { isEmpty } from 'lodash';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import callApi from '../../../../../../../../../util/apiCaller';
import { showNotification } from '../../../../../../../../../util/utility';
import styles from "../PgCollections/PgCollections.module.scss";
const { ExecuteCollection, PGCollectionTable, ConnectNewPGBtn, table_heading, Scheduled } = styles;

//@TODO make a resuable component out of this.

const nachStatusClassEnum = {
    Success: 'nach-status-Success',
    Failed: 'nach-status-Failed'
}

const nachTypeClassEnum = {
    'Paper': 'nach-type-Paper',
    ['E-NACH']: 'nach-type-E-NACH'
}

const statusClassEnum = {
    Scheduled: 'nach-status-Generated',
    Successful: 'nach-status-Success',
    failed: 'nach-status-Failed'
}

const ActiveNachRow = ({ item }) => {
    const {
        _id,
        bank_name,
        account_number,
        status,
        frequency,
        nach_type,
        creation_date,
        nach_amount,
        mandate_id
    } = item;
    return (<tr>
        <td>{bank_name}</td>
        <td>{account_number}</td>
        <td><span className={nachTypeClassEnum[nach_type]}>{nach_type}</span></td>
        <td>{_id}</td>
        <td>{mandate_id}</td>
        <td>{frequency}</td>
        <td>&#8377; {nach_amount}</td>
        <td><span className={nachStatusClassEnum[status]}>{status}</span></td>
        <td>{creation_date}</td>
    </tr>)
}

const NachCollectionRow = ({ item }) => {
    const {
        execution_date,
        mandate_id,
        nach_amount,
        frequency,
        availableAmount,
        payment_amount,
        execution_status
    } = item;
    return (<tr>
        <td>{execution_date}</td>
        <td>{mandate_id}</td>
        <td>&#8377; {nach_amount}</td>
        <td>{frequency}</td>
        <td>&#8377; {availableAmount}</td>
        <td>&#8377; {payment_amount}</td>
        <td><span className={statusClassEnum[execution_status]}>{execution_status}</span> </td>
    </tr>)
}
export default function NachCollectionsView({investeeOrgId}) {
    const [nachDetails, setNachDetails] = useState({});

    const fetchNachDetials = (cb) => {
        callApi(`investee-organization/${investeeOrgId}/nach-details`, "get").then((res) => {
            if (res.status === "Success") {
                setNachDetails(res.data);
            } else {
                const message = res?.error ?? "Some error occured";
                showNotification("Error", message);
            }
        });
    };

    useEffect(() => {
        fetchNachDetials();
    }, [])
    if (isEmpty(nachDetails)) return <div>No Data Found</div>;
    const { activeNaches, nachCollections } = nachDetails
    return <div>
        <div className={table_heading}>Active Naches</div>
        <div className={PGCollectionTable}>
            <table className="table">
                <thead>
                    <tr>
                        <th>Bank Name</th>
                        <th>Account No.</th>
                        <th>NACH Type</th>
                        <th>Customer ID</th>
                        <th>Mandate ID</th>
                        <th>Frequency</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Created At</th>
                    </tr>
                </thead>
                <tbody>
                    {!isEmpty(activeNaches) &&
                        activeNaches?.map((item, index) => <ActiveNachRow key={index} item={item} />)}
                </tbody>
            </table>
        </div>
        <div className={table_heading}>Nach Collections</div>
        <div className={PGCollectionTable}>
        <table className="table">
                <thead>
                    <tr>
                        <th>Execution Date</th>
                        <th>Mandate ID</th>
                        <th>Nach Amount</th>
                        <th>Nach Frequency</th>
                        <th>Amount Available</th>
                        <th>Execution Amount</th>
                        <th>Execution Status</th>
                    </tr>
                </thead>
                <tbody>
                    {!isEmpty(nachCollections) &&
                        nachCollections?.map((item, index) => <NachCollectionRow key={index} item={item} />)}
                </tbody>
            </table>
        </div>
    </div>
}