import React, { useEffect, useState, useCallback, useRef } from "react";
import { debounce, isEmpty } from "lodash";
import moment from "moment";
import RecurTable from "../../../../../components/RecurTable/RecurTable";
import AdminHead from "../../../components/AdminHead";
import PageLoader from "../../../../../components/loader/PageLoader";
import { Tabs } from "../tabs";
import ToggleButton from "../../../../../components/ToggleButton/ToggleButton";
import DatePicker from "react-datepicker";
import { getRecoveryViewDetails } from "../../../../../common/ApiHelpers";
import RecoveryTableRow from "./component/RecoveryTableRow";
import { downloadFileUsingFetch, mixPanelTrack, showNotification } from "../../../../../util/utility";
import styles from "./Recovery.module.scss";
import "react-datepicker/dist/react-datepicker.min.css";
import "../../../../../css/ReactCustomLibrary.scss";
import clearIcon from "../../../../../../src/assets/clear.svg";
import ReactAutoComplete from "react-autocomplete";
import LoadingSpinner from "../../../../../components/LoadingSpinner/LoadingSpinner";
import { useSelector } from "react-redux";
import { getEmail, getUserId, getUserName } from "../../../../App/AppReducer";

export const Recovery = ({ tab, setTab }) => {

  const userEmail = useSelector((state) => getEmail(state));
  const userName = useSelector((state) => getUserName(state));
  const userId = useSelector((state) => getUserId(state));

  const [state, setState] = useState({
    metricsDate: new Date(),
    totalAndRemain: { totalPaid: 0, totalRemaining: 0, graphValue: 0 },
    data: [],
    agents: [],
    agentsMap: [],
    investorOrgIdNameEntries: [],
    dpdBuckets: [],
    loader: false,
    lenderText: "All Investors",
    dpdText: "DPD Bucket",
    showRemainingAmtTxns: true,
    filterObj: {
      sortFilter: { sortBy: "remainingAmt", sortOrder: "desc" },
      showRemaining: true,
      companyName: "",
      dpdBuckets: [],
      lenderId: null,
      ownerIds: [],
    },
    loading: false,
    docLoader: false,
    collectedCompanies: 0,
    remainingCompanies: 0,
    type: "",
    selectedDpdBuckets: [],
    selectedOwners: [],
  });
  const [dpdInput, setDpdInput] = useState("");
  const [ownerInput, setOwnerInput] = useState("");
  const prevFilterObjRef = useRef(state.filterObj);
  const initialFilterObjRef = useRef(state.filterObj);

  useEffect(() => {
    const trackFilterChange = (prevFilterObj, newFilterObj) => {
      const changedKeys = Object.keys(newFilterObj).filter(
        (key) => prevFilterObj[key] !== newFilterObj[key]
      );

      changedKeys.forEach((key) => {
        mixPanelTrack({
          id: userId,
          trackUserObj: {
            "Filter Type": key,
          },
          trackCategoryName: "Filter Applied",
        });
      });
    };

    if (JSON.stringify(initialFilterObjRef.current) === JSON.stringify(state.filterObj)) {
      return;
    } else {
      trackFilterChange(prevFilterObjRef.current, state.filterObj);
      prevFilterObjRef.current = state.filterObj;
    }
  }, [state.filterObj]);

  const intitalMenuStyle = {
    borderRadius: "3px",
    boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
    background: "rgba(255, 255, 255, 0.9)",
    padding: "2px 5px",
    fontSize: "12px",
    fontWeight: "normal",
    position: "absolute",
    overflow: "auto",
    maxHeight: "7rem",
    zIndex: 2,
    fontFamily: "Gilroy-SemiBold",
    cursor: "pointer",
    top: "100%",
    left: 0,
    width: "100%"
  };
  

  const updateState = (newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  const toggleRemainingFilter = () => {
    updateState({
      showRemainingAmtTxns: !state.showRemainingAmtTxns,
      filterObj: {
        ...state.filterObj,
        showRemaining: !state.showRemainingAmtTxns,
      },
    });
  };

  const changeQuery = (keyName, keyValue) => {
    updateState({
      filterObj: { ...state.filterObj, [keyName]: keyValue },
      type: keyValue,
    });
  };

  const investorOrgNameFilter = (e) => {
    const value = e.target.value;
    const selectedOptionText = e.target.options[e.target.selectedIndex].text;
    updateState({
      filterObj: {
        ...state.filterObj,
        lenderId: value === "All Investors" ? null : value,
      },
      lenderText: selectedOptionText,
    });
  };

  const requestSort = (key) => {
    const { sortFilter } = state.filterObj;
    const newSortOrder =
      sortFilter.sortBy === key && sortFilter.sortOrder === "desc"
        ? "asc"
        : "desc";
    updateState({
      filterObj: {
        ...state.filterObj,
        sortFilter: { sortBy: key, sortOrder: newSortOrder },
      },
    });
  };

  const dpdBucketFilter = (selectedBucket) => {
    const updatedBuckets =
      selectedBucket === "DPD Bucket"
        ? []
        : state.selectedDpdBuckets.includes(selectedBucket)
        ? state.selectedDpdBuckets.filter((bucket) => bucket !== selectedBucket)
        : [...state.selectedDpdBuckets, selectedBucket];

    updateState({
      selectedDpdBuckets: updatedBuckets,
      filterObj: { ...state.filterObj, dpdBuckets: updatedBuckets },
    });
  };

  const clearFilters = (e, clearAll = false, key, value) => {
    e.preventDefault();
    let updatedFilterObj = { ...state.filterObj };
    let updatedState = {};

    if (clearAll) {
      updatedFilterObj = {
        sortFilter: state.filterObj.sortFilter,
        showRemaining: state.filterObj.showRemaining,
        companyName: "",
        dpdBuckets: [],
        lenderId: null,
        ownerIds: [],
      };
      updatedState = {
        lenderText: "All Investors",
        selectedDpdBuckets: [],
        selectedOwners: [],
        type: "",
      };
    } else {
      const clearMapping = {
        companyName: () => {
          updatedFilterObj.companyName = "";
          updatedState.type = "";
        },
        lenderId: () => {
          updatedFilterObj.lenderId = null;
          updatedState.lenderText = "All Investors";
        },
        dpdBuckets: () => {
          updatedFilterObj.dpdBuckets = updatedFilterObj.dpdBuckets.filter(
            (bucket) => bucket !== value
          );
          updatedState.selectedDpdBuckets = updatedFilterObj.dpdBuckets;
        },
        ownerIds: () => {
          const agentToRemove = state.agents.find(
            (agent) => agent.agentName === value
          );
          if (agentToRemove) {
            updatedFilterObj.ownerIds = updatedFilterObj.ownerIds.filter(
              (ownerId) => ownerId !== agentToRemove.id
            );
            updatedState.selectedOwners = updatedFilterObj.ownerIds;
          }
        },
      };

      if (clearMapping[key]) clearMapping[key]();
    }

    updateState({ ...updatedState, filterObj: updatedFilterObj });
  };

  const filterObjRep = () => {
    const { filterObj, lenderText } = state;
    return (
      filterObj.companyName ||
      (filterObj.lenderId && lenderText !== "All Investors") ||
      filterObj.dpdBuckets.length > 0 ||
      filterObj.ownerIds.length > 0
    );
  };

  const filterObjEntries = () => {
    const { filterObj, lenderText, agents } = state;
    const entries = [];
    if (filterObj.companyName)
      entries.push({ key: "companyName", value: filterObj.companyName });
    if (filterObj.lenderId && lenderText !== "All Investors")
      entries.push({ key: "lenderId", value: lenderText });
    if (filterObj.dpdBuckets.length > 0)
      entries.push({ key: "dpdBuckets", value: filterObj.dpdBuckets });
    if (filterObj.ownerIds.length > 0) {
      const ownerNames = filterObj.ownerIds.map((ownerId) => {
        const agent = agents.find((agent) => agent.id === ownerId);
        return agent ? agent.agentName : ownerId;
      });
      entries.push({ key: "ownerIds", value: ownerNames });
    }
    return entries;
  };

  const ownerFilter = (selectedOwner) => {
    const selectedAgent = state.agents.find(
      (agent) => agent.agentName === selectedOwner
    );
    if (selectedAgent) {
      const updatedOwners = state.selectedOwners.includes(selectedAgent.id)
        ? state.selectedOwners.filter((id) => id !== selectedAgent.id)
        : [...state.selectedOwners, selectedAgent.id];

      updateState({
        selectedOwners: updatedOwners,
        filterObj: { ...state.filterObj, ownerIds: updatedOwners },
      });
    }
  };

  const renderRows = () => {
    const { data, agentsMap, agents, metricsDate } = state;
    return (
      <tbody>
        {!isEmpty(data) ? (
          data.map((item, index) => (
            <RecoveryTableRow
              key={index}
              item={item}
              setLoader={(loader) => updateState({ loader })}
              agentsMap={agentsMap}
              agents={agents}
              fetchRecoveryDataWithoutLoader={fetchRecoveryDataWithoutLoader}
              index={index}
              setLoading={(loading) => updateState({ loading })}
              metricsDate={metricsDate}
            />
          ))
        ) : (
          <tr>
            <td className={styles.nodata} colSpan={12}>
              No Data
            </td>
          </tr>
        )}
      </tbody>
    );
  };

  const getHeaders = () => [
    {
      jsx: (
        <span>
          <input
            type="text"
            placeholder="Company"
            value={state.filterObj.companyName}
            onChange={(e) => changeQuery("companyName", e.target.value)}
            style={{ border: "none" }}
          />
        </span>
      ),
    },
    { jsx: <span>Collected / Total</span> },
    {
      jsx: (
        <span className={styles.header_input}>
          Due Amount{" "}
          <button
            onClick={() => requestSort("remainingAmt")}
            style={{ border: "none", marginLeft: "7px" }}
          >
            &#8645;
          </button>
        </span>
      ),
    },
    {
      jsx: (
        <span className={styles.header_input}>
          <ReactAutoComplete
            inputProps={{
              type: "text",
              name: "dpdBucket",
              placeholder: "DPD Bucket",
            }}
            items={state.dpdBuckets.map((bucket) => ({
              label: bucket,
              id: bucket,
            }))}
            value={dpdInput}
            getItemValue={(item) => item.label}
            shouldItemRender={(item, value) =>
              item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
            }
            onChange={(e) => {
              setDpdInput(e.target.value);
            }}
            onSelect={(value) => {
              dpdBucketFilter(value);
            }}
            renderItem={(item, isHighlighted) => (
              <div
                style={{
                  background: isHighlighted ? "#2F8FFF" : "white",
                }}
              >
                {item.label}
              </div>
            )}
            menuStyle={{ ...intitalMenuStyle }}
          />
        </span>
      ),
    },
    {
      jsx: (
        <span className={styles.header_input}>
          Portfolio Risk{" "}
          <button
            onClick={() => requestSort("totalRiskAmt")}
            style={{ border: "none", marginLeft: "7px" }}
          >
            &#8645;
          </button>
        </span>
      ),
    },
    {
      jsx: (
        <span className={styles.header_input}>
          PTP Date{" "}
          <button
            onClick={() => requestSort("ptpDate")}
            style={{ border: "none", marginLeft: "7px" }}
          >
            &#8645;
          </button>
        </span>
      ),
    },
    {
      jsx: (
        <span className={styles.header_input}>
          <ReactAutoComplete
            inputProps={{
              type: "text",
              name: "owner",
              placeholder: "Owner",
            }}
            items={state.agents.map((i) => ({
              label: i?.agentName,
              id: i?.agentName,
            }))}
            value={ownerInput}
            getItemValue={(item) => item.label}
            shouldItemRender={(item, value) =>
              item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
            }
            onChange={(e) => {
              setOwnerInput(e.target.value);
            }}
            onSelect={(value) => {
              ownerFilter(value);
            }}
            renderItem={(item, isHighlighted) => (
              <div
                style={{
                  background: isHighlighted ? "#2F8FFF" : "white",
                }}
              >
                {item.label}
              </div>
            )}
            menuStyle={{ ...intitalMenuStyle }}
          />
        </span>
      ),
    },
    { jsx: <span>Last Action</span> },
    { jsx: <span>Notes</span> },
  ];

  const fetchRecoveryData = async () => {
    updateState({ loader: true });
    try {
      const data = {
        yyyyMM: moment(state.metricsDate).format("YYYY-MM"),
        filters: state.filterObj,
      };
      const res = await getRecoveryViewDetails(data);
      if (res) {
        const totalPaid = res.totalPaid ?? 0;
        const totalRemaining = res.totalRemaining ?? 0;
        const agentsMap = res.agents.map((item) => ({
          label: item.agentName,
          value: item.agentName,
        }));

        let investorOrgIdNameEntries = Object.entries(res?.investorOrgIdNameMap);
        investorOrgIdNameEntries.sort((a, b) => a[1].localeCompare(b[1]));
        investorOrgIdNameEntries.unshift(["All Investors", "All Investors"]);

        const dpdBuckets = ["DPD Bucket", ...(res?.dpdbuckets ?? [])];

        updateState({
          loader: false,
          data: res.recoveryViewItems,
          collectedCompanies: res.collectedCompanies,
          remainingCompanies: res.remainingCompanies,
          totalAndRemain: {
            totalPaid,
            totalRemaining,
            graphValue: (totalPaid / totalRemaining) * 100,
          },
          agents: res.agents,
          agentsMap,
          investorOrgIdNameEntries,
          dpdBuckets,
          lenderText:
            res?.investorOrgIdNameMap[state.filterObj?.lenderId] ??
            "All Investors",
          dpdText: state.filterObj?.dpdBucket ?? "DPD Bucket",
        });
      } else {
        throw new Error(res?.error ?? "Failed to fetch Recovery Data");
      }
    } catch (error) {
      updateState({ loader: false });
      showNotification("Error", error.message);
    }
  };

  const downloadRecoveryDataDoc = async () => {
    mixPanelTrack({
      id: userId,
      trackUserObj: {
        user_name: userName,
        user_email: userEmail
      },
      trackCategoryName: "CSV Export",
    });
    updateState({ docLoader: true });
    const data = {
      yyyyMM: moment(state.metricsDate).format("YYYY-MM"),
      filters: state.filterObj,
    };
    const timestamp = moment().format("YYYY-MM-DD_HH_mm");
    const fileName = `Recovery_Data_${timestamp}.csv`;

    downloadFileUsingFetch(
      "befe/recovery/export-data",
      "post",
      { responseType: "blob" },
      fileName,
      () => {
      updateState({ docLoader: false });
      },
      false,
      data,
      true
    );
  };


  const fetchRecoveryDataWithoutLoader = useCallback(async () => {
    const data = {
      yyyyMM: moment(state.metricsDate).format("YYYY-MM"),
      filters: state.filterObj,
    };
    const res = await getRecoveryViewDetails(data);
    if (res) {
      updateState({
        data: res.recoveryViewItems,
        totalAndRemain: {
          totalPaid: res.totalPaid,
          totalRemaining: res.totalRemaining,
          graphValue: (res.totalPaid / res.totalRemaining) * 100,
        },
        agents: res.agents,
        agentsMap: res.agents.map((item) => ({
          label: item.agentName,
          value: item.agentName,
        })),
      });
    } else {
      showNotification("Error", res?.error ?? "Failed to fetch Recovery Data");
    }
  }, [state.metricsDate, state.filterObj]);

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      fetchRecoveryData();
    }, 900);
    debouncedSearch();
    return () => debouncedSearch.cancel();
  }, [state.metricsDate, state.filterObj]);

  return (
    <div className="main-body">
      <AdminHead />
      {state.loader ? (
        <PageLoader />
      ) : (
        <section className="w-100">
          <div
            className="d-flex justify-content-between align-items-center has-search p-3"
            style={{ height: "100px" }}
          >
            <div className="fs21 fw600">Collections</div>
            <div className={styles.CollectionsHeaderRight}>
              <select
                className={styles.investorOrgName}
                value={state.lenderText}
                onChange={investorOrgNameFilter}
              >
                {state.investorOrgIdNameEntries.map((item, index) => (
                  <option key={index} value={item[0]}>
                    {item[1]}
                  </option>
                ))}
              </select>
              <div className="custom-DatePicker">
                <DatePicker
                  selected={new Date(state.metricsDate)}
                  onChange={(date) => updateState({ metricsDate: date })}
                  minDate={new Date("2024-06-01")}
                  maxDate={new Date()}
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  showFullMonthYearPicker
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
              <div className={styles.HeaderSubPart}>
                <div>Complete</div>
                <div>
                  <span className={styles.Green}>
                    {state.collectedCompanies}
                  </span>
                  <span className={styles.Grey}>
                    /{state.collectedCompanies + state.remainingCompanies}
                  </span>
                </div>
              </div>
              <div className={styles.progressTop}>
                <div className={styles.progressValues}>
                  <p className={styles.greenTextValues}>
                    ₹ {state.totalAndRemain.totalPaid?.toLocaleString("en-IN")}
                  </p>
                  <p className={styles.redTextValues}>
                    ₹{" "}
                    {state.totalAndRemain.totalRemaining?.toLocaleString(
                      "en-IN"
                    )}{" "}
                    Remaining
                  </p>
                </div>
                <div className={styles.progressBar}>
                  <div
                    className={styles.filledBar}
                    style={{ width: `${state.totalAndRemain?.graphValue}%` }}
                  ></div>
                </div>
              </div>
              <div
                className={`${styles.RemainingOnlyFilter} DocumentVaultSwitch`}
              >
                <span>Show Relevant</span>
                <ToggleButton
                  checked={state.showRemainingAmtTxns}
                  onChange={toggleRemainingFilter}
                />
              </div>
            </div>
          </div>
          <div className="page-head-bottom-border"></div>
          <Tabs tab={tab} setTab={setTab} />
            <div className={styles.upper_row_for_filter}>
              <div className={styles.filter_arr_wrapper}>
                {filterObjEntries().map((o, index) =>
                  Array.isArray(o.value) ? (
                    o.value.map((val, subIndex) => (
                      <span
                        key={`${index}-${subIndex}`}
                        onClick={(e) => clearFilters(e, false, o.key, val)}
                      >
                        {val} <img src={clearIcon} alt="clear icon" />
                      </span>
                    ))
                  ) : (
                    <span
                      key={index}
                      onClick={(e) => clearFilters(e, false, o.key, o.value)}
                    >
                      {o.value} <img src={clearIcon} alt="clear icon" />
                    </span>
                  )
                )}
                {filterObjRep() && (
                  <span
                    className={styles.clear_all}
                    onClick={(e) => clearFilters(e, true)}
                  >
                    Clear All Filter
                  </span>
                )}
              </div>
              <div onClick={() => {downloadRecoveryDataDoc()}} className={styles.import_csv_button}>
                {state.docLoader ? (
                  <LoadingSpinner 
                    className={styles.del}
                    width="20px"
                    height="18px"
                    style={{ alignSelf: 'center'}}
                  />
                ) : (
                  <>
                    <span>
                      <img
                        className=""
                        src="/assets/white-download-icon.svg"
                        alt="Download"
                        style={{ width: "0.75rem", height: "0.75rem" }}
                      />
                    </span>
                    <div className={styles.exportText}>Export</div>
                  </>
                )}
              </div>
            </div>
            <RecurTable
              headers={getHeaders()}
              renderRows={renderRows()}
              loading={state.loading}
            />
        </section>
      )}
    </div>
  );
};

export default Recovery;
