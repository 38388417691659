//import fetch from 'isomorphic-fetch';
import { CERBERUS_TOKEN, ORG_ID, USER_ID } from "../constants/enums";
import storage from "./storageService";
import { removeFirstSlash } from "./utility";

export const API_URL = process.env.REACT_APP_API_URL;
export const PG_COLLECTIONS_BASE_URL =
  process.env.REACT_APP_PG_COLLECTION_API_URL;
  // export const API_URL = "http://localhost:8070";
// export const API_URL = "https://.recur.club"; // for testing QA
export const UW_API_URL =process.env.REACT_APP_UW_API_URL || "https://uwdev.recur.club";
// export const UW_API_URL  = "http://localhost:8000";
export const CERBERUS_BASE_URL = process.env.REACT_APP_CERBERUS_API_URL;
export const MNEMOSYNE_BASE_URL = process.env.REACT_APP_MNEMOSYNE_API_URL;
export const OLYMPUS_BASE_URL = `${process.env.REACT_APP_OLYMPUS_API_URL}/rest`;
// export const OLYMPUS_BASE_URL = `http://localhost:8083/olympus/rest`;
export const NOTIFICATION_BASE_URL = `${process.env.REACT_APP_NOTIFICATION_API_URL}`;
// export const NOTIFICATION_BASE_URL = `http://localhost:8081/notification`;
// export const MNEMOSYNE_BASE_URL = "http://localhost:8080/mnemosyne";
// export const CERBERUS_BASE_URL = 'https://apidev4.recur.club';  //For testing in the dev enviornement will delete it after testing.
export default function callApi(
  endpoint,
  method = "get",
  body,
  argHeaders = {},
  isLambda = false,
  underwritingData = false,
  pgCollectionsApi = false,
  cerberus = false,
  webUtilsApi = false,
  mnemosyne = false,
  olympus = false,
  notification = false,
) {
  let userId = storage.get(USER_ID)
  const currentUrl = window.location.href;
  let orgId = (mnemosyne && currentUrl.split('/company/')[1])
    ? currentUrl.split('/company/')[1].replace("/view", '')
    : storage.get(ORG_ID);
  let cerberusToken = storage.get(CERBERUS_TOKEN);
  let headers = { ...argHeaders };
  headers["content-type"] = "application/json";
  if (cerberusToken && cerberusToken !== '') {
    if (userId && userId !== '') {
      headers['x-user-id'] = userId;
    }
    if (mnemosyne && orgId && orgId !== '') headers['organization-id'] = orgId;
    headers['x-organization-id'] = "ADMIN";
    headers['x-cerberus-token'] = cerberusToken;
}

  let overallEndpoint;
  if (isLambda) overallEndpoint = endpoint;
  else if (underwritingData)
    overallEndpoint = `${UW_API_URL}/underwriting/${removeFirstSlash(
      endpoint
    )}`;
  else if (pgCollectionsApi) {
    overallEndpoint = `${PG_COLLECTIONS_BASE_URL}/pg/${removeFirstSlash(
      endpoint
    )}`;
  } 
  else if (webUtilsApi) {
    overallEndpoint = `${PG_COLLECTIONS_BASE_URL}/${removeFirstSlash(
      endpoint
    )}`;
  }  
  else if (cerberus){
    overallEndpoint= `${CERBERUS_BASE_URL}/cerberus/rest/${removeFirstSlash(endpoint)}`;
  }
  else if (mnemosyne) overallEndpoint = `${MNEMOSYNE_BASE_URL}/${removeFirstSlash(endpoint)}`;
  else if (olympus) overallEndpoint = `${OLYMPUS_BASE_URL}/${removeFirstSlash(endpoint)}`;
  else if (notification) overallEndpoint = `${NOTIFICATION_BASE_URL}/${removeFirstSlash(endpoint)}`;
  else overallEndpoint = `${API_URL}/api/${removeFirstSlash(endpoint)}`;

  const obj = {
    headers: headers,
    method,
    body,
  };
  if (webUtilsApi) delete obj.headers;
  else {
    if (method === "get") delete obj.body;
    else obj.body = JSON.stringify(body);
  }
  return fetch(overallEndpoint, obj)
    .then((response) => response.json().then((json) => ({ json, response })))
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
    .then(
      (response) => response,
      (error) => error
    );
}
