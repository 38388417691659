import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import RecurTabs from '../../../../../../../../components/RecurTabs/RecurTabs';
import PgCollections from './PgCollections/PgCollections';
import styles from '../Collections/Collections.module.scss';
import useCallApi from '../../../../../../../../util/useApiCaller';
import { useSelector } from 'react-redux';
import { getInvesteesOrganization } from '../../../../../../AdminReducers';
import { getValueOr_, getValueWithRsOr_, showNotification } from '../../../../../../../../util/utility';
import NachCollectionsView from './NachCollections/NachCollectionsView';

const {
    Scheduled
} = styles;

const StyledWrapper = styled.div`
    padding: 30px 40px;
`

const NACH_COLLECTIONS = "NACH Collections";
const PG_COLLECTIONS = "PG Collections";

export default function Collections() {
    const tabs = [NACH_COLLECTIONS, PG_COLLECTIONS];
    const investeeOrgId = useSelector(getInvesteesOrganization)?._id;
    const [currentTab, setCurrentTab] = useState(PG_COLLECTIONS);
    const [{ error: errorAtFetchingCollectionSummary, data: collectionsSummaryResponse }, fetchCollectionsSummaryApiCall] = useCallApi()

    useEffect(() => {
        fetchCollectionsSummaryApiCall("collection/summary", "post", {
            "companyId": investeeOrgId,
            "requestId": `${investeeOrgId}${+ new Date()}`
        }, {}, false, false, true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (errorAtFetchingCollectionSummary) {
            const message =
                errorAtFetchingCollectionSummary?.responseData?.responseMessage ?? "Some error occurred";
            showNotification("error", message)
        }
    }, [errorAtFetchingCollectionSummary])

    const { collectionSummary } = collectionsSummaryResponse ?? {};

    return (
        <StyledWrapper>
            <div className="dflex">
                <RecurTabs
                    tabs={tabs}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                />
                {currentTab === PG_COLLECTIONS && !errorAtFetchingCollectionSummary &&
                    <div className={Scheduled}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Scheduled Date</th>
                                    <th>Scheduled Amount (A)</th>
                                    <th>ESCROW BALANCE (B)</th>
                                    <th>PG Collections (C)</th>
                                    <th>NACH SCheduled (A-B-C)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{getValueOr_(collectionSummary?.scheduleDate)}</td>
                                    <td>{getValueWithRsOr_(collectionSummary?.scheduleAmount)}</td>
                                    <td>{getValueWithRsOr_(collectionSummary?.escrowBalance)}</td>
                                    <td>{getValueWithRsOr_(collectionSummary?.pgCollectionAmount)}</td>
                                    <td>{getValueWithRsOr_(collectionSummary?.nachScheduledAmount)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
            </div>

            {currentTab === PG_COLLECTIONS && (
                <PgCollections />
            )}

            {currentTab === NACH_COLLECTIONS && (
                <NachCollectionsView investeeOrgId={investeeOrgId}/>
            )}

        </StyledWrapper>
    )
}
