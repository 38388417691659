import React, { useEffect, useState } from 'react'
import styles from '../PgIntegrations/PgIntegration.module.scss';
import ConnectPgModal from '../ConnectPgModal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConnectedPGList } from '../../../../../../../../AdminActions';
import { getConnectedPgList, getInvesteesOrganization } from '../../../../../../../../AdminReducers';
import PgIntegrationRow from './components/PgIntegrationRow';

const {
    IntegrateNewPG,
    PGIntegrationTable
} = styles;

export default function PgIntegration() {
    const investeeOrgId = useSelector(getInvesteesOrganization)?._id;
    const connectedPGList = useSelector(getConnectedPgList)
    const [showConnectPgModal, setShowConnectPgModal] = useState(false)
    const [pgToEdit, setPgToEdit] = useState({})
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchConnectedPGList(investeeOrgId))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleEditClick = (pg) => {
        setPgToEdit(pg)
        setShowConnectPgModal(true)
    }

    const handleIntegrateNewPgClick = () => {
        setPgToEdit({});
        setShowConnectPgModal(true)
    }

    return (
        <>
            <ConnectPgModal show={showConnectPgModal} pgToEdit={pgToEdit} onHide={() => setShowConnectPgModal(false)} />
            <div className="dflex mt-5 mb-3">
                <span className='fs18 fw600'>PG Integrations</span>
                <button className={IntegrateNewPG} onClick={handleIntegrateNewPgClick}>Integrate New PG</button>
            </div>
            <div className={PGIntegrationTable}>
                <table className='table mb-0'>
                    <thead>
                        <tr>
                            <th>Start Date</th>
                            <th>Account ID</th>
                            <th>Payment Gateway</th>
                            <th>All PG Collections</th>
                            <th>All Recur Collections</th>
                            <th>Setup Frequency</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {connectedPGList?.map((pg, index) => {
                            return <PgIntegrationRow
                                key={index}
                                index={index}
                                pg={pg}
                                handleEditClick={handleEditClick}
                                investeeOrgId={investeeOrgId}
                            />
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}
