import CommonTable from "../../../../../../components/CommonTable/CommonTable";
import styles from "./BreakUpTable.module.scss";
const BreakUpTable = ({ item }: any) => {
  const {
    tableWrapper,
    investorname,
    collectedAmt,
    dueAmountWrapper,
    upperAmount,
    lowerAmount,
    bucketWrapper,
    portfolioUpperAmount,
  } = styles;
  const data = item.breakup?.length ? item.breakup : [];
  const columns = [
    {
      heading: "Investor",
      path: "investorName",
      cell: (value: any) => {
        return (
          <p className={investorname} title={value}>
            {value}
          </p>
        );
      },
    },
    {
      heading: "Collected",
      path: "collected",
      cell: (value: any) => {
        return <p className={collectedAmt}>{value ? value : "-"}</p>;
      },
    },
    {
      heading: "Due",
      path: "companyName",
      cell: (value: any, item: any) => {
        const { remainingAmt, remainingDelayCharge } = item;
        return (
          <div className={dueAmountWrapper}>
            <p className={upperAmount}> {remainingAmt}</p>
            <p className={lowerAmount}>+ {remainingDelayCharge} charges</p>
          </div>
        );
      },
    },
    {
      heading: "1-30 days",
      path: "dpdBucket",
      cell: (value: any, item: any) => {
        const { unpaidBucketDelay, unpaidBucketEmi } = item;
        const bucketKey = "bucket0";
        const delayAmt = unpaidBucketDelay[bucketKey];
        const delayEmi = unpaidBucketEmi[bucketKey];
        return (
          <div className={bucketWrapper}>
            {delayAmt || delayEmi ? (
              <>
                <p className={upperAmount}> {delayEmi}</p>
                <p className={lowerAmount}>+ {delayAmt} charges</p>
              </>
            ) : (
              <p className={upperAmount}> {"-"}</p>
            )}
          </div>
        );
      },
    },
    {
      heading: "31-60 days",
      path: "dpdBucket",
      cell: (value: any, item: any) => {
        const { unpaidBucketDelay, unpaidBucketEmi } = item;
        const bucketKey = "bucket1";
        const delayAmt = unpaidBucketDelay[bucketKey];
        const delayEmi = unpaidBucketEmi[bucketKey];
        return (
          <div className={bucketWrapper}>
            {delayAmt || delayEmi ? (
              <>
                <p className={upperAmount}> {delayEmi}</p>
                <p className={lowerAmount}>+ {delayAmt} charges</p>
              </>
            ) : (
              <p className={upperAmount}> {"-"}</p>
            )}
          </div>
        );
      },
    },
    {
      heading: "61-90 days",
      path: "dpdBucket",
      cell: (value: any, item: any) => {
        const { unpaidBucketDelay, unpaidBucketEmi } = item;
        const bucketKey = "bucket2";
        const delayAmt = unpaidBucketDelay[bucketKey];
        const delayEmi = unpaidBucketEmi[bucketKey];
        return (
          <div className={bucketWrapper}>
            {delayAmt || delayEmi ? (
              <>
                <p className={upperAmount}> {delayEmi}</p>
                <p className={lowerAmount}>+ {delayAmt} charges</p>
              </>
            ) : (
              <p className={upperAmount}> {"-"}</p>
            )}
          </div>
        );
      },
    },
    {
      heading: "90+",
      path: "dpdBucket",
      cell: (value: any, item: any) => {
        const { unpaidBucketDelay, unpaidBucketEmi } = item;
        const bucketKey = "bucket3";
        const delayAmt = unpaidBucketDelay[bucketKey];
        const delayEmi = unpaidBucketEmi[bucketKey];
        return (
          <div className={bucketWrapper}>
            {delayAmt || delayEmi ? (
              <>
                <p className={upperAmount}> {delayEmi}</p>
                <p className={lowerAmount}>+ {delayAmt} charges</p>
              </>
            ) : (
              <p className={upperAmount}> {"-"}</p>
            )}
          </div>
        );
      },
    },
    {
      heading: "Portfolio Risk",
      path: "companyName",
      cell: (value: any, item: any) => {
        const { totalRiskAmt, totalRiskText } = item;
        return (
          <div className={bucketWrapper}>
            <>
              <p className={`${upperAmount} ${portfolioUpperAmount}`}>{totalRiskAmt}</p>
              <p className={lowerAmount}>{totalRiskText}</p>{" "}
            </>
          </div>
        );
      },
    },
  ];
  return (
    <CommonTable
      columns={columns}
      data={data}
      tableClassName={"need-info-table"}
      hover={false}
      tableHeadingClass={""}
    />
  );
};
export default BreakUpTable;
