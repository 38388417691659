import React, { useEffect, useState } from 'react';
import { convertArrayToEnum } from '../../../../../../../../../../../../util/utility';
import '../UnderwrintingTable.scss';

function CollapsibleTableRow({ rowdata, headers }) {

  const [rowData, setRowData] = useState([]);
  const [showSubrows, setShowSubrows] = useState(false);
  const hasSubrows = !!rowData?.subRows?.length;

  useEffect(() => {
    if (rowdata) {
      setRowData({
        ...rowdata,
        amountsMap: convertArrayToEnum(rowdata?.amounts ?? [], 'period'),
        subRows: rowdata?.subRows?.map((subrow) => ({
          ...subrow,
          amountsMap: convertArrayToEnum(subrow?.amounts ?? [], 'period')
        })) ?? []
      })
    }
  }, [rowdata])
  //.filter(subrow => !!subrow?.name)?

  return (
    <>
      <tr>
        {headers.map((header, i) => (
          <>
            {(header.key !== 'period') ? (
              <td
                className={`namecolumn ${hasSubrows && 'cp'}`}
                onClick={() => hasSubrows && setShowSubrows(show => !show)}
              >
                {rowData[header.key] ?? "-"}

                {i === 0 && hasSubrows ? (
                  <img
                    alt=""
                    className={`expandimg ${showSubrows && 'rotateimg'}`}
                    src="/assets/expand.svg"
                    height="5px"
                    style={{ marginLeft: "10px" }}
                  />
                ) : null}
              </td>
            ) : (
              <td>
                {rowData?.amountsMap?.[header.name]?.amount ?? "-"}
              </td>
            )}
          </>
        ))}
      </tr>

      {showSubrows && rowData?.subRows?.length ? (
        <>
          {rowData?.subRows.map((subrow, ind) => (
            <tr>
              {headers.map((header, i) => (
                <>
                  {(header.key !== 'period') ? (
                    <td className="subrow" style={{ minWidth: "130px" }}>{subrow?.[header.key] || "-"} </td>
                  ) : (
                    <td>
                      {subrow?.amountsMap?.[header.name]?.amount ?? "-"}
                    </td>
                  )}
                </>
              ))}
            </tr>
          ))}
        </>
      ) : null}
    </>

  )
}

export default CollapsibleTableRow