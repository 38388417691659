import moment from 'moment';
import React from 'react';
import styles from '../MultipleTotComponents/MultipleTotFooter.module.scss';
import { expireApplication } from '../../../../../../../../../../../../../common/ApiHelpers'
import { showNotification } from "../../../../../../../../../../../../../util/utility.js";
import { GENERIC_ERROR, SHOW_NOTIFICATION_STATUS } from "../../../../../../../../../../../../../constants/enums.js";
import clsx from 'clsx';


const {
    MultipleTotFooterCard,
    applicationActiveToggle,
    lastEmailStyle,
    renderButtonStyle,
    toggleContainer,
    disabledFontStyle
} = styles;

export default function MultipleTotFooter({
    renderButtons,
    lastEmail,
    showToggle = false,
    isExpired,
    setIsExpired,
    applicationId,
    applicationType,
    fetchMonthlyData,
    isDrawdownTaken
}) {
    const toggleApplicationExpiry = async () => {
        let payload = {
            _id: applicationId,
            applicationType: applicationType,
            is_expired: !isExpired,
        }
        const res = await expireApplication(payload);
        if (res.data) {
            fetchMonthlyData();
            setIsExpired(!isExpired);
            showNotification(SHOW_NOTIFICATION_STATUS.SUCCESS, res.data);
        } else {
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, GENERIC_ERROR);
        }
    }

    return (
        <div className={MultipleTotFooterCard}>
            <div className={lastEmailStyle}><b>Last email: </b><span>{lastEmail ? moment(lastEmail).format("HH:mm, DD MMM YYYY") : ''}</span></div>
            <div className={clsx([applicationActiveToggle, {[disabledFontStyle]: isDrawdownTaken}])}>
                {showToggle && (
                    <>
                        <div className={toggleContainer}>
                            <label className="checkbox-container">
                                <input
                                    type="checkbox"
                                    checked={!isExpired}
                                    onChange={toggleApplicationExpiry}
                                    disabled={isDrawdownTaken}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        Application {isExpired ? "Expired" : "Active"}
                    </>
                )}
            </div>
            <div className={renderButtonStyle}>
                {renderButtons()}
            </div>
        </div>
    )
}