import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ComplianceTable from "./components/ComplianceTable/ComplianceTable";
import OverallView from "./components/OverallView/OverallView";
import callApi from "../../../../../../../../util/apiCaller";
import styles from "./ComplianceCheck.module.scss";
import { API_STATUS } from "../../../../../../../../constants/enums";

const { complianceContainer, complianceInnerContainer } = styles;

const ComplianceCheck = () => {
    const [nachSummary, setNachSummary] = useState(null);
    const [overall, setOverall] = useState(null);
    const [complianceData, setComplianceData] = useState([]);
    const [repaymentRemaining, setRepaymentRemaining] = useState(null);
    const [spoc, setSpoc] = useState(null);
    const { id } = useParams();

    function fetchComplianceData() {
        callApi("admin/fetchComplianceDetails", "post", { investee_organization_id: id }).then(
            (res) => {
                if (res.status === API_STATUS.SUCCESS) {
                    setNachSummary(res.data?.nach ?? null);
                    setOverall(res.data?.overall ?? null);
                    setComplianceData(res.data?.data ?? null);
                    setRepaymentRemaining(res.data?.repayment_remaining ?? null);
                    setSpoc(res.data?.spoc ?? null);
                }
            }
        );
    }

    useEffect(() => {
        fetchComplianceData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={complianceContainer}>
            <div className={complianceInnerContainer}>
                <OverallView
                    nachSummary={nachSummary}
                    overall={overall}
                    repaymentRemaining={repaymentRemaining}
                    spoc={spoc}
                />
            </div>
            <ComplianceTable
                complianceData={complianceData}
                setComplianceData={setComplianceData}
                setRcm={setNachSummary}
                setOverall={setOverall}
                setSpoc={setSpoc}
            />
        </div>
    );
};

export default ComplianceCheck;
