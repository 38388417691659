import {useState} from 'react';
import './ViewFilters.scss';
import { isEmpty } from 'lodash';

interface ViewFiltersProps{
    viewFilterOptions: string[]
    setFilterString: (filterString: any)=>void
    filterString: any
}
export const ViewFilters=(props: ViewFiltersProps)=>{
    const {viewFilterOptions,filterString} = props;
    const [selectedFilter,setSelectedFilter] = useState<string>('')
    const handleSelect = (value:string)=>{
        setSelectedFilter(value)
        props.setFilterString(value)
    }

    if (isEmpty(viewFilterOptions)) return null;

    return(
        <div className='ViewFilterContainer'>
            <select
                className='ViewFilterSelect'
                value={selectedFilter}
                onChange={(e)=>handleSelect(e.target.value)}
            >
                <option value=''>
                    {filterString}
                </option>
                {viewFilterOptions.filter(i=>i!==filterString).map((option,index)=>{
                    return(
                        <option
                            key={index}
                            value={option}
                        >
                            {option}
                        </option>
                    )
                })
                }
            </select>
        </div>
    )
}