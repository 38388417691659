import _ from "lodash";
import moment from "moment";
export const MONTHLY_TIME_PERIOD = "monthly";
export const QUARTERLY_TIME_PERIOD = "quarterly";
export const SEMI_ANNUAL_TIME_PERIOD = "semi-annually";
export const ANNUAL_TIME_PERIOD = "annually";

function getContractTransactionDetails(input) {
  let obj = {};

  let payment_delay_with_additional =
    input.payment_delay + input.additionalDelay; // for revising dates if gapvalue check doesn't meet(two repayments clash)
  let collection_date = moment(input.invoice_date).add(
    payment_delay_with_additional,
    "days"
  );

  const month = collection_date.format("MM");
  const year = collection_date.format("YYYY");
  let toCheckDate = moment(
    `${year}-${parseInt(month)}-${input.repaymentDay}`,
    "YYYY-MM-DD"
  );

  let repayment_date;
  if (collection_date.isSameOrAfter(toCheckDate)) {
    // isAfter // isBefore
    if (parseInt(month) === 12) {
      repayment_date = moment.utc(
        `${parseInt(year) + 1}-01-${input.repaymentDay}`,
        "YYYY-MM-DD"
      );
    } else {
      repayment_date = moment.utc(
        `${year}-${parseInt(month) + 1}-${input.repaymentDay}`,
        "YYYY-MM-DD"
      );
    }
  } else {
    repayment_date = moment.utc(
      `${year}-${month}-${input.repaymentDay}`,
      "YYYY-MM-DD"
    );
  }

  let today = moment().utcOffset(330);
  let dayToday = parseInt(today.format('D'));
  if( dayToday>= 28){
      today.add(1,'M');
  } 
  let monthToday = today.format('MM');
  let yearToday = today.format('YYYY');
  let next_28 = moment.utc(`28-${monthToday}-${yearToday}`,'DD-MM-YYYY');

  if (repayment_date.isSameOrBefore(next_28)) {
    obj.is_expired = true;
  }
  obj.scheduled_invoice_date = input.invoice_date;
  obj.scheduled_recur_payment_date = repayment_date;
  return obj;
}

function getAllPossibleContractTransactions(
  contract,
  additionalDelay,
  paymentDelay
) {
  let unit = contract.contract_term_unit
    ? contract.contract_term_unit.toLowerCase()
    : "";
  let freqGapObj = getRepaymentFrequencyAndGapValue(unit);
  let frequency = freqGapObj.frequency;
  let contractStartDate = contract.contract_start_date;
  let contractEndDate = contract.contract_end_date;
  let startEndDiff = 0;
  if (!contractEndDate) {
    let now = moment().utcOffset(330).startOf("day").endOf("month");
    contractEndDate = now.add(13, "months");
  }
  startEndDiff = monthsDiffBetweenDates(contractStartDate, contractEndDate);
  // var date = startdateWithPD;
  
  let sdate = moment.utc(contract.contract_start_date);
  let daySdate = sdate.format("DD");
  let monSdate = sdate.format("MM");
  let yearSdate = sdate.format("YYYY");
  let invoice_date = moment.utc(
    `${daySdate}-${monSdate}-${yearSdate}`,
    "DD-MM-YYYY"
  );

  let dayOfInitialDate = parseInt(sdate.format("DD"));
  let lastDayOfMonth = sdate.endOf("month").format("DD");
  let addTillEnd = dayOfInitialDate === parseInt(lastDayOfMonth) ? true : false;

  let contract_tranactions = [];


  for (let i = 0; i <= startEndDiff - frequency; i += frequency) {
    let input = {
      contract: contract,
      payment_delay: paymentDelay,
      invoice_date: invoice_date.clone(),
      additionalDelay: additionalDelay,
      repaymentDay: 28,
    };

    let trxDetails = getContractTransactionDetails(input);
    contract_tranactions.push(trxDetails);

    invoice_date = addMonth(
      invoice_date,
      frequency,
      dayOfInitialDate,
      addTillEnd
    );
    // invoice_date = invoice_date.add(frequency,'M');
  }

  return contract_tranactions;
}

function checkIfRevisionRequired(transactionObjArray, gapValue, gapValueGT) {
  for (let i = 0; i < transactionObjArray.length - 1; i++) {
    let current = transactionObjArray[i].scheduled_recur_payment_date;
    let next = transactionObjArray[i + 1].scheduled_recur_payment_date;
    let diffinDays = moment(next).diff(moment(current), "days");
    if (diffinDays < gapValue || diffinDays > gapValueGT) {
      return true;
    }
  }
  return false;
}

export const calculateTerm = (contract, paymentDelay) => {
  let transactionObjArr = getAllPossibleContractTransactions(
    contract,
    0,
    paymentDelay
  );
  let unexpiredTrxArray = [];
  let expiredTrxArray = [];
  if (transactionObjArr) {
    let gapfreqObj = getRepaymentFrequencyAndGapValue(
      contract.contract_term_unit
    );
    let maxVal = gapfreqObj.maxVal;
    let displayUnit = gapfreqObj.displayUnit;
    let isRevisionReq = checkIfRevisionRequired(
      transactionObjArr,
      gapfreqObj.gapValue,
      gapfreqObj.gapValueGT
    );
    let needExtension = false;
    if (isRevisionReq) {
      transactionObjArr = getAllPossibleContractTransactions(
        contract,
        10,
        paymentDelay
      );
    }
    let unexpiredTrxCount = 0;
    transactionObjArr.forEach((item) => {
      if (item.is_expired) {
        expiredTrxArray.push(item);
        needExtension = true;
      } else {
        unexpiredTrxCount++;
        if (unexpiredTrxArray.length < maxVal) {
          unexpiredTrxArray.push(item);
        }
      }
    });
    // let termCount = 0;
    // if(needExtension){
    //     unexpiredTrxCount=0;//reset to zero
    //     unexpiredTrxArray = []; //reset to empty array
    //     let lastExpiredTrx = expiredTrxArray[expiredTrxArray.length-1];
    //     let transactionObjArrExtended = [];
    //     if(isRevisionReq){
    //         transactionObjArrExtended =  extendContractTransactions(contract,10,lastExpiredTrx,paymentDelay);
    //     }
    //     else{
    //         transactionObjArrExtended =  extendContractTransactions(contract,0,lastExpiredTrx,paymentDelay);
    //     }

    //     mergeByProperty(transactionObjArr, transactionObjArrExtended, 'scheduled_invoice_date');
    //     transactionObjArr.forEach(item=>{
    //         if(item.is_expired){
    //             needExtension = true;
    //         } else{
    //             unexpiredTrxCount++;
    //             if(unexpiredTrxArray.length <maxVal){
    //                 unexpiredTrxArray.push(item);
    //             }
    //         }
    //     });
    //     termCount = unexpiredTrxCount;
    // }
    // else{
    //     termCount = unexpiredTrxCount;
    // }

    let termCount = unexpiredTrxCount > maxVal ? maxVal : unexpiredTrxCount;
    return {
      term: `${termCount} ${termCount > 1 ? displayUnit + "s" : displayUnit}`,
      transactionsArr: unexpiredTrxArray,
      term_numeric: Number(termCount),
    };
  } else {
    return "";
  }
};

export function mergeByProperty(arr1, arr2, prop) {
  _.each(arr2, function (arr2obj) {
    let arr1obj = _.find(arr1, function (arr1obj) {
     for(let propSingle of prop.split(",")){
        if(_.get(arr1obj,propSingle) !== _.get(arr2obj,propSingle)) return false;
      }
      return true;
    });

    arr1obj ? _.extend(arr1obj, arr2obj) : arr1.push(arr2obj);
  });
  return arr1;
}

function extendContractTransactions(
  contract,
  additionalDelay,
  last_expired_transaction,
  paymentDelay
) {
  let unit = contract.contract_term_unit.toLowerCase();
  let freqGapObj = getRepaymentFrequencyAndGapValue(unit);
  let frequency = freqGapObj.frequency;

  // let contractStartDay = moment(contract.contract_start_date).format('DD');

  let startDate; // check if valid
  if (last_expired_transaction) {
    let last_expired_transaction_invoice_date = moment(
      last_expired_transaction.scheduled_invoice_date
    );
    let dayOfInitialDateExp = parseInt(
      last_expired_transaction_invoice_date.format("DD")
    );
    let lastDayOfMonthExp = last_expired_transaction_invoice_date
      .clone()
      .endOf("month")
      .format("DD");
    let addTillEndExp =
      dayOfInitialDateExp == parseInt(lastDayOfMonthExp) ? true : false;
    startDate = addMonth(
      last_expired_transaction_invoice_date,
      frequency,
      dayOfInitialDateExp,
      addTillEndExp
    );
  } else {
    startDate = moment.utc(contract.contract_start_date);
  }
  let contractEndDate = contract.contract_end_date;
  let startEndDiff = 0;
  if (contractEndDate) {
    startEndDiff = monthsDiffBetweenDates(startDate, contractEndDate);
    if (startEndDiff > 12) {
      startEndDiff = 12;
    } else {
      startEndDiff = Math.ceil(startEndDiff);
    }
  } else {
    startEndDiff = 12;
  }
  // var date = startdateWithPD;
  let arr = [];
  for (let i = 0; i <= startEndDiff - frequency; i += frequency) {
    arr.push(i);
  }
  let invoice_date = moment(startDate);

  let dayOfInitialDate = parseInt(invoice_date.format("DD"));
  let lastDayOfMonth = invoice_date.endOf("month").format("DD");
  let addTillEnd = dayOfInitialDate == parseInt(lastDayOfMonth) ? true : false;

  let contract_tranactions = [];
  arr.map((item) => {
    let input = {
      contract: contract,
      payment_delay: paymentDelay,
      invoice_date: invoice_date.clone(),
      additionalDelay: additionalDelay,
      repaymentDay: 28,
    };

    let trxDetails = getContractTransactionDetails(input);
    contract_tranactions.push(trxDetails);

    invoice_date = addMonth(
      invoice_date,
      frequency,
      dayOfInitialDate,
      addTillEnd
    );
    // invoice_date = invoice_date.add(frequency,'M');
  });

  return contract_tranactions;
}

export const termUnit = (term_unit) => {
  let lowerCaseUnit = term_unit.toLowerCase();
  if (lowerCaseUnit == "monthly") {
    return "month";
  } else if (lowerCaseUnit == "quarterly") {
    return "quarter";
  } else if (lowerCaseUnit == "semi-annually") {
    return "half year";
  } else {
    return "year";
  }
  return;
};

export const displayTermUnit = (unit) => {
  let lowerCaseUnit = unit.toLowerCase();
  if (lowerCaseUnit == "monthly") {
    return "mo";
  } else if (lowerCaseUnit == "quarterly") {
    return "qtr";
  } else if (lowerCaseUnit == "semi-annually") {
    return "6 m";
  } else {
    return "yr";
  }
};

export const getRepaymentFrequencyAndGapValue = (unit) => {
  let lowerCaseUnit = unit ? unit.toLowerCase() : "";
  if (lowerCaseUnit == MONTHLY_TIME_PERIOD) {
    return {
      frequency: 1,
      gapValue: 10,
      gapValueGT: 40,
      displayUnit: "month",
      maxVal: 12,
    };
  } else if (lowerCaseUnit == QUARTERLY_TIME_PERIOD) {
    return {
      frequency: 3,
      gapValue: 75,
      gapValueGT: 105,
      displayUnit: "quarter",
      maxVal: 4,
    };
  } else if (lowerCaseUnit == SEMI_ANNUAL_TIME_PERIOD) {
    return {
      frequency: 6,
      gapValue: 170,
      gapValueGT: 205,
      displayUnit: "half year",
      maxVal: 2,
    };
  } else {
    return {
      frequency: 12,
      gapValue: 340,
      gapValueGT: 380,
      displayUnit: "year",
      maxVal: 1,
    };
  }
};

export const addMonth = (date, months, dayOfInitialDate, addTillEnd) => {
  let futureMonth = date.clone().add(months, "M");
  let noDaysInMonth = getNoOfDays(futureMonth);
  let month = moment(futureMonth).format("MM");
  let year = moment(futureMonth).format("YYYY");

  if (addTillEnd) {
    return moment(futureMonth).endOf("month");
  } else if (parseInt(dayOfInitialDate) < noDaysInMonth) {
    return moment.utc(
      `${dayOfInitialDate}-${parseInt(month)}-${year}`,
      "DD-MM-YYYY"
    );
  } else {
    return moment.utc(
      `${parseInt(noDaysInMonth)}-${parseInt(month)}-${year}`,
      "DD-MM-YYYY"
    );
  }
};

const getNoOfDays = (date) => {
  return moment(date, "YYYY-MM-DD").daysInMonth();
};

export const monthsDiffBetweenDates = (start, end) => {
  let start_date = moment.utc(start).startOf("day");
  let end_date = moment.utc(end).startOf("day");

  let diff = 0;
  while (
    start_date
      .clone()
      .add(diff + 1, "months")
      .add(-1, "days")
      .isSameOrBefore(end_date)
  ) {
    diff += 1;
  }
  return diff;
};
