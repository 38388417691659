import { useState } from 'react';
import PageLoader from '../../../../../../../../../../../../../../components/loader/PageLoader';
import LoadingSpinner from '../../../../../../../../../../../../../../components/LoadingSpinner/LoadingSpinner';
import { DOC_SERVICE_RESPONSE, GENERIC_ERROR, SHOW_NOTIFICATION } from '../../../../../../../../../../../../../../constants/enums';
import callApi from '../../../../../../../../../../../../../../util/apiCaller';
import { removeItemFromArray, showNotification } from '../../../../../../../../../../../../../../util/utility';
import { MESSAGE_TYPES } from '../consts/consts';
import styles from './ShowFiles.module.scss';

export default function ShowFiles({
    className,
    childClassName,
    messageType,
    files,
    onDeleteSuccess,
    setFiles,
    setUploadedFiles
}) {
    const {
        FilesContainer,
        FileContainer,
        FileName,
        FileActionIcons,
        Visible,
        VisibilityHidden,
        DownloadIcon,
    } = styles;

    const [isDeleting, setIsDeleting] = useState(false);
    const [isDownloading, setIsDownloading] = useState([]);

    const downloadFile = async (file) => {
        try {
            const docId = file.docLink;
            if (!docId) throw new Error();
            const tempIsDownloading = [...isDownloading];
            tempIsDownloading.push(docId);
            setIsDownloading(tempIsDownloading);
            const res = await callApi(`file/downloadFilePresignedForUnsignedKycDoc?fileId=${docId}`, "post", {}, {}, false, false, false, false, false, true);
            if (res?.responseData?.responseCode === DOC_SERVICE_RESPONSE.SUCCESS) {
                const url = res?.signedUrl;
                if (!url) throw new Error();
                const link = document.createElement('a');
                link.href = url;
                link.download = file.docName ?? '';
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
            }
            else {
                const message = res?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION.ERROR, message);
            }
        } catch (err) {
            showNotification(
                SHOW_NOTIFICATION.ERROR,
                'Not Able to fetch the file to Download',
            );
        } finally {
            const tempIsDownloading = removeItemFromArray([...isDownloading], file.docLink);
            setIsDownloading(tempIsDownloading);
        }
    };

    const deleteFile = async (docId,i) => {
        const tempFiles = [...files];
        let index = 0;
        if(typeof docId ==="string"){
            index=i
            if (index >= 0) tempFiles.splice(index, 1);
            setFiles(tempFiles)
            setUploadedFiles((prev)=>{
                return prev.filter((e)=>e.name !==docId)
            })
            return
        }

        for (let file of tempFiles) {
            if (file.docLink === docId) break;
            index++;
        }
        if (index >= 0) tempFiles.splice(index, 1);
        setIsDeleting(true);
        const res = await callApi("file/deleteFiles", "post", {
            identifierName: 'fileIds',
            docList: [docId]
        }, {}, false, false, false, false, false, true);
        if (res?.responseData?.responseCode === DOC_SERVICE_RESPONSE.SUCCESS) onDeleteSuccess(MESSAGE_TYPES.DRAFT, tempFiles);
        else {
            const message = res?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION.ERROR, message);
        }
        setIsDeleting(false);
    };

    const showDownloadIconOrLoader = (file) => {
        if (isDownloading.includes(file.docLink))
            return (
                <LoadingSpinner
                    className=""
                    width="11px"
                    height="11px"
                    style={{ alignSelf: 'center', marginRight: '2px' }}
                />
            );
        return (
            <img
                src="/assets/white-download-icon.svg"
                alt=""
                className={DownloadIcon}
                onClick={() => downloadFile(file)}
            />
        );
    };

    return (
        <div className={className ? `${FilesContainer} ${className}` : FilesContainer}>
            {isDeleting && <PageLoader />}
            {files?.map((file,index) => (
                <div
                    key={file.docLink}
                    className={
                        childClassName ? `${FileContainer} ${childClassName}` : FileContainer
                    }
                >
                    <div
                        className={FileName}
                        onClick={() => downloadFile(file)}
                        title={file.docName}
                    >
                        {file.docName}
                    </div>
                    <div className={FileActionIcons}>
                        <LoadingSpinner
                            className={
                                messageType === MESSAGE_TYPES.DRAFT &&
                                isDownloading.includes(file.docLink)
                                    ? Visible
                                    : VisibilityHidden
                            }
                            width="11px"
                            height="11px"
                            style={{ alignSelf: 'center' }}
                        />
                        {messageType === MESSAGE_TYPES.DRAFT  ? (
                            <img
                                src="/assets/white-cross.svg"
                                alt=""
                                className="cp"
                                onClick={() => deleteFile(String(file.docLink).length? file.docLink: file.docName,index)}
                            />
                        ) : (
                            showDownloadIconOrLoader(file)
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}
