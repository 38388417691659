import React from 'react';
import _ from "lodash";

const InvestorBanks = ({toggleAddBank, banklist}) => {
    return (
        <>
            <div className="admin-data-table1" style={{border: "none"}}>
                <button
                    onClick={() => toggleAddBank()}
                    className="btn-bg-clr company-small-btn" style={{width: "150px"}}>Add New Account
                </button>
            </div>
            <div className="admin-data-table1">
                <table className="table team">
                    <thead className="team-head">
                    <tr>
                    <th scope='col'>Account Holder Name</th>
                        <th scope="col">Nick Name</th>
                        <th scope="col">Account No.</th>
                        <th scope="col">Bank Name</th>
                        <th scope="col">IFSC Code</th>
                        <th scope="col">ESP Bank ID</th>
                        <th scope="col">Payout a/c for</th>
                    </tr>
                    </thead>
                    <tbody className="team-body">
                    {_.isEmpty(banklist) ?
                        null
                        :
                        <>{
                            banklist.map(item => (
                                <tr>
                                     <td className='pl-2'>{item.account_holder_name}</td>
                                    <td className="pl-2">{item.nick_name}</td>
                                    <td className="blue1">{item.account_number}</td>
                                    <td className="blue1">{item.bank_name}</td>
                                    <td className="blue1">{item.ifsc_code}</td>
                                    <td className="blue1">{item.escrow_pay_bank_id}</td>
                                    <td className="blue1">{_.isEmpty(item.payout_acc_for_org_ids) ? "-" : item.payout_acc_for_org_ids?.map(x => x.name).join(",")}</td>
                                </tr>
                            ))
                        }
                        </>
                    }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default InvestorBanks
