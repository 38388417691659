import React from 'react';

import styles from './DragDrop.module.scss';


function DragDropUpload({ isBackgroudWhite = false }: any) {

    const { Drag, Left, Right, Select , NewButton} = styles;
    return (
        <div
            className={
                isBackgroudWhite ? `BGColor ${NewButton}` : `${NewButton}`
            }
        >
            <div className={Left}>
                <div className={Drag}>Drag and Drop</div>
                <div className={Select}>Select a file or drop one here</div>
            </div>
            <div className={Right}>
                <img src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/black-plus.svg" alt="plus" height="20px" />
            </div>
        </div>
    );
}

export default DragDropUpload;
