import React, {
    // Component
} from 'react';
// import {Link} from 'react-router-dom';
import FilterHeaderComponent from '../../../../../components/FilterHeaderComponent/FilterHeaderComponent';

function RepaymentsTableHeader(props) {
    return (
        <thead className="team-head repay-table">
            <tr>
                <td></td>
                <FilterHeaderComponent
                    isSortAllowed={true}
                    sorting_order={'desc'}
                    isFilterAllowed={true}
                    headerText={'Investor Org'}
                    labelText={'Search Investor Org'}
                    filter_type={'search'}
                    handleSearchFilter={props.handleSearchFilter}
                    keyName={props.searchObjInvestorOrg.keyName}
                    value={props.searchObjInvestorOrg.value}
                />
                {/* <FilterHeaderComponent
                isSortAllowed={true}
                sorting_order={'desc'}
                isFilterAllowed={true}
                headerText={'Investor User'}
                labelText={'Search Investor User'}
                filter_type={'search'}
                handleSearchFilter = {props.handleSearchFilter}
                keyName = {props.searchObjInvestorUser.keyName}
                value = {props.searchObjInvestorUser.value}
            /> */}
                <FilterHeaderComponent
                    isSortAllowed={true}
                    sorting_order={'desc'}
                    isFilterAllowed={true}
                    headerText={'Company Name'}
                    labelText={'Search Company Name'}
                    filter_type={'search'}
                    handleSearchFilter={props.handleSearchFilter}
                    keyName={props.searchObjCompanyName.keyName}
                    value={props.searchObjContractID.value}
                />
                <FilterHeaderComponent
                    isSortAllowed={true}
                    sorting_order={'desc'}
                    isFilterAllowed={true}
                    headerText={'Contract ID'}
                    labelText={'Search Contract ID'}
                    filter_type={'search'}
                    handleSearchFilter={props.handleSearchFilter}
                    keyName={props.searchObjContractID.keyName}
                    value={props.searchObjContractID.value}
                />
                <FilterHeaderComponent
                    isFilterAllowed={true}
                    headerText={'Repayment No.'}
                    labelText={'Filter Repayment No.'}
                    filter_type={'range_search'}
                    handleSliderFilter={props.handleSliderFilter}
                    minVal={props.sliderObjRepaymentNo.minVal}
                    maxVal={props.sliderObjRepaymentNo.maxVal}
                    currentMin={props.sliderObjRepaymentNo.currentMin}
                    currentMax={props.sliderObjRepaymentNo.currentMax}
                    minValueBetween={props.sliderObjRepaymentNo.minValueBetween}
                    keyName={props.sliderObjRepaymentNo.keyName}
                />
                <FilterHeaderComponent
                    isFilterAllowed={true}
                    headerText={'Amount'}
                    labelText={'Filter Amount'}
                    filter_type={'range_search'}
                    handleSliderFilter={props.handleSliderFilter}
                    minVal={props.sliderObjAmount.minVal}
                    maxVal={props.sliderObjAmount.maxVal}
                    currentMin={props.sliderObjAmount.currentMin}
                    currentMax={props.sliderObjAmount.currentMax}
                    minValueBetween={props.sliderObjAmount.minValueBetween}
                    keyName={props.sliderObjAmount.keyName}
                />
                <FilterHeaderComponent
                    isFilterAllowed={true}
                    headerText={'Scheduled Date'}
                    labelText={'Scheduled Date'}
                    filter_type={'datepicker'}
                    isSortAllowed={true}
                    sorting_order={'desc'}
                    handledatePicker={props.handledatePicker}
                    date={props.scheduledDateObj.date}
                    keyName={props.scheduledDateObj.keyName}
                />
                <FilterHeaderComponent
                    isSortAllowed={true}
                    sorting_order={'asc'}
                    isFilterAllowed={true}
                    headerText={'Status'}
                    labelText={'Filter Status'}
                    filter_type={'selector'}
                    inputSelectorHandler={props.inputSelectorHandler}
                    elementsArray={props.selectorObjStatus.elementsArray}
                    keyName={props.selectorObjStatus.keyName}
                />
                <FilterHeaderComponent
                    isSortAllowed={true}
                    sorting_order={'desc'}
                    isFilterAllowed={true}
                    headerText={'Payment ID'}
                    labelText={'Search Payment ID'}
                    filter_type={'search'}
                    handleSearchFilter={props.handleSearchFilter}
                    keyName={props.searchObjPaymentID.keyName}
                    value={props.searchObjPaymentID.value}
                />
                <FilterHeaderComponent
                    isFilterAllowed={true}
                    headerText={'Last Updated'}
                    labelText={'Filter Last Updated'}
                    filter_type={'datepicker'}
                    isSortAllowed={true}
                    sorting_order={'desc'}
                    handledatePicker={props.handledatePicker}
                    date={props.lastUpdateDateObj.date}
                    keyName={props.lastUpdateDateObj.keyName}
                />
                <th>Escrow Status</th>
            </tr>
        </thead>
    )
}

export default RepaymentsTableHeader;
