import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  downloadFileFromArrayBuffer,
  getIndianFormattedNumber,
  showNotification,
} from "../../../../../../../../../../../../util/utility";
import callApi from "../../../../../../../../../../../../util/apiCaller";
import styles from '../../../bankstats.module.css'
import {
  BANK_EDIT_CLASSIFY_TYPE,
  BANK_STMT_CLASSIFICATION_TYPES,
  GST_CLASSIFICATION_TYPES,
  GST_CLASSIFY_TYPE_ARRAY,
  CURRENT_TAB_DATA_TYPE,
  CURRENT_TAB_TYPE,
  GST_INVOICE_MAP_TYPE,
  GST_EDIT_CLASSIFY_TYPE,
} from "../../../../../../../../../../../../common/ENUM";
import { isEmpty, isNil } from "lodash";
import CollapsibleTableRow from "./CollapsibleTableRow";
import { getObjAndFileName } from "../../../../../Overview/overviewUtility";
import { fetchPresignedUrl } from "../../../../../../../../../../../../common/ApiHelpers";
import { SHOW_NOTIFICATION_STATUS } from "../../../../../../../../../../../../constants/enums";

export default function UnderwritingTableRow({
  row,
  isCancelling,
  setIsCancelling,
  isSaving,
  setIsSaving,
  rowIndexRef,
  subclassificationIdRef,
  editedItems,
  setEditedItems,
  currentTab,
  underwritingClassificationDataState,
  setUnderwritingClassificationDataState,
  dataType,
  editBankClassification,
  bankBottomRef,
  editClassification,
  underwritingDataHeaders,
  editContracts,
  bottomRef,
  editInvoiceFrequency,
  invoicesNameListObj,
  editedContractThreshold,
  headerData
}) {
  const [classificationID, setClassificationID] = useState(
    row?.classificationId??null
  );
  const [subClassificationID, setSubClassificationID] = useState(
    row?.subClassificationId
  );
  const [frequency, setFrequency] = useState(row?.frequency);
  const [invoicesRationale, setInvoicesRationale] = useState(row?.rationale);
  const [bankRationale, setBankRationale] = useState(row?.rationale);
  const [bankClassification, setBankClassification] = useState(
    row?.bank_transaction_classification?.name
  );
  const [gstClassification, setGstClassification] = useState(
    row?.gstTag
  );
  const [editContractFrequency, setEditContractFrequency] = useState(
    row?.frequency
  );
  const [editContractStatus, setEditContractStatus] = useState(
    row?.adminStatus
  );
  const getFormattedNumber = (doFormatNumber, number) => {
    return doFormatNumber ? getIndianFormattedNumber(number): toString(number)?.split(',').join('')
  }
  const [editContractAmount, setEditContractAmount] = useState(
    getFormattedNumber(false, row?.amount)
  );
  const [modalShow, setModalShow] = useState(false);
  const [newSubclassification, setNewSubclassification] = useState("");
  const [showRationaleOptions, setShowRationaleOptions] = useState(false);
  // const [classify_change, set_classify_change] = useState("");
  // const [class_id, set_class_id] = useState("");

  useEffect(() => {
    setClassificationID(row?.classificationId);
    setSubClassificationID(
      !row?.subClassificationId
        ? Number("0")
        : row?.subClassificationId
    );
    setBankClassification(row?.bank_transaction_classification?.name);
    if (currentTab === CURRENT_TAB_TYPE.GST) setGstClassification(row?.gstTag);
    if (currentTab === CURRENT_TAB_TYPE.invoices) setInvoicesRationale(row?.rationale);
    else setBankRationale(row?.rationale);
  }, [row?.classificationId, row?.subClassificationId, row?.bank_transaction_classification?.name, row?.rationale, row?.gstTag]);

  useEffect(() => {
    if (!(isCancelling || isSaving)) {
      if (
        rowIndexRef.current === row?.id &&
        subclassificationIdRef.current
      ) {
        let newItem = { id: row?.id };
        newItem["subClassificationId"] = subClassificationID;
        const arr = [...editedItems].filter(
          (item) => item.classificationId || item.id !== newItem.id
        );
        arr.push(newItem);
        setEditedItems(arr);
        subclassificationIdRef.current = null;
      }
    } else {
      if (isCancelling) {
        setIsCancelling(false);
      }
      if (isSaving) {
        setIsSaving(false);
      }
      setEditedItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subClassificationID]);

  useEffect(() => {
    if (isCancelling === true) {
      setClassificationID(row?.classificationId);
      setSubClassificationID(row?.subClassificationId);
      setEditContractAmount(getFormattedNumber(false, row?.amount));
      setEditContractFrequency(row?.frequency);
      setEditContractStatus(row?.adminStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancelling]);
  useEffect(()=>{
    let list = []
    if(row.mis_amounts){
    let indextobesubtracted = underwritingDataHeaders.findIndex((item)=> item.key ==='period');
      underwritingDataHeaders?.some((item,index)=>{
        if(item.key === 'period'){
          row?.mis_amounts?.some((each)=>{
            if(each.period === item.name){
              list.push(each)
              return true
            }
            return false
          })
          if(!list[index-indextobesubtracted]){   //Had to subtract the index of classification in headers array.
            list.push({amount:'N/A', period: item.name})  //setting a zero amount for null data or no data.
          }
        }
      })
      row.mis_amounts = list
    }
  },[])

  const handleChange = (keyName, keyValue, prevValue = null) => {
    let newItem = { id: row?.id };
    newItem[keyName] = keyValue;
    if (prevValue) newItem.prevClassificationId = Number(prevValue);
    keyName === "classificationId" && setClassificationID(Number(keyValue));
    keyName === "subClassificationId" &&
      setSubClassificationID(Number(keyValue));

    setEditedItems((prevEdits) => {
      let newEdits = prevEdits.filter(
        (edit) =>
          edit.id !== newItem.id ||
          (edit.classificationId
            ? keyName === "subClassificationId"
            : keyName === "classificationId")
      );
      if (
        keyName === "classificationId" &&
        Number(row?.classificationId) !== Number(keyValue)
      ) {
        newEdits.push(newItem);
      }
      if (
        keyName === "subClassificationId" &&
        Number(row?.subClassificationId) !== Number(keyValue)
      ) {
        newEdits.push(newItem);
      }
      return newEdits;
    });
  };
  const updateEditedItems = (keyValuePair) => {
    let doesIdAlreadyExist = false;
    const items = editedItems.map(item => {
      if (item.id === row?.id) {
        doesIdAlreadyExist = true;
        for (let key in keyValuePair) {
          item[key] = keyValuePair[key];
        }
      }
      return item;
    });
    if (doesIdAlreadyExist) setEditedItems(items);
    else {
      const newItems = [...editedItems];
      newItems.push({
        id: row?.id,
        ...keyValuePair
      });
      setEditedItems(newItems);
    }
    // setEditedItems(editedItems.map(item => {
    //   if (item.id === row?.id && row?.bank_transaction_classification?.name !== bankClassification) {
    //     item.classificationId = BANK_EDIT_CLASSIFY_TYPE[bankClassification];
    //     item.prevClassificationId = BANK_EDIT_CLASSIFY_TYPE[bankClassification] === 1 ? 2 : 1
    //   }
    //   return item;
    // }));
  }
  const handleInvoicesRationaleChange = value => {
    setInvoicesRationale(value);
    setShowRationaleOptions(true);
  }
  const handleBankRationaleChange = e => {
    const { value } = e.target;
    setBankRationale(value);
    updateEditedItems({
      classificationId: BANK_EDIT_CLASSIFY_TYPE[bankClassification],
      rationale: value
    });
  }
  const handleBankClassifyChange = (bankClassification,prevClassificationId) => {
    let newClassificationId = parseInt(bankClassification);
    setBankClassification(newClassificationId);
    updateEditedItems({
      classificationId: newClassificationId,
      prevClassificationId: prevClassificationId,
      rationale: bankRationale??''
    });
  };
  const handleGstClassifyChange = (gstName, newClassification) => {
    setGstClassification(GST_CLASSIFY_TYPE_ARRAY[newClassification]??'')
    updateEditedItems({
      gst: gstName,
      gstTag: GST_CLASSIFY_TYPE_ARRAY[newClassification]??''
    });
  };
  const handleContractsChange = (frequency, adminStatus) => {
    setEditContractFrequency(frequency);
    setEditContractStatus(adminStatus);
    const items = [
      ...editedItems.filter((item) => item.id !== row?.id),
    ];
    if (
      row?.frequency !== frequency ||
      row?.adminStatus !== adminStatus
    ) {
      items.push({
        id: row?.id,
        frequency: frequency,
        adminStatus: adminStatus,
      });
    }
    setEditedItems(items);
  };
  const handleFrequencyChange = (frequency) => {
    setFrequency(frequency);
    if (row?.frequency !== frequency) updateEditedItems({ frequency });
  };

  const handleAmountChange = (amount) => {
    setEditContractAmount(amount);
    if (row?.amount !== amount) updateEditedItems({ amount });
  };
  const onChangeSelect = () => {
    if (changeFunc(row?.id) === true) {
      setModalShow(true);
    }
  };

  const unclassifiedColor =
    row?.classification === "Unclassified" ? "red" : "black";

  const underwritingCreatedSubclassification = {
    classificationId: classificationID,
    className: currentTab,
    name: newSubclassification,
  };

  const postUnderwritingCreatedSubclassification = () => {
    callApi(
      `/classifications/create-sub-classification`,
      "post",
      underwritingCreatedSubclassification,
      undefined,
      undefined,
      true
    ).then((res) => {
      if (res.success && res.data) {
        rowIndexRef.current = row?.id;
        showNotification("Success", res.message);

        let newItem = { id: res.data.data.id };
        newItem["class"] = currentTab;
        newItem["name"] = newSubclassification;

        const array = [...underwritingClassificationDataState];
        array[
          array.findIndex((data) => {
            return data.id === classificationID;
          })
        ].ledger_sub_classifications.push(newItem);

        setUnderwritingClassificationDataState(array);
        setSubClassificationID(res.data.data.id);
        subclassificationIdRef.current = res.data.data.id;
        setEditedItems([]);
      } else {
        showNotification("error", res.message);
      }
    });
  };
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);

  const getFileNameFromSignedUrl = (preSignedUrl) => {
    const parts = preSignedUrl.split("?")[0].split("/");
    let fileName = parts[parts.length - 1]
    return fileName;
  }

  const downloadLetter = async (url) => {
    setIsDownloadingFile(true);
    const response = await fetchPresignedUrl(Number(url));
    if (response) {
      const url = response.signedUrl;
      const responseDownload = await fetch(url);
      const blob = await responseDownload.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);

      link.download = getFileNameFromSignedUrl(url);
      link.click();
      setIsDownloadingFile(false)
    } else {
      showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Not Able to fetch the file to show');
    }
  };


  const handleDownloadExcelLink = (excelLink) => {
    const { obj, fileName } = getObjAndFileName(excelLink)
    const getBuffer = (data) => {
        return data.file.Body.data
    }
    const cb = null;
    downloadFileFromArrayBuffer("deals/admin/download-from-s3", "post", obj, null, fileName, { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }, getBuffer, cb)
}

  const contractFrequencyArr = [
    { label: "Monthly", value: "Monthly" },
    { label: "Quarterly", value: "Quarterly" },
  ];

  const contractAdminStatusArr = [
    { label: "Approved", value: "Approved" },
    { label: "On-Hold", value: "On-Hold" },
  ];

  const invoice_gst_type =
    dataType === CURRENT_TAB_DATA_TYPE.spread
      ? GST_INVOICE_MAP_TYPE.spread
      : GST_INVOICE_MAP_TYPE.pivot;

  
  const periodToAmount = row?.[invoice_gst_type]?.reduce((result, item) => {
    result[item.period] = item.amount;
    return result;
  }, {});

  const renderBankStatementsRowData = (item) => {
    const { key } = item;
    if (key === "bank_account.accountNumber") return row?.bank_account?.accountNumber;
    if (key === "bank_transaction_classification.name") {
      if (editBankClassification) return (
        <span
          style={{
            paddingTop: "7px",
            paddingBottom: "7px",
            position: "sticky",
            left: "360px",
            backgroundColor: "white",
          }}
        >
          <select
            className="underwriting-select"
            value={row?.classificationId}
            onChange={(e) => {
              handleBankClassifyChange(e.target.value,row?.classificationId);
            }}
          >
            {BANK_STMT_CLASSIFICATION_TYPES.map((option) => (
              <option key={`${option.name}${option.id}`} value={option.id}>{option.name}</option>
            ))}

          </select>
        </span>
      );
      return row?.bank_transaction_classification?.name;
    }
    if (key === "rationale") {
      if (editBankClassification) return (
        <input
          value={bankRationale}
          onChange={handleBankRationaleChange}
          placeholder="Enter rationale"
        />
      );
      return row[key];
    }
    if (row[key]) return row[key];
    return "";
  }

  const renderGstRowData = (item) => {
    const { key } = item;
    if(editClassification) {
      if(key === "gstTag") return (
        <span
          style={{
            paddingTop: "7px",
            paddingBottom: "7px",
            position: "sticky",
            left: "360px",
            backgroundColor: "white",
          }}
        >
          <select
            className="underwriting-select"
            value={GST_EDIT_CLASSIFY_TYPE[gstClassification]}
            onChange={(e) => {
              handleGstClassifyChange(row?.gst, e.target.value);
            }}
          >
            {GST_CLASSIFICATION_TYPES.map((option) => (
              <option key={`${option.name}${option.id}`} value={option.id}>{option.label}</option>
            ))}

          </select>
        </span>
      )
    }
    return row[item.key] ? row[item.key] : "";
  }

  const getDebtScheduleRow = (item) => {
    if(item.key === 'comment') {
      return (
        <div style={{
          background: '#F6F6F6',
          padding: '8px',
          borderRadius:'4px',
          width: '150px'
        }}>
          {row?.[item?.key] || "-"}
        </div>
      )
    }
    return row?.[item?.key] || "-"
  }

  const updateInvoicesRationale = (name) => {
    setInvoicesRationale(name);
    setShowRationaleOptions(false);
    updateEditedItems({
      rationale: name
    });
  }

  if(currentTab === CURRENT_TAB_TYPE.bank_statements && dataType === 'cashflow') {
    return <CollapsibleTableRow rowdata={row} headers={underwritingDataHeaders}/>
  }

  return (
    <>
      <div style={{ width: "10px" }}>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          postUnderwritingCreatedSubclassification={
            postUnderwritingCreatedSubclassification
          }
          setNewSubclassification={setNewSubclassification}
          newSubclassification={newSubclassification}
        />
      </div>

      {currentTab !== CURRENT_TAB_TYPE.invoices ? (
        <tr style={{ position: "relative" }} ref={bankBottomRef}>
          {/* ----------------------------------------------------------------------- */}
          {/* First column */}
          {currentTab != CURRENT_TAB_TYPE.bank_statements && currentTab != CURRENT_TAB_TYPE.GST &&
          (editClassification || dataType === "raw") ? (
            <td
              style={{
                minWidth: "120px",
                fontSize: "11px",
                fontWeight: "600",
                paddingTop: "7px",
                paddingBottom: "7px",
                position: "sticky",
                left: "0px",
                backgroundColor: "white",
              }}
            >
              {row?.particular ? row?.particular : ""}
            </td>
          )
            : (
            dataType === "classified" &&
            currentTab != CURRENT_TAB_TYPE.bank_statements && currentTab != CURRENT_TAB_TYPE.GST && (
              <td
                style={{
                  color: unclassifiedColor,
                  fontWeight: "600",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  position: "sticky",
                  left: "0px",
                  backgroundColor: "white",
                }}
              >
                {row?.classification}
              </td>
            )
          )}
          {/* masterpiece */}
          {dataType == "raw" &&
            currentTab == CURRENT_TAB_TYPE.bank_statements &&
            underwritingDataHeaders.map((item, index) => (
              <td
                key={index}
                style={{
                  minWidth: "120px",
                  fontSize: "11px",
                  fontWeight: "600",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  position: "sticky",
                  left: "0px",
                  backgroundColor: "white",
                }}
              >
                {renderBankStatementsRowData(item)}
              </td>
            ))}
          {(dataType == "pdf" && currentTab == CURRENT_TAB_TYPE.bank_statements) &&
            underwritingDataHeaders.map((item, index) => (
              <td
                key={index}
                style={{
                  minWidth: "120px",
                  fontSize: "11px",
                  fontWeight: "600",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  position: "sticky",
                  left: "0px",
                  backgroundColor: "white",
                }}
              >
                {row[item.key] ? row[item.key] : ""}
              </td>
            ))}
            {(currentTab == CURRENT_TAB_TYPE.debt_schedule) &&
            underwritingDataHeaders.map((item, index) => (
              <td
                key={index}
                style={{
                  minWidth: "120px",
                  fontSize: "11px",
                  fontWeight: "600",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  position: "sticky",
                  left: "0px",
                  backgroundColor: "white",
                }}
              >
                {getDebtScheduleRow(item)}
              </td>
            ))}
          {currentTab == CURRENT_TAB_TYPE.bank_statements && dataType == "pdf" && (
            <td
              style={{
                minWidth: "120px",
                fontSize: "11px",
                fontWeight: "600",
                paddingTop: "7px",
                paddingBottom: "7px",
                position: "sticky",
                left: "0px",
                backgroundColor: "white",
              }}
            >
              <Button onClick={() => downloadLetter(row?.doc_link)}>
                <i className="fas fa-download" />
              </Button>
            </td>
          )}
          {currentTab === CURRENT_TAB_TYPE.bank_statements &&
            dataType === CURRENT_TAB_DATA_TYPE.excel_report &&
            underwritingDataHeaders.map((item, index) => (
              <td
                style={{
                  minWidth: "180px",
                  fontSize: "11px",
                  fontWeight: "600",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  position: "sticky",
                  left: "0px",
                  backgroundColor: "white",
                  zIndex: 2,
                }}
              >
                {item.key === "link" ? (
                  <a href={row?.link} className={styles.anchor_btn}>
                      <i className="fas fa-download" />
                  </a>
                ) : row[item.key] ? (
                  row[item.key]
                ) : (
                  ""
                )}
              </td>
            ))}
          {currentTab == CURRENT_TAB_TYPE.bank_statements && (dataType==='summary'||
            dataType == "classified") && (
              <td
                style={{
                  minWidth: "180px",
                  fontSize: "11px",
                  fontWeight: "600",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  position: "sticky",
                  left: "0px",
                  backgroundColor: "white",
                  zIndex: 2,
                }}
              >
                {row?.classification ? row?.classification : ""}
              </td>
            )}
          {(dataType == "classified"||dataType==='summary') &&
            currentTab == CURRENT_TAB_TYPE.bank_statements &&
            row?.amounts?.map((item, index) => (
              <td
                key={index}
                style={{
                  minWidth: "120px",
                  fontSize: "11px",
                  fontWeight: "600",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  position: "sticky",
                  left: "0px",
                  backgroundColor: "white",
                }}
              >
                {item.amount ? item.amount : 0}
              </td>
            ))}

          {((currentTab === CURRENT_TAB_TYPE.GST &&
            dataType !== CURRENT_TAB_DATA_TYPE.gst &&
            dataType !== CURRENT_TAB_DATA_TYPE.spread &&
            dataType !== CURRENT_TAB_DATA_TYPE.pivot) ||
            currentTab === CURRENT_TAB_TYPE.contracts) &&
            underwritingDataHeaders.map((item, index) => (
              <td
                key={index}
                style={{
                  minWidth: "120px",
                  fontSize: "11px",
                  fontWeight: "600",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  position: "sticky",
                  left: "0px",
                  backgroundColor: "white",
                }}
              >
                { item.key === "frequency" && editContracts ? (
                  <select
                    className="underwriting-select"
                    value={editContractFrequency}
                    onChange={(e) => {
                      handleContractsChange(e.target.value, editContractStatus);
                    }}
                  >
                    {contractFrequencyArr.map((obj) => (
                      <option value={obj.value} key={obj.value}>
                        {obj.label}
                      </option>
                    ))}
                  </select>
                ) : item.key === "amount" && editContracts ? (
                  <input 
                        className="underwriting-select"
                        value={editContractAmount}
                        onChange={(e) => {
                          handleAmountChange(e.target.value);
                      }}>{item.value}
                    </input>
                )  :  item.key === "adminStatus" && editContracts ? (
                  <select
                    className="underwriting-select"
                    value={editContractStatus}
                    onChange={(e) => {
                      handleContractsChange(
                        editContractFrequency,
                        e.target.value
                      );
                    }}
                  >
                    {contractAdminStatusArr.map((obj) => (
                      <option value={obj.value} key={obj.value}>
                        {obj.label}
                      </option>
                    ))}
                  </select>
                ) : item.key === "excelDownloadLink" && !!(row?.excelDownloadLink) ? (
                    <Button onClick={() => handleDownloadExcelLink(row?.excelDownloadLink)}>
                      <i className="fas fa-download" />
                    </Button>
                ) : row[item.key] ? (
                  row[item.key]
                ) : (
                  ""
                )
                }
              </td>
            ))
          }
          {dataType == "gst" &&
            currentTab == CURRENT_TAB_TYPE.GST &&
            underwritingDataHeaders.map((item, index) => (
              <td
                key={index}
                style={{
                  minWidth: "120px",
                  fontSize: "11px",
                  fontWeight: "600",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  position: "sticky",
                  left: "0px",
                  backgroundColor: "white",
                }}
              >
                {renderGstRowData(item)}
              </td>
            ))}

          {currentTab === CURRENT_TAB_TYPE.GST &&
            (dataType === CURRENT_TAB_DATA_TYPE.pivot ||
              dataType === CURRENT_TAB_DATA_TYPE.spread) && (
              <td
                style={{
                  minWidth: "120px",
                  fontSize: "11px",
                  fontWeight: "600",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  position: "sticky",
                  left: "0px",
                  backgroundColor: "white",
                  zIndex: 2
                }}
              >
                {row?.name ? row?.name : ""}
              </td>
            )}

          {currentTab === CURRENT_TAB_TYPE.GST &&
            (dataType === CURRENT_TAB_DATA_TYPE.pivot ||
              dataType === CURRENT_TAB_DATA_TYPE.spread) &&
              headerData?.map((item, index) => (
              <td
                key={index}
                style={{
                  minWidth: "120px",
                  fontSize: "11px",
                  fontWeight: "600",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  position: "sticky",
                  left: "0px",
                  backgroundColor: "white",
                }}
              >
                {periodToAmount?.[item] ?? 0}
              </td>
            ))}

          {/* masterpiece end */}
          {(editClassification ||
            (dataType === "raw" &&
              currentTab != CURRENT_TAB_TYPE.bank_statements)) && (
            <td
              style={{
                minWidth: "120px",
                fontSize: "11px",
                fontWeight: "600",
                paddingTop: "7px",
                paddingBottom: "7px",
                position: "sticky",
                left: "120px",
                backgroundColor: "white",
              }}
            >
              {row?.legend ? row?.legend : ""}
            </td>
          )}
          {(editClassification ||
            (dataType === "raw" &&
              currentTab != CURRENT_TAB_TYPE.bank_statements)) && (
            <td
              style={{
                minWidth: "120px",
                fontSize: "11px",
                fontWeight: "600",
                paddingTop: "7px",
                paddingBottom: "7px",
                position: "sticky",
                left: "240px",
                backgroundColor: "white",
              }}
            >
              {row?.subLegend ? row?.subLegend : ""}
            </td>
          )}
          {/* ------------------------------------------------------------------------- */}
          {/* Second Column */}
          {dataType === "raw" &&
          currentTab !== CURRENT_TAB_TYPE.bank_statements && 
          !editClassification ? (
            row?.ledger_classification && (
              <td
                style={{
                  position: "sticky",
                  left: "360px",
                  backgroundColor: "white",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                }}
              >
                <span
                  className="sub-classification-data"
                  style={{ minWidth: "130px" }}
                >
                  {row?.ledger_classification
                    ? row?.ledger_classification.name
                    : "null"}
                </span>
              </td>
            )
          ) :  currentTab !== CURRENT_TAB_TYPE.GST && editClassification ? (
            <td
              style={{
                paddingTop: "7px",
                paddingBottom: "7px",
                position: "sticky",
                left: "360px",
                backgroundColor: "white",
              }}
            >
              <select
                value={classificationID}
                onChange={(e) => {
                  handleChange(
                    "classificationId",
                    Number(e.target.value),
                    row?.classificationId
                  );
                  handleChange("subClassificationId", Number("0"));
                }}
                placeholder="Name of Bank"
                style={
                  classificationID === Number("1")
                    ? {
                        width: "125px",
                        border: "0.1px solid red",
                        borderRadius: "4px",
                        color: "grey",
                      }
                    : { width: "125px", borderRadius: "4px" }
                }
                name=""
                id=""
              >
                {underwritingClassificationDataState.map(
                  (item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  )
                )}
              </select>
            </td>
          ) : (
            dataType === "classified" && (
              <td
                style={{
                  position: "sticky",
                  left: "121px",
                  backgroundColor: "white",
                  textAlign: "center",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  fontSize: "14px",
                  color: unclassifiedColor,
                }}
              >
                {underwritingClassificationDataState[
                  underwritingClassificationDataState.findIndex(
                    (data) => {
                      return data.id === classificationID;
                    }
                  )
                ]?.sign === "MINUS"
                  ? "-"
                  : underwritingClassificationDataState[
                      underwritingClassificationDataState.findIndex(
                        (data) => {
                          return data.id === classificationID;
                        }
                      )
                    ]?.sign === "PLUS"
                  ? "+"
                  : underwritingClassificationDataState[
                      underwritingClassificationDataState.findIndex(
                        (data) => {
                          return data.id === classificationID;
                        }
                      )
                    ]?.sign === null
                  ? "+"
                  : ""}
              </td>
            )
          )}
          {/* Third Column */}
          {dataType === "raw" &&
          !editClassification &&
          currentTab != CURRENT_TAB_TYPE.bank_statements ? (
            <td
              style={{
                position: "sticky",
                left: "514px",
                backgroundColor: "white",
                paddingTop: "7px",
                paddingBottom: "7px",
              }}
            >
              <span
                className="sub-classification-data"
                style={{ minWidth: "130px" }}
              >
                {row?.ledger_sub_classification
                  ? row?.ledger_sub_classification.name
                  : "Unclassified"}
              </span>
            </td>
          ) : (
            currentTab !== CURRENT_TAB_TYPE.GST && editClassification && (
              <td
                style={{
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  position: "sticky",
                  left: "509px",
                  backgroundColor: "white",
                }}
              >
                <select
                  className="underwriting-select"
                  value={subClassificationID}
                  onChange={(e) => {
                    e.target.value !== "add" &&
                      handleChange(
                        "subClassificationId",
                        Number(e.target.value)
                      );
                    onChangeSelect();
                  }}
                  placeholder="Name of Bank"
                  name=""
                  style={
                    subClassificationID === Number("0")
                      ? {
                          width: "125px",
                          border: "0.1px solid red",
                          borderRadius: "4px",
                          color: "grey",
                        }
                      : { width: "125px", borderRadius: "4px" }
                  }
                  id={`selectBox-${row?.id}`}
                >
                  <option value={"add"} key={"add-key"} className="add-new">
                    {"+ Add New"}
                  </option>
                  <option
                    value={Number("0")}
                    key={"unclassified-key"}
                    className="add-new"
                  >
                    Unclassified
                  </option>
                  {underwritingClassificationDataState[
                    underwritingClassificationDataState.findIndex(
                      (data) => {
                        return data.id === classificationID;
                      }
                    )
                  ]?.ledger_sub_classifications.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </td>
            )
          )}
          {/* All other columns */}
          {currentTab !== CURRENT_TAB_TYPE.invoices &&
            currentTab !== CURRENT_TAB_TYPE.bank_statements &&
            row?.mis_amounts?.map((item, index) => {
              return (
                <td
                  key={index}
                  style={{
                    textAlign: "left",
                    paddingTop: "7px",
                    paddingBottom: "7px",
                    color: unclassifiedColor,
                  }}
                >
                  {item.amount}
                </td>
              );
            })}
        </tr>
      ) : (
        <tr ref={bottomRef}>
          {dataType === "classified" || dataType === "spread" ? (
            <td
              style={{
                minWidth: "300px",
                fontSize: "11px",
                fontWeight: "600",
                paddingTop: "7px",
                paddingBottom: "7px",
                left: "0px",
                backgroundColor: "white",
                zIndex: "1",
                position: "sticky",
              }}
            >
              {row?.name}
            </td>
          ) : (
            <td
              style={{
                minWidth: "300px",
                fontSize: "11px",
                fontWeight: "600",
                paddingTop: "7px",
                paddingBottom: "7px",
                left: "0px",
                backgroundColor: "white",
                zIndex: "1",
              }}
            >
              {row?.name}
            </td>
          )}
          {dataType !== "raw" ? (
            row?.[
              dataType === "classified"
                ? "invoice_amounts"
                : "invoice_spreads"
            ]?.map((a, index) => (
              <td
                key={`pivot-row-${index}`}
                style={{
                  textAlign: "left",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  color: unclassifiedColor,
                }}
              >
                {a.amount}
              </td>
            ))
          ) : (
            <>
              <td
                style={{
                  minWidth: "150px"
                }}
              >
                {editInvoiceFrequency ? (
                  <div>
                    <div className={styles.SearchBar}>
                      <span data-bs-toggle="tooltip" data-bs-html="true" title={invoicesRationale}>
                        <input
                          required
                          className="text-truncate"
                          placeholder="Search company here"
                          value={invoicesRationale}
                          onChange={e => handleInvoicesRationaleChange(e.target.value)}
                        />
                      </span>
                      <span className="cp" onClick={() => handleInvoicesRationaleChange(invoicesRationale)}>
                        {invoicesRationale in invoicesNameListObj ? (
                          <img alt="" src="../../../../../assets/expand-more.svg"/>
                        ) : (
                          <img alt="" src="../../../../../assets/search.svg"/>
                        )}
                      </span>
                    </div>
                    {invoicesRationale?.length > 0 && showRationaleOptions && !isEmpty(invoicesNameListObj) && (
                      <div className={styles.DropdownOptions}>
                        {Object.keys(invoicesNameListObj).map((name, idx) => {
                          if (name?.toLowerCase()?.includes(invoicesRationale?.toLowerCase())) return (
                            <div key={idx} className="cp" onClick={() => updateInvoicesRationale(name)}>{name}</div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                ) : (
                  <span style={{
                    fontSize: "11px",
                    fontWeight: "600",
                    paddingTop: "7px",
                    paddingBottom: "7px",
                    position: "sticky",
                    left: "0px",
                    backgroundColor: "white",
                    textAlign: "center",
                  }}>{row?.rationale ?? '-'}</span>
                )}
              </td>
              <td
                style={{
                  minWidth: "100px",
                  fontSize: "11px",
                  fontWeight: "600",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  position: "sticky",
                  left: "0px",
                  backgroundColor: "white",
                  textAlign: "center",
                }}
              >
                {row?.invoiceDate}
              </td>
              <td
                style={{
                  minWidth: "100px",
                  fontSize: "11px",
                  fontWeight: "600",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  position: "sticky",
                  left: "0px",
                  backgroundColor: "white",
                }}
              >
                {row?.amount}
              </td>
              <td
                style={{
                  minWidth: "100px",
                  fontSize: "11px",
                  fontWeight: "600",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  position: "sticky",
                  left: "0px",
                  backgroundColor: "white",
                  textAlign: "center",
                }}
              >
                {row?.currency}
              </td>
              <td
                style={{
                  minWidth: "100px",
                  fontSize: "11px",
                  fontWeight: "600",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  position: "sticky",
                  left: "0px",
                  backgroundColor: "white",
                  textAlign: "center",
                }}
              >
                {row?.startDate}
              </td>
              <td
                style={{
                  minWidth: "100px",
                  fontSize: "11px",
                  fontWeight: "600",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  position: "sticky",
                  left: "0px",
                  backgroundColor: "white",
                  textAlign: "center",
                }}
              >
                {row?.endDate}
              </td>
              <td
                style={{
                  minWidth: "100px",
                  fontSize: "11px",
                  fontWeight: "600",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  position: "sticky",
                  left: "0px",
                  backgroundColor: "white",
                }}
              >
                {row?.paymentAmount}
              </td>
              <td
                style={{
                  minWidth: "100px",
                  fontSize: "11px",
                  fontWeight: "600",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  position: "sticky",
                  left: "0px",
                  backgroundColor: "white",
                  textAlign: "center",
                }}
              >
                {editInvoiceFrequency ? (
                  <select
                    className="underwriting-select"
                    value={frequency}
                    onChange={(e) => handleFrequencyChange(e.target.value)}
                    placeholder="Frequency"
                  >
                    <option value={"MONTHLY"}>MONTHLY</option>
                    <option value={"QUARTERLY"}>QUARTERLY</option>
                    <option value={"HALF_YEARLY"}>HALF_YEARLY</option>
                    <option value={"YEARLY"}>YEARLY</option>
                  </select>
                ) : (
                  <span
                    className="sub-classification-data"
                    style={{ minWidth: "130px" }}
                  >
                    {row?.frequency}
                  </span>
                )}
              </td>
              <td
                style={{
                  minWidth: "100px",
                  fontSize: "11px",
                  fontWeight: "600",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  position: "sticky",
                  left: "0px",
                  backgroundColor: "white",
                  textAlign: "center",
                }}
              >
                {row?.frequencyRemarks ?? "-"}
              </td>
              <td
                style={{
                  minWidth: "100px",
                  fontSize: "11px",
                  fontWeight: "600",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  position: "sticky",
                  left: "0px",
                  backgroundColor: "white",
                  textAlign: "center",
                }}
              >
                {row?.itemDescription ?? "-"}
              </td>
            </>
          )}
        </tr>
      )}
    </>
  );
}

function MyVerticallyCenteredModal({ newSubclassification, setNewSubclassification, postUnderwritingCreatedSubclassification, onHide, ...props }) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add New Sub-Classification
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <input
            type="text"
            placeholder="New Subclassification"
            value={newSubclassification}
            onChange={(e) => setNewSubclassification(e.target.value)}
            className={"small-fonts edit-dropdown dropdown-width2"}
          />
        </div>
        <Button
          onClick={() => {
            postUnderwritingCreatedSubclassification();
            onHide();
          }}
          className="mt-3"
        >
          Add New
        </Button>
      </Modal.Body>
    </Modal>
  );
}

function changeFunc(id) {
  var selectBox = document.getElementById(`selectBox-${id}`);
  var selectedValue = selectBox.options[selectBox.selectedIndex].value;
  if (selectedValue === "add") {
    return true;
  } else {
    return false;
  }
}
