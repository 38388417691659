import React from 'react';
import styles from './RecurTable.module.scss';
import { Player } from '@lottiefiles/react-lottie-player';

export default function RecurTable({ headers, renderRows, changeColorOnHover,loading,style,headerStyle,additionalRef }) {
    const { TableCard, TableCardHover, PaddingL_30 } = styles;
    const renderHeader = (header, index) => {
        const { jsx } = header;
        // const {
        //     canBeFiltered,
        //     headerText,
        //     labelText,
        //     isSortAllowed,
        //     handleSort,
        //     sorting_order,
        //     sortBy,
        //     sortKeyName,
        //     isFilterAllowed,
        //     filter_type,
        //     handleSearchFilter,
        //     inputSelectorHandler,
        //     elementsArray,
        //     termElementsArray,
        //     page,
        //     handledatePicker,
        //     date,
        //     handleSliderFilter,
        //     minVal,
        //     maxVal,
        //     currentMin,
        //     currentMax,
        //     keyName,
        //     keyNameUnit,
        //     value,
        //     status,
        //     statusUnit,
        //     resetTerm,
        //     isResetingAllFilters,
        //     setIsResetingAllFilters,
        //     jsx,
        // } = header;
        // if (canBeFiltered) {
        //     return (
        //         <FilterHeaderComponent
        //             class={index === 0 ? PaddingL_15 : null}
        //             key={index}
        //             headerText={headerText}
        //             labelText={labelText}
        //             isSortAllowed={isSortAllowed}
        //             handleSort={handleSort}
        //             sorting_order={sorting_order}
        //             sortBy={sortBy}
        //             investeeOrgId={investeeOrgId ? investeeOrgId : null}
        //             sortKeyName={sortKeyName}
        //             isFilterAllowed={isFilterAllowed}
        //             filter_type={filter_type}
        //             handleSearchFilter={handleSearchFilter}
        //             inputSelectorHandler={inputSelectorHandler}
        //             elementsArray={elementsArray}
        //             termElementsArray={termElementsArray}
        //             page={page}
        //             handledatePicker={handledatePicker}
        //             date={date}
        //             handleSliderFilter={handleSliderFilter}
        //             minVal={minVal}
        //             maxVal={maxVal}
        //             currentMin={currentMin}
        //             currentMax={currentMax}
        //             keyName={keyName}
        //             keyNameUnit={keyNameUnit}
        //             value={value}
        //             status={status}
        //             statusUnit={statusUnit}
        //             resetTerm={resetTerm}
        //             isResetingAllFilters={isResetingAllFilters}
        //             setIsResetingAllFilters={setIsResetingAllFilters}
        //         />
        //     );
        // }
        return (
            <th className={index === 0 ? PaddingL_30 : null} key={index}>
                {jsx}
            </th>
        );
    };

    return (
        <div style={style} className={changeColorOnHover ? TableCardHover : TableCard}>
            <table>
                <thead>
                    <tr style={headerStyle}>
                        {headers && headers.map((header, index) => renderHeader(header, index))}
                    </tr>
                </thead>
                {loading?
                <Player
                    autoplay
                    loop
                    src='https://fl-fe-assets.s3.ap-south-1.amazonaws.com/json/tableLoader.json'
                    style={{ height: '500px', width: '500px',position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)' }}
                >
                </Player>
                :renderRows}
                {typeof additionalRef === 'function' && <div ref={additionalRef} />}
            </table>
        </div>
    );
}
