import React from 'react';
import { TOTAL_COUNT, VERIFIED_COUNT } from '../consts/consts';
import styles from '../DocumentVault.module.scss';

export default function DocumentVaultCards({ item }) {
    const {
        TopCard,
        TopCardHeader,
        GreenText,
        GreyBigText,
        GreySmallText
    } = styles;

    const getGreyBigText = (item) => {
        if (VERIFIED_COUNT in item && TOTAL_COUNT in item) return '/' + (item?.[TOTAL_COUNT] ?? 0);
        return item?.value;
    }

    const isDifferentType = (item) => {
        if (VERIFIED_COUNT in item && TOTAL_COUNT in item) return true;
        return false;
    }

    return (
        <div className={`p-3 dflex flex-column ${TopCard}`}>
            <div className={TopCardHeader}>{item.label}</div>
            <div className='dflex-ac mt-2'>
                {isDifferentType(item) && (
                    <span className={GreenText}>{item?.[VERIFIED_COUNT] ?? 0}</span>
                )}
                <span className={GreyBigText}>{getGreyBigText(item)}</span>
                {isDifferentType(item) && (
                    <span className={GreySmallText}>documents verified</span>
                )}
            </div>
        </div>
    )
}