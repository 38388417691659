import React from "react";
import {
  CURRENT_TAB_TYPE,
  CURRENT_TAB_DATA_TYPE,
  SORT_COL_DIRECTION,
  DATA_TYPE_TABS
} from "../../../../../../../../../../../../common/ENUM";
import styles from "../../../bankstats.module.css";

export default function UnderwritingTableHeader(props) {
  const requestSort = (key) => {
    let direction = SORT_COL_DIRECTION.asc;
    if (
      props.sortConfig?.key === key &&
      props.sortConfig?.direction === SORT_COL_DIRECTION.asc
    ) {
      direction = SORT_COL_DIRECTION.dsc;
    }
    props.setSortConfig({ key, direction });
  };

  const renderHeaders = (keyName, name) => {
    if (props.currentTab === CURRENT_TAB_TYPE.bank_statements) {
      if (keyName === "amount" || keyName === "date" || keyName === "rationale") {
        return (
          <button
            type="button"
            onClick={() => requestSort(keyName)}
            className={styles.amountSort}
          >
            {name} &#8645;
          </button>
        );
      }
    }
    return name;
  }

  return (
    <thead
      className="finance-table-head p-20 underwritting-table"
      style={{
        position: "sticky",
        top: "0px",
        backgroundColor: "#fff",
        height: "40px",
        zIndex: "2",
      }}
    >
      {/* Raw Or Edit Tab */}
      {(props.editClassification ||
        props.dataType === "raw" ||
        props.dataType === "pdf" ||
        props.dataType === CURRENT_TAB_DATA_TYPE.excel_report ||
        props.currentTab === CURRENT_TAB_TYPE.GST ||
        props.currentTab === CURRENT_TAB_TYPE.contracts) && (
        <tr className="right-click-filter" style={{ position: "relative" }}>
          {props.underwritingDataHeaders?.map((item, index) => {
            const paddingLeft =
              item.name === "Name" &&
              props.currentTab !== CURRENT_TAB_TYPE.GST &&
              props.currentTab !== CURRENT_TAB_TYPE.contracts
                ? "75px"
                : item.name === "Particular"
                ? "15px"
                : item.name === "Legend"
                ? "25px"
                : item.name === "Sub-Legend"
                ? "15px"
                : item.name === "Classification" &&
                  props.currentTab != CURRENT_TAB_TYPE.bank_statements
                ? "37px"
                : item.name === "Sub-Classification"
                ? "20px"
                : "15px";

            const left =
              item.name === "Name"
                ? "0px"
                : item.name === "Particular"
                ? "0px"
                : item.name === "Legend"
                ? "120px"
                : item.name === "Sub-Legend"
                ? "240px"
                : item.name === "Classification" &&
                  props.currentTab != CURRENT_TAB_TYPE.bank_statements
                ? "360px"
                : item.name === "Sub-Classification" && props.editClassification
                ? "509px"
                : item.name === "Sub-Classification"
                ? "514px"
                : "";

            const bgColor =
              item.name === "Name" ||
              item.name === "Particular" ||
              item.name === "Legend" ||
              item.name === "Sub-Legend" ||
              item.name === "Classification" ||
              item.name === "Sub-Classification"
                ? "white"
                : null;

            const zIndex =
              item.name === "Name" ||
              item.name === "Particular" ||
              item.name === "Legend" ||
              item.name === "Sub-Legend" ||
              item.name === "Classification" ||
              item.name === "Sub-Classification"
                ? "2"
                : "1";

            return (
              <th
                key={index}
                style={{
                  width: "",
                  paddingLeft: paddingLeft,
                  verticalAlign: "inherit",
                  fontSize: "12.2px",
                  fontWeight: "600",
                  position: "sticky",
                  borderTop: "1px solid rgb(233, 233, 233)",
                  borderBottom: "1px solid rgb(233, 233, 233)",
                  left: left,
                  backgroundColor: bgColor,
                  zIndex: zIndex,
                }}
              >
                {renderHeaders(item.key, item.name)}
              </th>
            );
          })}
        </tr>
      )}
      {/* Classifed Tab */}
      {!props.editClassification &&
        (props.dataType === DATA_TYPE_TABS.classified || props.dataType === DATA_TYPE_TABS.spread || props.dataType === DATA_TYPE_TABS.cashflow || props.dataType===DATA_TYPE_TABS.summary) &&
        props.currentTab !== CURRENT_TAB_TYPE.GST && (
          <tr className="right-click-filter">
            {props.underwritingDataHeaders?.map((item, index) => {
              const paddingLeft =
                item.name === "Classification" ? "10px" : "10px";
              const paddingRight =
                item.name === "Classification" ? "30px" : "10px";
              const left =
                item.name === "Classification" || item.name === "Name"
                  ? "0px"
                  : "";
              const bgColor =
                item.name === "Classification" || item.name === "Name"
                  ? "white"
                  : null;
              const zIndex =
                item.name === "Classification" || item.name === "Name"
                  ? "2"
                  : "1";

              return (
                <>
                  <th
                    key={index}
                    style={{
                      width: "",
                      paddingLeft: paddingLeft,
                      paddingRight: paddingRight,
                      verticalAlign: "inherit",
                      fontSize: "12.2px",
                      fontWeight: "600",
                      position: "sticky",
                      left: left,
                      backgroundColor: bgColor,
                      zIndex: zIndex,
                      borderTop: "1px solid rgb(233, 233, 233)",
                      whiteSpace:"nowrap"
                    }}
                  >
                    {item.name}
                  </th>
                  {index === 0 &&
                    props.currentTab !== CURRENT_TAB_TYPE.invoices &&
                    props.currentTab !== CURRENT_TAB_TYPE.bank_statements && 
                    props.currentTab !== CURRENT_TAB_TYPE.debt_schedule && (
                      <th
                        style={{
                          width: "",
                          paddingLeft: "30px",
                          paddingRight: paddingRight,
                          verticalAlign: "inherit",
                          fontSize: "12.2px",
                          fontWeight: "600",
                          position: "sticky",
                          left: "121px",
                          backgroundColor: bgColor,
                          zIndex: zIndex,
                        }}
                      >
                        Sign
                      </th>
                    )}
                </>
              );
            })}
          </tr>
        )}
      {/* {view pdf tab} */}

      {/* {props.dataType === "pdf" ? 
      <tr>
        <td>Account no</td>
      </tr> :
       <></>
       } */}
    </thead>
  );
}
