import React, { useEffect } from 'react';
import { useState } from 'react';
import { COLLECTION_METHODS, SECONDARY_RCM_METHODS} from '../../../../../../../../../../../../../../../constants/enums';
import styles from './MultiSelect.module.scss';

export default function MultiSelect({ options, isDisabled, selectedOptions, onChange,ToggleBtn}) {
    const { checkBox, dropdownOptions, roleLabel, addCollection } = styles;
    const [showOptions, setShowOptions] = useState(false);
    const COLLECTION_NAME = {
        "NACH (Weekly)": "nachWeekly",
        "NACH (Fortnightly)": "nachFortnightly",
        "Razorpay Split": "razorpaySplit",
        "Cashfree Split": "cashfreeSplit",
        "Direct Collection": "directCollection",
        "MAB": "MAB",
        "Coborrower": "Coborrower",
        "Amazon": SECONDARY_RCM_METHODS.Amazon,
        "Swiggy": SECONDARY_RCM_METHODS.Swiggy,
        "Zomato": SECONDARY_RCM_METHODS.Zomato,
        "Shiprocket": SECONDARY_RCM_METHODS.Shiprocket,
        "Flipkart": SECONDARY_RCM_METHODS.Flipkart,
        "Easebuzz": SECONDARY_RCM_METHODS.Easebuzz,
        "Nykaa": SECONDARY_RCM_METHODS.Nykaa,
        "Snapdeal": SECONDARY_RCM_METHODS.Snapdeal,
        "Paytm": SECONDARY_RCM_METHODS.Paytm
    };

    useEffect(() => {
        document.addEventListener('click', closeOptionsIfShown);
        return () => {
            document.removeEventListener('click', closeOptionsIfShown);
        }
    }, []);

    const closeOptionsIfShown = () => {
        setShowOptions(false);
    }

    const handleCheck = (e) => {
        const value = e.target.innerText;
        const index=options.indexOf(value);
        if(index!==-1) options.splice(index,1);
        onChange({ category: COLLECTION_NAME[value],addingRCM:true });
        
    }

    const handleClick = e => {
        e.preventDefault();
        e.stopPropagation();
        if (!isDisabled) setShowOptions(!showOptions);
    }

    return (
        <div>
            <div
                onClick={handleClick}
                className={addCollection}>+ Add Collection Method</div>
            {showOptions && (
                <div className={checkBox}>
                    {Object.keys(options).map((item, index) => (
                            <div
                                className={dropdownOptions}
                                key={index}
                            >
                                <label
                                    htmlFor={index}
                                    className={roleLabel}
                                    onClick={handleCheck}
                                >
                                    {COLLECTION_METHODS[options[item]]}
                                </label>
                            </div>
                        ))}
                </div>
            )}
        </div>
    );
}
