import React from 'react';
import styles from './RenderSingleCategory.module.scss';
import { NEED_INFO_ONBOARDING_STATUS, ONBOARDING_STATUS } from '../../../../../../../../../../../../../../common/ENUM';
import ToggleButton from '../../../../../../../../../../../../../../components/ToggleButton/ToggleButton';

export default function RenderSingleCategory({ keyName, statusInfo, setStatusInfo, isModerating }) {
    const ADD_BANK = "is_add_bank_account";
    const { NEEDINFO, APPROVED, IS_NACH , NEEDINFOOPTIONAL } = ONBOARDING_STATUS;
    const { DynamicValue , toggleContainer } = styles;
    const { category, status, comment, is_nach_enabled } = statusInfo[keyName];

    const handleStatusInfoChange = (val, keyName, statusCategory) => {
        if (isModerating) {
            if (statusCategory === ADD_BANK) {
                const obj = { ...statusInfo };
                obj[keyName].status = val;
                setStatusInfo(obj);
            }
            else if (statusCategory === IS_NACH) {
                const obj = { ...statusInfo };
                obj[keyName].is_nach_enabled = val;
                setStatusInfo(obj);
            }
            else if (statusCategory) {
                const obj = { ...statusInfo };
                if (val) {
                    obj[keyName].status = statusCategory;
                    if (statusCategory === APPROVED && obj[keyName].comment) obj[keyName].comment = "";

                }
                else obj[keyName].status = "";
                setStatusInfo(obj);
            }
            else {
                const obj = { ...statusInfo };
                obj[keyName].comment = val;
                setStatusInfo(obj);
            }
        }
    }

    if (category === ADD_BANK) {
        return (
            <span key={keyName} className='fs12 fw300 w-50 dflex-ac'>
                <input
                    type="checkbox"
                    id='addAccountOb1'
                    className='reviewFinancialCheckbox'
                    checked={status}
                    onChange={e => isModerating && handleStatusInfoChange(e.target.checked, keyName, ADD_BANK)}
                    disabled={!isModerating}
                />
                <label htmlFor='addAccountOb1' className='ml-2'><i>Ask to add another bank account & investments</i></label>
            </span>
        );
    }
    if (isModerating) {
        return (
            <span key={keyName}>
                <div className='dflex-ja'>
                    <div className='fs12 fw500 w-50'>{category}</div>
                    <div className='fs10 fw300 w-50 dflex-ja'>
                        <span className='mr-5 dflex-ac'>
                            <input
                                type='checkbox'
                                className='mr-2 reviewFinancialCheckbox'
                                checked={status === APPROVED}
                                onChange={e => handleStatusInfoChange(e.target.checked, keyName, APPROVED)}
                            />
                            <span><i>Approve</i></span>
                        </span>
                    
        <div className="DocumentVaultSwitch ml-">
            <div className={toggleContainer}>
                <ToggleButton
                    checked={status === NEEDINFOOPTIONAL}
                    onChange={e => handleStatusInfoChange(e.target.checked, keyName, NEEDINFOOPTIONAL)}
                    
                />
            </div>
        </div>
    
                        <span className='dflex-ac'>
                            <input
                                type='checkbox'
                                className='mr-2 reviewFinancialCheckbox'
                                checked={status === NEEDINFO}
                                onChange={e => handleStatusInfoChange(e.target.checked, keyName, NEEDINFO)}
                            />
                            <span><i>Need more Info</i></span>
                        </span>
                    </div>
                </div>
                <input
                    className={DynamicValue}
                    placeholder={NEED_INFO_ONBOARDING_STATUS.includes(status)   && 'Enter Comments'}
                    required
                    disabled={!NEED_INFO_ONBOARDING_STATUS.includes(status) }
                    value={comment}
                    onChange={e => handleStatusInfoChange(e.target.value, keyName)}
                />
                <br /><br />
            </span>
        );
    }
    return (
        <span key={keyName}>
            <div className='dflex-ja'>
                <div className='fs12 fw500 w-50'>{category}</div>
                <div className='fs10 fw300 w-50 dflex-ja'>
                    <span className='dflex-ac'>
                        <input
                            type='checkbox'
                            className='mr-2 reviewFinancialCheckbox'
                            checked={status === APPROVED}
                            disabled
                        />
                        <span><i>Approve</i></span>
                    </span>
                    <div className="DocumentVaultSwitch ml-">
            <div className={toggleContainer}>
                <ToggleButton
                    checked={status === NEEDINFOOPTIONAL}
                    disabled                    
                />
            </div>
        </div>
                    <span className='dflex-ac'>
                        <input
                            type='checkbox'
                            className='mr-2 reviewFinancialCheckbox'
                            checked={status === NEEDINFO}
                            disabled
                        />
                        <span><i>Need more Info</i></span>
                    </span>
                </div>
            </div>
            <input
                className={DynamicValue}
                required
                disabled
                value={comment}
            />
            <br /><br />
        </span>
    );
}
