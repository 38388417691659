import React from "react";
import FilterHeaderComponent from "../../../../../components/FilterHeaderComponent/FilterHeaderComponent";

function TradesTableHeader(props) {
  return (
    <thead className='team-head trade-head no-lb'>
      <tr>
        <th className='pl-2' style={{ position: 'unset' }}>Investor Org</th>
        <th style={{ position: 'unset' }}>Investor User</th>
        <th style={{ position: 'unset' }}>Company Name</th>
        <th style={{ position: 'unset' }}>Contract ID</th>
        <th style={{ position: 'unset' }}>Investment</th>
        <th style={{ position: 'unset' }}>Traded Term</th>
        <th style={{ position: 'unset' }}>Repayments</th>
        <FilterHeaderComponent
          scope="col"
          isFilterAllowed={true}
          headerText={'Repayment Start'}
          labelText={'Filter Repayment Start'}
          filter_type={'datepicker'}
          isSortAllowed={true}
          sorting_order={'desc'}
          handledatePicker = {props.handledatePicker}
          keyName = {props.repaymentStartDateObj.keyName}
          date = {props.repaymentStartDateObj.date}
        />
        <th style={{ position: 'unset' }}>Date Requested</th>
        <FilterHeaderComponent
          isSortAllowed={true}
          sorting_order={'asc'}
          isFilterAllowed={true}
          headerText={'Status'}
          labelText={'Filter Status'}
          filter_type={'selector'}
          inputSelectorHandler = {props.inputSelectorHandler}
          keyName = {props.selectorObjStatus.keyName}
          value = {props.selectorObjStatus.value}
          page = "Trades"
        />
        <th style={{ position: 'unset' }}>Trade ID</th>
        <th style={{ position: 'unset' }}>Order ID</th>
        <th style={{ position: 'unset' }}>Last Updated</th>
        {/* <FilterHeaderComponent
          isSortAllowed={true}
          sorting_order={"desc"}
          isFilterAllowed={true}
          headerText={"Investor Org"}
          labelText={"Search Investor Org"}
          filter_type={"search"}
          handleSearchFilter={props.handleSearchFilter}
          // keyName={props.searchObjInvestorOrg.keyName}
          // value={props.searchObjInvestorOrg.value}
        />
        <FilterHeaderComponent
          isSortAllowed={true}
          sorting_order={"desc"}
          isFilterAllowed={true}
          headerText={"Investor User"}
          labelText={"Search Investor User"}
          filter_type={"search"}
          handleSearchFilter={props.handleSearchFilter}
          // keyName={props.searchObjInvestorUser.keyName}
          // value={props.searchObjInvestorUser.value}
        />
        <th>Company Name</th>
        <FilterHeaderComponent
          isSortAllowed={true}
          sorting_order={"desc"}
          isFilterAllowed={true}
          headerText={"Contract ID"}
          labelText={"Search Contract ID"}
          filter_type={"search"}
          handleSearchFilter={props.handleSearchFilter}
          // keyName={props.searchObjContractID.keyName}
          // value={props.searchObjContractID.value}
        />
        <FilterHeaderComponent
          isFilterAllowed={true}
          headerText={"Investment"}
          labelText={"Filter Investment"}
          filter_type={"range_search"}
          handleSliderFilter={props.handleSliderFilter}
          // minVal={props.sliderObjInvestment.minVal}
          // maxVal={props.sliderObjInvestment.maxVal}
          // currentMin={props.sliderObjInvestment.currentMin}
          // currentMax={props.sliderObjInvestment.currentMax}
          // minValueBetween={props.sliderObjInvestment.minValueBetween}
          // keyName={props.sliderObjInvestment.keyName}
        />
        <FilterHeaderComponent
          isFilterAllowed={true}
          headerText={"Traded Term"}
          labelText={"Filter Payments Term"}
          filter_type={"range_search"}
          handleSliderFilter={props.handleSliderFilter}
          // minVal={props.sliderObjPaymentsTerm.minVal}
          // maxVal={props.sliderObjPaymentsTerm.maxVal}
          // currentMin={props.sliderObjPaymentsTerm.currentMin}
          // currentMax={props.sliderObjPaymentsTerm.currentMax}
          // minValueBetween={props.sliderObjPaymentsTerm.minValueBetween}
          // keyName={props.sliderObjPaymentsTerm.keyName}
        />
        <FilterHeaderComponent
          isFilterAllowed={true}
          headerText={"Repayments"}
          labelText={"Filter Payments"}
          filter_type={"range_search"}
          handleSliderFilter={props.handleSliderFilter}
          // minVal={props.sliderObjPayments.minVal}
          // maxVal={props.sliderObjPayments.maxVal}
          // currentMin={props.sliderObjPayments.currentMin}
          // currentMax={props.sliderObjPayments.currentMax}
          // minValueBetween={props.sliderObjPayments.minValueBetween}
          // keyName={props.sliderObjPayments.keyName}
        />
        <th>Repayment Start</th>
        <FilterHeaderComponent
          isFilterAllowed={true}
          headerText={"Date Requested"}
          labelText={"Filter Date Requested"}
          filter_type={"datepicker"}
          isSortAllowed={true}
          sorting_order={"desc"}
          handledatePicker={props.handledatePicker}
          // date={props.dateObjDateRequested.date}
          // keyName={props.dateObjDateRequested.keyName}
        />

        <FilterHeaderComponent
          isSortAllowed={true}
          sorting_order={"asc"}
          isFilterAllowed={true}
          headerText={"Status"}
          labelText={"Filter Status"}
          filter_type={"selector"}
          inputSelectorHandler={props.inputSelectorHandler}
          // elementsArray={props.selectorObjStatus.elementsArray}
          // keyName={props.selectorObjStatus.keyName}
        />
        <FilterHeaderComponent
          isSortAllowed={true}
          sorting_order={"desc"}
          isFilterAllowed={true}
          headerText={"Trade ID"}
          labelText={"Filter Admin User"}
          filter_type={"search"}
          handleSearchFilter={props.handleSearchFilter}
          // keyName={props.searchObjAdminUser.keyName}
          // value={props.searchObjAdminUser.value}
        />
        <FilterHeaderComponent
          isFilterAllowed={true}
          headerText={"Last Updated"}
          labelText={"Filter Last Updated"}
          filter_type={"datepicker"}
          isSortAllowed={true}
          sorting_order={"desc"}
          handledatePicker={props.handledatePicker}
          // date={props.dateObjLastUpdated.date}
          // keyName={props.dateObjLastUpdated.keyName}
        />
        <th></th>
        <th></th> */}
      </tr>
    </thead>
  );
}

export default TradesTableHeader;
