import React from "react";
import { COLLECTIONS_TAB, NACH_COLLECTIONS_TAB, RECOVERY_TAB } from "./NachCollectionsPage";
export const Tabs = ({
  tab,
  setTab,
}) => {
  const collectionTab = [
    {
      label: "Collections",
      segmentName: COLLECTIONS_TAB,
    },
    {
      label: "Recovery",
      segmentName: RECOVERY_TAB,
    },
    {
      label: "NACH Colllections",
      segmentName: NACH_COLLECTIONS_TAB,
    }
  ];
  return (
    <ul className="nav nav-tabs company-tabs" id="myTab" role="tablist">
      <li style={{ width: "26px" }}></li>
      {collectionTab.map((item, index) => (
        <li
          key={index}
          className={
            tab === item.segmentName ? "nav-item bgclr3 color9" : "nav-item"
          }
          onClick={() => setTab(item.segmentName)}
        >
          <button style={{width: item.segmentName=== NACH_COLLECTIONS_TAB ? "120px":""}} className={tab === item.segmentName ? "color9":""}>
            {item.label}
          </button>
        </li>
      ))}
    </ul>
  );
};
