import dayjs from "dayjs";
import UTC from "dayjs/plugin/utc";
import isToday from "dayjs/plugin/isToday";
import camelCase from "lodash/camelCase";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
  deleteDoc,
  downloadFile,
  fetchDocDetails,
  generateRequestId, getFileNameFromSignedUrl, showNotification
} from "../../../../../../../../../../../util/utility";
import "./ViewDocsModal.scss";
import {
  AUDIT_HANDLER_URI,
 IPA_DOC,
 OVERVIEW_STATUS,
 TENTATIVE_DEAL_TERMS,
 TENTATIVE_DEAL_TERMS_CHECK,
 UPLOADING,
 UPLOAD_IPA,
 kycFolderNames
} from "../../../../../../../../../../../common/ENUM";
import {
  getUserName,
} from "../../../../../../../../../../App/AppReducer";
import {
  WAIVED,
  NOT_WAIVED,
  USER_ID,
  SIGNED,
  SENT_FOR_SIGNING,
  SIGNING,
  FAILED,
  INVESTOR_OFFER_APPROVED,
  SHOW_NOTIFICATION_STATUS,
  FLOW_ID,
  ADDITIONAL_DEAL_DOC_CATEGORY_ID,
  ZEUS_EMAIL_KYC_AUDIT_TRAIL,
  DOC_SERVICE_RESPONSE
} from "../../../../../../../../../../../constants/enums";
import {
  totRecurFeeUpdate,
  updateIndicativeTerms,
} from "../../../../../../../../../../../common/ApiHelpers";
import storage from "../../../../../../../../../../../util/storageService";
import _ from "lodash";
import TransactionDocuments from "./TransactionDocuments";
import { getInvesteesOrganization } from "../../../../../../../../../AdminReducers";
import callApi from "../../../../../../../../../../../util/apiCaller";
import { ipaStatus } from "../../../../../../../../../../../constants/commonConstants";


dayjs.extend(isToday);
dayjs.extend(UTC);



export default function ViewIndicativeTerms({
  investor,
  fetchDeals,
}) {
  const {
    investor_organization_id,
    offer_id,
    tentativeDealSize,
    tentativeFinancingStructure,
    tentativeTenure,
    rateOfInterest,
    securityOthersComments,
    recurFee,
    isRecurFeeWaived,
    termsLastReviewedByDate,
    termsLastReviewedBy,
    investorProcessingFee,
    totId,
    fixedDeposit,
    debtDocuments,
    sharedIpaWithCompany,
    debtSanctionComplete
  } = investor;
  const sharing='Sharing...';
  const shareIPAWithCompanyText='Share IPA with Company';
  const [dealEditing, setDealEditing] = useState(false);
  const [checkOfferStatus, setCheckOfferStatus] = useState(true)
  const [checkIfDealPassed, setCheckIfDealPassed] = useState(false)
  const [checkIfDealSanctioned, setCheckIfDealSanctioned] = useState(false)
  const [tentativeProperties, setTentativeProperties] = useState({});
  const [feeProperties, setFeeProperties] = useState({})
  const userName = useSelector((state) => getUserName(state));
  const userId = storage.get(USER_ID);
  const [ipaDoc, setIpaDoc] =useState({});
  const [uploading, setUploading] = useState({
    IPA_DOC: false,
  });
  const [isShareIPAButtonClicked,setIsShareIPAButtonClicked]= useState(false);

  const investeeOrgData = useSelector(getInvesteesOrganization);
  const investeeOrgName = investeeOrgData?.name;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(tentativeFinancingStructure ?? []);
  const dropdownRef = useRef(null);
  const options = [
    "Term Loan",
    "Working Capital Demand Loan",
    "Sales Invoice Discounting",
    "Purchase Invoice Discounting",
    "Overdraft",
    "Drop Line Over Draft",
    "Venture Debt",
    "Operating Lease",
    "Vendor Financing",
    "Credit Card",
    "Loan Against Property"
  ];

  const handleClickOutside = (event) => {
    if (isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    setTentativeProperties({
      ...tentativeProperties,
      tentativeFinancingStructure: selectedOptions ?? [],
    });
  }, [selectedOptions]);

  const handleCheckboxChange = (option) => {
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.includes(option)
        ? prevSelectedOptions.filter((o) => o !== option)
        : [...prevSelectedOptions, option]
    );
  };

  const handleSelectAllChange = () => {
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.length === options.length ? [] : options
    );
  };

  useEffect(() => {  
    setPropsState();
    if(investor?.debtSanctionComplete === "Complete") setCheckIfDealSanctioned(true)
    if(investor.offer_status === OVERVIEW_STATUS.passed) setCheckIfDealPassed(true)
    if(investor.offer_status === INVESTOR_OFFER_APPROVED){
      setCheckOfferStatus(false)
      if(investor.ipaDoc?.url){
        saveIpaDoc(investor.ipaDoc)
      }
      if(!investor.ipaDoc){
        setIpaDoc(null)
      }
    }
  }, [investor]);


  async function saveIpaDoc(doc){
        const response= await fetchDocDetails(doc.url); 
    const fileName = getFileNameFromSignedUrl(response.url,kycFolderNames.DEBT_ADDN_DOCS);
    const {_id} = doc
    const url = doc.url
    setIpaDoc({url,fileName,_id});
      }
  
  function setPropsState() {
    setTentativeProperties(() => ({
      tentativeDealSize: tentativeDealSize,
      tentativeTenure: tentativeTenure,
      rateOfInterest: rateOfInterest,
      tentativeFinancingStructure: tentativeFinancingStructure,
      securityOthersComments: securityOthersComments,
      investorProcessingFee: investorProcessingFee,
      fixedDeposit : fixedDeposit,
      termsLastReviewedBy: termsLastReviewedBy ? termsLastReviewedBy : "-",
      termsLastReviewedByDate: termsLastReviewedByDate ? dayjs(termsLastReviewedByDate)
        .utc()
        .format("DD/MM/YYYY") : "-"
    }));
    setFeeProperties(() => ({
      recurFee: recurFee,
      isRecurFeeWaived: isRecurFeeWaived,
    }));
  }


  const renderDealStatus = () => {
    if (
      investor?.sanction_status === SIGNED &&
      investor?.mfa_status === SIGNED
    ) {
      return <div className="signed">Active</div>;
    }
    if (
      investor?.sanction_status === SENT_FOR_SIGNING ||
      investor?.sanction_status === SIGNING ||
      investor?.mfa_status === SENT_FOR_SIGNING ||
      investor?.mfa_status === SIGNING
    )
      return <div className="signing">Signing</div>;
    if (
      investor?.sanction_status === FAILED ||
      investor?.mfa_status === FAILED
    ) {
      <div className="sent">failed</div>;
    }
    return <div className="notSent">Not Sent</div>;
  };
  const renderIPAStatus =()=>{
    if(sharedIpaWithCompany===ipaStatus.SHARED){
      return <div className="signed">Sent</div>;
    }
    else if(sharedIpaWithCompany===ipaStatus.ACCEPTED){
     return  <div className="signed">Accepted</div>;
    }
    else{
     return  <div className="notSent">Not Sent</div>;
    }
  }

  const getInvestorStatus = () => {
    if (
      investor.offer_status === INVESTOR_OFFER_APPROVED &&
      investor?.investor_deal_status
    )
      return investor.investor_deal_status;
    return investor.offer_status;
  };

  const handleEditClick = () => {
    setDealEditing(true);
  };

  const handleCancelClick = () => {
    setDealEditing(false);
    setPropsState();
  };

  const exitSubmit = () => {
    setDealEditing(false);
  };


  const handleTentaiveInputChange = (e) => {

    const value = e.target.type === 'number' ? +(e.target.value) : e.target.value;
    setTentativeProperties({
      ...tentativeProperties,
      [e.target.name]: value,
    });

  };

  function inputDataCheck(data) {
    for (const key in data) {
      if(key === TENTATIVE_DEAL_TERMS_CHECK[key]){
        if(data[key] === null){
          showNotification(SHOW_NOTIFICATION_STATUS.WARNING, `Incorrect data for ${TENTATIVE_DEAL_TERMS[key]}`);
          return false
        }
      }   
    }
    return true
  }

  const handleFeeInputChange = (e) => {
    setFeeProperties({
      ...feeProperties,
      [e.target.name]: +e.target.value,
    });
  };

  const handleFinanaceOptionChange = (e) => {
    setTentativeProperties({
      ...tentativeProperties,
      tentativeFinancingStructure: e.target.value,
    });
  };
  const handleFeeTypeOptionChange = (e) => {
    setFeeProperties({ ...feeProperties, isRecurFeeWaived: e.target.value });
  };

  const handleSubmitRecurFee = async () => {
    let requestId = generateRequestId();
    let existingState = {
      recurFee: recurFee,
      isRecurFeeWaived: isRecurFeeWaived
    };
    let newState = {
      recurFee: feeProperties.recurFee,
      isRecurFeeWaived: feeProperties.isRecurFeeWaived,
    };
    let data = {
      totId: totId,
      recurFee: feeProperties.recurFee,
      isRecurFeeWaived: feeProperties.isRecurFeeWaived,
      requestId: requestId,
      auditHandlerReqDto: {
        requestIdForAudit: requestId,
        auditData: {
          changeOwner: userId,
          orgId: investor_organization_id,
          existingState: {},
          newState: {},
          requestUri: AUDIT_HANDLER_URI.TOT_FEE_URI,
          changeDate: new Date(),
        },
      },
    };

    if (!_.isEqual(existingState, newState)) {
      if (_(newState.recurFee) > 0) {
        data.auditHandlerReqDto.auditData.existingState = existingState;
        data.auditHandlerReqDto.auditData.newState = newState;
        const result = await totRecurFeeUpdate({ data });
        if (result) {
          fetchDeals();
        }
      } else {
        showNotification(SHOW_NOTIFICATION_STATUS.WARNING, `Incorrect data for ${TENTATIVE_DEAL_TERMS.recurFee}`);
      }
    } else {
      handleCancelClick()
    }
  };
  const handleSubmitIndicativeTerms = async (isShareIPA=false) => {
    if(isShareIPA){
      setIsShareIPAButtonClicked(true);
    }
    let existingState = {
      tentativeDealSize: tentativeDealSize,
      tentativeTenure: tentativeTenure,
      rateOfInterest: rateOfInterest,
      tentativeFinancingStructure: tentativeFinancingStructure,
      securityOthersComments: securityOthersComments,
      investorProcessingFee: investorProcessingFee,
      fixedDeposit : fixedDeposit,
      ipaDocument :investor.ipaDoc,
    };
    let newState = {
      tentativeDealSize: tentativeProperties.tentativeDealSize,
      tentativeTenure: tentativeProperties.tentativeTenure,
      rateOfInterest: tentativeProperties.rateOfInterest,
      tentativeFinancingStructure:
        tentativeProperties.tentativeFinancingStructure,
      securityOthersComments: tentativeProperties.securityOthersComments,
      investorProcessingFee: tentativeProperties.investorProcessingFee,
      fixedDeposit : tentativeProperties.fixedDeposit,
      ipaDocument : ipaDoc
    };
    let data = {
      tentativeDealSize: tentativeProperties.tentativeDealSize,
      tentativeTenure: tentativeProperties.tentativeTenure,
      rateOfInterest: tentativeProperties.rateOfInterest,
      tentativeFinancingStructure:
        tentativeProperties.tentativeFinancingStructure,
      securityOthersComments: tentativeProperties.securityOthersComments,
      investorProcessingFee: tentativeProperties.investorProcessingFee,
      fixedDeposit : tentativeProperties.fixedDeposit,
      ipaDocument : ipaDoc,
      termsLastReviewedBy: userName,
      offerId: offer_id,
      auditHandlerReqDto: {
        requestIdForAudit: generateRequestId(),
        auditData: {
          changeOwner: userId,
          orgId: investor_organization_id,
          existingState: {},
          newState: {},
          requestUri: AUDIT_HANDLER_URI.INDICATIVE_TERMS,
          changeDate: new Date(),
        },
      },
    };
    if (isShareIPA) {
      data.sharedIpaWithCompany = ipaStatus.SHARED;
      newState.sharedIpaWithCompany = ipaStatus.SHARED;
    }
     const check =  inputDataCheck(newState)
    if (!_.isEqual(existingState, newState) && check) {
      data.auditHandlerReqDto.auditData.existingState = existingState;
      data.auditHandlerReqDto.auditData.newState = newState;
      const result = await updateIndicativeTerms({ data });
      setIsShareIPAButtonClicked(false);
      if (result) {
        onSuccess();
      }
    } else {
      handleCancelClick();
    }
  };

  const onSuccess = () => {
    fetchDeals();
  };
  const onFileChangeCapture = async (e) => {
    const  selectedFile = e.target?.files?.[0]
    setUploading({
        IPA_DOC: true, 
      });
    try{
        selectedFile && await uploadDocument({file : selectedFile, fileDesc :IPA_DOC } ); 
    } finally {
        setUploading({
            IPA_DOC: false, 
          });
  }
  };

  const uploadDocument = async ({ file, flowId = FLOW_ID.TRANSACTION_DOCUMENTS, fileDesc }) => {
    const data = { url: "" };
    const orgName = investeeOrgName.split(" ");
    let investeeOrgNameToSend = orgName[0] + " " + (orgName[1] ?? "");
    investeeOrgNameToSend += "_" + "";
    let categoryId = Number(ADDITIONAL_DEAL_DOC_CATEGORY_ID);
    const signResponse = await callApi(
      "file/signedUrl",
      "post",
      {
        categoryId,
        fileName: file.name,
        investeeOrgName: investeeOrgNameToSend,
      },
      {},
      false,
      false,
      false,
      false,
      false,
      true
    );
    data.url = signResponse.signedUrl;
    const requestOptions = {
      method: "put",
      body: file,
      headers: new Headers({
        "Content-Type": file.type,
      }),
    };

    const uploadResponse = await fetch(data.url, requestOptions);
    const data_to_post = {
      categoryId,
      fileName: file.name,
      fileSizeKb: file.size / 1_000,
      fileType: file.type,
      uploadedBy: userName,
      flowId,
      description: fileDesc,
      emailAddress: ZEUS_EMAIL_KYC_AUDIT_TRAIL,
    };
    const updateResponse = await callApi(
      "file/saveMetadata",
      "post",
      data_to_post,
      {},
      false,
      false,
      false,
      false,
      false,
      true
    );
    if (
      updateResponse?.responseData?.responseCode ===
      DOC_SERVICE_RESPONSE.SUCCESS
    ) {
       const fileMetadata = updateResponse?.fileMetadata;
      setIpaDoc({
        description: fileDesc,
         url: fileMetadata?.id,
         fileName :file.name
      }) 
    }
  };
  const deletingDebtDoc = async (e) => {
    e.preventDefault();
    setIpaDoc(null)
  };

  const getShareIpaDisableStatus = () =>{
     return isShareIPAButtonClicked ||  sharedIpaWithCompany === "ACCEPTED" || debtSanctionComplete === "Complete" || checkIfDealPassed
  }
  return (
    <>
      <div className="dealTermsContainer">
        <div className="mainCard">
          <div className="dealTermsHeader">
            <div>Tentative Deal Terms</div>

                      {!dealEditing ? (
                        <div className="dealTermsHeaderButtons">
                          <div class="lastEdited">
                            Last Edited By{" "}
                            {tentativeProperties.termsLastReviewedBy} on{" "}
                            {tentativeProperties.termsLastReviewedByDate}
                          </div>
                          <div>
                            <button
                              className="blueButton"
                              disabled={checkOfferStatus || checkIfDealPassed || checkIfDealSanctioned}
                              onClick={() => {
                                handleEditClick();
                              }}
                            >
                              <img
                                src="/assets/create.png"
                                alt=""
                                style={{
                                  width: "10px",
                                  margin: "0px 5px 2px 0",
                                }}
                              />
                              <span>Edit Terms</span>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="dealTermsHeaderButtons">
                          <div>
                            <button
                              className="cancelButton"
                              onClick={() => {
                                handleCancelClick();
                              }}
                            >
                              <img
                                src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/letter-x.svg"
                                alt=""
                                style={{
                                  width: "10px",
                                  margin: "0px 5px 2px 0",
                                }}
                              />
                              <span className="editTerms">Cancel</span>
                            </button>
                          </div>

                          <div>
                            <button
                              className="blueButton"
                              onClick={() => {
                                handleSubmitRecurFee();
                                handleSubmitIndicativeTerms();
                                exitSubmit();
                              }}
                            >
                              <img
                                src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/save-icon.svg"
                                alt=""
                                style={{
                                  width: "10px",
                                  margin: "0px 5px 2px 0",
                                }}
                              />
                              <span>Save</span>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>

                    <div class="input-container">
                      <div class="input-right">
                        <div class="label1">
                          <div style={{ display: "flex" }}>
                            <div>Limit (cr)</div>
                            <input
                              type="number"
                              min={0.001}
                              className={
                                dealEditing ? `inputDataActive` : `inputData`
                              }
                              disabled={!dealEditing}
                              id="tentativeDealSize"
                              name="tentativeDealSize"
                              style={{
                                padding: "10px",
                                marginLeft: "81px",
                              }}
                              value={tentativeProperties.tentativeDealSize}
                              onChange={handleTentaiveInputChange}
                            ></input>
                          </div>
                          <div style={{ display: "flex" }}>
                            <div >
                              Tenure (Months)
                            </div>
                            <input
                              className={
                                dealEditing ? `inputDataActive` : `inputData`
                              }
                              disabled={!dealEditing}
                              id="tentativeTenure"
                              name="tentativeTenure"
                              type="number"
                             min={1}
                              style={{
                                padding: "10px",
                                marginLeft: "73px",
                                marginRight: "13px",
                              }}
                              value={tentativeProperties.tentativeTenure}
                              onChange={handleTentaiveInputChange}
                            ></input>
                          </div>
                        </div>
                        <div class="label2">
                          <div style={{ display: "flex" }}>
                            <div>ROI (%)</div>
                            <input
                              className={
                                dealEditing ? `inputDataActive` : `inputData`
                              }
                              disabled={!dealEditing}
                              id="rateOfInterest"
                              name="rateOfInterest"
                              type="number"
                              min={1}
                              max={100}
                              style={{
                                padding: "10px",
                                marginLeft: "85px",
                              }}
                              value={tentativeProperties.rateOfInterest}
                              onChange={handleTentaiveInputChange}
                            ></input>
                          </div>
                          <div style={{ display: "flex" }}>
                            <div>
                              Investor Processing Fee (%)
                            </div>
                            <input
                              id="investorProcessingFee"
                              name="investorProcessingFee"
                              type="number"
                              min={1}
                              max={100}
                              className={
                                dealEditing ? `inputDataActive` : `inputData`
                              }
                              disabled={!dealEditing}
                              style={{
                                padding: "10px",
                                marginLeft: "8px",
                                marginRight: "13px",
                              }}
                              value={tentativeProperties.investorProcessingFee}
                              onChange={handleTentaiveInputChange}
                            ></input>
                          </div>
                        </div>
                        <div class="label3">
                          <div style={{ display: "flex" }}>
                            <label htmlFor="tentativeFinancingStructure">
                              Financing Structure
                            </label>
                            
                              <div
                                className={dealEditing ? `inputDataActive` : `inputData`}
                                style={{ marginLeft: "25px", position: "relative" }}
                                ref={dropdownRef}
                              >
                                <div
                                  onClick={() => dealEditing && setIsOpen(!isOpen)}
                                  className="permissionBox"
                                >
                                  {selectedOptions.length === 0 ? (
                                    <span>No Options Selected</span>
                                  ) : (
                                    <span className="customDropdownText">{selectedOptions.join(', ')}</span>
                                  )}
                                  <img src={isOpen ? "/assets/rcff-down.svg" : "/assets/rcff-up.svg"} alt="" />
                                </div>
                                {isOpen && (
                                  <div className="optionsContainer">
                                    <div className="optionsBox">
                                      <input
                                        type="checkbox"
                                        value="Select All"
                                        checked={selectedOptions.length === options.length}
                                        onChange={handleSelectAllChange}
                                        style={{ alignItems: 'flex-start', width: '16px' }}
                                      />
                                      <label htmlFor="Select All">
                                        <div className="checkboxLabel">Select All</div>
                                      </label>
                                    </div>
                                    {options.map(option => (
                                      <div key={option} className="optionsBox">
                                        <input
                                          type="checkbox"
                                          name="tentativeFinancingStructure"
                                          value={option}
                                          checked={selectedOptions.includes(option)}
                                          onChange={() => handleCheckboxChange(option)}
                                          style={{ alignItems: 'flex-start', width: '16px' }}
                                        />
                                        <label htmlFor={option}>
                                          <div className="checkboxLabel">{option}</div>
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                          </div>
                          <div style={{ display: "flex" }}>
                            <div>
                              Recur Advisory Fee (%)
                            </div>
                            <input
                              id="recurFee"
                              name="recurFee"
                              type="number"
                              min={1}
                              max={100}
                              className={
                                dealEditing ? `inputDataActive` : `inputData`
                              }
                              disabled={!dealEditing}
                              style={{
                                width: "35px",
                                marginLeft: "32px",
                                paddingLeft: "10px",
                              }}
                              value={feeProperties.recurFee}
                              onChange={handleFeeInputChange}
                            ></input>
                            <label htmlFor="isRecurFeeWaived"></label>
                            <select
                              className={
                                dealEditing ? `inputDataActive` : `inputData`
                              }
                              disabled={!dealEditing}
                              id="isRecurFeeWaived"
                              name="isRecurFeeWaived"
                              style={{
                                alignContent: "center",
                                width: "72px",
                                height: "20px",
                                marginLeft: "7px",
                                marginRight: "13px",
                              }}
                              onChange={handleFeeTypeOptionChange}
                              value={feeProperties.isRecurFeeWaived}
                            >
                              <option value={false}>{NOT_WAIVED}</option>
                              <option value={true}>{WAIVED}</option>
                            </select>
                          </div>
                        </div>
                        {/* IPA POP_UP */}
                        <div class="label2">
                          <div style={{ display: "flex" }}>
                            <div>IPA Upload</div>
                            <div className="UploadCardIndicative" style={{ 
                                marginLeft: "70px",
                              }}>
                            <label>
                            <input
                              type="file"
                              hidden
                              disabled={ipaDoc?.url || !dealEditing}
                              className={
                                dealEditing ? `inputDataActive` : `inputData`
                              }
                              onChangeCapture={(e) => onFileChangeCapture(e)}
                            ></input>
                               <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px 0px 0px 0px',
                                justifyContent: 'flex-start',
                            }}>
                            {ipaDoc?.url ?(
                                <img
                                    className="mr-2"
                                    src="/assets/blue-download.svg"
                                    alt=""
                                    style={{ width: '10px' }}
                                      onClick={() => downloadFile({ docId: ipaDoc?.url, fileName: ipaDoc?.fileName })}
                                />
                            ) : (
                                <img
                                    className="mr-2"
                                    src="/assets/cloud_upload.svg"
                                    alt=""
                                    style={{ width: '20px', marginRight: '0px' }}
                                    disabled={!dealEditing}
                                />
                            )}
                            <span
                                  className={`fs10 fw5 truncated-textIPA`}
                                style={{ alignItems: 'center', marginLeft: 'auto' }}>
                                {uploading?.IPA_DOC
                                    ? UPLOADING
                                    : ipaDoc?.fileName
                                    ? ipaDoc?.fileName
                                    : UPLOAD_IPA}
                            </span>
                            {ipaDoc?.url && dealEditing ? (
                                <img
                                    className="mr-2"
                                    src="/assets/clear-grey.svg"
                                    alt=""
                                    style={{ width: '10px' }}
                                    onClick={(e) => deletingDebtDoc(e)}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                            </label>
                            </div>
                          </div>
                          <div style={{ display: "flex"}}>
                            <div style={{ padding : "0px 55px 0px 0px"}}>
                              Fixed Deposit (%)
                              
                            </div>
                            <input
                              id="fixedDeposit"
                              name="fixedDeposit"
                              type="number"
                              min={1}
                              max={100}
                              className={
                                dealEditing ? `inputDataActive` : `inputData`
                              }
                              disabled={!dealEditing}
                              style={{
                                padding: "10px",
                                marginLeft: "8px",
                                marginRight: "13px",
                              }}
                              value={tentativeProperties.fixedDeposit}
                              onChange={handleTentaiveInputChange}
                            ></input>
                          </div>
                        </div>
                        <div class="label4">
                        <div style={{ display: "flex" }}>
                          <div style={{ display: "flex", textWrap: "nowrap", }}>
                            Security Structure
                          </div>
                          <textarea
                            className={
                              dealEditing ? `textAreaActive` : `textArea`
                            }
                            disabled={!dealEditing}
                            id="securityOthersComments"
                            name="securityOthersComments"
                            style={{
                              paddingLeft: "10px",
                              width: "475px",
                              height: "67px",
                              fontSize: "10px",
                              marginRight: "13px",
                              marginLeft: "32px",
                            }}
                            value={tentativeProperties.securityOthersComments}
                            onChange={handleTentaiveInputChange}
                            type="text"
                          ></textarea>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                
      <div className="bottomCardContainer">
      <div
                      style={{
                        width: "100%",
                        padding: "20px",
                        paddingBottom: "20px",
                      }}
                      className="CardBorder mb-0"
                    >
                      <div className="dflexJA fs12">
                        <span>Company Status:</span>
                        <span>{renderIPAStatus()}</span>
                      </div>
                      {(!checkOfferStatus)&&<button
                        className={`shareIPAWithCompany`}
                        disabled= {getShareIpaDisableStatus()}
                        onClick={() => {
                          handleSubmitIndicativeTerms(true);
                        }}
                      >
                      {(isShareIPAButtonClicked)? sharing: shareIPAWithCompanyText}
                      </button>}
                    </div>

        <div className="rightBox dflexJA fs12">
          <span className="ml-2 mr-5">Investor Status:</span>
          <span className={`${camelCase(getInvestorStatus())}`}>
            {getInvestorStatus()}
          </span>
        </div>
      </div>
      {!checkOfferStatus && <TransactionDocuments investor={investor} fetchDeals={fetchDeals} />}
    </>
  );
}