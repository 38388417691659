import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import callApi from '../../../../../../../../../../../util/apiCaller';
import { showNotification } from '../../../../../../../../../../../util/utility';
import _ from 'lodash';

export default function ModerateRowBox({
    investeeOnboardedId,
    fetchMonthlyData,
    fetchDocumentationData,
    dataLength,
    moderateRow,
    setModerateRow,
    price,
    tradingLimit,
    irr,
    contractsCount
}) {
    const emailCheck = price && tradingLimit && irr && contractsCount;
    const { dataType, due_date } = moderateRow;
    const getStatusOptions = () => {
        if (dataType === "monthly") {
            if (dataLength > 1 || due_date !== "Onboarding") {
                return ["Need Information", "Approved","Approved-Runway<3M"];
            }
            else {
                return ["Need Information", "Approved", "Not Approved", "Waitlisted"];
            }
        }
        else if (dataType === "documentation") {
            return ["Need Information", "Approved"];
        }
    }
    const [statusOptions, setStatusOptions] = useState(getStatusOptions());
    const [isModerating, setIsModerating] = useState(false);
    const initialStatusInfo = () => {
        if (due_date === "Documentation") {
            return {
                1: {
                    category: "KYC",
                    status: ""
                },
                2: {
                    category: "Aut. Doc.",
                    status: ""
                },
                3: {
                    category: "Company Aut.",
                    status: ""
                },
                4: {
                    category: "Add'I",
                    status: ""
                }
            }
        }
        else if (due_date === "Onboarding") {
            return {
                1: {
                    category: "MIS",
                    status: ""
                },
                2: {
                    category: "Invoicing",
                    status: ""
                },
                3: {
                    category: "Contracts",
                    status: ""
                },
                4: {
                    category: "Bank Statement",
                    status: ""
                },
                5: {
                    category: "Audited Financials",
                    status: ""
                }
            };
        }
        else {
            return {
                1: {
                    category: "MIS",
                    status: ""
                },
                2: {
                    category: "Invoicing",
                    status: ""
                },
                3: {
                    category: "Contracts",
                    status: ""
                },
                4: {
                    category: "Bank Statement",
                    status: ""
                }
            };
        }
    }
    const [statusInfo, setStatusInfo] = useState(initialStatusInfo());
    const statusInfoRef = useRef(initialStatusInfo());
    const [showReqStatusOptions, setShowReqStatusOptions] = useState(false);
    const [status, setStatus] = useState("");
    const statusRef = useRef("");
    const [note, setNote] = useState("");
    const noteRef = useRef("");
    const [CP, setCP] = useState([""]);
    const CPRef = useRef([""]);
    const [CS, setCS] = useState([""]);
    const CSRef = useRef([""]);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [lastEmail, setLastEmail] = useState(null);
    const path = (due_date === "Onboarding" || due_date === "Documentation") ?
        due_date : moment(due_date).format("YYYY-MM");

    useEffect(() => {
        setStatusOptions(getStatusOptions());
        if (dataType === "monthly") {
            fetchMonthlyStatusInfo();
        }
    }, [due_date]);

    useEffect(() => {
        if (dataType === "documentation") {
            fetchDocumentationStatusInfo();
        }
    }, [moderateRow]);

    const fetchDocumentationStatusInfo = () => {
        const {
            status,
            kyc_documents_status,
            authorized_signatory_status,
            company_authorization_status,
            additional_documents_status,
            comment,
            last_updated_at,
            last_email_sent_at
        } = moderateRow;
        setStatus(status ? status : "");
        statusRef.current = status ? status : "";
        let obj = initialStatusInfo();
        obj[1].status = kyc_documents_status ? kyc_documents_status : "";
        obj[2].status = authorized_signatory_status ? authorized_signatory_status : "";
        obj[3].status = company_authorization_status ? company_authorization_status : "";
        obj[4].status = additional_documents_status ? additional_documents_status : "";
        setStatusInfo(obj);
        statusInfoRef.current = obj;
        setNote(comment ? comment : "");
        noteRef.current = comment ? comment : "";
        setLastUpdated(last_updated_at);
        setLastEmail(last_email_sent_at);
    }

    const fetchMonthlyStatusInfo = () => {
        callApi(`fetch-monthly-onboarding-status/${investeeOnboardedId}?path=${path}`, "get")
            .then(res => {
                if (res.status === "Success") {
                    const {
                        status,
                        mis_status,
                        invoicing_status,
                        customer_contracts_status,
                        bank_transactions_status,
                        financials_status,
                        comment,
                        cp_additional_documents,
                        cs_additional_documents,
                        last_updated_at,
                        last_email_sent_at
                    } = res.data;
                    setStatus(status ? status : "");
                    statusRef.current = status ? status : "";
                    let obj = initialStatusInfo();
                    obj[1].status = mis_status ? mis_status : "";
                    obj[2].status = invoicing_status ? invoicing_status : "";
                    obj[3].status = customer_contracts_status ? customer_contracts_status : "";
                    obj[4].status = bank_transactions_status ? bank_transactions_status : "";
                    if (due_date === "Onboarding") {
                        obj[5].status = financials_status ? financials_status : "";
                    }
                    setStatusInfo(obj);
                    statusInfoRef.current = obj;
                    setNote(comment ? comment : "");
                    noteRef.current = comment ? comment : "";
                    setCP(!_.isEmpty(cp_additional_documents) ? cp_additional_documents : [""]);
                    CPRef.current = !_.isEmpty(cp_additional_documents) ? cp_additional_documents : [""];
                    setCS(!_.isEmpty(cs_additional_documents) ? cs_additional_documents : [""]);
                    CSRef.current = !_.isEmpty(cs_additional_documents) ? cs_additional_documents : [""];
                    setLastUpdated(last_updated_at);
                    setLastEmail(last_email_sent_at);
                }
                else {
                    showNotification("Error", "Some error occurred!");
                }
            });
    }

    const renderTBody = () => {
        if (isModerating) {
            return (Object.keys(statusInfo).map(item => (
                <tr key={statusInfo[item].category}>
                    <td className="fs12 fw500">{statusInfo[item].category}</td>
                    <td className="text-center">
                        <input
                            type="checkbox"
                            className="bluecheck mr-2"
                            checked={statusInfo[item].status === "Approved"}
                            onChange={e => handleStatus(e, item, "Approved")}
                        />
                    </td>
                    <td className="text-center">
                        <input
                            type="checkbox"
                            className="bluecheck mr-2"
                            checked={statusInfo[item].status === "Need Information"}
                            onChange={e => handleStatus(e, item, "Need Information")}
                        />
                    </td>
                </tr>
            )))
        }
        else {
            return (Object.keys(statusInfo).map(item => (
                <tr key={statusInfo[item].category}>
                    <td className="fs12 fw500">{statusInfo[item].category}</td>
                    <td className="text-center">
                        <input
                            type="checkbox"
                            className="bluecheck mr-2"
                            checked={statusInfo[item].status === "Approved"}
                            disabled
                        />
                    </td>
                    <td className="text-center">
                        <input
                            type="checkbox"
                            className="bluecheck mr-2"
                            checked={statusInfo[item].status === "Need Information"}
                            disabled
                        />
                    </td>
                </tr>
            )))
        }
    }

    const renderTextBox = (state, setState) => {
        const handleChange = (val, index) => {
            if (state instanceof Array) {
                const arr = [...state];
                arr[index] = val;
                setState(arr);
            }
            else {
                setState(val);
            }
        }
        if (state instanceof Array) {
            return state.map((item, index) => (
                <div
                    key={index}
                    className={isModerating ?
                        "Bgmoderate fs12 fw300 mt-2"
                        :
                        "BgGreyBorderDark fs12 fw300 mt-2"
                    }
                >
                    <input
                        style={{ height: '100%', width: '100%', border: 'none' }}
                        value={item}
                        disabled={!isModerating}
                        onChange={e => handleChange(e.target.value, index)}
                        autoFocus
                    />
                </div>
            ));
        }
        else {
            if (isModerating) {
                return (
                    <textarea
                        className={isModerating ?
                            "Bgmoderate w-75 fs12 fw300"
                            :
                            "BgGreyBorderDark w-75 fs12 fw300"
                        }
                        style={{ height: '83px' }}
                        disabled={!isModerating}
                        onChange={e => handleChange(e.target.value)}
                        onKeyDown={e => {
                            if (e.key === 'Enter') handleChange('\n');
                        }}
                        value={state}
                    />
                );
            }
            else {
                return (
                    <div
                        className="BgGreyBorderDark w-75 fs12 fw300"
                        style={{ height: '83px', overflowY: 'scroll' }}
                    >
                        {state.split("\n").map((i, key) => {
                            return <div key={key}>{i}</div>;
                        })}
                    </div>
                )
            }
        }
    }

    const disableEmail = () => {
        if (dataType === "monthly") {
            return status === "Approved" && !emailCheck;
        }
        else if (dataType === "documentation") {
            return status !== "Need Information";
        }
    }

    const renderButtons = () => {
        if (isModerating) {
            return (
                <div className="d-flex">
                    <button
                        className="btn4 fs14 fw600 mr-3"
                        onClick={onCancel}
                    >Cancel</button>
                    <button
                        className="btn3 fs14 fw600"
                        onClick={onSave}
                    >Save</button>
                </div>
            )
        }
        else {
            return (
                <div className="d-flex">
                    <button
                        className={disableEmail() ?
                            "btn2 fs14 fw600 mr-3"
                            :
                            "btn3 fs14 fw600 mr-3"
                        }
                        onClick={emailUser}
                        disabled={disableEmail()}
                    >Email User</button>
                    <button
                        className="btn3 fs14 fw600"
                        onClick={() => setIsModerating(true)}
                    >Moderate</button>
                </div>
            )
        }
    }

    const handleStatus = (e, item, statusCategory) => {
        if (isModerating) {
            const obj = { ...statusInfo };
            if (e.target.checked) obj[item].status = statusCategory;
            else obj[item].status = "";
            setStatusInfo(obj);
        }
    }

    const handleReqStatusClick = () => {
        if (isModerating) setShowReqStatusOptions(!showReqStatusOptions);
    }

    const handleOptionClick = item => {
        setStatus(item);
        setShowReqStatusOptions(false);
    }

    const emailUser = () => {
        let endpoint;
        if (dataType === "monthly") {
            endpoint = `email-user/${investeeOnboardedId}?path=${path}`;
        }
        else if (dataType === "documentation") {
            endpoint = `/admin/onboarding-phase2/${investeeOnboardedId}/need_info_mail`;
        }
        callApi(endpoint, "get").then(res => {
            if (res.status === "Success") {
                showNotification("Success", "Email sent successfully");
                if (dataType === "monthly") {
                    fetchMonthlyStatusInfo();
                }
                else if (dataType === "documentation") {
                    setLastEmail(res.data?.last_email_sent_at);
                }
            }
            else {
                const message = res.data?.message ? res.data?.message : "Some error occurred!";
                showNotification("Error", message);
            }
        });
    }

    const onCancel = () => {
        if (showReqStatusOptions) setShowReqStatusOptions(false);
        setStatusInfo(JSON.parse(JSON.stringify(statusInfoRef.current)));
        if (status !== statusRef.current) setStatus(statusRef.current);
        if (note !== noteRef.current) setNote(noteRef.current);
        if (!_.isEqual(CP, CPRef.current)) setCP(CPRef.current);
        if (!_.isEqual(CS, CSRef.current)) setCS(CSRef.current);
        setIsModerating(false);
    }

    const onSave = () => {
        let endpoint;
        const emptyStrRemover = (state, setState) => {
            const arr = state.filter(item => item.trim() !== "");
            setState(arr);
            return arr;
        }
        const obj = {
            status,
            comment: note
        }
        if (due_date === "Onboarding") {
            const CPArr = emptyStrRemover([...CP], setCP);
            const CSArr = emptyStrRemover([...CS], setCS);
            if (!_.isEmpty(CPArr)) {
                obj.cp_additional_documents = CPArr;
            }
            if (!_.isEmpty(CSArr)) {
                obj.cs_additional_documents = CSArr;
            }
            obj.financials_status = statusInfo[5].status ? statusInfo[5].status : "Open";
        }
        if (dataType === "monthly") {
            endpoint = `moderate/${investeeOnboardedId}?path=${path}`;
            obj.mis_status = statusInfo[1].status ? statusInfo[1].status : "Open";
            obj.invoicing_status = statusInfo[2].status ? statusInfo[2].status : "Open";
            obj.customer_contracts_status = statusInfo[3].status ? statusInfo[3].status : "Open";
            obj.bank_transactions_status = statusInfo[4].status ? statusInfo[4].status : "Open";
        }
        else if (dataType === "documentation") {
            endpoint = `admin/investee-kyc-application/${investeeOnboardedId}/moderate`;
            obj.kyc_documents_status = statusInfo[1].status ? statusInfo[1].status : "Open";
            obj.authorized_signatory_status = statusInfo[2].status ? statusInfo[2].status : "Open";
            obj.company_authorization_status = statusInfo[3].status ? statusInfo[3].status : "Open";
            obj.additional_documents_status = statusInfo[4].status ? statusInfo[4].status : "Open";
        }
        callApi(endpoint, "post", obj).then(res => {
            if (res.status === "Success") {
                showNotification("Success", "Saved successfully");
                if (dataType === "monthly") {
                    fetchMonthlyStatusInfo();
                    fetchMonthlyData();
                }
                else if (dataType === "documentation") {
                    fetchDocumentationData(true);
                }
            }
            else {
                showNotification("Error", "Some error occurred!");
            }
        });
        setIsModerating(false);
    }

    const addMore = (state, setState) => {
        const arr = [...state];
        arr.push("");
        setState(arr);
    }

    return (
        <div className="col-md-4 position-absolute" style={{bottom:'0px',right:"10px"}}>
            <div className="shadowBorder mb-4">
                <div className="p20px">
                    <div className="d-flex justify-content-between align-items-center">
                        <span className="fs16 fw600">Moderation Comments ({(due_date === "Onboarding" || due_date === "Documentation") ? due_date : moment(due_date).format("DD MMM YYYY")})</span>
                        <button
                            type='button'
                            className='close'
                            onClick={() => setModerateRow(null)}
                        >
                            <span><img src="/assets/clear.png" alt="" /></span>
                        </button>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div className="fs12 fw600">Status:</div>
                        <div className={isModerating ? "BgGreyBorderDark w-75 border-0" : "BgGreyBorderDark w-75"}>
                            <table className="w-100">
                                <thead className='no-lb'>
                                    <tr>
                                        <th></th>
                                        <th className="fs10 fw300 text-center"><i>Approved</i></th>
                                        <th className="fs10 fw300 text-center"><i>Need more Info</i></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderTBody()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div className="fs12 fw600">Request Status:</div>
                        <div
                            className={isModerating ?
                                "d-flex justify-content-between Bgmoderate w-75 fs12 fw300 pl-1"
                                :
                                "d-flex justify-content-between BgGreyBorderDark w-75 fs12 fw300 pl-1"
                            }
                            onClick={handleReqStatusClick}
                        >
                            <span>{status}</span>
                            {isModerating && (
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="24px" height="24px" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#757575" stroke="none">
                                        <path d="M1180 3504 c-183 -66 -269 -266 -190 -439 33 -72 1360 -1400 1435 -1436 40 -19 66 -24 135 -24 69 0 95 5 135 24 73 35 1401 1363 1436 1436 34 72 34 199 -1 270 -31 64 -91 124 -155 155 -71 35 -198 35 -270 1 -39 -19 -166 -140 -597 -570 l-548 -546 -547 546 c-441 439 -558 550 -598 569 -66 30 -173 37 -235 14z" />
                                    </g>
                                </svg>
                            )}
                        </div>
                    </div>
                    {showReqStatusOptions && (
                        <div className={isModerating ? "border w-75 fs12 fw300 ml-auto" : "BgGreyBorderDark w-75 fs12 fw300 ml-auto"}>
                            {statusOptions.map((item, index) => (
                                <div
                                    key={index}
                                    className="DV-RS-option"
                                    onClick={() => handleOptionClick(item)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {item}
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div className="fs12 fw600">Note:</div>
                        {renderTextBox(note, setNote)}
                    </div>
                    {dataType === "monthly" && due_date === "Onboarding" && (
                        <>
                            <div className="d-flex justify-content-between align-items-center mt-3">
                                <div className="fs12 fw600">CP:</div>
                                <div className='w-75'>{renderTextBox(CP, setCP)}</div>
                            </div>
                            {isModerating && (
                                <div className="add-cp">
                                    <span onClick={() => addMore(CP, setCP)}>
                                        <img src="/assets/BluePlus.svg" alt="plus" />
                                        <span>Add CP</span>
                                    </span>
                                </div>
                            )}
                            <div className="d-flex justify-content-between align-items-center mt-3">
                                <div className="fs12 fw600">CS:</div>
                                <div className='w-75'>{renderTextBox(CS, setCS)}</div>
                            </div>
                            {isModerating && (
                                <div className="add-cp">
                                    <span onClick={() => addMore(CS, setCS)}>
                                        <img src="/assets/BluePlus.svg" alt="plus" />
                                        <span>Add CS</span>
                                    </span>
                                </div>
                            )}
                        </>
                    )}
                </div>
                <hr className="dvhr"></hr>
                <div className="p20px d-flex justify-content-between align-items-center">
                    <div>
                        <div className="fs10 mb-2">
                            <span className="fw600">Last Update</span>
                            <span className="fw300">: {lastUpdated ? moment(lastUpdated).format("HH:mm, DD MMM YYYY") : "NA"}</span>
                        </div>
                        <div className="fs10">
                            <span className="fw600">Last email</span>
                            <span className="fw300">: {lastEmail ? moment(lastEmail).format("HH:mm, DD MMM YYYY") : "NA"}</span>
                        </div>
                    </div>
                    {renderButtons()}
                </div>
            </div>
        </div>
    )
}
