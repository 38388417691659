import React from "react";
import { Modal } from "react-bootstrap";
import { downloadFileUsingFetch } from "../../../../../../../../../../../util/utility";
import KycDocs from "./KycDocs";
import "./ViewDocsModal.scss";


export default function KycModal({
  showKycDocs,
  setShowKycDocs,
  investeeOrgId,
}) {
  const downloadAllDocs = () => {
    downloadFileUsingFetch(
      `admin/${investeeOrgId}/download-all-docs`,
      "get",
      {
        responseType: "blob",
      },
      "kycDocuments.zip"
    );
  };

  return (
    <Modal
      show={showKycDocs}
      onHide={() => setShowKycDocs(false)}
      className="KYCModal">
      <div className="dflexJA modal-header border-0 p-4">
        <h4 className="modal-title">Deal Documents</h4>
        <div className="dflex-je">
          <button onClick={downloadAllDocs} className="btn3 fs10 fw500 px-4">
            Download All
          </button>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setShowKycDocs(false)}>
            <img src="../../../../../assets/modal-cross.svg" alt="" />
          </button>
        </div>
      </div>
      <KycDocs isKyc/>
    </Modal>
  );
}
