import React from "react";
import { filterEPBankAccounts, showNotification } from "../util/utility";
import { Button, Modal } from "react-bootstrap";
import banks from "../../src/constants/banks.json";
import nachTypes from "../../src/constants/nachTypes.json";
import moment from "moment";
class CreateNachModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nach: {},
      nachType: "",
    };
  }

  handleChange = (keyName, keyValue) => {
    let nach = this.state.nach;
    nach[keyName] = keyValue;
    this.setState({
      nach: nach,
    });
  };

  validateInput = () => {
    let isValid = true;
    let message = "";
    const { nach } = this.state;
    if (!nach.lender_name) {
      message = "Please select your lender name";
      isValid = false;
    } else if (!nach.nach_type) {
      message = "Please select Nach type";
      isValid = false;
    } else if (!nach.nach_amount) {
      message = "Please enter Nach amount";
      isValid = false;
    } else if (nach.nach_amount < 1) {
      message = "Please enter Nach amount greater than Rs 1";
      isValid = false;
    } else if (nach.nach_amount > 1000000 && nach.nach_type === "E-NACH") {
      message = "E-Nach amount should be less than Rs10 Lakh";
      isValid = false;
    } else if (nach.nach_type === "Paper") {
      if (!nach.prime_signatory) {
          message = "Please enter Primary Signatory"
          isValid = false;
      } else if (!nach.prime_signatory_phone) {
          message = "Please enter Prime Signatory phone"
          isValid = false;
      }
    }
    return {
      isValid,
      message,
    };
  };

  componentDidMount() {
    this.setState((prevState) => ({
      nach: { ...prevState.nach, end_date: this.props.lenderDetailList.mandateEndDate }
    }));
  }
  

  createNach = () => {
    let check = this.validateInput();
    if (check.isValid) {
      this.props.createNach(this.state.nach);
    } else {
      showNotification("warning", check.message);
    }
  };

  selectNachType = (value) => {
    this.handleChange("nach_type", value);
    this.setState({
      nachType: value,
    });
  };

  render() {
    const { nach } = this.state;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.closeModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="modal-title">Create Facility NACH</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {this.state.nach.lender_name && <div style={{fontSize:"8px", marginTop:"5px", paddingLeft:"6px", color: "#8D8D8D"}}>
              Lender Name
            </div>}
            <select
              value={this.state.nach.lender_name ? this.state.nach.lender_name : ""}
              onChange={
                (e) => this.handleChange("lender_name", e.target.value)
              }
              className="create-nach-field mb-2"
              style={
                !this.state.nach.lender_name
                  ? { color: "C3C3C3", paddingLeft: "15px", marginTop:"18px" }
                  : { color: "black", paddingLeft: "15px", marginTop:"1px" }
              }
              placeholder="Lender Name"
              name=""
              id=""
            >
              <option value="" disabled>
                Lender Name
              </option>
              {this.props.lenderDetailList?.investorDeals.map((item, index) => (
                <option key={index} value={item.investorOrgId}>
                  {item.investorName}
                </option>
              ))}
            </select>
            {this.state.nach?.end_date && <div style={{fontSize:"8px", marginTop:"5px", paddingLeft:"6px", color: "#8D8D8D"}}>
            End Date
            </div>}
            <input
              title={"index"} 
              value={moment(this.state.nach?.end_date, "DD-MM-YYYY").format("YYYY-MM-DD")}
              required
              onChange={(e) => this.handleChange("end_date", moment(e.target.value, "YYYY-MM-DD").format("DD-MM-YYYY"))}
              className="create-nach-field mb-2"
              style={
                !this.state.nach.end_date
                  ? { color: "C3C3C3", paddingLeft: "15px", marginTop:"18px" }
                  : { color: "black", paddingLeft: "15px", marginTop:"1px" }
              }
              type="date"
              placeholder="End Date"
              min={moment(this.state.nach?.end_date, 'DD-MM-YYYY').subtract(10, 'years').format('YYYY-MM-DD')}
            ></input>
            {this.state.nachType && <div style={{fontSize:"8px", marginTop:"5px", paddingLeft:"6px", color: "#8D8D8D"}}>
              NACH Type
            </div>}
            <select
              value={this.state.nachType}
              onChange={(e) => this.selectNachType(e.target.value)}
              className="create-nach-field mb-2"
              style={
                this.state.nachType === ""
                  ? { color: "C3C3C3", paddingLeft: "15px", marginTop:"18px" }
                  : { color: "black", paddingLeft: "15px", marginTop:"1px" }
              }
              placeholder="Name of Bank"
              name=""
              id=""
            >
              <option value="" disabled>
                NACH type
              </option>
              {nachTypes.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {this.state.nach?.nach_amount && <div style={{fontSize:"8px", marginTop:"5px", paddingLeft:"6px", color: "#8D8D8D"}}>
            Amount (Lacs)
            </div>}
            <input
              value={this.state.nach?.nach_amount}
              required
              onChange={(e) => this.handleChange("nach_amount", e.target.value)}
              className="create-nach-field mb-2"
              style={
                !this.state.nach.nach_amount
                  ? { color: "C3C3C3", paddingLeft: "15px", marginTop:"18px" }
                  : { color: "black", paddingLeft: "15px", marginTop:"1px" }
              }
              type="text"
              placeholder="Amount (Lacs)"
            ></input>
            <div>
                <div style={{ display: "flex" }}>
                  <input
                    value={this.state.nach?.prime_signatory}
                    required
                    onChange={(e) =>
                      this.handleChange("prime_signatory", e.target.value)
                    }
                    className="create-nach-field mt-3 mr-4 mb-2"
                    style={
                      !this.state.nach.prime_signatory
                        ? { color: "C3C3C3", paddingLeft: "15px" }
                        : { color: "black", paddingLeft: "15px" }
                    }
                    type="text"
                    placeholder="Full Name of Primary Signatory"
                  ></input>
                  <input
                    value={this.state.nach?.prime_signatory_phone}
                    required
                    onChange={(e) =>
                      this.handleChange("prime_signatory_phone", e.target.value)
                    }
                    className="create-nach-field mt-3"
                    style={
                      !this.state.nach.prime_signatory_phone
                        ? { color: "C3C3C3", paddingLeft: "15px" }
                        : { color: "black", paddingLeft: "15px" }
                    }
                    type="text"
                    placeholder="Phone no. (Primary Signatory)"
                  ></input>
                </div>
              </div>
            <button
              className="create-nach-btn"
              disabled={this.props.disableCreateNachButton}
              onClick={() => this.createNach()}
            >
              Create Facility NACH
            </button>
        </Modal.Body>
      </Modal>
    );
  }
}

export default CreateNachModal;