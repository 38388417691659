import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {encryptMessage, numberOnly, showNotification} from "../../../../util/utility";
import {loginUser, loginWithOtp} from "../../AppActions";
import {handleValidationLogin} from "../../../../util/loginValidations";
import { random } from 'lodash';
import callApi from '../../../../util/apiCaller';

class LoginForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {
                emailId: '',
                otp: ''
            },
            isOtpSent: false,
            changeText:false,
            time:30
        };
    }
    thisInterval=null;
    startTimer(){
        this.thisInterval = setInterval(this.countDown,1000);
    }
    countDown=()=>{
        let timer = this.state.time-1;
        if(this.state.isOtpSent){
                this.setState({time:timer})
            if(this.state.time===0){
                clearInterval(this.thisInterval);
                this.setState({isOtpSent: false})
            }
        }
    }

    handleChange = (keyName, keyValue) => {
        let user = this.state.user;
        if(keyName==='otp'){
            keyValue = keyValue.length>6?keyValue.slice(0,6):keyValue;
        }
        user[keyName] = keyValue;
        this.setState({
            user: user
        });
    };

    handleSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }
        let payload = {
            requestId: `${random(0,1000000)}`,
            user:{
                emailId: this.state.user.emailId,
                otp: this.state.user.otp,
                userType:'Admin'
            }
        }
        const check = handleValidationLogin(payload.user);
        if (check.isValid) {
            await this.props.dispatch(loginWithOtp(payload))
            this.props.history.push('/admin');
        } else {
            showNotification("error", check.message);
        }
    };
    sendOTP=(e)=>{
        if(e) e.preventDefault();
        let payload = {
            requestId: `${random(0,1000000)}`,
            user:{
                emailId: this.state.user.emailId,
                userType:'Admin'
            }
        }
        if(!payload.user.emailId)showNotification('error','Please enter email id');
        callApi('/mfa/generateOtp', 'post', payload,false,false,false,false,true).then(res=>{
            if(res.responseData.responseCode===20){
                showNotification('success',res.responseData.responseMessage??'OTP sent successfully');
                this.setState({isOtpSent: true,changeText:true})
                this.startTimer();
            }
            else{
                showNotification('error',res.responseData.responseMessage??'Something went wrong');
                this.setState({isOtpSent: false})
            }
        })
    }

    render() {
        return (
            <>
                <div className="col-md-6 col-sm-12  login-card recur-card">
                    <section id="inner-wrapper" className="login">
                            <article>
                                <div>
                                    <h4>Welcome to Recur</h4>
                                    <img alt="" src='/assets/shape.svg' className=""/>
                                    <p>COMPANY'S CLUB</p>

                                    <form>

                                    <div className="form-group" style={{paddingTop: '15px'}}>
                                        <div className="input-group">

                                            {/* <span className="input-group-addon form-icons"><i
                                                className="fa fa-envelope"> </i></span> */}

                                            <input
                                                value={this.state.user.emailId}
                                                onChange={(e) => this.handleChange('emailId', e.target.value)}
                                                type="email" className="form-control" placeholder="Email"
                                                style={{borderRadius: '4px'}}/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group">
                                            {/* <span className="input-group-addon form-icons"><i className="fa fa-key"> </i></span> */}
                                            <input
                                                disabled={!this.state.changeText}
                                                value={this.state.user.otp}
                                                onChange={(e) => this.handleChange('otp', numberOnly(e.target.value))}
                                                type="otp" className="form-control" placeholder="otp"
                                                style={{borderRadius: '4px'}}/>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', paddingTop: '25px', justifyContent:'space-between',alignItems:'center'}}>
                                        <div>
                                        <button
                                            disabled={this.props.isLoggingIn}
                                            onClick={(e)=>this.handleSubmit(e)}
                                            className="btn btn-primary btn-block button login-btn"
                                            style={{background: '#4C8DFF', marginRight: '15px'}}>
                                            {this.props.isLoggingIn ? 'Logging' : 'Login'}
                                        </button>
                                        </div>
                                        <div className='d-flex flex-column justify-content-center align-items-center'>
                                        <button
                                            disabled={this.state.isOtpSent}
                                            className="btn btn-primary btn-block button login-btn"
                                            onClick={(e)=>this.sendOTP(e)}
                                            style={{background: '#4C8DFF'}}>
                                            {this.state.isOtpSent||this.state.changeText ? 'Resend Otp ' : 'Send OTP'}
                                        </button>
                                        </div>
                                    </div>
                                        {this.state.isOtpSent&&<div style={{color:'red',fontSize:'12px',float:'right'}}>{this.state.time} seconds</div>}
                                    </form>

                                    <div style={{marginTop: '25px', fontWeight:'300', fontFamily: 'Graphik'}}>
                                        Don't have an account? <Link className="remove-decor blue-color-onhover" to='/signup' style={{

                                        color: '#348CFF',
                                        cursor: 'pointer'
                                    }}>Signup</Link>
                                    </div>
                                </div>
                            </article>
                    </section>
                </div>

            </>
        )
    }
}

export default LoginForm;
