import React, { Component } from "react";
import OrderRepaymentsTableHeader from "../OrderRepaymentsPage/components/OrderRepaymentsTableHeader";
import OrderRepaymentsTableRow from "../OrderRepaymentsPage/components/OrderRepaymentsTableRow";
import AdminHead from "../../components/AdminHead.js";
import callApi from "../../../../util/apiCaller";
import { getToggleConfigValueForDelayPaid, showNotification } from "../../../../util/utility";
import _ from "lodash";
import { mergeByProperty } from "../../../../util/calculateTermUtil";
import OrderTransactionListHeader from "./components/OrderTransactionListHeader";
import OrderTransactionListRow from "./components/OrderTransactionListRow";
import { Button } from "@material-ui/core";
import "../../../../css/Table.scss";
import CompletedOrderRepaymentsTableRow from "./components/CompletedOrderRepaymentsTableRow";
import CompletedOrderRepaymensListHeader from "./components/CompletedOrderRepaymentsListHeader";

const PENDING = "pending";
const COMPLETED = "completed"

class OrderRepaymentsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contracts: [],
      repayments: [],
      completed_repayments: [],
      is_fetching_repayments: false,
      is_fetching_completed_repayments: false,
      viewMode: PENDING,
      pageNum: 1,
      pageSize: 200,
      totalCount: 0,
      qtext: "",
      selected_repayments_payload: [],
      selected_repayment_ids: [],
      is_marking_transaction_status: false,
      mark_transaction_status_as: "",
      searchObjInvestorOrg: {
        keyName: "investor_organization_name",
        value: "",
      },
      searchObjInvestorUser: {
        keyName: "investor_name",
        value: "",
      },
      searchObjCompanyName: {
        keyName: "investee_organization_name",
        value: "",
      },
      searchObjContractID: {
        keyName: "contract_id",
        value: "",
      },
      searchObjPaymentID: {
        keyName: "transaction_id",
        value: "",
      },
      sliderObjRepaymentNo: {
        keyName: "payment_number",
        value: "",
      },
      sliderObjAmount: {
        minVal: 0,
        maxVal: 12,
        currentMax: 12,
        currentMin: 0,
        keyName: "transaction_amount",
        minValueBetween: 1,
      },
      scheduledDateObj: {
        date: [undefined, undefined],
        keyName: "scheduled_recur_payment_date",
        isChanged: false
      },
      lastUpdateDateObj: {
        date: [new Date(), new Date()],
        keyName: "updated_at",
      },
      selectorObjStatus: {
        keyName: "transaction_status",
        elementsArray: [
          {
            label: "Complete",
            selected: true,
          },
          {
            label: "Delayed",
            selected: true,
          },
          {
            label: "Default",
            selected: true,
          },
          {
            label: "Scheduled",
            selected: true,
          },
        ],
      },
      viewTopLevel: true,
      listInvestorInvesteeDate: [],
      selected_repayment_index: null,
    };
    this.debounceSearch = _.debounce(() => {
      if(this.state.viewMode === PENDING) return (this.fetchRepayments(true), 400);
      else return this.fetchCompletedRepayments(true);
    },400);
  }

  keyStateObjNameDict = {
    investor_organization_name: "searchObjInvestorOrg",
    investor_name: "searchObjInvestorUser",
    investee_organization_name: "searchObjCompanyName",
    contract_id: "searchObjContractID",
    transaction_id: "searchObjPaymentID",
    payment_number: "sliderObjRepaymentNo",
    recurring_payment_value: "sliderObjPayments",
    transaction_amount: "sliderObjAmount",
    scheduled_recur_payment_date: "scheduledDateObj",
    updated_at: "lastUpdateDateObj",
    transaction_status: "selectorObjStatus",
  };

  componentDidMount() {
    this.fetchRepayments();
  }

  onSelectTransaction = (val) => {
    this.setState({ selected_repayment_index: val })
  }

  emptySelectedIds = () => {
    this.setState({ selected_repayment_ids: [] })
  }

  onChangeTransactionCheckbox = (val) => {
    let selected_repayments_ids = [...this.state.selected_repayment_ids];
    if (val.target.checked) {
      selected_repayments_ids.push(val.target.value);
    } else if (!val.target.checked) {
      _.remove(selected_repayments_ids, n => n === val.target.value);
    }
    this.setState({
      selected_repayment_ids: selected_repayments_ids
    });
  };

  onChangeRepaymentsCheckbox = (isChecked, item) => {
    let selected_repayments = [...this.state.selected_repayments_payload];
    if (isChecked) {
      if(selected_repayments.length > 20) {
        showNotification("error", "Maximum repayments limit reached");
        return;
      }
      selected_repayments.push({
        transaction_date: item?.transaction_date,
        investee_organization_id: item?.investee_organization?._id,
        investor_organization_id: item?.investor_organization?._id,
        repaymentAmountToBeDone: Number(item?.repaymentAmountToBeDone),
        delayPaidToggle : item.delayPaidToggle
        // repaymentAmountToBeDoneWP : Number(item?.repaymentAmountToBeDoneWP)
      });
    }
    else if (!isChecked) {
      const index = selected_repayments.findIndex(n => n.investor_organization_id === item?.investor_organization?._id && n?.investee_organization_id === item?.investee_organization?._id && n.transaction_date === item?.transaction_date);
      selected_repayments.splice(index,1);
    }
    this.setState({
      selected_repayments_payload: selected_repayments
    });
  };


  changeView = (viewMode) => {
    let tempScheduledObj = {...this.state.scheduledDateObj};
    tempScheduledObj.date[0] = "";
    tempScheduledObj.date[1] = "";
    tempScheduledObj.isChanged = false;
    if (viewMode === PENDING) {
      // this.fetchTrades();
      this.setState({
        scheduledDateObj: tempScheduledObj
      },() => this.fetchRepayments(true));
      this.setState({
        qtext: ""
      })
      this.setState({
        viewMode: viewMode,
      });
    } else if (viewMode === COMPLETED) {
      // this.fetchTradesByOrderId(selectedOrderID);
    this.setState({
      scheduledDateObj: tempScheduledObj
    },() => this.fetchCompletedRepayments(true));
    this.setState({
      qtext: ""
    })
      this.setState({
        viewMode: viewMode,
      });
    }
  }
  
  fetchRepayments = (isReseting) => {
    this.setState({ is_fetching_repayments: true });
    let filters = this.getFilters();
    if (isReseting) {
      filters.pageNum = 1;
    }
    callApi(`admin/repayments/getRepaymentsByGroup`, "post", filters).then((res) => {
      if (res.status === "Success") {
        let temp_repayments = res?.data?.transactions;
        for(let repayment of temp_repayments) {
          let remainingRepayment = Math.ceil((repayment.total_repayment ?? 0) - (repayment.repayment_done ?? 0))
          let minOfCompanyBalncAndRemainingRepayment = Math.min(remainingRepayment, repayment?.investee_organization?.escrow_account_balance);
          if(isNaN(minOfCompanyBalncAndRemainingRepayment)) minOfCompanyBalncAndRemainingRepayment = '';
          repayment.repaymentAmountToBeDone = minOfCompanyBalncAndRemainingRepayment ?? '';
          repayment.delayPaidToggle = getToggleConfigValueForDelayPaid(repayment).toggle;
          // repayment.repaymentAmountToBeDoneWP = remainingRepayment;
        }
        this.setState({
          repayments: isReseting ?
            temp_repayments :
            mergeByProperty(this.state.repayments, temp_repayments, "transaction_date,investee_organization._id,investor_organization._id"),
          totalCount: res.data.count,
          selected_repayment_ids: [],
        });
      } else {
        showNotification("error", "Some error occured");
      }
      this.setState({ is_fetching_repayments: false });
    });
  };

  fetchCompletedRepayments = (isReseting) => {
    this.setState({ is_fetching_completed_repayments: true });
    let filters = this.getFilters();
    const ifFromAndToDateAreNotPresent = (filters.transaction_date?.from && !filters.transaction_date?.to)
      || (filters.transaction_date?.to && !filters.transaction_date?.from)
    if (ifFromAndToDateAreNotPresent) {
      showNotification("Error", "both from and to date are required")
      return;
    }
    if (isReseting) {
      filters.pageNum = 1;
    }
    callApi(`admin/repayments/getCompletedTrxs`, "post", filters).then((res) => {
      if (res.status === "Success") {
        let completed_repayments = this.state.completed_repayments;
        this.setState({
          completed_repayments: isReseting ?
            res?.data :
            mergeByProperty(completed_repayments, res?.data, "transaction_date,investee_organization._id,investor_organization._id"),
            totalCount: res?.data?.count,
        });
      } else {
        showNotification("error", "Some error occured");
      }
      this.setState({ is_fetching_completed_repayments: false });
    });
  }

  markTransactionStatus = (status) => {
    if(status === 'CompleteWP') alert(`Want to complete repayment without EP Transfer ?`);
    if (!this.state.viewTopLevel && _.isEmpty(this.state.selected_repayment_ids)) {
      showNotification("Error", "No Transaction is Selected");
      return;
    }
    if (this.state.viewTopLevel && _.isNil(this.state.selected_repayments_payload)) {
      showNotification("Error", "No Repayments are Selected");
      return;
    }
    this.setState({
      is_marking_transaction_status: true,
      mark_transaction_status_as: status,
    });
    let obj = {};
    let pathToHit;
    if (this.state.viewTopLevel) {
      obj.groupObjArray = this.state.selected_repayments_payload
      // if(status === 'CompleteWP') {
      //   const repaymentPayloadArr = [...this.state.selected_repayments_payload];
      //   obj.groupObjArray = repaymentPayloadArr?.map(payload => {
      //                       payload.repaymentAmountToBeDone = payload.repaymentAmountToBeDoneWP
      //                       delete payload["repaymentAmountToBeDoneWP"];
      //                       return payload;
      //                     })
      // }
      pathToHit = "admin/repayments/markRepaymentStatusByGroup";
    } else {
      obj['orderTrxIds'] = this.state.selected_repayment_ids
      pathToHit = "admin/repayments/markRepaymentStatus";
    }
    obj['status'] = status;
    callApi(pathToHit, "post", obj).then((res) => {
      if (res.status === "Success") {
        showNotification("success", "Transaction Updated Successfully!");
        this.setState({
          is_marking_transaction_status: false,
        });
        this.fetchRepayments();
        if (this.state.viewTopLevel) {
          this.setState({ selected_repayments_payload: [] });
        } else {
          this.setState({ selected_repayment_ids: [] });
        }
      } else {
        if(Array.isArray(res.data) && res.data.length > 0 ){
          showNotification("error", `${res.data.length} Repayment(s) Failed in this group`);
        }
        showNotification("error", "Some error occured");
        this.setState({
          is_marking_transaction_status: false,
        });
      }
      this.setState({ selected_repayments_payload: [] });
    });
  };

  hasMore = () => {
    return this.state.totalCount + 1 > this.state.pageNum * this.state.pageSize;
  };

  hasMoreCompletedRepayments = () => {
    return this.state.totalCount + 1 > this.state.pageNum * this.state.pageSize;
  };

  loadMore = () => {
    this.setState({ pageNum: this.state.pageNum + 1 });
    this.fetchRepayments();
  };

  loadMoreCompletedRepayments = () => {
    this.setState({ pageNum: this.state.pageNum + 1 });
    this.fetchCompletedRepayments();
  };

  getFilters = () => {
    let filters = {};
    filters.pageSize = this.state.pageSize;
    filters.pageNum = this.state.pageNum;

    if (this.state.qtext) {
      filters.isSearching = true;
      filters.qtext = this.state.qtext;
    }

    if (this.state.scheduledDateObj.isChanged)
      filters["transaction_date"] = {
        'from': this.state.scheduledDateObj.date[0],
        'to': this.state.scheduledDateObj.date[1]
      }
    return filters;
  };

  handleApplyFilters = () => {
    this.fetchContracts();
  };

  handleSearchFilter = (obj) => {
    let searchObj = {};
    searchObj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(searchObj, () => {
      this.handleApplyFilters();
    });
  };

  handleSliderFilter = (obj) => {
    let sliderobj = {};
    sliderobj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(sliderobj, () => {
      this.handleApplyFilters();
    });
  };

  handledatePicker = (obj) => {
    let datePickerObj = {};
    datePickerObj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(datePickerObj, () => {
      this.handleApplyFilters();
    });
  };

  handleSetDate = (val, i) => {
    let obj = { ...this.state.scheduledDateObj }
    obj.date[i] = val;
    obj.isChanged = true;
    this.setState({ scheduledDateObj: obj })
  }

  inputSelectorHandler = (obj) => {
    let selectorObj = {};
    selectorObj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(selectorObj, () => {
      this.handleApplyFilters();
    });
  };

  handleRightArrowClick = (item, fromRefreshClick) => {
    let obj = { ...item }
    obj['pageSize'] = 200
    callApi(`admin/repayments/getRepaymentsBySingleGroup`, 'post', obj)
      .then((result) => {
        if (result.status === 'Success' && result.data.length > 0) {
          if (fromRefreshClick)
            this.setState({ listInvestorInvesteeDate: result.data })
          else
            this.setState({ viewTopLevel: false }, () => {
              this.setState({ listInvestorInvesteeDate: result.data })
            })
        }
      })
  }

  handleResfresh = () => {
    this.setState({selected_repayment_ids:[]})
    let obj = {}
    obj.investee_organization_id = this.state.listInvestorInvesteeDate[0]?.investee_organization_id?._id
    obj.investor_organization_id = this.state.listInvestorInvesteeDate[0]?.investor_organization_id?._id
    obj.transaction_date = this.state.listInvestorInvesteeDate[0]?.transaction_date
    this.handleRightArrowClick(obj, true)
  }

  handleTextSearch = (val) => {
    this.setState(
      {
        qtext: val,
      },
      () => {
        this.debounceSearch();
      }
    );
  };

  render() {
    return (
      <div className="main-body">
        <AdminHead />
        <section id='admin-company'>
          <div class='d-flex justify-content-between form-group has-search'>
            {this.state.viewTopLevel && this.state.viewMode === PENDING && <div className='d-flex justify-content-between'>
              {this.state.is_marking_transaction_status &&
                this.state.mark_transaction_status_as === "Complete" ? (
                <button className='app-btn btn-approve' disabled={true}>
                  <img src='../../../../../assets/icon-done.svg' alt=''></img>
                  Loading...
                </button>
              ) : (
                //align below two button parallel

                <div className="d-flex">
                   <button
                style={{width: "130px", height: "28px", backgroundColor: "#279c13"}}
                  className='app-btn btn-approve'
                  onClick={() => this.markTransactionStatus("Complete")}>
                  <img src='../../../../../assets/icon-done.svg' alt=''></img>
                  Complete
                </button>
                 <button
                 style={{width: "150px", height: "28px", backgroundColor: "#4C8DFF"}}
                   className='app-btn btn-approve'
                   onClick={() => this.markTransactionStatus("CompleteWP")}>
                   <img src='../../../../../assets/icon-done.svg' alt=''></img>
                   Complete WP
                 </button>
                </div>
               
              )}
            </div>}
             {this.state.viewTopLevel && (<div className="trade-order">
                <div className={`b-b b-theme nav-active-theme mb-3`} style={this.state.viewMode === COMPLETED ? {marginLeft: "370px"} : {marginLeft: "130px"}}>
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item"><a className={"nav-link " + (this.state.viewMode === PENDING ? "active" : "")} id="pending-tab" onClick={() => this.changeView(PENDING)} data-toggle="tab" href="#pending" role="tab" aria-controls="pending" aria-selected="true">Pending</a></li>
                    <li className="nav-item"><a className={"nav-link " + (this.state.viewMode === PENDING ? "" : "active")} id="completed-tab" onClick={() => this.changeView(COMPLETED)} data-toggle="tab" href="#completed" role="tab" aria-controls="completed" aria-selected="false">Completed</a></li>
                  </ul>
                </div>
              </div>)}
            <div className="d-flex">
              {this.state.viewTopLevel ?
                <>
                  <div className="d-flex mr-2">
                    <input
                      className="filter-value ml-1 h-28" type="date" id="start-date"
                      name="comp-name"
                      onChange={e => this.handleSetDate(e.target.value, 0)}
                      value={this.state.scheduledDateObj.date[0]}
                    >
                    </input>
                  </div>
                  <span className="mr-1 mt-1" style={{fontSize: "10px", color: "#929292"}}>to</span>
                  <div className="d-flex mr-3">
                    <input
                      className="filter-value ml-1 h-28" type="date" id="end-date"
                      name="comp-name"
                      onChange={e => this.handleSetDate(e.target.value, 1)}
                      value={this.state.scheduledDateObj.date[1]}
                    >
                    </input>
                    <Button variant='outlined' className="ml-1 h-28" style={{ color: '#2F8FFF', borderColor: '#2F8FFF', height: '23px',textTransform: "none" }} onClick={() => {this.state.viewMode === PENDING ? this.fetchRepayments(true) : this.fetchCompletedRepayments(true)}}><span style={{fontSize: "10px", fontFamily: "Gilroy-Semibold"}}>Go &#8594;</span></Button>
                  </div>
                  <div>
                  <span className="form-control-feedback">
                  <img alt="" src='../../../../../assets/search.png' style={{height: "14px", width: "14px",marginBottom: "12px"}}></img>
                  </span>
                  <input
                    value={this.state.qtext}
                    onChange={(e) => this.handleTextSearch(e.target.value)}
                    type='text'
                    className='form-control'
                    placeholder='Search by Company'
                    style={{height: '28px' }} />  
                  </div>
                </>
                :
                ''
              }
            </div>
          </div>
          {!(this.state.viewTopLevel) &&
            <Button className="mb-3" variant='outlined' style={{ color: '#2F8FFF', borderColor: '#2F8FFF', textTransform: "none", fontSize: "10px", fontFamily: "Gilroy-Semibold"
            }} onClick={() => this.setState({ viewTopLevel: true })}><span style={{marginRight: "5px"}}>&#8592;</span>Back</Button>}
          <div className='TableCard'>
            {/* table-head / admin-data-table tableFixHead */}
            <table className=''>
              {this.state.viewTopLevel && this.state.viewMode === PENDING
                ?
                <OrderTransactionListHeader />
                : this.state.viewTopLevel && this.state.viewMode === COMPLETED
                ? <CompletedOrderRepaymensListHeader/>
                : <OrderRepaymentsTableHeader
                  searchObjInvestorOrg={this.state.searchObjInvestorOrg}
                  searchObjInvestorUser={this.state.searchObjInvestorUser}
                  searchObjCompanyName={this.state.searchObjCompanyName}
                  searchObjContractID={this.state.searchObjContractID}
                  searchObjPaymentID={this.state.searchObjPaymentID}
                  sliderObjRepaymentNo={this.state.sliderObjRepaymentNo}
                  sliderObjAmount={this.state.sliderObjAmount}
                  scheduledDateObj={this.state.scheduledDateObj}
                  lastUpdateDateObj={this.state.lastUpdateDateObj}
                  selectorObjStatus={this.state.selectorObjStatus}
                  handleSliderFilter={this.handleSliderFilter}
                  inputSelectorHandler={this.inputSelectorHandler}
                  handleSearchFilter={this.handleSearchFilter}
                  handledatePicker={this.handledatePicker}
                />
              }
              {/* <tbody style={{ display: 'none' }}></tbody>
            </table>
          </div>
          <div className='tableFixHead table-body'>
            <table
              className='table team'
            >
              <thead></thead> */}
              <tbody className='team-body'>
                {this.state.viewTopLevel && this.state.viewMode === PENDING ?
                  (this.state.repayments?.map((item, index) => (
                    <OrderTransactionListRow
                      index={index}
                      key={index}
                      item={item}
                      loader={this.state.is_fetching_repayments}
                      hasMore={this.hasMore}
                      loadMore={this.loadMore}
                      dataLength={this.state.repayments.length}
                      selected_repayment_index={this.state.selected_repayment_index}
                      onSelectTransaction={this.onSelectTransaction}
                      handleRightArrowClick={this.handleRightArrowClick}
                      selected_repayment_ids={this.state.selected_repayment_ids}
                      repayments={this.state.repayments}
                      setRepayments={val => this.setState({repayments: val})}
                      selected_repayments_payload={this.state.selected_repayments_payload}
                      setSelectedRepaymentsPayload={val => this.setState({selected_repayments_payload: val})}
                      onChangeRepaymentsCheckbox={
                        this.onChangeRepaymentsCheckbox
                      }
                      is_marking_transaction_status={this.state.is_marking_transaction_status}
                    />
                  )))
                  : this.state.viewTopLevel && this.state.viewMode === COMPLETED ? 
                  (this.state.completed_repayments?.map((item, index) => (
                    <CompletedOrderRepaymentsTableRow
                      key={item._id}
                      item={item}
                      index={index} 
                      loader={this.state.is_fetching_completed_repayments}
                      hasMore={this.hasMoreCompletedRepayments}
                      loadMore={this.loadMoreCompletedRepayments}
                      dataLength={this.state.completed_repayments.length}
                    />
                  )))
                  :
                  (this.state.listInvestorInvesteeDate?.map((item, index) => (
                    <OrderRepaymentsTableRow
                      key={item._id}
                      item={item}
                      index={index}
                      emptySelectedIds={this.emptySelectedIds}
                      onChangeTransactionCheckbox={
                        this.onChangeTransactionCheckbox
                      }
                      selected_repayment_ids={this.state.selected_repayment_ids} />
                  )))
                }
              </tbody>
            </table>
          </div>
        </section>
      </div>
    );
  }
}

export default OrderRepaymentsPage;
