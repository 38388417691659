import  {  useState } from 'react';
import styles from './InputCollectionMethod.module.scss';
import MultiSelect from '../MultiSelect/MultiSelect';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { COLLECTION_METHODS, RAZORPAY_SPLIT_KEY, CASHFREE_SPLIT_KEY, DIRECT_COLLECTION_KEY, NACH_FORTNIGHTLY, NACH_WEEKLY, COBORROWER, MAB, AUTO_WITHDRAW_COVER_MEHTODS, SECONDARY_RCM_METHODS, RCM_METHOD_COVER, SPLIT_NACH_RCM_METHODS } from '../../../../../../../../../../../../../../../constants/enums';
import CoborrowerDropdowns from './CoborrowerDropdowns.tsx';
import { containsKeys, showNotification } from '../../../../../../../../../../../../../../../util/utility';
import ToggleButton from '../../ToggleButton/ToggleButton';

export default function InputCollectionMethod({ data, isDisabled, handleChange, twoInput , userNameForCoborrower, setUserNameForCoborrower }) {
    const { Selected, DisabledSelected, Input, coborrowerName, coborrowerDropdown, removeInActive,removeActive, SelectedWithoutType, Options, OptionsHide,ApplicableCompanyText,containerBox} = styles;
    const SELECTION_TYPE = [NACH_WEEKLY, NACH_FORTNIGHTLY, RAZORPAY_SPLIT_KEY, CASHFREE_SPLIT_KEY, DIRECT_COLLECTION_KEY, MAB, COBORROWER , SECONDARY_RCM_METHODS.Amazon , SECONDARY_RCM_METHODS.Swiggy , SECONDARY_RCM_METHODS.Zomato ,SECONDARY_RCM_METHODS.Shiprocket, SECONDARY_RCM_METHODS.Flipkart , SECONDARY_RCM_METHODS.Easebuzz, SECONDARY_RCM_METHODS.Nykaa, SECONDARY_RCM_METHODS.Snapdeal, SECONDARY_RCM_METHODS.Paytm ];
    const DATES = "dates";
    const AMOUNT = "amount";
    const NAME = "name";
    const COMPANY="company";
    const INDIVIDUAL="individual";
    const TYPE="type";
    const START_DATE = "start_date";
    const END_DATE = "end_date";
    const OFF= "off";
    const [option,setOption]=useState();
    const [selectedOptions, setSelectedOptions] = useState();
    const [viewUsers,setViewUsers]=useState(undefined);
    const [filteredUsers,setFilteredUsers] = useState(null);
    const [coborrowerList, setCoborrowerList] = useState([]);
    useEffect(() => {
        const handleWheel = (event) => {
          const activeElement = document.activeElement;
          if (
            activeElement &&
            activeElement.type === "number" &&
            activeElement.classList.contains("noscroll")
          ) {
            activeElement.blur();
          }
        };
    
        document.addEventListener('click', closeOptionsIfShown);
        document.addEventListener("wheel", handleWheel);
        return () => {
            document.removeEventListener('click', closeOptionsIfShown);
            document.removeEventListener("wheel", handleWheel);
        }
    }, []);

    const closeOptionsIfShown=()=>{
        setViewUsers(undefined);
    }

    useEffect(() => {
        if (!isEmpty(userNameForCoborrower)) {
            const tempCoborrowers = [];
            for (const coborrower of userNameForCoborrower ?? []) {
                const temp = {
                    name: coborrower.name,
                    userId: coborrower.userId,
                };
                if ((data?.Coborrower ?? []).find((c) => c.user_id === coborrower.userId && c.type === INDIVIDUAL)) {
                    temp.dontShow = true;
                } else if ((data?.Coborrower ?? []).find((c) => c.name === coborrower.name && c.type === INDIVIDUAL)) {
                    temp.dontShow = true;

                } else {
                    temp.dontShow = false;
                }
                tempCoborrowers.push(temp);
            }
            setCoborrowerList(tempCoborrowers);
        }
    }, [data, userNameForCoborrower]);

    useEffect(() => {
        updateSelectedOptions();
        let option=SELECTION_TYPE;
        Object.keys(data ?? {}).forEach((item)=>{
            if(item!==COBORROWER){
                const index=option.indexOf(item);
                if(index!=-1) option.splice(index,1);
            }
        })
        setOption(option);
    }, [data]);
    
    const updateSelectedOptions = () => {
        let obj = {};
        if (!isEmpty(data)) obj = data
        setSelectedOptions(obj);
    }
    function setValue(item,index){
        const temp=selectedOptions;
        if(temp?.[COBORROWER]?.[index]){
            temp[COBORROWER][index][NAME]=item.name;
            temp[COBORROWER][index]['user_id']=item?.userId;
        }
    }

    const removeRCM=(e,index)=>{
        if(!isDisabled)
            onChange({category:e.target.alt, removeItem:true,elementIndex:index});
    }
    const renderSearchResults = (value)=>{
        if(!value) return;
        const temp=userNameForCoborrower;
        const result=temp.filter((item)=>{
            return (item.name.toLowerCase().includes(value.toLowerCase()))
        })
        setFilteredUsers(result);
    }
    const onChange = ({ category, keyName, keyValue, removeItem,elementIndex,addingRCM=false }) => {
        const alreadySelectedSplitNachRCM = containsKeys(selectedOptions,SPLIT_NACH_RCM_METHODS);
        if(addingRCM && category in SPLIT_NACH_RCM_METHODS && alreadySelectedSplitNachRCM){
            showNotification("error",`${SPLIT_NACH_RCM_METHODS[category]} and ${alreadySelectedSplitNachRCM} RCMs cannot be selected together`);
            return;
        }
        const obj = { ...selectedOptions };
        if (removeItem) {
            if(category===COBORROWER && obj[category]?.length>1){
                obj[category].splice(elementIndex,1);
            }
            else{
                delete obj[category];
            }
        }
        else if(category===COBORROWER){
            if(elementIndex!==undefined){
                obj[category][elementIndex][keyName]=keyValue;
            }
            else{
                const newList=obj[category] ? [...obj[category]] : [];
                newList.push({name:null,type:null});
                obj[category]=newList;
            }
        }
        else if(AUTO_WITHDRAW_COVER_MEHTODS.includes(category)){
            const settlementPeriod = RCM_METHOD_COVER[category] ?? 0;
            if(!obj[category]) obj[category]={settlementPeriod};
            if(keyName)obj[category][keyName] = Number(keyValue);
        }
        else {
            if (keyName) {
                if(!obj[category]) obj[category]={};
                obj[category][keyName] = Number(keyValue);
            }
            else {
                obj[category] = null;
                if (category === DIRECT_COLLECTION_KEY && !(MAB in obj)) obj[MAB] = null;
            }
        }
        if(addingRCM && category in SPLIT_NACH_RCM_METHODS)  {
            if(obj[category]){
                obj[category]["isCompanyLevel"] = false
            } else {
                obj[category] = {
                    "isCompanyLevel" : false
                }
            }
        }
        setSelectedOptions(obj);
        handleChange("collectionMethods", obj);
    }

    const onChangeCompanyLevel = ({category,isCompanyLevel,selectedOptionsObj}) => {
        let obj = { ...selectedOptions };
        if(selectedOptionsObj){
            obj = {...selectedOptionsObj}
        }
        if(obj[category]){
            obj[category]["isCompanyLevel"] = isCompanyLevel
        } else {
            obj[category] = {
                "isCompanyLevel" : isCompanyLevel
            }
        }
        setSelectedOptions(obj);
        handleChange("collectionMethods", obj);
    }

    const renderInput = (category, type, value, index,userId) => {
        if (type === DATES) {
            return (
                <div className='dflex'>
                    <input
                        type='number'
                        className={`noscroll ${Input}`}
                        required
                        placeholder="Start date"
                        disabled={isDisabled}
                        value={selectedOptions?.[category]?.[START_DATE]}
                        onChange={e => onChange({
                            category,
                            keyName: START_DATE,
                            keyValue: e.target.value
                        })}
                    />
                    <input
                        type='number'
                        className={`noscroll ${Input}`}
                        required
                        placeholder="End date"
                        disabled={isDisabled}
                        value={selectedOptions?.[category]?.[END_DATE]}
                        onChange={e => onChange({
                            category,
                            keyName: END_DATE,
                            keyValue: e.target.value
                        })}
                    />
                </div>
            );
        }
        if (type === AMOUNT) {
            return (
                <input
                    type='number'
                    className={`noscroll ${Input}`}
                    required
                    placeholder="Amount"
                    disabled={isDisabled}
                    value={selectedOptions?.[category]?.[AMOUNT]}
                    onChange={e => onChange({
                        category,
                        keyName: AMOUNT,
                        keyValue: e.target.value
                    })}
                />
            );
        }
        if(category === COBORROWER){
            return (
                <>
                    <select name="coborrower"  id={coborrowerDropdown} 
                    onChange={(e) => onChange({
                        category,
                        keyName: TYPE,
                        keyValue: e.target.value,
                        elementIndex: index ?? 0
                    })}
                    className={isDisabled ? `${Selected} ${DisabledSelected}` : Selected}
                    disabled={isDisabled}
                    value={type}
                    >
                        <option disabled selected value>Select</option>
                        <option value={COMPANY}>Company</option>
                        <option value={INDIVIDUAL}>Individual</option>
                    </select>
                    {
                    type===INDIVIDUAL ?
                    <>
                        <input
                            type='text'
                            id={`${coborrowerName}`}
                            list={INDIVIDUAL}
                            // className={isDisabled ? `${Selected} ${DisabledSelected}` : Selected}
                            disabled={isDisabled||userId}
                            onClick={(e)=>{
                                e.preventDefault();
                                e.stopPropagation();
                                setFilteredUsers([])
                                setViewUsers(index)}}
                            onChange={e => {onChange({
                                category,
                                keyName: NAME,
                                keyValue: e.target.value,
                                elementIndex: index ?? 0
                            })
                            renderSearchResults(e.target.value)
                        }}
                            value={value}
                            autocomplete={OFF}
                        /> 
                        <div className={viewUsers===index ? Options:OptionsHide}>
                            {!isDisabled && filteredUsers && filteredUsers.map((item)=>(
                                <span
                                className="dflex-ac"
                                onClick={()=>setValue(item,index)}
                                >
                                    {item?.name}
                                </span>
                            ))}
                        </div>
                    </>
                    :
                    <input
                        type='text'
                        id={`${coborrowerName}`}
                        className={isDisabled ? `${Selected} ${DisabledSelected}` : Selected}
                        disabled={isDisabled}
                        onChange={e => onChange({
                            category,
                            keyName: NAME,
                            keyValue: e.target.value,
                            elementIndex: index ?? 0
                        })}
                        value={value}
                        autocomplete={OFF}
                    />
                    }
                </>
            )
        }
        return null;
    }

    return (
        <div>
            {(Object.keys(selectedOptions ?? {})).map((option, index) => {
                let type = '';
                switch (option) {
                    case RAZORPAY_SPLIT_KEY:
                        type = DATES;
                        break;
                    case CASHFREE_SPLIT_KEY:
                        type = DATES;
                        break;
                    case DIRECT_COLLECTION_KEY:
                        type = AMOUNT;
                        break;
                    case MAB:
                        type = AMOUNT;
                        break;
                    case COBORROWER:
                        type = NAME;
                        break;
                    default:
                        break;
                }
                if(option===COBORROWER && selectedOptions?.[option]){
                    return selectedOptions?.[option]?.map((item,index)=>{
                        return (
                            <div key={index} className='mb-2'>
                                <div className={twoInput}>
                                    <CoborrowerDropdowns userNameForCoborrower={coborrowerList} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} isDisabled={isDisabled} index={index} handleChange={handleChange} item={item}/>
                                    {/* {renderInput(option, item?.[TYPE], item?.[NAME], index,item['user_id'])} */}
                                </div>
                            </div>);
                    })
                }
                return (
                    <div key={index} className='mb-2'>
                        <div className={twoInput}>
                            {<div className={isDisabled ? `${DisabledSelected} ${type ? Selected: SelectedWithoutType}`: type ? `${Selected}`:`${SelectedWithoutType}`}>{COLLECTION_METHODS[option]}</div>}
                            {renderInput(option, type)}
                            {<img className={isDisabled ?removeInActive:removeActive} src="/assets/remove.svg" alt={option} onClick={(e)=>removeRCM(e)}/>}
                        </div>
                       { option in SPLIT_NACH_RCM_METHODS && 
                        <div className={containerBox}>
                            <div className={ApplicableCompanyText}>
                                Applicable on company level ?
                            </div>
                            <ToggleButton
                                disabled={isDisabled}
                                companyLevelBtn={true}
                                checked={selectedOptions[option]?.isCompanyLevel}
                                onChange={() => onChangeCompanyLevel({
                                    category : option,
                                    isCompanyLevel : !selectedOptions[option]?.isCompanyLevel
  
                                })}
                            />
                        </div>}
                    </div>
                );
            })}
            <MultiSelect
                options={option}
                isDisabled={isDisabled}
                selectedOptions={Object.keys(selectedOptions ?? {})}
                onChange={onChange}
            />
            {/* {showOptions ? (
                <img src="/assets/rcff-down.svg" alt="" />
            ) : (
                <img src="/assets/rcff-up.svg" alt="" />
            )} */}
        </div>
    );
}
