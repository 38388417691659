import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";
import { priceFormat } from "../../../../../../../../../../util/utility";
import Summary from "./components/Summary";
import styles from "./OverallView.module.scss";

const {
    overallView,
    repaymentRemainingCard,
    repaymentTotalColor,
    overallViewCard,
    cardDate,
    notAvailable,
} = styles;

const OverallView = ({ nachSummary, overall, repaymentRemaining, spoc }) => {
    return (
        <div className={overallView}>
            <Summary
                isDataPresent={!isEmpty(overall)}
                totalValue={overall?.total}
                metValue={overall?.met}
                metUnverifiedValue={overall?.metUnverified}
                partialValue={overall?.partially}
                notMetValue={overall?.not_met}
                title="Overall Summary"
            />
            <Summary
                isDataPresent={!isEmpty(nachSummary)}
                totalValue={nachSummary?.total}
                metValue={nachSummary?.met}
                partialValue={nachSummary?.partially}
                notMetValue={nachSummary?.not_met}
                title="NACH Summary"
            />
            <div>
                Repayment Remaining
                {repaymentRemaining ? (
                    <div className={repaymentRemainingCard}>
                        <span>
                            ₹{priceFormat(repaymentRemaining.totalRepaymentAmount)}
                            {" (" + repaymentRemaining.lastOrder?.repaymentRemaining}
                        </span>
                        <span className={repaymentTotalColor}>
                            {"/" + repaymentRemaining.lastOrder?.totalRepayments}
                        </span>
                        <span>{")"}</span>
                    </div>
                ) : (
                    <div className={notAvailable}>N/A</div>
                )}
            </div>
            <div>
                Last SPOC Name
                {spoc && spoc.last_spoc && spoc.last_update ? (
                    <div className={overallViewCard}>
                        <span>{spoc.last_spoc}</span>
                        <span className={cardDate}>
                            on {dayjs(spoc.last_update).format("DD MMM YYYY")}
                        </span>
                    </div>
                ) : (
                    <div className={notAvailable}>N/A</div>
                )}
            </div>
        </div>
    );
};

export default OverallView;
