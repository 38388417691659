import { allow } from 'joi';
import React, { useState } from 'react';
import styled from "styled-components";

//StyledContainer Width was : ${98 * tabsLength}px;
const StyledContainer = styled.div`
    width: max-content;
    margin: 20px 0px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #F1F1F1;
    background-color: #F8F8F8;
    height: max-content;
    display: flex;
    align-items: center;
    & a:hover{
        text-decoration: none;       
    }
    text-decoration: none;
`;
const StyledUL = styled.ul`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    list-style: none;
    border: none;
    padding: 0px;
`;

const StyledList = styled.li`
    margin-bottom: -1px;
`;

const StyledTabs = styled.a`
    width: max-content;
    color: ${props => props.active ? "#FFFFFF" : "#939393"};
    font-family: 'Graphik';
    font-size: 15px;
    letter-spacing: 0;
    line-height: 14px;
    text-align: center;
    font-weight: 500;
    display: flex;
    align-item: center;
    padding: 8px 25px;
    margin-right: 0px;
    text-decoration: none;
    border-radius: ${props => props.active ? "4px" : "0px"};
    background-color: ${props => props.active ? "#4C8DFF" : 'transparent'};
    border-color: ${props => props.active ? "#dee2e6 #dee2e6 #fff" : null};
    &:hover {
        color: ${props => props.active ? "#FFFFFF" : "#939393"};
    }
`;

function StatusBar({ tabs, currentTab, setCurrentTab, allowOnlyForwardSelection }) {
    const handleClick = (label) => {
        setCurrentTab(label);
    }

    const tabsToMap = tabs.map((item, index) => {
        let obj = {};
        obj.id = index + 1;
        obj.label = item;
        return obj;
    })
    const selectedTabIndex = tabs.indexOf(currentTab)
    return (
        <StyledContainer style={{ margin: "0px" }}>
            <StyledUL role="tablist">
                {tabsToMap.map((item, index) => (
                    <StyledList key={index}>
                        <StyledTabs
                            data-toggle="tab"
                            href={'menu' + item.id}
                            onClick={() => handleClick(item.label)}
                            active={currentTab === item.label}
                            style={{ pointerEvents: allowOnlyForwardSelection && (index < selectedTabIndex) ? "none" : "" }}
                        >
                            <span>{item.label}</span>
                        </StyledTabs>
                    </StyledList>
                ))}
            </StyledUL>
        </StyledContainer>
    )
}

export default StatusBar;
