import CommonTable from "../../../../../../../../../../../components/CommonTable/CommonTable";
import COMPONENTENUMS from "../../../../../../../../../../../components/StatusTags/StatusTags";
import ToggleButton from "../../../../../../../../../../../components/ToggleButton/ToggleButton";
import { Button } from "react-bootstrap";
import "./NeedInfoTable.scss";
import { useEffect, useState } from "react";
import NeedInfoDrawer from "./NeedInfoDrawer/NeedInfoDrawer";
import {
  addGlobalNeedInfo,
  getAllInvestors,
  getGlobalNeedInfo,
  sendGlobalNeedInfoMail,
  updateGlobalNeedInfo,
} from "../../../../../../../../../../../common/ApiContainer";
import {
  GLOBAL_NEED_INFO_STATUS,
  downloadFileUsingFetch,
  showNotification,
} from "../../../../../../../../../../../util/utility";
import {
  LOAN_TYPE,
  SHOW_NOTIFICATION_STATUS,
} from "../../../../../../../../../../../constants/enums";
import { useSelector } from "react-redux";
import { getInvesteesOrganization } from "../../../../../../../../../AdminReducers";
import moment from "moment";
import { getUserId } from "../../../../../../../../../../App/AppReducer";
import ReactTooltip from "react-tooltip";

const DEFAULTFORMARRAY = [
  {
    data_type: "",
    data_type_error: false,
    investors: [],
    investor: "",
    description: "",
    allow_document_upload: true,
    allow_text: true,
    scale_ds_request: true,
    mandatory: true,
  },
];
const dataType = {
  ONBOARDING: "Onboarding",
  ADD_ON_LIMIT: "ADD_ON_LIMIT",
};

function NeedInfoTable({ monthlyData }) {
  const [showRelevent, setShowRelevent] = useState(false);
  const investeeDetails = useSelector(getInvesteesOrganization);
  const investeeOrgId = useSelector(getInvesteesOrganization);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [investorOptions, setInvestorOptions] = useState(null);
  const [disableDataRequest, setDisableDataRequest] = useState(true);
  const [rejectRequest, setRejectRequest] = useState(false);
  const [data, setData] = useState([]);
  const userId = useSelector(getUserId);
  const [needInfoFormArray, setNeedInfoFormArray] = useState(DEFAULTFORMARRAY);
  const columns = [
    {
      heading: "Data Type",
      path: "dataType",
      cell: (value) => {
        return <p style={{ margin: 0 }}>{value}</p>;
      },
    },
    {
      heading: "Details Required",
      path: "comment",
      tdProps: {
        style: {
          width: "250px",
        },
      },
      cell: (value) => {
        return (
          <p
            style={{
              margin: 0,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "250px",
            }}
            title={value}
          >
            {value}
          </p>
        );
      },
    },
    {
      heading: "Source",
      path: "applicationType",
    },
    {
      heading: "Last Activity",
      path: "updatedAt",
      cell: (value) => {
        const formattedDate = moment(value).format("DD MMM YYYY");
        return <p style={{ margin: 0 }}>{formattedDate}</p>;
      },
    },
    {
      heading: "Status",
      path: "status",
      cell: (value) => {
        let Component = COMPONENTENUMS[value]();
        return Component;
      },
    },
    {
      heading: "Data Submitted",
      path: "submission",
      cell: (value, item) => {
        return (
          <div className="data-submitted-wrapper">
            {item.responseText?.length ? (
              <>
                <img
                  src={"/assets/black_info.png"}
                  alt={"info-icon"}
                  data-for={`match-percentage-${item.id}`}
                  data-tip=""
                />
                <ReactTooltip
                  key={item.id}
                  id={`match-percentage-${item.id}`}
                  type="light"
                  place="left"
                  className="needinfo-tooltip"
                  border={true}
                  borderColor="black"
                  delayShow={50}
                  multiline={true}
                >
                  <p>{item.responseText}</p>
                </ReactTooltip>
              </>
            ) : (
              <img
                src={"/assets/disable_info.png"}
                alt={"info-icon"}
                style={{ cursor: "no-drop" }}
              />
            )}
            {item.docs?.length ? (
              <img
                src={"/assets/download_blue.png"}
                alt={"info-icon"}
                onClick={() => {
                  handleDownloadZip(item);
                }}
              />
            ) : (
              <img
                src={"/assets/disbale_download.png"}
                alt={"info-icon"}
                style={{ cursor: "no-drop" }}
              />
            )}
          </div>
        );
      },
    },
    {
      heading: "Actions",
      path: "action",
      cell: (value, item) => {
        return (
          <div className="data-submitted-wrapper">
            {item.status === "Submitted" || (item.responseText?.length || item.docs?.length) ? (
              <img
                src={"/assets/accept.png"}
                alt={"info-icon"}
                onClick={() => {
                  handleApprove(item);
                }}
              />
            ) : (
              <img
                src={"/assets/disable_approve.png"}
                alt={"info-icon"}
                style={{ cursor: "no-drop" }}
              />
            )}
            {item.status !== "Not Approved" && item.status !== "Approved" ? (
              <img
                src={"/assets/reject.png"}
                alt={"info-icon"}
                onClick={() => {
                  handleRejectRequest(value, item);
                }}
              />
            ) : (
              <img
                src={"/assets/disable_reject.png"}
                alt={"info-icon"}
                style={{ cursor: "no-drop" }}
              />
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (investeeOrgId) getGlobalNeedInfoData();
  }, [showRelevent, investeeOrgId]);

  useEffect(() => {
    async function getAllInvestorDetails() {
      try {
        const investorResponse = await getAllInvestors();
        const _options = Object.keys(investorResponse.data.data).map((item) => {
          return {
            label: item,
            value: investorResponse.data.data[item],
            disabled: false,
          };
        });
        setInvestorOptions(_options);
      } catch (err) {
        setInvestorOptions([]);
        showNotification(
          SHOW_NOTIFICATION_STATUS.ERROR,
          "Error while fetching investor list"
        );
      }
    }
    getAllInvestorDetails();
  }, []);

  useEffect(() => {
    if (monthlyData.length) {
      const latestAddOn=[...monthlyData].shift()
      const onBoardingDetails = monthlyData.find((item) => {
        return item.due_date === dataType.ONBOARDING;
      });
      const _disableDataRequest = onBoardingDetails.status !== "Approved" || latestAddOn.status !== "Approved";
      setDisableDataRequest(_disableDataRequest);
    }
  }, [monthlyData]);

  const onClose = () => {
    // setNeedInfoFormArray();
    if (rejectRequest) {
      setNeedInfoFormArray(DEFAULTFORMARRAY);
      setRejectRequest(false);
    }
    setOpenDrawer(false);
  };

  async function handleDownloadZip(item) {
    try {
      const fileName = `${item.dataType}.zip`;
      downloadFileUsingFetch(
        `onboarding/zip-global-need-info-docs?id=${item.id}`,
        "get",
        {
          responseType: "blob",
        },
        fileName,
        () => {},
        false,
        {},
        true
      );
    } catch (err) {
      showNotification(
        SHOW_NOTIFICATION_STATUS.ERROR,
        "Error while downloading the file! Please try again later"
      );
    }
  }

  async function handleApprove(item) {
    try {
      const payload = {
        id: item.id,
        moderatedBy: userId,
        action: GLOBAL_NEED_INFO_STATUS.APPROVE,
        investeeOrgId: item.investeeOrgId,
      };
      const response = await updateGlobalNeedInfo(payload);
      if (response.data.responseData.responseCode === 20) {
        getGlobalNeedInfoData();
        showNotification(
          SHOW_NOTIFICATION_STATUS.SUCCESS,
          response.data.responseData.responseMessage ?? "Request submitted successfully!"
        );
      }else{
        showNotification(
          SHOW_NOTIFICATION_STATUS.ERROR,
          "Error while updating! Please try again later"
        );
      }
    } catch (err) {
      showNotification(
        SHOW_NOTIFICATION_STATUS.ERROR,
        "Error while updating! Please try again later"
      );
    }
  }

  function updateInvestorList(item) {
    const investorlist = [];
    for (let i = 0; i < investorOptions.length; i++) {
      for (let j = 0; j < item.investorOrgId.length; j++) {
        if (item.investorOrgId[j] === investorOptions[i].value) {
          investorlist.push(investorOptions[i]);
        }
      }
    }
    return investorlist;
  }
  const handleRejectRequest = async (value, item) => {
    try {
      if(!disableDataRequest){
        setOpenDrawer(true);
      }
      setRejectRequest(true);
      const _rejectedNeedInfo = {
        data_type: item.dataType,
        data_type_error: false,
        investors: item.investorOrgId.length ? updateInvestorList(item) : [],
        investor: "",
        description: item.comment,
        allow_document_upload: item.requireDocument,
        allow_text: item.requireText,
        scale_ds_request: item.loanType === LOAN_TYPE.DEBT_SYNDICATION,
        mandatory: item.isOptional,
      };
      setNeedInfoFormArray([_rejectedNeedInfo]);
      const payload = {
        id: item.id,
        moderatedBy: userId,
        action: GLOBAL_NEED_INFO_STATUS.INCOMPLETE,
        investeeOrgId: item.investeeOrgId,
      };
      const response = await updateGlobalNeedInfo(payload);
      if (response.data.responseData.responseCode === 20) {
        getGlobalNeedInfoData();
        showNotification(
          SHOW_NOTIFICATION_STATUS.SUCCESS,
          // response.data.responseData.responseMessage
          "Request Rejected Successfully"
        );
      }
    } catch (err) {
      showNotification(
        SHOW_NOTIFICATION_STATUS.ERROR,
        "Error while updating! Please try again later"
      );
    }
  };

  async function getGlobalNeedInfoData() {
    try {
      const payload = {
        investeeOrgId: investeeOrgId?._id,
        filters: {
          // loanType: "Debt Syndication",
          source: "ZEUS",
          showRelevant: showRelevent,
        },
      };
      const response = await getGlobalNeedInfo(payload);
      if (response.data.responseData.responseCode===20) { 
        setData(response.data.data.globalNeedInfoList);
      }else{
        showNotification(
          SHOW_NOTIFICATION_STATUS.ERROR,
          "Error While Fetching Global Need Info Data"
        );
        setData([])
      }
    } catch (err) {
      showNotification(
        SHOW_NOTIFICATION_STATUS.ERROR,
        "Error While Fetching Global Need Info Data"
      );
    }
  }

  async function submitGlobalNeedInfoData(payload) {
    try {
      const response = await addGlobalNeedInfo(payload);
      if (response.data.responseData.responseCode===20) {
        showNotification(
          SHOW_NOTIFICATION_STATUS.SUCCESS,
          response.data.responseData.responseMessage ?? "Request submitted successfully!"
        );
        getGlobalNeedInfoData();
        setNeedInfoFormArray(DEFAULTFORMARRAY);
      }else{
        showNotification(
          SHOW_NOTIFICATION_STATUS.ERROR,
          response.data.responseData.responseMessage ?? "Error While Adding Global Need Info Data!"
        );
      }
    } catch (err) {
      showNotification(
        SHOW_NOTIFICATION_STATUS.ERROR,
        "Error While Adding Global Need Info Data"
      );
    }
  }

  async function handleSendEmail() {
    try {
      const response = await sendGlobalNeedInfoMail(investeeOrgId?._id);
      if(response.data.responseData.responseCode===20){
        showNotification(
          SHOW_NOTIFICATION_STATUS.SUCCESS,
          response.data.responseData.responseMessage
        );
      }else{
        showNotification(
          SHOW_NOTIFICATION_STATUS.ERROR,
          response.data.responseData.responseMessage ?? "Facing issue while sending mail! Please try again later"
        );
      }

    } catch (err) {
      showNotification(
        SHOW_NOTIFICATION_STATUS.ERROR,
        err.data.responseData.responseMessage ?? "Facing issue while sending mail! Please try again later"
      );
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    const inValidInfoFormArray= needInfoFormArray.find((item)=>{
      if(!item.data_type.length || item.data_type_error || !item.description.length || (!item.allow_document_upload && !item.allow_text) ) return item
    })
    if(inValidInfoFormArray===undefined){
      onClose();
      const payload = needInfoFormArray.map((item) => {
        return {
          dataType: item.data_type,
          comment: item.description,
          requireText: item.allow_text,
          requireDocument: item.allow_document_upload,
          applicationType: "Data Vault",
          queriedById: investeeDetails.user_id,
          isOptional: !item.mandatory,
          loanType: item.scale_ds_request
            ? LOAN_TYPE.DEBT_SYNDICATION
            : LOAN_TYPE.RECURRING_REVENUE_FINANCING,
          investorOrgId: item.investors.map((item) => item.value),
          investeeOrgId: investeeDetails._id,
        };
      });
      submitGlobalNeedInfoData(payload);
      return;
    }
      if(!inValidInfoFormArray?.description?.length){
      showNotification(SHOW_NOTIFICATION_STATUS.ERROR, "Description is Required");
      }else if(!inValidInfoFormArray?.data_type?.length || inValidInfoFormArray?.data_type_error){
        let _needInfoFormArray = [...needInfoFormArray];
        _needInfoFormArray.forEach((item) => {
          if (!item.data_type.length || item.data_type_error) {
            item.data_type_error = true;
          } else {
            item.data_type_error = false;
          }
        });
        setNeedInfoFormArray(_needInfoFormArray);
      showNotification(SHOW_NOTIFICATION_STATUS.ERROR, "Data type is Required");
      }else if(!inValidInfoFormArray?.allow_document_upload && !inValidInfoFormArray?.allow_text){
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, "Atleast Allow Document Upload or Allow Text Input is Required");
      }
  }
  return (
    <>
      <div className="needinfoWrapper">
        <div className="needInfoHeadingWrapper">
        <h2 className="needInfoheading">Data Requests</h2>
       {disableDataRequest? <p className="warningText">Section is disabled during Moderation </p>:null}
        </div>
        <div className="needInfoCTAWrapper">
          <div className="toogleWrapper">
            <div className="DocumentVaultSwitch mr-2 mb-n1">
              <ToggleButton
                checked={showRelevent}
                onChange={() => setShowRelevent((prev) => !prev)}
                disabled={disableDataRequest}
              />
            </div>
            <p style={{ color: disableDataRequest && "#979797"}}>Show Relevant</p>
          </div>
          <Button
            variant="success"
            size="sm"
            onClick={handleSendEmail}
            disabled={disableDataRequest}
          >
            Email Need Info Req.
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              setOpenDrawer(true);
            }}
            disabled={disableDataRequest}
          >
            New Data Request
          </Button>
        </div>
      </div>
      <div className="need-info-table-wrapper">
        <CommonTable
          columns={columns}
          data={data}
          tableClassName={"need-info-table"}
          hover={false}
        />
      </div>
      <NeedInfoDrawer
        open={openDrawer}
        onClose={onClose}
        needInfoFormArray={needInfoFormArray}
        setNeedInfoFormArray={setNeedInfoFormArray}
        handleSubmit={handleSubmit}
        investorOptions={investorOptions}
        setInvestorOptions={setInvestorOptions}
        data={data}
      />
    </>
  );
}

export default NeedInfoTable;
