import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import last from "lodash/last";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import SaveSVG from "../../../../../../../../../../../components/SVGs/SaveSVG";
import DealInvestor from "./DealInvestor";
import {
  downloadFileFromArrayBuffer,
  showNotification,
} from "../../../../../../../../../../../util/utility";
import callApi from "../../../../../../../../../../../util/apiCaller";
import { getCheckIfUserHasRelevantPermissions } from "../../../../../../../../../../App/AppReducer";
import {
  investmentReportS3Sign,
  updateInvesteeDocuments,
  uploadDocumentToS3,
} from "../../../../../../../../../../App/AppActions";
import { fetchInvesteeDetails } from "../../../../../../../../../AdminActions";
import { getObjAndFileName } from "../../../overviewUtility";
import InvestorPriorityModal from "./InvestorPriorityModal";
import "../../../../../../../../../../../css/TotCss/DealOverview.scss";
import { API_STATUS, TOAST_TYPES } from "../../../../../../../../../../../constants/enums";
import ManageInvestorModal from "../../../../../../../../../../../components/ManageInvestorModal/ManageInvestorModal";
import { RECUR_INVESTOR_DEAL_NAME } from "../../../../DocumentVault/consts/consts";

export default function Deal({
  isMountedFromOverviewPage = false,
  initialDeal,
  index,
  fetchDeals,
  investeeOrgId,
  displayName,
  tradingLimit,
  isCreateDealOrEditClicked,
  setIsCreateDealOrEditClicked,
  handleCancelForCreatedDeal,
  totId,
  new_unsanctioned_limit,
  tenure,
  totSize,
  ...props
}) {
  const isUserHasEditAccess = useSelector((state) =>
    getCheckIfUserHasRelevantPermissions(state, ["app:edit"])
  );
  const isUserHasUWViewAccess = useSelector((state) => getCheckIfUserHasRelevantPermissions(state, ["underwriting:edit"]));
  const dispatch = useDispatch();
  const dealRef = useRef(null);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [deal, setDeal] = useState(null);
  const [show, setShow] = useState(false);
  const [isSavedDisabled, setIsSavedDisabled] = useState(true);

  useEffect(() => {
    setDeal(JSON.parse(JSON.stringify(initialDeal)));
    dealRef.current = JSON.parse(JSON.stringify(initialDeal));
  }, [initialDeal]);

  useEffect(() => {
    if (isEmpty(initialDeal)) {
      setIsEditClicked(true);
      setIsCreated(true);
      setIsSavedDisabled(false);
      setDeal({
        is_deleted: false,
        deal_name: `${displayName} (${moment().format("DD MMM YYYY")})`,
        deal_size: new_unsanctioned_limit || "0",
        investee_organization_id: investeeOrgId,
        is_deal_changed: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelClick = () => {
    if (isCreated) {
      handleCancelForCreatedDeal(index);
    } else {
      setDeal(JSON.parse(JSON.stringify(dealRef.current)));
      setIsEditClicked(false);
    }
    setIsCreateDealOrEditClicked(false);
  };

  const validateDeal = (obj) => {
    let isValid = true;
    let message = "";
    let sumOfApproved = 0;
    let rIDeal = {}
    deal.investor_deal_offer &&
      deal.investor_deal_offer.forEach(
        (investor) =>{ 
          (sumOfApproved += Number(investor.approved))
          if(investor.investor_name === RECUR_INVESTOR_DEAL_NAME) {
            rIDeal = investor
          }
        }

      );
    if (obj.deal_name.trim() === "") {
        message = "Invalid Deal Name";
        isValid = false;
    } else if (Number(obj.deal_size) < 0 || obj.deal_size === "") {
        message = "Invalid Deal Size";
        isValid = false;
    } else if (Number(deal.deal_size) < Number(obj.deal_size)) { // if deal size is reduced checking if there is enough in RI deal to reduce deal size
        if (
            rIDeal &&
            (Number(rIDeal.allocated_amount) ?? 0) - (Number(rIDeal.drawn) ?? 0) <
                Number(obj.deal_size) - Number(deal.deal_size)
        ) {
            message = "Not enough allocated in RI deal to reduce dealSize";
            isValid = false;
        }
    }
    if (!isCreated && (Number(obj.total_drawn) < 0 || obj.total_drawn === "")) {
      message = "Invalid Total Drawn";
      isValid = false;
    }
    if (!isValid) setIsSavedDisabled(true);
    return { isValid, message };
  };

  const handleSaveClick = () => {
    if(Number(deal.deal_size) !== Number(totSize.toFixed(2))){
      showNotification("error", "Deal size should be equal to TOT size");
      return;
    }
    setIsSavedDisabled(true);
    let obj = {
      investee_organization_id: deal.investee_organization_id,
      deal: {},
    };
    if (!isCreated) {
      //* Updating Deal
      obj.deal._id = deal._id;
      if (deal.is_deal_changed) {
        obj.deal.is_deleted = deal.is_deleted;
        obj.deal.deal_name = deal.deal_name;
        obj.deal.deal_size = deal.deal_size;
        obj.deal.total_drawn = deal.total_drawn;
      }
      //* Updating Investor or Creating Investor for a Deal
      if (deal.investor_deal_offer?.length > 0) {
        obj.investor_deal_offer = [];
        obj.investor_deal_offer = deal.investor_deal_offer
          .filter((investor) => investor?.is_changed)
          .map((investor) => {
            let i = { ...investor };
            delete i.is_changed;
            delete i.updated_at;
            delete i.created_at;
            delete i.investee_organization_id;
            delete i?.is_created;
            return i;
          });
        if (isEmpty(obj.investor_deal_offer)) delete obj.investor_deal_offer;
      }
    } else {
      //* Creating a Deal
      if (deal.is_deal_changed) {
        obj.deal.deal_name = deal.deal_name;
        obj.deal.deal_size = deal.deal_size;
        obj.deal.tot_id = totId;
      }
      //* Creating an Investor with a newly created Deal
      if (deal.investor_deal_offer?.length > 0) {
        obj.deal.investor_deal_offer = [];
        obj.deal.investor_deal_offer = deal.investor_deal_offer
          .filter((investor) => investor?.is_changed)
          .map((investor) => {
            let i = { ...investor };
            i.investee_organization_id = investeeOrgId;
            delete i.is_changed;
            delete i?.is_created;
            return i;
          });
        if (isEmpty(obj.deal.investor_deal_offer))
          delete obj.deal.investor_deal_offer;
      }
    }

    // Validation for Deal
    if (deal.is_deal_changed) {
      const { isValid, message } = validateDeal(obj.deal);
      if (!isValid) {
        showNotification(TOAST_TYPES.ERROR, message);
        return false;
      }
    }

    if (!isCreated) {
      //* Api Call for Update
      callApi("deals/investee-organization/deals_update", "post", obj).then(
        (response) => {
          if (response.status === API_STATUS.SUCCESS) {
            setIsSavedDisabled(false);
            setIsEditClicked(false);
            fetchDeals();
            dealRef.current = deal;
            setIsCreateDealOrEditClicked(false);
          } else {
            const message = response?.message ?? "Some error occured";
            showNotification(TOAST_TYPES.ERROR, message);
          }
        }
      );
    } else {
      //* Api Call for Create
      callApi("deals/investee-organization/create-deals", "post", obj).then(
        (response) => {
          if (response.status === API_STATUS.SUCCESS) {
            setIsSavedDisabled(false);
            setIsCreated(false);
            setIsEditClicked(false);
            fetchDeals();
            dealRef.current = deal;
            setIsCreateDealOrEditClicked(false);
          } else {
            const message = response?.message ?? "Some error occured";
            showNotification(TOAST_TYPES.ERROR, message);
          }
        }
      );
    }
  };

  const handleChange = (keyName, value, index) => {
    let obj = { ...deal };
    if (!isNil(index)) {
      obj.investor_deal_offer[index][keyName] = value;
      obj.investor_deal_offer[index]["is_changed"] = true;
    } else {
      obj[keyName] = value;
      obj["is_deal_changed"] = true;
    }
    setIsSavedDisabled(false);
    setDeal(obj);
  };

  const handleDeleteDeal = () => {
    let obj = {
      deal: {
        _id: deal._id,
        is_deleted: true,
        investor_deal_offer: deal.investor_deal_offer.map((investor) => ({
          investor_organization_id: investor.investor_organization_id,
          is_deleted: true,
        })),
      },
      investee_organization_id: deal.investee_organization_id,
    };
    callApi("deals/investee-organization/deals_update", "post", obj).then(
      (response) => {
        if (response.status === API_STATUS.SUCCESS) {
          fetchDeals();
          showNotification(TOAST_TYPES.SUCCESS, "Deal Deleted Successfully");
        } else {
          showNotification(TOAST_TYPES.ERROR, response?.message);
        }
      }
    );
    handleClose();
  };

  const handleInvestmentReportUpload = async (event) => {
    const files = event.target.files;
    let flag = files.length > 0;
    for (const file of files) {
      const signResponse = await investmentReportS3Sign(file, investeeOrgId);
      if (signResponse) {
        const s3UploadResponse = await uploadDocumentToS3(file, signResponse);
        if (s3UploadResponse) {
          const dataUpdateResponse = await updateInvesteeDocuments(deal?._id, {
            investment_report: signResponse.file_url,
          });
          if (dataUpdateResponse) fetchDeals();
          flag = flag && dataUpdateResponse;
        } else {
          showNotification(
            TOAST_TYPES.ERROR,
            `Could not upload file: ${file.name} to S3`
          );
          flag = false;
        }
      } else {
        showNotification(TOAST_TYPES.ERROR, `Could not sign file: ${file.name}`);
        flag = false;
      }
    }
    if (flag)
      showNotification(TOAST_TYPES.SUCCESS, "Investment report(s) uploaded successfully");
    dispatch(fetchInvesteeDetails(investeeOrgId));
    event.target.value = "";
  };

  const deleteInvestmentReport = async () => {
    const res = await updateInvesteeDocuments(deal?._id, {
      investment_report: "",
    });
    if (res) fetchDeals();
  };

  const downloadReport = (report, key = null) => {
    if (report === "" || isNil(report)) {
      showNotification(TOAST_TYPES.ERROR, "Please upload Investment Report");
      return;
    }
    let result = {};

    if (isNil(key)) {
      result = getObjAndFileName(report);
    } else {
      result = { obj: { url: key }, fileName: last(key?.split("/")) };
    }

    const getBuffer = (data) => {
      return data.file.Body.data;
    };
    downloadFileFromArrayBuffer(
      "deals/admin/download-from-s3",
      "post",
      result?.obj,
      null,
      result?.fileName,
      { type: "application/pdf" },
      getBuffer
    );
  };

  const downloadBothReports = (InvestorReport, isOffer, offerId) => {
    // downloadReport(deal?.investment_report);
    if (!isOffer) downloadReport(InvestorReport);
    else {
      callApi(
        `autoInvest/investment_report/offer/${offerId}/regenerate-download`
      ).then((res) => {
        if (res.status === API_STATUS.SUCCESS) {
          downloadReport(res.data, res.data);
        } else {
          const message = res?.status ?? "Some error occured";
          showNotification(TOAST_TYPES.ERROR, message);
        }
      });
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const imgStyle = {
    width: "29px",
    height: "40px",
    marginTop: "-30px",
    marginBottom: "15px",
  };
  const disabled =
    "disable-btn save-btn btnht1 d-flex align-items-center justify-content-center";

  return (
    <>
      {!deal?.is_deleted && (
        <>
          <Modal
            show={show}
            onHide={handleClose}
            className="Trash-modal"
            size={{ width: "446px", height: "265px" }}>
            <div>
              <div class="modal-header">
                <button
                  onClick={handleClose}
                  type="button"
                  class="close ml-auto">
                  <img src="/assets/clear.png" alt="Clear" />
                </button>
              </div>
              <div class="modal-body">
                <img
                  style={imgStyle}
                  className="i-modal-icon"
                  src="/assets/dustbin-red.svg"
                  alt=""
                />
                <div className="fs21 fw6 mb-3 clr-red">Delete Deal</div>
                Are you sure you want to Delete {deal?.deal_name}
                <br />
                <span className="dflex" style={{ marginTop: "34px" }}>
                  <button
                    className="btn6"
                    onClick={handleClose}
                    style={{
                      width: "45%",
                      height: "40px",
                      borderColor: "#D95656",
                      color: "#D95656",
                      backgroundColor: "white",
                      fontSize: "15px",
                    }}>
                    Cancel
                  </button>
                  <button
                    className="btn6"
                    onClick={() => handleDeleteDeal()}
                    style={{
                      width: "45%",
                      height: "40px",
                      backgroundColor: "red",
                      fontSize: "15px",
                    }}>
                    Delete
                  </button>
                </span>
              </div>
            </div>
          </Modal>
          <div
            className={
              isEditClicked
                ? "overview-border2 mt-2"
                : "overview-border2 p-3 mt-2"
            }>
            {isEditClicked ? (
              <>
                {/* Cancel & Save btn */}
                <div className="d-flex align-items-center p-3">
                  <button
                    style={{ cursor: "pointer" }}
                    className="mr-4 btn6 btnht1 d-flex align-items-center justify-content-center ml-auto"
                    onClick={handleCancelClick}>
                    <img
                      src="../../../../../assets/icon-clear.svg"
                      alt="cross"
                      className="mr-2"
                      width="11px"
                    />
                    <span>Cancel</span>
                  </button>
                  <button
                    disabled={isSavedDisabled}
                    onClick={() => handleSaveClick()}
                    className={
                      isSavedDisabled
                        ? disabled
                        : "save-btn btn5 btnht1 d-flex align-items-center justify-content-center"
                    }>
                    <SaveSVG />
                    <span>Save</span>
                  </button>
                </div>
              </>
            ) : (
              <>
                {/* Edit & Delete btn */}
                {!isCreateDealOrEditClicked && !isMountedFromOverviewPage && (
                  <div className="d-flex align-items-center p-3">
                    {(isUserHasEditAccess||isUserHasUWViewAccess) && (
                      <>
                        {Array.isArray(deal?.priority_list) &&
                          deal?.priority_list?.length > 0 && (
                            <span
                              className="btn3 d-flex align-items-center justify-content-center ml-auto fs10"
                              data-toggle="modal"
                              data-target={`#investor-priority-modal-${deal?._id}`}
                            >
                              <img
                                src="/assets/create.png"
                                alt="Edit"
                                width="11px"
                                className="mr-1"
                              />
                              <span className="mr-1">Edit Priority</span>
                            </span>
                          )}
                        <button
                          style={{ cursor: "pointer" }}
                          className={`save-btn btn3 d-flex align-items-center justify-content-center ${
                            Array.isArray(deal?.priority_list) &&
                            deal?.priority_list?.length > 0
                              ? "ml-3"
                              : "ml-auto"
                          } fs10`}
                          onClick={() => {
                            setIsEditClicked(true);
                            setIsCreateDealOrEditClicked(true);
                          }}
                        >
                          <img
                            src="/assets/create.png"
                            alt="Edit"
                            width="11px"
                            className="mr-1"
                          />
                          <span className="mr-1">Edit Deal</span>
                        </button>
                        {/* <div
                          onClick={() => handleShow()}
                          style={{ cursor: "pointer" }}
                          className="ml-2 d-flex align-items-center justify-content-center">
                          <img
                            src="/assets/dustbin-red.svg"
                            alt="cross"
                            className="mr-2"
                            width="16px"
                          />
                        </div> */}
                      </>
                    )}
                  </div>
                )}
              </>
            )}

            {/* Deal Name, Deal Size & Total Drawn */}
            <div
              className={
                isEditClicked
                  ? "row px-3 py-2 mx-0 border-bottom"
                  : "row bgclr2 p-2 mt-3 br4 mx-0"
              }>
              <div className="col-md-5">
                <div className="dflexJA">
                  <div className="fs10 w-50">Deal Name</div>
                  {isEditClicked ? (
                    <>
                      <input
                        type="text"
                        className="w-50"
                        value={deal?.deal_name}
                        name=""
                        required
                        onChange={(e) =>
                          handleChange("deal_name", e.target.value)
                        }
                      />
                      <br />
                    </>
                  ) : (
                    <div
                      className=" w-50 fs12 clr-purple text-truncate expand"
                      style={{ maxWidth: "50%" }}>
                      {deal?.deal_name}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <div className="dflexJA">
                  <div className="fs10">Drawn / Deal Size (lacs)</div>
                  {isEditClicked ? (
                    <div style={{ width: "60%" }} className="dflexJA">
                      <input
                        type="text"
                        className="mr-1"
                        value={deal?.total_drawn}
                        name=""
                        required
                        onChange={(e) =>
                          handleChange("total_drawn", e.target.value)
                        }
                      />
                      /
                      <input
                        type="number"
                        className="ml-1"
                        value={deal?.deal_size}
                        name=""
                        required
                        onChange={(e) =>
                          handleChange("deal_size", e.target.value)
                        }
                      />
                    </div>
                  ) : (
                    <div className="fs12 clr-purple" style={{ width: "40%" }}>
                      {Number(deal?.total_drawn)?.toFixed(3)}/{deal?.deal_size}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>

            {deal?.investor_deal_offer?.map((investor, index) => {
              if (!investor.is_deleted) {
                investor.loanType =deal?.loanType ? deal?.loanType : null
                return (
                  <DealInvestor
                    downloadBothReports={downloadBothReports}
                    key={index}
                    investor={investor}
                    isEditClicked={isEditClicked}
                    fetchDeals={fetchDeals}
                    dealId={deal?._id}
                    dealsTotId={deal?.tot_id}
                    totId={totId}
                    queryParams={props.queryParams}
                    investeeOrgId={investeeOrgId}
                    resetQueryParams={props.resetQueryParams}
                    dealName={deal?.deal_name}
                    totMaxTenure={tenure}
                  />
                );
              }
              return null;
            })}

            {!isMountedFromOverviewPage && (
              <ManageInvestorModal
                downloadReport={downloadReport}
                key={index}
                fetchDeals={fetchDeals}
                dealSize={deal?.deal_size}
                dealId={deal?._id}
                dealName={deal?.deal_name}
                totId={totId}
                isInvestmentReportGenerated={deal?.investment_report_generated}
                tenure={tenure}
                loanType={deal?.loanType}
                investorDealOffer={deal?.investor_deal_offer}
              />
            )}

            {Array.isArray(deal?.priority_list) &&
              deal?.priority_list?.length > 0 && (
                <InvestorPriorityModal deal={deal} />
            )}
          </div>
        </>
      )}
    </>
  );
}
