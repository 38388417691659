import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import async from "async";
import Joi from "joi";
import { v4 } from "uuid";
import moment from 'moment';
import { Modal, Button } from "react-bootstrap";
import callApi from '../../../../../../../../../util/apiCaller';
import SaveSVG from '../../../../../../../../../components/SVGs/SaveSVG';
import { getUserName } from '../../../../../../../../App/AppReducer';
import { fetchInvesteeDetails, updateInvesteeDetails } from '../../../../../../../AdminActions';
import { showNotification } from '../../../../../../../../../util/utility';
import { isEmpty, isNil } from 'lodash';
import ConfirmTotUpdate from '../../../../../../../../../components/ConfirmTotUpdateModal/ConfirmTotUpdateModal';
import { getStatusBeforeCompanyEdit } from '../../../../../../../../../common/ApiHelpers';

let initialStateForSalary = {
    salesMarketing: 0,
    customerOperations: 0,
    others: 100
}

export default function DetailsEdit({
    investeeOrgId,
    setIsEditingCompany,
    investeeDetails,
    setInvesteeDetails,
    directorDetails,
    chargesDetails,
    updateDateChar,
    updateDateDir,
    salaryBreakupData,
    originalInvesteeDetails,
}) {

    const company_sectors = [
        "SaaS",
        "Tech Services",
        "B2B Tech Platform",
        "Prop Tech",
        "EdTech",
        "D2C",
        "EV",
        "Fintech",
        "Asset Leasing / Subscription",
        "Other Services"
    ]

    company_sectors.sort((a, b) => (a > b ? 1 : -1));

    const fundings = [
        "Bootstrapped",
        "Angel",
        "Seed",
        "Series A",
        "Series B",
        "Series C",
        "Series D",
        "Listed",
    ];

    const [show, setShow] = useState(false);
    // const [files, setFiles] = useState(null);
    const [fileContents, setFileContents] = useState(null);
    const [editConfirmationDetails, setEditConfirmationDetails] = useState({
        showModal: false,
        title: "",
        header: "",
    });
    const dispatch = useDispatch();
    const userName = useSelector(state => getUserName(state));

    const {
        name,
        display_name,
        company_sector,
        company_sub_sector,
        founded_in,
        funding,
        authorized_designation,
        authorized_signature,
        profile_image,
        company_overview,
        website_url,
        pan_card,
        company_cin,
        gstin_number,
        tan_number,
        address,
        documents
    } = investeeDetails;

    if(isNil(investeeDetails['salaryBreakup'])) investeeDetails['salaryBreakup'] = initialStateForSalary;

    const fetchDetails = () => {
        dispatch(fetchInvesteeDetails(investeeOrgId));
    }

    const handleChange = (keyName, keyVal) => {
        const obj = { ...investeeDetails };
        obj[keyName] = keyVal;
        setInvesteeDetails(obj);
    }

    const removeImageContent = keyName => {
        const obj = { ...investeeDetails };
        obj[keyName] = "";
        setInvesteeDetails(obj);
    };

    const updateImageContent = (keyName, profile_image) => {
        const obj = { ...investeeDetails };
        obj[keyName] = profile_image;
        setInvesteeDetails(obj)
    }

    const uploadProfileImage = file => {
        async.series(
            [
                callback => {
                    callApi(`s3/sign`, "post", {
                        filename: "profile-images/" + v4() + file && file.name,
                        filetype: file.type
                    })
                        .then(res => {
                            const xhr = new XMLHttpRequest();
                            xhr.upload.addEventListener(
                                "progress",
                                e => {
                                    // console.log(e);
                                },
                                false
                            );
                            xhr.onreadystatechange = e => {
                                if (xhr.readyState === 4) {
                                    if (xhr.status === 200) {
                                        updateImageContent("profile_image", res.url);
                                    }
                                    callback(null);
                                }
                            };
                            xhr.open("PUT", res.signed_request);
                            xhr.setRequestHeader("x-amz-acl", "public-read");
                            xhr.send(file);
                        })
                        .catch(err => callback(err));
                },
            ],
            (err, results) => {
                if (err) {
                    // console.log(err);
                    return;
                }
                showNotification("Success", "Successfully updated image");
            }
        );
    }

    const uploadSignatureImage = file => {
        async.series(
            [
                callback => {
                    callApi(`s3/sign`, "post", {
                        filename: "profile-signatures/" + v4() + file.name,
                        filetype: file.type,
                    })
                        .then(res => {
                            const xhr = new XMLHttpRequest();
                            xhr.upload.addEventListener(
                                "progress",
                                e => {
                                    // console.log(e);
                                },
                                false
                            );
                            xhr.onreadystatechange = e => {
                                if (xhr.readyState === 4) {
                                    if (xhr.status === 200) {
                                        updateImageContent("authorized_signature", res.url);
                                    }
                                    callback(null);
                                }
                            };
                            xhr.open("PUT", res.signed_request);
                            xhr.setRequestHeader("x-amz-acl", "public-read");
                            xhr.send(file);
                        })
                        .catch(err => callback(err));
                },
            ],
            (err, results) => {
                if (err) {
                    return;
                }
                showNotification("Success", "Successfully updated image");
            }
        );
    };

    const uploadDocument = file => {
        let data = { url: "" };
        let name = file.name;
        async.series(
            [
                callback => {
                    callApi(`s3/sign`, "post", {
                        filename: "documents/" + v4() + file.name,
                        filetype: file.type,
                    }).then(res => {
                        const xhr = new XMLHttpRequest();
                        xhr.upload.addEventListener(
                            "progress",
                            e => {
                                // console.log(e);
                            },
                            false
                        );
                        data.url = res.url;
                        xhr.open("PUT", res.signed_request);
                        xhr.setRequestHeader("x-amz-acl", "public-read");
                        xhr.send(file);
                        callback(null);
                    });
                },
                callback => {
                    let organization = {};
                    organization.documents = documents
                        ? documents
                        : [];
                    organization.documents.push({
                        file_name: name,
                        file_url: data.url,
                    });
                    callApi(`investeeorganizations/${investeeOrgId}/data-update`, "post", {
                        investeeorganization: investeeDetails
                    }).then(res => {
                        if (res && res.data && res.data.investeeorganization) {
                            fetchDetails();
                        }
                        callback(null, res);
                    })
                        .catch(err => callback(err));
                },
            ],
            (err, results) => {
                if (err) {
                    console.log(err);
                    return;
                }
                console.log(results);
                showNotification("Success", "Successfully uploaded document");
            }
        );
    };

    const schema = Joi.object({
        investeeContractStats: Joi.object({
            opening_arr: Joi.number().required(),
            opening_change: Joi.number().required(),
            new_arr: Joi.number().required(),
            new_change: Joi.number().required(),
            contraction_arr: Joi.number().required(),
            contraction_change: Joi.number().required(),
            churn_arr: Joi.number().required(),
            churn_change: Joi.number().required(),
            closing_arr: Joi.number().required(),
            closing_change: Joi.number().required(),
            expansion_arr: Joi.number().required(),
            expansion_change: Joi.number().required(),
        }),
        investeeProfileMetrics: Joi.object({
            metric: Joi.array().items(
                Joi.object({
                    month: Joi.string().required(),
                    arr: Joi.number().required(),
                    growth: Joi.number().required(),
                })
            ),
        }),
        investeeorganization: Joi.object({
            arr_growth: Joi.number(),
            net_retention: Joi.number(),
            arpa: Joi.number(),
            revenue_churn: Joi.number(),
            customer_churn: Joi.number(),
            ltv_cac: Joi.number(),
            cac_payback: Joi.number(),
            gross_margin: Joi.number(),
            ebitda: Joi.number(),
            cashburn_permonth: Joi.number(),
            runway: Joi.number(),
            net_dollar_retention: Joi.number(),
            closing_arr: Joi.number().required(),
            burn_as_perc_revenue: Joi.number().required(),
            customer_diversity_local: Joi.number().required(),
            customer_diversity_cc: Joi.number().required(),
            ntm_fin_outflow: Joi.number(),
            receivables_cycle: Joi.number().required(),
            debt_amount: Joi.number().required(),
            cash_bal_eval: Joi.number().required(),
            de_ratio: Joi.number().required(),
            remarks: Joi.string().required(),
        }),
    });
    const uploadFile = () => {
        const parsedSchema = schema.validate(JSON.parse(fileContents));
        const value = parsedSchema.value;
        if (parsedSchema.error) {
            showNotification("error", parsedSchema.error.message);
            return;
        }
        async.series(
            [
                callback => {
                    callApi(
                        `admin/investeeorganization/${investeeOrgId}/investeecontracts/data-update`,
                        "post",
                        { investeeContractStats: value.investeeContractStats }
                    ).then(res => {
                        if (res.status === "Success") {
                            fetchDetails();
                            callback(null, res.data.investeeContractStats);
                        } else {
                            callback(res.message);
                            showNotification("error", "Some error occured");
                        }
                    });
                },
                callback => {
                    callApi(
                        `admin/investeeorganization/${investeeOrgId}/investeeprofilemetrics/data-update`,
                        "post",
                        { investeeProfileMetrics: value.investeeProfileMetrics }
                    ).then(res => {
                        if (res.status === "Success") {
                            fetchDetails();
                            callback(null, res.data.investeeProfileMetrics);
                        } else {
                            callback(res.message);
                            showNotification("error", "Some error occured");
                        }
                    });
                },
                callback => {
                    callApi(`investeeorganizations/${investeeOrgId}/data-update`, "post", {
                        investeeorganization: value.investeeorganization,
                    }).then(res => {
                        if (res.status === "Success") {
                            fetchDetails();
                            callback(null, res.data.investeeorganization);
                        } else {
                            callback(res.message);
                            showNotification("error", "Some error occured");
                        }
                    });
                },
            ],
            (err, results) => {
                if (err) {
                    return;
                } else {
                    showNotification("Success", "Successfully added data");
                }
            }
        );
    };

    const handleDocUploadBtn = (documentId) => {
        document.getElementById("documentUpload").click();
    };

    const removeDocument = documentId => {
        let organization = {};
        organization.documents = documents.filter(
            item => item._id !== documentId
        );
        callApi(`investeeorganizations/${investeeOrgId}/data-update`, "post", {
            investeeorganization: investeeDetails
        }).then(res => {
            fetchDetails();
            showNotification("Success", "Successfully removed document");
        });
    };

    const fileChange = e => {
        // setFiles(e.target.files[0]);
        var reader = new FileReader();
        reader.readAsText(e.target.files[0], "UTF-8");
        reader.onload = evt => {
            setFileContents(evt.target.result);
        };
        reader.onerror = evt => {
            setFileContents("error reading file");
        };
    };

    const onCancel = () => {
        fetchDetails();
        setIsEditingCompany(false);
    }

    const onSave = async (proceed = false) => {
        const obj = { ...investeeDetails };
        obj.last_details_updated = {
            updated_at: new Date(),
            updated_by: userName
        };
        if (originalInvesteeDetails?.address !== obj?.address && !proceed) {
            obj.checkSanctionMfaStatus = true;
            const status = await getStatusBeforeCompanyEdit(investeeOrgId, obj);
            if (!isEmpty(status)) {
                if (status.isMfaSlRegenerationRequired) {
                    setEditConfirmationDetails({
                        showModal: true,
                        title: "By changing address details, signed MFA/SL needs to be cancelled, regenerated and re-shared with the company. Are you sure you want to proceed?",
                        header: "Alert - MFA Already Signed!",
                    });
                    return;
                }
                if (status.dealShared) {
                    setEditConfirmationDetails({
                        showModal: true,
                        title: "By changing address details, signed MFA/SL needs to be regenerated and re-shared with the company. Are you sure you want to proceed?",
                        header: "Alert - MFA Already Shared!",
                    });
                    return;
                }
            }
        }
        dispatch(updateInvesteeDetails(investeeOrgId, obj));
        setIsEditingCompany(false);
    }

    /**
     * @description provide an salary breakup object for correct salary split.
     */
    const getCorrectSalaryValue = (intVal, key) => {
        let otherVal = 100;
        const { salaryBreakup } = investeeDetails;
        if (key === 'salesMarketing') {
            otherVal = otherVal - salaryBreakup.customerOperations - intVal;
        } else if (key === 'customerOperations') {
            otherVal = otherVal - salaryBreakup.salesMarketing - intVal;
        }
        if (otherVal < 0) {
            otherVal = 0;
            showNotification('error', 'Sum of percentages must be equal to 100%');
        }
        return {
            ...salaryBreakup,
            [key]: parseInt(intVal, 10),
            others: otherVal,
        }
    }

    const onChangingSalary = (e) => {
        e.preventDefault();
        let isNumber = new RegExp(/^\d+\.?\d*$/).test(e.target.value);
        let value = isNumber ? parseInt(e.target.value) : initialStateForSalary[e.target.name]; // set number only values
        if (value > 100) return;
        let breakUp = getCorrectSalaryValue(value, e.target.name);
        setInvesteeDetails({
            ...investeeDetails,
            salaryBreakup: {
                salesMarketing: breakUp.salesMarketing || 0,
                customerOperations: breakUp.customerOperations || 0,
                others: breakUp.others || 100
            }
        });
    }

    return (
        <section className="col p-0">
            <div className="d-flex justify-content-between flex-wrap align-items-center company-row1 p-4">
                <div className='d-flex align-items-center'>
                    <span className="fs21 mr-4 fw600">Details</span>
                </div>
                <div className="d-flex align-items-center" >
                    <button
                        className='mr-4 btn6 btnht1 d-flex align-items-center justify-content-center'
                        onClick={onCancel}
                    >
                        <img
                            src='../../../../../assets/icon-clear.svg'
                            alt=''
                            className='mr-2'
                            style={{ width: '11px' }}
                        />
                        <span>Cancel</span>
                    </button>
                    <button
                        onClick={() => onSave(false)}
                        className='save-btn btn5 btnht1 d-flex align-items-center justify-content-center'
                    >
                        <SaveSVG />
                        <span>Save</span>
                    </button>
                </div>
            </div>
            <div id="company-detail" className="company-detail pl-4 pr-4 m-0">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="row">
                            <div className="col-lg-9">
                                <p className="cmp-row2-head">Company Name</p>
                                <input
                                    onChange={e => handleChange("name", e.target.value)}
                                    value={name}
                                    className="col-lg-9 small-fonts edit-dropdown dropdown-width1"
                                    type="text"
                                    id="comp-name"
                                    name="comp-name"
                                    placeholder="Company"
                                />
                                <br></br>
                                <p className="cmp-row2-head asterisk">Display Name</p>
                                <input
                                    onChange={e => handleChange("display_name", e.target.value)}
                                    value={display_name}
                                    className="col-lg-9 small-fonts edit-dropdown dropdown-width1"
                                    type="text"
                                    id="comp-name"
                                    name="comp-name"
                                    placeholder="Company"
                                />
                                <br></br>
                                <p className="cmp-row2-head asterisk">Company Sector</p>
                                <select
                                    className="col-lg-9 small-fonts edit-dropdown dropdown-width1"
                                    onChange={e => handleChange("company_sector", e.target.value)}
                                    value={company_sector ? company_sector : ""}
                                    placeholder="Company"
                                    name=""
                                    id=""
                                >
                                    <option value="" disabled>Select type</option>
                                    {company_sectors.map((item, index) => (
                                        <option
                                            key={index}
                                            value={item}
                                        >{item}</option>
                                    ))}
                                </select>
                                <p className="cmp-row2-head">Compay Sub Sector</p>
                                <input
                                    onChange={e => handleChange("company_sub_sector", e.target.value)}
                                    value={company_sub_sector}
                                    className="col-lg-9 small-fonts edit-dropdown dropdown-width1"
                                    type="text"
                                    id="comp-name"
                                    name="comp-name"
                                    placeholder="Company Sub Sector"
                                />
                                <br></br>
                            </div>
                            <div className="col-lg-3" style={{ textAlign: "right" }}>
                                <img
                                    className="cmp-logo"
                                    style={{ position: "relative" }}
                                    key={profile_image}
                                    src={profile_image
                                        ? `${profile_image}?t=${new Date().getTime()}`
                                        : "/assets/company-logo.svg"
                                    }
                                    alt=""
                                />
                                <input
                                    multiple={false}
                                    accept="image/*"
                                    onChange={e => uploadProfileImage(e.target.files[0])}
                                    id="img"
                                    type="file"
                                    style={{ display: "none" }}
                                />
                                <label className="upload-btn upload-btn-onimg" for="img">
                                    <img className="" src="/assets/upload.svg" alt="" />
                                    Upload New
                                </label>
                                <button
                                    onClick={() => removeImageContent("profile_image")}
                                    className="remove-btn"
                                >
                                    <img className="" src="/assets/upload.svg" alt="" />
                                    Remove
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <p className="cmp-row2-head asterisk">Founded</p>
                                <input
                                    onChange={e => handleChange("founded_in", e.target.value)}
                                    value={founded_in ? moment(founded_in).format("YYYY-MM-DD") : "NA"}
                                    type="date"
                                    id="date"
                                    className="small-fonts edit-dropdown dropdown-width2"
                                />
                            </div>
                            <div
                                className="col-lg-6"
                                style={{ padding: "0px 15px 0px 0px" }}
                            >
                                <p className="cmp-row2-head asterisk">Funding</p>
                                <select
                                    className="col-lg-9 small-fonts edit-dropdown dropdown-width1"
                                    onChange={e => handleChange("funding", e.target.value)}
                                    value={funding ? funding : ""}
                                    placeholder="Funding"
                                    name=""
                                    id=""
                                >
                                    <option value="" disabled>Select type</option>
                                    {fundings.map(item => (
                                        <option value={item} key={item}>{item}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div>
                            <p className="cmp-row2-head">Authorized Designation</p>
                            <input
                                onChange={e => handleChange("authorized_designation", e.target.value)}
                                value={authorized_designation}
                                type={"text"}
                                className={"small-fonts edit-dropdown dropdown-width2"}
                            />
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <p className="cmp-row2-head">Authorized Signature</p>
                                <img
                                    className="cmp-logo"
                                    style={{ position: "relative" }}
                                    key={authorized_signature}
                                    src={authorized_signature
                                        ? `${authorized_signature}?t=${new Date().getTime()}`
                                        : "/assets/company-logo.svg"
                                    }
                                    alt=""
                                />
                                <input
                                    multiple={false}
                                    accept="image/*"
                                    onChange={e => uploadSignatureImage(e.target.files[0])}
                                    id="img2"
                                    type="file"
                                    style={{ display: "none" }}
                                />
                                <label className="upload-btn upload-btn-onimg" htmlFor="img2">
                                    <img src="/assets/upload.svg" alt="" />
                                    Upload Sign
                                </label>
                                <button
                                    onClick={() => removeImageContent("authorized_signature")}
                                    className="remove-btn"
                                >
                                    <img src="/assets/upload.svg" alt="" />
                                    Remove
                                </button>
                            </div>
                            <div className="col-sm-6"></div>
                        </div>
                    </div>
                    <div className="col-lg-4" style={{ width: "470px" }}>
                        <div>
                            <p className="cmp-row2-head asterisk">Company Overview</p>
                            <textarea
                                onChange={e => handleChange("company_overview", e.target.value)}
                                value={company_overview}
                                className="cmp-overview"
                                style={{
                                    borderRadius: "4px",
                                    border: "1px solid #d2d2d2",
                                    opacity: "0.80",
                                    width: "100%",
                                }}
                            ></textarea>
                        </div>
                        <div>
                            <p className="cmp-row2-head asterisk" style={{ width: "100%" }}>
                                Website
                            </p>
                            <input
                                onChange={e => handleChange("website_url", e.target.value)}
                                value={website_url}
                                className="col-lg-12 small-fonts edit-dropdown dropdown-width1"
                                type="text"
                                placeholder="Website"
                                id="website"
                                name="website"
                            />
                        </div>
                        <div>
                            <p className="cmp-row2-head asterisk" style={{ width: "100%" }}>
                                Pancard
                            </p>
                            <input
                                onChange={e => handleChange("pan_card", e.target.value)}
                                value={pan_card}
                                className="col-lg-12 small-fonts edit-dropdown dropdown-width1"
                                type="text"
                                placeholder="PAN Card"
                                id="pan_card"
                                name="pan_card"
                            />
                        </div>
                        <div>
                            <p className="cmp-row2-head asterisk" style={{ width: "100%" }}>
                                CIN/LLPIN
                            </p>
                            <input
                                onChange={e => handleChange("company_cin", e.target.value)}
                                value={company_cin}
                                className="col-lg-12 small-fonts edit-dropdown dropdown-width1"
                                type="text"
                                placeholder="CIN/LLPIN"
                                id="cin"
                                name="cin"
                            />
                        </div>
                        <div>
                            <p className="cmp-row2-head" style={{ width: "100%" }}>
                                GST
                            </p>
                            <input
                                onChange={e => handleChange("gstin_number", e.target.value)}
                                value={gstin_number}
                                className="col-lg-12 small-fonts edit-dropdown dropdown-width1"
                                type="text"
                                placeholder="GST"
                                id="gst"
                                name="gst"
                            />
                        </div>
                        <div>
                            <p className="cmp-row2-head" style={{ width: "100%" }}>
                                Tan Number
                            </p>
                            <input
                                onChange={e => handleChange("tan_number", e.target.value)}
                                value={tan_number}
                                className="col-lg-12 small-fonts edit-dropdown dropdown-width1"
                                type="text"
                                placeholder="Tan Number"
                                id="tan_number"
                                name="tan_number"
                            />
                        </div>
                        <div>
                            <p className="cmp-row2-head asterisk">Address</p>
                            <input
                                className="cmp-row2-field company-address edit-dropdown"
                                type="text"
                                id="comp-name"
                                name="comp-name"
                                value={address}
                                onChange={e => handleChange("address", e.target.value)}
                            ></input>
                            <br></br>
                        </div>
                    </div>
                    <div className="col-lg-3" style={{ width: "296px" }}>
                        <p className="cmp-row2-head">
                            Additional Documents (for Investors)
                        </p>
                        <div
                            className=""
                            style={{ borderRadius: "4px", border: "1px solid #d2d2d2" }}
                        >
                            <table className="table company-table  pay-schedule-table">
                                <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            className="cmp-tbl-head"
                                            style={{ opacity: "0.27" }}
                                        >
                                            FILE NAME
                                        </th>
                                        <th
                                            scope="col"
                                            className="edit-upload-addition-btn"
                                            style={{ textAlign: "right", opacity: "unset" }}
                                        >
                                            <input
                                                multiple={false}
                                                accept="image/*"
                                                onChange={e => uploadDocument(e.target.files[0])}
                                                id="documentUpload"
                                                type="file"
                                                style={{ display: "none" }}
                                            />
                                            <button
                                                onClick={() => handleDocUploadBtn()}
                                                className="upload-btn"
                                            >
                                                <img
                                                    src="/assets/upload.svg"
                                                    alt=""
                                                />
                                                Upload New
                                            </button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {documents && documents.map(item => (
                                        <tr className="company-addition-doc" key={item._id}>
                                            <td className="company-file-name">
                                                {item.file_name}
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                                <a href={item.file_url} target={`_blank`}>
                                                    <img
                                                        className=""
                                                        src="/assets/upload.svg"
                                                        alt=""
                                                    ></img>
                                                </a>
                                                <a
                                                    href="/#"
                                                    onClick={() => removeDocument(item._id)}
                                                >
                                                    Remove
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="">
                        <p className="cmp-row2-head">Salary Breakup</p>
                        <div className="border1">
                            {salaryBreakupData.map((data) => {
                                return (
                                    <div className='dflex-ac bb2 bb1 p-2' key={data.name}>
                                        <span className='w-50 fs14'>{data.label}</span>
                                        <input className='w-50 fs12' value={investeeDetails?.salaryBreakup?.[data.name]} type="string" name={data?.name} onChange={onChangingSalary} disabled={data.name === 'others'}/>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                    </div>

                    <div className="page-head-bottom-border"></div>
                <div className='p-5 w-100'>
                <div className='m-2 director-details'>
                    Director Details
                    <p className='cmp-row2-head1'>{directorDetails?.length>0 && `Last Updated on: ${updateDateDir}`}</p>
                </div>
                <div className='row table-head'>
                    <table className='table team'>
                        <thead>
                            <tr className='director-heading'>
                                <th style={{whiteSpace:'nowrap'}}>DIRECTOR NAME</th>
                                <th>DATE OF BIRTH</th>
                                <th>PAN</th>
                                <th>GENDER</th>
                                <th>DIN NO.</th>
                                <th>APPOINTMENT DATE</th>
                                <th>ADDRESS</th>
                            </tr>
                        </thead>
                        <tbody className='director-heading'>
                                {directorDetails && directorDetails.map((item={}, index) => {
                                return (
                                    <>
                                        <tr key={index} className='table-content'>
                                            <td style={{whiteSpace:'nowrap'}}>{item?.name ?? '-'}</td>
                                            <td>{item?.dob ?? '-'}</td>
                                            {!isEmpty(item?.pan)&& <td>{item?.pan[0] ?? '-'}</td>}
                                            <td>{item?.gender ?? '-'}</td>
                                            <td>{item?.din ?? '-'}</td>
                                            <td>{item?.tenure_begin_date ?? '-'}</td>
                                            <td style={{width:'40%'}}>{item?.address ?? '-'}</td>
                                        </tr>
                                    </>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='px-5 w-100'>
                <div className='mx-2 director-details'>
                    Charges Details
                    <p className='cmp-row2-head1'>{chargesDetails?.length>0 &&`Last Updated On: ${updateDateChar}`}</p>
                </div>
                <div className='mb-5 row table-head'>
                    <table className='table team'>
                        <thead className='team-head order-table wtspc'>
                            <tr className='director-heading'>
                                <th>STATUS</th>
                                <th>CHARGE HOLDER NAME</th>
                                <th>AMOUNT</th>
                                <th>ASSET UNDER CHARGE</th>
                                <th>CREATION DATE</th>
                                <th>MODIFICATION DATE</th>
                                <th>SATISFACTION DATE</th>
                            </tr>
                        </thead>
                        <tbody className='director-heading'>
                            {chargesDetails && chargesDetails.map((item, index) => {
                                return (
                                    <>
                                        <tr key={index} className='table-content'>
                                            <td>{item.status ?? '-'}</td>
                                            <td>{item.charge_holder_name ?? '-'}</td>
                                            <td>{item.amount ?? '-'}</td>
                                            <td>{item.asset_under_charge ?? '-'}</td>
                                            <td>{item.date_of_creation ?? '-'}</td>
                                            <td>{item.modification_date==='' ? '-':item.modification_date??'-'}</td>
                                            <td>{item.satisfaction_date===''?'-' :item.satisfaction_date??'-'}</td>
                                        </tr>
                                    </>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                </div>
                </div>
                
            </div>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Choose JSON File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form>
                            <input
                                name="file"
                                type="file"
                                accept=".json"
                                placeholder="Please select schema file"
                                onChange={fileChange}
                                multiple={false}
                            />
                        </form>
                        <textarea
                            value={fileContents}
                            rows="10"
                            cols="50"
                            onChange={e => setFileContents(e.target.value)}
                        />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        disabled={!fileContents}
                        onClick={uploadFile}
                    >
                        Upload File
                    </Button>
                </Modal.Footer>
            </Modal>
            <ConfirmTotUpdate
                show={editConfirmationDetails.showModal}
                onHide={() =>
                    setEditConfirmationDetails({ ...editConfirmationDetails, showModal: false })
                }
                title={editConfirmationDetails.title}
                header={editConfirmationDetails.header}
                onDelete={() => onSave(true)}
                showMessage2={false}
            />
        </section>
    )
}