import axios from "axios";
import { CustomRequest } from "./common";
import { random } from "lodash";
import {UpdateClaimPayload, CreateNachPayload, EditFacilityNachPayload} from "./ApiTypes";
import { generateRequestId } from "../util/utility";

export const API_BASE_PATH = `${process.env.REACT_APP_API_URL}/api/`; // introduce a fallback here
// export const API_BASE_PATH = `http://localhost:8070/api/`;
export const CERBERUS_BASE_PATH = `${process.env.REACT_APP_CERBERUS_API_URL}/cerberus/`;
export const API_BASE_AUTH_PATH = `${API_BASE_PATH}auth/`;
export const UW_BASE_PATH = `${process.env.REACT_APP_UW_API_URL}/`;
// export const UW_BASE_PATH = `http://localhost:8000/`;
// export const API_BASE_CERBERUS_PATH = `https://apidev4.recur.club/cerberus/rest/`;
export const API_BASE_CERBERUS_PATH = `${CERBERUS_BASE_PATH}rest/`;
export const WEBUTILS_URL = process.env.REACT_APP_WEBUTILS_API_URL;
export const MNEMOSYNE_URL = process.env.REACT_APP_MNEMOSYNE_API_URL;
export const OLYMPUS_URL = `${process.env.REACT_APP_OLYMPUS_API_URL}/rest/`;
// export const OLYMPUS_URL = 'http://localhost:8083/olympus/rest/'
export const NOTIFICATION_BASE_URL = `${process.env.REACT_APP_NOTIFICATION_API_URL}`;
// export const NOTIFICATION_BASE_URL ='http://localhost:8081/notification'
const GET = 'GET';
const POST = 'POST';
const DELETE = 'DELETE';

// @description: Example Function to call API
// export const functionName = (data: typeInterface): any => {
//     const payload: CustomRequest = {
//         method: Method to use,
//         url: `${API_BASE_CERBERUS_PATH}access/login`,
//         isAuthRequired: false, this gives you the option to skip the cerberus auth
//         data,  This is the payload to send to the API
//     };
//     return axios(payload);
// };

const makeApiRequest = (endpoint: string, method: string, data: any, params?: any): any => {
    const url = `${API_BASE_PATH}${endpoint}`;
    const payload: CustomRequest = {
        method,
        url,
        data,
        params,
        isAuthRequired: true,
    };
    return axios(payload);
}

export const fetchTaggedDocs = (data: any): any => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}taggedDocs/fetch`,
        params:{
            orgId: data.orgId,
            pageNumber: data.pageNumber??1,
            context: data.context,
            fromId: data.fromId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
}
export const fetchTaggedDropdown = (data: any): any => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}taggedDocs/getMetaConfig?orgId=${data.orgId}`,
        isAuthRequired: true,
    };
    return axios(payload);
}
export const getCibilScore = (data: any): any => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}cibil/getCibilData`,
        params:{
            investeeOrganizationId: data.investeeOrganizationId,
            directorNames: data.directorNames,
        },
        isAuthRequired: true,
    };
    return axios(payload);
}
export const refetchCibilScore = (data: any): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}cibil/fetchCibil`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
}
export const refetchKarzaData = (data: any): any => {
    return makeApiRequest('admin/fetchMCA', POST, data);
}

export const fetchMfaSlUploadsInvestors = (data: any): any => {
    return makeApiRequest('admin/deals/investee_organization/getMfaSlStatusLenderWise', POST, data);
}
export const toggleBankAccountStatus = (data: any): any => {
    return makeApiRequest('admin/markBankStatus', POST, data);
}
export const fetchBankAccountList = (data: any): any => {
    const fetchData ={
        organization_type:data?.orgType
    }
    const endPoint =`admin/repayments/organization/${data.orgId}/bank-list`
    return makeApiRequest(endPoint, POST, fetchData);
}

export const fetchKarzaFetchedStatus = (data: any): any => {
    const requestData = {
        orgId: data.investee_organization_id
    };
    return makeApiRequest('admin/getMCAfetchStatus', GET,null, requestData);
}
export const sendWaitlistedEmail = (orgId:string,identifierType:string,identifierId:string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}deal-report/send-waitlisted-email`,
        params:{
            orgId:orgId,
            identifierType:identifierType,
            identifierId:identifierId
        },
        headers:{
            requestId: random(10000000)
        },
        isAuthRequired: true,
    };
    return axios(payload);
}
export const updateDocTags = ({
    xOrganizationId,
    taggedDocument:{
        documentId,
        messageId,
        docTag,
    }
}:{
    xOrganizationId: string,
    taggedDocument: {
        documentId: string,
        messageId: string,
        docTag: string,
    }
})=>{
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}taggedDocs/updateDocTag`,
        isAuthRequired: true,
        data: {
            xOrganizationId,
            taggedDocument:{
                documentId,
                messageId,
                docTag,
            }
        },
    };
    return axios(payload);
}
export const updateDocumentationStatus = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}deal/updateDocumentationStatus`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchQueryMessages = (offerId: string, queryType?: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${NOTIFICATION_BASE_URL}/conversation/${offerId}/Admin/fetch-messages`,
        params:{
            message_query_type: queryType ?? null,
        },
        isAuthRequired: true,
    };
    return axios(payload);
}

export const fetchIsComplianceStatusNotMet = (data:any)=> {
    let baseUrl = `${API_BASE_PATH}admin/fetchIsComplianceStatusNotMet`;
        const payload: CustomRequest = {
            method: POST,
            url: baseUrl,
            isAuthRequired: true,
            data:data
        };
        return axios(payload)
}

export const fetchGlobalComplianceData = (data:any) => {
    let baseUrl = `${OLYMPUS_URL}compliance/get-compliance-data`;
    const payload: CustomRequest = {
        method: POST,
        url: baseUrl,
        isAuthRequired: true,
        cancelToken: data.cancelToken,
        data:data
    };
    return axios(payload)
};
export const fetchCollectionData = (data: object, beta: boolean) => {
    const baseUrl = beta ? `${OLYMPUS_URL}collection/fetch-details` : `${API_BASE_PATH}collections/fetch-details`;
    const payload: CustomRequest = {
        method: POST,
        url: baseUrl,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

// Bank Alerts on Summary Screen - Data Vault > Data > Bank Statements
export const fetchBankAlerts = (orgId: string) => {
    const baseUrl = `${UW_BASE_PATH}underwriting/data_validation/getAlerts`
    const data : {[key : string] : string} = {
        investeeOrgId : `${orgId}`,
        type : "bank_statement"
    }
    const payload: CustomRequest = {
        method: POST,
        url: baseUrl,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
}
export const callApiToUpdateIndicativeTerms = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}deal/save-indicative-terms`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToUpdateTotRecurFee = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}tot/update-tot-fee`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

// Get Uw Flag
export const getUwFlag = (orgId: string, identifierId : string | undefined, requestId : string) => {
    let baseUrl = `${OLYMPUS_URL}deal-report/data-get?`

    if(orgId != undefined){
        baseUrl += `orgId=${orgId}`
    }
    if(identifierId != undefined){
        baseUrl += `&${identifierId}` 
    }
    if(requestId != undefined){
        baseUrl += `&${requestId}` 
    }

    const payload: CustomRequest = {
        method: GET,
        url: baseUrl,
        isAuthRequired: true,
    };
    return axios(payload);
}
export const sendScaleTot = (stageTotId: string) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}debt-tot/send`,
        data:{stageTotId},
        isAuthRequired: true,
    };
    return axios(payload);
};

export const reInitiateProductTot = (stageTotId: string) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}debt-tot/re-initiate-product-tot`,
        data:{stageTotId},
        isAuthRequired: true,
    };
    return axios(payload);
};

export const getPrepareTermsTotDetails = (data : any) => {   
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}admin/tot-history/get-prepare-terms-tot-details`,
        data: data,
        isAuthRequired: true,
    };
    return axios(payload);
}

export const getGlobalNeedInfo=(data:any)=>{
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}onboarding/get-global-need-info-data`,
        data:{
            investeeOrgId:data.investeeOrgId,
            filters:data.filters
        },
        isAuthRequired: true,
    };

    return axios(payload)
}
export const addGlobalNeedInfo=(data:any)=>{
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}onboarding/add-global-need-info-req`,
        data,
        isAuthRequired: true,
    };
    return axios(payload)
}
export const updateGlobalNeedInfo=(data:any)=>{
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}onboarding/edit-global-need-info-req`,
        data:data,
        isAuthRequired: true,
    };
    return axios(payload)
}

export const deleteGlobalNeedInfo=(data:any)=>{
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}onboarding/delete-global-need-info-docs`,
        data,
        isAuthRequired: true,
    };
    return axios(payload)
}
export const getAllInvestors=()=>{
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}onboarding/get-investor-name-id-map`,
        isAuthRequired: true,
    };
    return axios(payload)
}

export const downloadGlobalNeedInfoZipFile=(id:string)=>{
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}onboarding/zip-global-need-info-docs`,
        params:{
            id:id
        },
        isAuthRequired: true,
    };
    return axios(payload)
}

export const sendGlobalNeedInfoMail=(orgId:string)=>{
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}onboarding/send-global-need-info-mail`,
        params:{
            orgId:orgId
        },
        isAuthRequired: true,
    };
    return axios(payload)
}
export const updateLanId = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}admin/updateLanId`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
}

export const sendBulkMessage=(data:any)=>{
    const payload: CustomRequest = {
        method: POST,
        url: `${NOTIFICATION_BASE_URL}/conversation/send-bulk-message`,
        data:data,
        isAuthRequired: true,
    };
    return axios(payload);
}

export const boradCastEmail = (data: any) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}deal/broadcast-email`,
        data:data,
        isAuthRequired: true,
    };
    return axios(payload);
}

export const regeneratePingReport = (investeeOrganizationId: string) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}admin/refetch-ping-report`,
        data:{
            investeeOrganizationId: investeeOrganizationId
        },
        isAuthRequired: true,
    }
    return axios(payload);
}
export const getCustomerQueries = (data:object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}customerSupport/get-support-queries`,
        data:data,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const sendReminderToInvCustomerSupport = (customerSupportQueryId:string, email: string) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}customerSupport/send-reminder-email`,
        data:{
            customerQueryId:customerSupportQueryId,
            activityBy:email
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchTdsClaim = (customerQueryId: string | null) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}tds/get-claim`,
        params: {
            customerQueryId: customerQueryId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchPresignedUrlMnemosyne=(docId:string|undefined|Number)=>{
    const payload: CustomRequest = {
        method: POST,
        url: `${MNEMOSYNE_URL}/file/downloadFilePresigned`,
        params:{
            fileId:docId
        },
        isAuthRequired: true,
    };
    return axios(payload)
}
export const fetchSignedUrlToUpload = (input: object, orgId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${MNEMOSYNE_URL}/file/signedUrl`,
        data: input,
        headers: {
            'organization-id': orgId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const saveMetaDataInDocService = (input: object, orgId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${MNEMOSYNE_URL}/file/saveMetadata`,
        data: input,
        headers: {
            'organization-id': orgId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToDeleteFileFromDocService = (docId: number): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${MNEMOSYNE_URL}/file/deleteFiles`,
        data: {
            identifierName: 'fileIds',
            docList: [docId],
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const deleteFileApi = (input: any, data: any, isOnboarding: boolean) => {
    const axiosPayload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}/investee-onboarding/${input.investeeOnboardingId}/${
            isOnboarding ? 'v3/' : ''
        }delete-file?path=${input.path}`,
        data: data,
        isAuthRequired: true,
    };
    return axios(axiosPayload);
};

export const callUpdateClaim = (data: UpdateClaimPayload) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}tds/update-claim`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callUpdateSupportQuery = (data: UpdateClaimPayload) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}customerSupport/update`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const callSendReminderToInv = (customerSupportQueryId:string, email: string) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}tds/tds-reminder-email`,
        data:{
            customerSupportQueryId:customerSupportQueryId,
            activityBy:email
        },
        isAuthRequired: true,
    };
    return axios(payload);
};
export const refetchTdsData = ()=>{
    const payload: CustomRequest = {
        method: POST,
        data:{
            customerSupportQueryIdList:[]
        },
        url: `${OLYMPUS_URL}tds/manual-fetch-extraction-status`,
        isAuthRequired: true,
    };
    return axios(payload);
}

export const fetchBalanceConfirmationData = (customerQueryId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}documentQuery/get-data`,
        params: {
            customerQueryId: customerQueryId,
        },
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const getSupportQueryApi = (customerQueryId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}customerSupport/get-data`,
        params: {
            customerQueryId: customerQueryId,
        },
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const checkStatusBeforeCompanyEdit = (investeeOrgId: string, data: any)=>{
    const payload: CustomRequest = {
        method: POST,
        data,
        url: `${API_BASE_PATH}investeeorganizations/${investeeOrgId}/data-update`,
        isAuthRequired: true,
    };
    return axios(payload);
}

export const triggerSwiftEmailToUser = (totId: string, data: any) => {
    const payload: CustomRequest = {
        method: POST,
        data,
        url: `${API_BASE_PATH}admin/tot-history/${totId}/sendTotEmail`,
        headers: {
            "request-id": generateRequestId(),
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const downloadComplianceFilesRequest = (cpId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}cp/downloadAllFiles?cpId=${cpId}`,
        headers: {
            'request-id': generateRequestId(),
        },
        responseType: 'blob',
        isAuthRequired: true,
    };
    return axios(payload);
};


export const createNach = (data: CreateNachPayload) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}nach/create-facility-nach`,
        data,
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true
    };
    return axios(payload);
}

export const fetchOrgNachDetailsRequest = (orgId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}nach/fetch-facility-nach-details?orgId=${orgId}`,
        isAuthRequired: true
    };

    return axios(payload);
}

export const editNachRequest = (data: EditFacilityNachPayload) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}nach/edit-nach`,
        data,
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true
    };
    return axios(payload);
}

export const fetchMoratMonths = async (noOfMonths:number[]) =>{
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}rrf-tot/getMoratMonth`,
        data:{
            moratArray:noOfMonths
        },
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true
    };
    return axios(payload);
}

export const expireApplicationRequest = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}admin/expire-application`,
        data,
        isAuthRequired: true,
    }
    return axios(payload);
}
export const getRecordedPayments = (data:object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}payment/getRecordedPayments`,
        data:data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const verifyRecordedPayment = (data:object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}payment/markRecordedPayment`,
        data:data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const verifyRecordedPaymentBulk = (data:object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}payment/markRecordedPaymentBulk`,
        data:data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const getLastFetchedDetailsRequest = (investeeOrgId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${UW_BASE_PATH}underwriting/organization/lastFetchDetails?investeeOrgId=${investeeOrgId}`,
        isAuthRequired: true
    };

    return axios(payload);
}

export const fetchAdhocNachData = async(data:any)=>{
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}befe/adhocNach/fetchAllDetails`,
        data,
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true
    };
    return axios(payload);
}

export const callCreateAdhocNach = async(data:any)=>{
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}befe/adhocNach/create`,
        data,
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true
    };
    return axios(payload);
}

export const callEditAdhocNach = async(data:any)=>{
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}befe/adhocNach/update`,
        data,
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true
    };
    return axios(payload);
}

export const callDeleteAdhocNach = async(data:any)=>{
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}befe/adhocNach/delete`,
        data,
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true
    };
    return axios(payload);
}
export const downloadTdsTransferZip = async(data: any)=>{
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}tds/download-tds-verified-file`,
        headers: {
            'request-id': generateRequestId(),
        },
        data,
        responseType: 'arraybuffer',
        isAuthRequired: true
    };
    return axios(payload);
}

export const approveVerifiedTdsClaims = async(data:any)=>{
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}tds/update-bulk`,
        data,
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true
    };
    return axios(payload);
}
export const callApiToUpdateNachAccount = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}nach/update-nach-account`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callAddDealComments = async(data:any)=>{
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}deal/saveDealComments`,
        data,
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true
    };
    return axios(payload);
}
export const callApiToGetRecoveryViewDetails = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}befe/recovery/fetchData`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToUpdateRecoveryViewOwner = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}befe/recovery/updateOwner`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToCreateRecoveryViewComments = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}comments/create`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};
