import React, { Component } from "react";
// import {Link} from 'react-router-dom';
import AutoInvestTableHeader from "../AutoInvestPage/components/AutoInvestTableHeader";
import AutoInvestTableRow from "../AutoInvestPage/components/AutoInvestTableRow";
import AdminHead from "../../components/AdminHead.js";
import callApi from "../../../../util/apiCaller";
import { showNotification } from "../../../../util/utility";
import _ from "lodash";
import { mergeByProperty } from "../../../../util/calculateTermUtil";

const statusOptions = ['Approved', 'Open', 'Not Approved'];
class AutoInvestPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNum: 1,
      pageSize: 200,
      totalCount: 0,
      autoinvest:[],
      // searchObjInvestorOrg: {
      //   keyName: "contract_name",
      //   value: "",
      // },
      // searchObjInvestorUser: {
      //   keyName: "contract_id",
      //   value: "",
      // },
      // searchObjContractID: {
      //   keyName: "investor_name",
      //   value: "",
      // },
      // searchObjAdminUser: {
      //   keyName: "investor_name",
      //   value: "",
      // },
      // sliderObjPaymentsTerm: {
      //   minVal: 0,
      //   maxVal: 12,
      //   currentMax: 12,
      //   currentMin: 0,
      //   keyName: "contract_term_value",
      //   minValueBetween: 1,
      // },
      // sliderObjPayments: {
      //   minVal: 0,
      //   maxVal: 100,
      //   currentMax: 70,
      //   currentMin: 20,
      //   keyName: "gross_cashflow",
      //   minValueBetween: 10,
      // },
      // sliderObjInvestment: {
      //   minVal: 0,
      //   maxVal: 100,
      //   currentMax: 70,
      //   currentMin: 20,
      //   keyName: "gross_cashflow",
      //   minValueBetween: 10,
      // },
      // dateObjDateRequested: {
      //   date: [new Date(), new Date()],
      //   keyName: "payment_to_investor_date",
      // },
      // dateObjLastUpdated: {
      //   date: [new Date(), new Date()],
      //   keyName: "payment_to_investor_date",
      // },
      repaymentStartDateObj: {
        keyName: "first_repayment",
        date: ['', '']
      },
      selectorObjStatus: {
        keyName: "trade_approval_status",
        value: statusOptions
      },
    };
  }

  keyStateObjNameDict = {
    contract_term_value: "sliderObjArr",
    recurring_payment_value: "sliderObjPrice",
    disbursed: "sliderObjTradeLimit",
    contract_price: "sliderObjPrice",
    payout: "sliderObjTradedArr",
    fees: "sliderObjFees",
    contract_id: "searchObjContractID",
    contract_trade_id: "searchObjTradeID",
    first_repayment: "repaymentStartDateObj",
    trade_approval_status: "selectorObjStatus",
    type: "selectorObjType",
  };

  componentDidMount(){
    this.fetchAutoInvest();
  }
  fetchAutoInvest = () =>{
          //call api to get autoinvest data
          callApi(`admin/autoinvest/get-all-autoinvest`, "post").then((res) => {
              if (res.status === "Success") {
                this.setState({
                  autoinvest: res.data.autoinvest,
                  totalCount: res.data.totalCount
                });
              } else {
                showNotification("error", "Some error occured");
              }
          });
    }
  hasMore = () => {
    // console.log("HAS-MORE ", this.state.totalCount, this.state.pageSize, this.state.pageNum);
    return this.state.totalCount + 1 > this.state.pageSize * this.state.pageNum;
  };

  loadMore = () => {
    this.setState({ pageNum: this.state.pageNum + 1, isFiltering: false });
    this.fetchAutoInvest();
  };

  getFilters = () => {
    const { selectorObjStatus, repaymentStartDateObj } = this.state;
    let filters = {};
    filters.pageNum = this.state.pageNum;
    filters.pageSize = this.state.pageSize;
    if (this.state.qtext) {
      filters.isSearching = true;
      filters.qtext = this.state.qtext;
    }
    filters.isFiltering = true;
    filters.isSearching = true;
    // filters.sort_by = ''
    // filters[this.state.searchObjCustomer.keyName] = this.state.searchObjCustomer.value;
    // filters[this.state.searchObjContractID.keyName] = this.state.searchObjContractID.value;
    // filters[this.state.searchObjInvestor.keyName] = this.state.searchObjInvestor.value;
    // filters[this.state.sliderObjTerm.keyName] = {
    //     "min": this.state.sliderObjTerm.currentMin,
    //     "max": this.state.sliderObjTerm.currentMax
    // };
    // filters[this.state.sliderObjPaymentNo.keyName] = {
    //     "min": this.state.sliderObjPaymentNo.currentMin,
    //     "max": this.state.sliderObjPaymentNo.currentMax
    // };
    // filters[this.state.sliderObjGrossCashFlow.keyName] = {
    //     "min": this.state.sliderObjGrossCashFlow.currentMin,
    //     "max": this.state.sliderObjGrossCashFlow.currentMax
    // };
    // filters[this.state.sliderObjFees.keyName] = {
    //     "min": this.state.sliderObjFees.currentMin,
    //     "max": this.state.sliderObjFees.currentMax
    // };
    // filters[this.state.paymentDateObj.keyName] = {
    //     "from":this.state.paymentDateObj.date[0],
    //     "to":this.state.paymentDateObj.date[1]
    // };
    filters[repaymentStartDateObj.keyName] = {
      "from": repaymentStartDateObj.date[0],
      "to": repaymentStartDateObj.date[1]
    }
    filters[selectorObjStatus.keyName] = selectorObjStatus.value;
    return filters;
  };

  handleApplyFilters = () => {
    this.setState({ isFiltering: true });
    this.fetchTrades(true);
  };

  handleSearchFilter = (obj) => {
    let searchObj = {};
    searchObj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(searchObj, () => {
      this.handleApplyFilters();
    });
  };

  handleSliderFilter = (obj) => {
    let sliderobj = {};
    sliderobj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(sliderobj, () => {
      this.handleApplyFilters();
    });
  };

  handledatePicker = (obj) => {
    let datePickerObj = {};
    datePickerObj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(datePickerObj, () => {
      this.handleApplyFilters();
    });
  };

  inputSelectorHandler = (obj) => {
    let selectorObj = {};
    selectorObj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(selectorObj, () => {
      this.handleApplyFilters();
    });
  };

  markTradeApprovalStatus = (status) => {
    this.setState({
      is_marking_contract_status: true,
      mark_contract_status_as: status,
    });
    callApi(`/admin/mark-contracts-trade-approved`, "post", {
      trade_ids: this.state.selected_trades_ids,
      approval_status: status,
    }).then((res) => {
      console.log(res);
      if (res.status === "Success") {
        showNotification("success", "Status Updated Successfully!");
        this.setState({
          is_marking_contract_status: false,
          selected_trades_ids: [],
        });
        this.fetchTrades();
      } else {
        showNotification("error", "Some error occured");
        this.setState({
          is_marking_contract_status: false,
        });
      }
    });
  };

  onChangeTradeCheckbox = (val) => {
    let selected_trades_ids = this.state.selected_trades_ids;
    if (val.target.checked) {
      selected_trades_ids.push(val.target.value);
    } else if (!val.target.checked) {
      _.remove(selected_trades_ids, (n) => {
        return n === val.target.value;
      });
    }
    this.setState({
      selected_trades_ids: selected_trades_ids,
    });
  };

  render() {
    return (
      <div className="main-body">
        <AdminHead />
        <section id='admin-company'>
          <div className='d-flex justify-content-between form-group has-search'>
            <div className='d-flex justify-content-between'>
              <button
                className='app-btn btn-approve'
                disabled = {this.state.is_marking_contract_status}
                onClick={() => {
                  // this.markTradeApprovalStatus("Approved");
                }}>
                <img src='../../../../../assets/icon-done.svg' alt=''></img>
                {this.state.is_marking_contract_status ? 'Approving' : 'Approve'}
              </button>
              <button
                className='app-btn btn-no-approve'
                onClick={() => {
                  // this.markTradeApprovalStatus("Not Approved");
                }}>
                <img src='../../../../../assets/icon-clear.svg' alt=''></img>Not
                Approved
              </button>
              <button
                className='app-btn btn-hold'
                onClick={() => {
                  // this.markTradeApprovalStatus("On Hold");
                }}>
                <img src='../../../../../assets/icon-history.svg' alt=''></img>
                On Hold
              </button>
            </div>
          </div>
          <div className='table-head'>
            <table className='table team'>
              <AutoInvestTableHeader
                searchObjInvestorOrg={this.state.searchObjInvestorOrg}
                searchObjInvestorUser={this.state.searchObjInvestorUser}
                searchObjContractID={this.state.searchObjContractID}
                searchObjAdminUser={this.state.searchObjAdminUser}
                sliderObjPaymentsTerm={this.state.sliderObjPaymentsTerm}
                sliderObjPayments={this.state.sliderObjPayments}
                sliderObjInvestment={this.state.sliderObjInvestment}
                dateObjDateRequested={this.state.dateObjDateRequested}
                dateObjLastUpdated={this.state.dateObjLastUpdated}
                searchObjInvestorOrg={this.state.searchObjInvestorOrg}
                repaymentStartDateObj={this.state.repaymentStartDateObj}
                selectorObjStatus={this.state.selectorObjStatus}
                handleSliderFilter={this.handleSliderFilter}
                inputSelectorHandler={this.inputSelectorHandler}
                handleSearchFilter={this.handleSearchFilter}
                handledatePicker={this.handledatePicker}
              />
              <tbody style={{ display: 'none' }}></tbody>
            </table>
          </div>
          <div className='tableFixHead table-body'>
            <table
                className='table team'
            >
              <thead></thead>
              <tbody className='team-body'>
                {!_.isEmpty(this.state.autoinvest) &&
                  this.state.autoinvest.map((item, index) => (
                    <AutoInvestTableRow
                      key={item._id}
                      loader={this.state.is_fetching_trades}
                      hasMore={this.hasMore}
                      loadMore={this.loadMore}
                      dataLength={this.state.autoinvest.length}
                      index={index}
                      selected_trades_ids={this.state.selected_trades_ids}
                      onChangeTradeCheckbox={this.onChangeTradeCheckbox}
                      item={item}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    );
  }
}

export default AutoInvestPage;
