import React from "react";

function OrderTransactionListHeader(props) {
  return (
    <thead>
      <tr>
        <th></th>
        <th>Investor Name</th>
        <th>Company Name</th>
        <th>Company Balance</th>
        <th>Repayments</th>
        <th>Due Date</th>
        <th>DPD's</th>
        <th>Amount to collect</th>
        <th>Delay paid</th>
        <th></th>
      </tr>
    </thead>
  );
}

export default OrderTransactionListHeader;
