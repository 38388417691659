import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { commentTypeEnum } from '../../../../../../../../../../../../../common/ENUM';
import callApi from '../../../../../../../../../../../../../util/apiCaller';
import { showNotification } from '../../../../../../../../../../../../../util/utility';
import { getUnderwriters, getUserName } from '../../../../../../../../../../../../App/AppReducer';
import styles from '../MultipleTotComponents/ModeratorDetails.module.scss';
import { API_STATUS } from '../../../../../../../../../../../../../constants/enums';
import { getInvesteesOrganization } from '../../../../../../../../../../../AdminReducers';

const {
    ModeratorDetailsCard,
    ModeratorDetailsLine1,
    editModerateBox,
    moderateBox,
    LogRequestCallBtns,
    editBtns,
    cancelBtn,
    saveBtn,
    disableBtns,
    commentRow,
    actionBtns
} = styles;

export default function ModeratorDetails({
  identifierType,
  investeeOrgId,
  isApplicationExpired = false,
  investeeOnboardedId,
  addOnId,
  underWriterName
  })
{
    const [note, setNote] = useState('');
    const [moderatorDetails, setModeratorDetails] = useState('');
    const [editModerationComments, setEditModerationComments] = useState(false);
    const [commentType, setCommentType] = useState(null);
    const underwriters = useSelector(getUnderwriters);
    const userName = useSelector(state => getUserName(state));
    const investee_details = useSelector(getInvesteesOrganization);
    const [editAbleComment, setEditAbleComment] = useState({});
    let textAreaRef = useRef(null);

    const onSave = () => {
        if(note.trim().length === 0) {
          showNotification("Error", "Please enter a comment");
          return;
        }
        callCreateApi();
        setEditModerationComments(false);
        setNote('');
        setCommentType(null)
    }
  
  const fetchModeratorComment = () => {
      callApi("get-comment", "post", {
          identifier_id : addOnId ?? investeeOnboardedId,
          type : identifierType
      }).then(res => {
          if (res.status === API_STATUS.SUCCESS) setModeratorDetails(res.data);
      })
  }
  useEffect(() => { 
   fetchModeratorComment();
  }, [investeeOnboardedId,addOnId]);

    const callCreateApi = () => {
      const payload = {
        org_id: investeeOrgId,
        comment: note,
        identifier_id: addOnId ?? investeeOnboardedId,
        identifier_type: identifierType,
        purpose: commentType,
        owner: userName
      }
        callApi("create-comment", "post", payload).then(res => {
            if (res.status === "Success") {
              fetchModeratorComment();
              showNotification("Success", "Successfully saved");
            }
            else {
                const message = res?.data?.message ?? "Some error occurred";
                showNotification("Error", message);
            }
        })
    }
  
  const callUpdateApi = () => {
    callApi("update-comment", "post", {
      comment: editAbleComment?.comment,
      comment_id: editAbleComment?._id//moderatorDetails[0]?._id
    }).then(res => {
      if (res.status === "Success") showNotification("Success", "Successfully saved");
      else {
        const message = res?.data?.message ?? "Some error occurred";
        showNotification("Error", message);
      }
    }).finally(() => {
      const modifiedModeratorDetails = moderatorDetails.map((item) => { 
        if(item?._id === editAbleComment?._id) {
          item.comment = editAbleComment?.comment;
        }
        return item;
      });
      setModeratorDetails(modifiedModeratorDetails)
      setEditAbleComment({});
     });
    }
  
  const handleCommentEdit = (e, item) => {
    setEditAbleComment({...editAbleComment, comment: e.target.value});
  }
  
  const handleCommentClick = (e, item) => { 
    e.preventDefault();
    setEditAbleComment(item);
    setTimeout(() => {
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    })
  }

  const disableCommentEdit = (item) => { 
    return editAbleComment?._id !== item?._id
  }


    const editModerationCommentsBox = (type) => {
      if(isApplicationExpired) return;
       if(!commentType) {
        setEditModerationComments(true);
        setCommentType(type);
       }
    }

    const onCancel = () => {
      setEditModerationComments(false);
      setNote('');
      setCommentType(null);
    }

    return (
      <div>
        <div className={ModeratorDetailsCard}>
          <div className={ModeratorDetailsLine1}>Moderator Details</div>
          <div className="fs12 fw300 mt-2">
            <b>Moderator: </b>
            {investee_details?.underwriterId ? <span>
              {underwriters?.find((p) => p._id === investee_details?.underwriterId)?.agent_name}
            </span>: <span>{underWriterName}</span>}
          </div>
          <div className="fs12 fw300 mt-2">
            <b>Last Update: </b>
            <span>
              {moderatorDetails && moderatorDetails[0]?.updated_at
                ? moment(moderatorDetails[0]?.updated_at).format("HH:mm, DD MMM YYYY")
                : ""}
            </span>
          </div>
          <div className="fs12 fw500 mt-2 d-flex justify-content-between mr-5 mb-2">
            <div>Moderator Notes</div>
            <div className={(commentType|| isApplicationExpired )? disableBtns : LogRequestCallBtns}>
              <span onClick={() => editModerationCommentsBox(commentTypeEnum.LOG_CALL.key)}>
                Log Call
              </span>
              <span
                className="ml-2"
                onClick={() => editModerationCommentsBox(commentTypeEnum.REQUEST_CALL.key)}
              >
                Request Call
              </span>
            </div>
          </div>
          <div>
            {editModerationComments ? (
              <div className={editModerateBox}>
                <textarea
                  autoFocus={editModerationComments}
                  type="text"
                  placeholder="What's this call about ?"
                  onChange={(e) => setNote(e.target.value)}
                  value={note}
                />
                <div className={editBtns}>
                  <button onClick={onCancel} className={cancelBtn}>
                    Cancel
                  </button>
                  <button onClick={onSave} className={saveBtn}>
                    {commentType === commentTypeEnum.COMMENT_ONLY.key
                      ? "Save Comment"
                      : commentType === commentTypeEnum.LOG_CALL.key
                      ? "Log Call"
                      : "Request Call"}
                  </button>
                </div>
              </div>
            ) : (
              <div
                className={moderateBox}
                onDoubleClick={() => editModerationCommentsBox(commentTypeEnum.COMMENT_ONLY.key)}
              >
                {moderatorDetails && moderatorDetails.map((item, index) => (
                  <div className="mb-2" key={index}>
                    <div className={commentRow}>
                      <span>
                        {dayjs(item?.created_at).format("h:mm A")},&nbsp;
                        {dayjs(item?.created_at).format("DD MMM YY")}&nbsp;
                      </span>
                      <span>
                        &nbsp;by {item?.owner}&nbsp;
                        {item?.purpose &&
                        item?.purpose !== commentTypeEnum.COMMENT_ONLY.key
                          ? `(${commentTypeEnum[item?.purpose].value})`
                          : ""}
                      </span>
                    </div>
                    <div style={{whiteSpace: "pre-wrap"}}>
                      {!disableCommentEdit(item) ? <div>
                        <textarea
                        type="text"
                        disabled={disableCommentEdit(item)}
                        onChange={(e) => handleCommentEdit(e, item)}
                        value={editAbleComment?.comment}
                        style={{ border: 'none', backgroundColor: 'inherit', width: '100%', resize: 'none'}}
                        ref={textAreaRef}
                        />
                        <span>
                          <button onClick={callUpdateApi} className={saveBtn} style={{marginRight: '10px'}}>Save</button>
                          <button onClick={()=> setEditAbleComment({})} className={cancelBtn}>Cancel</button>
                        </span>
                      </div> : <div onClick={(e) => handleCommentClick(e, item)}>{item?.comment.trim()}</div>}
                      </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
}
