import React from 'react';
import ToggleButton from '../../../../../../../../../../../../../components/ToggleButton/ToggleButton';
import styles from '../MultipleTotComponents/ReviewFinancialData.module.scss';

const {
    ReviewFinancialDataTitle,
    ReviewFinancialDataCard,
    toggleContainer
} = styles;

export default function ReviewFinancialData({
    renderFields
}) {

    return (
        <div>
            <div className={ReviewFinancialDataTitle}>
                {/* <span>Review Financial Data</span> */}
            </div>
            
            <div className={ReviewFinancialDataCard}>
            <div className="DocumentVaultSwitch ml-">
            <div className={toggleContainer}>
            <span>Need info Optional      </span> {/* this is the legend added to show need info optional*/}
                <ToggleButton
                    checked={true}
                    onChange={true}
                />
            </div>
        </div>
                {renderFields}
            </div>
        </div>
    )
}