import React, { Component } from "react";
import {
  Link,
  withRouter,
  // Link
} from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import "../../../../../css/CompaniesDetail.css";
import {
  calculateTerm,
  displayTermUnit,
} from "../../../../../util/calculateTermUtil";
import { connect } from "react-redux";
import { fetchTransactionByContractId } from "../../../AdminActions";
import { getTransactionForContract } from "../../../AdminReducers";
import callApi from "../../../../../util/apiCaller";

class ContractDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contract_transactions: [],
      trading_history: [],
    };
  }

  componentDidMount() {
    const id = this.props.contractDetails._id;
    if (id) {
      this.props.dispatch(fetchTransactionByContractId(id)).then(() => {
        this.setState({
          contract_transactions: this.props.transactions_for_contract,
        });
      });
      this.fetchTradingHistoryForContracts(id);
    }
  }

  fetchTradingHistoryForContracts = (contract_id) => {
    callApi(`admin/contracts/${contract_id}/trade-history`, "get").then(
      (res) => {
        if (res.status === "Success" && res.data) {
          this.setState({ trading_history: res.data.trades });
        }
      }
    );
  };
  render() {
    var contractsDetail = this.props.contractDetails;
    return (
      <section className="col p-0">
        <div id='company-detail' className='company-detail'>
          <div className='company-detail-row1'>
            <div className='d-flex justify-content-between align-items-center company-row1'>
              <div>
                <p className='grey-text small-fonts'>
                  <span
                    onClick={() => {
                      this.props.handleSegmentChange("contractlist", {});
                    }}>
                    Contracts &nbsp;&nbsp;{" "}
                  </span>
                  &nbsp;{contractsDetail.investee_organization_id.name}
                </p>
                <h3 className='company-name'>
                  {contractsDetail.investee_organization_id.name}{" "}
                </h3>
                <p className='black-text small-fonts'>
                  Status: &nbsp;
                  <span>{contractsDetail.approval_status}</span>
                </p>
              </div>
              <div className='d-flex justify-content-between align-items-center'>
                <p className='small-fonts last-edit'>
                  Last edited on:&nbsp;&nbsp;
                  {moment(contractsDetail.updated_at).format("DD MMM YYYY")}
                  <span> by Admin User</span>
                </p>
                <button
                  onClick={() => this.props.toggleEditView(true)}
                  className='btn-bg-clr medium-fonts company-big-btn'>
                  Edit Contract
                </button>
              </div>
            </div>
            <div className='company-row1'>
              <table class='table admin-contract-table'>
                <thead>
                  <tr>
                    <th>Customer ID</th>
                    <th>Contract Start </th>
                    <th>Contract End</th>
                    <th>Term</th>
                    <th>Delay</th>
                    <th>Payments</th>
                    <th>Requested By</th>
                    <th>Date Requested</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{contractsDetail.name}</td>
                    <td>
                      {moment(contractsDetail.contract_start_date).format(
                        "DD MMM YYYY"
                      )}
                    </td>
                    <td>
                      {moment(contractsDetail.contract_end_date).format(
                        "DD MMM YYYY"
                      )}
                    </td>
                    <td>{contractsDetail.contract_term_value_words}</td>
                    <td>
                      {contractsDetail.contract_payment_delay
                        ? contractsDetail.contract_payment_delay
                        : contractsDetail.investee_organization_id.global_delay
                        ? contractsDetail.investee_organization_id.global_delay
                        : "-"}
                    </td>
                    <td>
                      <i class='fa fa-rupee' style={{ color: "gray" }}></i>{" "}
                      {contractsDetail.recurring_payment_value} /{" "}
                      {displayTermUnit(contractsDetail.contract_term_unit)}
                    </td>
                    <td className='blue-table-cel'>Company user</td>
                    <td>
                      {moment(contractsDetail.created_at).format("DD MMM YYYY")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className='contract-detail-row2'>
            <div className='contract-table-type mb-4'>Trading History</div>
            {/* <div className="tableFixHead-contract"> */}
            <div>
              <table class='table admin-contract-table'>
                <thead>
                  <tr>
                    <th className='pl-2'>FIRST REPAYMENT DATE</th>
                    <th>TRADED TERMS</th>
                    <th>LAST REPAYMENT DATE</th>
                    <th>REPAYMENTS</th>
                    <th>INVESTOR</th>
                    <th>DATE REQUESTED</th>
                    <th>STATUS</th>
                    <th>TRADE ID</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.trading_history.map((item) => (
                    <tr key={item._id}>
                      <td className='pl-2'>
                        {moment(item.first_repayment).format("DD MMM YYYY")}
                      </td>
                      <td>{item.traded_terms}</td>
                      <td>
                        {moment(item.last_repayment).format("DD MMM YYYY")}
                      </td>
                      <td>
                        ₹ {item.recurring_payment_value * item.traded_terms}
                      </td>
                      <td>{item.contract_investor_id?.contact_name}</td>
                      <td>
                        {moment(item.contract_trade_date).format("DD MMM YYYY")}
                      </td>
                      <td>{item.investor_status}</td>
                      <td>{item.trade_id}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className='contract-table-type'>Payment Schedule</div>
            {/* <div className="tableFixHead-contract"> */}
            <div>
              <table class='table pay-schedule-table'>
                <thead>
                  <tr>
                    <th
                      colspan='4'
                      style={{ textAlign: "center" }}
                      className='contract-BR contract-BB contract-bold'>
                      SCHEDULED
                    </th>
                    <th
                      colspan='4'
                      style={{ textAlign: "center" }}
                      className='contract-BB contract-bold'>
                      ACTUAL
                    </th>
                  </tr>
                  <tr>
                    <th className='pl-2'>INVOICE DATE</th>
                    <th>PAYMENT DATE</th>
                    <th>REPAYMENT DATE</th>
                    <th className='contract-BR'>AMOUNT</th>
                    <th className='pl-5'>INVOICE DATE</th>
                    <th>PAYMENT DATE</th>
                    <th>REPAYMENT DATE</th>
                    <th>AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.contract_transactions ? (
                    this.state.contract_transactions
                      .filter(
                        (item) =>
                          item.transaction_type === "COMPANY_TO_INVESTOR"
                      )
                      .map((i) => {
                        return (
                          <tr>
                            {i.is_expired ? (
                              <td
                                className='pl-2'
                                style={{ textDecoration: "line-through" }}>
                                {moment(i.scheduled_invoice_date).format(
                                  "DD MMM YY"
                                )}
                              </td>
                            ) : (
                              <td className='pl-2'>
                                {moment(i.scheduled_invoice_date).format(
                                  "DD MMM YY"
                                )}
                              </td>
                            )}
                            <td>
                              {moment(i.scheduled_collection_date).format(
                                "DD MMM YY"
                              )}
                            </td>
                            <td>
                              {moment(i.scheduled_recur_payment_date).format(
                                "DD MMM YY"
                              )}
                            </td>
                            <td className='contract-BR'>
                              <i
                                class='fa fa-rupee'
                                style={{ color: "gray" }}></i>
                              {i.scheduled_invoice_amount}
                            </td>
                            <td className='pl-5'>
                              {i.actual_invoice_date
                                ? moment(i.actual_invoice_date).format(
                                    "DD MMM YY"
                                  )
                                : "-"}
                            </td>
                            <td>
                              {i.actual_collection_date
                                ? moment(i.actual_collection_date).format(
                                    "DD MMM YY"
                                  )
                                : "-"}
                            </td>
                            <td>
                              {i.actual_recur_payment_date
                                ? moment(i.actual_recur_payment_date).format(
                                    "DD MMM YY"
                                  )
                                : "-"}
                            </td>
                            <td>
                              <i
                                class='fa fa-rupee'
                                style={{ color: "gray" }}></i>{" "}
                              {i.actual_recur_payment_amount
                                ? i.actual_recur_payment_amount
                                : "-"}
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <tr>
                      <td className='pl-2'>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td className='contract-BR'>
                        <i class='fa fa-rupee' style={{ color: "gray" }}></i>-
                      </td>
                      <td className='pl-5'>-</td>
                      <td>-</td>
                      <td>- </td>
                      <td>
                        <i class='fa fa-rupee' style={{ color: "gray" }}></i>-
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    transactions_for_contract: getTransactionForContract(state),
  };
};
export default withRouter(connect(mapStateToProps)(ContractDetailPage));
