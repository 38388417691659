import React from "react";
import { DEAL_STAGE_STATUS, DEAL_STAGE_IMG_URL } from "../../../../../../../../constants/enums";

import styles from './dealProgressTable.module.scss'

const DealsProgressTable = ({ rowData, tooltipPosition, onMouseEnter, onMouseLeave }) => {
    const {
        contentStyles,
        overlayArrow,
        dealStatuslement,
        overlayStyles,
        headers,
        lineItembaseStyle,
        lineItembaseCompletedStageNameStyle,
        lineItembasePendingStyle,
        lineItembaseNotStartedStyle,
        lineItemImg,
        lineItemBody,
        dashedLineTop,
        dashedLineBottom,
        lineItembaseCompletedStyle,
        flex30,
        flex25,
        flex20,
        flex15,
    } = styles;

    const getCompletionStageImgCss = (status) => {
        if (status === DEAL_STAGE_STATUS.COMPLETED) {
            return { height: "24px", width: '24px' }
        }
        else {
            return { height: "20px", width: '20px', marginLeft: "2px" };
        }

    }

    return (
        <div className={overlayStyles} onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}>
            <div className={overlayArrow}></div>

            <div className={contentStyles} >
                <div className={headers}>
                    <div
                        className={dealStatuslement}
                    >
                        Deal Status
                    </div>
                    {/* we will include below section  later */}
                    {/* <div
                        style={{
                            textAlign: "right",
                            backgroundColor: "#E5FCDE",
                            padding: "5px 9px 5px 9px",
                            borderRadius: "5px",
                        }}
                    >
                        <span style={{ color: "#00AA00", fontFamily: "Gilroy", fontSize: '10px', fontWeight: "500", }}>21 Days to Cash Transfer</span>
                        <span style={{ color: "#A9A9A9", fontFamily: "Gilroy", fontSize: '10px', fontWeight: "500", }}> (Estimated)</span>
                    </div> */}
                </div>
                <hr className={dashedLineTop} />
                {rowData.map((row) => (
                    <div>
                        <div key={row?.stageName} className={lineItemBody}>
                            <div className={lineItemImg}>
                                <img style={getCompletionStageImgCss(row.status)} src={row.imageSrc} alt="Icon" />
                            </div>
                            {
                                (row.status === DEAL_STAGE_STATUS.COMPLETED) ? (<>
                                    <div className={`${lineItembaseStyle} ${lineItembaseCompletedStageNameStyle}`}>{row?.stageName || "-"}</div>
                                    <div className={`${lineItembaseStyle} ${lineItembaseCompletedStyle} ${flex25}`} >{row?.stageOwner || "-"}</div>
                                    <div className={`${lineItembaseStyle} ${lineItembaseCompletedStyle} ${flex20}`} >{row?.completionDate || "-"}</div>
                                    <div className={`${lineItembaseStyle} ${lineItembaseCompletedStyle} ${flex15}`} >{row?.timeTaken || "-"}</div>
                                </>

                                ) : (row.status === DEAL_STAGE_STATUS.PENDING) ? (<>
                                    <div className={`${lineItembaseStyle} ${lineItembasePendingStyle} ${flex30}`} style={{ marginLeft: '-1px' }}>{row?.stageName || "-"}</div>
                                    <div className={`${lineItembaseStyle} ${lineItembasePendingStyle} ${flex25}`} >{row?.stageOwner || "-"}</div>
                                    <div className={`${lineItembaseStyle} ${lineItembasePendingStyle} ${flex20}`} >{row?.completionDate || "-"}</div>
                                    <div className={`${lineItembaseStyle} ${lineItembasePendingStyle} ${flex15}`} >{row?.timeTaken || "-"}</div>
                                </>) : (<>
                                    <div className={`${lineItembaseStyle} ${lineItembaseNotStartedStyle} ${flex30}`} style={{ marginLeft: "0px", fontWeight: "600" }}>{row?.stageName || "-"}</div>
                                    <div className={`${lineItembaseStyle} ${lineItembaseNotStartedStyle} ${flex25}`} >{row?.stageOwner || "-"}</div>
                                    <div className={`${lineItembaseStyle} ${lineItembaseNotStartedStyle} ${flex20}`} >{row?.completionDate || "-"}</div>
                                    <div className={`${lineItembaseStyle} ${lineItembaseNotStartedStyle} ${flex15}`} >{row?.timeTaken || "-"}</div>
                                    
                                </>)
                            }

                        </div>
                        <hr className={dashedLineBottom} />
                    </div>
                ))}
            </div>
        </div>
    );
};
export default DealsProgressTable;
