import React, { Component } from "react";
import RepaymentsTableHeader from "../RepaymentsPage/components/RepaymentsTableHeader";
import RepaymentsTableRow from "../RepaymentsPage/components/RepaymentsTableRow";
import AdminHead from "../../components/AdminHead.js";
import callApi from "../../../../util/apiCaller";
import { showNotification } from "../../../../util/utility";
import _ from "lodash";
import { mergeByProperty } from "../../../../util/calculateTermUtil";
import TransactionListHeader from "./components/TransactionListHeader";
import TransactionListRow from "./components/TransactionListRow";
import { Button } from "@material-ui/core";
import InputSearch from "../../../../components/InputSearch";
import InputDatePicker from "../../../../components/InputDatePicker";
class RepaymentsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contracts: [],
      repayments: [],
      is_fetching_repayments: false,
      pageNum: 1,
      pageSize: 200,
      totalCount: 0,
      qtext: "",
      selected_repayment_ids: [],
      is_marking_transaction_status: false,
      mark_transaction_status_as: "",
      searchObjInvestorOrg: {
        keyName: "investor_organization_name",
        value: "",
      },
      searchObjInvestorUser: {
        keyName: "investor_name",
        value: "",
      },
      searchObjCompanyName: {
        keyName: "investee_organization_name",
        value: "",
      },
      searchObjContractID: {
        keyName: "contract_id",
        value: "",
      },
      searchObjPaymentID: {
        keyName: "transaction_id",
        value: "",
      },
      sliderObjRepaymentNo: {
        keyName: "payment_number",
        value: "",
      },
      sliderObjAmount: {
        minVal: 0,
        maxVal: 12,
        currentMax: 12,
        currentMin: 0,
        keyName: "transaction_amount",
        minValueBetween: 1,
      },
      scheduledDateObj: {
        date: [new Date(), new Date()],
        keyName: "scheduled_recur_payment_date",
        isChanged: false
      },
      lastUpdateDateObj: {
        date: [new Date(), new Date()],
        keyName: "updated_at",
      },
      selectorObjStatus: {
        keyName: "transaction_status",
        elementsArray: [
          {
            label: "Complete",
            selected: true,
          },
          {
            label: "Delayed",
            selected: true,
          },
          {
            label: "Default",
            selected: true,
          },
          {
            label: "Scheduled",
            selected: true,
          },
        ],
      },
      viewTopLevel: true,
      listInvestorInvesteeDate: [],
      selected_repayment_index: null,
    };
    this.debounceSearch = _.debounce(() => this.fetchRepayments(true), 400);
  }

  keyStateObjNameDict = {
    investor_organization_name: "searchObjInvestorOrg",
    investor_name: "searchObjInvestorUser",
    investee_organization_name: "searchObjCompanyName",
    contract_id: "searchObjContractID",
    transaction_id: "searchObjPaymentID",
    payment_number: "sliderObjRepaymentNo",
    recurring_payment_value: "sliderObjPayments",
    transaction_amount: "sliderObjAmount",
    scheduled_recur_payment_date: "scheduledDateObj",
    updated_at: "lastUpdateDateObj",
    transaction_status: "selectorObjStatus",
  };

  componentDidMount() {
    this.fetchRepayments();
  }

  onSelectTransaction = (val) => {
    this.setState({ selected_repayment_index: val })
  }

  emptySelectedIds = () => {
    this.setState({ selected_repayment_ids: [] })
  }

  onChangeTransactionCheckbox = (val) => {
    let selected_repayments_ids = [...this.state.selected_repayment_ids];
    if (val.target.checked) {
      selected_repayments_ids.push(val.target.value);
    } else if (!val.target.checked) {
      _.remove(selected_repayments_ids, n => n === val.target.value);
    }
    this.setState({
      selected_repayment_ids: selected_repayments_ids
    });
  };

  fetchRepayments = (isReseting) => {
    this.setState({ is_fetching_repayments: true });
    let filters = this.getFilters();
    if (isReseting) {
      filters.pageNum = 1;
    }
    callApi(`admin/contract-transaction/list`, "post", filters).then((res) => {
      if (res.status === "Success") {
        let repayments = this.state.repayments;
        this.setState({
          repayments: isReseting ? res.data.transaction : mergeByProperty(repayments, res.data.transaction, "_id"),
          totalCount: res.data.count,
          selected_repayment_ids: [],
        });
      } else {
        showNotification("error", "Some error occured");
      }
      this.setState({ is_fetching_repayments: false });
    });
  };

  markTransactionStatus = (status) => {
    if (!(this.state.viewTopLevel) && _.isEmpty(this.state.selected_repayment_ids)) {
      showNotification("Error", "No Transaction is Selected");
      return;
    }
    if (this.state.viewTopLevel && _.isNil(this.state.selected_repayment_index)) {
      showNotification("Error", "No Repayments are Selected");
      return;
    }
    this.setState({
      is_marking_transaction_status: true,
      mark_transaction_status_as: status,
    });
    let obj = {};
    if (this.state.viewTopLevel) {
      obj = this.state.repayments[this.state.selected_repayment_index]._id
    } else {
      obj['contractTransactionsIdsArr'] = this.state.selected_repayment_ids
    }
    obj['status'] = status;
    callApi(`admin/contract-transaction/statusUpdateContractTransactions`, "post", obj).then((res) => {
      if (res.status === "Success") {
        showNotification("success", "Transaction Updated Successfully!");
        this.setState({
          is_marking_transaction_status: false,
        });
        this.fetchRepayments();
        if (this.state.viewTopLevel) {
          this.setState({ selected_repayment_index: null });
        } else {
          this.setState({ selected_repayment_ids: [] });
        }
      } else {
        showNotification("error", "Some error occured");
        this.setState({
          is_marking_transaction_status: false,
        });
      }
    });
  };

  hasMore = () => {
    console.log(
      "HAS_MORE ",
      this.state.totalCount,
      this.state.pageSize,
      this.state.pageNum
    );
    return this.state.totalCount + 1 > this.state.pageNum * this.state.pageSize;
  };

  loadMore = () => {
    this.setState({ pageNum: this.state.pageNum + 1 });
    let filters = this.getFilters();
    this.fetchRepayments();
  };

  getFilters = () => {
    let filters = {};
    filters.pageSize = this.state.pageSize;
    filters.pageNum = this.state.pageNum;
    // filters.pageNum = this.state.pageNum;
    // filters.pageSize = this.state.pageSize;
    if (this.state.qtext) {
      filters.isSearching = true;
      filters.qtext = this.state.qtext;
    }
    // filters.isFiltering = true
    // filters.isSearching = true;
    // filters[this.state.searchObjCompanyName.keyName] = this.state.searchObjCompanyName.value;
    // filters[this.state.searchObjCustomer.keyName] = this.state.searchObjCustomer.value;
    // filters[this.state.searchObjCustomerEmail.keyName] = this.state.searchObjCustomerEmail.value;
    // filters[this.state.searchObjAdminUser.keyName ] = this.state.searchObjAdminUser.value;
    // filters[this.state.searchObjRequesteBy.keyName ] = this.state.searchObjRequesteBy.value;
    // filters[this.state.sliderObjTerm.keyName] = {
    //     "min": this.state.sliderObjTerm.currentMin,
    //     "max": this.state.sliderObjTerm.currentMax
    // };
    // filters.sort_by = ''
    // filters[this.state.sliderObjPayments.keyName] = {
    //     "min": this.state.sliderObjPayments.currentMin,
    //     "max": this.state.sliderObjPayments.currentMax
    // };
    // filters[this.state.dateObjContractStart.keyName] = {
    //     "from":this.state.dateObjContractStart.date[0],
    //     "to":this.state.dateObjContractStart.date[1]
    // };
    // filters[this.state.dateObjContractEnd.keyName] = {
    //     "from":this.state.dateObjContractEnd.date[0],
    //     "to":this.state.dateObjContractEnd.date[1]
    // };
    // filters[this.state.dateObjLastUpdated.keyName] = {
    //     "from":this.state.dateObjLastUpdated.date[0],
    //     "to":this.state.dateObjLastUpdated.date[1]
    // };
    // filters[this.state.dateObjDateRequested.keyName] = {
    //     "from":this.state.dateObjDateRequested.date[0],
    //     "to":this.state.dateObjDateRequested.date[1]
    // };
    // filters[this.state.selectorObjStatus.keyName] = {
    //     "in": this.state.selectorObjStatus.elementsArray.map((item)=>{
    //         if(item.selected){
    //             return item.label;
    //         }
    //     })
    // }
    if (this.state.scheduledDateObj.isChanged)
      filters["scheduled_recur_payment_date"] = {
        'from': this.state.scheduledDateObj.date[0],
        'to': this.state.scheduledDateObj.date[1]
      }
    return filters;
  };

  handleApplyFilters = () => {
    this.fetchContracts();
  };

  handleSearchFilter = (obj) => {
    let searchObj = {};
    searchObj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(searchObj, () => {
      this.handleApplyFilters();
    });
  };

  handleSliderFilter = (obj) => {
    let sliderobj = {};
    sliderobj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(sliderobj, () => {
      this.handleApplyFilters();
    });
  };

  handledatePicker = (obj) => {
    let datePickerObj = {};
    datePickerObj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(datePickerObj, () => {
      this.handleApplyFilters();
    });
  };

  handleSetDate = (val, i) => {
    let obj = { ...this.state.scheduledDateObj }
    obj.date[i] = val;
    obj.isChanged = true;
    this.setState({ scheduledDateObj: obj })
  }

  inputSelectorHandler = (obj) => {
    let selectorObj = {};
    selectorObj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(selectorObj, () => {
      this.handleApplyFilters();
    });
  };

  handleRightArrowClick = (item, fromRefreshClick) => {
    let obj = { ...item }
    obj['pageSize'] = 200
    callApi(`admin/contract-transaction/listInvestorInvesteeDate`, 'post', obj)
      .then((result) => {
        if (result.status === 'Success' && result.data.length > 0) {
          if (fromRefreshClick)
            this.setState({ listInvestorInvesteeDate: result.data })
          else
            this.setState({ viewTopLevel: false }, () => {
              this.setState({ listInvestorInvesteeDate: result.data })
            })
        }
      })
  }

  handleResfresh = () => {
    let obj = {}
    obj.investee_organization_id = this.state.listInvestorInvesteeDate[0].investee_organization_id
    obj.investor_organization_id = this.state.listInvestorInvesteeDate[0].investor_organization_id
    obj.scheduled_recur_payment_date = this.state.listInvestorInvesteeDate[0].scheduled_recur_payment_date
    this.handleRightArrowClick(obj, true)
  }

  handleTextSearch = (val) => {
    this.setState(
      {
        qtext: val,
      },
      () => {
        this.debounceSearch();
      }
    );
  };

  render() {
    return (
      <div className="main-body">
        <AdminHead />
        <section id='admin-company'>
          <div class='d-flex justify-content-between form-group has-search'>
            <div className='d-flex justify-content-between'>
              {this.state.is_marking_transaction_status &&
                this.state.mark_transaction_status_as === "Approved" ? (
                <button className='app-btn btn-approve' disabled={true}>
                  <img src='../../../../../assets/icon-done.svg' alt=''></img>
                  Loading...
                </button>
              ) : (
                <button
                  className='app-btn btn-approve'
                  onClick={() => this.markTransactionStatus("Complete")}>
                  <img src='../../../../../assets/icon-done.svg' alt=''></img>
                  Complete
                </button>
              )}

              <button
                className='app-btn btn-no-approve'
                onClick={() => this.markTransactionStatus("Default")}>
                <img src='../../../../../assets/icon-clear.svg' alt=''></img>
                Default
              </button>
              {/* <button
                className='app-btn btn-hold'
                onClick={() => this.markTransactionStatus("Delayed")}>
                <img src='../../../../../assets/icon-history.svg' alt=''></img>
                Delayed
              </button> */}
            </div>
            <div className="d-flex">
              {this.state.viewTopLevel ?
                <>
                  <div className="d-flex mr-3">
                    <label>From</label>
                    <input
                      className="filter-value ml-1 h-28" type="date" id="start-date"
                      name="comp-name"
                      onChange={e => this.handleSetDate(e.target.value, 0)}
                      value={this.state.scheduledDateObj.date[0]}
                    >
                    </input>
                  </div>
                  <div className="d-flex mr-3">
                    <label>To</label>
                    <input
                      className="filter-value ml-1 h-28" type="date" id="end-date"
                      name="comp-name"
                      onChange={e => this.handleSetDate(e.target.value, 1)}
                      value={this.state.scheduledDateObj.date[1]}
                    >
                    </input>
                    <Button variant='outlined' className="ml-1" style={{ color: 'blue', borderColor: 'blue', height: '23px' }} onClick={() => this.fetchRepayments(true)}>Go</Button>
                  </div>
                  <input
                    value={this.state.qtext}
                    onChange={(e) => this.handleTextSearch(e.target.value)}
                    type='text'
                    className='form-control'
                    placeholder='Search Repayments'
                    style={{ margin: "auto 0px" }} />
                </>
                :
                <button
                  className='app-btn'
                  style={{ backgroundColor: '#007bff', color: '#fff', width: '55px' }}
                  onClick={this.handleResfresh}>
                  <i class="fa fa-refresh" aria-hidden="true"></i>
                </button>
              }
            </div>
          </div>
          {!(this.state.viewTopLevel) &&
            <Button variant='outlined' style={{ color: 'blue', borderColor: 'blue' }} onClick={() => this.setState({ viewTopLevel: true })}>Back</Button>}
          <div className='table-head'>
            {/* admin-data-table tableFixHead */}
            <table className='table team'>
              {this.state.viewTopLevel
                ?
                <TransactionListHeader />
                : <RepaymentsTableHeader
                  searchObjInvestorOrg={this.state.searchObjInvestorOrg}
                  searchObjInvestorUser={this.state.searchObjInvestorUser}
                  searchObjCompanyName={this.state.searchObjCompanyName}
                  searchObjContractID={this.state.searchObjContractID}
                  searchObjPaymentID={this.state.searchObjPaymentID}
                  sliderObjRepaymentNo={this.state.sliderObjRepaymentNo}
                  sliderObjAmount={this.state.sliderObjAmount}
                  scheduledDateObj={this.state.scheduledDateObj}
                  lastUpdateDateObj={this.state.lastUpdateDateObj}
                  selectorObjStatus={this.state.selectorObjStatus}
                  handleSliderFilter={this.handleSliderFilter}
                  inputSelectorHandler={this.inputSelectorHandler}
                  handleSearchFilter={this.handleSearchFilter}
                  handledatePicker={this.handledatePicker}
                />
              }
              <tbody style={{ display: 'none' }}></tbody>
            </table>
          </div>
          <div className='tableFixHead table-body'>
            <table
              className='table team'
            >
              <thead></thead>
              <tbody className='team-body'>
                {this.state.viewTopLevel ?
                  (this.state.repayments?.map((item, index) => (
                    <TransactionListRow
                      index={index}
                      key={index}
                      item={item}
                      loader={this.state.is_fetching_repayments}
                      hasMore={this.hasMore}
                      loadMore={this.loadMore}
                      dataLength={this.state.repayments.length}
                      selected_repayment_index={this.state.selected_repayment_index}
                      onSelectTransaction={this.onSelectTransaction}
                      handleRightArrowClick={this.handleRightArrowClick}
                      selected_repayment_ids={this.state.selected_repayment_ids} />
                  )))
                  :
                  (this.state.listInvestorInvesteeDate?.map((item, index) => (
                    <RepaymentsTableRow
                      key={item._id}
                      item={item}
                      index={index}
                      emptySelectedIds={this.emptySelectedIds}
                      onChangeTransactionCheckbox={
                        this.onChangeTransactionCheckbox
                      }
                      selected_repayment_ids={this.state.selected_repayment_ids} />
                  )))
                }
              </tbody>
            </table>
          </div>
        </section>
      </div>
    );
  }
}

export default RepaymentsPage;
