import React, { useCallback, useRef } from "react";
// import {Link} from 'react-router-dom';
import moment from "moment";
import { downloadFileFromArrayBuffer, priceFormat } from "../../../../../util/utility";
import { FILE_CONTENT_TYPE } from "../../../../../constants/enums";
import { useSelector } from "react-redux";
import { getCheckIfUserHasRelevantPermissions } from "../../../../App/AppReducer";

function downloadNoDues(order_id, investee_name) {
  const getBuffer = (data) => {
    return data.file.Body.data;
  };
  const cb = null;
  downloadFileFromArrayBuffer(`admin/${order_id}/download-no-dues`, "post", {}, null, `${investee_name}_${order_id}`, { type: FILE_CONTENT_TYPE.PDF }, getBuffer, cb);
}

function OrdersTableRow({ item, loader, hasMore, loadMore, selected_order_ids, onChangeTradeCheckbox, dataLength, index, changeView }) {
  const observer = useRef();
  const isUserHasUWViewAccess = useSelector((state) => getCheckIfUserHasRelevantPermissions(state, ["underwriting:edit"]));
  const lastTradeRef = useCallback(
    (node) => {
      if (loader) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore()) loadMore();
      });
      if (node) observer.current.observe(node);
    },
    [loader, hasMore()]
  );

  const renderer = (
    <>
      <td>
        <input
          disabled={["Approved", "Not Approved","Completed"].includes(item.approval_status)}
          type='checkbox'
          value={item._id}
          style={{ marginLeft: "5px" }}
          checked={selected_order_ids.some((i) => i === item._id)}
          onChange={onChangeTradeCheckbox}
        ></input>
      </td>
      <td style={{width:'20%'}}>{item.investor_org_id?.name}</td>
      <td style={{wordBreak:'break-all', width:'20%'}} className="pr-2">
        {item.investee_org_id?.name}
      </td>
      {/* <td>{item.order_count}</td> */}
      <td>₹ {priceFormat(item.total_investment, 2)}</td>
      <td style={{width:'10%'}}>{moment(item.created_at).format('DD MMM YYYY')}</td>
      <td className={`${item.approval_status.split(' ').join('-')}-status`}
      // className='bold-table-cel blue-table-cel'
      >
        <b>{item.approval_status}</b>
      </td>
      <td>{item.order_id}</td>
      <td>{item?.recur_fee?.investee_fee_waived === false ? `₹ ${priceFormat(item?.recur_fee?.investee_fee_amount,2)}` : "Waived"}</td>
      <td>
        <a target='_blank' rel="noopener noreferrer" style={item.escrow_holding_certificate_path ? { color: '#279C13' } : { color: 'grey' }} href={isUserHasUWViewAccess? null:item.escrow_holding_certificate_path}>{ }<i className="fa fa-download" aria-hidden="true"></i></a>
      </td>
      {/* <td>
        <div rel="noopener noreferrer" style={{ color: '#279C13' }} onClick={()=>{!isUserHasUWViewAccess&& downloadNoDues(item._id,item.investee_org_id?.name)}}>{ }<i className="fa fa-download" aria-hidden="true"></i></div>
      </td> */}
      <td className="pr-4"
      onClick={() => !isUserHasUWViewAccess&& changeView("trades", item._id)}><img src='../../../../../assets/right-arrow-order.svg' alt=''></img></td>
    </>
  );

  if (dataLength === index + 1) {
    return <tr ref={lastTradeRef}>{renderer}</tr>;
  } else {
    return <tr>{renderer}</tr>;
  }
}

export default OrdersTableRow;
