import {DRAWER_ENUMS, getTdsStatusColors} from "../CustomerRequests.tsx";
import {Drawer} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import './StatementOfAccountDrawer.scss'
import RequestType from "../RequestType/RequestType.tsx";
import {showNotification} from "../../../../../util/utility.js";
import {GENERIC_ERROR, SHOW_NOTIFICATION, SHOW_NOTIFICATION_STATUS} from "../../../../../constants/enums.js";
import {
    fetchPresignedUrl,
    getBalanceConfirmationData,
    sendReminderToCustomerSupport
} from "../../../../../common/ApiHelpers.ts";
import {useSelector} from "react-redux";
import {getEmail} from "../../../../App/AppReducer.js";
import { isEmpty } from "lodash";

interface BalanceConfirmationDrawerProps {
    openDrawer: string,
    setOpenDrawer: React.Dispatch<React.SetStateAction<string>>
    claimData: any
    getClaimData: () => void
}

export interface ViewOnlyData {
    investeeOrganizationId: string;
    startDate: string;
    endDate: string;
    submittedOn: string;
    lastActivityOn: string;
    documents: Document[];
    drawdowns: Drawdowns[];
    status: string;
    customerQueryId: string;
    investorName: string;
    investeeName: string;
    expectedResolutionDate: string;
}

interface Drawdowns {
    investorName: string;
    investorOrganizationId: string;
    lanId?: string;
    amount: string;
    drawdownDate: string;
    orderId: string;
}

interface Document {
    documentName: string;
    docId: string;
}

const BalanceConfirmationDrawer = (props: BalanceConfirmationDrawerProps) => {
    const emptyData: ViewOnlyData = {
        investeeOrganizationId: '',
        startDate: '',
        endDate: '',
        documents: [],
        submittedOn: '',
        lastActivityOn: '',
        drawdowns: [],
        status: '',
        customerQueryId: '',
        investorName: '',
        investeeName: '',
        expectedResolutionDate: '',
    };
    const {openDrawer, setOpenDrawer, claimData, getClaimData} = props
    const [data, setData] = useState<ViewOnlyData>(emptyData)
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const email = useSelector(getEmail)
    const getViewOnlyData = async (customerSupportQueryId: string) => {
        const res = await getBalanceConfirmationData(customerSupportQueryId);
        if (res) {
            setData(res);
        } else {
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Something went wrong');
            setOpenDrawer('')
        }
    };
    useEffect(() => {
        if (!isEmpty(claimData) && openDrawer === DRAWER_ENUMS.STATEMENT_OF_ACCOUNT) {
            getViewOnlyData(claimData.customerQueryId);
        }
    }, [claimData,openDrawer]);
    const downloadDocument = async (docId:string) => {
        const res = await fetchPresignedUrl(docId);
        if (res) {
            window.open(res.signedUrl, '_blank');
        } else {
            showNotification(SHOW_NOTIFICATION.ERROR, GENERIC_ERROR);
        }
    }
    const sendReminderToInv = async () => {
        setDisabled(true);
        const res = await sendReminderToCustomerSupport(claimData.customerQueryId, email);
        if (res) {
            setDisabled(false);
            showNotification(SHOW_NOTIFICATION.SUCCESS, res.responseData.responseMessage);
        } else {
            setDisabled(false);
            showNotification(SHOW_NOTIFICATION.ERROR, res.responseData.responseMessage??GENERIC_ERROR);
        }
    }
    return (
        <Drawer
            anchor="right"
            open={openDrawer === DRAWER_ENUMS.STATEMENT_OF_ACCOUNT}
            ModalProps={
                {

                    style: {
                        zIndex: 100,
                    }
                }}
            PaperProps={{
                style: {
                    width: '37%',
                    zIndex: 100
                },
            }}
            disableEnforceFocus
        >
            <div className={'BalanceConfirmationWrapper'}>
                <div className={'DrawerHeading'}>
                    <div className={'HeadingText'}>
                        <div>Ticket Details</div>
                        <div className={'StatusBanner'}
                             style={{
                                 color: getTdsStatusColors(claimData.status).color,
                                 background: getTdsStatusColors(claimData.status).banner,
                             }}>
                            {claimData.status}
                        </div>
                    </div>
                    <div className={'CrossIcon'}>
                        <img onClick={() => setOpenDrawer('')} src="../../../../../assets/modal-cross.svg"
                             alt=""></img>
                    </div>
                </div>
                <div className={'QuestionContainer'}>
                    <div className={'DrawerBody'}>
                        <RequestType requestType={'Statement of Account'} showChangeText={false}/>
                        <div className={'detailsContainer'}>
                            <div className={'ViewOnlyTextContainer'}>
                                <p>
                                    Ticket ID: <span>{data.customerQueryId}</span>
                                </p>
                                <p>
                                    Submitted on: <span>{data.submittedOn}</span>
                                </p>
                                {data.status === 'RESOLVED' && (
                                    <p>
                                        Resolved on: <span>{data.lastActivityOn}</span>
                                    </p>
                                )}
                                <p>
                                    Company Name: <span>{data.investeeName}</span>
                                </p>
                                <p>
                                    Investor Name: <span>{data.investorName}</span>
                                </p>
                                <p>
                                    Selected Duration:{' '}
                                    <span>{data.startDate} - {data.endDate}</span>
                                </p>
                            </div>
                            <p style={{ margin: '1rem 0' }}>Selected Drawdowns</p>
                            <div className={'DrawdownsContainer'}>
                                {data.drawdowns.map((drawDown, index) => {
                                    return (
                                        <div
                                            key={index}
                                            style={
                                                index === data?.drawdowns?.length - 1
                                                    ? { border: 'none' }
                                                    : {}
                                            }
                                            className={'DrawdownItems'}
                                        >
                                    <span
                                        className={'InvestorName'}
                                        title={drawDown.investorName}
                                    >
                                        {drawDown.investorName}
                                    </span>
                                            <span
                                                style={{
                                                    color: '#7F7F7F',
                                                    fontFamily: 'Gilroy-Medium,serif',
                                                }}
                                            >
                                        (₹ {drawDown.amount} drawn on {drawDown.drawdownDate})
                                    </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {data?.documents?.length > 0 && (
                            <p style={{ margin: '2rem 0 1rem 0' }}>Attached Document(s)</p>
                        )}
                        <div className={'AttachmentContainer'}>
                            {data?.documents?.map((document, index) => {
                                return (
                                    <div key={index} className={'AttachmentItem'}>
                                <span onClick={() => downloadDocument(document.docId)}>
                                    {document.documentName}
                                </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className={'TdsDrawerFooter'}>
                <div className={'FooterButtonsContainer'}>
                    <button
                        onClick={() => {
                            setOpenDrawer('')
                        }}
                        className={'FooterRejectBtn'}
                    >
                        Close
                    </button>
                    {claimData.status!=='RESOLVED'&&<button
                        onClick={() => {
                            sendReminderToInv()
                        }}
                        disabled={disabled}
                        className={'FooterSendReminderBtn'}
                    >
                        Send Reminder to Investor
                    </button>}
                </div>
            </div>
        </Drawer>
    )
}

export default BalanceConfirmationDrawer