import React, { useEffect, useState } from "react";
import styles from './dealSummary.module.scss'
import DealsProgressTable from "./dealsProgressTable";
import { DEAL_STAGE_STATUS, DEAL_STAGE_IMG_URL, EXPIRING_DEAL_STAGES, EXPIRED_OR_PASSED_DEALS_STAGES } from "../../../../../../../../constants/enums";
const DealsProgressSummary = ({dealData}) => {
    const {
        mainComponent
    } = styles
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipHovered, setTooltipHovered] = useState(false);
    const lastCompletedStageUp = (dealData?.lastCompletedStageUpper) ? dealData?.lastCompletedStageUpper : dealData?.lastCompletedStageDown;
    const lastCompletedStageDown = (dealData?.lastCompletedStageDown) ? dealData?.lastCompletedStageDown : "N/A";
    const completionImgURL = (EXPIRED_OR_PASSED_DEALS_STAGES.includes(lastCompletedStageUp)) ? DEAL_STAGE_IMG_URL.STAGE_COMPLETED_FADED_IMG_URL : DEAL_STAGE_IMG_URL.STAGE_COMPLETED_IMG_URL;
    const notStartedImgUrl = DEAL_STAGE_IMG_URL.STAGE_NOT_DONE_IMG_URL;
    const pendingImgUrl = DEAL_STAGE_IMG_URL.STAGE_PENDING_IMG_URL;
    const [dealsProgressTableData, setdealsProgressTableData]=useState([]);

    useEffect(()=>{
        prepareDataForDealProgressTable(dealData?.visibilityStates, dealData?.lastCompletedStageUpper);
    },[dealData])

    const handleComponentMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleComponentMouseLeave = () => {
        if (!tooltipHovered) {
            setShowTooltip(false);
        }
    };

    const handleTooltipMouseEnter = () => {
        setTooltipHovered(true);
    };

    const handleTooltipMouseLeave = () => {
        setTooltipHovered(false);
        setShowTooltip(false);
    };
    const getLastCompletedStageUpCSS=(lastCompletedStageUp)=>{
        if(lastCompletedStageUp === DEAL_STAGE_STATUS.QUERY_RAISED){
            return { color: "#FCBF07", fontSize: "9px" }
        }
        else if(EXPIRED_OR_PASSED_DEALS_STAGES.includes(lastCompletedStageUp) ){
            return { color: "#c3c3c3", fontSize: "9px" }
        }
        else if(EXPIRING_DEAL_STAGES.includes(lastCompletedStageUp)){
            return { color: "#DF666A", fontSize: "9px" };
        }
        else return { color: "#00AA00", fontSize: "9px" };

    }
    const getLastCompletedStageDownCSS=()=>{
       if(EXPIRED_OR_PASSED_DEALS_STAGES.includes(lastCompletedStageUp)){
         return { color: "#c3c3c3", fontSize: "9px" };
       }
       else{
        return { color: "#000000", fontSize: "9px" };
       }

    }

    const prepareDataForDealProgressTable = (data,lastCompletedStageUpper) => {
        if (!data) return;
        let rowData = [];
        for (let i = 0; i < data.length; i++) {
            const obj = data[i];
            const modifiedObj = {};
            modifiedObj.status = (obj?.status === DEAL_STAGE_STATUS.COMPLETED) ? DEAL_STAGE_STATUS.COMPLETED : (obj?.status === DEAL_STAGE_STATUS.PENDING) ? DEAL_STAGE_STATUS.PENDING : DEAL_STAGE_STATUS.NOT_STARTED;
            modifiedObj.timeTaken = obj?.timeTaken;
            modifiedObj.completionDate = obj?.completionDate;
            modifiedObj.stageOwner = obj?.owner;
            modifiedObj.stageName = obj?.stage;
            if((EXPIRED_OR_PASSED_DEALS_STAGES).includes(lastCompletedStageUpper)) {
                modifiedObj.status = (obj?.status === DEAL_STAGE_STATUS.COMPLETED) ? DEAL_STAGE_STATUS.COMPLETED : DEAL_STAGE_STATUS.NOT_STARTED;
                modifiedObj.completionDate = (obj?.status === DEAL_STAGE_STATUS.COMPLETED) ? obj?.completionDate : "-";
            }
            modifiedObj.imageSrc = (modifiedObj?.status === DEAL_STAGE_STATUS.COMPLETED) ? DEAL_STAGE_IMG_URL.STAGE_COMPLETED_IMG_URL : (modifiedObj?.status === DEAL_STAGE_STATUS.PENDING) ? DEAL_STAGE_IMG_URL.STAGE_PENDING_IMG_URL : DEAL_STAGE_IMG_URL.STAGE_NOT_DONE_IMG_URL;
            rowData.push(modifiedObj);
        }
        setdealsProgressTableData(rowData)
    }

    return (
        <div
            className={mainComponent}
            onMouseEnter={handleComponentMouseEnter}
            onMouseLeave={handleComponentMouseLeave}
        >
            <div>
               <span style={getLastCompletedStageUpCSS(lastCompletedStageUp)}>{lastCompletedStageUp}</span>
            </div>
            <div>
                {
                    dealsProgressTableData.map((row, index) => (
                        <span>
                            {
                                (row.status === DEAL_STAGE_STATUS.COMPLETED ) ? (
                                    <img style={{ height: "16px", width: '16px' }} src={completionImgURL} alt="Icon" />
                                ) : (
                                    <>
                                        <img style={{ height: "14px", width: '14px', marginLeft: "2px" }} src={notStartedImgUrl} alt="Icon" />
                                    </>
                                )
                            }

                        </span>

                    ))}


            </div>
            <div>
                <span style={getLastCompletedStageDownCSS()}>
                   <span>{lastCompletedStageDown} </span>
                    <span style={{ color: "#c3c3c3", marginLeft: "5px", fontSize: "9px" }}>
                       {dealData?.lastActivityDateIdeal}
                    </span>
                </span>
            </div>

            {showTooltip && (
                <DealsProgressTable
                    rowData={dealsProgressTableData}
                    onMouseEnter={handleTooltipMouseEnter}
                    onMouseLeave={handleTooltipMouseLeave}
                />
            )}
        </div>
    )
}

export default DealsProgressSummary;