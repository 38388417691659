import React from "react";
import moment from "moment";

const InvestorInvestbook = ({ tradebook }) => {
  return (
    <>
      <div className='admin-data-table1'>
        <table className='table team'>
          <thead className='team-head'>
            <tr>
              <th style={{ paddingLeft: "20px" }}>Company</th>
              <th scope='col'>Contract ID</th>
              <th scope='col'>Term</th>
              <th scope='col'>IRR</th>
              {/* <th style={{padding:'0px 5px'}}></th> */}
              <th scope='col'>Investment</th>
              <th scope='col'>Repayments</th>
              <th scope='col'>Received</th>
              <th scope='col'>
                Investment Date{" "}
                <img
                  alt=''
                  src='/assets/investor-down-arrow.png'
                  style={{ paddingLeft: "2px", width: "12px" }}
                />
              </th>
              <th scope='col'>Trade ID</th>
              <th scope='col'>Status</th>
            </tr>
          </thead>
          <tbody className='team-body'>
            {tradebook &&
              tradebook.map((item) => (
                <tr>
                  <td
                    className='table-company'
                    style={{ paddingLeft: "20px", color: "#4C8DFF" }}>
                    <img
                      alt=''
                      src='/assets/default-company-listing-logo.svg'
                      style={{ paddingRight: "10px" }}
                    />
                    {item.investee_organization_id.name}
                  </td>
                  <td>
                    {/* <img alt="" src="/assets/cart-contractid.svg" style={{ paddingRight: '5px' }} /> */}
                    {item.contract_id_num}
                  </td>
                  <td>
                    {item.contract_term_value} {item.contract_term_unit}
                  </td>
                  <td>{item.investor_irr_recorded}%</td>
                  {/* <td></td> */}
                  <td className='bold-table-cel'>
                    ₹{" "}
                    {item.investor_payment_amount
                      ? item.investor_payment_amount.toLocaleString("en-IN")
                      : null}
                  </td>
                  <td>
                    ₹{" "}
                    {item.recurring_payment_value
                      ? item.recurring_payment_value.toLocaleString("en-IN")
                      : null}
                  </td>
                  <td>
                    {item.payouts_done} / {item.payouts_total}
                  </td>
                  <td>
                    {moment(item.contract_trade_date).format("DD MMM YYYY")}
                  </td>
                  <td>{item.trade_id}</td>
                  <td>
                    <span className='scheduled-bg'>{item.investor_status}</span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default InvestorInvestbook;
