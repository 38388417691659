import clsx from "clsx";
import React from "react";
import { Modal } from "react-bootstrap";
import "./ConfirmTotUpdate.scss";

interface ConfirmDeleteModal {
  show: boolean;
  onHide: () => void;
  header: string;
  title: string;
  onDelete?: () => void;
  showMessage2: boolean;
}

export default function ConfirmTotUpdate({
  show,
  onHide,
  header,
  title,
  onDelete,
  showMessage2,
}: ConfirmDeleteModal) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      className="TOT-update-modal"
      backdrop="static"
      //   size={{ width: "446px", height: "265px" }}
    >
      <div>
        <div className="modal-header">
          <div className="fs21 fw6 mb-3 clr-red">{header}</div>
          <button onClick={onHide} type="button" className="close ml-auto">
            <img src="/assets/clear.png" alt="Clear" />
          </button>
        </div>
        <div className="modal-body">
          <div className={"DescriptionMessage"}>
            {title}
            {!showMessage2 && "Are you sure you want to proceed?"}
          </div>
          {showMessage2 && <br></br>}
          {showMessage2 && (
            <div className={"DescriptionMessage"}>
              {" "}
              Shared MFA/SL needs to be <span className="Bold">regenerated and re-shared</span> with
              the company (except Fee and RCM changes). Are you sure you want to proceed?
            </div>
          )}
          <br />
          <span className={clsx("dflex", "ButtonContainer")}>
            <button className={clsx("btn6", "Cancel")} onClick={onHide}>
              Cancel
            </button>
            <button
              className={clsx("btn6", "Delete")}
              onClick={(e) => {
                e.stopPropagation();
                onDelete?.();
              }}
            >
              Proceed
            </button>
          </span>
        </div>
      </div>
    </Modal>
  );
}
