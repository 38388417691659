import React from // Component
"react";
// import {Link} from 'react-router-dom';
import FilterHeaderComponent from "../../../../../components/FilterHeaderComponent/FilterHeaderComponent";

function OrdersTableHeader(props) {
  return (
    <thead>
      <tr>
        <th className='pl-2'/>
        <th>Investor Org</th>
        <th>Company Name</th>
        <th>Investment</th>
        <th>Date Requested</th>
        <th>Status</th>
        <th>Order ID</th>
        <th>Fees</th>
        <th>HC</th>
        <th></th>
      </tr>
    </thead>
  );
}

export default OrdersTableHeader;
