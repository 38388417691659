import React, { useEffect, useState } from 'react';
import "../../../../../../../../../css/Drawdowns.css";
import styled from "styled-components";
import Slider from "@material-ui/core/Slider";
import DrawdownBox from './DrawdownBox';
import { priceFormat, showNotification } from '../../../../../../../../../util/utility';
import _ from 'lodash';
import moment from 'moment';
import DrawdownLetterDone from './DrawdownLetterDone';
import callApi from '../../../../../../../../../util/apiCaller';
import ReactTooltip from 'react-tooltip';
import { SanctionNotApprovedModal } from './SanctionNotApprovedModal';

const GenerateBtn = styled("div")`
    background-color: ${({disabled}) => disabled ? "#C4C4C4" : "#4C8DFF"};
    border-radius: 6px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    padding: 7px 0;
    margin-bottom: 10px;
    cursor: ${({disabled}) => disabled ? "not-allowed" : "pointer"};
`;
const DrawdownSection = styled("div")`
    background-color: #F9F9F9;
    border-radius: 4px;
    margin-bottom: 20px;
    &:last-child{
        margin-bottom: 10px;
    }
`;

export default function TradeContracts({selectedContracts,selectedContractValue,
    selectedPayOut,investorDeals,setSelectedInvestorDeal,selectedInvestorDeal,
    generateDrawdown,drawdownStatus,investee_organization_id,available_limit,
    sliderValue,setSliderValue,onSliderChangeCommited,repaymentSchedule,setdrawdownStatus,
    selectedInvestorName,selectedCompanyName,clearStatus,sanctionNotApprovedModal,setSanctionNotApprovedModal}) {

    const [childInvestorDeals,setChildInvestorDeals] = useState([]);

    useEffect(() => {
        getChildInvestorDeals();
    },[])

    const getChildInvestorDeals = () => {
        callApi("admin/deals/get-drawdown","post",
        {investee_organization_id}).then(res => {
          if(res && res.status === "Success"){
            setChildInvestorDeals(res.data.investor_deals);
          } else {
            showNotification("Error","Error getting drawdowns");
          }
        })
    }
    return (
        <>
        {["NG","GI"].includes(drawdownStatus)   ?
        <div>
            <div className='Trade-contracts'>
                <div className='fs16 fw600 color3 mb-4'>Trade Contracts</div>
                <div className='dflex-ja mb-3'>
                    <div className='fs12 clr13 fw300 dflex-ac'>
                        <span className='mr-1'>Selected Contracts</span>
                        <span className="tooltips">
                            <img className="ques-icon" src="/assets/ques-icon.svg" alt="" />
                            <span class="tooltiptexts  after1" style={{ inset: "auto", marginLeft: "-5px", marginTop: "65px" }}>
                                Number of contracts selected to be listed on Investor's Market for trade.
                            </span>
                        </span>
                </div>
                    <div className='fs12 fw500'>{selectedContracts}</div>
                </div>
                <div className='dflex-ja mb-3'>
                    <div className='fs12 clr13 fw300 dflex-ac'>
                        <span className='mr-1'>Total Contract Value</span>
                        <span className="tooltips">
                            <img className="ques-icon" src="/assets/ques-icon.svg" alt="" />
                            <span class="tooltiptexts  after1" style={{ inset: "auto", marginLeft: "0px", marginTop: "65px" }}>
                                Sum of all contract payments for selected contract over trade tenure.
                            </span>
                        </span>
                </div>
                    <div className='fs12 fw500'>₹ {priceFormat(selectedContractValue)}</div>
                </div>
                <div className='dflex-ja mb-3'>
                    <div className='fs12 clr13 fw300 dflex-ac'>
                        <span className='mr-1'>Payout</span>
                        <span className="tooltips">
                            <img className="ques-icon" src="/assets/ques-icon.svg" alt="" />
                            <span class="tooltiptexts  after1" style={{ inset: "auto", marginLeft: "-73px", marginTop: "75px" }}>
                                Indicated the total upfront capital you might be able to raise once investors purchase all contracts you are lisiting in Investor's Market.
                            </span>
                        </span>
                </div>
                    <div className='fs12 fw500'>₹ {priceFormat(selectedPayOut)}</div>
                </div>
                <div className='VSclr bgclr1 br4 dflex-jca py-1 mb-3' data-tip='' data-for={'VS'}>
                    <img style={{ width: "12px", height: "13px" }} className="mr-1" src="/assets/daterange.png" alt="" />
                    <span className='fs12'>View Payment Schedule</span>
                </div>
                <ReactTooltip
                    id={'VS'}
                    type="light"
                    place="bottom"
                    className="payout-tooltip"
                    border={true}
                    borderColor="black"
                    delayShow={50}
                    multiline={true}
                >
                        <span class="p-0">
                            <div>
                                <div 
                                className={!_.isEmpty(repaymentSchedule) ? 'p-2 text-center' : 'd-none'}>
                                    Payment Schedule
                                </div>
                                {
                                    !_.isEmpty(repaymentSchedule)
                                    ? repaymentSchedule.map((item) => (
                                        <div className="dflex-ja border-top p-2 fw500">
                                          <div className="fs10">{item?.date ? moment(item.date.split("T")[0], "YYYY-MM-DD").format("DD MMM YYYY") : "-"} </div>
                                          <div className="fs10">₹ {item.totalAmount ? priceFormat(item.totalAmount) : ""}</div>
                                        </div>
                                      ))
                                    :
                                    <div className='fs12 p-2'>Select Contracts to View Schedule</div>
                                }
                            </div>
                        </span>
                </ReactTooltip>
                <select
                    className='drawdown-select mb-3'
                    onChange={(e) => setSelectedInvestorDeal(e.target.value)}
                    value = {selectedInvestorDeal}
                >
                    <option value="" disabled>Select Investor</option>
                    {
                        !_.isEmpty(investorDeals) && investorDeals.map(investorDeal => (
                            <option value={investorDeal?._id}>
                                {`${investorDeal?.deal_id?.deal_name}-${investorDeal?.investor_name}`}
                            </option>
                        ))
                    }
                </select>
                <div className='Trade-contract-slider'>
                    <Slider
                     min={0}
                     max={available_limit}
                     value={sliderValue}
                     onChange={(e,v) => setSliderValue(v)}
                     onChangeCommitted={onSliderChangeCommited}
                    />
                </div>
                <div className='text-right mb-3'>
                    <span className='mr-1 fs12 fw300 clr13'><span>₹ {priceFormat(available_limit-selectedPayOut)}</span> Remaining </span>
                    <span className="tooltips">
                        <img className="ques-icon" src="/assets/ques-icon.svg" alt="" />
                        <span class="tooltiptexts after2 fw300" style={{ inset: "auto", marginLeft: "-100px", marginTop: "25px", width:'110px' }}>
                            Remaining amount of Payout you can receive by trading your contracts, post trading the selected contracts.
                        </span>
                    </span>
                </div>
                <GenerateBtn
                    onClick={selectedContracts === 0 ? null: generateDrawdown}
                    disabled = {drawdownStatus === "GI" || selectedContracts === 0}
                >
                    <span>{drawdownStatus === "NG" ? "Generate Drawdown Letter" : "Generating...."}</span>
                    <img alt="" src="/assets/arrow-white.svg"
                        style={{ width: "13px"}} className="ml-2"/>
                </GenerateBtn>
                <SanctionNotApprovedModal 
                    sanctionNotApprovedModal={sanctionNotApprovedModal}
                    setSanctionNotApprovedModal={setSanctionNotApprovedModal}
                    generateDrawdown={generateDrawdown}
                />
            </div>
        </div>
            :
            (drawdownStatus ==="G" && <DrawdownLetterDone
                setdrawdownStatus = {setdrawdownStatus}
                selectedInvestorName = {selectedInvestorName}
                selectedCompanyName = {selectedCompanyName}
                selectedPayOut = {selectedPayOut}
                clearStatus = {clearStatus}
                getChildInvestorDeals = {getChildInvestorDeals}
            />)
        }
        <DrawdownBox 
            investee_organization_id = {investee_organization_id}
            investorDeals = {childInvestorDeals}
            getChildInvestorDeals = {getChildInvestorDeals}
        />
    </>
    )
}