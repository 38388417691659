import isEmpty from "lodash/isEmpty";
import { Modal } from "react-bootstrap";

import styles from "./CancelDocumentModal.module.scss";

const CancelDocumentModal = ({
    showModal,
    cancellationReason,
    setCancellationReason,
    submitCancellation,
    close,
}) => {
    return (
        <Modal show={showModal} onHide={close} className={styles.container}>
            <Modal.Header className={styles.header}>
                <Modal.Title className={styles.title}>Cancellation Reason</Modal.Title>
                <button type="button" className="close" onClick={close}>
                    <span>
                        <img src="/assets/clear.svg" alt="Close"></img>
                    </span>
                </button>
            </Modal.Header>
            <Modal.Body className={styles.body}>
                <textarea
                    autoFocus={true}
                    name="cancelReason"
                    id="cancelReason"
                    rows={6}
                    value={cancellationReason}
                    onChange={(e) => setCancellationReason(e.target.value)}
                    className={styles.cancelReason}
                    placeholder="Reason for the cancellation"
                />
                <div className={styles.cta}>
                    <button className={styles.cancelBtn} onClick={submitCancellation} disabled={isEmpty(cancellationReason)}>
                        Cancel Signed
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CancelDocumentModal;
