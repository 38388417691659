import React from // Component
"react";
import FilterHeaderComponent from "../../../../../components/FilterHeaderComponent/FilterHeaderComponent";

function InvoicesTableHeader(props) {
  return (
    <thead className='team-head invoices-table'>
      <tr>
        <th scope='col' style={{ paddingLeft: "5px", fontWeight: "600"}}>
          Name
        </th>
        <th scope='col' style={{ paddingLeft: "5px", fontWeight: "600"}}>
          Type
        </th>
        <th scope='col' style={{ paddingLeft: "5px", fontWeight: "600"}}>
          Month
        </th>
        <th scope='col' style={{ paddingLeft: "5px", fontWeight: "600"}}>
        Fee/Amount
        </th>
        <th scope='col' style={{ paddingLeft: "5px", fontWeight: "600"}}>
          GST (18%)
        </th>
        <th scope='col' style={{ paddingLeft: "5px", fontWeight: "600"}}>
          Total <button
      onClick={() => props.requestSort("total")}
      style={{ border: "none", marginLeft: "7px" }}
    >
      &#8645;
    </button>
        </th>
        <th scope='col' style={{ paddingLeft: "5px", fontWeight: "600"}}>
          Invoice Date
          <img
            style={{ width: "13px", marginLeft: "5px" }}
            src='/assets/down-arrow.svg'
            alt=''></img>
        </th>
        <th scope='col' style={{ paddingLeft: "5px", fontWeight: "600"}}>
          # Invoice
        </th>
        <FilterHeaderComponent
                style={{fontWeight: "600"}}
                isSortAllowed={true}
                sorting_order={'desc'}
                isFilterAllowed={true}
                headerText={'Status'}
                labelText={'Filter Invoice Status'}
                filter_type={'search'}
                handleSearchFilter = {props.handleSearchFilter}
                keyName = {props.searchObjInvoiceStatus.keyName}
                value = {props.searchObjInvoiceStatus.value}
            />
    
        <th></th>
      </tr>
    </thead>
  );
}

export default InvoicesTableHeader;
