import { isEmpty } from "lodash";
import { API_STATUS } from "../../constants/enums";
import callApi from "../../util/apiCaller";
import { showNotification } from "../../util/utility";

export const FETCHING_TEAM_MEMBERS = "FETCHING_TEAM_MEMBERS";
export const FETCH_TEAM_MEMBERS = "FETCH_TEAM_MEMBERS";

export const ADDING_NEW_MEMBER = "ADDING_NEW_MEMBER";
export const ADD_NEW_MEMBER = "ADD_NEW_MEMBER";

export const FETCHING_TRANSACTIONS_BY_CONTRACT_ID =
  "FETCHING_TRANSACTIONS_BY_CONTRACT_ID";
export const FETCH_TRANSACTIONS_BY_CONTRACT_ID =
  "FETCH_TRANSACTIONS_BY_CONTRACT_ID";

export const FETCHING_INVESTEES = "FETCHING_INVESTEES";
export const FETCH_INVESTEES = "FETCH_INVESTEES";

export const FETCHING_INVESTEES_DETAILS = "FETCHING_INVESTEES_DETAILS";
export const FETCH_INVESTEES_DETAILS = "FETCH_INVESTEES_DETAILS";
export const FETCH_TOGGLE_INFO="FETCH_TOGGLE_INFO";
export const FETCHING_TOGGLE_INFO="FETCHING_TOGGLE_INFO";
export const FETCH_DEAL_REPORT="FETCH_DEAL_REPORT";

export const UPDATING_INVESTEE_DETAILS = "UPDATING_INVESTEE_DETAILS";
export const UPDATED_INVESTEE_DETAILS = "UPDATED_INVESTEE_DETAILS";

export const FETCHING_KYC_DOCS = "FETCHING_KYC_DOCS";
export const FETCH_KYC_DOCS = "FETCH_KYC_DOCS";
export const FETCHING_CONNECTED_PG_LIST = "FETCHING_CONNECTED_PG_LIST";
export const UPDATE_INVESTEE_ORGANIZATION = "UPDATE_INVESTEE_ORGANIZATION";
export const UPDATE_TOGGLE_INFO="UPDATE_TOGGLE_INFO"

export function fetchKycDocs(
  investee_organization_id,
  investorDealId,
  investorOrgId,
  hideKycDocs,
  hideInvestorSpecificDocs
) {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_KYC_DOCS,
      isFetchingKycDocs: true,
    });
    const res = await callApi("deals/view-download-kyc-documents", "post", {
      investee_organization_id,
      ...(investorDealId && { investor_deal_id: investorDealId }),
      ...(investorOrgId && { investor_organization_id: investorOrgId }),
      ...(hideKycDocs && { hideKycDocs }),
      ...(hideInvestorSpecificDocs && { hideInvestorSpecificDocs }),
    });
    if (res.status === API_STATUS.SUCCESS) {
      dispatch({
        type: FETCH_KYC_DOCS,
        kycDocs: res.data,
        isFetchingKycDocs: false,
      });
    } else {
      dispatch({
        type: FETCHING_KYC_DOCS,
        isFetchingKycDocs: false,
      });
    }
  };
}

export function fetchCompaniesDetails(filters, isReseting) {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_INVESTEES,
      is_fetching_investees: true,
    });
    const res = await callApi(`admin/investee/list/v2`, "post", filters);
    if (res.status === API_STATUS.SUCCESS) {
      dispatch({
        type: FETCH_INVESTEES,
        investees: res.data.investees,
        aggregrateDetails: res.data.aggregrateDetails,
        underwriters: res.data.underWriters,
        companyStatuses: res.data.companyStatuses,
        underWritingStatuses: res.data.underWritingStatuses,
        totalCount: res.data.count,
        is_fetching_investees: false,
        isReseting: isReseting,
      });
    } else {
      dispatch({
        type: FETCHING_INVESTEES,
        is_fetching_investees: false,
      });
      showNotification("error", "Some error occured");
    }
  };
}

export function fetchTeamMembers(investee_organization_id) {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_TEAM_MEMBERS,
      is_fetching_members: true,
    });
    const res = await callApi(
      `investeeOrganization/${investee_organization_id}/profile/get-team`,
      "get"
    );
    if (res.status === API_STATUS.SUCCESS) {
      dispatch({
        type: FETCH_TEAM_MEMBERS,
        members: res.data.investees,
        is_fetching_members: false,
      });
    } else {
      dispatch({
        type: FETCHING_TEAM_MEMBERS,
        is_fetching_members: false,
      });
    }
  };
}

export function addNewMember(investee_organization_id) {
  return async (dispatch) => {
    dispatch({
      type: ADDING_NEW_MEMBER,
    });
    const res = await callApi(
      `investeeOrganization/${investee_organization_id}/profile/get-team`,
      "get"
    );
    if (res.status === API_STATUS.SUCCESS) {
      dispatch({
        type: ADD_NEW_MEMBER,
        members: res.data.investees,
        is_fetching_members: false,
      });
    } else {
      dispatch({
        type: ADDING_NEW_MEMBER,
      });
    }
  };
}

export function fetchTransactionByContractId(contract_id) {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_TRANSACTIONS_BY_CONTRACT_ID,
      is_fetching_transactions_by_contract_id: true,
    });
    const res = await callApi(
      `admin/contracts/${contract_id}/transaction-list`,
      "get"
    );
    if (res.status === API_STATUS.SUCCESS) {
      dispatch({
        type: FETCH_TRANSACTIONS_BY_CONTRACT_ID,
        transactions_for_contract: res.data.transactions.contract_transaction,
        is_fetching_transactions_by_contract_id: false,
      });
    } else {
      dispatch({
        type: FETCHING_TRANSACTIONS_BY_CONTRACT_ID,
        is_fetching_transactions_by_contract_id: false,
      });
    }
  };
}

export function fetchInvesteeDetails(investeeOrgid) {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_INVESTEES_DETAILS,
      is_fetching_investees_details: true,
    });
    const res = await callApi(
      `admin/investeeorganization/${investeeOrgid}/info`,
      "get"
    );
    if (res.status === API_STATUS.SUCCESS) {
      dispatch({
        type: FETCH_INVESTEES_DETAILS,
        investee_contract_stats: res.data.Investee.investee_contract_stats,
        investee_profile_metrics: res.data.Investee.investee_profile_metrics,
        investee_organization: res.data.Investee.investee_organization,
        investeeOnboardingData: res.data.Investee.investeeOnboardingData,
        capital_requested: res.data.Investee.capital_requested,
        is_fetching_investees_details: false,
      });
    } else {
      dispatch({
        type: FETCHING_INVESTEES_DETAILS,
        is_fetching_investees_details: false,
      });
    }
  };
}
export  function updateToggleInfo(id,input){
  if(id){
    return async (dispatch) => {
    const res = await callApi(
      "admin/update-toggle-info",
      "post",
      { id:id,
        isAddOn:input?.isAddOn,
        debtSyndicationToggle:input?.debtSyndicationToggle,
        rrfToggle: input?.rrfToggle
      }
      );
    if(res.status===API_STATUS.SUCCESS){
      dispatch({
        type:UPDATE_TOGGLE_INFO,
        rrf_toggle: res?.data?.rrfToggle,
        debt_syndication_toggle: res?.data?.debtSyndicationToggle,
      })
    }
    else {
      showNotification("Error", res?.data);
    }
    };
  }
  else showNotification("Error", "Invalid Organization Id");

}
export function fetchToggleInfo(id,orgId,isAddOn){
  if(id){
    return async (dispatch) => {
    const res = await callApi(
      "admin/get-toggle-info",
      "post",
      {id:id,
       orgId:orgId,
       isAddOn:isAddOn
      }
      );
    if(res.status===API_STATUS.SUCCESS){
      dispatch({
        type:FETCH_TOGGLE_INFO,
        gst_annual_rev: res?.data?.gstAnnualRev,
        rrf_toggle: res?.data?.rrfToggle,
        debt_syndication_toggle: res?.data?.debtSyndicationToggle,
        is_fetching_toggle_info: false
      })
    }
    else {
      dispatch({
        type: FETCHING_TOGGLE_INFO,
        is_fetching_toggle_info: false,
      });
      showNotification("Error", res?.data);
    }
    };
  }
  else showNotification("Error", "Invalid Organization Id");  
}



export function fetchDealReport (investeeOnboardedId, orgId){
 return  async(dispatch)=>{
  const res=  await callApi(
    `deal-report/data-get?identifierId=${investeeOnboardedId}&orgId=${orgId}&requestId=${Date.now()}`,
    "get",
    null,
    null,
    false,
    false,
    false,
    false,
    false,
    false,
    true
  )
  if (res?.responseData?.responseCode === 20) {
    delete res.responseData;

    dispatch({
      type: FETCH_DEAL_REPORT,
      deal_report:res
    });

  } 
 }};


export function fetchConnectedPGList(investeeOrgId) {
  if (investeeOrgId)
    return async (dispatch) => {
      const res = await callApi(
        "connection/all",
        "post",
        {
          companyId: investeeOrgId,
          requestId: `${investeeOrgId}${+new Date()}`,
        },
        {},
        false,
        false,
        true
      );
      if (res?.responseData.responseCode === 20) {
        dispatch({
          type: FETCHING_CONNECTED_PG_LIST,
          connectedPGList: res.connectedPGList,
          isEmptyConnectedPgList: isEmpty(res.connectedPGList),
        });
      } else {
        const message =
          res?.responseData?.responseMessage ?? "Some error occurred";
        showNotification("Error", message);
      }
    };
  else showNotification("Error", "Invalid Organization Id");
}

export function updateInvesteeDetails(
  investeeOrgId,
  investeeorganization,
  isIRROrMaxTenurechanged
) {
  const triggerIrrToPrice = async (dispatch) => {
    const res = await callApi(`admin/getPriceFromIrr`, "post", {
      investee_org_id: investeeOrgId,
    });
    if (res.status === API_STATUS.SUCCESS) {
      dispatch({
        type: UPDATED_INVESTEE_DETAILS,
        investee_organization: res.data.investeeorganization,
        isUpdatingInvesteeDetails: false,
      });
    } else {
      dispatch({
        type: UPDATING_INVESTEE_DETAILS,
        isUpdatingInvesteeDetails: false,
      });
      showNotification("error", "Some error occured");
    }
  };
  return async (dispatch) => {
    dispatch({
      type: UPDATING_INVESTEE_DETAILS,
      isUpdatingInvesteeDetails: true,
    });
    const res = await callApi(
      `investeeorganizations/${investeeOrgId}/data-update`,
      "post",
      {
        investeeorganization,
      }
    );
    const check = investeeorganization.irr &&
      investeeorganization.custom_tenure_limit_max &&
      isIRROrMaxTenurechanged;
    if (res.status === API_STATUS.SUCCESS) {
      dispatch({
        type: UPDATED_INVESTEE_DETAILS,
        investee_organization: res.data.investeeorganization,
        isUpdatingInvesteeDetails: check,
      });
      showNotification("success", "Successfully updated data");
      check && triggerIrrToPrice(dispatch);
    } else {
      dispatch({
        type: UPDATING_INVESTEE_DETAILS,
        isUpdatingInvesteeDetails: false,
      });
      showNotification("error", "Some error occured");
    }
  };
}

// todo get filtered list api for tradebook page
// todo get filtered list api for trade
// todo add investee support request
// todo add investee stats
// todo add investee payments and payouts
