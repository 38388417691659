import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactAutocomplete from 'react-autocomplete'
import './MailAttachment.scss'
import RecurTable from '../../../../../../../../components/RecurTable/RecurTable'
import { isEmpty } from 'lodash'
import { getTaggedDocs, getTaggedDropdown, getUpdateDocTags } from '../../../../../../../../common/ApiHelpers.ts'
import { useSelector } from 'react-redux'
import { showNotification } from '../../../../../../../../util/utility'
import { SHOW_NOTIFICATION } from '../../../../../../../../constants/enums'
import ViewDocModal from './ViewDocModal.tsx'


interface RowData {
  messageId?: string,
  documentId?: string,
  date?: string,
  from?: string,
  context?: string,
  subject?: string,
  body?: string,
  attachment?: {
    attachmentName?: string,
    attachmentLink?: string
    mimeType?: string,
    signedLink?: string
  }
  docTag?: string,
  uploadStatus?: string
}

function MailAttachment() {
  const orgId = useSelector((state: any) => state.app.arrData.investee_organization._id)
  const [rowData, setRowData] = useState<RowData[]>([])
  const [fromDropdown, setFromDropdown] = useState<any>([])
  const [from, setFrom] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)
  const [context, setContext] = useState<string>()
  const [contextDropdown, setContextDropdown] = useState<any>([])
  const [docTagDropdown, setDocTagDropdown] = useState<any>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [doc,setDoc] = useState<any>({})
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const observer = useRef<IntersectionObserver | null>(null);
  const getRowData = async () => {
    if (loading || !hasMore) return;
    setLoading(true)
    try {
      const res = await getTaggedDocs({ orgId, pageNumber});
      if (res.responseData.responseCode === 20) {
        setRowData((prevRowData) => [...prevRowData, ...res.docList]);
        setHasMore(res.docList.length > 0);
      } else {
        showNotification(SHOW_NOTIFICATION.ERROR, res.responseData.responseMessage ?? 'Something went wrong');
      }
    } catch (error) {
      showNotification(SHOW_NOTIFICATION.ERROR, 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };
  const lastDocsRef = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    if (pageNumber > 1) {
      getRowData();
    }
    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [pageNumber]);

  const intitalMenuStyle = {
    borderRadius: "3px",
    boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
    background: "rgba(255, 255, 255, 0.9)",
    padding: "2px 5px",
    fontSize: "13px",
    border: 'none',
    fontWeight: "normal",
    position: 'fixed' as 'fixed',
    overflow: "auto",
    maxHeight: "50%",
    zIndex: 2,
    fontFamily: "Gilroy-SemiBold",
    cursor: "pointer",
    marginTop: "5px",
  };
  const getDropdownData = async () => {
    const res = await getTaggedDropdown({ orgId })
    if (res.responseData.responseCode === 20) {
      setFromDropdown(res.fromDropdown)
      setContextDropdown(res.contextDropdown)
      setDocTagDropdown(res.docTagDropdown)
    }
    else{
      showNotification(SHOW_NOTIFICATION.ERROR, res.responseData.responseMessage??'Something went wrong')
    }
  }
  const updateDocTag= async (value:string,row:RowData)=>{
    const payload = {
      xOrganizationId: orgId,
      taggedDocument:{
        documentId: row.documentId,
        docTag: value,
        messageId: row.messageId
      }
    }
    setRowData(rowData.map((item: any) => item.documentId === row.documentId ? { ...item, docTag: value } : item))
    const res = await getUpdateDocTags(payload)
    if (res.responseData.responseCode === 20) {
      showNotification(SHOW_NOTIFICATION.SUCCESS, res.responseData.responseMessage)
    }
    else{
      showNotification(SHOW_NOTIFICATION.ERROR, res.responseData.responseMessage??'Something went wrong')
    }
  }
  const applyFilter = async (keyname:string,value:string) => {
    const data = {
      orgId: orgId,
      fromId: keyname==='from'?value:null,
      context: keyname==='context'?value:null
    }
    const res = await getTaggedDocs(data)
    if (res.responseData.responseCode === 20) {
      setRowData(res.docList)
    }
  }
  useEffect(() => {
    getRowData();
    getDropdownData();
  }, [])
  const getHeaders = () => {
    return [
      {
        jsx: <span>
          Date
        </span>
      },
      {
        jsx:
          <span className='header_input'>
            <ReactAutocomplete
              items={fromDropdown}
              inputProps={{
                type: "text",
                name: "from",
                placeholder: "From",
              }}
              shouldItemRender={(item, value) => item?.toLowerCase()?.indexOf(value.toLowerCase()) > -1}
              getItemValue={item => item}
              renderItem={(item, highlighted) =>
                <div
                  key={item}
                  style={{
                    background: highlighted ? "#2F8FFF" : "white",
                    padding: "5px",
                    opacity: "1"
                  }}
                >
                  {item}
                </div>
              }
              value={from}
              onChange={e => setFrom(e.target.value)}
              onSelect={value => {
                setFrom(value)
                applyFilter('from',value)
              }}
              menuStyle={{ ...intitalMenuStyle }}
            />
          </span>
      },
      {
        jsx:
          <span className='header_input'>
            <ReactAutocomplete
              items={contextDropdown}
              inputProps={{
                type: "text",
                name: "context",
                placeholder: "Context",
              }}
              shouldItemRender={(item, value) => item?.toLowerCase()?.indexOf(value.toLowerCase()) > -1}
              getItemValue={item => item}
              renderItem={(item, highlighted) =>
                <div
                  key={item}
                  style={{
                    background: highlighted ? "#2F8FFF" : "white",
                    padding: "5px",
                    opacity: "1"
                  }}
                >
                  {item}
                </div>
              }
              value={context}
              onChange={e => setContext(e.target.value)}
              onSelect={value => {setContext(value)
                applyFilter('context',value)
              }}
              menuStyle={{ ...intitalMenuStyle }}
            />
          </span>
      },
      {
        jsx: <span className="header-text">
          Subject Line
        </span>
      },
      {
        jsx: <span style={{paddingLeft:'1rem'}} className="header-text">
          Body Message
        </span>
      },
      {
        jsx: <span style={{paddingLeft:'1rem'}} className="header-text">
          Attachment File
        </span>
      },
      {
        jsx: <span className="header-text">
          Tag Document
        </span>
      },
      {
        jsx: <span className="header-text">
          Upload Status
        </span>
      },
      {
        jsx: <span className="header-text">
          Confirm Tag
        </span>
      },
    ]
  }
  const downloadAttachment=(attachmentLink:string|undefined,attachmentName:string|undefined,mimeType:string|undefined,signedLink:string|undefined)=>{
    if(attachmentLink){
      setShowModal(true)
      setDoc({
        uri: attachmentLink,
        fileName: attachmentName,
        fileType: mimeType,
        signedUri: signedLink
      })
    }
  }
  const renderRows = (data: any) => {
    if (isEmpty(data)) {
      return (
        <tbody>
          <tr>
            <td className='noDataText' colSpan={6}>No data available.</td>
          </tr>
        </tbody>
      );
    }
    return (
      <>
        {
          rowData.map((row: RowData, index: number) => {
            return (
              <tbody>
                <tr className='tableRows' key={index}>
                  <td style={{ width: '10%' }}>{row.date ?? '-'}</td>
                  <td style={{ width: '10%' }}>{row.from ?? '-'}</td>
                  <td style={{ width: '10%' }}>{row.context ?? '-'}</td>
                  <td className={row.subject ? 'trunc' : ''} style={{ width: '30%' }}><div>{row.subject ?? '-'}</div></td>
                  <td className={row.body ? 'trunc' : ''} style={{ width: '30%', paddingLeft: '1rem',paddingRight:'1rem' }}>
                    <div dangerouslySetInnerHTML={{ __html: row.body ?? '-' }} /></td>
                  <td className={row.attachment?.attachmentLink ? 'attachmentLink' : ''} style={{ width: '15%',paddingLeft:'1rem' }} onClick={() => downloadAttachment(row.attachment?.attachmentLink, row.attachment?.attachmentName,row?.attachment?.mimeType, row.attachment?.signedLink)}>{row.attachment?.attachmentName ?? '-'}</td>
                  <td style={{width:'25%'}}>
                    <span className='header_input'>
                      <ReactAutocomplete
                        items={docTagDropdown}
                        inputProps={{
                          type: "text",
                          name: "docTag",
                          placeholder: "Doc Tag",
                          disabled: row.uploadStatus !== 'Confirmation Pending'
                        }}
                        shouldItemRender={(item, value) => item?.toLowerCase()?.indexOf(value.toLowerCase()) > -1}
                        getItemValue={item => item}
                        renderItem={(item, highlighted) =>
                          <div
                            key={item}
                            style={{
                              background: highlighted ? "#2F8FFF" : "white",
                              padding: "5px",
                              opacity: "1"
                            }}
                          >
                            {item}
                          </div>
                        }
                        value={row.docTag}
                        onChange={e => {
                          setRowData(rowData.map((item: any) => item.documentId === row.documentId ? { ...item, docTag: e.target.value } : item))
                        }}
                        onSelect={value => setRowData(rowData.map((item: any) => item.documentId === row.documentId ? { ...item, docTag: value } : item))}
                        menuStyle={{ ...intitalMenuStyle }}
                      />
                    </span>
                  </td>
                  <td style={{ width: '15%' }}>{row.uploadStatus ?? '-'}</td>
                  <td style={{ width: '15%' }}>
                  <button
                      className='confirmButton'
                      disabled={row.uploadStatus !== 'Confirmation Pending'}
                      onClick={() => {updateDocTag(row.docTag || '',row).then(() => { getRowData() })}}
                      title={row.uploadStatus !== 'Confirmation Pending' ? 'Document tag has already been confirmed' : ''}
                  >
                      Confirm Tag
                  </button>
                  </td>
                </tr>
              </tbody>
            )
          })
        }
      </>
    );
  }
  return (
    <>
    <div className='tableContainer'>
      <RecurTable
        headers={getHeaders()}
        renderRows={renderRows(rowData)}
        changeColorOnHover={false}
        additionalRef={lastDocsRef} // Passing the ref function here
      />
    </div>
        {showModal && <ViewDocModal doc={doc} setShowModal={setShowModal} showModal={showModal} />}
      </>
  )
}

export default MailAttachment