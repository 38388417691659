import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "../../../../../../css/CompaniesDetail.css";
import { fetchEscrowAccountInfo } from "../../../../../App/AppActions";
import { connect } from "react-redux";
import {
  getCheckIfUserHasRelevantPermissions,
  getEmail,
  getEscrowAccountInfo,
  getisFetchingEscroeAccountInfo,
} from "../../../../../App/AppReducer";
import {
  downloadFileUsingFetch,
  mixPanelTrack,
  showNotification,
} from "../../../../../../util/utility";
import Details from "./components/Details/Details";
import Contracts from "./components/Contracts";
// import Finances from "./components/Finances";
import Banks from "./components/Banks";
import DataVault from "./components/DataVault/DataVault";
import Overview from "./components/Overview/Overview";
import { getInvesteeOnboardingData, getInvesteesOrgDisplayName } from "../../../../AdminReducers";
import callApi from "../../../../../../util/apiCaller";
import Users from "./components/Users";
import Drawdowns from "./components/Drawdown/Drawdowns";
import Checkout from "./components/Checkout";
import Collections from "./components/Collections/Collections";
import DocumentVault from "./components/DocumentVault/DocumentVault";
import Insights from "./components/Insights/Insights";
import ComplianceCheck from "./components/Compliance/ComplianceCheck";
import MailAttachment from "./components/MailAttachment/MailAttachment.tsx";
import storage from "../../../../../../util/storageService";


class CompaniesDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enachDebitAmount: "",
      enachDebitDate: moment().format("YYYY-MM-DD"),
      isDownloadingFile: false,
      isEditingCompany: false,
      showAnimation: false,
      readZeus: "",
    };
  }

  setIsEditingCompany = (val, isOverview) => {
    // if (isOverview && val) {
    //   showNotification("warning", "Be Careful while changing attributes that need trigger, it may cause unexpected behaviour without triggering", false);
    // }
    this.setState({ isEditingCompany: val });
  };

  componentDidMount() {
    const segment = ["compliance-checks"];
    const id = this.props.match?.params.id;
    this.props.dispatch(fetchEscrowAccountInfo(id, "investee"));
    if (segment.includes(this.props.match?.params?.type)) {
      this.props.toggleSegment(this.props.match?.params.type, () => {
        if (this.state.isEditingCompany) {
          this.setState({ isEditingCompany: false });
        }
      })
    }
  }

  downloadFile = () => {
    this.setState({ isDownloadingFile: true });
    downloadFileUsingFetch(
      `admin/zip-stream-investee-docs/${this.props.investeeOrganization._id}`,
      "get",
      {
        responseType: "blob",
      },
      "onboardingFiles.zip",
      () => {
        this.setState({ isDownloadingFile: false });
      }
    );
  };

  handleChangeEnachForm = (key, value) => {
    const obj = this.state;
    obj[key] = value;
    this.setState(obj);
  };

  handleClickStatusBar = (label) => {
    this.setState({ currentTab: label });
  };

  createZeusUser = () => {
    const id = this.props.match?.params.id;
    callApi(`admin/create-zeus-user`, "post", {
      orgId: id,
      user_type: "company",
    }).then((res) => {
      if (res.status === "Success") {
        showNotification("Success", "User Created Successfully");
        const data = res?.data?.result;
        if (data?.orgId && data?.userId && data?.cerberusToken) {
          const url = `${process.env.REACT_APP_CUSTOMERAPP_URI}/company?orgId=${data.orgId}&userId=${data.userId}&cerberusToken=${data.cerberusToken}&type=INVESTEE`;
          window.open(url, '_blank');
        }
      } else {
        showNotification("Error", res.data.message);
      }
    });
  };

  summonAica = () => {
    const investeeOrgId = this.props.investeeOrganizationId;
    // const aicaURL = 'http://localhost:3335';
    const aicaURL = process.env.REACT_APP_AICA_URL;
    const userId = storage.get("user_id");
    const token = storage.get("cerberus_token");
    if(!userId || !token) {
        showNotification("Error", "Can't Summon Aica User not found");
        return;
    }
    window.open(`${aicaURL}/investor/summon-aica?orgId=${investeeOrgId}&userId=${userId}&token=${token}&source=RECUR`,'_blank');
  }

  syncCrmData = (investee_organization_id) => {
    if (investee_organization_id)
      callApi(
        `admin/investee-organization-id/${investee_organization_id}/crm-sync`,
        "post"
      ).then((res) => {
        if (res.status === "Success") {
          showNotification("Success", "Data Synced Successfully");
        } else {
          showNotification("Error", "Error while syncing data");
        }
      });
    else showNotification("Error", "Invalid organization Id");
  };

  calculateTaxAndDownloadExcel = (investee_organization_id) => {
    const parsedName = this.props.displayName ? this.props.displayName.split(' ').join('_') : '';
    downloadFileUsingFetch(
      `admin/investeeOrganization/${investee_organization_id}/calculate-tax-generate-excel`,
      "get",
      {
        responseType: "blob",
      },
      `tax_sheets_${parsedName}.zip`,
      () => { },
      false
    );
  };
  render() {
    let companiesTab = [];

    if (this.props.isUserHasEditAccess || this.props.isUserHasUWViewAccess) {
      companiesTab = [
        {
          label: "Overview",
          segmentName: "overview",
        },
        {
          label: "Details",
          segmentName: "details",
        },
        {
          label: "Drawdowns",
          segmentName: "drawdowns",
        },
        {
          label: "Users",
          segmentName: "users",
        },
        {
          label: "Contracts",
          segmentName: "contracts",
        },
        // {
        //   label: "Finances",
        //   segmentName: "finances",
        // },
        {
          label: "Bank Accounts",
          segmentName: "banks",
        },
        {
          label: "Collections",
          segmentName: "collections",
        },
        {
          label: "Data Vault",
          segmentName: "data-vault",
        },
        {
          label: "Checkout",
          segmentName: "checkout",
        },
        {
          label: "Insights",
          segmentName: "insights",
        },
        {
          label: "Doc Vault",
          segmentName: "document-vault",
        },
        {
          label: "Compliance",
          segmentName: "compliance-checks"
        },
        {
          label: "Mail Attachment",
          segmentName: "mail-attachment",
        }
      ];
    }
    else {
      companiesTab = [
        {
          label: "Overview",
          segmentName: "overview",
        },
        {
          label: "Details",
          segmentName: "details",
        },
        {
          label: "Bank Accounts",
          segmentName: "banks",
        },
        {
          label: "Data Vault",
          segmentName: "data-vault",
        },
        {
          label: "Doc Vault",
          segmentName: "document-vault",
        },
        {
          label: "Users",
          segmentName: "users",
        },
        {
          label: "Compliance",
          segmentName: "compliance-checks"
        },
        {
          label: "Mail Attachment",
          segmentName: "mail-attachment",
        }
      ];
    }
    let segment;
    if (this.props.selectedSegment === "overview") {
      segment = (
        <Overview
          investeeOrganization={this.props.investeeOrganization}
          isEditingCompany={this.state.isEditingCompany}
          setIsEditingCompany={this.setIsEditingCompany}
          investee_organization_id={this.props.investee_organization_id}
        />
      );
    } else if (this.props.selectedSegment === "details") {
      segment = (
        <Details
          investeeOrganization={this.props.investeeOrganization}
          onboardingData={this.props.onboardingData}
          investeeContractStats={this.props.investeeContractStats}
          isEditingCompany={this.state.isEditingCompany}
          setIsEditingCompany={this.setIsEditingCompany}
        />
      );
    } else if (
      this.props.selectedSegment === "drawdowns" &&
      (this.props.isUserHasEditAccess || this.props.isUserHasUWViewAccess)
    ) {
      segment = <Drawdowns investeeOrg={this.props.investeeOrganization} />;
    } else if (
      this.props.selectedSegment === "users"
    ) {
      segment = <Users investeeOrgId={this.props.investeeOrganization._id} />;
    } else if (
      this.props.selectedSegment === "contracts" &&
      (this.props.isUserHasEditAccess || this.props.isUserHasUWViewAccess)
    ) {
      segment = (
        <Contracts
          id={this.props.investeeOrganization._id}
          custom_tenure={this.props.investeeOrganization.custom_tenure}
          contracts={this.props.contracts}
        />
      );
    }
    //  else if (
    //   this.props.selectedSegment === "finances" &&
    //   this.props.isUserHasEditAccess
    // ) {
    //   segment = <Finances finances={this.props.finances} />;
    // } 
    else if (this.props.selectedSegment === "banks") {
      segment = (
        <Banks
          setBank={(item) => this.props.setBank(item)}
          toggleAddBank={this.props.toggleAddBank}
          toggleCreateNach={this.props.toggleCreateNach}
          nachList={this.props.nachList}
          handleNachSyncStatus={this.props.handleNachSyncStatus}
          setNachListState={this.props.setNachListState}
          fetchAllNach={this.props.fetchAllNach}
        />
      );
    } else if (this.props.selectedSegment === "collections") {
      segment = <Collections />;
    } else if (this.props.selectedSegment === "data-vault") {
      segment = (
        <DataVault
          investeeOrganization={this.props.investeeOrganization}
          investeeOnboardedId={this.props.investeeOnboardedId}
          isModeratingDocumentation={this.props.isModeratingDocumentation}
          setIsModeratingDocumentation={this.props.setIsModeratingDocumentation}
          documentationData={this.props.documentationData}
          fetchDocumentationData={this.props.fetchDocumentationData}
          monthlyData={this.props.monthlyData}
          fetchMonthlyData={this.props.fetchMonthlyData}
          contractsCount={this.props.contractsCount}
          integrationsObj={this.props.integrationsObj}
          monthlyViewData={this.props.monthlyViewData}
          downloadIntegrationFiles={this.props.downloadIntegrationFiles}
        />
      );
    } else if (this.props.selectedSegment === "insights") {
      segment = <Insights />
    } else if (this.props.selectedSegment === "checkout") {
      segment = <Checkout />;
    } else if (this.props.selectedSegment === "document-vault") {
      segment = <DocumentVault />;
    }
    else if (this.props.selectedSegment === "compliance-checks") {
      segment = <ComplianceCheck />;
    }
    else if (this.props.selectedSegment === "mail-attachment") {
      segment = <MailAttachment />;
    }
    return (
      <section className="col p-0">
        <div id="company-detail" className="company-detail">
          <div className="">
            <div className="d-flex justify-content-between align-items-center company-row1 p-4">
              <div className="d-flex align-items-center">
                <span className="fs14 mr-3 fw600" style={{ cursor: "pointer" }}>
                  {this.props.investeeOrganization.name}
                </span>
                {!this.state.isEditingCompany && (
                  <span
                    className={
                      this.props.investeeOrganization.status === "Active"
                        ? "active-status"
                        : "inactive-status"
                    }>
                    {" "}
                    {this.props.investeeOrganization.status === "Active"
                      ? "Active"
                      : this.props.investeeOrganization.status === "Locked"
                        ? "Locked"
                        : "Inactive"}
                  </span>
                )}
                {this.props.investeeOrganization.backgroundProcessRunning && (
                  <span
                    style={{ marginLeft: "5px" }}
                    className="processing-status hearbeat-animation">
                    {"Triggers Active"}
                  </span>
                )}
                {(this.props.isUserHasEditAccess || this.props.isUserHasUWViewAccess) && (
                  <button
                    className="crm-sync-btn ml-3"
                    onClick={() =>
                      this.syncCrmData(this.props.investeeOrganizationId)
                    }>
                    <img
                      src="/assets/icon-history.svg"
                      alt=""
                      style={{ width: "11px" }}
                      className="mr-2"
                    />
                    Sync CRM
                  </button>
                )}
                <button
                  className="crm-sync-btn ml-3"
                  onClick={() =>
                    this.calculateTaxAndDownloadExcel(
                      this.props.investeeOrganizationId
                    )
                  }>
                  <i className="fa fa-calculator" aria-hidden="true"></i>
                  {" Generate Tax Report"}
                </button>
              </div>
              <div className="dflex-je">
                {!this.state.isEditingCompany && (
                  <button
                    className={`sum-aica d-flex align-items-center1`}
                    onClick={() => {
                      this.summonAica();
                    }}>
                    <img
                      src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/threebars-black.svg"
                      alt="summon-zeus"
                      className="mr-2"
                    />
                    <span style={{ width: "max-content" }}>Summon AICA</span>
                  </button>
                )}
                {!this.state.isEditingCompany && (
                  <button
                    className={`${this.state.showAnimation} sum-zeus d-flex align-items-center1`}
                    onClick={() => {

                      mixPanelTrack({
                        id: this.props?.investeeOrganization._id,
                        trackUserObj: {
                          'Login Type': 'Zeus',
                          distinct_id: this.props?.investeeOrganization._id,
                          userId: this.props?.investeeOrganization.user_id,
                      },
                        trackCategoryName: 'login',
                      });

                      this.createZeusUser();
                      this.setState({ showAnimation: "sum-zeus-animation1" });
                      setTimeout(() => {
                        this.setState({ showAnimation: "sum-zeus-animation2" });
                        setTimeout(() => {
                          this.setState({ showAnimation: "" });
                        }, 1000);
                      }, 1000);
                    }}>
                    <img
                      src="/assets/sum-zeus.svg"
                      alt="summon-zeus"
                      className="mr-2"
                    />
                    <span style={{ width: "max-content" }}>Summon Zeus</span>
                  </button>
                )}

              </div>
            </div>
            <ul
              className="nav nav-tabs company-tabs"
              id="myTab"
              role="tablist">
              {companiesTab.map((item, index) => (
                <li
                  key={index}
                  className={
                    this.props.selectedSegment === item.segmentName
                      ? "nav-item bgclr3 color9"
                      : "nav-item"
                  }
                  onClick={() =>
                    this.props.toggleSegment(item.segmentName, () => {
                      if (this.state.isEditingCompany) {
                        this.setState({ isEditingCompany: false });
                      }
                    })
                  }>
                  <a
                    className="nav-link"
                    id={`${item.segmentName}-tab`}
                    data-toggle="tab"
                    href={`#${item.segmentName}`}
                    role="tab"
                    aria-controls={item.segmentName}
                    aria-selected="false">
                    <button
                      className={
                        this.props.selectedSegment === item.segmentName
                          ? "color9"
                          : ""
                      }>
                      {item.label}
                    </button>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          {segment}
        </div>
      </section>
    );
  }
}
function mapStateToProps(state, props) {
  return {
    escrowAccountInfo: getEscrowAccountInfo(state),
    isFetchingEscrowAccountInfo: getisFetchingEscroeAccountInfo(state),
    investeeOnboardedId: getInvesteeOnboardingData(state)?._id,
    investeeOrganizationId:
      getInvesteeOnboardingData(state)?.investee_organization_id,
    isUserHasEditAccess: getCheckIfUserHasRelevantPermissions(state, [
      "app:edit",
    ]),
    displayName: getInvesteesOrgDisplayName(state),
    userEmail: getEmail(state)
  };
}
export default connect(mapStateToProps)(withRouter(CompaniesDetailPage));
