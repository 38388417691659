import React, { useCallback, useRef } from 'react';
import "../../../../../../../../../css/Drawdowns.css";
import { displayTermUnit, getRepaymentFrequencyAndGapValue } from '../../../../../../../../../util/calculateTermUtil';
import { priceFormat } from '../../../../../../../../../util/utility';

export default function DrawdownsTableRow(props) {
  const {loader, hasMore, loadMore, dataLength, index} = props;
  const observer = useRef();
  const lastRecordRef = useCallback(node => {
      if (loader) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting && hasMore()) {
              loadMore();
          }
      });
      if (node) observer.current.observe(node);
  }, [loader, hasMore]);

    let { _id,name, contract_id_num, recurring_payment_value, 
        contract_term_unit, status,
        contract_xnpv_price_terms, keys_to_pick ,custom_tenure_listed,
         is_contract_approved,approval_status,term_array_for_traded_contract,listed_perc} = props.contract;
    let xnpv_price_term = {
        terms : term_array_for_traded_contract?.[props.custom_tenure - 1]?.terms,
        xnpv : "-"
    };

    if(is_contract_approved){
        if(["Listed","Active","Processing"].includes(status)){
            xnpv_price_term = contract_xnpv_price_terms[keys_to_pick[custom_tenure_listed-1]]
        } else {
            xnpv_price_term = contract_xnpv_price_terms[keys_to_pick[props.custom_tenure-1]]
        }
    } else {
        status = approval_status;
    }
    const term_unit = getRepaymentFrequencyAndGapValue(contract_term_unit).displayUnit
    const renderer = (
            <>
               {props.drawdownStatus !== "G" &&
               <td style={{width: (status === "Listed")  ? '5%': '0%', 
                           display: (status === "Listed")  ? 'table-cell': 'none'}}>        
                   {(status === "Listed")  && 
                   <input
                    type='checkbox'
                    onChange={(e) => props.onChangeCheckBox(e,{...xnpv_price_term,recurring_payment_value,_id,listed_perc,contract_xnpv_price_terms:contract_xnpv_price_terms[0]})}
                    checked = {props.contractsForDrawdown?.indexOf(_id) !== -1}
                    ></input>
                    }
                </td>  }
                <td style={{width: status === "Listed" && props.drawdownStatus !== "G" ? '15%': '20%'}}>
                    {name}
                </td>
                <td style={{width:'15%'}}>{contract_id_num} </td>
                <td style={{width:'15%'}}>{xnpv_price_term?.terms?.terms ? xnpv_price_term?.terms?.terms : xnpv_price_term?.terms} {term_unit} </td>
                <td style={{width:'15%'}}>₹ {priceFormat(recurring_payment_value * ((listed_perc ?? 100)/100))} / {displayTermUnit(contract_term_unit)}</td>
                <td style={{width:'15%'}} className="color1 fw600">₹ {priceFormat(xnpv_price_term?.xnpv * ((listed_perc ?? 100)/100))}</td>
                {props.drawdownStatus !== "G" && 
                <td style={{width:'20%'}}>
                    <span className={`${status.toLowerCase()}-bg`} style={{maxWidth:'100px'}}>
                        {status}
                    </span>
                    {/* <span className='Order-Placed-bg' style={{maxWidth:'100px'}}>
                        Order Placed
                    </span> */}
                </td>}
            </>
        )
        if (dataLength === index + 1) {
            return (
            <tbody className='team-body'>
                <tr key={_id} ref={lastRecordRef}>
                    {renderer}
                </tr>
            </tbody>
            )
        }
        else {
            return (
            <tbody className='team-body'>
                <tr key={_id}>
                    {renderer}
                </tr>
            </tbody>
            )
        }
}