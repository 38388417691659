import React, { useState } from 'react';
import PageLoader from '../../../../../../components/loader/PageLoader';
import { API_STATUS, MONTHS } from '../../../../../../constants/enums';
import callApi from '../../../../../../util/apiCaller';
import { showNotification } from '../../../../../../util/utility';
import RepaymentDataModal from './RepaymentDataModal/RepaymentDataModal';
import styles from './RepaymentUpdateEmail.module.scss';

export default function RepaymentUpdateEmail({ investorOrgId }) {
    const {
        UpdateMailCard,
        Text,
        Buttons,
        PreviewBtn,
        EmailBtn
    } = styles;
    const [selectedMonth, setSelectedMonth] = useState('');
    const [showPreview, setShowPreview] = useState(null);
    const [isGettingResponse, setIsGettingResponse] = useState(false);

    const onSelectingMonth = e => {
        const { value } = e.target;
        setSelectedMonth(value);
    }

    const updateShowPreview = value => setShowPreview(value);

    const onButtonClick = async(isMailingUser) => {
        if (selectedMonth) {
            try {
                const apiRoute = `investor/company-repayment-schedule?investorOrgId=${investorOrgId}&month=${selectedMonth}&year=${getMonthWithYear(selectedMonth).year}`;
                const endpoint = isMailingUser ? apiRoute + '&isEmail=true' : apiRoute;
                setIsGettingResponse(true);
                const res = await(callApi(endpoint, "get"));
                if (res.status === API_STATUS.SUCCESS) {
                    if (isMailingUser) showNotification("Success", "Mailed successfully!");
                    else updateShowPreview(res.data);
                }
                else {
                    const message = res.data?.message ?? "Some error occurred!";
                    showNotification("Error", message);
                }
                setIsGettingResponse(false);
            }
            catch(err) {
                setIsGettingResponse(false);
                showNotification("Error", "Some error occurred!");
            }
        }
        else showNotification("Error", "You need to select a month");
    }

    const getMonthWithYear = month => {
        const date = new Date();
        const currentMonth = date.getMonth() + 1;
        const currentYear = date.getFullYear();
        if (currentMonth < month) return {
            toDisplay: (MONTHS[month].slice(0, 3) + ' ' + (currentYear - 1)),
            year: currentYear - 1
        };
        return {
            toDisplay: (MONTHS[month].slice(0, 3) + ' ' + currentYear),
            year: currentYear
        }
    }

    return (
        <div className={UpdateMailCard}>
            {isGettingResponse && <PageLoader />}
            <div className={Text}>Repayment Update Email</div>
            <select value={selectedMonth} onChange={onSelectingMonth}>
                <option value='' disabled>Month</option>
                {Object.keys(MONTHS).map(month => (
                    <option key={month} value={month}>{getMonthWithYear(month).toDisplay}</option>
                ))}
            </select>
            <div className={Buttons}>
                <button className={PreviewBtn} onClick={() => onButtonClick()}>
                    <img className="mr-1" src="/assets/blue-eye.svg" alt="mail-icon" />
                    Preview
                </button>
                <button className={EmailBtn} onClick={() => onButtonClick(true)}>
                    <img className="mr-1" src="/assets/email-white.svg" alt="mail-icon" />
                    Email User
                </button>
            </div>
            {showPreview && (
                <RepaymentDataModal
                    data={showPreview}
                    closeModal={() => updateShowPreview(null)}
                />
            )}
        </div>
    )
}
