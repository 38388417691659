import './ActionModals.scss'
import {Modal} from "react-bootstrap";
import React, {useEffect} from "react";

interface RejectionModalProps{
    show:boolean
    onClose:()=>void
    rejectClaim:(rejectReason:string)=>void
}
const RejectionModal = (props:RejectionModalProps)=>{
    const {show,onClose,rejectClaim} = props;
    const [rejectionReason,setRejectionReason] = React.useState<string>('');
    const [disabled,setDisabled] = React.useState<boolean>(false);
    useEffect(() => {
        if(rejectionReason===''){
            setDisabled(true)
        }else{
            setDisabled(false)
        }
    }, [rejectionReason]);
    return(
        <Modal
            show={show}
            onHide={onClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>Reject TDS Claim</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <textarea
                        className="rejection-textarea"
                        placeholder="Rejection Reason (Required)"
                        onChange={(e)=>setRejectionReason(e.target.value)}
                    />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className={'RejectionCancelBtn'}>Cancel</button>
                <button disabled={disabled} onClick={()=>rejectClaim(rejectionReason)} className={'RejectionBtn'}>Reject</button>
            </Modal.Footer>
        </Modal>
    )
}

export default RejectionModal;