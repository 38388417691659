import React from 'react';

function TableRow(props) {
    if (props.item) {
        return (
            <tr>
                <td class="text-truncate expand" style={{maxWidth: '100px', paddingLeft: '47px'}}>{props.item.code}</td>
                <td>{props.item.name}</td>
                <td class="text-truncate expand" style={{maxWidth: '100px'}}><a onClick={() => props.toggleEditItem(props.item)}>Edit</a></td>
            </tr>
        )
    }
    return null
}

export default TableRow;
