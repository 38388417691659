import isNil from 'lodash/isNil';
import React from 'react';
import styles from '../DocumentVault.module.scss';

export default function DocumentVaultTableSectionHeader({ docsData, category }) {
    const {
        TableBodyHeaders,
        Verified,
        NotVerified,
    } = styles;

    const { verifiedCount, totalCount } = docsData?.[category] ?? {
        verifiedCount: '-',
        totalCount: '-',
    }

    const areAllVerified = !isNil(totalCount) && totalCount !== '-' && totalCount !== 0 && verifiedCount === totalCount;
    return (
        <tr className={TableBodyHeaders}>
            <td>
                <div className={`dflex-ja`}>
                    <span>{category}</span>
                    <img src="/assets/expand-more.svg" alt="" className='ml-2'/>
                </div>
            </td>
            <td>
                <div className={`dflex-jca ${areAllVerified ? Verified : NotVerified}`}>
                    {areAllVerified ? (
                        <img src="/assets/doc-vault-varified.svg" alt="" className='mr-2'/>
                    ) : (
                        <img src="/assets/doc-vault-not-varified.svg" alt="" className='mr-2'/>
                    )}
                    <span>{verifiedCount ?? '-'}/{totalCount ?? '-'}  verified</span>
                </div>
            </td>
            <td></td>
            <td></td>
            {/* <td></td> */}
            <td></td>
        </tr>
    )
}