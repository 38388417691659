import React, { useEffect, useMemo, useRef, useState } from "react";
import RecurTable from "../../../../../../components/RecurTable/RecurTable";
import { ApprovedLimitsTableRow } from "./components/ApprovedLimitsTableRow";
import ReactAutoComplete from "react-autocomplete";
import styles from './components/ApprovedLimits.module.scss'
import callApi from "../../../../../../util/apiCaller";
import { showNotification } from "../../../../../../util/utility";
import clearIcon from "../../../../../../assets/clear.svg";
import { isEmpty, isString } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { queryParams } from "../../CompaniesPage";


const { header_input, appLimitsTable, filter_arr_wrapper, clear_all, uw_width, company_status_width, deal_status_width, heading } = styles;

export const underwritingStatusType = {
  NOT_STARTED: "Not Started",
  ONGOING: "OnGoing",
  APPROVED: "Approved",
  NEED_INFO: "Need Info",
};

const ApprovedLimits = ({hideCompletedExpired, fetchAggregateNumbers, setCurrentTab}) => {

  const isFirstRender = useRef(true);

  const [query, setQuery] = useState({
    companyStatus: "",
    underwriter: "",
    dealStatus: ""
  });

  const initialFilterObj = {
    companyStatus: [],
    dealStatus: [],
    underwriter: [],
    displayName: [],
    hideCompletedExpired: hideCompletedExpired
   };

  const history = useHistory();
  const location = useLocation();

  const [approvedLimitsData, setApprovedLimitsData] = useState([]);
  const [aggregateNumbers, setAggregateNumbers] = useState({});
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(null);
  const [qText, setQText] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [companyStatusEnum, setCompanyStatusEnum] = useState([]);
  const [dealStatusEnum, setDealStatusEnum] = useState([]);
  const [underwriters, setUnderwriters] = useState([])
  const [filterObj, setFilterObj] = useState({
    ...initialFilterObj,
  });
  const [is_fetching_approved_limits_data, set_is_fetching_approved_limits_data] = useState(false);

  const hasMore = () => {
    return pageNum < Math.ceil(totalCount/pageSize);
  };

  const loadMore = () => {
    setPageNum(pageNum + 1);
  };

  useEffect(() => {
    if (location?.state && "searchCompanyName" in location?.state) {
      if (location.state?.searchCompanyName) {
        setFilterObj({
          ...filterObj,
          displayName: [location.state.searchCompanyName],
        });
        history.replace({
          pathname: `/admin/company/list`,
          search: `?tab=${queryParams.APPROVED_LIMITS}&show-hide-expired-filter=${hideCompletedExpired}`,
          state: {},
        });
      }
    } else {
      history.push({
        search: `?tab=${queryParams.APPROVED_LIMITS}&show-hide-expired-filter=${hideCompletedExpired}`,
      });
      const filterObjFromLocalStorage = JSON.parse(
        window.localStorage.getItem("approvedLimitsFilterObj")
      );
      setFilterObj(filterObjFromLocalStorage ?? initialFilterObj);
    }
  }, []);

  useEffect(() => {
   if(pageNum > 1) fetchApprovedLimitsData();
  },[pageNum])

  useEffect(() => {
   if(approvedLimitsData.length === 0 && hasMore()) {
    loadMore();
   }
  }, [approvedLimitsData])

  useEffect(() => {
    if (!isFirstRender.current) {
      const tempFilterObj = {...filterObj};
      delete tempFilterObj["displayName"]
      window.localStorage.setItem(
        "approvedLimitsFilterObj",
        JSON.stringify(tempFilterObj)
      );
      fetchApprovedLimitsData(true);
    }
  }, [filterObj]);

  useEffect(() => {
    fetchAggregateNumbers();
  }, []);

  useEffect(() => {
    if(!isFirstRender.current) {
      filterObj.hideCompletedExpired = hideCompletedExpired;
        history.replace({
         search: `?tab=${queryParams.APPROVED_LIMITS}&show-hide-expired-filter=${hideCompletedExpired}`
        })
      fetchApprovedLimitsData(true);
    }
    else isFirstRender.current = false;
  }, [hideCompletedExpired])

  const checkIfFilterObjChanged = () => {
    for(const key in filterObj) {
      if(key !== "displayName" && key !== "hideCompletedExpired") {
        if(!isEmpty(filterObj[key])) return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if(qText !== null) {
      if(qText && checkIfFilterObjChanged()) {
        setFilterObj({...initialFilterObj, ["displayName"]: [qText]});
        return;
      }
      filterObj.displayName = qText === "" ? [] : [qText];
      const delayDebounceFn = setTimeout(() => {
        fetchApprovedLimitsData(true);
      }, 400)
  
      return () => clearTimeout(delayDebounceFn)
    }
  }, [qText]);

  const intitalMenuStyle = {
    borderRadius: "3px",
    boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
    background: "rgba(255, 255, 255, 0.9)",
    padding: "2px 5px",
    fontSize: "13px",
    fontWeight: "normal",
    position: "fixed",
    overflow: "auto",
    maxHeight: "50%",
    zIndex: 2,
    fontFamily: "Gilroy-SemiBold",
    cursor: "pointer",
    marginTop: "5px",
  };

    const onOptionSelect = (value, filterType) => {
      if (!value || filterObj[filterType]?.includes(value)) return;
      setFilterObj({
        ...filterObj,
        [filterType]: [...filterObj[filterType], value],
      });
    };
    
  const getFilters = () => {
    const filters = {};
    filters.requestId = Date.now();
    filters.pageNum = pageNum;
    filters.filters = filterObj; 
    return filters;
  }

  const fetchApprovedLimitsData = (isReseting) => {
    const filters = getFilters();
    if (isReseting) {
      filters.pageNum = 1;
      setPageNum(1);
    }
    set_is_fetching_approved_limits_data(true);
    callApi(`admin/fetch-approved-limits-data`, "post", filters)
      .then((res) => {
        if (res.status === "Success") {
          if (!isReseting) {
            setApprovedLimitsData((prevData) => {
              const newData = [...prevData, ...res?.data?.limitDetails];
              return newData;
            })
          }
          else setApprovedLimitsData(res?.data?.limitDetails);
          setTotalCount(res?.data?.orgCount);
          setCompanyStatusEnum(res?.data?.companyStatusEnum);
          setDealStatusEnum(res?.data?.dealStatusEnum);
          setUnderwriters(res?.data?.underwriters);
          setPageSize(res?.data?.pageSize);
          set_is_fetching_approved_limits_data(false);
        } else {
          showNotification("error", "Some error occured");
        }
      })
      .catch(() => showNotification("Error", "Some error occurred!"));
  };

  const filterObjRep = Object.values(filterObj)
  .flat()
  .filter((t) => isString(t));

  const filterObjEnteries = () => {
    let result = [];
    for (const [key, value] of Object.entries(filterObj)) {
      if (Array.isArray(value)) {
        value.forEach((v) => result.push({ key, value: v }));
      }
    }
    return result;
  };

  const handleTextSearch = (val) => {
    setQText(val);
  };

  const clearFilters = (e, allClear = false, filterType, filterValue) => {
    e.stopPropagation();
    if (allClear) {
      setQText(null);
      setFilterObj({ ...initialFilterObj });
      return;
    } else {
      if(filterType === "displayName") setQText(null);
      setFilterObj({
        ...filterObj,
        [filterType]: filterObj[filterType].filter((f) => f !== filterValue),
      });
    }
  };

  const shouldRender = (item, value) =>
    item?.label?.toLowerCase().indexOf(value.toLowerCase()) > -1;

  const getHeaders = () => {
    return [
      {
        jsx: (
          <span className={`${header_input} ${heading}`}>
            <input
              value={qText === null ? "" : qText}
              onChange={(e) => handleTextSearch(e.target.value)}
              type="text"
              className="form-control"
              placeholder="Company Name"
              style={{
                width: "180px",
                border: "none",
                fontSize: "13px",
              }}
            ></input>
          </span>
        ),
      },
      {
        jsx: (
          <div className={`${header_input} ${company_status_width} ${heading}`}>
            <ReactAutoComplete
              inputProps={{
                type: "text",
                name: "companyStatus",
                placeholder: "Company Status",
              }}
              items={Object.values(companyStatusEnum || []).map((i) => ({
                label: i,
                id: i,
              }))}
              value={query?.companyStatus}
              getItemValue={(item) => item.label}
              shouldItemRender={shouldRender}
              onChange={(e) => {
                setQuery({ companyStatus: e.target.value });
              }}
              onSelect={(value) => {
                onOptionSelect(value, "companyStatus");
              }}
              renderItem={(item, isHighlighted) => (
                <div
                  style={{
                    background: isHighlighted ? "#2F8FFF" : "white",
                    padding: "5px",
                  }}
                >
                  {item.label}
                </div>
              )}
              menuStyle={{ ...intitalMenuStyle }}
            />
          </div>
        ),
      },
      {
        jsx: <span className={heading}>Sanction/Limit</span>,
      },
      {
        jsx: <span className={heading}>Tenure</span>,
      },
      {
        jsx: <span className={heading}>Approved/ <br/>Shared</span>,
      },
      {
        jsx: (
          <div className={`${header_input} ${uw_width} ${heading}`}>
            <ReactAutoComplete
              inputProps={{
                type: "text",
                name: "underwriter",
                placeholder: "Underwriter",
              }}
              items={Object.values(underwriters || []).map((i) => ({
                label: i,
                id: i,
              }))}
              value={query?.underwriter}
              getItemValue={(item) => item.label}
              shouldItemRender={shouldRender}
              onChange={(e) => {
                setQuery({ underwriter: e.target.value });
              }}
              onSelect={(value) => {
                onOptionSelect(value, "underwriter");
              }}
              renderItem={(item, isHighlighted) => (
                <div
                  style={{
                    background: isHighlighted ? "#2F8FFF" : "white",
                    padding: "5px",
                  }}
                >
                  {item.label}
                </div>
              )}
              menuStyle={{ ...intitalMenuStyle }}
            />
          </div>
        ),
      },
      {
        jsx: (
          <div className={`${header_input} ${deal_status_width} ${heading}`}>
            <ReactAutoComplete
              inputProps={{
                type: "text",
                name: "dealStatus",
                placeholder: "Deal Status",
              }}
              items={Object.values(dealStatusEnum || []).map((i) => ({
                label: i,
                id: i,
              }))}
              value={query?.dealStatus}
              getItemValue={(item) => item.label}
              shouldItemRender={shouldRender}
              onChange={(e) => {
                setQuery({ dealStatus: e.target.value });
              }}
              onSelect={(value) => {
                onOptionSelect(value, "dealStatus");
              }}
              renderItem={(item, isHighlighted) => (
                <div
                  style={{
                    background: isHighlighted ? "#2F8FFF" : "white",
                    padding: "5px",
                  }}
                >
                  {item.label}
                </div>
              )}
              menuStyle={{ ...intitalMenuStyle }}
            />
          </div>
        ),
      },
      {
        jsx: <span className={heading}>Last Activity</span>,
      },
      {
        jsx: <span className={heading}>Action</span>,
      },
      {
        jsx: <span></span>,
      }
    ]
  };

  const renderRows = () => {
    return (
      <tbody>
        {
          approvedLimitsData?.map((item, index) => (
            <ApprovedLimitsTableRow 
             item={item} 
             dataLength={approvedLimitsData.length}
             index={index}
             hasMore={hasMore}
             loadMore={loadMore}
             loader={is_fetching_approved_limits_data}
             setCurrentTab={setCurrentTab}
             key={`index_${item.createdAt ?? item.orgId}`}
            />
          ))}
      </tbody>
    );
  };
  return (
    <div className={appLimitsTable}>
      <div className={filter_arr_wrapper}>
        {filterObjEnteries().map((o) => (
          <span onClick={(e) => clearFilters(e, false, o?.key, o?.value)}>
            {o?.value} <img src={clearIcon} alt="clear icon" />
          </span>
        ))}
        {!isEmpty(filterObjRep) && (
          <span className={clear_all} onClick={(e) => clearFilters(e, true)}>
            Clear All Filter
          </span>
        )}
      </div>
      <RecurTable headers={getHeaders()} renderRows={renderRows()} />
    </div>
  );
};

export default ApprovedLimits;
