import React, { useEffect, useState } from 'react';
import "../../../../../../../../../css/Drawdowns.css";

export default function DrawdownsTableHead(props) {

    return (
        <thead className='team-head order-table'>
            <tr>
                <th style={{width:'20%'}}>Customer</th>
                <th style={{width:'15%'}}>Contract ID</th>
                <th style={{width:'15%'}}>Term</th>
                <th style={{width:'15%'}}>Payments</th>
                <th style={{width:'15%'}}>Payout</th>
                {props.drawdownStatus !== "G" && <th style={{width:'20%'}}>Status</th>}
            </tr>
        </thead>
        )
}