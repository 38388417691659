import React from 'react';
import styles from '../DocumentVault.module.scss';

export default function ShowButton({ status, openModal }) {
    const {
        DetailBtn,
        UploadBtn,
        VerifyBtn
    } = styles;

    if (status?.toLowerCase() === 'uploaded') return (
        <button
            className={`${DetailBtn} ${VerifyBtn}`}
            onClick={openModal}
        >Verify</button>
    );
    if (status?.toLowerCase() === 'need update' || status?.toLowerCase() === 'not uploaded') return (
        <button
            className={`${DetailBtn} ${UploadBtn}`}
            onClick={openModal}
        >Upload</button>
    );
    return null;
}
