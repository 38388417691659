import React from 'react';
import { Modal } from 'react-bootstrap';
import '../RepaymentDataModal/RepaymentDataModal.scss';

export default function RepaymentDataModal({
    closeModal,
    data
}) {
    const { month, year, investorName, repayments } = data ?? {
        month: '',
        year: new Date().getFullYear(),
        investorName: '',
        repayments: []
    };
    return (
        <Modal show onHide={closeModal} className="Repayment-Data-Modal">
            <Modal.Header className="border-0">
                <div><strong>Subject:</strong> {investorName ?? ''} - Recur Club - {month} {year} Repayments Update</div>
                <button type="button" class="close">
                    <img
                        src="../../../../../assets/modal-cross.svg"
                        alt=""
                        onClick={closeModal}
                    />
                </button>
            </Modal.Header>
            <Modal.Body>
                <img
                    src="../../../../../assets/recur-logo-email.svg"
                    alt=""
                    className='my-4 mx-3'
                />
                <div className='line'></div>
                <div className='my-4 mx-3'>
                    <div className='textDecor'>
                        Hi {investorName ?? ''} Team,
                        <br></br><br></br>
                        All scheduled repayments for {month} {year} are sighted and collected in the {investorName ?? ''} virtual escrow account and transferred to the EMI Collection Account. Please find the details below:
                        <br></br><br></br><br></br>
                        <b>REPAYMENT DETAILS:</b>
                    </div>
                    <div className='RepaymentDataTable'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Company Name</th>
                                    <th>Date</th>
                                    <th>Scheduled</th>
                                    <th>Collected</th>
                                    <th>Account No</th>
                                    <th>Status</th>

                                </tr>
                            </thead>
                            <tbody>
                                {repayments && repayments.map((repayment, index) => {
                                    const {
                                        companyName,
                                        date,
                                        scheduledAmount,
                                        collectedAmount,
                                        accountNumber,
                                        status
                                    } = repayment ?? {
                                        companyName: '',
                                        date: '-',
                                        scheduledAmount: 'NA',
                                        collectedAmount: 'NA',
                                        accountNumber: '-',
                                        status: 'NA'
                                    };
                                    return (
                                        <tr key={index}>
                                            <td>{companyName}</td>
                                            <td>{date}</td>
                                            <td>{scheduledAmount}</td>
                                            <td>{collectedAmount}</td>
                                            <td>{accountNumber}</td>
                                            <td>{status}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='textDecor'>
                        If you have any questions, feel free to
                        <b> respond to this email with your queries,</b> else call us at +91 7052917096. You can always view details about your portfolio and repayments on your
                        {' '}
                        <span>Recur Portal.</span>
                        <br/><br/>
                        Thanks,<br/>
                        Recur Club
                    </div>
                </div>
                <div className='line'></div>
                <div className='my-4 mx-3 repayment-footer'>
                    You’re receiving this email because you signed up for an account on Recur. If you are not sure why you’re receiving this, please email <span>support@recur.club</span>
                    <br/><br/><br/>
                    © 2022 Recur Club Technologies Pvt. Ltd. All rights reserved
                </div>
            </Modal.Body>
        </Modal>
    );
}
