import React,{useEffect,useState} from 'react'
import callApi from '../../../../../../../../../../../util/apiCaller';
import moment from 'moment';
import styles from '../ComplianceTable.module.scss'
import { base64ToArrayBuffer, downloadByteArray, showNotification } from '../../../../../../../../../../../util/utility';
import { getUserName } from '../../../../../../../../../../App/AppReducer';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { MET, NOT_MET, PARTIALLY_MET, EXPIRED,RCM, ENUM_RCM_STATUS, MET_UNVERIFIED } from '../../../../../../../../../../../constants/enums';
import { downloadComplianceFiles } from '../../../../../../../../../../../common/ApiHelpers';
const {tableData, pl30, detailCol, CollectionComments, commentsBox, commentStyle} = styles;

export const ComplianceTableRow = ({value,index,setComplianceData,setRcm,setOverall,setSpoc}) => {
    const {_id, deal_name, docData, start_date, rcm_category, rcm_sub_category,rcm_details,rcm_status,updated_at,moderator_comments} = value;
    const [showStatus, setShowStatus] = useState(false);
    const [statusLevel, setStatusLevel] = useState("");
    const [comments, setComments] = useState();
    const [listComments,setListComments] = useState(moderator_comments);
    const [viewUpdate,SetViewUpdate] = useState(NoOfDaysAgo(updated_at))
    const [isEditComments,setIsEditComments] = useState(false);

    const status = [
      { statusLevel: MET },
      { statusLevel: PARTIALLY_MET },
      { statusLevel: NOT_MET },
      { statusLevel: MET_UNVERIFIED },
    ];

    const userName = useSelector(state => getUserName(state));

    function findStatusClass(status){
      switch(status) {
        case MET:
          return styles.GreenCircle;
        case PARTIALLY_MET:
          return styles.YellowCircle;
        case NOT_MET:
          return styles.GreyCircle;
        case MET_UNVERIFIED:
          return styles.BlueCircle;
        default:
          return "";
      }
    }
    
    function getRcmStatusText(status){
      switch(status) {
        case MET:
          return "Met (Verified)";
        case PARTIALLY_MET:
          return PARTIALLY_MET;
        case NOT_MET:
          return NOT_MET;
        case MET_UNVERIFIED:
          return "Met (Unverified)"
        default:
          return "";
      }
    }

    useEffect(()=>{
      if(comments && comments !== moderator_comments && comments !== ""){
        updateStatus({comments:comments});
        setComplianceData((prev_data)=>{
          const temp_data = [...prev_data];
          temp_data[index].moderator_comments=comments;
          return temp_data;
        });
        setSpoc((prev_data)=>{
          const temp=prev_data;
          temp.last_spoc=userName;
          temp.last_update=new Date();
          return temp;
        })
        let day=NoOfDaysAgo(new Date());
        SetViewUpdate(day);
      }
    },[comments])

    useEffect(() => {
      if (statusLevel !== rcm_status && statusLevel !== "") {
        updateStatus({status:statusLevel});
        setComplianceData((prev_data)=>{
          const temp_data = [...prev_data];
          temp_data[index].rcm_status=statusLevel;
          return temp_data;
        });
        if(rcm_category===RCM){
          setRcm((prev_data)=>{
            const temp={...prev_data};
            temp[ENUM_RCM_STATUS[rcm_status]]-=1;
            temp[ENUM_RCM_STATUS[statusLevel]]+=1;
            return temp;
          })
        }
        setOverall((prev_data)=>{
          const temp={...prev_data};
          temp[ENUM_RCM_STATUS[rcm_status]]-=1;
          temp[ENUM_RCM_STATUS[statusLevel]]+=1;
          return temp;
        })
        setSpoc((prev_data)=>{
          const temp=prev_data;
          temp.last_spoc=userName;
          temp.last_update=new Date();
          return temp;
        })
        let day=NoOfDaysAgo(new Date());
        SetViewUpdate(day);
      }
    }, [statusLevel]);
    
    function updateStatus(data){
      const body ={};
      body.status_id=_id;
      if (data?.status) body.rcm_status=statusLevel;
      if (data?.comments) body.moderator_comments=data.comments;
      body.updated_by = userName
      callApi(
        `admin/updateCompliance`,
        "post",
        body
      ).then((res) => {
          if(res.status==='Success'){
            showNotification('success', 'Data saved successfully', 1500, 'top-right')
          }else showNotification("error", 'Data update failed', 1500, 'top-right')
      });
    }

    function updateComments(e){
      if(e.target.value){
        setComments(e.target.value);
        const updateObj={
          comment:e.target.value,
          updated_at:new Date(),
          owner:userName
        }
        const newListComments=[...listComments];
        newListComments.push(updateObj);
        setListComments(newListComments);
      }
      setIsEditComments(false);
    }

    function NoOfDaysAgo(updated_at){
      const updatedAtDate = new Date(updated_at);
      const now = new Date();
      // Set today's date to 11:59 PM
      const today = new Date(now);
      today.setHours(23, 59, 0, 0);
      // Calculate the difference in milliseconds
      const millisecondsDifference = today.getTime() - updatedAtDate.getTime();
      // Convert milliseconds to days
      const daysDifference = Math.floor(millisecondsDifference / (1000 * 60 * 60 * 24));
      if (daysDifference === 0) {
        return "Today";
      } else if (daysDifference === 1) {
        return "Yesterday";
      } else {
        return daysDifference + " Days ago";
      }
    }

    const getComplianceStatus = () => {
      if (rcm_sub_category === "NACH") {
        return (
          <div className={styles.ComplianceDropdown}>
            <div className={styles.OptionSelected}>
              <span className="dflex-ac mr-1.5">
                <div className={findStatusClass(rcm_status)}></div>
                {rcm_status}
              </span>
            </div>
          </div>
        );
      }
      if (rcm_status !== EXPIRED) {
        return (
          <div className={styles.ComplianceDropdown} onClick={() => setShowStatus(!showStatus)}>
            <div className={styles.OptionSelected}>
              <span className="dflex-ac mr-1.5">
                <div className={findStatusClass(rcm_status)}></div>
                {getRcmStatusText(rcm_status)}
              </span>
              <img src="../../../../../assets/dropdown-select.svg" alt="" />
            </div>
            {showStatus && (
              <div className={styles.Options}>
                {status.map((item) => (
                  <span
                    key={item?.statusLevel}
                    className="dflex-ac"
                    onClick={() => setStatusLevel(item?.statusLevel)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className={findStatusClass(item?.statusLevel)}></div>
                    {getRcmStatusText(item.statusLevel)}
                  </span>
                ))}
              </div>
            )}
          </div>
        );
      }
      return EXPIRED;
    }

    const downloadCpFiles = async () => {
      const data = await downloadComplianceFiles(_id);
      if (data) {
        const blob = new Blob([data], { type: 'octet/stream' });
        const fileName = `${deal_name ? deal_name + "_" : ""}CP.zip`;
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      }
    }

    const renderer = (
        <>
         <td><div className={pl30}>{deal_name ?? '-'}</div></td>
         <td>{start_date ? moment(start_date).format("DD MMM YYYY"): '-'}</td>
         <td>{rcm_category ?? '-'}</td>
         <td>{rcm_sub_category ?? '-'}</td>
         <td className={detailCol}>{rcm_details ?? '-'}</td>
         <td>{getComplianceStatus()}</td>
         <td>{viewUpdate}</td>
         {/* <td><textarea className={complianceComments} disabled={rcm_status===EXPIRED} placeholder={'Comments here'} value={moderator_comments} onBlur={updateComments}></textarea></td> */}

        <td className={CollectionComments} style={{ width: "15%" }}>
          {isEditComments ? (
            <textarea
              autoFocus={isEditComments}
              onBlur={updateComments}
              style={{ width: "139px", height: "61px" }}
            />
          ) : (
            <div
              onDoubleClick={() => setIsEditComments(true)}
              className={commentsBox}
            >
              {Array.isArray(listComments) ? (
                listComments?.map((comment, index) => (
                  <div className="mb-2" key={index}>
                    <div className={commentStyle}>
                      <span>
                        {dayjs(comment?.updated_at).format("h:mm A")},&nbsp;
                        {dayjs(comment?.updated_at).format("DD MMM YY")}
                      </span>
                      <span>&nbsp;by {comment?.owner}</span>
                    </div>
                    <div>{comment?.comment}</div>
                  </div>
                ))
              ) : (
                <div>{comments}</div>
              )}
            </div>
          )}
          {Array.isArray(docData) && docData.length > 0 && (
            <button className={styles.downloadFileBtn} onClick={downloadCpFiles}>
              <img className={styles.downloadIcon} src="/assets/download-dv.svg" alt="Download" />
              {docData.length} file{docData.length > 1 ? "s" : ""}
            </button>
          )}
        </td>
        </>
    )
  return (
    <tr
      className={`ht71px ${tableData}`}
    >
      {renderer}
    </tr>
  )
}
