import { getS3IconLink } from "../../../../../../../../../../../util/utility";

export default function Dropdown ({ label, value, onChange, options }) { 
  return(
  <div >
    <label>{label}</label>
    <select  className="OptionSelect" id ={value} value={value} onChange={onChange} style={{ backgroundImage: `url(${getS3IconLink("down-arrow")})`}}>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  </div>
  )
;
      }