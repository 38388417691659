import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { API_STATUS } from '../../../../../../../../../../constants/enums';
import callApi from '../../../../../../../../../../util/apiCaller';
import {
    capitalizeFirstLetter,
    downloadFileUsingFetch,
    showNotification
} from '../../../../../../../../../../util/utility';

export default function DataVaultIntegration({
    investeeOrgId,
    investeeOnboardedId,
    integrationsObj,
    fetchMonthlyData
}) {
    const categories = ["MIS", "Invoicing", "Bank Statement"];
    const sources = ["Zoho", "Tally", "Razorpay", "Stripe"];
    const mapping = {
        "MIS": "integration_mis",
        "Invoicing": "integration_invoicing",
        "Bank Statement": "integration_bank_statements"
    }
    const [isDownloadingFile, setIsDownloadingFile] = useState(false);
    const [encryptedInvesteeOrgId, setEncryptedInvesteeOrgId] = useState('');
    const [categoriesObj, setCategoriesObj] = useState({
        "MIS": {
            zoho: false,
            tally: false,
            razorpay: false,
            stripe: false,
            by_user: false,
            by_admin: false
        },
        "Invoicing": {
            zoho: false,
            tally: false,
            razorpay: false,
            stripe: false,
            by_user: false,
            by_admin: false
        },
        "Bank Statement": {
            zoho: false,
            tally: false,
            razorpay: false,
            stripe: false,
            by_user: false,
            by_admin: false
        }
    });
    const {
        misIntegrations,
        invoicingIntegrations,
        bankStatementsIntegrations
    } = integrationsObj;

    useEffect(() => {
        setIntegrations();
    }, [
        misIntegrations,
        invoicingIntegrations,
        bankStatementsIntegrations
    ]);

    useEffect(() => {
        getEncryptedInvesteeOrganizationId();
    }, [])

    const setIntegrations = () => {
        const obj = { ...categoriesObj };
        obj["MIS"] = { ...misIntegrations };
        obj["Invoicing"] = { ...invoicingIntegrations };
        obj["Bank Statement"] = { ...bankStatementsIntegrations };
        setCategoriesObj(obj);
    }

    // const getIntegratedSources = obj => {
    //     let sources = [];
    //     for (let i in obj) {
    //         if (obj[i] && i !== "_id") {
    //             sources.push(capitalizeFirstLetter(i));
    //         }
    //     }
    //     return sources;
    // }

    const checkIfIntegrated = integrationObj => {
        let hasIntegrated = "";
        for (let i in integrationObj) {
            const item = capitalizeFirstLetter(i);
            if (integrationObj[i] && (sources.includes(item))) {
                hasIntegrated = item;
                break;
            }
        }
        return hasIntegrated;
    }

    const handleCategoryClick = category => {
        const obj = { ...categoriesObj };
        if (!obj[category].show_sources) obj[category].show_sources = true;
        else obj[category].show_sources = false;
        setCategoriesObj(obj);
    }

    const handleOptionClick = (category, source, toRemove = false) => {
        const obj = {};
        const objToSend = {};
        if (toRemove) {
            objToSend[source] = false;
        }
        else {
            objToSend[source] = true;
        }
        obj[mapping[category]] = objToSend;
        callApi(`data-vault/${investeeOnboardedId}/edit-investee-integration-info`, "post", obj).then(res => {
            if (res.status === "Success") {
                const {
                    integration_mis,
                    integration_invoicing,
                    integration_bank_statements
                } = res.data?.investeeOnboardingDetails;
                const obj = { ...categoriesObj };
                obj["MIS"] = integration_mis ? { ...integration_mis } : obj["MIS"];
                obj["Invoicing"] = integration_invoicing ? { ...integration_invoicing } : obj["Invoicing"];
                obj["Bank Statement"] = integration_bank_statements ? { ...integration_bank_statements } : obj["Bank Statement"];
                setCategoriesObj(obj);
            }
            else {
                const message = res.data?.message ? res.data?.message : "Some error occurred!";
                showNotification("Error", message);
            }
        });
        setTimeout(() => fetchMonthlyData(), 0);
    }

    const getEncryptedInvesteeOrganizationId = () => {
        callApi(
            `recur-checkout/investee-organization-id/${investeeOrgId}/get-encrypted-investee-organization-id`,
            'post'
        ).then((res) => {
            if (res.status === API_STATUS.SUCCESS) {
                setEncryptedInvesteeOrgId(
                    res.data.encryptedInvesteeOrganizationId
                );
            }
        });
    };

    const onRemove = (category, source) => {
        handleOptionClick(category, source, true);
    }

    const downloadIntegrationDocs = () => {
        setIsDownloadingFile(true);
        downloadFileUsingFetch(`admin/v2/${investeeOrgId}/zip-stream-integration-or-documentation-docs?purpose=integration`, "get", {
            "responseType": 'blob'
        }, 'integrationFiles.zip', () => {
            setIsDownloadingFile(false);
        });
    }

    const copyGstFetchLink = () => {
            navigator.clipboard.writeText(
                `${process.env.REACT_APP_CUSTOMERAPP_URI
                }/${encodeURIComponent(
                    encryptedInvesteeOrgId
                )}/gst-invoices-fetch`
            );
            showNotification(
                'Success',
                'GST Fetch Link Copied'
            );
    }

    return (
        <div>
            <div className="fs16 fw600 mb-2 dflex-ja" >
                <div className='d-flex'>
                    <span className='mr-2'>Integrations</span>
                    {isDownloadingFile ?
                        <div id="loading-spinner">
                            <div className="spin-icon"></div>
                        </div>
                        :
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={downloadIntegrationDocs}
                        >
                            <img src="/assets/download-dv.svg" alt="" />
                        </span>
                    }
                </div>
                <div onClick={copyGstFetchLink}>
                    <span
                        style={{ cursor: 'pointer' }}
                    >
                        <img style={{"height": "15px", "paddingBottom": "2px"}} src="/assets/copy-icon-blue.svg" alt="" />
                    </span>
                    <span className='mr-4 gst-link-button' 
                    >GST Fetch Link</span>
                </div>
            </div>
            <div className="BgGreyBorder mb-4">
                {/* <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <span>
                            <button
                                className="btn1 fs10 fw600 mr-2"
                                onClick={onCancel}
                            >Cancel</button>
                            <button
                                className="btn1 fs10 fw600"
                                onClick={onSave}
                            >Save</button>
                        </span>
                    </div>
                </div> */}
                <div className="BgWhite mt-2">
                    {Object.keys(categoriesObj).map(category => {
                        if (categories.includes(capitalizeFirstLetter(category))) {
                            const source = checkIfIntegrated(categoriesObj[category]);
                            return (
                                <span key={category}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span className="fs12">
                                            <b>{category==='MIS'? "Provisional Fs.":category}</b>
                                            {categoriesObj[category].by_user && <span> (by user)</span>}
                                            {categoriesObj[category].by_admin && <span> (by admin)</span>}
                                        </span>
                                        <span
                                            className="fs10 color5"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => onRemove(category, source.toLowerCase())}
                                        >
                                            <img className="mr-1" src="/assets/remove.svg" alt="" style={{ width: '9px' }} />
                                            <span>Remove</span>
                                        </span>
                                    </div>
                                    <div
                                        className="d-flex justify-content-between Bgmoderate fs12 fw300"
                                        style={{ padding: "10px", margin: "5px 0 15px" }}
                                        onClick={() => handleCategoryClick(category)}
                                    >
                                        <span>{source}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="24px" height="24px" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#757575" stroke="none">
                                                <path d="M1180 3504 c-183 -66 -269 -266 -190 -439 33 -72 1360 -1400 1435 -1436 40 -19 66 -24 135 -24 69 0 95 5 135 24 73 35 1401 1363 1436 1436 34 72 34 199 -1 270 -31 64 -91 124 -155 155 -71 35 -198 35 -270 1 -39 -19 -166 -140 -597 -570 l-548 -546 -547 546 c-441 439 -558 550 -598 569 -66 30 -173 37 -235 14z" />
                                            </g>
                                        </svg>
                                    </div>
                                    {categoriesObj[category].show_sources && (
                                        <div
                                            className="Bgmoderatefs12 fw300 ml-auto"
                                            style={{ padding: "10px", margin: "5px 0 15px" }}
                                        >
                                            {sources.map((item, index) => (
                                                <div
                                                    key={item}
                                                    className={index !== sources.length - 1 ? "mb-1" : null}
                                                    onClick={() => handleOptionClick(category, item.toLowerCase())}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    {item}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </span>
                            )
                        }
                    })}
                </div>
                {/* {!isEditing &&
                    <div className="BgWhite mt-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="fs12 color4 fs300">No account synced</span>
                        </div>
                    </div>
                } */}
            </div>
        </div>
    )
}
