import moment from "moment";
import RecoveryviewProgress from "./RecoveryviewProgress";
import { updateRecoveryViewOwner } from "../../../../../../common/ApiHelpers";
import RecoveryviewNotes from "./RecoveryviewNotes";
import styles from "../Recovery.module.scss";
import ReactTooltip from "react-tooltip";
import {useRef, useState} from "react";

type RecoveryTableRowProps={
  item: any
  setLoader: Function
  agentsMap: any[]
  agents: any[]
  fetchRecoveryDataWithoutLoader: Function
  index: number
  setLoading: Function
  metricsDate : string
}

const RecoveryTableRow = ({
  item,
  setLoader,
  agentsMap,
  agents,
  fetchRecoveryDataWithoutLoader,
  index,
  setLoading,
  metricsDate
}:RecoveryTableRowProps) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const count = useRef(0);
  const {
    remainingAmt,
    remainingDelayCharge,
    companyName,
    dpdBucket,
    totalRiskAmt,
    totalRiskText,
    ptpDate,
    lastActionTimeDiff,
    lastAction,
    notes,
  } = item;
  const {
    companyNameWrap,
    lastActionWrapper,
    dueAmountWrapper,
    portfolioWrapper,
    ownerWrapper,
    upperAmount,
    lowerAmount,
    dpdBucketWrap,
    ptpDateWrap,
    ptpDateWrapper,
    ptpTooltipWrapper,
    heading,
    ptpDates,
    ptpTooltip,
  } = styles;
  const handleUpdateOwner = async (e:any, item:any) => {
    e.stopPropagation()
    setLoading(true)
    const agent = agents.find((item) => item.agentName === e.target.value);
    const payload = {
      yyyyMM: moment().format("YYYY-MM"),
      organizationId: item.investeeOrgId,
      owner: agent.id,
    };
    await updateRecoveryViewOwner(payload);
    await fetchRecoveryDataWithoutLoader();
    setLoading(false)
  };
  return (
    <tr
      onClick={(e)=>{
        if(!isHovering) {
          setShowDrawer(true);
        }
      }}
    style={{
      cursor: "pointer",
    }}
    >
      <td>
        <p
          className={companyNameWrap}
          title={companyName}
          
        >
          {companyName}
        </p>
      </td>
        <RecoveryviewProgress item={item} isHovering={isHovering} setIsHovering={setIsHovering}/>
      <td>
        <div className={dueAmountWrapper}>
          <p className={upperAmount}> {remainingAmt}</p>
          <p className={lowerAmount}>+ {remainingDelayCharge} charges</p>
        </div>
      </td>
      <td>
        <p className={dpdBucketWrap}>{dpdBucket?.length ? dpdBucket : "-"}</p>
      </td>
      <td>
        <div className={portfolioWrapper}>
          <p className={upperAmount}>{totalRiskAmt}</p>
          <p className={lowerAmount}>{totalRiskText}</p>
        </div>
      </td>
      <td>
        <div className={ptpDateWrapper}>
          {ptpDate?.length ? (
            <>
              <p className={ptpDateWrap}>
                {moment(ptpDate?.[0]?.ptpDate).format("D MMM YYYY")}
              </p>
              <img
                src="/assets/outlined_needinfo.svg"
                alt="info"
                data-tip=""
                data-for={`ptpDateInfo_${index}_${item.total}`}
              />
              <ReactTooltip
                key={`ptpDateInfo_${index}_${item.total}`}
                id={`ptpDateInfo_${index}_${item.total}`}
                type="light"
                place="bottom"
                className={ptpTooltip}
                border={true}
                borderColor="black"
                delayHide={500}
                delayShow={50}
                delayUpdate={500}
                multiline={true}
              >
                <div className={ptpTooltipWrapper}>
                  <p
                    className={heading}
                  >{`PTP updated ${ptpDate?.length} times`}</p>
                  {ptpDate.slice(0, 5).map((e) => {
                    return (
                      <p className={ptpDates}>
                        {moment(e.ptpDate).format("D MMM YYYY")}
                      </p>
                    );
                  })}
                  {ptpDate.length >= 6 ? (
                    <p className={ptpDates}>
                      + {ptpDate.slice(5, ptpDate.length).length} more
                    </p>
                  ) : null}
                </div>
              </ReactTooltip>
            </>
          ) : (
            <p className={ptpDateWrap}>{"-"}</p>
          )}
        </div>
      </td>
      <td>
       <div className={ownerWrapper} 
       onClick={(e)=>{
            e.stopPropagation()
          }}>
       <select
          disabled={new Date().getMonth() > new Date(metricsDate).getMonth()}
          value={item.owner}
          onClick={(e)=>{
            e.stopPropagation()
          }}
          onChange={(e) => {
            handleUpdateOwner(e, item);
          }}
        >
          {agentsMap.map((item) => {
            return <option value={item.value}>{item.label}</option>;
          })}
        </select>
       </div>
      </td>
      <td>
        <div className={lastActionWrapper}>
          {notes.length ? (
            <>
              <p className={upperAmount}>{lastAction}</p>
              <p className={lowerAmount}>{lastActionTimeDiff}</p>{" "}
            </>
          ) : (
            <p className={upperAmount}>{"-"}</p>
          )}
        </div>
      </td>
      <td onClick={(e)=>e.stopPropagation()} style={{ margin: "10px 0", width:"170px" }}>
        <RecoveryviewNotes
          item={item}
          fetchRecoveryDataWithoutLoader={fetchRecoveryDataWithoutLoader}
          setShowDrawer={setShowDrawer}
          showDrawer={showDrawer}
          setLoading={setLoading}
          metricsDate={metricsDate}
        />
      </td>
    </tr>
  );
};

export default RecoveryTableRow;
