import React from 'react'

export default function ToggleButton({ checked, onChange,name,disabled=false }) {
    return (
        <label className="switch">
            <input type="checkbox" checked={checked} onChange={onChange} name={name} disabled={disabled}/>
            <span className="slider round"></span>
        </label>
    )
}
