import React, {
    // Component
} from 'react';
// import {Link} from 'react-router-dom';
// import moment from 'moment'

function HelpRequestTableRow(props) {
    if (props.item) {
        return (
            <tr>
                <td class="text-truncate expand" style={{maxWidth: '100px', paddingLeft: '47px'}}>{props.item.template_name}</td>
                <td class="text-truncate expand" style={{maxWidth: '100px', paddingLeft: '5px'}}>{props.item.template_type}</td>
                <td class="text-truncate expand" style={{maxWidth: '100px'}}><a onClick={() => props.toggleEditItem(props.item)}>Edit</a></td>
            </tr>
        )
    }
    return null
}

export default HelpRequestTableRow;
