export const ICON_NAMES = {
    SAVE_ICON_BLUE:'SaveIconBlue',
    CROSS_RED_NACH: 'CrossRedNach',
    DELETE_ICON_RED: 'DeleteIconRed',
    EDIT_ICON_BLUE_NACH: 'EditIconBlueNach',
    INFO_BLACK: 'info-icon-black',
}

export const FL_ICON_NAMES = {
    GREEN_DOWN_ARROW: 'arrowDownGreen',
    RED_UP_ARROW: 'arrowUpRed'
}