import React, { useState, useEffect } from 'react';
import '../../css/Modal.scss';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import { isEmpty, isEqual } from 'lodash';

export default function FileReader({ docs, shouldUpdateDocs, onDocsUpdateSuccess }) {
    const PDF_FILE_TYPE = 'application/pdf';
    const [documents, setDocuments] = useState();
    const [needToUpdateDoc, setIsNeedToUpdateDoc] = useState(true);
    const [tempDocs, setTempDocs] = useState(null);

    useEffect(() => {
        if (!isEmpty(docs) && needToUpdateDoc) {
            setDocuments(docs);
            if (shouldUpdateDocs && onDocsUpdateSuccess) onDocsUpdateSuccess();
            setIsNeedToUpdateDoc(false);
        }
        else if (isEmpty(docs) && !needToUpdateDoc) {
            setDocuments(docs);
            setIsNeedToUpdateDoc(true);
        }
    }, [docs]);

    useEffect(() => {
        if (shouldUpdateDocs && !isEmpty(docs)) {
            if (!isEmpty(documents) && isEmpty(tempDocs)) setTempDocs(documents);
            setDocuments({});
        }
    }, [shouldUpdateDocs]);

    useEffect(() => {
        if (isEmpty(documents) && !isEmpty(docs) && !isEqual(tempDocs, docs)) {
            setDocuments(docs);
            if (!isEmpty(tempDocs)) setTempDocs(null);
            if (onDocsUpdateSuccess) onDocsUpdateSuccess();
        }
    }, [documents, tempDocs, docs]);

    const isPdfFileType = () => documents?.[0]?.fileType === PDF_FILE_TYPE;

    return (
        <div className='PDFInnerSection'>
            {!isEmpty(documents) && (isPdfFileType() ? (
                <iframe src={documents?.[0]?.uri} width="100%" height="100%" loading='lazy' />
            ) : (
                <DocViewer
                    style={{ height: '85vh' }}
                    pluginRenderers={DocViewerRenderers}
                    documents={documents}
                />
            ))}
        </div>
    )
}
