import styles from "../Recovery.module.scss";

type ViewCommentDrawerProps = {
  item: any;
  setIsEditing: Function
  metricsDate: string;
};

const ViewCommentDrawer = ({ item, setIsEditing,metricsDate }: ViewCommentDrawerProps) => {
  const {
    drawerHeader,
    notesDrawerWrapper,
    notesHeaderWrapper,
    addedNotes,
    addNotesCTAWrapper,
    ownerInfo,
    addNotesCTA,
    commentBox,
    commentsWrapper,
    timeStamp,
    comment,
  } = styles;
  const { notes } = item;

  return (
    <>
      <section className={notesDrawerWrapper}>
        <div className={notesHeaderWrapper}>
          <p className={addedNotes}>Added Notes</p>
          <div className={addNotesCTAWrapper}>
            {notes.length ? (
              <p
                className={ownerInfo}
              >{`${notes?.[0]?.noteTime} by ${notes?.[0]?.noteOwner} `}</p>
            ) : null}
            <button
              className={addNotesCTA}
              disabled={new Date().getMonth() > new Date(metricsDate).getMonth()}
              onClick={() => {
                setIsEditing(true);
              }}
            >
              Add Notes
            </button>
          </div>
        </div>
        <div className={commentBox}>
          {notes.map((e:any) => {
            const noteParts = e.note?.split("\n");
            return (
              <div className={commentsWrapper}>
                <p className={timeStamp}>
                  {e.noteTime} by {e.noteOwner}
                </p>
                {noteParts?.map((part:any) => (
                  <>{part && <p className={comment}>{part}</p>}</>
                ))}
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default ViewCommentDrawer;
