import moment from "moment";
import React, { useCallback, useRef } from "react";
// import {Link} from 'react-router-dom';
import _ from "lodash";

function ClubAccessTableRow(props) {
  const observer = useRef();
  const lastUserRef = useCallback(
    (node) => {
      if (props.loader) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && props.hasMore()) props.loadMore();
      });
      if (node) observer.current.observe(node);
    },
    [props.loader, props.hasMore()]
  );

  const renderer = (
    <>
      <td className="" style={{width:'15%'}}>
        <input
          type='checkbox'
          value={props.item._id}
          style={{ marginLeft: "25px", marginRight: "10px" }}
          checked={props.selected_user_ids.some((i) => i === props.item._id)}
          onChange={props.onChangeUserCheckbox.bind(this)}
        />
        <span>{props.item.contact_name}</span>
      </td>
      <td style={{width:'7%'}}>{props.item.is_email_verified ? "Yes" : "No"}</td>
      <td style={{width:'7%'}}>{props.item.phone_number}</td>
      {props.item.is_investee ? (
        <td  style={{width:'6%'}}>{"Company"}</td>
      ) : props.item.is_investor ? (
        <td  style={{width:'6%'}}>{"Investor"}</td>
      ) : (
        <td  style={{width:'6%'}}>{"Admin"}</td>
      )}
      {!_.isEmpty(props.item.investee_organization_id) ? (
        <td style={{width:'11%'}}>{props.item.investee_organization_id.name}</td>
      ) : !_.isEmpty(props.item.investor_organization_id) ? (
        <td style={{width:'11%'}}>{props.item.investor_organization_id.name}</td>
      ) : (
        <td style={{width:'11%'}}></td>
      )}
      {props.item.is_investee ? (
        <td style={{width:'9%'}}>{"Company"}</td>
      ) : props.item.is_investor ? (
        props.item.investor_id ? (
          <td style={{width:'9%'}}>{props.item.investor_id.investor_type}</td>
        ) : (
          <td style={{width:'9%'}}></td>
        )
      ) : (
        <td style={{width:'9%'}}>{"Admin"}</td>
      )}
      <td style={{width:'11%'}}>{props.item.contact_email}</td>
      <td style={{width:'9%'}}>
        {moment(props.item.created_at)
          .utc()
          .utcOffset(330)
          .format("DD MMMM YYYY")}
      </td>
      <td
       style={{width:'9%'}}
        className={`bold-table-cel status-${_.kebabCase(
          props.item.approval_status
        )}-table-cel`}>
        <b>{props.item.approval_status}</b>
      </td>
      <td style={{width:'7%'}}>{props.item.admin_user}</td>
      <td style={{width:'9%'}}>
        {moment(props.item.updated_at)
          .utc()
          .utcOffset(330)
          .format("DD MMMM YYYY")}
      </td>
    </>
  );

  if (props.dataLength === props.index + 1) {
    return <tr ref={lastUserRef}>{renderer}</tr>;
  } else {
    return <tr>{renderer}</tr>;
  }
}

export default ClubAccessTableRow;
