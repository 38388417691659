import moment from 'moment';
import { useEffect, useRef } from 'react';
import { INVESTOR_DISPLAY_NAME } from '../../../../../../../../../../../../constants/enums';
import { getTimePassed } from '../../../../../../../../../../../../util/utility';
import { MESSAGE_TYPES } from '../ViewDocsDrawer/UWQueries/consts/consts';
import ShowFiles from '../ViewDocsDrawer/UWQueries/ShowFiles/ShowFiles';
import styles from './ChatBox.module.scss';

// If wrapping this component with some element, then need to provide "overflow: auto;" style to that wrapper element
export default function ChatBox({ messages, investorOrgName, onDeleteSuccess }) {
    const RECUR = "Recur";
    const INVESTOR = "Investor";
    const USER_TYPE = {
        INVESTOR: INVESTOR,
        ADMIN: "Admin",
    }
    const {
        ChatContainer,
        InvestorChat,
        User,
        InvestorIcon,
        InvestorUserName,
        LastActivity,
        Message,
        RecurChat,
        RecurIcon,
        RecurUserName,
        RecurFilesContainer,
        InvestorFileContainer,
        RecurFileContainer
    } = styles;

    const scrollRef = useRef(null);

    useEffect(() => {
        scrollAfterOpeningChatBox();
    }, [messages?.length]);

    const scrollAfterOpeningChatBox = () => {
        scrollRef.current?.scrollIntoView({ block: 'end' });
    };

    const isSenderTypeInvestor = (senderType) => senderType === USER_TYPE.INVESTOR;

    return (
        <div className={ChatContainer}>
            {messages?.map((message, index) => (
                <div
                    ref={index === messages.length - 1 ? scrollRef : null}
                    className={isSenderTypeInvestor(message.senderType) ? InvestorChat : RecurChat}
                >
                    <div className={User}>
                        <div className={isSenderTypeInvestor(message.senderType) ? InvestorIcon : RecurIcon}>
                            {isSenderTypeInvestor(message.senderType) ? 'I' : 'R'}
                        </div>
                        <div className={isSenderTypeInvestor(message.senderType) ? InvestorUserName : RecurUserName}>
                            {isSenderTypeInvestor(message.senderType) ? (INVESTOR_DISPLAY_NAME[investorOrgName] ?? investorOrgName ?? INVESTOR) : RECUR} - {message.sender}
                        </div>
                        <div className={LastActivity}>{getTimePassed(moment.utc(message.createdAt).local())} ago</div>
                    </div>
                    <div className={Message}>
                        <div className={message?.additionalDocuments?.length ? 'mb-2' : ''}>
                        {message.message.split('\n').map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </div>
                        <ShowFiles
                            className={
                                !isSenderTypeInvestor(message.senderType) ? RecurFilesContainer : ''
                            }
                            childClassName={
                                isSenderTypeInvestor(message.senderType) ? InvestorFileContainer : RecurFileContainer
                            }
                            messageType={MESSAGE_TYPES.CHAT}
                            files={message.additionalDocuments}
                            onDeleteSuccess={onDeleteSuccess}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
}
