import { green } from '@material-ui/core/colors';
import _ from 'lodash';
import moment from 'moment';
import React, { useRef } from 'react';
import { Button } from 'react-bootstrap';
import { displayTermUnit } from '../../../../../util/calculateTermUtil';
// import {Link} from 'react-router-dom';
// import moment from 'moment'

function SwapsTableRow({item,openModal}) {

    // const observer = useRef();
    // const lastInvesteeRef = useCallback(
    //   (node) => {
    //     if (props.loader) return;
    //     if (observer.current) observer.current.disconnect();
    //     observer.current = new IntersectionObserver((entries) => {
    //       if (entries[0].isIntersecting && props.hasMore()) props.loadMore();
    //     });
    //     if (node) observer.current.observe(node);
    //   },
    //   [props.loader, props.hasMore()]
    // );
  
    return (
        <tr className="ht71px">
            <td class="text-truncate expand pl-1" style={{width:'8%',maxWidth:'100px'}}>{item.investor_organization_name}</td>
            <td style={{width:'8%'}}>{
                item.investee_organization_name
                }</td>
            <td style={{width:'8%'}}>{item.contract_id_num}</td>
            <td style={{width:'8%'}}>{item.remaining_terms} {item.contract_term_unit}</td>
            <td style={{width:'8%'}}>₹{item.recurring_payment_value} / {displayTermUnit(item.contract_term_unit)}</td>
            <td style={{width:'8%'}}>{item.swap_date_identified ? moment(item.swap_date_identified).format('DD MMM YYYY'): 'NA'}
            </td>
            <td style={{width:'11%'}}>{item.swapped_with?.join(', ')}</td>
            <td style={{width:'11%'}}>₹{item.foreclosure_amount}</td>
            <td  style={{width:'8%'}} className={`bold-table-cel status-${_.kebabCase(item.swap_request_status)}-table-cel`}>
                {_.capitalize(item.swap_request_status)}
            </td>
            <td style={{width:'8%'}}></td>
            <td style={{width:'5%'}}>
                <Button disabled = {item.swap_request_status !== 'open'} variant={item.swap_request_status !== 'open' ? "secondary":"primary"} className ="rectangle-swap" onClick ={() => openModal(item,'swap')} >
                    <img style={{height:'18px'}} src="/assets/swap_icon.svg"></img>
                </Button>
            </td>
            <td style={{width:'5%'}}>
                <Button disabled = {item.swap_request_status !== 'open'} variant="secondary" className ="rectangle-liquidate" onClick ={() => openModal(item,'liquidate')}>
                    <img style={{height:'18px'}} src="/assets/liquidate.svg"></img>
                </Button>
            </td>

        </tr>
    )
}

export default SwapsTableRow;
