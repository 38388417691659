import "./UwRiskFlagTooltip.scss";

export const UwRiskFlagTooltip = (props: any) => {

  const uw_signal_types = {
    REJECT : "reject",
    DIFFICULT: "difficult",
    SPARK: "spark",
    GOOD: "good",
    FANTASTIC: "fantastic",
  };

  
  // ICP Sector
  const getIcpSectorClass = (value : string) => {
    if(value === "YES"){
      return `fw300 fs9 ${uw_signal_types.FANTASTIC.toUpperCase()}`
    }else{
      return `fw300 fs9 ${uw_signal_types.DIFFICULT.toUpperCase()}`
    }
  }

// Proprietorship Firm
const getProprietorshipFirm = (value : string) => {
  if(value === "NO"){
    return ` fw300 fs9 ${uw_signal_types.FANTASTIC.toUpperCase()}`
  }else{
    return `fw300 fs9 ${uw_signal_types.DIFFICULT.toUpperCase()}`
  }
}

// Customer Concentration
const getCustomerConcentrationClass = (value : string) => {
  if(value === "HIGH"){
    return `fw300 fs9 ${uw_signal_types.REJECT.toUpperCase()}`
  }else if(value === "AVERAGE"){
    return `fw300 fs9 ${uw_signal_types.DIFFICULT.toUpperCase()}`
  }else{
    return `fw300 fs9 ${uw_signal_types.FANTASTIC.toUpperCase()}`
  }
}

// Cibil Score
const getCibilScore = (value : number) => {
  if(value > 700){
    return `fw300 fs9 ${uw_signal_types.FANTASTIC .toUpperCase()}`
  }else if(value <= 700 && value>650){
    return `fw300 fs9 ${uw_signal_types.DIFFICULT.toUpperCase()}`
  }else{
    return `fw300 fs9 ${uw_signal_types.REJECT.toUpperCase()}`
  }
}

const getColorCodeForUwTag = (riskFlag: string) => {
  switch (riskFlag) {
    case uw_signal_types.REJECT:
      return "#D80000";
    case uw_signal_types.DIFFICULT:
      return "#FCBF07";
    case uw_signal_types.GOOD:
      return "#00AA00";
    case uw_signal_types.SPARK:
      return "#5A5A5A";
    case uw_signal_types.FANTASTIC:
      return "#8f00ff";
  }
};

  const { uwRiskTags } = props;
  return (
    <div className="riskTooltipWrapperUwFlag">

      {/* UW Signal Heading */}
      <h6>
        DealEvaluation:{" "}
        <span
          style={{ color: getColorCodeForUwTag(uwRiskTags?.uwSignal) }}
        >
          {uwRiskTags?.uwSignal?.toUpperCase()}
        </span>
      </h6>

      {/* Vintage */}
      <div className="riskRow">
        <div className="riskRowLeft">Vintage (m)</div>
        <div
          className={`requestedRowMiddle fw300 fs9 ${uwRiskTags?.vintage}`}
        >
          {uwRiskTags?.vintageVal?.toFixed(2)}
        </div>
        <div
          className={`requestedRowRight fw300 fs9 ${uwRiskTags?.vintage}`}
        >
          {uwRiskTags?.vintage}
        </div>
      </div>

      {/* Promoter Cibil Score */}
      <div className="riskRow">
        <div className="riskRowLeft">Promoter Cibil Score</div>
        <div
          className={`requestedRowMiddle ${getCibilScore(uwRiskTags?.promoterCibilScoreVal)}`}
        >
          {uwRiskTags?.promoterCibilScoreVal?.toFixed(2)}
        </div>
        <div
          className={`requestedRowRight ${getCibilScore(uwRiskTags?.promoterCibilScoreVal)}`}
        >
          {uwRiskTags?.promoterCibilScore?.toUpperCase()}
        </div>
      </div>
      
      {/* Receivables */}
      <div className="riskRow">
        <div className="riskRowLeft">Receivables</div>
        <div
          className={`requestedRowMiddle ${getCustomerConcentrationClass(uwRiskTags?.receivables?.toUpperCase())}`}
        >
          {uwRiskTags?.receivableDaysVal?.toFixed(2)}
        </div>
        <div
          className={`requestedRowRight ${getCustomerConcentrationClass(uwRiskTags?.receivables?.toUpperCase())}`}
        >
          {uwRiskTags?.receivables?.toUpperCase()}
        </div>
      </div>
        
      {/* Customer Concentration */}
      <div className="riskRow">
        <div className="riskRowLeft">Customer Concentration</div>
        <div
          className={`requestedRowMiddle ${getCustomerConcentrationClass(uwRiskTags?.customerConcentration?.toUpperCase())}`}
        >
          {uwRiskTags?.customerConcentrationVal?.toFixed(2)}
        </div>
        <div
          className={`requestedRowRight ${getCustomerConcentrationClass(uwRiskTags?.customerConcentration?.toUpperCase())}`}
        >
          {uwRiskTags?.customerConcentration?.toUpperCase()}
        </div>
      </div>

      {/* Proprietor Ship Firm */}
      <div className="riskRow">
        <div className="riskRowLeft">Proprietary Firm</div>
        {/* <div
          className={`requestedRowMiddle ${getProprietorshipFirm(uwRiskTags.proprietorshipFirm?.toUpperCase())}`}
        >
          {uwRiskTags.proprietorshipFirm}
        </div> */}
        <div
          className={`requestedRowRight ${getProprietorshipFirm(uwRiskTags?.proprietorshipFirm?.toUpperCase())}`}
        >
          {uwRiskTags?.proprietorshipFirm?.toUpperCase()}
        </div>
      </div>

      {/* ICP Sector */}
      <div className="riskRow">
        <div className="riskRowLeft">ICP Sector</div>
        {/* <div
          className={`requestedRowMiddle ${getIcpSectorClass(uwRiskTags.icpSector?.toUpperCase())}`}
        >
          {uwRiskTags.icpSector}
        </div> */}
        <div
          className= {`requestedRowRight ${getIcpSectorClass(uwRiskTags?.icpSector?.toUpperCase())}`}
        >
          {uwRiskTags?.icpSector?.toUpperCase()}
        </div>
      </div>

      {/* ARR (INR Cr) */}
      <div className="riskRow">
        <div className="riskRowLeft">ARR (INR Cr)</div>
        <div
          className={`requestedRowMiddle fw300 fs9 ${uwRiskTags?.arrStatus?.toUpperCase()}`}
        >
          {uwRiskTags?.arr?.toFixed(2)}
        </div>
        <div
          className= {`requestedRowRight fw300 fs9 ${uwRiskTags?.arrStatus?.toUpperCase()}`}
        >
          {uwRiskTags?.arrStatus?.toUpperCase()}
        </div>
      </div>

      {/* Runway */}
      <div className="riskRow">
        <div className="riskRowLeft">Runway</div>
        <div
          className={`requestedRowMiddle fw300 fs9 ${uwRiskTags?.runwayStatus?.toUpperCase()}`}
        >
          {uwRiskTags?.runway?.toFixed(2)}
        </div>
        <div
          className={`requestedRowRight fw300 fs9 ${uwRiskTags?.runwayStatus?.toUpperCase()}`}
        >
          {uwRiskTags?.runwayStatus?.toUpperCase()}
        </div>
      </div>

      {/** Debt Percentage */}
      <div className="riskRow">
        <div className="riskRowLeft">Debt(%)</div>
        <div
          className={`requestedRowMiddle fw300 fs9 ${uwRiskTags?.debtStatus?.toUpperCase()}`}
        >
          {uwRiskTags?.debtPercent?.toFixed(2)}
        </div>
        <div
          className={`requestedRowRight fw300 fs9 ${uwRiskTags?.debtStatus?.toUpperCase()}`}
        >
          {uwRiskTags?.debtStatus?.toUpperCase()}
        </div>
      </div>

        {/** Debt Percentage */}
        <div className="riskRow">
          <div className="riskRowLeft">Tentative Funding (INR Cr)</div>
          <div
            className={`requestedRowMiddle fw300 fs9`}
          >
            {uwRiskTags?.tentativeFunding?.toFixed(2)}
          </div>
          <div
            className={`requestedRowRight fw300 fs9 ${uwRiskTags?.debtStatus?.toUpperCase()}`}
          >
            {/* {uwRiskTags?.debtStatus?.toUpperCase()} */}
          </div>
      </div>



      
    </div>
  );
};
