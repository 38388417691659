import React from "react";
import {Route, Redirect} from "react-router-dom";
import { ADMIN, CERBERUS_TOKEN } from "./constants/enums";
import storage from "./util/storageService";

const ProtectedAdminRoute = ({isAuthenticated, isUserAdmin, component: Component, ...rest}) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (storage.get(CERBERUS_TOKEN) && storage.get(ADMIN)) {
                    return <Component {...props} />;
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: {
                                    from: props.location
                                }
                            }}
                        />
                    );
                }
            }}
        />
    );
};
export default ProtectedAdminRoute;
