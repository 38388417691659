import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { downloadFileUsingFetch } from '../../../../../../../../../../util/utility';
import { getCheckIfUserHasRelevantPermissions } from '../../../../../../../../../App/AppReducer';

const documentationData = {
    submission_date: new Date(),
    status: "Submitted"
}
export default function DocumentationTableRows({ investeeOrganizationId, companyName, documentationData, onModerate }) {
    const [isDownloadingFile, setIsDownloadingFile] = useState(false);
    const isUserHasEditAccess = useSelector(state => getCheckIfUserHasRelevantPermissions(state, ["app:edit"])); 
    const {
        submission_date,
        status,
        is_kyc_documents_complete: kycDocsCheck,
        is_authorized_signatory_complete: authSignatoryCheck,
        is_company_authorization_complete: companyAuthCheck,
        is_additional_documents_complete: additionalDocsCheck
    } = documentationData;

    const downloadFiles = () => {
        setIsDownloadingFile(true);
        const fileName = companyName.replace(/ /g, '_') + '_' + "kyc-documentation-files.zip";
        downloadFileUsingFetch(`admin/v2/${investeeOrganizationId}/zip-stream-integration-or-documentation-docs?purpose=documentation`, "get", {
            "responseType": 'blob'
        }, fileName, () => {
            setIsDownloadingFile(false);
        });
    }

    return (
        <tr className='bgclr1'>
            <td className="pl-2">
                <span className="color1">Documentation</span>
            </td>
            <td>
                {kycDocsCheck ?
                    <img src="/assets/done.svg" alt="" />
                    :
                    <img src="/assets/pending.svg" alt="" />
                }
            </td>
            <td>
                {authSignatoryCheck ?
                    <img src="/assets/done.svg" alt="" />
                    :
                    <img src="/assets/pending.svg" alt="" />
                }
            </td>
            <td>
                {companyAuthCheck ?
                    <img src="/assets/done.svg" alt="" />
                    :
                    <img src="/assets/pending.svg" alt="" />
                }
            </td>
            <td>
                {additionalDocsCheck ?
                    <img src="/assets/done.svg" alt="" />
                    :
                    <img src="/assets/pending.svg" alt="" />
                }
            </td>
            <td>
                {submission_date ?
                    (submission_date === "-" ? submission_date : moment(submission_date).format("DD MMM YYYY"))
                    :
                    "-"
                }
            </td>
            {/* <td><span className="submitted-bg">{status}</span></td> */}
            <td>
                <span className={`${status?.split(' ').join('-')}-bg`}>
                    {status === "Need Information" ? "Need Info" : status}
                </span>
            </td>
            {isUserHasEditAccess ?
            <td>
                <span onClick={onModerate}>
                    <img className="mr-2" src="/assets/moderate.svg" alt="" />
                    <span className="color2 fw500">Moderate</span>
                </span>
            </td> : 
            <td>
            <span >
                <span className="color2 fw500"></span>
            </span>
        </td> 
            }
            <td className='text-center'>
                {isDownloadingFile ?
                    <div id="loading-spinner">
                        <div className="spin-icon"></div>
                    </div>
                    :
                    <span onClick={downloadFiles}>
                        <img src="/assets/download-dv.svg" alt="" />
                    </span>
                }
            </td>
        </tr>
    )
}
