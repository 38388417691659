import ReactAutoComplete from "react-autocomplete";
import "./NeedInfoForm.scss";
import { useEffect, useState } from "react";
import ToggleButton from "../../../../../../../../../../../../components/ToggleButton/ToggleButton";
import { Modal } from "react-bootstrap";
import {
  DSNEEDINFOARRAY,
  dataTypeOptions,
  showNotification,
} from "../../../../../../../../../../../../util/utility";
import { getAllInvestors } from "../../../../../../../../../../../../common/ApiContainer";
import { SHOW_NOTIFICATION_STATUS } from "../../../../../../../../../../../../constants/enums";
import { debounce } from "lodash";

const intitalMenuStyle = {
  borderRadius: "3px",
  boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
  background: "#ffffff",
  padding: "2px 5px",
  fontSize: "13px",
  border: "none",
  fontWeight: "normal",
  position: "fixed",
  overflow: "auto",
  maxHeight: "50%",
  zIndex: 2,
  fontFamily: "Gilroy-SemiBold",
  cursor: "pointer",
  marginTop: "5px",
  display: "block",
};

const NeedInfoForm = ({
  needInfoFormArray,
  setNeedInfoFormArray,
  investorOptions,
  data
}) => {
  const [deleteModalIndex, setDeleteModalIndex] = useState(null);
  // const [investorOptions,setInvestorOptions]=useState([])
  const [open, setOpen] = useState(false);
  let isValidDataType=true
  const [dsNeedInfoClicked, setDSNeedInfoClicked] = useState(false);
  const handleOpen = (index) => {
    setDeleteModalIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setDeleteModalIndex(null);
    setOpen(false);
  };

  function handleAddNeedInfo() {
    setNeedInfoFormArray((prev) => {
      return prev.concat([
        {
          data_type: "",
          investors: [],
          investor: "",
          description: "",
          allow_document_upload: true,
          allow_text: true,
          scale_ds_request: true,
          mandatory: true,
        },
      ]);
    });
  }

  function handleChange  (event, index) {
    const { name, value, type, checked } = event.target;
    if (name === "data_type") {
      const {dataType,isValid}=checkIsValidDataType(value)
      if(!isValid){
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR,`Data type ${dataType.dataType ?? dataType.data_type} already exits!`)
        setNeedInfoFormArray((prev) => {
          return prev.map((item, i) => {
            if (i === index) {
              if (type === "checkbox") {
                // Handle checkbox input
                return {
                  ...item,
                  [name]: checked,
                };
              } else {
                // Handle text input
                return {
                  ...item,
                  [name]: value,
                  data_type_error: true,
                  data_type_error_message:"Data type already exists!"
                };
              }
            }
            return item;
          });
        });
        return
      } 
    }

    setNeedInfoFormArray((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          if (type === "checkbox") {
            // Handle checkbox input
            return {
              ...item,
              [name]: checked,
            };
          } else {
            // Handle text input
            return {
              ...item,
              [name]: value,
              data_type_error: item.data_type_error,
              data_type_error_message:item.data_type_error_message
            };
          }
        }
        return item;
      });
    });
  };

  // const debouncedHandleChange=debounce((e,index)=>handleChange(e,index),500)

  function handleAddInvestors(value, investor, index) {
    setNeedInfoFormArray((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            investors: !item.investors.find(
              (item) => item.value === investor.value
            )
              ? item.investors.concat(investor)
              : item.investors, // Update the specific property with the new value
          };
        }
        return item;
      });
    });
  }

  function handleRemoveInvestor(parentIndex, investorIndex) {
    let _needInfoFormArray = [...needInfoFormArray];
    for (let i = 0; i < _needInfoFormArray.length; i++) {
      if (i === parentIndex) {
        let investorsList = _needInfoFormArray[parentIndex].investors;
        for (let j = 0; j < investorsList.length; j++) {
          if (j === investorIndex) {
            investorsList.splice(j, 1);
          }
        }
      }
    }
    setNeedInfoFormArray(_needInfoFormArray);
  }
  function handleBlur(event, index) {
    let _needInfoFormArray = [...needInfoFormArray];
    for (let i = 0; i < _needInfoFormArray.length; i++) {
      if (i === index) {
        if (!event.target.value.length) {
          _needInfoFormArray[index].data_type_error = true;
        } else {
          _needInfoFormArray[index].data_type_error = false;
        }
      }
    }
    setNeedInfoFormArray(_needInfoFormArray);
  }

  function handleRemoveCard() {
    let _needInfoFormArray = [...needInfoFormArray];
    _needInfoFormArray.splice(deleteModalIndex, 1);
    setNeedInfoFormArray(_needInfoFormArray);
    handleClose();
  }

  function handleAddDSNeedInfo() {
    setNeedInfoFormArray((prev) => {
      return prev.concat(DSNEEDINFOARRAY);
    });
    setDSNeedInfoClicked(true);
  }

  function handleRemoveDSNeedInfo() {
    let listWithOutDSNeedInfo = needInfoFormArray.filter(
      (item) => !item.addViaDSNeedInfo
    );
    setNeedInfoFormArray(listWithOutDSNeedInfo);
    setDSNeedInfoClicked(false);
  }
  const shouldRender = (item, value) =>
    item?.label?.toLowerCase().indexOf(value.toLowerCase()) > -1;

    function checkIsValidDataType(value){
      for(let i=0;i<needInfoFormArray.length;i++){
        if(needInfoFormArray[i].data_type===value){
          return {
            dataType:needInfoFormArray[i],
            isValid:false
          }
        }
      }
      for(let i=0;i<data.length;i++){
        if(data[i].dataType===value && data[i].status!=="Not Approved" && data[i].status!=="Approved"){
          return {
            dataType:data[i],
            isValid:false
          }
        }
      }
      return {
        isValid:true
      }
    }
  const handleUpdateDataTypeSelect = (value, item, index) => {
    const {dataType,isValid}=checkIsValidDataType(value)
    if(!isValid){
      showNotification(SHOW_NOTIFICATION_STATUS.ERROR,`Data type ${dataType?.dataType ?? dataType?.data_type} already exists!`)
      return;
    } 
    setNeedInfoFormArray((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            data_type: value,
            data_type_error: false,
          };
          // }
        }
        return item;
      });
    });
  };
  return (
    <>
      <form className="needInfoForm">
        {needInfoFormArray.map((item, index) => {
          return (
            <div className="needInfoFormCard" key={`needInfo_${index}`}>
              {needInfoFormArray.length > 1 ? (
                <img
                  src="/assets/remove.svg"
                  alt="clear"
                  className="removeIcon"
                  onClick={() => {
                    handleOpen(index);
                    // handleRemoveCard(index);
                  }}
                />
              ) : null}
              <div className="needInfoFormWrapper">
                <div className="selectWrapper">
                  <label className={item.data_type_error ? "error" : ""}>
                    {" "}
                    Data Type
                  </label>
                  {/* <select
                    className={`needInfoSelect ${
                      item.data_type_error ? "error" : ""
                    }`}
                    value={item["data_type"]}
                    name={"data_type"}
                    onChange={(e) => {
                      handleChange(e, index);
                    }}
                    onBlur={(event) => {
                      handleBlur(event, index);
                    }}
                  >
                    <option value="" selected>
                      Select Data Type
                    </option>
                    {/* <option value="0">First option</option>
                    <option value="1">Second option</option> */}
                  {/* {
                      filteredDataTypeOptions.map((item)=>{
                        return(
                          <option value={item.value} disabled={item.disabled}>{item.label}</option>
                        )
                      })
                    }
                  </select> */}
                  <ReactAutoComplete
                    wrapperStyle={{ display: "block" }}
                    inputProps={{
                      type: "search",
                      name: "data_type",
                      placeholder: "Search DataType",
                    }}
                    items={dataTypeOptions}
                    value={item.data_type}
                    getItemValue={(item) => item.label}
                    shouldItemRender={shouldRender}
                    onChange={(e) => {
                      handleChange(e, index);
                    }}
                    onSelect={(value, item) => {
                      handleUpdateDataTypeSelect(value, item, index);
                    }}
                    renderInput={(props) => {
                      return (
                        <input
                          className={`needInfoSelect ${
                            item.data_type_error ? "error" : ""
                          }`}
                          {...props}
                        />
                      );
                    }}
                    renderItem={(item, isHighlighted) => (
                      <div
                        style={{
                          background: isHighlighted
                            ? "rgba(0,0,0,.075)"
                            : "white",
                          padding: "5px",
                          margin: "-2px -5px",
                          opacity: "1",
                        }}
                      >
                        {item.label}
                      </div>
                    )}
                    menuStyle={{ ...intitalMenuStyle }}
                  />
                  {item.data_type_error ? (
                    <p className="datatypeError"> {item.data_type_error_message?.length ? item.data_type_error_message:"* Data Type is Required"}</p>
                  ) : null}
                </div>
                <div className="inputWrapper">
                  <label> Investor</label>
                  <ReactAutoComplete
                    wrapperStyle={{ display: "block" }}
                    inputProps={{
                      type: "search",
                      name: "investor",
                      placeholder: "Search Investor",
                    }}
                    items={investorOptions}
                    value={item.investor}
                    getItemValue={(item) => item.label}
                    shouldItemRender={shouldRender}
                    onChange={(e) => {
                      handleChange(e, index);
                    }}
                    onSelect={(value, item) => {
                      handleAddInvestors(value, item, index);
                    }}
                    renderInput={(props) => {
                      return <input className="needInfoInput" {...props} />;
                    }}
                    renderItem={(item, isHighlighted) => (
                      <div
                        style={{
                          background: isHighlighted
                            ? "rgba(0,0,0,.075)"
                            : "white",
                          padding: "5px",
                          margin: "-2px -5px",
                          opacity: "1",
                        }}
                      >
                        {item.label}
                      </div>
                    )}
                    menuStyle={{ ...intitalMenuStyle }}
                  />
                </div>
              </div>
              {item.investors.length ? (
                <div className="investorWrapper">
                  {item.investors.map((investor, i) => {
                    return (
                      <div key={investor}>
                        <p className="investor">{investor.label}</p>
                        <img
                          src="/assets/clear-blue.svg"
                          alt="clear"
                          className="removeInvestor"
                          onClick={() => {
                            handleRemoveInvestor(index, i);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : null}
              <div className="descriptionWrapper">
                <label> Description</label>
                <textarea
                  rows={4}
                  name="description"
                  value={item["description"]}
                  onChange={(e) => {
                    handleChange(e, index);
                  }}
                />
              </div>
              <hr />
              <div className="needInfoCheckBoxWrapper">
                <div>
                  <input
                    type="checkbox"
                    name="allow_document_upload"
                    checked={item["allow_document_upload"]}
                    onChange={(e) => {
                      handleChange(e, index);
                    }}
                  />
                  <label>Allow Document Upload</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="allow_text"
                    checked={item["allow_text"]}
                    onChange={(e) => {
                      handleChange(e, index);
                    }}
                  />
                  <label>Allow Text Input</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="scale_ds_request"
                    checked={item["scale_ds_request"]}
                    onChange={(e) => {
                      handleChange(e, index);
                    }}
                  />
                  <label>Scale DS Request</label>
                </div>
                <div className="toogleWrapper">
                  <div className="DocumentVaultSwitch mr-2">
                    <ToggleButton
                      name={"mandatory"}
                      checked={item["mandatory"]}
                      onChange={(e) => {
                        handleChange(e, index);
                      }}
                    />
                  </div>
                  <label>Make Mandatory</label>
                </div>
              </div>
            </div>
          );
        })}
      </form>
      <div className="outerButtonWrapper">
        <button onClick={handleAddNeedInfo}>
          <img
            src="/assets/Add-blue.svg"
            alt="add-icon"
            width={14}
            height={14}
          />
          <p>Additional Need Info</p>
        </button>
        {/* <button
          onClick={
            dsNeedInfoClicked ? handleRemoveDSNeedInfo : handleAddDSNeedInfo
          }
        >
          {dsNeedInfoClicked ? (
            <>
              {" "}
              <img
                src="/assets/clear-red.svg"
                alt="add-icon"
                width={14}
                height={14}
              />
              <p className="remove">Remove All DS Need Info</p>
            </>
          ) : (
            <>
              <img
                src="/assets/Add-blue.svg"
                alt="add-icon"
                width={14}
                height={14}
              />
              <p>Add All DS Need Info</p>
            </>
          )}
        </button> */}
      </div>
      <Modal
        show={open}
        onHide={handleClose}
        size="xl"
        // aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ zIndex: "999999" }}
        backdrop={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="modal-title">Deleting</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="needInfoDeleteModal">
            <p> Are you sure you want to delete this?</p>
            <div className="deleteButtonWrapper">
              <button className="cancel" onClick={handleClose}>
                Cancel
              </button>
              <button className="delete" onClick={handleRemoveCard}>
                Yes, Delete it
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NeedInfoForm;
