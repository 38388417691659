import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import callApi from '../../../../../../../util/apiCaller';
import { showNotification } from '../../../../../../../util/utility';
import { getInvesteesOrganization } from '../../../../../AdminReducers';
import StatusBar from './DataVault/components/StatusBar';


export default function Checkout({
 
}) {
    const tabs = ["Disabled", "Enabled"];
    const statusBar = {
        1: "Disabled",
        2: "Enabled"
    }
    const investeeOrgCheckoutAllowed = useSelector(state => getInvesteesOrganization(state).recur_checkout_allowed);
    const isFirstRender = useRef(investeeOrgCheckoutAllowed ? statusBar[2]: statusBar[1]);
    const [currentCheckoutStatus, setCheckoutStatus] = React.useState(investeeOrgCheckoutAllowed ? statusBar[2]: statusBar[1]);
    const investeeOrganizationId = useSelector(state => getInvesteesOrganization(state)._id);
    const updateIsCheckoutAllowed = (status) => {
        const body = {};
        body.investee_organization_id = investeeOrganizationId;
        body.recur_checkout_allowed = status;
        callApi(`/admin/set-recur-checkout-flag`, "post", body).then(
            res => {
                if (res.status === "Success") {
                    showNotification("Success", "Successfully updated checkout flag");
                } else {
                    showNotification("Error", "Couldn't update flag");
                }
            }
        );
    }
    const handleStatusUpdate = (status) => {
        isFirstRender.current = null;
        updateIsCheckoutAllowed(status == statusBar[2] ? true: false);
    }

    return (
        <div className="container-fluid pt-4 main-onboarding">
            <StatusBar tabs={tabs} currentTab={currentCheckoutStatus} setCurrentTab={setCheckoutStatus} />
            {(currentCheckoutStatus === statusBar[1] && isFirstRender.current !== currentCheckoutStatus) &&
                handleStatusUpdate(statusBar[1])
            }
            {(currentCheckoutStatus === statusBar[2] && isFirstRender.current !== currentCheckoutStatus) &&  
                handleStatusUpdate(statusBar[2])
            }
        </div>
    )
}
