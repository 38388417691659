import React, { useEffect, useState } from 'react';
import { isEmpty, isNil } from 'lodash';
import RecurTable from '../../../../components/RecurTable/RecurTable';
import callApi from '../../../../util/apiCaller';
import { showNotification, updateParentObj } from '../../../../util/utility';
import AdminHead from '../../components/AdminHead';
import styles from './RecurCheckoutPage.module.scss';
import RecurCheckoutTableRow from './components/RecurCheckoutTableRow';
import PageLoader from '../../../../components/loader/PageLoader';
import { API_STATUS } from '../../../../constants/enums';

export default function RecurCheckoutPage() {
    const { NoDataTable } = styles;
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [checkoutData, setCheckoutData] = useState([]);
    const [qtext, setQtext] = useState();
    const [typingtimeout, setTypingtimeout] = useState();

    useEffect(() => {
        fetchCheckoutData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isNil(qtext)) {
            if (typingtimeout) {
                clearTimeout(typingtimeout);
                setTypingtimeout(null);
            }
            setTypingtimeout(setTimeout(() => fetchCheckoutData(true), 400));
        }
    }, [qtext]);

    const fetchCheckoutData = (isFiltering) => {
        const body = {};
        if (isFiltering) body.orgName = qtext;
        setIsFetchingData(true);
        callApi('admin/recur-checkout/get-checkout-data', 'post', body).then((res) => {
            if (res.status === API_STATUS.SUCCESS) setCheckoutData(res.data);
            else showNotification('error', 'Failed to fetch checkout data');
            setIsFetchingData(false);
        });
    };

    const updateCheckoutData = ({ accountStatementId, contract, index, isCustomerPayment, cb }) => {
        const body = { accountStatementId };
        if (contract) body.contractId = contract._id;
        else body.isCustomerPayment = isCustomerPayment;
        callApi("admin/recur-checkout/checkout-data-update", "post", body).then(res => {
            if (res.status === API_STATUS.SUCCESS) {
                const arr = [...checkoutData];
                const childObj = { ...res.data };
                if (!childObj.contract_id) childObj.contractName = '';
                if (contract) childObj.contractName = contract.name;
                arr[index] = updateParentObj({ parentObj: arr[index], childObj });
                setCheckoutData(arr);
                if (cb) cb();
            }
            else {
                const message = res.data?.message ?? "Some error occurred!";
                showNotification("Error", message);
            }
        })
    }

    const getHeaders = () => [
        {
            jsx: <span>Date</span>,
        },
        {
            jsx: <span>Company Name</span>,
        },
        {
            jsx: <span>Customer Payment</span>,
        },
        {
            jsx: <span>Customer Name</span>,
        },
        {
            jsx: <span>Payment</span>,
        },
        {
            jsx: <span>Status</span>,
        },
    ];

    const renderRows = (data) => {
        if (isEmpty(data)) {
            return (
                <tbody className={NoDataTable}>
                    <tr>
                        <td colSpan="6">No data available.</td>
                    </tr>
                </tbody>
            );
        }
        return (
            <tbody>
                {checkoutData && checkoutData.map((item, index) => (
                    <RecurCheckoutTableRow
                        key={index}
                        data={item}
                        index={index}
                        updateCheckoutData={updateCheckoutData}
                    />
                ))}
            </tbody>
        );
    }

    const handleTextSearch = (val) => {
        setQtext(val);
    };

    return (
        <div className="main-body">
            {isFetchingData && <PageLoader />}
            <AdminHead />
            <section className="w-100">
                <div className="d-flex justify-content-between has-search p-4">
                    <div className="fs21 fw600">Recur Checkout</div>
                    <div>
                        <span className='form-control-feedback'>
                            <img
                                alt=''
                                src='../../../../../assets/search.png'
                                style={{ height: "14px", width: "14px" }}
                            />
                        </span>
                        <input
                            value={qtext}
                            onChange={(e) => handleTextSearch(e.target.value)}
                            type='text'
                            className='form-control'
                            placeholder='Search Company'
                            style={{ margin: "auto 0px" }}
                        />
                    </div>
                </div>
                <div className="page-head-bottom-border"></div>
                <div className='mt-5'>
                    <RecurTable headers={getHeaders()} renderRows={renderRows(checkoutData)} />
                </div>
            </section>
        </div>
    );
}
