import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import '../../../../../../../../../../../css/ModerationComment.css'
import callApi from '../../../../../../../../../../../util/apiCaller';
import { showNotification } from '../../../../../../../../../../../util/utility';
import _, { isEmpty } from 'lodash';
import styled from "styled-components";
import SaveSVG from '../../../../../../../../../../../components/SVGs/SaveSVG';
import TradeTerms from './components/TradeTerms';
import { getInvesteeOnboardingData, getInvesteesOrganization } from '../../../../../../../../../AdminReducers';
import { useSelector } from 'react-redux';
import { ADD_ON_LIMIT_CONST, IDENTIFIER_TYPE, SHOW_NOTIFICATION } from '../../../../../../../../../../../constants/enums';
import AdditionalInfo from './components/AdditionalInfo';
import { sendWaitlistedEmailHelper } from '../../../../../../../../../../../common/ApiHelpers';

const DynamicValue = styled.textarea`
    border: 0.5px solid #D2D2D2;
    border-radius: 4px;
    background-color: #FFFFFF;
    height: 80px;
    width: 100%;
    margin-top: 2px;
    font-weight: 500 !important;
    font-size: 10px;
    padding: 10px;
    color: #ADADAD;
    &:disabled{
        background-color: #F1F1F1;
        border: none;
    }
    &:focus, &:valid{
        border: 0.5px solid #4C8DFF;
        color: #000000;
    }
`;
const SelectBox = styled.select`
    border: 0.5px solid #D2D2D2;
    border-radius: 4px;
    background-color: #FFFFFF;
    height: 40px;
    width: 100%;
    margin-top: 2px;
    font-weight: 500 !important;
    font-size: 10px;
    padding: 10px;
    color: #ADADAD;
    &:disabled{
        background-color: #F1F1F1;
        border: none;
    }
    &:focus, &:valid{
        border: 0.5px solid #4C8DFF;
        color: #000000;
    }
`;
const EmailUserBtn = styled.button`
    width: 115px;
    border-radius: 4px;
    background-color: #00bd00;
    color: #ffffff;
    font-size: 10px;
    font-weight: 600;
    border: none;
    margin-right: 30px;
    padding: 6.5px 0;
    img {
        width: 14px;
        margin-right: 5px;
    }
    &:disabled {
        background-color: #c7c7c7;
    }
`;
const ModerateBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 4px;
    background-color: #4C8DFF;
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 600;
    border: none;
    padding: 7px 24px;
`;

export default function ModerateRowBoxNew({
    investeeOnboardedId,
    fetchMonthlyData,
    fetchDocumentationData,
    dataLength,
    moderateRow,
    price,
    tradingLimit,
    monthlyData,
    irr,
    contractsCount,
    monthlyViewData
}) {
    const NEEDINFO = "Need Information";
    const APPROVED = "Approved";
    const IS_NACH = "is_nach_enabled";
    const WAITLISTED = 'Waitlisted'
    const investeeOrganizationId = useSelector(getInvesteesOrganization)?._id;

    const MIS = "MIS";
    const AUD_FINANCIAL = "Audited Financials";
    const PROV_FINANCIAL = "Provisional Financials";
    const INVOICING = "Invoicing Data";
    const ADD_BANK = "is_add_bank_account";

    const APPROVED_RUNAWAY = "Approved-Runway<3M";

    const emailCheck = price && tradingLimit && irr && contractsCount;
    const { dataType, due_date } = moderateRow;
    const getStatusOptions = () => {
        if (dataType === "monthly") {
            if (dataLength > 1 || due_date !== "Onboarding") {
                if (due_date.includes(ADD_ON_LIMIT_CONST)) {
                    return ["Need Information", "Approved", "Approved-Runway<3M", "Waitlisted"];
                }
                return ["Need Information", "Approved", "Approved-Runway<3M"];
            }
            else {
                return ["Need Information", "Approved", "Not Approved", "Waitlisted", "Spark Approved"];
            }
        }
        else if (dataType === "documentation") {
            return ["Need Information", "Approved"];
        }
    }
    const onboardingData = useSelector(state => getInvesteeOnboardingData(state));
    const investee_details = useSelector(state => getInvesteesOrganization(state));
    const [statusOptions, setStatusOptions] = useState(getStatusOptions());
    const [isModerating, setIsModerating] = useState(false);
    const initialStatusInfo = () => {
        if (due_date === "Documentation") {
            return {
                1: {
                    category: "KYC",
                    status: ""
                },
                2: {
                    category: "Aut. Doc.",
                    status: ""
                },
                3: {
                    category: "Company Aut.",
                    status: ""
                },
                4: {
                    category: "Add'I",
                    status: ""
                }
            }
        }
        if (due_date === "Onboarding") {
            return {
                1: {
                    category: MIS,
                    status: "",
                    comment: ""
                },
                2: {
                    category: AUD_FINANCIAL,
                    status: "",
                    comment: ""
                },
                3: {
                    category: PROV_FINANCIAL,
                    status: "",
                    comment: ""
                },
                4: {
                    category: INVOICING,
                    status: "",
                    comment: ""
                }
            };
        }
        return {
            1: {
                category: "Bank Statement",
                status: "",
                comment: ""
            },
            2: {
                category: "MIS",
                status: "",
                comment: ""
            },
            3: {
                category: "Invoicing",
                status: "",
                comment: ""
            }
        };
    }
    const [statusInfo, setStatusInfo] = useState(initialStatusInfo());
    const statusInfoRef = useRef(initialStatusInfo());
    const [showReqStatusOptions, setShowReqStatusOptions] = useState(false);
    const [addnInfo, setAddnInfo] = useState([])
    const [isAddnInfo, setIsAddnInfo] = useState(false)
    const [status, setStatus] = useState("");
    const statusRef = useRef("");
    const [note, setNote] = useState("");
    const noteRef = useRef("");
    const [CP, setCP] = useState([""]);
    const CPRef = useRef([""]);
    const [CS, setCS] = useState([""]);
    const CSRef = useRef([""]);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [lastEmail, setLastEmail] = useState(null);
    const path = (due_date === "Onboarding" || due_date === "Documentation") ?
        due_date : due_date.includes(ADD_ON_LIMIT_CONST) ? due_date : moment(due_date).format("YYYY-MM");

    useEffect(() => {
        setStatusOptions(getStatusOptions());
        if (dataType === "monthly") {
            fetchMonthlyStatusInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [due_date]);

    useEffect(() => {
        if (dataType === "documentation") {
            fetchDocumentationStatusInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moderateRow]);

    const fetchDocumentationStatusInfo = () => {
        const {
            status,
            kyc_documents_status,
            authorized_signatory_status,
            company_authorization_status,
            additional_documents_status,
            comment,
            last_updated_at,
            last_email_sent_at
        } = moderateRow;
        setStatus(status ? status : "");
        statusRef.current = status ? status : "";
        let obj = initialStatusInfo();
        obj[1].status = kyc_documents_status ? kyc_documents_status : "";
        obj[2].status = authorized_signatory_status ? authorized_signatory_status : "";
        obj[3].status = company_authorization_status ? company_authorization_status : "";
        obj[4].status = additional_documents_status ? additional_documents_status : "";
        setStatusInfo(JSON.parse(JSON.stringify(obj)));
        statusInfoRef.current = JSON.parse(JSON.stringify(obj));
        setNote(comment ? comment : "");
        noteRef.current = comment ? comment : "";
        setLastUpdated(last_updated_at);
        setLastEmail(last_email_sent_at);
    }

    const fetchMonthlyStatusInfo = () => {
        if (due_date === "Onboarding") {
            updateMonthlyStatusInfo("Onboarding", onboardingData);
        }
        else {
            callApi(`fetch-monthly-onboarding-status/${investeeOnboardedId}?path=${path}`, "get")
                .then(res => {
                    if (res.status === "Success") {
                        updateMonthlyStatusInfo(due_date, res.data);
                    }
                });
        }
    }
    useEffect(() => {
        if (monthlyData[0].additionalRequiredDocuments) {
            setAddnInfo(monthlyData[0].additionalRequiredDocuments)
        }
    }, [monthlyData])

    const updateMonthlyStatusInfo = (category, data) => {
        let obj = initialStatusInfo();
        const {
            mis,
            financial_statement,
            invoicing,
            bank_account,
            status,
            comment,
            cp_additional_documents,
            cs_additional_documents,
            last_updated_at,
            last_email_sent_at,
            doc_comments,
            additionalReqDocuments,
        } = data;

        if (category === "Onboarding") {
            if (!_.isEmpty(bank_account)) {
                let currentNum = 6;
                Object.keys(bank_account).forEach((accountNumber, index) => {
                    currentNum = index + 6;
                    if (accountNumber !== ADD_BANK) {
                        obj[currentNum] = { ...bank_account[accountNumber] };
                        obj[currentNum].category = bank_account[accountNumber].bank_name ? 'Bank (' + bank_account[accountNumber].bank_name + ' - ' + accountNumber + ')' : null;
                    }
                });
                currentNum++;
                obj[currentNum] = {};
                obj[currentNum].category = ADD_BANK;
                obj[currentNum].status = bank_account[ADD_BANK];
            }
            if (!_.isEmpty(mis)) {
                obj[1] = JSON.parse(JSON.stringify(mis));
                obj[1].category = MIS;
            }
            if (financial_statement && "audited_financial_statement" in financial_statement && !_.isEmpty(financial_statement.audited_financial_statement)) {
                obj[2] = JSON.parse(JSON.stringify(financial_statement.audited_financial_statement));
                obj[2].category = AUD_FINANCIAL;
            }
            if (financial_statement && "provisional_financial_statement" in financial_statement && !_.isEmpty(financial_statement.provisional_financial_statement)) {
                obj[3] = JSON.parse(JSON.stringify(financial_statement.provisional_financial_statement));
                obj[3].category = PROV_FINANCIAL;
            }
            if (!_.isEmpty(invoicing)) {
                obj[4] = JSON.parse(JSON.stringify(invoicing));
                obj[4].category = INVOICING;
            }
            setStatusInfo(JSON.parse(JSON.stringify(obj)));
            statusInfoRef.current = JSON.parse(JSON.stringify(obj));
            if (status) {
                setStatus(status);
                statusRef.current = status;
            }
            if (comment) {
                setNote(comment);
                noteRef.current = comment;
            }
            if (!_.isEmpty(cp_additional_documents)) {
                setCP(cp_additional_documents);
                CPRef.current = cp_additional_documents;
            }
            if (!_.isEmpty(cs_additional_documents)) {
                setCS(cs_additional_documents);
                CSRef.current = cs_additional_documents;
            }
            setLastUpdated(last_updated_at);
            setLastEmail(last_email_sent_at);
        }
        else {
            const {
                status,
                mis_status,
                invoicing_status,
                bank_transactions_status,
                comment,
                cp_additional_documents,
                cs_additional_documents,
                last_updated_at,
                last_email_sent_at,
                doc_comments
            } = data;
            obj[1].status = bank_transactions_status ? bank_transactions_status : "";
            let bankStatementComments = doc_comments?.bank_statements ?? "";
            if (typeof doc_comments?.bank_statements === "object") {
                bankStatementComments = Object.keys(doc_comments?.bank_statements ?? {})
                    .map((key) => `${key}: ${doc_comments.bank_statements[key]}`)
                    .join("\n");
            }
            obj[1].comment = bankStatementComments;
            obj[2].status = mis_status ? mis_status : "";
            obj[2].comment = doc_comments && doc_comments.mis ? doc_comments.mis : "";
            obj[3].status = invoicing_status ? invoicing_status : "";
            obj[3].comment = doc_comments && doc_comments.invoicing ? doc_comments.invoicing : "";
            setStatusInfo(JSON.parse(JSON.stringify(obj)));
            statusInfoRef.current = JSON.parse(JSON.stringify(obj));
            if (status) {
                setStatus(status);
                statusRef.current = status;
            }
            if (comment) {
                setNote(comment);
                noteRef.current = comment;
            }
            if (!_.isEmpty(cp_additional_documents)) {
                setCP(cp_additional_documents);
                CPRef.current = cp_additional_documents;
            }
            if (!_.isEmpty(cs_additional_documents)) {
                setCS(cs_additional_documents);
                CSRef.current = cs_additional_documents;
            }
            setLastUpdated(last_updated_at);
            setLastEmail(last_email_sent_at);
        }
    }

    const renderSingleCategory = keyName => {
        const { category, status, comment, is_nach_enabled } = statusInfo[keyName];
        // const is_nach_enabled = "";
        if (category === ADD_BANK) {
            return (
                <span key={keyName} className='fs12 fw300 w-50'>
                    <input
                        type="checkbox"
                        id='addAccount'
                        checked={status}
                        onChange={e => isModerating && handleStatusInfoChange(e.target.checked, keyName, ADD_BANK)}
                        disabled={!isModerating}
                    />
                    <label htmlFor='addAccount' className='ml-2'><i>Ask to add another bank account</i></label>
                </span>
            );
        }
        if (isModerating) {
            return (
                <span key={keyName}>
                    <div className='dflex-ja'>
                        <div className='fs12 fw500 w-50'>{category}</div>
                        <div className='fs10 fw300 w-50 dflex-ja'>
                            {keyName >= 6 && <span className='mr-5 dflex-ac'>
                                <input
                                    type='checkbox'
                                    className='mr-2'
                                    checked={is_nach_enabled === true}
                                    onChange={e => handleStatusInfoChange(e.target.checked, keyName, IS_NACH)}
                                />
                                <span><i>NACH</i></span>
                            </span>}
                            <span className='mr-5 dflex-ac'>
                                <input
                                    type='checkbox'
                                    className='mr-2'
                                    checked={status === APPROVED}
                                    onChange={e => handleStatusInfoChange(e.target.checked, keyName, APPROVED)}
                                />
                                <span><i>Approve</i></span>
                            </span>
                            <span className='dflex-ac'>
                                <input
                                    type='checkbox'
                                    className='mr-2'
                                    checked={status === NEEDINFO}
                                    onChange={e => handleStatusInfoChange(e.target.checked, keyName, NEEDINFO)}
                                />
                                <span><i>Need more Info</i></span>
                            </span>
                        </div>
                    </div>
                    <DynamicValue
                        placeholder={status === NEEDINFO && 'Enter Comments'}
                        required
                        disabled={status !== NEEDINFO}
                        value={comment}
                        onChange={e => handleStatusInfoChange(e.target.value, keyName)}
                    />
                    <br /><br />
                </span>
            );
        }
        return (
            <span key={keyName}>
                <div className='dflex-ja'>
                    <div className='fs12 fw500 w-50'>{category}</div>
                    <div className='fs10 fw300 w-50 dflex-ja'>
                        {keyName >= 6 && <span className='mr-3 dflex-ac'>
                            <input
                                type='checkbox'
                                className='mr-2'
                                checked={is_nach_enabled === true}
                                disabled
                            />
                            <span><i>NACH</i></span>
                        </span>}
                        <span className='dflex-ac'>
                            <input
                                type='checkbox'
                                className='mr-2'
                                checked={status === APPROVED}
                                disabled
                            />
                            <span><i>Approve</i></span>
                        </span>
                        <span className='dflex-ac'>
                            <input
                                type='checkbox'
                                className='mr-2'
                                checked={status === NEEDINFO}
                                disabled
                            />
                            <span><i>Need more Info</i></span>
                        </span>
                    </div>
                </div>
                <DynamicValue
                    required
                    disabled
                    value={comment}
                />
                <br /><br />
            </span>
        );
    }

    const renderStatus = () => {
        if (isModerating) {
            return (
                <div className='dflex-ja'>
                    <div className='fs12 fw500 w-25'>Status:</div>
                    <div className='w-75'>
                        <SelectBox value={status} onChange={e => setStatus(e.target.value)}>
                            <option value={status} disabled hidden>{status.trim() ? status : "Select status"}</option>
                            {statusOptions.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </SelectBox>
                    </div>
                </div>
            );
        }
        return (
            <div className='dflex-ja'>
                <div className='fs12 fw500 w-25'>Status:</div>
                <div className='w-75'>
                    <DynamicValue
                        required
                        disabled
                        value={status}
                    />
                </div>
            </div>
        );
    }

    const renderTextBox = (state, setState) => {
        const handleChange = (val, index) => {
            if (state instanceof Array) {
                const arr = [...state];
                arr[index] = val;
                setState(arr);
            }
            else {
                setState(val);
            }
        }
        if (state instanceof Array) {
            return state.map((item, index) => (
                <div
                    key={index}
                    className={isModerating ?
                        "Bgmoderate fs12 fw300 mt-2"
                        :
                        "BgGreyBorderDark fs12 fw300 mt-2"
                    }
                >
                    <input
                        style={{ height: '100%', width: '100%', border: 'none' }}
                        value={item}
                        disabled={!isModerating}
                        onChange={e => handleChange(e.target.value, index)}
                        autoFocus
                    />
                </div>
            ));
        }
        else {
            if (isModerating) {
                return (
                    <textarea
                        className={isModerating ?
                            "Bgmoderate w-75 fs12 fw300"
                            :
                            "BgGreyBorderDark w-75 fs12 fw300"
                        }
                        style={{ height: '83px' }}
                        disabled={!isModerating}
                        onChange={e => handleChange(e.target.value)}
                        onKeyDown={e => {
                            if (e.key === 'Enter') handleChange('\n');
                        }}
                        value={state}
                    />
                );
            }
            else {
                return (
                    <div
                        className="BgGreyBorderDark w-75 fs12 fw300"
                        style={{ height: '83px', overflowY: 'scroll' }}
                    >
                        {state.split("\n").map((i, key) => {
                            return <div key={key}>{i}</div>;
                        })}
                    </div>
                )
            }
        }
    }

    const renderMain = () => {
        return (
            <div className='row'>
                <div className='col-lg-6'>
                    <div className='light-round-border p-4'>
                        {Object.keys(statusInfo).map(item => renderSingleCategory(item))}
                    </div>
                    <AdditionalInfo isModerating={isModerating} setIsAddnInfo={setIsAddnInfo} addnInfo={addnInfo} setAddnInfo={setAddnInfo} renderStatus={renderStatus} />
                </div>
                <div className='col-lg-6'>
                    <div className='light-round-border p-4 mr-4'>
                        {renderStatus()}
                        <br />
                        <div className='dflex-ja'>
                            <div className='fs12 fw500 w-25'>Note:</div>
                            {renderTextBox(note, setNote)}
                        </div>
                        <br />
                        {dataType === "monthly" && due_date === "Onboarding" && (
                            <>
                                <div className='dflex-ja'>
                                    <div className='fs12 fw500 w-25'>CP:</div>
                                    <div className='w-75'>{renderTextBox(CP, setCP)}</div>
                                </div>
                                {isModerating && (
                                    <div className="add-cp mb-3">
                                        <span onClick={() => addMore(CP, setCP)}>
                                            <img src="/assets/BluePlus.svg" alt="plus" />
                                            <span>Add CP</span>
                                        </span>
                                    </div>
                                )}
                                <div className='dflex-ja'>
                                    <div className='fs12 fw500 w-25'>CS:</div>
                                    <div className='w-75'>{renderTextBox(CS, setCS)}</div>
                                </div>
                                {isModerating && (
                                    <div className="add-cp">
                                        <span onClick={() => addMore(CS, setCS)}>
                                            <img src="/assets/BluePlus.svg" alt="plus" />
                                            <span>Add CS</span>
                                        </span>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    {!isModerating && (
                        <TradeTerms
                        />
                    )}
                </div>
            </div>
        );
    }

    const disableEmail = () => {
        if (dataType === "monthly" && due_date === "Onboarding") {
            return (status === "Approved" || status === "Spark Approved") && !emailCheck;
        }
        else if (dataType === "documentation") {
            return status !== "Need Information";
        }
    }

    const disableSave = () => {
        if (dataType === "monthly" && due_date === "Onboarding") {
            return (status === "Approved" || status === "Spark Approved") && !emailCheck;
        }
    }

    const renderButtons = () => {
        if (isModerating) {
            return (
                <>
                    <button
                        className="mr-4 btn6 btnht1 d-flex align-items-center justify-content-center"
                        onClick={onCancel}
                    >
                        <img
                            src="../../../../../assets/icon-clear.svg"
                            alt="cross"
                            className="mr-2"
                            style={{ width: '11px' }}
                        />
                        <span>Cancel</span>
                    </button>
                    <button
                        className="save-btn btn5 btnht1 d-flex align-items-center justify-content-center"
                        style={disableSave() ? { cursor: 'not-allowed' } : null}
                        onClick={onSave}
                        disabled={disableSave()}
                    >
                        <SaveSVG />
                        <span>Save</span>
                    </button>
                </>
            );
        } else {
            return (
                <>
                    <EmailUserBtn onClick={emailUser} disabled={disableEmail()}>
                        <img src="/assets/email.svg" alt="Email" />
                        <span>Email User</span>
                    </EmailUserBtn>
                    <ModerateBtn onClick={() => setIsModerating(true)}>
                        <img className="mr-1" src="/assets/moderate-btn-icon.svg" alt="Moderate" />{' '}
                        <span>Moderate</span>
                    </ModerateBtn>
                </>
            );
        }
    };

    const handleStatusInfoChange = (val, keyName, statusCategory) => {
        if (isModerating) {
            if (statusCategory === ADD_BANK) {
                const obj = { ...statusInfo };
                obj[keyName].status = val;
                setStatusInfo(obj);
            }
            else if (statusCategory === IS_NACH) {
                const obj = { ...statusInfo };
                obj[keyName].is_nach_enabled = val;
                setStatusInfo(obj);
            }
            else if (statusCategory) {
                const obj = { ...statusInfo };
                if (val) {
                    obj[keyName].status = statusCategory;
                    if (statusCategory === APPROVED && obj[keyName].comment) obj[keyName].comment = "";

                }
                else obj[keyName].status = "";
                setStatusInfo(obj);
            }
            else {
                const obj = { ...statusInfo };
                obj[keyName].comment = val;
                setStatusInfo(obj);
            }
        }
    }

    const emailUser = async () => {
        if(status===WAITLISTED){
            const res = await sendWaitlistedEmailHelper(investeeOrganizationId,IDENTIFIER_TYPE.ONBOARDING,investeeOnboardedId)
            if(res.responseData.responseCode === 20){
                setLastEmail(moment(res?.lastEmailSentDate).format('HH:MM DD-MMM-YYYY'))
                showNotification(SHOW_NOTIFICATION.SUCCESS,res?.responseData.responseMessage??'Email sent successfully')
            }
            else{
                showNotification(SHOW_NOTIFICATION.ERROR,res?.responseData.responseMessage??"Couldn't send Email!")
            }
        }
        let endpoint;
        if (dataType === "monthly") {
            endpoint = `v2/investee-onboarding/${investeeOnboardedId}/email-user?path=${path}`;
        }
        else if (dataType === "documentation") {
            endpoint = `/admin/onboarding-phase2/${investeeOnboardedId}/need_info_mail`;
        }
        callApi(endpoint, "get").then(res => {
            if (res.status === "Success") {
                showNotification("Success", "Email sent successfully");
                if (dataType === "monthly") {
                    fetchMonthlyStatusInfo();
                }
                else if (dataType === "documentation") {
                    setLastEmail(res.data?.last_email_sent_at);
                }
            }
            else {
                const message = res.data?.message ? res.data?.message : "Some error occurred!";
                showNotification("Error", message);
            }
        });
    }

    const onCancel = () => {
        if (showReqStatusOptions) setShowReqStatusOptions(false);
        if (!_.isEqual(statusInfo, statusInfoRef.current)) setStatusInfo(JSON.parse(JSON.stringify(statusInfoRef.current)));
        if (status !== statusRef.current) setStatus(statusRef.current);
        if (note !== noteRef.current) setNote(noteRef.current);
        if (!_.isEqual(CP, CPRef.current)) setCP(CPRef.current);
        if (!_.isEqual(CS, CSRef.current)) setCS(CSRef.current);
        setIsModerating(false);
    }

    const onSave = () => {
        if (status === APPROVED || status === APPROVED_RUNAWAY) {
            const mandatory_investee_fields = ["display_name", "company_sector", "company_sub_sector", "founded_in", "funding", "company_overview"];
            const mandatory_onboarding_details = ["cin_number", "pan_number", "gstin_number", "company_website", "current_company_address"];
            const absent_mandatory_fields = [];
            for (const field of mandatory_investee_fields) {
                const present = field in investee_details;
                if (present && isEmpty(investee_details[field])) absent_mandatory_fields.push(field);
                else if (!present) absent_mandatory_fields.push(field);
            }
            for (const field of mandatory_onboarding_details) {
                const present = field in onboardingData;
                if (present && isEmpty(onboardingData[field])) absent_mandatory_fields.push(field);
                else if (!present) absent_mandatory_fields.push(field);
            }
            if (!isEmpty(absent_mandatory_fields)) {
                absent_mandatory_fields.forEach((field, index) => {
                    switch (field) {
                        case "display_name":
                            absent_mandatory_fields[index] = "Display Name"
                            break;
                        case "company_sector":
                            absent_mandatory_fields[index] = "Company Sector"
                            break;
                        case "company_sub_sector":
                            absent_mandatory_fields[index] = "Company Sub Sector"
                            break;
                        case "founded_in":
                            absent_mandatory_fields[index] = "Founded In"
                            break;
                        case "funding":
                            absent_mandatory_fields[index] = "Funding"
                            break;
                        case "company_overview":
                            absent_mandatory_fields[index] = "Company Overview"
                            break;
                        case "website_url":
                            absent_mandatory_fields[index] = "Website Url"
                            break;
                        case "pan_card":
                            absent_mandatory_fields[index] = "Pan Card"
                            break;
                        case "company_cin":
                            absent_mandatory_fields[index] = "Company Cin"
                            break;
                        case "address":
                            absent_mandatory_fields[index] = "Address"
                            break;
                        case "cin_number":
                            absent_mandatory_fields[index] = "Cin Number"
                            break;
                        case "pan_number":
                            absent_mandatory_fields[index] = "Pan Number";
                            break;
                        case "gstin_number":
                            absent_mandatory_fields[index] = "Gstin Number";
                            break;
                        case "company_website":
                            absent_mandatory_fields[index] = "Company Website"
                            break;
                        case "current_company_address":
                            absent_mandatory_fields[index] = "Current Company address"
                            break;
                        default:
                            absent_mandatory_fields[index] = field
                    }
                })
            }
            const notification_fields = absent_mandatory_fields.join(', ');
            if (!isEmpty(notification_fields)) {
                showNotification("Error", `Please fill ${notification_fields}`);
                return;
            }
        }
        let endpoint;
        const emptyStrRemover = (state, setState) => {
            const arr = state.filter(item => item.trim() !== "");
            setState(arr);
            return arr;
        }
        const obj = {
            status,
            comment: note
        }
        if (due_date === "Onboarding") {
            const CPArr = emptyStrRemover([...CP], setCP);
            const CSArr = emptyStrRemover([...CS], setCS);
            obj.cp_additional_documents = CPArr;
            obj.cs_additional_documents = CSArr;
        }
        if (dataType === "monthly") {
            if (due_date === "Onboarding") {
                endpoint = `investee-onboarding/v2/${investeeOnboardedId}/moderate?path=Onboarding`;
                obj.bank_account = {};
                Object.keys(statusInfo).forEach(key => {
                    const tempObj = { ...statusInfo[key] };
                    switch (key) {
                        case '1':
                            obj.mis = { ...tempObj };
                            break;
                        case '2':
                            obj.financial_statement = {};
                            obj.financial_statement.audited_financial_statement = { ...tempObj };
                            break;
                        case '3':
                            obj.financial_statement.provisional_financial_statement = { ...tempObj };
                            break;
                        case '4':
                            obj.invoicing = { ...tempObj };
                            break;
                        default:
                            if (tempObj.category === ADD_BANK) {
                                obj.bank_account[ADD_BANK] = tempObj.status;
                            }
                            else {
                                if (!tempObj.status) tempObj.status = "Open";
                                obj.bank_account[tempObj.account_number] = { ...tempObj };
                            }
                    }
                });
            }
            else {
                const number_of_bank_accounts = Object.keys(monthlyViewData?.availableBankTrxMonthlyDates ?? {})?.length;
                obj.doc_comments = {};
                if (number_of_bank_accounts) {
                    obj.bank_transactions_status = {}
                    obj.doc_comments.bank_statements = {}
                    for (let account in monthlyViewData?.availableBankTrxMonthlyDates) {
                        obj.bank_transactions_status[account] = statusInfo[1].status ? statusInfo[1].status : "Open";
                        obj.doc_comments.bank_statements[account] = statusInfo[1].comment ? statusInfo[1].comment : "";
                    }
                } else {
                    obj.bank_transactions_status = statusInfo[1].status ? statusInfo[1].status : "Open";
                    obj.doc_comments.bank_statements = statusInfo[1].comment ? statusInfo[1].comment : "";
                }

                endpoint = `moderate/${investeeOnboardedId}?path=${path}`;
                obj.mis_status = statusInfo[2].status ? statusInfo[2].status : "Open";
                obj.invoicing_status = statusInfo[3].status ? statusInfo[3].status : "Open";
                obj.doc_comments.mis = statusInfo[2].comment ? statusInfo[2].comment : "";
                obj.doc_comments.invoicing = statusInfo[3].comment ? statusInfo[3].comment : "";
            }
        }
        else if (dataType === "documentation") {
            endpoint = `admin/investee-kyc-application/${investeeOnboardedId}/moderate`;
            obj.kyc_documents_status = statusInfo[1].status ? statusInfo[1].status : "Open";
            obj.authorized_signatory_status = statusInfo[2].status ? statusInfo[2].status : "Open";
            obj.company_authorization_status = statusInfo[3].status ? statusInfo[3].status : "Open";
            obj.additional_documents_status = statusInfo[4].status ? statusInfo[4].status : "Open";
        }
        if (isAddnInfo) {
            obj.additionalRequiredDocuments = addnInfo;
        }
        callApi(endpoint, "post", obj).then(res => {
            if (res.status === "Success") {
                showNotification("Success", "Saved successfully");
                if (dataType === "monthly") {
                    updateMonthlyStatusInfo(path, res.data);
                    fetchMonthlyData();
                }
                else if (dataType === "documentation") {
                    fetchDocumentationData(true);
                }
            }
            else {
                showNotification("Error", res?.data?.message);
            }
        }).catch(() => {
            showNotification("Error", "Some error occurred!");
        });
        setIsModerating(false);
    }

    const addMore = (state, setState) => {
        const arr = [...state];
        arr.push("");
        setState(arr);
    }

    return (
        <div className="modal fade" id="Moderation-Comment" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content shadowBorder">
                    <div className="modal-header">
                        <div className='fs16 fw600'>Moderation Comments</div>
                        <button type="button" className="close" data-dismiss="modal">
                            <img src="../../../../../assets/modal-cross.svg" alt=""></img>
                        </button>
                    </div>
                    <div className="modal-body pb-4 pt-0">
                        {renderMain()}
                    </div>
                    <div className="modal-footer">
                        <div className='row w-100 m-0'>
                            <div className='col-lg-6'>
                                <div className='dflex-ac'>
                                    <span className='fs10 fw300 mr-4'>
                                        <span><b>Last Update: </b></span>{lastUpdated ? moment(lastUpdated).format("HH:mm, DD MMM YYYY") : "NA"}
                                    </span>
                                    <span className='fs10 fw300'>
                                        <span><b>Last email: </b></span>{lastEmail ? moment(lastEmail).format("HH:mm, DD MMM YYYY") : "NA"}
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='d-flex align-items-center justify-content-end'>
                                    {renderButtons()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
