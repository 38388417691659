import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  CURRENT_TAB_DATA_TYPE,
  CURRENT_TAB_TYPE,
  lastFetchDetailsToTabTypeMapping,
} from "../../../../../../../../../../common/ENUM";
import callApi from "../../../../../../../../../../util/apiCaller";
import {
  downloadFileUsingFetch,
  showNotification,
} from "../../../../../../../../../../util/utility";
import {
  fetchUnderwritingData,
  postUnderwritingEditedData,
  POSTING_UNDERWRITING_EDITED_DATA,
  updateInvoiceFrequencies,
  updateBankStatementsClassification,
  updateGstClassification,
  updateContracts,
  refreshSourceData,
  syncInsights,
  triggerClassificationModel,
  triggerBankDetails,
  updateGst,
} from "../../../../../../../../../App/AppActions";
import { getIsPostingUnderwritingEditedData } from "../../../../../../../../../App/AppReducer";
import StatusBarClassified from "./StatusBarClassified";

const Blue = styled("span")`
  display: flex;
  align-items: center;
  color: #4c8dff;
  font-size: 13px;
  margin-right: 20px;
  cursor: pointer;
`;

export default function DataStage(props) {
  const EXTNS = {
    "CSV": ".csv",
    "Excel": ".xlsx"
  };
  const dispatch = useDispatch();
  const [isExporting, setIsExporting] = useState(false);
  const [isExportingAll, setIsExportingAll] = useState(false);
  const [showUploadOptions, setShowUploadOptions] = useState(false);
  const wrapperRef = useRef(null);
  const underwritingEditedItems = {};
  underwritingEditedItems["classifications"] = props.editedItems;

  const ispostingUnderwritingEditedData = useSelector((state) =>
    getIsPostingUnderwritingEditedData(state)
  );
  const companyName = useSelector(
    (state) => state.admin?.investee_organization?.name
  );

  useEffect(() => {
    /**
     * if clicked on outside of element
     */
    function handleClickOutside(e) {
      // if (showUploadOptions) setShowUploadOptions(false);
      if (wrapperRef.current && !wrapperRef.current.contains(e.target)) setShowUploadOptions(false);
    }
    if (showUploadOptions) {
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
    }
  }, [showUploadOptions]);

  const handleClick = () => {
    dispatch(
      fetchUnderwritingData(
        props.investeeOrganization._id,
        props.currentTab,
        props.dataType,
        props.dates.start_date,
        props.dates.end_date,
        props.investeeOrganization.investee_onboarded_id
      )
    );
  };
  const LAST_FETCH_DETAILS_KEY  = lastFetchDetailsToTabTypeMapping[props.currentTab];


  const getEndPoint = (startDate, endDate) => {
    if (props.currentTab === CURRENT_TAB_TYPE.invoices)
      return `invoices/${props.dataType}?investeeOrgId=${props.investeeOrganization._id}&csv=true`;
    if (props.currentTab === CURRENT_TAB_TYPE.bank_statements)
      return `bank/bank_stmt/${props.dataType}?investeeOrgId=${props.investeeOrganization._id}&csv=true`;
    if (
      props.currentTab === CURRENT_TAB_TYPE.GST &&
      props.dataType === CURRENT_TAB_DATA_TYPE.pivot
    )
      return `invoices/classified?investeeOrgId=${props.investeeOrganization._id}&gst=true&csv=true`;
    if (
      props.currentTab === CURRENT_TAB_TYPE.GST &&
      props.dataType === CURRENT_TAB_DATA_TYPE.spread
    )
      return `invoices/${props.dataType}?investeeOrgId=${props.investeeOrganization._id}&gst=true&csv=true`;
    if (props.currentTab === CURRENT_TAB_TYPE.GST)
      return `invoices/raw/${props.dataType}?investeeOrgId=${props.investeeOrganization._id}&csv=true`;
    if (props.currentTab === CURRENT_TAB_TYPE.contracts)
      return `${props.dataType}?investeeOrgId=${props.investeeOrganization._id}&csv=true`;
    if(props.currentTab === CURRENT_TAB_TYPE.debt_schedule)
      return `debt/debt_schedule/raw?investeeOrgId=${props.investeeOrganization._id}&csv=true`
    return `mis/${props.currentTab}/${props.dataType}?investeeOrgId=${props.investeeOrganization._id}&startDate=${startDate}&endDate=${endDate}&csv=true`;
  };
  const downloadFile = () => {
    setIsExporting(true);
    const startDate = moment(props.dates.start_date, "YYYY-MM-DD").format(
      "MM-DD-YYYY"
    );
    const endDate = moment(props.dates.end_date, "YYYY-MM-DD").format(
      "MM-DD-YYYY"
    );
    const endpoint = getEndPoint(startDate, endDate);
    downloadFileUsingFetch(
      endpoint,
      "get",
      { responseType: "blob" },
      `${companyName}-underwriting-${props.currentTab}.csv`,
      () => {
        setIsExporting(false);
      },
      true
    );
  };

  const downloadAllFile = () => {
    setIsExportingAll(true);
    downloadFileUsingFetch(
      `mis/download?investeeOrgId=${props.investeeOrganization._id}&dataType=${props.dataType}`,
      "get",
      { responseType: "blob" },
      `underwriting-all-files.zip`,
      () => {
        setIsExportingAll(false);
      },
      true
    );
  };

  const handleSaveButton = async () => {
    if (props.currentTab === CURRENT_TAB_TYPE.invoices) {
      if (props.editedItems.length) {
        dispatch({
          type: POSTING_UNDERWRITING_EDITED_DATA,
          isposting_underwriting_edited_data: true,
        });
        const status = await updateInvoiceFrequencies(props.investeeOrganization._id, props.editedItems);
        dispatch({
          type: POSTING_UNDERWRITING_EDITED_DATA,
          isposting_underwriting_edited_data: false,
        });
        if (status) {
          props.setEditInvoiceFrequency(false);
          props.setEditedItems([]);
          dispatch(
            fetchUnderwritingData(
              props.investeeOrganization._id,
              props.currentTab,
              props.dataType,
              props.dates.start_date,
              props.dates.end_date,
              props.investeeOrganization.investee_onboarded_id
            )
          );
        }
      } else showNotification("Error", "No updates to save");
    } else if (props.currentTab === CURRENT_TAB_TYPE.bank_statements) {
      props.setSaveScroll(false);
      if (props.editedItems.length > 0) {
        dispatch({
          type: POSTING_UNDERWRITING_EDITED_DATA,
          isposting_underwriting_edited_data: true,
        });
        const status = await updateBankStatementsClassification(props.investeeOrganization._id,
          props.editedItems
        );
        dispatch({
          type: POSTING_UNDERWRITING_EDITED_DATA,
          isposting_underwriting_edited_data: false,
        });
        if (status) {
          props.setEditBankClassification(false);
          props.setEditedItems([]);
          dispatch(
            fetchUnderwritingData(
              props.investeeOrganization._id,
              props.currentTab,
              props.dataType,
              props.dates.start_date,
              props.dates.end_date,
              props.investeeOrganization.investee_onboarded_id,
              true,
              props.investeeOrganization.finbox_entity_id
            )
          );
        }
      } else showNotification("Error", "No updates to save");
    } else if (props.currentTab === CURRENT_TAB_TYPE.contracts) {
      if (props.editedItems.length > 0 || !isEmpty(props?.editedContractThreshold)) {
        dispatch({
          type: POSTING_UNDERWRITING_EDITED_DATA,
          isposting_underwriting_edited_data: true,
        });
        let contractsPayload = {
          threshold: !isEmpty(props?.editedContractThreshold) ? props?.editedContractThreshold : props?.getContractThresholdFromState,
          contracts: props.editedItems
        }
        const status = await updateContracts(
          contractsPayload,
          props.investeeOrganization._id
        );
        dispatch({
          type: POSTING_UNDERWRITING_EDITED_DATA,
          isposting_underwriting_edited_data: false,
        });
        if (status) {
          props.setEditContracts(false);
          props.setEditedItems([]);
          dispatch(
            fetchUnderwritingData(
              props.investeeOrganization._id,
              props.currentTab,
              props.dataType,
              props.dates.start_date,
              props.dates.end_date,
              props.investeeOrganization.investee_onboarded_id
            )
          );
        }
      } else showNotification("Error", "No updates to save");
    } else if (props.currentTab === CURRENT_TAB_TYPE.GST) {
      props.setSaveScroll(false);
      if (props.editedItems.length > 0) {
        dispatch({
          type: POSTING_UNDERWRITING_EDITED_DATA,
          isposting_underwriting_edited_data: true,
        });
        const status = await updateGstClassification(props.investeeOrganization._id,
          props.editedItems
        );
        dispatch({
          type: POSTING_UNDERWRITING_EDITED_DATA,
          isposting_underwriting_edited_data: false,
        });
        if (status) {
          props.setEditClassification(false);
          props.setEditedItems([]);
          dispatch(
            fetchUnderwritingData(
              props.investeeOrganization._id,
              props.currentTab,
              props.dataType,
              props.dates.start_date,
              props.dates.end_date,
              props.investeeOrganization.investee_onboarded_id,
              true,
              props.investeeOrganization.finbox_entity_id
            )
          );
        }
      } else showNotification("Error", "No updates to save");
    }else {
      if (underwritingEditedItems.classifications.length) {
        dispatch(
          postUnderwritingEditedData(
            props.investeeOrganization._id,
            props.currentTab,
            props.dataType,
            props.dates.start_date,
            props.dates.end_date,
            underwritingEditedItems,
            props.setEditClassification,
            props.setEditedItems,
            props.setIsSaving
          )
        );
      } else showNotification("Error", "No updates to save");
    }
  };

  const classifyData = async () => {
    const response = await triggerClassificationModel(
      props.investeeOrganization._id
    );
    if (response) {
      dispatch(
        fetchUnderwritingData(
          props.investeeOrganization._id,
          props.currentTab,
          props.dataType,
          props.dates.start_date,
          props.dates.end_date,
          props.investeeOrganization.investee_onboarded_id
        )
      );
    }
  };

  const onEditClassification = () => {
    if (
      props.currentTab !== CURRENT_TAB_TYPE.invoices &&
      props.currentTab !== CURRENT_TAB_TYPE.bank_statements
    ) {
      props.setEditClassification(true);
      props.dataType === "classified" &&
        dispatch(
          fetchUnderwritingData(
            props.investeeOrganization._id,
            props.currentTab,
            "raw",
            props.dates.start_date,
            props.dates.end_date,
            props.investeeOrganization.investee_onboarded_id
          )
        );
    } else if (props.currentTab === CURRENT_TAB_TYPE.bank_statements) {
      props.setEditBankClassification(true);
    }
    else if (props.currentTab === CURRENT_TAB_DATA_TYPE.gst){
      props.setEditClassification(true);
    }
  };

  const styleUploadDialogBox = () => {
    return {
      position: "absolute",
      zIndex: "10",
      backgroundColor: "#ffffff",
      top: "25px",
      right: "-68px",
      width: "125px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.147918)",
      borderRadius: "2px",
      padding: "10px 0px 10px 9px",
      fontFamily: "Gilroy-Semibold",
    };
  };

  const handleChange = (e, ext) => {
    if (props.currentTab === CURRENT_TAB_TYPE.invoices) {
      if (props.dataType === CURRENT_TAB_DATA_TYPE.spread) {
        if (ext === ".csv") props.handleInvoiceSpreadUpload(e);
        else props.handleInvoiceUpload(e, ext);
      } else {
        props.handleInvoiceUpload(e, ext);
      }
    } else {
      if (ext === ".csv") props.handleMisUpload(e);
      else props.handleInvoiceUpload(e, ext);
    }
  };

  const updateDataHandler = async () => {
    let response = true;
    if (props.currentTab === CURRENT_TAB_TYPE.bank_statements) response = await triggerBankDetails(props.investeeOrganization._id, props.investeeOrganization.finbox_entity_id)
    else if (props.currentTab === CURRENT_TAB_TYPE.GST) response = await updateGst(props.investeeOrganization._id)
    else response = await refreshSourceData(props.investeeOrganization._id);

    if(response) {
      props.getLastFetchedDetailsData();
    }
  }

  const onUploadClick = (extnLabel) => {
    document.getElementById(extnLabel).click();
  }

  const ShowUploadTypeOptions = () => {
    return (
      <div ref={wrapperRef} style={styleUploadDialogBox()}>
        {Object.keys(EXTNS).map((extnLabel) => (
          <div key={extnLabel}>
            <input
              type="file"
              id={extnLabel}
              accept={EXTNS[extnLabel]}
              onChangeCapture={(e) => handleChange(e, EXTNS[extnLabel])}
              hidden
            />
            <Blue style={{ marginBottom: "4px" }} onClick={() => onUploadClick(extnLabel)}>Upload as {extnLabel}</Blue>
          </div>
        ))}
      </div>
    );
  };


  return (
    <div style={{ display: "block", marginLeft: "auto", position: "absolute", right: 0, bottom: 0, zIndex: 5 }}>
      <div className="p-3 mb-0 w32 mt-0 ml-auto d-flex flex-column" style={{ width: "100%" }}>
        <div style={{alignSelf:"flex-end"}}>
          <p style={{ margin: "0px -8px 2px 0" }}>
            <span className="last-updated-on">Last Fetched : &nbsp;</span>
            <span className="last-updated-on-date">
              {props.lastFetchedData?.lastFetchDate?.[LAST_FETCH_DETAILS_KEY] 
                ? `On ${props.lastFetchedData?.lastFetchDate?.[LAST_FETCH_DETAILS_KEY]} via ${props.lastFetchedData?.lastFetchSource?.[LAST_FETCH_DETAILS_KEY]}`
                : "N/A"
              }
            </span>
          </p>
        </div>
        <div className="d-flex justify-content-end align-items-center flex-wrap">
          <Blue
            onClick={updateDataHandler}
          >
            <span>
              <img
                src="/assets/Icon_refresh@1.5x.svg"
                alt=""
                style={{
                  height: "12.5px",
                  marginRight: "3.5px",
                  color: "blue",
                }}
              />
            </span>
            Update Data
          </Blue>
          <Blue
            onClick={() => syncInsights(props.investeeOrganization._id)}
          >
            <span>
              <img
                src="/assets/Icon_refresh@1.5x.svg"
                alt=""
                style={{
                  height: "12.5px",
                  marginRight: "3.5px",
                  color: "blue",
                }}
              />
            </span>
            Sync Insights
          </Blue>
          <Blue onClick={() => classifyData()}>
            <span>
              <img
                src="/assets/Icon_refresh@1.5x.svg"
                alt=""
                style={{
                  height: "12.5px",
                  marginRight: "3.5px",
                  color: "blue",
                }}
              />
            </span>
            Classify Data
          </Blue>
          <Blue onClick={downloadAllFile}>
            {!isExportingAll ? (
              <span>
                <img
                  src="/assets/download-dv.svg"
                  alt=""
                  style={{ height: "12.5px", marginRight: "3.5px" }}
                />
              </span>
            ) : (
              <span id="loading-spinner">
                <img
                  className="spin-icon"
                  style={{ marginRight: "8px" }}
                ></img>
              </span>
            )}
            Export-All
          </Blue>
          <div style={{ marginRight: "10px" }}>
            <input
              style={{ width: "107px" }}
              className="filter-value"
              type="date"
              id="start-date"
              name="comp-name"
              onChange={(e) =>
                props.handleDateChange("start_date", e.target.value)
              }
              value={props.dates?.start_date}
            ></input>
          </div>
          <span className="to" style={{ marginRight: "10px" }}>
            To
          </span>
          <div style={{ marginRight: "10px" }}>
            <input
              style={{ width: "107px" }}
              className="filter-value"
              type="date"
              id="end-date"
              name="comp-name"
              onChange={(e) =>
                props.handleDateChange("end_date", e.target.value)
              }
              value={props.dates.end_date}
            ></input>
          </div>
          <div style={{ marginRight: "3px" }}>
            <button
              className="date-go-btn d-flex align-items-center"
              onClick={handleClick}
            >
              <span className="go">Go</span>
              <span>
                <img src="/assets/Icon_arrow-forward@1x.svg" alt="" />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center flex-wrap mb-2">
        {!props.editClassification &&
          !props.editInvoiceFrequency &&
          !props.editBankClassification &&
          !props.editContracts && (
            <>
              <Blue onClick={onEditClassification}>
                <span
                  className={
                    props.currentTab === CURRENT_TAB_TYPE.invoices &&
                    "edit-classification-disabled"
                  }
                  hidden={
                    props.currentTab === CURRENT_TAB_TYPE.invoices ||
                    (props.currentTab === CURRENT_TAB_TYPE.bank_statements &&
                      props.dataType !== "raw") ||
                    (props.currentTab === CURRENT_TAB_TYPE.GST && props.dataType !== "gst")||
                    props.currentTab === CURRENT_TAB_TYPE.contracts || props.currentTab === CURRENT_TAB_TYPE.debt_schedule
                  }
                >
                  <span>
                    <img
                      src="/assets/create.png"
                      alt=""
                      style={{ height: "12.5px", marginRight: "3.5px" }}
                    />
                  </span>
                  Edit Classification
                </span>
              </Blue>
              <Blue
                onClick={() => {
                  if (
                    (props.currentTab === CURRENT_TAB_TYPE.invoices ||
                      props.currentTab === CURRENT_TAB_TYPE.bank_statements) &&
                    props.dataType === "raw"
                  ) {
                    props.setEditInvoiceFrequency(true);
                  }
                }}
                hidden={
                  props.currentTab !== CURRENT_TAB_TYPE.invoices ||
                  (props.currentTab === CURRENT_TAB_TYPE.invoices &&
                    props.dataType !== "raw") ||
                  props.currentTab === CURRENT_TAB_TYPE.bank_statements ||
                  props.currentTab === CURRENT_TAB_TYPE.GST
                }
              >
                <span
                  className={
                    props.currentTab === CURRENT_TAB_TYPE.invoices &&
                    props.dataType !== "raw" &&
                    "edit-classification-disabled"
                  }
                >
                  <span>
                    <img
                      src="/assets/create.png"
                      style={{ height: "12.5px", marginRight: "3.5px" }}
                      alt="create"
                    />
                  </span>
                  Edit Invoices
                </span>
              </Blue>

              <Blue
                onClick={() => {
                  props.setEditContracts(true);
                }}
                hidden={props.currentTab !== CURRENT_TAB_TYPE.contracts}
              >
                <span>
                  <span>
                    <img
                      src="/assets/create.png"
                      style={{ height: "12.5px", marginRight: "3.5px" }}
                      alt="create"
                    />
                  </span>
                  Edit Contracts
                </span>
              </Blue>

              {/* burn projection */}
              <Blue
                onClick={() => {
                  props.setBurnClicked(!props.burnClicked);
                }}
              >
                <span
                  hidden={
                    props.currentTab != CURRENT_TAB_TYPE.bank_statements ||
                    (props.currentTab == CURRENT_TAB_TYPE.bank_statements &&
                      (props.dataType === CURRENT_TAB_DATA_TYPE.classified ||
                        props.dataType === CURRENT_TAB_DATA_TYPE.excel_report))
                  }
                >
                  <span>
                    <img
                      src="/assets/create.png"
                      alt="create"
                      style={{ height: "12.5px", marginRight: "3.5px" }}
                    />
                  </span>
                  Burn Projection
                </span>
              </Blue>

              <Blue
                hidden={
                  (props.currentTab === CURRENT_TAB_TYPE.invoices &&
                    props.dataType === CURRENT_TAB_DATA_TYPE.classified) ||
                  props.currentTab === CURRENT_TAB_TYPE.bank_statements ||
                  props.currentTab === CURRENT_TAB_TYPE.GST ||
                  props.currentTab === CURRENT_TAB_TYPE.contracts || props.currentTab === CURRENT_TAB_TYPE.debt_schedule
                }
                onClick={() => setShowUploadOptions(true)}
                style={{ position: "relative" }}
              >
                + Upload
                {showUploadOptions && <ShowUploadTypeOptions />}
              </Blue>

              <Blue
                onClick={downloadFile}
                hidden={
                  props.currentTab === CURRENT_TAB_TYPE.bank_statements &&
                  (props.dataType === CURRENT_TAB_DATA_TYPE.pdf ||
                    props.dataType === CURRENT_TAB_DATA_TYPE.excel_report)
                }
              >
                {!isExporting ? (
                  <span>
                    <img
                      src="/assets/download-dv.svg"
                      alt=""
                      style={{ height: "12.5px", marginRight: "3.5px" }}
                    />
                  </span>
                ) : (
                  <span id="loading-spinner">
                    <img
                      src=""
                      className="spin-icon"
                      style={{ marginRight: "8px" }}
                      alt=""
                    ></img>
                  </span>
                )}
                Export
              </Blue>

              <StatusBarClassified
                dataType={props.dataType}
                currentTab={props.currentTab}
                setDataType={props.setDataType}
              />
            </>
          )}
        {(props.editClassification ||
          props.editInvoiceFrequency ||
          props.editBankClassification ||
          props.editContracts) && (
            <>
              <button
                onClick={() => {
                  props.setIsCancelling(true);
                  props.currentTab === CURRENT_TAB_TYPE.invoices
                    ? props.setEditInvoiceFrequency(false)
                    : props.currentTab === CURRENT_TAB_TYPE.bank_statements
                      ? props.setEditBankClassification(false)
                      : props.currentTab === CURRENT_TAB_TYPE.contracts
                        ? props.setEditContracts(false)
                        : props.setEditClassification(false);
                  props.setEditedItems([]);
                  props.dataType === "classified" &&
                    dispatch(
                      fetchUnderwritingData(
                        props.investeeOrganization._id,
                        props.currentTab,
                        props.dataType,
                        props.dates.start_date,
                        props.dates.end_date,
                        props.investeeOrganization.investee_onboarded_id
                      )
                    );
                }}
                className="company-edit-cancel go"
                style={{
                  height: "28px",
                  minWidth: "86px",
                  paddingL: "7px 18px",
                  margin: "1px",
                  backgroundColor: "white",
                  marginRight: "15px",
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleSaveButton}
                className="date-go-btn"
                style={{
                  height: "28px",
                  minWidth: "86px",
                  backgroundColor: "#4C8DFF",
                  color: "white",
                  paddingLeft: "6px 15px",
                  margin: "1px",
                  marginRight: "3px",
                }}
              >
                <span>
                  <img
                    src="/assets/Save_Icon.svg"
                    alt=""
                    style={{ marginRight: "5px" }}
                  />
                  {!ispostingUnderwritingEditedData ? "Save" : "Saving"}
                </span>
              </button>
            </>
          )}
      </div>
    </div>
  );
}
