import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import papa from "papaparse";

import {
  FETCHING_UNDERWRITING_DATA,
  fetchUnderwritingData,
  uploadInvoiceSpreadData,
  uploadRawInvoiceData,
  uploadRawMisData,
} from "../../../../../../../../App/AppActions";
import DataStage from "./components/DataStage";
import UnderwritingTable from "./components/UnderwritingTable/UnderwritingTable";
import { dateLastMonth, showNotification } from "../../../../../../../../../util/utility";
import styles from "./bankstats.module.css";

import callApi from "../../../../../../../../../util/apiCaller";
import {
  CURRENT_TAB_DATA_TYPE,
  CURRENT_TAB_TYPE,
} from "../../../../../../../../../common/ENUM";
import { isEmpty, isNil, isString } from "lodash";
import { getContractsSynced, getContractThreshold, getLastSyncedDate } from "../../../../../../../../App/AppReducer";
import Tabs from "./components/Tabs";
import { getLastFetchedDetails } from "../../../../../../../../../common/ApiHelpers";
export default function UnderwritingTool(props) {
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState(CURRENT_TAB_TYPE.balance_sheets);
  const [editClassification, setEditClassification] = useState(false);
  const [editBankClassification, setEditBankClassification] = useState(false);
  const [editContracts, setEditContracts] = useState(false);
  const [editInvoiceFrequency, setEditInvoiceFrequency] = useState(false);
  const [dataType, setDataType] = useState("classified");
  const [updateData, setUpdateData] = useState(false);
  const [lastFetchedData, setLastFetchedData] = useState({});
  const [dates, setDates] = useState({
    start_date: dateLastMonth(),
    end_date: moment().format("YYYY-MM-DD"),
  });
  const [editedItems, setEditedItems] = useState([]);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveScroll, setSaveScroll] = useState(true);
  const [burnClicked, setBurnClicked] = useState(false);
  const [resp_burn_arr, set_resp_burn_arr] = useState([]);
  const [burn_metrics, set_burn_metrics] = useState({
    fromDate: "",
    toDate: "",
    burnValue: "",
  });
  const getContractThresholdFromState = useSelector((state) => getContractThreshold(state));
  const syncedContracts= useSelector(getContractsSynced);
  const lastSyncedDate = useSelector(getLastSyncedDate);

  const [editedContractThreshold, setEditedContractThreshold] = useState(getContractThresholdFromState);

  const getLastFetchedDetailsData = async () => {
    const lastFetchedDetails = await getLastFetchedDetails(props.investeeOrganization._id);
    if(lastFetchedDetails) {
      setLastFetchedData(lastFetchedDetails);
    }
  }
  useEffect(() => {
    const getting_data = async () => {
      const resp = await callApi(
        `/bank/burn-projection?investeeOrgId=${props.investeeOrganization._id}`,
        "get",
        undefined,
        undefined,
        undefined,
        true
      );
      set_resp_burn_arr(resp?.data?.data);
    };
    getting_data();

    getLastFetchedDetailsData();
  }, [burnClicked]);

  function formatDate(input) {
    input = input.split("-").join("/");
    let datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }
  function formatDateSlice(input) {
    const changed_format = input.slice(0, 10);
    return formatDate(changed_format);
  }
  function jsDate(dateString) {
    let dateParts = dateString.split("/");
    // month is 0-based, that's why we need dataParts[1] - 1
    let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    const date_obj_string = dateObject.toString().slice(4, 15);
    const month = date_obj_string.slice(0, 3);
    const day = date_obj_string.slice(4, 6);
    const year = date_obj_string.slice(7, 11);
    return `${day} ${month} ${year}`;
  }

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    set_burn_metrics({ ...burn_metrics, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      burn_metrics.fromDate &&
      burn_metrics.toDate &&
      burn_metrics.burnValue
    ) {
      const new_burn_metrics = {
        ...burn_metrics,
        id: new Date().getTime().toString(),
      };
      const saving_data = async () => {
        await callApi(
          `/bank/add/burn-projection?investeeOrgId=${props.investeeOrganization._id}`,
          "put",
          new_burn_metrics,
          undefined,
          undefined,
          true
        );
        const getting_data = async () => {
          const resp = await callApi(
            `/bank/burn-projection?investeeOrgId=${props.investeeOrganization._id}`,
            "get",
            undefined,
            undefined,
            undefined,
            true
          );
          set_resp_burn_arr(resp.data.data);
        };
        getting_data();
      };
      saving_data();
    }
    set_burn_metrics({ fromDate: "", toDate: "", burnValue: "" });
  };

  useEffect(() => {
    setEditedItems([]);
  }, [currentTab]);

  const handleDateChange = (keyName, keyValue) => {
    let newDates = { ...dates };
    newDates[keyName] = keyValue;
    setDates(newDates);
  };

  const handleInvoiceSpreadUpload = (event) => {
    dispatch({
      type: FETCHING_UNDERWRITING_DATA,
      is_fetching_underwriting_data: true,
    });
    const files = event.target.files;
    if (files.length > 0) {
      const csvFile = files[0];
      papa.parse(csvFile, {
        skipEmptyLines: true,
        complete: async (results) => {
          const dateLabels = results.data[0].slice(1);
          const data = results.data.slice(1).map((row) => ({
            customerName: row[0],
            amounts: row
              .slice(1)
              .map((amount, i) => {
                if (dateLabels[i])
                  return {
                    date: dateLabels[i],
                    amount: amount
                      ? parseInt(amount.replaceAll(",", ""), 10)
                      : 0,
                  };
                return null;
              })
              .filter((a) => a !== null),
          }));
          const status = await uploadInvoiceSpreadData(
            props.investeeOrganization._id,
            data
          );
          if (status) {
            dispatch(
              fetchUnderwritingData(
                props.investeeOrganization._id,
                currentTab,
                dataType,
                dates.start_date,
                dates.end_date,
                props.investeeOrganization.investee_onboarded_id
              )
            );
          }
        },
      });
    }
    dispatch({
      type: FETCHING_UNDERWRITING_DATA,
      is_fetching_underwriting_data: false,
    });
    event.target.value = "";
  };

  const validateColumnsInFile = (file, headersCheckObj) => {
    const getRow = (rows, index) => {
      if (Array.isArray(rows)) return rows[index];
      return rows.split('\n')[index].split(',');
    };

    const getMissingColumns = (rows, index) => {
      if (isEmpty(rows)) return [];
      const tempArr = [];
      const tempObj = { ...headersCheckObj };
      const headers = getRow(rows, index);
      const objKeys = Object.keys(tempObj);
      if (headers.length < objKeys.length) {
        return `File should be like sample.csv`;
      }
      headers.forEach((cell) => {
        objKeys.forEach((key) => {
          if (tempObj[key] !== 'found') {
            tempObj[key].forEach((item) => {
              if (cell && isString(cell) && cell.toLowerCase().includes(item))
                tempObj[key] = 'found';
            });
          }
        });
      });
      objKeys.forEach((key) => {
        if (tempObj[key] !== 'found') {
          tempArr.push(key);
        }
      });
      return tempArr;
    };

    const findHeader = (rows) => {
      if (isEmpty(rows)) return null;
      let index = null;
      for (let i = 0; i < 10; i++) {
        const row = getRow(rows, i);
        if (row && row.length > 3) {
          const fourthCell = row[3];
          if (fourthCell) {
            index = i;
            break;
          }
        }
      }
      return index;
    };

    const splitter = file.name.split('.');
    const extn = splitter[splitter.length - 1];
    if (extn === 'csv') {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        let result;
        reader.readAsText(file);
        reader.onerror = (e) => {
          reject(e);
        };
        reader.onload = (e) => {
          const index = findHeader(e.target.result);
          if (!isNil(index)) {
            result = getMissingColumns(e.target.result, index);
            if (typeof result === 'string') reject(result);
            resolve(result);
          }
          reject(`File should be like sample.csv`);
        };
      });
    }
    else return [];
  };

  const getS3detailsForInvoicesUpload = async(ext) => {
    const type = ext === ".csv" ? "invoices/" : "mis/excel-";
    try {
      const res = await callApi(`${type}s3-details?orgId=${props.investeeOrganization._id}`, "get", null, {}, false, true);
      if (res.statusCode == 200) return { ...res?.data?.data };
      else {
        const message = res?.data?.message ?? "Some error occurred";
        showNotification("Error", message);
      }
    }
    catch (err) {
      console.error("Error: ", err);
      showNotification("Error", "Some error occurred");
    }
  }

  const handleInvoiceUpload = async(event, ext) => {
    dispatch({
      type: FETCHING_UNDERWRITING_DATA,
      is_fetching_underwriting_data: true,
    });
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      const remainingMustHeaders = ext !== ".xlsx" ? await validateColumnsInFile(file, {
        "Name": ['name', 'customer', 'client'],
        "Amount": ['amount'],
        "Invoice Date": ['date'],
        "Invoice ID": ['id']
      }) : [];
      const length = remainingMustHeaders.length;
      if ((remainingMustHeaders && length === 0)) {
        const s3details = await getS3detailsForInvoicesUpload(ext);
        if (s3details) {
          const { key, bucket, uuid } = s3details;
          const body = new FormData();
          body.append("file", file);
          callApi(
            `/file/upload?key=${key}&bucket=${bucket}&requestId=${uuid}`,
            "post",
            body,
            {},
            false,
            false,
            false,
            false,
            true
          ).then(res => {
            if (res.responseData && res.responseData.responseCode && res.responseData.responseCode === 20) {
              showNotification("Success", "Successfully uploaded");
            }
            else showNotification("Error", "Some error occurred");
          });
        }
      }
      else {
        let message;
        if (length === 1) message = "'" + remainingMustHeaders[0] + "' column is missing";
        else {
          for (let type of remainingMustHeaders) {
            if (type === remainingMustHeaders[0]) message = "'" + type + "'";
            else if (type === remainingMustHeaders[length - 1]) message += " and '" + type + "' columns are missing";
            else message += ", '" + type + "'";
          }
        }
        showNotification("Error", message);
      }
    }
    dispatch({
      type: FETCHING_UNDERWRITING_DATA,
      is_fetching_underwriting_data: false,
    });
    event.target.value = "";
  };

  const handleMisUpload = (event) => {
    dispatch({
      type: FETCHING_UNDERWRITING_DATA,
      is_fetching_underwriting_data: true,
    });
    const files = event.target.files;
    if (files.length > 0) {
      const csvFile = files[0];
      papa.parse(csvFile, {
        skipEmptyLines: true,
        complete: async (results) => {
          const dateLabels = results.data[0].slice(5);
          const data = results.data.slice(1).map((row) => ({
            particular: row[0],
            ...(row[1] !== "" && { legend: row[1] }),
            ...(row[2] !== "" && { subLegend: row[2] }),
            ...(row[3] !== "" && { classification: row[3] }),
            amounts: row.slice(5).map((amount, i) => ({
              date: dateLabels[i],
              amount: amount ? parseInt(amount.replaceAll(",", ""), 10) : 0,
            })),
          }));
          const status = await uploadRawMisData(
            props.investeeOrganization._id,
            currentTab,
            data
          );
          if (status) {
            dispatch(
              fetchUnderwritingData(
                props.investeeOrganization._id,
                currentTab,
                dataType,
                dates.start_date,
                dates.end_date,
                props.investeeOrganization.investee_onboarded_id
              )
            );
          }
        },
      });
    }
    dispatch({
      type: FETCHING_UNDERWRITING_DATA,
      is_fetching_underwriting_data: false,
    });
    event.target.value = "";
  };

  return (
    <div className="underwritting-link">
      <div className="data-vault-data-top">
        <Tabs 
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          setDataType={setDataType}
          setEditClassification={setEditClassification}
          setBurnClicked={setBurnClicked}
        />
        <DataStage
          investeeOrganization={props.investeeOrganization}
          dataType={dataType}
          setDataType={setDataType}
          setEditClassification={setEditClassification}
          editClassification={editClassification}
          editBankClassification={editBankClassification}
          editContracts={editContracts}
          setEditContracts={setEditContracts}
          setEditBankClassification={setEditBankClassification}
          setEditInvoiceFrequency={setEditInvoiceFrequency}
          editInvoiceFrequency={editInvoiceFrequency}
          handleDateChange={handleDateChange}
          handleMisUpload={handleMisUpload}
          handleInvoiceUpload={handleInvoiceUpload}
          handleInvoiceSpreadUpload={handleInvoiceSpreadUpload}
          dates={dates}
          setDates={setDates}
          setUpdateData={setUpdateData}
          editedItems={editedItems}
          setEditedItems={setEditedItems}
          currentTab={currentTab}
          setIsCancelling={setIsCancelling}
          setIsSaving={setIsSaving}
          setBurnClicked={setBurnClicked}
          burnClicked={burnClicked}
          setSaveScroll={setSaveScroll}
          editedContractThreshold={editedContractThreshold}
          getContractThresholdFromState={getContractThresholdFromState}
          lastFetchedData={lastFetchedData}
          getLastFetchedDetailsData={getLastFetchedDetailsData}
        />
      </div>
      {currentTab == CURRENT_TAB_TYPE.bank_statements &&
        (dataType == "pdf" || dataType == "raw") &&
        burnClicked ? (
        <div className={styles.burn_box}>
          <form onSubmit={handleSubmit} className={styles.form_format}>
            <input
              type="date"
              class="filter-value"
              id="fromDate"
              name="fromDate"
              value={burn_metrics.fromDate}
              onChange={handleChange}
              required
            />
            <input
              type="date"
              class="filter-value"
              id="toDate"
              name="toDate"
              value={burn_metrics.toDate}
              onChange={handleChange}
              required
            />
            <input
              type="number"
              class="filter-value"
              id="burnValue"
              name="burnValue"
              value={burn_metrics.burnValue}
              onChange={handleChange}
              required
            />
            <div>
              <button class="sc-iBkjds iHGKRu">Save</button>
            </div>
          </form>
          <article className={styles.burn_scroller}>
            {resp_burn_arr.map((burn_item) => {
              const { id, date, toDate, burnValue } = burn_item;
              return (
                <div key={id} className={styles.burn_display}>
                  {/* <div className={styles.burn_values}>
                    {jsDate(formatDateSlice(fromDate))}
                  </div> */}
                  <div className={styles.burn_values}>
                    {jsDate(formatDateSlice(date))}
                  </div>
                  <div className={styles.burn_values}>{burnValue}</div>
                </div>
              );
            })}
          </article>
        </div>
      ) : (
        <></>
      )}
      <UnderwritingTable
        investeeOrganization={props.investeeOrganization}
        currentTab={currentTab}
        dates={dates}
        dataType={dataType}
        editClassification={editClassification}
        setEditClassification={setEditClassification}
        editBankClassification={editBankClassification}
        setEditBankClassification={setEditBankClassification}
        editContracts={editContracts}
        setEditContracts={setEditContracts}
        editInvoiceFrequency={editInvoiceFrequency}
        setEditedItems={setEditedItems}
        editedItems={editedItems}
        updateData={updateData}
        setUpdateData={setUpdateData}
        isCancelling={isCancelling}
        setIsCancelling={setIsCancelling}
        isSaving={isSaving}
        setIsSaving={setIsSaving}
        jsDate={jsDate}
        formatDate={formatDate}
        saveScroll={saveScroll}
        setSaveScroll={setSaveScroll}
        editedContractThreshold={editedContractThreshold}
        setEditedContractThreshold={setEditedContractThreshold}
        getContractThresholdFromState={getContractThresholdFromState}
        syncedContracts={syncedContracts}
        lastSyncedDate={lastSyncedDate}
      />
    </div>
  );
}
