import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import { API_STATUS, LAKH, TOAST_TYPES } from "../../../../../../../../../../../constants/enums";
import callApi from "../../../../../../../../../../../util/apiCaller";
import { priceFormat, showNotification } from "../../../../../../../../../../../util/utility";

const SIGNED = "signed";

const GreyColorBox = {
  fontSize: "8px",
  alignItems: "center",
  backgroundColor: "#E8E8E8",
  border: "1px solid #D6D6D6",
  padding: "2px 0",
  borderRadius: "4px",
  textAlign: "center",
};

const InvestorPriorityModal = ({ deal }) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [priorities, setPriorities] = useState([]);
  const [expired, setExpired] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [investorDealMap, setInvestorDealMap] = useState({});

  useEffect(() => {
    const dealMap = {};
if (deal && deal.investor_deal_offer) {
  for (let i = 0; i < deal.investor_deal_offer.length; i++) {
    const c = deal.investor_deal_offer[i];
    dealMap[c.investor_organization_id] = c;
  }
}

    setInvestorDealMap(dealMap);
    
    const priorityList = [];
    const expiredList = [];
    const completedList = [];
    deal?.priority_list?.forEach((priority) => {
      const investorDeal = dealMap[priority?.investor_organization_id ?? null];
      const today = dayjs().endOf("day").valueOf();
      const endDate = dayjs(investorDeal?.end_date).endOf("day").valueOf();
      if (Number.parseFloat(((investorDeal?.approved - investorDeal?.drawn) * LAKH).toFixed(0)) > 0 && today <= endDate) {
        priorityList.push(priority);
      } else if (Number.parseFloat(((investorDeal?.approved - investorDeal?.drawn) * LAKH).toFixed(0)) <= 0) {
        completedList.push(priority);
      } else if (today > endDate) {
        expiredList.push(priority);
      }
    });
    setPriorities(priorityList);
    setCompleted(completedList);    
    setExpired(expiredList);
  }, [deal]);

  const handleSelectChange = (e, prevPriority) => {
    const newPriority = Number.parseInt(e.target.value, 10);
    const newDeal = priorities[prevPriority];
    const newPriorities = [...priorities];
    newPriorities.splice(prevPriority, 1);
    newPriorities.splice(newPriority, 0, newDeal);
    setPriorities(newPriorities);
  };

  const savePriorities = async () => {
    setButtonDisabled(true);
    const response = await callApi("deals/investee-organization/deals_update", "post", {
      investee_organization_id: deal?.investee_organization_id,
      deal: {
        _id: deal?._id,
        priority_list: priorities,
      },
    });
    if (response.status === API_STATUS.SUCCESS)
      showNotification(TOAST_TYPES.SUCCESS, "Investor priorities updated successfully");
    else
      showNotification(
        TOAST_TYPES.ERROR,
        response?.data?.message ?? "Could not update investor priorities"
      );
    setButtonDisabled(false);
  };

  const remainingLimit = (approved, drawn) => {
    if (Number.isFinite(approved) && Number.isFinite(drawn)) {
      return priceFormat(
        Number.parseFloat(approved.toFixed(2)) - Number.parseFloat(drawn.toFixed(2)),
        2
      );
    }
    return "-";
  };

  return (
    <div className="modal fade" id={`investor-priority-modal-${deal?._id}`} role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header border-0 p-4">
            <span>Prioritize Active Lines</span>
            <button type="button" className="close" data-dismiss="modal">
              <img src="../../../../../assets/modal-cross.svg" alt="Close Modal" />
            </button>
          </div>
          <div className="mb-2" style={{ padding: "0 1.5rem 1.5rem" }}>
            <div className="bgclr2 br4 p-2 fs12">
              <span className="clr17">Deal Name:</span> {deal?.deal_name}
            </div>
            <div className="modal-table mt-4">
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "30%" }}>Investor (Priority)</th>
                    <th style={{ width: "14%" }}>Remaining</th>
                    <th style={{ width: "14%" }}>End Date</th>
                    <th style={{ width: "14%" }}>Priority</th>
                  </tr>
                </thead>
                <tbody>
                  {(priorities ?? []).map((priority, index) => {
                    const investorDeal = investorDealMap[priority?.investor_organization_id];
                    return (
                      <tr key={priority?._id}>
                        <td title={`${priority?.name} (${index + 1})`}>
                          {priority?.name} ({index + 1})
                        </td>
                        <td>{remainingLimit(investorDeal?.approved, investorDeal?.drawn)}</td>
                        <td>
                          {dayjs(investorDeal?.end_date).format("D MMM YYYY") ?? "Auto Generated"}
                        </td>
                        <td>
                          <select
                            name="select-priority"
                            id="select-priority"
                            value={index}
                            onChange={(e) => handleSelectChange(e, index)}
                          >
                            {(priorities ?? {}).map((_p, i) => {
                              if (i === index)
                                return (
                                  <option disabled value={i}>
                                    Priority {i + 1}
                                  </option>
                                );
                              return <option value={i}>Priority {i + 1}</option>;
                            })}
                          </select>
                        </td>
                      </tr>
                    );
                  })}
                  {(expired ?? []).map((priority, _index) => {
                    const investorDeal = investorDealMap[priority?.investor_organization_id];
                    return (
                      <tr key={priority?._id}>
                        <td title={`${priority?.name}`}>
                          {priority?.name}
                        </td>
                        <td>{remainingLimit(investorDeal?.approved, investorDeal?.drawn)}</td>
                        <td>
                          {dayjs(investorDeal?.end_date).format("D MMM YYYY") ?? "Auto Generated"}
                        </td>
                        <td><div style={GreyColorBox}>Expired</div></td>
                      </tr>
                    );
                  })}
                  {(completed ?? []).map((priority, _index) => {
                    const investorDeal = investorDealMap[priority?.investor_organization_id];
                    return (
                      <tr key={priority?._id}>
                        <td title={`${priority?.name}`}>
                          {priority?.name}
                        </td>
                        <td>{remainingLimit(investorDeal?.approved, investorDeal?.drawn)}</td>
                        <td>
                          {dayjs(investorDeal?.end_date).format("D MMM YYYY") ?? "Auto Generated"}
                        </td>
                        <td><div style={GreyColorBox}>Completed</div></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <button
              className="btn3 fs12 w-100 mt-2 p-2"
              onClick={savePriorities}
              disabled={buttonDisabled}
            >
              Save Priority
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorPriorityModal;
