import React, {
    // Component
} from 'react';
// import {Link} from 'react-router-dom';
import FilterHeaderComponent from '../../../../../components/FilterHeaderComponent/FilterHeaderComponent';

function SwapsTableHeader(props) {
    return (
        <thead className="team-head swap-table">
            {/* admin-contract-head */}
        <tr>
            {/* <th><input type="checkbox"
                                //    value = {props.item._id}
                                //    style={{marginLeft:'5px', paddingRight:'5px'}}
                                //    checked={props.selected_contract_ids.some(i => i === props.item._id)}
                                //    onChange={props.onChangeContractCheckbox.bind(this)} 
                                   />
                                   </th> */}
           <th className='pl-1'>Investor Org</th>
           <th>Company Name</th>
           <th>Contract ID</th>
           <th>Remaining Term</th>
           <th>Repayments</th>
           <th>Date Identified</th>
           <th>Swap With</th>
           <th>Forelosure Amount</th>
           <th>Status</th>
           <th>Last Updated</th>
           <th></th>
           <th></th>
        </tr>
        </thead>
    )
}

export default SwapsTableHeader;
