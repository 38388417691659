import React, { Component } from "react";
// import { Link } from 'react-router-dom';
import ClubAccessTableHeader from "../ClubAccessPage/components/ClubAccessTableHeader";
import ClubAccessTableRow from "../ClubAccessPage/components/ClubAccessTableRow";
import AdminHead from "../../components/AdminHead.js";
import callApi from "../../../../util/apiCaller";
import { showNotification } from "../../../../util/utility";
import AddNewModal from "./components/AddNewModal";
import _ from "lodash";
import { mergeByProperty } from "../../../../util/calculateTermUtil";

const emailVerify = {
  'Yes': true,
  'No': false
};
const club = {
  'Company': 'is_investee',
  'Investor': 'is_investor'
}
const emailVerifyOptions = ['Yes', 'No'];
const clubOptions = ['Company', 'Investor'];
const statusOptions = ['Approved', 'Open', 'Not Approved', 'On Hold'];
class ClubAccessPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInvesteesFilter: true,
      users: [],
      is_fetching_users: false,
      selected_user_ids: [],
      is_marking_users_status: false,
      mark_user_status_as: "",
      pageNum: 1,
      pageSize: 200,
      totalCount: 0,
      qtext: "",
      isFiltering: false,
      // searchObjUserName: {
      //   keyName: "contact_name",
      //   value: "",
      // },
      selectorObjEmailVerify: {
        keyName: "is_email_verified",
        value: emailVerifyOptions,
        status: false
      },
      selectorObjClub: {
        keyName: "club",
        value: clubOptions,
        status: false
      },
      // searchObjOrgName: {
      //   keyName: "org_name",
      //   value: "",
      // },
      // searchObjEmail: {
      //   keyName: "contact_email",
      //   value: "",
      // },
      // searchObjAdminUser: {
      //   keyName: "admin_user",
      //   value: "",
      // },
      // dateObjDateRequested: {
      //   date: [new Date(), new Date()],
      //   keyName: "created_at",
      // },
      // dateObjLastUpdated: {
      //   date: [new Date(), new Date()],
      //   keyName: "updated_at",
      // },
      // selectorObjUserType: {
      //   keyName: "user_type",
      //   elementsArray: [
      //     {
      //       label: "Company",
      //       selected: true,
      //     },
      //     {
      //       label: "Investor",
      //       selected: true,
      //     },
      //   ],
      // },
      selectorObjStatus: {
        keyName: "approval_status",
        value: statusOptions,
        status: false
      }
    };
    this.debounceSearch = _.debounce(() => this.fetchUsers(true), 400);
  }

  keyStateObjNameDict = {
    contact_name: "searchObjUserName",
    is_email_verified: "selectorObjEmailVerify",
    phone_number: "selectorObjPhoneNumber",
    club: "selectorObjClub",
    org_name: "searchObjOrgName",
    contact_email: "searchObjEmail",
    created_at: "dateObjDateRequested",
    approval_status: "selectorObjStatus",
    updated_at: "dateObjLastUpdated",
    admin_user: "searchObjAdminUser",
    user_type: "selectorObjUserType"
  };

  componentDidMount() {
    this.setState({ isFiltering: true });
    this.fetchUsers();
  }

  fetchUsers = (isFiltering = false) => {
    // console.log('fetching')
    this.setState({ is_fetching_users: true });
    let filters = this.getFilters(isFiltering);
    callApi(`admin/user/list`, "post", filters).then((res) => {
      // console.log(res);
      if (res.status === "Success") {
        let users = this.state.users;
        if (isFiltering) {
          this.setState({
            users: res.data.users,
            totalCount: 0,
            selected_user_ids: []
          })
        }
        else {
          this.setState({
            users: mergeByProperty(users, res.data.users, "_id"),
            totalCount: res.data.count,
            selected_user_ids: []
          });
        }
      } else {
        showNotification("error", "Some error occured");
      }
      this.setState({ is_fetching_users: false });
    });
  };

  hasMore = () => {
    return this.state.totalCount + 1 > this.state.pageNum * this.state.pageSize;
  };

  loadMore = () => {
    this.setState({ pageNum: this.state.pageNum + 1, isFiltering: false });
    this.fetchUsers();
  };

  onChangeUserCheckbox = (val) => {
    let selected_user_ids = this.state.selected_user_ids;
    if (val.target.checked) {
      selected_user_ids.push(val.target.value);
    } else if (!val.target.checked) {
      _.remove(selected_user_ids, (n) => {
        return n === val.target.value;
      });
    }
    this.setState({
      selected_user_ids: selected_user_ids,
    });
  };

  markUserApprovalStatus = (status) => {
    this.setState({
      is_marking_users_status: true,
      mark_user_status_as: status,
    });
    callApi(`admin/mark-users-approval-status`, "post", {
      user_ids: this.state.selected_user_ids,
      approval_status: status,
    }).then((res) => {
      if (res.status === "Success") {
        showNotification("success", "Approved Successfully!");
        this.setState({
          is_marking_users_status: false,
        });
        this.fetchUsers();
      } else {
        showNotification("error", "Some error occured");
        this.setState({
          is_marking_users_status: false,
        });
      }
    });
  };

  handleTextSearch = (val) => {
    this.setState(
      {
        qtext: val,
      },
      () => {
        this.debounceSearch();
      }
    );
  };

  getFilters = (isFiltering) => {
    const { selectorObjEmailVerify, selectorObjClub, selectorObjStatus } = this.state;
    let filters = {};
    
    if(isFiltering){
      this.setState({pageNum:1});
    }
    filters.pageNum = isFiltering ? 1: this.state.pageNum;
    filters.pageSize = this.state.pageSize;
    if (this.state.qtext) {
      filters.isSearching = true;
      filters.qtext = this.state.qtext;
    }
    // filters.sort_by = ''
    // filters[this.state.searchObjCompanyName.keyName] = this.state.searchObjCompanyName.value;
    // filters[this.state.searchObjCustomer.keyName] = this.state.searchObjCustomer.value;
    const emailVerifyArray = selectorObjEmailVerify.value.map(item => {
      return emailVerify[item];
    });
    filters[selectorObjEmailVerify.keyName] = emailVerifyArray;
    // selectorObjClub.value.forEach(item => {
    //   filters[club[item]] = true;
    // });
    // clubKeys.map(item => {
    //   filters[item] = true;
    // });
    // filters[clubKey] = true;
    // filters[this.state.searchObjCustomerEmail.keyName] = this.state.searchObjCustomerEmail.value;
    // filters[this.state.searchObjAdminUser.keyName ] = this.state.searchObjAdminUser.value;
    // filters[this.state.searchObjRequesteBy.keyName ] = this.state.searchObjRequesteBy.value;
    // filters[this.state.sliderObjTerm.keyName] = {
    //     "min": this.state.sliderObjTerm.currentMin,
    //     "max": this.state.sliderObjTerm.currentMax
    // };
    // filters[this.state.sliderObjPayments.keyName] = {
    //     "min": this.state.sliderObjPayments.currentMin,
    //     "max": this.state.sliderObjPayments.currentMax
    // };
    // filters[this.state.dateObjContractStart.keyName] = {
    //     "from":this.state.dateObjContractStart.date[0],
    //     "to":this.state.dateObjContractStart.date[1]
    // };
    // filters[this.state.dateObjContractEnd.keyName] = {
    //     "from":this.state.dateObjContractEnd.date[0],
    //     "to":this.state.dateObjContractEnd.date[1]
    // };
    // filters[this.state.dateObjLastUpdated.keyName] = {
    //     "from":this.state.dateObjLastUpdated.date[0],
    //     "to":this.state.dateObjLastUpdated.date[1]
    // };
    // filters[this.state.dateObjDateRequested.keyName] = {
    //     "from":this.state.dateObjDateRequested.date[0],
    //     "to":this.state.dateObjDateRequested.date[1]
    // };
    // filters[selectorObjStatus.keyName] = selectorObjStatus.value
    if(this.state.isInvesteesFilter){
      filters.is_investee = this.state.isInvesteesFilter;
    } else {
      filters.is_investor = !this.state.isInvesteesFilter;
    }
    return filters;
  };

  handleApplyFilters = () => {
    this.setState({ isFiltering: true });
    this.fetchUsers(true);
  };

  handleSearchFilter = (obj) => {
    let searchObj = {};
    searchObj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(searchObj, () => {
      this.handleApplyFilters();
    });
  };

  handleSliderFilter = (obj) => {
    let sliderobj = {};
    sliderobj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(sliderobj, () => {
      this.handleApplyFilters();
    });
  };

  handledatePicker = (obj) => {
    let datePickerObj = {};
    datePickerObj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(datePickerObj, () => {
      this.handleApplyFilters();
    });
  };

  inputSelectorHandler = (obj) => {
    let selectorObj = {};
    selectorObj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(selectorObj, () => {
      this.handleApplyFilters();
    });
  };

  // handleTextSearch = (val) => {
  //   this.setState(
  //     {
  //       qtext: val,
  //     },
  //     () => {
  //       this.debounceSearch();
  //     }
  //   );
  // };

  // debounceSearch = _.debounce(() => this.fetchUsers(true), 400);

  render() {
    return (
      <div className="main-body">
        <AdminHead />
        <section id='admin-company'>
          <div class='d-flex justify-content-between form-group has-search'>
            <div className='d-flex justify-content-between'>
              {this.state.is_marking_users_status &&
                this.state.mark_user_status_as === "Approved" ? (
                <button className='app-btn btn-approve'>
                  <img src='../../../../../assets/icon-done.svg' alt=''></img>
                  Loading...
                </button>
              ) : (
                <button
                  className='app-btn btn-approve'
                  onClick={() => this.markUserApprovalStatus("Approved")}>
                  <img src='../../../../../assets/icon-done.svg' alt=''></img>
                  Approve
                </button>
              )}
              <button
                className='app-btn btn-no-approve'
                onClick={() => this.markUserApprovalStatus("Not Approved")}>
                <img src='../../../../../assets/icon-clear.svg' alt=''></img>Not
                Approved
              </button>
              <button
                className='app-btn btn-hold'
                onClick={() => this.markUserApprovalStatus("On Hold")}>
                <img src='../../../../../assets/icon-history.svg' alt=''></img>
                On Hold
              </button>
              <input
                value={this.state.qtext}
                onChange={(e) => this.handleTextSearch(e.target.value)}
                type='text'
                className='form-control'
                placeholder='Search...'
                style={{ margin: "auto 0px" }}
              />

            </div>
            <button
              data-toggle='modal'
              data-target='#addnewmodal'
              className='admin-company-btn'>
              <img src='../../../../../assets/plus.ico' alt=''></img>
              Add New
            </button>

            {/* <!-- Modal --> */}
            <AddNewModal
            // support_request={this.state.support_request}
            // is_raising_support_request={this.state.is_raising_support_request}
            // handleChange={this.handleChange}
            // callSupportRequest={this.callSupportRequest}
            // handleClearData = {this.handleClearData}
            />
          </div>
          <button
            className={this.state.isInvesteesFilter ? "btn btn-primary mr-1" : "btn btn-secondary mr-1"}
            onClick={() => {
              !(this.state?.isInvesteesFilter) &&
                this.setState({ isInvesteesFilter: true }, () => {
                  this.handleApplyFilters()
                })
            }}>
            Investees</button>
          <button
            className={this.state.isInvesteesFilter ? "btn btn-secondary" : "btn btn-primary"}
            onClick={() => {
              this.state?.isInvesteesFilter &&
                this.setState({ isInvesteesFilter: false }, () => {
                  this.handleApplyFilters()
                })
            }}>
            Investors</button>
          <div className='table-head'>
            {/* admin-data-table tableFixHead */}
            <table className='table team'>
              <ClubAccessTableHeader
                searchObjUserName={this.state.searchObjUserName}
                searchObjOrgName={this.state.searchObjOrgName}
                searchObjEmail={this.state.searchObjEmail}
                selectorObjEmailVerify={this.state.selectorObjEmailVerify}
                searchObjAdminUser={this.state.searchObjAdminUser}
                dateObjDateRequested={this.state.dateObjDateRequested}
                dateObjLastUpdated={this.state.dateObjLastUpdated}
                selectorObjClub={this.state.selectorObjClub}
                selectorObjUserType={this.state.selectorObjUserType}
                selectorObjStatus={this.state.selectorObjStatus}
                handleSliderFilter={this.handleSliderFilter}
                inputSelectorHandler={this.inputSelectorHandler}
                handleSearchFilter={this.handleSearchFilter}
                handledatePicker={this.handledatePicker}
              />
              <tbody style={{ display: 'none' }}></tbody>
            </table>
          </div>
          <div className='tableFixHead table-body' style={{ wordBreak: 'break-word' }}>
            <table
              className='table team'
            >
              <thead></thead>
              <tbody className='team-body'>
                {this.state.users
                  .map((item, index) => (
                    <ClubAccessTableRow
                      key={item._id}
                      item={item}
                      loader={this.state.is_fetching_users}
                      hasMore={this.hasMore}
                      loadMore={this.loadMore}
                      dataLength={this.state.users.length}
                      index={index}
                      selected_user_ids={this.state.selected_user_ids}
                      onChangeUserCheckbox={this.onChangeUserCheckbox}
                    />
                  )
                  )}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    );
  }
}

export default ClubAccessPage;
