import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import OverviewEdit from "./components/OverviewEdit";
import {
  downloadFileFromArrayBuffer,
  downloadFileUsingFetch,
  exportToCsv,
  generateRequestId,
  getFeeWithoutGst,
  getMoratoriumEnum,
  getRoiFromXIRR,
  priceFormat,
  showNotification,
} from "../../../../../../../../util/utility";
import { useDispatch, useSelector } from "react-redux";
import {
  getArrData,
  getCheckIfUserHasRelevantPermissions,
  getDataSpecialist,
  getEscrowAccountInfo,
  getSalesPartner,
  getUnderwriters,
  getViewOnCompaniesOverview,
} from "../../../../../../../App/AppReducer";
import DealsOverview from "./components/DealsOverview/DealsOverview";
import { getObjAndFileName } from "./overviewUtility";
import callApi from "../../../../../../../../util/apiCaller";
import {
  changeScreenOnCompaniesOverview,
  fetchAgentsData,
  fetchArrData,
} from "../../../../../../../App/AppActions";
import isNil from "lodash/isNil";
// import { fetchInvesteeDetails } from '../../../../../../AdminActions';
import KycModal from "./components/DealsOverview/components/KycModal";
import { getKycDocs } from "../../../../../../AdminReducers";
import ManageTOT from "./components/ManageTOT";
import styled from "styled-components";
import "../../../../../../../../css/Status.css";
import { OVERVIEW_SCREENMAP } from "../../../../../../../../common/ENUM";
import { API_STATUS } from "../../../../../../../../constants/enums";
import useQuery from "../../../../../../../../common/customHooks/useQuery";
import { uw_signal_types } from "../DataVault/Overview/components/ModerateBox/components/DealEvaluation";
import { UwRiskFlag } from "./components/UwRiskFlag/UwRiskFlag";

const SubPage = styled.img`
  width: 13.5px;
  margin: 0 18px;
  transform: scaleX(-1) rotate(-90deg);
`;
const GenerateLetter = ({ letter, title, investeeOrgId, agreement }) => {
  const handleClick = () => {
    let obj = { pdfType: letter, investee_organization_id: investeeOrgId };
    callApi("admin/ep_msa_agreement/generate_agreements", "post", obj)
      .then((res) => {
        if (res.status === "Success")
          showNotification("success", `${letter} Generated`);
        else showNotification("error", "Some error occured");
      })
      .catch(() => showNotification("error", "Some error occured"));
  };
  return (
    <>
      {!agreement && (
        <div
          className="generate-btn"
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          <img
            src="/assets/add_generate-1.5x.svg"
            alt=""
            style={{ width: "15px" }}
            className="mr-2"
          />
          {title}
        </div>
      )}
    </>
  );
};

//* Documents
const DocumentsRow = ({
  rowTitle,
  date,
  agreement,
  investeeOrgId,
  handleKycDocsClick,
}) => {
  const getBuffer = (data) => data.file.Body.data;
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
  let result;
  if (agreement && rowTitle !== "KYC Documents")
    result = getObjAndFileName(agreement);

  const getObjFileNameUrlTypeMap = () => ({
    "Master Service Agreement": {
      obj: result?.obj,
      fileName: result?.fileName,
      api_url: "download-file",
      type: "application/pdf",
    },
    "Escrow Pay Agreement": {
      obj: result?.obj,
      fileName: result?.fileName,
      api_url: "download-file",
      type: "application/pdf",
    },
    "KYC Documents": {
      api_url: `admin/v2/${investeeOrgId}/zip-stream-integration-or-documentation-docs?purpose=signed_documentation`,
      obj: {},
      fileName: `Signed_Kyc_Documents_${investeeOrgId}.zip`,
      type: { responseType: "blob" },
    },
    "Decentro Response": {
      obj: { url: `decentro_responses/${investeeOrgId}-cin-gstin.json` },
      fileName: `${investeeOrgId}-cin-gstin.json`,
      api_url: "download-file",
      type: "application/json",
    },
  });

  const fetchDecentroData = (obj) => {
    let funcObj = {};
    if (obj) funcObj = { ...obj };
    funcObj.dataNotAvailableOnS3 = true;
    callApi("/validate-cin-gstin", "post", funcObj)
      .then((res) => {
        if (res.status === API_STATUS.SUCCESS) {
          const { obj, fileName, api_url, type } =
            getObjFileNameUrlTypeMap()[rowTitle];
          downloadFileFromArrayBuffer(
            api_url,
            "post",
            obj,
            null,
            fileName,
            { type: type },
            getBuffer,
            () => setIsDownloadingFile(false)
          );
        } else showNotification("error", "Couldn't fetch data from decentro");
      })
      .catch(() => showNotification("error", "Some error occured"));
  };

  const downloadLetter = () => {
    if (rowTitle === "KYC Documents") {
      handleKycDocsClick(true);
      return;
    }
    setIsDownloadingFile(true);
    const { obj, fileName, api_url, type } =
      getObjFileNameUrlTypeMap()[rowTitle];
    downloadFileFromArrayBuffer(
      api_url,
      "post",
      obj,
      null,
      fileName,
      { type: type },
      getBuffer,
      () => setIsDownloadingFile(false),
      (res) => {
        if (
          rowTitle === "Decentro Response" &&
          res &&
          res.data &&
          res.data.message === "The specified key does not exist."
        )
          fetchDecentroData({
            investee_organization_id: investeeOrgId,
            fetchOrgDataAtBE: true,
          });
        else showNotification("error", "Some error occured");
        setIsDownloadingFile(false);
      }
    );
  };

  return (
    <div className="dflex1 p-2 bgclr2 br4">
      {agreement ? (
        <img
          src="/assets/done.svg"
          alt=""
          style={{ width: "14px" }}
          className="mr-1"
        />
      ) : (
        <img
          src="/assets/pending.svg"
          alt=""
          style={{ width: "14px" }}
          className="mr-1"
        />
      )}
      <div className="w-50 fs10 fw300">{rowTitle}</div>
      <div className="w-50 color8 fs12">{date}</div>
      {isDownloadingFile ? (
        <div id="loading-spinner">
          <div
            className="spin-icon"
            style={{ width: "11px", height: "11px" }}
          ></div>
        </div>
      ) : (
        <span onClick={() => downloadLetter()} style={{ cursor: "pointer" }}>
          <img src="/assets/download-dv.svg" alt="" />
        </span>
      )}
    </div>
  );
};

// Hover Image
const getImageSource = (value) => {
  if(value){
    return "/assets/ques-icon.svg"
  }else{
    return ""
  }
}

export default function Overview({
  investeeOrganization,
  isEditingCompany,
  setIsEditingCompany,
  investee_organization_id,
}) {
  const query = useQuery();
  const partnerRef = useRef(null);
  const dispatch = useDispatch();
  const [investeeDetails, setInvesteeDetails] = useState(null);
  const [showKycDocs, setShowKycDocs] = useState(false);
  const viewIs = useSelector(getViewOnCompaniesOverview);
  const availableTradeLimit = useSelector(getArrData)?.available_limit;
  const [queryParams, setQueryParams] = useState({});
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
  const [encryptedInvesteeOrgId, setEncryptedInvesteeOrgId] = useState('');
  const escrowBalance = useSelector(
    (state) => getEscrowAccountInfo(state).balance
  );
  const salesPartner = useSelector((state) => getSalesPartner(state));
  const dataSpecialist = useSelector((state) => getDataSpecialist(state));
  const underwriters = useSelector((state) => getUnderwriters(state));
  const kycDocs = useSelector((state) => getKycDocs(state));
  const isUserHasEditAccess = useSelector((state) =>
    getCheckIfUserHasRelevantPermissions(state, ["app:edit"])
  );
  const isUserHasUWViewAccess = useSelector((state) => getCheckIfUserHasRelevantPermissions(state, ["underwriting:edit"]));
  

  const resetQueryParams = () => {
    setQueryParams({});
  }
  const {
    is_company_active,
    tot_status,
    trading_limit,
    irr,
    max_tenure_price,
    custom_tenure,
    custom_tenure_limit_min,
    custom_tenure_limit_max,
    fees,
    recur_fees_waived,
    fee_model,
    moratorium_months,
    mab,
    recur_rating,
    repayment_risk,
    total_contract_count,
    global_delay,
    escrow_pay_user_id,
    sales_partner_id,
    data_specialist_id,
    underwriter_id,
    recur_insider_status,
    last_overview_updated,
    status,
    backgroundProcessRunning,
    name,
    escrow_pay_agreement,
    msa_agreement,
    ep_agreement_date,
    msa_agreement_date,
    display_name,
    _id,
    listing_by_priority,
    uwRiskFlagDetails
  } = investeeOrganization;

  useEffect(() => {
    setQueryParams({
      isRedirect: query.get('isRedirect'),
      totId: query.get('totId'),
      offerId: query.get('offerId')
    })
  }, [])
  useEffect(() => {
    const obj = { ...investeeDetails };
    obj.is_company_active = is_company_active;
    obj.status = status;
    obj.trading_limit = trading_limit;
    obj.irr = irr;
    obj.max_tenure_price = max_tenure_price;
    obj.custom_tenure = custom_tenure;
    obj.custom_tenure_limit_min = custom_tenure_limit_min;
    obj.custom_tenure_limit_max = custom_tenure_limit_max;
    obj.fees = fees;
    obj.recur_fees_waived = recur_fees_waived;
    obj.fee_model = fee_model;
    obj.moratorium_months = moratorium_months;
    obj.mab = mab;
    obj.recur_rating = recur_rating;
    obj.repayment_risk = repayment_risk;
    obj.total_contract_count = total_contract_count;
    obj.global_delay = global_delay;
    obj.escrow_pay_user_id = escrow_pay_user_id;
    obj.underwriter_id = underwriter_id;
    obj.recur_insider_status = recur_insider_status;
    obj.last_overview_updated = last_overview_updated;
    obj.listing_by_priority = listing_by_priority;
    obj.uwRiskFlagDetails = uwRiskFlagDetails
    setInvesteeDetails(obj);
    fetchAndGetArrData();

    if (queryParams.isRedirect) {
      dispatch(changeScreenOnCompaniesOverview(""));
      dispatch(
        changeScreenOnCompaniesOverview(OVERVIEW_SCREENMAP.TOT)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investeeOrganization]);

  useEffect(() => {
    if (
      (!isNil(sales_partner_id) ||
        !isNil(underwriter_id)) &&
      !partnerRef.current
    ) {
      partnerRef.current = true;
      dispatch(
        fetchAgentsData(
          sales_partner_id,
          investeeOrganization.data_specialist_id
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sales_partner_id, underwriter_id, data_specialist_id]);

  useEffect(() => {
  if (sales_partner_id) {
      dispatch(
        fetchAgentsData(
          sales_partner_id,
          investeeOrganization.data_specialist_id
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sales_partner_id]);

  useEffect(() => {
    getEncryptedInvesteeOrganizationId();
}, [])

  const fetchAndGetArrData = () => {
    dispatch(fetchArrData(investee_organization_id));
  };

  if (isEditingCompany && !backgroundProcessRunning) {
    return (
      <OverviewEdit
        investeeOrgId={investeeOrganization._id}
        setIsEditingCompany={setIsEditingCompany}
        escrowBalance={escrowBalance}
        investeeDetails={investeeDetails}
        setInvesteeDetails={setInvesteeDetails}
        salesPartner={salesPartner}
        dataSpecialist={dataSpecialist}
        underwriters={underwriters}
        initialIrr={irr}
      />
    );
  }

  const handleKycDocsClick = (val) => {
    if (showKycDocs !== val) setShowKycDocs(val);
  };

  const copyGstFetchLink = () => {
    navigator.clipboard.writeText(
        `${process.env.REACT_APP_CUSTOMERAPP_URI
        }/${encodeURIComponent(
            encryptedInvesteeOrgId
        )}/gst-invoices-fetch`
    );
    showNotification(
        'Success',
        'GST Fetch Link Copied'
    );
}

const getEncryptedInvesteeOrganizationId = () => {
  callApi(
      `recur-checkout/investee-organization-id/${investee_organization_id}/get-encrypted-investee-organization-id`,
      'post'
  ).then((res) => {
      if (res.status === API_STATUS.SUCCESS) {
          setEncryptedInvesteeOrgId(
              res?.data?.encryptedInvesteeOrganizationId
          );
      }
  });
};

  // const exportToCsv = (tableName) => {
  //   setIsDownloadingFile(true);
  //   const headers = { 'content-type': 'application/json' };
  //   const input = {};
  //   input.investee_organization_id = investee_organization_id;
  //   input.tableName = tableName;
  //   downloadFileUsingFetch(
  //     'export-csv/v2',
  //     'post',
  //     headers,
  //     'contracts.csv',
  //     () => {
  //       setIsDownloadingFile(false);
  //     },
  //     false,
  //     input
  //   );
  // };

  const renderUnderwriters = () => {
    return underwriters.find(
      (p) => p._id === investeeDetails.underwriter_id
    )?.agent_name;
  }

  const renderSection = () => {
    switch (viewIs) {
      case OVERVIEW_SCREENMAP.TOT:
        return <ManageTOT available_trade_limit={availableTradeLimit} queryParams={queryParams} resetQueryParams={resetQueryParams} getArrData={fetchAndGetArrData}/>;
      default:
        return (
          <>
            <div className="pl-4 pr-4 m-0" style={{ fontSize: "12px" }}>
              <div className="d-flex justify-content-between">
              <div className="need-trigger">
                Needs Repayments,xnpv,term array trigger
              </div>
              <div onClick={copyGstFetchLink}>
                    <span
                        style={{ cursor: 'pointer' }}
                    >
                        <img style={{"height": "15px", "paddingBottom": "2px"}} src="/assets/copy-icon-blue.svg" alt="" />
                    </span>
                    <span className='mr-4 gst-link-button' 
                    >Generate GST Fetch Link</span>
              </div>
              </div>
              <div className="need-trigger-xnpv">Needs xnpv trigger</div>
            </div>
            <div className="row p-4 m-0">
              <div className="col-md-8 pl-0">
                <div className="row m-0">
                  <div className="col-md-6 pl-0">
                    <div className="overview-border p-3">
                      <div className="d-flex mb-3">
                        <div className="fs12 fs500">Trade Terms</div>
                        <div
                          className={`${tot_status}-tot-status TotComStatus ml-3 dflex-jca`}
                        >
                          {tot_status}
                        </div>
                        <button
                          className="btn5 ml-auto"
                          onClick={() =>
                            dispatch(
                              changeScreenOnCompaniesOverview(
                                OVERVIEW_SCREENMAP.TOT
                              )
                            )
                          }
                        >
                          Manage
                        </button>
                      </div>
                      <div className="dflex1 p-2 bgclr2 br4">
                        <div className="w-50 fs10 fw300">
                          Trading Limit (INR lacs)
                        </div>
                        <div className="w-50 color6 fs12">
                          {priceFormat(trading_limit * 1_00000)}
                        </div>
                      </div>
                      <div className="dflex1 p-2 bgclr2 br4 mt-3">
                        <div className="w-50 fs10 fw300 need-trigger-xnpv">
                          XIRR (Price max tenure)
                        </div>
                        <div className="w-50 d-flex align-items-center">
                          <span className="color6 fs12 mr-2">{Number((irr ?? 0).toFixed(9))} %</span>
                          <span className="overview-border1 fs8 px-3 py-1">
                            {max_tenure_price &&
                              Number((max_tenure_price * 100).toFixed(4)).toFixed(2)}{" "}
                            %
                          </span>
                        </div>
                      </div>
                      <div className="dflex1 p-2 bgclr2 br4 mt-3">
                        <div className="w-50 fs10 fw300">
                          ROI
                        </div>
                        <div className="w-50 d-flex align-items-center">
                          <span className="color6 fs12 mr-2">{getRoiFromXIRR(irr)} %</span>
                        </div>
                      </div>
                      <div className="dflex1 p-2 bgclr2 br4 mt-3">
                        <div className="w-50 fs10 fw300">Selected Tenure</div>
                        <div className="w-50 color6 fs12">{custom_tenure}</div>
                      </div>
                      <div className="dflex1 p-2 bgclr2 br4 mt-3">
                        <div className="w-50 fs10 fw300 need-trigger">
                          Tenure (months)
                        </div>
                        <div className="w-50 color6 fs12">
                          {custom_tenure_limit_min} - {custom_tenure_limit_max}
                        </div>
                      </div>
                      <div className="dflex1 p-2 bgclr2 br4 mt-3">
                        <div className="w-50 fs10 fw300 need-trigger-xnpv">
                          Fee (without GST)
                        </div>
                        <div className="w-50 color6 fs12 d-flex">
                          <span>{getFeeWithoutGst(fees)} %</span>
                          {recur_fees_waived && (
                            <span className="bgclr8 color11 fs9 br2 px-3 py-1 ml-2">
                              Waived
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="dflex1 p-2 bgclr2 br4 mt-3">
                        <div className="w-50 fs10 fw300 need-trigger-xnpv">
                          Fee Model
                        </div>
                        <div className="w-50 color6 fs12">{fee_model}</div>
                      </div>
                      <div className="dflex1 p-2 bgclr2 br4 mt-3">
                        <div className="w-50 fs10 fw300 need-trigger">
                          Moratorium (Upto 3 Months)
                        </div>
                        <div className="w-50 color6 fs12">
                          {getMoratoriumEnum(moratorium_months) + ` month${getMoratoriumEnum(moratorium_months)>1?'s':''}`}
                        </div>
                      </div>
                      <div className="dflex1 p-2 bgclr2 br4 mt-3">
                        <div className="w-50 fs10 fw300">
                          Minimum Balance (INR)
                        </div>
                        <div className="w-50 color6 fs12">{mab}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 pr-0">
                    <div className="overview-border p-3">
                      <div className="fs12 fs500 mb-3">Company Ratings</div>
                      <div className="dflex1 p-2 bgclr2 br4">
                        <div className="w-50 fs10 fw300">Recur Rating</div>
                        <div className="w-50 d-flex">
                          <span className="CC fs10">{recur_rating}</span>
                        </div>
                      </div>
                      <div className="dflex1 p-2 bgclr2 br4 mt-3">
                        <div className="w-50 fs10 fw300">Repayment Risk</div>
                        <div className="w-50 d-flex">
                          <span className={repayment_risk}>
                            {repayment_risk}
                          </span>
                        </div>
                      </div>
                      <div className="dflex1 p-2 bgclr2 br4 mt-3">
                        <div className="w-50 fs10 fw300">UWFlag</div>
                          <div className="w-50 d-flex">
                            <UwRiskFlag orgId={investee_organization_id} requestId = {generateRequestId()}/>
                          </div>
                          
                      </div>
                    </div>
                    <div className="overview-border p-3 mt-4">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="fs12 fs500">Contracts Overview</div>
                        <button
                          className='btn3 fs10 d-flex align-items-center cp'
                          onClick={() => exportToCsv({
                            tableName: "admin_contracts",
                            setLoader: setIsDownloadingFile,
                            investeeOrgId: investee_organization_id
                          })}
                        >
                          {isDownloadingFile && (
                            <div id="loading-spinner" style={{ marginRight: '10px' }}>
                              <div className="spin-icon" style={{ width: '15px' }} />
                            </div>
                          )}
                          <span className='mr-1'>Download</span>
                        </button>
                        {/* <button className='btn3'>Upload Contracts</button> */}
                      </div>
                      <div className="dflex1 p-2 bgclr2 br4">
                        <div className="w-50 fs10 fw300">Contracts Synced</div>
                        <div className="w-50 color6 fs12">
                          {total_contract_count}
                        </div>
                      </div>
                      <div className="dflex1 p-2 bgclr2 br4 mt-3">
                        <div className="w-50 fs10 fw300 need-trigger">
                          Payment Delay (Days)
                        </div>
                        <div className="w-50 color6 fs12">{global_delay}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <DealsOverview
                  isMountedFromOverviewPage
                  investeeOrganizationName={name}
                  investeeOrgId={investee_organization_id}
                  displayName={display_name}
                  tradingLimit={trading_limit}
                />
              </div>
              <div className="col-md-4 pr-0">
                <div className="overview-border p-3">
                  <div className="fs12 fs500 mb-3">Escrow Pay Account</div>
                  <div className="dflex1 p-2 bgclr2 br4">
                    <div className="w-50 fs10 fw300">User ID</div>
                    <div className="w-50 color10 fs12">
                      {escrow_pay_user_id}
                    </div>
                  </div>
                  <div className="dflex1 p-2 bgclr2 br4 mt-3">
                    <div className="w-50 fs10 fw300">Funds Available</div>
                    <div className="w-50 color10 fs12">{escrowBalance}</div>
                  </div>
                </div>
                <div className="overview-border p-3 mt-4">
                  <div className="fs12 fs500 mb-3">Company Relations</div>
                  <div className="dflex1 p-2 bgclr2 br4">
                    <div className="w-50 fs10 fw300">Capital Consultant</div>
                    <div className="w-50 color8 fs12">
                      {salesPartner?.agent_name}
                    </div>
                  </div>
                  <div className="dflex1 p-2 bgclr2 br4 mt-3">
                    <div className="w-50 fs10 fw300">Data Specialist</div>
                    <div className="w-50 color8 fs12">
                      {dataSpecialist?.agent_name}
                    </div>
                  </div>
                  {(investeeDetails && underwriters && underwriters.length) >
                    0 && (
                      <div className="dflex1 p-2 bgclr2 br4 mt-3">
                        <div className="w-50 fs10 fw300">Underwriter</div>
                        <div className="w-50 color8 fs12">
                          {renderUnderwriters()}
                        </div>
                      </div>
                    )}
                </div>
                <div className="overview-border p-3 mt-4">
                  <div className="fs12 fs500 mb-3">Recur Insiders</div>
                  <div className="dflex1 p-2 bgclr2 br4">
                    <div className="w-50 fs10 fw300">Insider Status</div>
                    <div className="w-50 d-flex">
                      <span className="CC fs10">{recur_insider_status}</span>
                    </div>
                  </div>
                </div>
                <div className="overview-border p-3 mt-4">
                  <div className="dflexJA fs12 fs500 mb-3">
                    <span>Documents</span>
                    {(isUserHasEditAccess||isUserHasUWViewAccess) && (
                      <div className="dflexJA">
                        <GenerateLetter
                          agreement={msa_agreement}
                          letter="MSA Agreement"
                          title="Generate MSA"
                          investeeOrgId={_id}
                        />
                        <GenerateLetter
                          agreement={escrow_pay_agreement}
                          letter="Escrow Pay Agreement"
                          title="Generate EP"
                          investeeOrgId={_id}
                        />
                      </div>
                    )}
                  </div>
                  {(msa_agreement_date && msa_agreement) && 
                  <DocumentsRow
                      handleKycDocsClick={handleKycDocsClick}
                      rowTitle={"Master Service Agreement"}
                      date={moment(msa_agreement_date).format("DD MMM YYYY")}
                      agreement={msa_agreement}
                      investeeOrgId={_id}
                    />}

                  {(ep_agreement_date && escrow_pay_agreement) && 
                  <DocumentsRow
                      handleKycDocsClick={handleKycDocsClick}
                      rowTitle={"Escrow Pay Agreement"}
                      date={moment(ep_agreement_date).format("DD MMM YYYY")}
                      agreement={escrow_pay_agreement}
                      investeeOrgId={_id}
                    />}
                  {[
                    {
                      rowTitle: "KYC Documents",
                      date: "-",
                      agreement:
                        kycDocs?.beneficiary
                          ?.is_authorized_signatory_complete &&
                        kycDocs?.company_authorisation
                          ?.is_company_authorization_complete &&
                        kycDocs?.company_kyc?.is_kyc_documents_complete,
                    },
                    {
                      rowTitle: "Decentro Response",
                      date: "-",
                    },
                  ].map((document) => (
                    <DocumentsRow
                      handleKycDocsClick={handleKycDocsClick}
                      rowTitle={document.rowTitle}
                      date={document.date}
                      agreement={document.agreement}
                      investeeOrgId={_id}
                    />
                  ))}
                  {showKycDocs && (
                    <KycModal
                      showKycDocs={showKycDocs}
                      setShowKycDocs={handleKycDocsClick}
                      investeeOrgId={_id}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap align-items-center company-row1 p-4">
        <div className="d-flex align-items-center">
          {viewIs === OVERVIEW_SCREENMAP.TOT ? (
            <span className="fs21 mr-4">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => dispatch(changeScreenOnCompaniesOverview(""))}
              >
                <span className="color2 fw500">Overview</span>
              </span>
              <SubPage src="/assets/right-arrow.svg" alt="" />
              <span className="fw600">Terms of Trade</span>
            </span>
          ) : (
            <span className="fs21 mr-4">
              <span className="fs21 mr-4 fw600">Overview</span>
              {/**commenting for RI Deal */}
              {/* {<span
                className={
                  listing_by_priority === true
                    ? "active-status"
                    : "inactive-status"
                }>
                {" "}
                {listing_by_priority === true
                ? "DEALS"
                : "MARKET"}
              </span>} */}
            </span> 
          )}
        </div>
        {viewIs !== OVERVIEW_SCREENMAP.TOT && (
          <div className="d-flex align-items-center">
            {(isUserHasEditAccess||isUserHasUWViewAccess) && (
              <>
                <p className="fs10 fw300 mb-0 mr-4">
                  <b>Last edited on:&nbsp;&nbsp;</b>
                  {last_overview_updated && (
                    <span>
                      {moment(last_overview_updated.updated_at).format(
                        "h:mm:ss, DD MMMM YYYY"
                      )}{" "}
                      by {last_overview_updated.updated_by}
                    </span>
                  )}
                </p>
                <button
                  className="btn5 d-flex align-items-center"
                  onClick={() =>
                    !backgroundProcessRunning
                      ? setIsEditingCompany(true, true)
                      : showNotification(
                        "warning",
                        "Cannot Edit, Triggers Running for Company"
                      )
                  }
                >
                  <img
                    src="/assets/create.png"
                    alt=""
                    style={{ width: "11px" }}
                    className="mr-1"
                  />
                  Edit Company
                </button>
              </>
            )}
          </div>
        )}
      </div>
      {renderSection()}
    </>
  );
}
