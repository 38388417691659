import React from 'react';
import moment from "moment";
import _ from "lodash";

const InvestorFinances = ({finances}) => {
    return (
        <>
            <div className="admin-data-table1">
                <table className="table team">
                    <thead className="team-head">
                    <tr>
                        <th scope="col" className="pl-2">Customer</th>
                        <th scope="col">Contract ID</th>
                        <th scope="col">Term</th>
                        <th scope="col">Payment No.</th>
                        <th scope="col">Investor</th>
                        <th scope="col">Gross Cashflow</th>
                        <th scope="col">Fees</th>
                        <th scope="col">Payment Date</th>
                        <th scope="col">Status</th>
                    </tr>
                    </thead>
                    <tbody className="team-body">
                    {_.isEmpty(finances) ?
                        null
                        :
                        <>{
                            finances.map(item => (
                                <tr>
                                    <td style={{paddingLeft: '10px'}}>{item.investee_organization_name}</td>
                                    <td style={{color: '#4C8DFF'}}>{item.contract_id.contract_id_num}</td>
                                    <td>{item.term}</td>
                                    <td>{item.payment_number} / {item.term}</td>
                                    <td>-</td>
                                    <td>₹ -</td>
                                    <td className="bold-table-cel" style={{color: '#49A71B'}}>+ ₹ -</td>
                                    <td>{moment(item.scheduled_recur_payment_date).format("DD MMM YYYY")}</td>
                                    <td><span className="scheduled-bg">{item.transaction_status}</span></td>
                                </tr>
                            ))
                        }
                        </>
                    }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default InvestorFinances
