import AdminHead from "../../components/AdminHead.js";
import React, {useEffect} from "react";
import {debounce, isEmpty} from "lodash";
import './CustomerRequests.scss'
import clsx from "clsx";
import RecurTable from "../../../../components/RecurTable/RecurTable.jsx";
import { approveBulkTdsClaims, downloadTdsTransferZipFile, fetchCustomerQueries, getRefetchTdsClaimsData } from "../../../../common/ApiHelpers.ts";
import moment from "moment";
import TdsDrawer from "./TdsDrawer/TdsDrawer.tsx";
import {showNotification} from "../../../../util/utility.js";
import {SHOW_NOTIFICATION} from "../../../../constants/enums.js";
import BalanceConfirmationDrawer from "./BalanceConfirmationDrawer/BalanceConfirmationDrawer.tsx";
import StatementOfAccountDrawer from "./StatementOfAccountDrawer/StatementOfAccountDrawer.tsx";
import NoDuesCertificateDrawer from "./NoDuesCertificateDrawer/NoDuesCertificateDrawer.tsx";
// @ts-ignore
import {useHistory} from "react-router-dom";
import OtherTicketDrawer from "./OtherTicket/OtherTicket.tsx";
import ReactAutoComplete from "react-autocomplete";
// @ts-ignore
import clearIcon from "../../../../assets/clear.svg";
import PageLoader from "../../../../components/loader/PageLoader";
import ApprovalAllModalProps from "./ActionModals/ApprovalAllModal.tsx";
import { getEmail } from "../../../App/AppReducer.js";
import { useSelector } from "react-redux";

export const DRAWER_ENUMS: any = {
    TDS: 'TDS',
    BALANCE_CONFIRMATION: 'Balance Confirmation',
    STATEMENT_OF_ACCOUNT: 'Statement of Account',
    NO_DUES_CERTIFICATE: 'No Dues Certificate',
    OTHER_TICKET: 'Others'
}

const STATUS_LIST_ENUM : any = {
    NOT_SUBMITTED: 'NOT SUBMITTED',
    APPROVED: 'APPROVED',
    RESOLVED: 'RESOLVED',
    SUBMITTED: 'SUBMITTED',
    REJECTED: 'REJECTED',
    INV_REJECTED: 'INV. REJECTED',
    VERIFIED: 'VERIFIED'
}
export const getTdsStatusColors = (status: string) => {
    let color = '';
    let banner = '';
    switch (status) {
        case STATUS_LIST_ENUM.NOT_SUBMITTED:
            color = '#D6BB0A';
            banner = '#FFFAD7';
            break;
        case STATUS_LIST_ENUM.APPROVED:
        case STATUS_LIST_ENUM.RESOLVED:
            color = '#00AA00';
            banner = '#E5FCDE';
            break;
        case STATUS_LIST_ENUM.SUBMITTED:
            color = '#9E3CB9';
            banner = '#FDF4FF';
            break;
        case STATUS_LIST_ENUM.REJECTED:
        case STATUS_LIST_ENUM.INV_REJECTED:
            color = '#DF666A';
            banner = '#FDF0EC';
            break;
        case STATUS_LIST_ENUM.VERIFIED:
            color= '#D6BB0A';
            banner = '#FFFAD7';
    }
    return {color, banner};
};

const intitalMenuStyle = {
    borderRadius: "3px",
    boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
    background: "rgba(255, 255, 255, 0.9)",
    padding: "2px 5px",
    fontSize: "12px",
    fontWeight: "normal",
    position: "fixed",
    overflow: "auto",
    maxHeight: "50%",
    zIndex: 2,
    fontFamily: "Gilroy-SemiBold",
    cursor: "pointer",
  };

const CustomerRequests = () => {
    const [investeeName, setInvesteeName] = React.useState('')
    const [investorName, setInvestorName] = React.useState('')
    const history = useHistory();
    const BUTTON_ENUMS: any = {
        ALL: 'All',
        TDS: 'TDS',
        DOCUMENT_TICKETS: 'Document Ticket',
    };
    const [loading, setLoading] = React.useState(false)
    const [rowData, setRowData] = React.useState([])
    const [buttonSelected, setButtonSelected] = React.useState(BUTTON_ENUMS.ALL)
    const [triggerDrawer, setTriggerDrawer] = React.useState('closed')
    const [claimData, setClaimData] = React.useState('')
    const [statusList, setstatusList] = React.useState<string[]>([])
    const [showApproveAllModal, setShowApproveAllModal] = React.useState<boolean>(false);
    const [disableVerifiedDropDown, setDisableVerifiedDropDown] = React.useState<boolean>(false);
    const [verifiedTicketCount, setVerifiedTicketCount] = React.useState<Number>(0);
    const email = useSelector(getEmail)

    const [query, setQuery] = React.useState({
        status: ""
      });

    const shouldRender = (item : any, value: any) =>
        item?.label?.toLowerCase().indexOf(value.toLowerCase()) > -1;

    const onStatusSelect = (status: string) => {
        let obj = [...statusList]
        obj.push(status);
        setstatusList(obj);
    }

    const clearFilters = (e : any, allClear = false, status: any) => {
        e.stopPropagation();
        if(allClear){
            setstatusList([])
            return
        }
        let obj = statusList.filter(s => s !== status);
        setstatusList(obj);
    }

    const handleDropdownChange = (e: any) =>{
        if(e === 'Download'){
            downloadTdsTransferZip()
        }
        else if(e === 'Approve'){
            showVerifiedTickets()

        }
    }

    const showVerifiedTickets = async () => {
        setLoading(true);
        setstatusList([STATUS_LIST_ENUM.VERIFIED])
        setButtonSelected(BUTTON_ENUMS.TDS);
        setLoading(false);
        setShowApproveAllModal(true);
    }

    const approveAllVerifiedTickets = async () => {
        setShowApproveAllModal(false);
        setLoading(true);
        const res = await approveBulkTdsClaims({customerSupportQueryIdList: [], activityBy: email});
        if (res) {
            showNotification(SHOW_NOTIFICATION.SUCCESS, 'Successfully Approved verified tickets')
            await getRowData();
        } else {
            showNotification(SHOW_NOTIFICATION.ERROR, 'Error in approving verified tickets, please try again later!')
        }
        setLoading(false);
    }

    const downloadTdsTransferZip = async () => {
        setLoading(true)
        const res = await downloadTdsTransferZipFile({});
        if (res && res.length !== 0) {
            const url = window.URL.createObjectURL(
                new Blob([res], { type: 'application/zip' }),
            );
            const link = document.createElement('a');
            link.href = url;
            link.download = 'TDS.zip';
            document.body.appendChild(link);
            link.click();
        } else {
            showNotification('error', 'Unable to download file. Please try again later.');
        }
        setLoading(false)
    }

    const getRowData = async () => {
        setLoading(true);
        const ticketType = Object.keys(BUTTON_ENUMS).find((key) => BUTTON_ENUMS[key] === buttonSelected) ?? '';
        let payload:any = {
            requestType:ticketType,
        }
        if(investeeName) payload['investeeName'] = investeeName
        if(investorName) payload['investorName'] = investorName
        if(statusList) payload['statusList'] = statusList
        const res = await fetchCustomerQueries(payload);
        if (res) {
            setLoading(false);
            setRowData(res?.data);
            
            let verifiedCount = res?.statusCountMap[STATUS_LIST_ENUM.VERIFIED] ?? 0;
            setVerifiedTicketCount(verifiedCount);
            if(verifiedCount <= 0) setDisableVerifiedDropDown(true);
            else setDisableVerifiedDropDown(false);
        }
        setLoading(false);
    };
    useEffect(() => {
        if(triggerDrawer===''){
            history.push({
                search: ''
            });
        }
    }, [triggerDrawer]);
    const debounced = debounce(() => {
        getRowData();
    }, 1000);
    const handleTextSearch = (value: string, key: string) => {
        // implementing search with debounce of 1 sec to avoid multiple api calls
        if(key === 'investeeName') setInvesteeName(value)
        if(key === 'investorName') setInvestorName(value)
    }
    useEffect(() => {
        debounced();
        return debounced.cancel;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investeeName]);
    useEffect(() => {
        debounced();
        return debounced.cancel;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investorName]);
    useEffect(() => {
        getRowData();
        setInvestorName('')
        setInvesteeName('')
    }, [buttonSelected]);
    useEffect(() => {
        const fetchData = async () => {
            await getRowData();
        };
        fetchData();
    }, [statusList]);
    const handleRowClick = (e:any,row: any) => {
            e.stopPropagation()
        history.push({
            search: '?'+new URLSearchParams({drawer: row.requestType, id: row.customerQueryId}).toString()
        });
            setTriggerDrawer(row.requestType)
            setClaimData(row)
    }
    const refetchData = async () => {
        const res = await getRefetchTdsClaimsData();
        if (res) {
            showNotification(SHOW_NOTIFICATION.SUCCESS, 'Data Refetched successfully!')
            getRowData();
        } else {
            showNotification(SHOW_NOTIFICATION.ERROR, 'Error in refetching data, please try again later!')
        }
    }
    useEffect(() => {
        let params = new URLSearchParams(history.location.search);
        if (params.get('drawer') && params.get('id')) {
            setTriggerDrawer(params.get('drawer') ?? '')
            setClaimData( rowData.find((row: any) => row.customerQueryId === params.get('id')) ?? '')
        }
    }, [rowData]);
    const getRows = () => {
        if (isEmpty(rowData)) {
            return <tbody className={'noDataInTable'}>No Queries available</tbody>;
        } else {
            return (
                <tbody>
                {rowData.map((row: any, index: number) => {
                    return (
                        <tr
                            style={{fontSize: '12px'}}
                            onClick={(e) => handleRowClick(e,row)}
                            key={index}
                        >
                            <td style={{width: '10%'}}>
                                {<span
                                    style={(!("investorOrganizationId" in row) && row.requestType !== 'Others') ? {backgroundColor: '#DF666A'} : {background: 'transparent'}}
                                    className='redDot'></span>}{row.customerQueryId}</td>
                            <td>{row.requestType !== 'Others' ? row.requestType : row.ticketDetails.ticketSubType}</td>
                            <td style={{width: '15%'}}>{row.investeeName ?? '-'}</td>
                            <td style={{width: '15%'}}>{row.investorName ?? '-'}</td>
                            <td style={{width: '15%'}}>{row.duration ?? '-'}</td>
                            <td style={{width: '10%'}}>{<span
                                className={'StatusBannerCustomerSupport'}
                                style={{
                                    color: getTdsStatusColors(row.status).color,
                                    background: getTdsStatusColors(row.status).banner,
                                }}
                            >
                                        {row.status}
                                    </span>}</td>
                            <td style={{width: '10%'}}>{row.lastActivityDate}</td>
                            <td>{moment(row.submittedOn).format('DD MMM YYYY')}</td>
                        </tr>
                    );
                })}
                </tbody>
            );
        }
    }
    const getHeaders = () => {
        return [
            {
                jsx: <span style={{marginLeft: '-1rem'}}>Ticket ID</span>,
            },
            {
                jsx: 'Ticket Type',
            },
            {
                jsx: (
                    <span className='header_input'>
                        <input
                            value={investeeName}
                            onChange={(e) => handleTextSearch(e.target.value, "investeeName")}
                            type='text'
                            className='form-control'
                            placeholder='Company Name'
                            style={{border: "none", fontSize: "14px", paddingTop: 0, paddingBottom: 0}}>
                        </input>
                    </span>
                ),
            },
            {
                jsx: (
                    <span className='header_input'>
                        <input
                            value={investorName}
                            onChange={(e) => handleTextSearch(e.target.value, "investorName")}
                            type='text'
                            className='form-control'
                            placeholder='Investor'
                            style={{border: "none", fontSize: "14px", paddingTop: 0, paddingBottom: 0}}>
                        </input>
                    </span>
                ),
            },
            {
                jsx: 'Duration',
            },
            {
                jsx: (
                  <span className='header_input' style={{width:'10%',marginLeft:'-0.8rem'}}>
                    <ReactAutoComplete
                      inputProps={{
                        type: "text",
                        placeholder: "Status",
                      }}
                      items={Object.values(STATUS_LIST_ENUM || []).filter((i) => i !== STATUS_LIST_ENUM.NOT_SUBMITTED).map((i) => ({ label: i, id: i }))}
                      value={query?.status}
                      getItemValue={(item) => item.label}
                      shouldItemRender={shouldRender}
                      onChange={(e) => {
                        setQuery({ status: e.target.value });
                      }}
                      onSelect={(value) => {
                        onStatusSelect(value);
                      }}
                      renderItem={(item, isHighlighted) => (
                        <div
                          style={{
                            background: isHighlighted ? "#2F8FFF" : "white", 

                          }}
                        >
                          {item.label}
                        </div>
                      )}
                      //@ts-ignore
                      menuStyle={{ ...intitalMenuStyle }}
                    />
                  </span>
                ),
            },
            {
                jsx: 'Last Activity',
            },
            {
                jsx: 'Submitted Date',
            }
        ];
    }
    return (
        <div className="main-body">
            {loading ? <PageLoader showOverlay /> : ""}
            <AdminHead/>
            <section className='w-100 py-5'>
                <div className='fs21 px-5 pb-3'>Customer Support</div>
                <div className="page-head-bottom-border"></div>
                <div className='tdsTableHeadWrapper'>
                    <div className='tdsTableHeadContainer'>
                        <div className='TdsbtnConatiner'>
                            {
                                Object.keys(BUTTON_ENUMS)?.map((key) => {
                                    return (
                                        <div className='ButtonContainer'>
                                            {BUTTON_ENUMS[key] === BUTTON_ENUMS.ALL && (
                                                <label htmlFor="button-label">
                                                    Show request for
                                                </label>
                                            )}
                                            <button
                                                id={
                                                    BUTTON_ENUMS[key] === BUTTON_ENUMS.ALL
                                                        ? 'button-label'
                                                        : ''
                                                }
                                                className={clsx({
                                                    ['Active']: BUTTON_ENUMS[key] === buttonSelected,
                                                    ['Inactive']: BUTTON_ENUMS[key] !== buttonSelected,
                                                })}
                                                onClick={() => {
                                                    setButtonSelected(BUTTON_ENUMS[key]);
                                                }}
                                            >
                                                {BUTTON_ENUMS[key]}
                                            </button>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div className='TdsTableLabel'>
                            Showing <span
                            style={{fontFamily: 'gilroy-bold,serif'}}>{isEmpty(rowData) ? 0 : 1} - {rowData?.length}</span> of {rowData?.length} entries
                        </div>
                    </div>
                    <div>
                        <button
                            onClick={() => {
                                refetchData();
                            }}
                            className={'refetchButton'}
                        >
                            <img
                                src={'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/refetchCibilBlue.svg'}
                                alt="new request"
                            />
                            Refetch Data
                        </button>
                    </div>
                        <span style={{marginLeft: "2rem"}}>
                            <select
                                disabled={disableVerifiedDropDown}
                                className={"VerifiedDropDown"}
                                value={""}
                                onChange={(e)=>handleDropdownChange(e.target.value)}
                                >
                                <option disabled value=''>Verified Tickets</option>
                                <option value='Download'>Download All</option>
                                <option value='Approve'>Approve All</option>
                            </select>
                        </span>
                </div>
                <div className='filter_arr_wrapper'>
                    {statusList.map((status) => (
                        <span className="filterItem" onClick={(e) => clearFilters(e, false, status)}>
                        {status} <img height={15} width={15} src={clearIcon} alt="clear icon" />
                        </span>
                    ))}
                    {!isEmpty(statusList) && (
                        <span
                        className='clear_all'
                        onClick={(e) => clearFilters(e, true, "")}
                        >
                        Clear All Filter
                        </span>
                    )}
                </div>
                <RecurTable
                    changeColorOnHover={true}
                    style={{}}
                    headerStyle={{}}
                    headers={getHeaders()}
                    loading={loading}
                    renderRows={getRows()}
                />
                <TdsDrawer setOpenDrawer={setTriggerDrawer} openDrawer={triggerDrawer} claimData={claimData}
                           getClaimData={getRowData}/>
                <BalanceConfirmationDrawer openDrawer={triggerDrawer} setOpenDrawer={setTriggerDrawer} claimData={claimData} getClaimData={getRowData}/>
                <StatementOfAccountDrawer openDrawer={triggerDrawer} setOpenDrawer={setTriggerDrawer} claimData={claimData} getClaimData={getRowData}/>
                <NoDuesCertificateDrawer openDrawer={triggerDrawer} setOpenDrawer={setTriggerDrawer} claimData={claimData} getClaimData={getRowData}/>
                <OtherTicketDrawer openDrawer={triggerDrawer} setOpenDrawer={setTriggerDrawer} claimData={claimData} getClaimData={getRowData}/>

            </section>
            {showApproveAllModal &&
                <ApprovalAllModalProps show={showApproveAllModal} onClose={() => setShowApproveAllModal(false)}
                    approveAllClaims={approveAllVerifiedTickets} ticketCount={verifiedTicketCount.toString()}/>
            }
        </div>
    )
}

export default CustomerRequests;
