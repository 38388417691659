import { useEffect, useState } from 'react';
import { Mixpanel } from './mixpanel';

const usePageTracking = () => {
  const [pathname, setPathname] = useState(window.location.href);

  useEffect(() => {
    const handlePathnameChange = () => {
      setPathname(window.location.href);
    };


    window.addEventListener('popstate', handlePathnameChange);
    return () => {
      window.removeEventListener('popstate', handlePathnameChange);
    };
  }, []);

  useEffect(() => {
    const pageProperties = {
      page_path: pathname,
    };

    Mixpanel.track('Page View', pageProperties); 
  }, [pathname]);
};

export default usePageTracking;

