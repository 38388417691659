import React, {
    Component,
    // useEffect, 
    // useState 
} from "react";
import "../css/Filters.css";
// import { Link } from 'react-router-dom';
// import { connect } from 'react-redux';

class InputSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyName: this.props.keyName,
            searchKeyValue: this.props.value,
            typing: false,
            typingTimeout: 0
        };
    }

    searchChange = (e) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }
        this.setState({
            searchKeyValue: e.target.value,
            typing: false,
            typingTimeout: setTimeout(() => {
                this.props.handleSearchFilter({
                    keyName: this.state.keyName,
                    value: e.target.value
                });
            }, 1000)
        });
    }

    render() {
        return (
            <section>
                <div className="filter-search border-shadow">
                    <div className="filter-type">{this.props.labelText}</div>
                    <div class="filter-searchbar">
                        <span class="filter-search-icon">
                            <img alt="" src="../../../../../assets/search.png" style={{ height: '14px', width: '14px' }} />
                        </span>
                        <input
                            value={this.state.searchKeyValue}
                            onChange={(e) => this.searchChange(e)}
                            type="text" className="filter-search-input" placeholder="Search...">
                        </input>
                    </div>
                </div>
            </section>
        );
    }
}

export default InputSearch;
