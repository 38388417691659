import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    // Redirect
} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import './App.css';
import LoginPage from './modules/App/LoginPage/LoginPage';
import PageContent from "./layouts/PageContent";
import CacheBuster from "./CacheBuster";
import { get } from "lodash";
import NavigateFunctionComponent from "./common/NavigateFunctionComponent.tsx";

function App(props) {
    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL)
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                window.localStorage.setItem(
                    'myIp',
                    get(JSON.parse(res.headers), 'x-forwarded-for'),
                );
            })
            .catch((err) => console.error('Problem fetching my IP', err));
    }, []);
    return (
        <CacheBuster>
            {({loading, isLatestVersion, refreshCacheAndReload}) => {
                if (loading) return null;
                if (!loading && !isLatestVersion) {
                    // You can decide how and when you want to force reload
                    refreshCacheAndReload();
                }
                return (
                    <React.Fragment>
                        <div className="App">
                            <Router>
                            <NavigateFunctionComponent/>
                                <Switch>
                                    <Route
                                        exact path="/"
                                        component={(props) => (
                                            <LoginPage
                                                {...props}
                                            />
                                        )}
                                    />
                                    <PageContent history={props.history}/>
                                </Switch>
                            </Router>
                            <ToastContainer/>
                        </div>
                    </React.Fragment>
                );
            }}
        </CacheBuster>
    );
}

export default App;
