import { isEmpty } from "lodash";
import React from "react";
import { useEffect, useState } from "react";
import { useHistory,useLocation } from "react-router-dom";
import { fetchComplianceData } from "../../../../../../common/ApiHelpers";
import PageLoader from "../../../../../../components/loader/PageLoader";
import styles from "./compliance.module.scss";
import ComplianceRow from "./ComplianceRow";
import { SORTING } from "../../../../../../constants/commonConstants";
import ReactAutocomplete from "react-autocomplete";

const intitalMenuStyle = {
    borderRadius: "3px",
    boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
    background: "rgba(255, 255, 255, 0.9)",
    padding: "2px 5px",
    fontSize: "13px",
    fontWeight: "normal",
    position: "fixed",
    overflow: "auto",
    maxHeight: "50%",
    zIndex: 2,
    fontFamily: "Gilroy-SemiBold",
    cursor: "pointer",
    marginTop: "5px",
};
const ComplianceViewPage = ({showRelevantCompliance}) => {

    const COMPLIANCE = "compliance";
    const history = useHistory();
    const [dataRows,setDataRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortNachSummary, setsortNachSummary] = useState(SORTING.SORT_NEU);
    const [spocDropDownList, setSpocDropDownList] = useState([]);
    const [overallSummaryDropDownList, setOverallSummaryDropDownList] = useState([]);
    const [qText, setQText] = useState("");
    const initialComplianceFilterObj = {
        searchFilters:{
            companyName:'',
            lastSpoc:'',
            overallSummary:'',
            showRelavant: true,
        },
        sortFilters:{
            sortingField:'',
            sortingOrder:1,
        }
    };
    const [complianceFilterObj, setComplianceFilterObj] = useState({
        ...initialComplianceFilterObj,
    });
    
    useEffect(()=>{
        if(isEmpty(dataRows)){
            complianceApiHandler(complianceFilterObj);
        }
    },[])
    
    useEffect(()=>{
        const delayDebounceFn = setTimeout(() => {
            complianceApiHandler(complianceFilterObj);
          }, 600)
        return () => clearTimeout(delayDebounceFn)
    }, [complianceFilterObj])

    useEffect(()=>{      
        complianceFilterObj.searchFilters.showRelavant = showRelevantCompliance
        let params = new URLSearchParams(history.location.search);
        params.set('getRelevant', showRelevantCompliance);
        params.set('tab', COMPLIANCE);
        history.push({
            search: params.toString(),
        });
    },[showRelevantCompliance, complianceFilterObj.sortFilters.sortingField, complianceFilterObj.searchFilters.companyName])
    useEffect(()=>{
      let obj = {...complianceFilterObj}
        obj.searchFilters.showRelavant = showRelevantCompliance
        setComplianceFilterObj(obj);
    },[showRelevantCompliance])

    const complianceApiHandler = async(complianceFilter)=>{
        const res=await fetchComplianceData(complianceFilter,setLoading);
        setDataRows(res?.complianceList??[]);
        setSpocDropDownList(res?.spocList??[]);
        setOverallSummaryDropDownList(res?.overAllSummaryStatusList??[]);
    }
    const handleSorting = (sortingField) => {
        let obj = {...complianceFilterObj}
        obj.sortFilters.sortingField = sortingField
        if(sortNachSummary === SORTING.SORT_ASC){
            obj.sortFilters.sortingOrder = -1
            setsortNachSummary(SORTING.SORT_DES)
        }
        else{
            obj.sortFilters.sortingOrder = 1
            setsortNachSummary(SORTING.SORT_ASC)
        }
        setComplianceFilterObj(obj);
    }
    const {complianceSection, ComplianceTableCard, wid20, wid15, wid10, header_input} = styles;

    const handleTextSearch = (val) => {
        let obj = {...complianceFilterObj}
        obj.searchFilters.companyName = val
        setComplianceFilterObj(obj);

    };

    return (
        <div className="main-body">
            {loading ?<PageLoader />
                :<section className={complianceSection}>
                    <div className={`${ComplianceTableCard} mt-4`} >
                    <table>
                        <thead>
                            <tr>
                                <th className={wid20} 
                                    colspan="2"
                                >   
                                    <span className={header_input}>
                                        <input
                                        value={complianceFilterObj.searchFilters.companyName}
                                        onChange={(e) => handleTextSearch(e.target.value)}
                                        type="text"
                                        className="form-control"
                                        placeholder="Company Name"
                                        style={{
                                            border: "none",
                                            fontSize: "13px",
                                        }}
                                        ></input>
                                    </span>
                                </th>
                                <th className={wid10}>
                                    <span className={header_input}>
                                    <ReactAutocomplete
                                        inputProps={{
                                            type: "text",
                                            name: "Last Spoc",
                                            placeholder: "Last Spoc",
                                        }}
                                        items={spocDropDownList}
                                        value={complianceFilterObj.searchFilters.lastSpoc}
                                        onChange={(e) => {
                                            let obj = {...complianceFilterObj}
                                            obj.searchFilters.lastSpoc = e.target.value
                                            setComplianceFilterObj(obj);
                                        }}
                                        onSelect={(value) => {
                                            let obj = {...complianceFilterObj}
                                            obj.searchFilters.lastSpoc = value
                                            setComplianceFilterObj(obj);
                                        }}
                                        renderItem={(item, isHighlighted) => (
                                            <div
                                                style={{
                                                    background: isHighlighted ? "#2F8FFF" : "white",
                                                    padding: "5px",
                                                    margin: "-2px -5px",
                                                    opacity: "1"
                                                }}
                                            >
                                                {item}
                                            </div>
                                        )}
                                        getItemValue={(item) => item}
                                        menuStyle={{ ...intitalMenuStyle }}
                                     />
                                    </span>
                                </th>
                                <th className={wid20}>
                                    <span className={header_input}>
                                    <ReactAutocomplete
                                        inputProps={{
                                            type: "text",
                                            name: "Overall Summary",
                                            placeholder: "Overall Summary",
                                        }}
                                        items={overallSummaryDropDownList}
                                        value={complianceFilterObj.searchFilters.overallSummary}
                                        onChange={(e) => {
                                            let obj = {...complianceFilterObj}
                                            obj.searchFilters.overallSummary = e.target.value
                                            setComplianceFilterObj(obj);
                                        }}
                                        onSelect={(value) => {
                                            let obj = {...complianceFilterObj}
                                            obj.searchFilters.overallSummary = value
                                            setComplianceFilterObj(obj);
                                        }}
                                        renderItem={(item, isHighlighted) => (
                                            <div
                                                style={{
                                                    background: isHighlighted ? "#2F8FFF" : "white",
                                                    padding: "5px",
                                                    margin: "-2px -5px",
                                                    opacity: "1"
                                                }}
                                            >
                                                {item==='PartiallyComplete'?'Partially Complete':item}
                                            </div>
                                        )}
                                        getItemValue={(item) => item}
                                        menuStyle={{...intitalMenuStyle}}
                                    />
                                    </span>
                                </th>
                                <th className={wid15}>NACH Summary
                                    <button
                                        onClick={() => handleSorting('nachSummary')}
                                        style={{border: "none", marginLeft: "7px"}}
                                    >
                                        &#8645;
                                    </button>
                                </th>
                                <th className={wid15}>Repayment Remaining
                                    <button
                                        onClick={() => handleSorting('repaymentRemainingAmount')}
                                        style={{border: "none", marginLeft: "7px"}}
                                    >
                                        &#8645;
                                    </button>
                                </th>
                                <th className={wid10}>Start Date
                                    <button
                                        onClick={() => handleSorting('startDate')}
                                        style={{border: "none", marginLeft: "7px"}}
                                    >
                                        &#8645;
                                    </button>
                                </th>
                                <th className={wid10} colspan="2">Last Update
                                    <button
                                        onClick={() => handleSorting('lastUpdateDate')}
                                        style={{border: "none", marginLeft: "7px"}}
                                    >
                                        &#8645;
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {dataRows && !isEmpty(dataRows) ?
                            dataRows?.map((item, index) => (
                                <ComplianceRow data={item} index={index}/>
                            )) :
                            <tr>
                                <td colSpan="9" style={{textAlign: "center"}}>No Data Found</td>
                            </tr>
                        }
                        </tbody>
                    </table>
                    </div>
                </section>
            }
        </div>
    );
};

export default ComplianceViewPage;
