import "./StatusTags.scss"
const Received=()=>{
    return (
        <div className="wrapper received " >
            <p>Received</p>
        </div>
    )
}

const Requested=()=>{
    return (
        <div className="wrapper requested">
            <p>Requested</p>
        </div>
    )
}

const Approved=()=>{
    return (
        <div className="wrapper approved">
            <p>approved</p>
        </div>
    )
}

const Incomplete=()=>{
    return (
        <div className="wrapper incomplete">
            <p>Incomplete</p>
        </div>
    )
}

const Open=()=>{
    return (
        <div className="wrapper open">
            <p>Open</p>
        </div>
    )
}

const COMPONENTENUMS={
    Submitted: Received,
    Approved: Approved,
    "Need Information": Requested,
    "Not Approved": Incomplete,
    Open
}
export default COMPONENTENUMS