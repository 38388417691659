import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNachToggleKeys } from "../../../../constants/enums";

const ENABLE_TEXT = "enable";
const DISABLE_TEXT = "disable";
const CANCEL_NACH_PLACEHOLDER = "Cancel Nach Because";
const KEEP_NACH_PLACEHOLDER = "Keep Nach Because";


export const CancelNachModal = ({
    showCancelNachModal,
    setShowCancelNachModal,
    updateComments,
    nachComments,
    setNachComments,
    setUseNachSelected,
    modifyApiPayload,
    useNach
}) => {
  
  const handleClose = () => {
    setShowCancelNachModal(false);
  };

  const cancelNach = () => {
    if(nachComments.trim().length === 0) return;
    setShowCancelNachModal(false);
    setUseNachSelected(false);
    modifyApiPayload("useNach", useNachToggleKeys.NO);
    updateComments();
  }

  const keepNach = () => {
    if(nachComments.trim().length === 0) return;
    modifyApiPayload("useNach", useNachToggleKeys.YES);
    setUseNachSelected(true);
    updateComments(null, true);
    setShowCancelNachModal(false);
  }

  const enableOrDisableText = useNach && useNach === useNachToggleKeys.NO? ENABLE_TEXT : DISABLE_TEXT;
  const keepCancelNachPlaceholderText = useNach && useNach === useNachToggleKeys.NO? KEEP_NACH_PLACEHOLDER : CANCEL_NACH_PLACEHOLDER;
  
  return (
    <Modal
      show={showCancelNachModal}
      onHide={handleClose}
      className="cancelNachModal"
    >
      <Modal.Body>
        <div className="d-flex justify-content-between mt-2">
        <div className={`fw600 mt-1`}>
        Are you sure you want to {enableOrDisableText} NACH scheduled?
        </div>
        <div>
        <button onClick={handleClose} className="closeModal">
        <img src="/assets/modal-cross.svg" alt=""></img>
      </button>
        </div>
        </div>
        <textarea
            // autoFocus={isEditComments}
            className="mt-4 form-control"
            value={nachComments}
            onChange={(e) => setNachComments(e.target.value)}
            // ref={commentsBoxRef}
            placeholder={keepCancelNachPlaceholderText}
          />
        <div className="d-flex justify-content-between b-row">
          <button className="KeepNach border-0 rounded" onClick={keepNach} disabled={!(useNach && useNach === useNachToggleKeys.NO)}>
            Keep NACH
          </button>
          <button className="CancelNach border-0 rounded" onClick={cancelNach} disabled={(useNach && useNach === useNachToggleKeys.NO)}>
            Cancel NACH
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
