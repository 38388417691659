import React from "react";
import styled from "styled-components";
import { CURRENT_TAB_TYPE } from "../../../../../../../../../../common/ENUM";

//StyledContainer Width was : ${98 * tabsLength}px;
const StyledContainer = styled.div`
  width: max-content;
  margin: 0px 0px;
  padding: 5px;
  border-radius: 4px;
  background-color: #f8f8f8;
  height: max-content;
  display: flex;
  align-items: center;
`;
const StyledUL = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  list-style: none;
  border: none;
  padding: 0px;
`;

const StyledList = styled.li`
  margin-bottom: -1px;
`;

const StyledTabs = styled.a`
  width: max-content;
  color: ${(props) => (props.active ? "#FFFFFF" : "#939393")};
  font-family: "Graphik";
  font-size: 10px;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  font-weight: 500;
  display: flex;
  align-item: center;
  padding: 4px 8px;
  margin-right: 0px;
  text-decoration: none;
  border-radius: ${(props) => (props.active ? "4px" : "0px")};
  background-color: ${(props) => (props.active ? "#4C8DFF" : "transparent")};
  border-color: ${(props) => (props.active ? "#dee2e6 #dee2e6 #fff" : null)};
  &:hover {
    color: ${(props) => (props.active ? "#FFFFFF" : "#939393")};
  }
`;

const MIS_TABS = [
  { key: "classified", label: "Classified" },
  { key: "raw", label: "Raw Data" },
];
const INVOICES_TABS = [
  { key: "raw", label: "Raw Data" },
  { key: "classified", label: "Pivot Data" },
  { key: "spread", label: "Spread Data" },
];

const BANK_TABS = [
  { key: 'summary', label: 'Summary' },
  { key: "classified", label: "Classified" },
  { key: "raw", label: "Raw Data" },
  { key: 'cashflow', label: 'Pivot' },
  { key: "pdf", label: "View Pdf" },
  { key: "excel_report", label: "Excel Report" },
];

const GST_TABS = [
  { key: "profile", label: "Profile" },
  { key: "gst", label: "GST" },
  { key: "gstr2a", label: "GSTR2A" },
  { key: "top-vendors", label: "Top Vendors" },
  { key: "top-customers", label: "Top Customers" },
  { key: "classified", label: "Pivot" },
  { key: "spread", label: "Spread" },
];

const CONTRACT_TABS = [{ key: "contracts", label: "Contracts" }];

const DEBT_SCHEDULE_TABS = [{ key: 'summary', label: 'Summary'}];

function StatusBarClassified({ dataType, currentTab, setDataType }) {
  const handleClick = (label) => {
    setDataType(label);
  };

  const activeTabs = () => {
    switch(currentTab) {
      case CURRENT_TAB_TYPE.invoices: return INVOICES_TABS;
      case CURRENT_TAB_TYPE.bank_statements: return BANK_TABS;
      case CURRENT_TAB_TYPE.GST: return GST_TABS;
      case CURRENT_TAB_TYPE.contracts: return CONTRACT_TABS;
      case CURRENT_TAB_TYPE.debt_schedule: return DEBT_SCHEDULE_TABS;
      default: return MIS_TABS;
    }
  }  

  return (
    <StyledContainer style={{ marginTop: "0px" }}>
      <StyledUL role="tablist">
        {activeTabs().map((item, index) => (
          <StyledList key={`status-bar-item-${index}`}>
            <StyledTabs
              data-toggle="tab"
              href={`menu-${index + 1}`}
              onClick={() => handleClick(item.key)}
              active={dataType === item.key}
            >
              <span>{item.label}</span>
            </StyledTabs>
          </StyledList>
        ))}
      </StyledUL>
    </StyledContainer>
  );
}

export default StatusBarClassified;
