import React from 'react'
import { Modal } from 'react-bootstrap'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'
import './MailAttachment.scss'
import { isEmpty } from 'lodash'
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer'

function ViewDocModal(props:any) {
    const {doc,showModal,setShowModal} = props
    const PDF_FILE_TYPE = 'application/pdf';
    const isPdfFileType = () => doc?.fileType === PDF_FILE_TYPE;
  return (
    <Modal
        contentClassName='showDocModal'
        show={showModal}
        onHide={() => setShowModal(false)}
        style={{ zIndex: 1201 }}
        backdropClassName="customBackdropClassName"
    >
      <ModalHeader
        closeButton
      >
        {doc?.fileName}
      </ModalHeader>
        <Modal.Body>
        <div>
        {!isEmpty(doc) && (isPdfFileType() ? (
                <iframe src={doc?.signedUri ?? doc?.uri} width="100%" height="1000px" loading='lazy' />
            ) : (
                <DocViewer
                    style={{ height: '85vh' }}
                    pluginRenderers={DocViewerRenderers}
                    config={{
                      header:{
                        disableFileName: true,
                      }
                    }}
                    documents={[doc]}
                />
            ))}
            </div>
        </Modal.Body>
    </Modal>
  )
}

export default ViewDocModal