import React, { Component, useState } from "react";
import CompaniesTableHeader from "../CompaniesPage/components/CompaniesTableHeader";
import CompaniesTableRow from "../CompaniesPage/components/CompaniesTableRow";
import AdminHead from "../../components/AdminHead.js";
import callApi from "../../../../util/apiCaller";
import { showNotification } from "../../../../util/utility";
import _, { isEmpty, isString } from "lodash";
import {
  getAggregrateDetailsUW,
  getCompanyStatues,
  getInvestees,
  getInvesteesTotalCount,
  getIsFetchingInvestees,
  getUnderwriters,
  getUnderWritingStatuses,
} from "../../AdminReducers";
import { fetchCompaniesDetails } from "../../AdminActions";
import { connect } from "react-redux";
import styles from "./components/CompaniesTableRow.module.scss";
import clearIcon from "../../../../assets/clear.svg";
import CompaniesStatusBar, {
  companiesTab,
} from "./components/CompaniesStatusBar";
import ApprovedLimits from "./components/ApprovedLimits/ApprovedLimits";
import ToggleButton from "../../../../components/ToggleButton/ToggleButton";
import { getCheckIfUserHasRelevantPermissions } from "../../../App/AppReducer";
import DealsViewPage from "./components/DealsViewPage/DealsViewPage";
import { UNDERWRITING_STATUS, COMPANY_STATUS } from "../../../../common/ENUM.js";
import ComplianceViewPage from "./components/ComplianceViewPage/ComplianceViewPage";
import { ViewFilters } from "./ViewFilters/ViewFilters";
import { VIEW_FILTERS, VIEW_FILTERS_API_KEY } from "../../../../constants/enums";
import PageLoader from "../../../../components/loader/PageLoader";

export const tabsEnum = {
  COMPANIES: "companies",
  APPROVED_LIMITS: "approved_limits",
  DEALS: "deals",
  COMPLIANCE: "compliance"
};

export const queryParams = {
  APPROVED_LIMITS: "approved-limits",
  DEALS: "deals",
  COMPLIANCE: "compliance"
};

const viewFilterOptions = [
  VIEW_FILTERS.VIEW_ALL,
  VIEW_FILTERS.VIEW_NEW,
  VIEW_FILTERS.VIEW_ADD_ON,
  VIEW_FILTERS.VIEW_DS,
  VIEW_FILTERS.VIEW_UNDRAWN_LINES
]

class CompaniesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_fetching_investees: false,
      investees: [],
      underwriters: [],
      pageNum: 1,
      pageSize: 50,
      totalCount: 0,
      qtext: "",
      qtextComments: "",
      sortBy: {},
      hideCompletedExpired: this.getHideExpiredFilterStatus(),
      filterObj: {
        ...this.initialFilterObj,
      },
      showRelevantCompanies: this.getRelevantCompaniesFilterStatus(),
      showRelevantDeals: this.getRelevantDealsFilterStatus(),
      addonToggleBtn: this.getAddonCompaniesFilterStatus(),
      showRelevantCompliance: true,
      companyStatuses: [],
      underWritingStatuses: [],
      currentTab: companiesTab[0].segmentName,
      aggregateNumbers: {},
      viewFilter: VIEW_FILTERS.VIEW_ALL,
      viewCompanyFilter: VIEW_FILTERS.VIEW_ALL,
      viewAll: true,
      viewDs: false,
      viewNew: false,
      viewAddOn: false,
      loading: false
    };
    this.debounceSearch = _.debounce(() => this.fetchCompanies(true), 400);
    this.table = React.createRef();
    this.firstRow = React.createRef();
    this.clearAllRef = React.createRef(false);
  }

  getHideExpiredFilterStatus = () => {
    const search = this.props.location.search;
    const filter = new URLSearchParams(search).get("show-hide-expired-filter");
    const stringToBoolean = (string) => (string === "false" ? false : true);
    return stringToBoolean(filter);
  };

  getRelevantCompaniesFilterStatus = () => {
    const search = this.props.location.search;
    const filter = new URLSearchParams(search).get("show-relevant-filter");
    if (filter === "true" || this.props.isUserHasUWViewAccess) return true;
    else return false;
  };

  getRelevantDealsFilterStatus = () => {
    const search = this.props.location.search;
    const filter = new URLSearchParams(search).get("show-relevant-deal-filter");
    if (filter === "true") return true;
    else return false;
  }

  getAddonCompaniesFilterStatus = () => {
    const search = this.props.location.search;
    return !!new URLSearchParams(search).get("addon");
  };

  initialFilterObj = {
    companyStatus: [],
    underWriter: [],
    underWritingStatus: [],
  };

  keyStateObjNameDict = {
    arr: "sliderObjArr",
    recurring_payment_value: "sliderObjPrice",
    trading_limit: "sliderObjTradeLimit",
    contract_price: "sliderObjPrice",
    traded_arr: "sliderObjTradedArr",
    fees: "sliderObjFees",
    listed_arr: "sliderObjListedArr",
    next_payment: "sliderObjNextPayment",
    status: "selectorObjStatus",
  };

  handleInitialization = () => {
    const search = this.props.location.search;
    const tab = new URLSearchParams(search).get("tab");
    if (tab === queryParams.APPROVED_LIMITS) {
      this.setState({
        currentTab: tabsEnum.APPROVED_LIMITS,
      });
    } else if (tab === queryParams.DEALS)
      this.setState({
        currentTab: tabsEnum.DEALS,
      });
      else if(tab === queryParams.COMPLIANCE){
        this.setState({
          currentTab: tabsEnum.COMPLIANCE,
        });
      }
    else
      this.setState({
        currentTab: tabsEnum.COMPANIES,
      });
  };

  componentDidMount() {
    this.table?.current?.focus();
    this.handleInitialization();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search)
      this.handleInitialization();
  }

  hasMore = () => {
    return this.state.totalCount + 1 > this.state.pageNum * this.state.pageSize;
  };

  loadMore = () => {
    this.setState({ pageNum: this.state.pageNum + 1 });
    this.fetchCompanies();
  };

  fetchCompanies = (isReseting) => {
    if(!this.props.location.search.includes('tab=compliance')){
    this.setState({
      loading :true
    });
    let filters = this.getFilters();
    if (isReseting) {
      filters.pageNum = 1;
      this.firstRow?.current?.scrollIntoView({ behavior: "smooth" });
    }
    if (!isEmpty(this.state.sortBy)) {
      filters.sortBy = this.state.sortBy;
    }
    this.setState({
      is_fetching_investees: true,
      ...(this.state.qtext),
    });
    this.setState({
      is_fetching_investees: true,
      ...(this.state.qtextComments),
    });
    filters.showRelevantCompanies = this.state.showRelevantCompanies;
    this.props.dispatch(fetchCompaniesDetails(filters, isReseting)).then(() => {
      this.setState({loading :false});
      this.setState({
        is_fetching_investees: false,
        investees: this.props.investees,
        underwriters: this.props.underwriters,
        totalCount: this.props.totalCount,
        companyStatuses: this.props.companyStatuses,
        underWritingStatuses: this.props.underWritingStatuses,
        aggregrateDetails: this.props.aggregrateDetails,
      });
    });
    }
  };

  filterObjRep = () =>
    Object.values(this.state.filterObj)
      .flat()
      .filter((t) => isString(t));

  filterObjEnteries = () => {
    let result = [];
    for (const [key, value] of Object.entries(this.state.filterObj)) {
      if (Array.isArray(value)) {
        value.forEach((v) => result.push({ key, value: v }));
      }
    }
    return result;
  };

  fetchAggregateNumbers = () => {
    callApi("admin/get-aggregate-numbers-approved-limit-tab", "post")
      .then((res) => {
        if (res.status === "Success") {
          this.setState({
            aggregateNumbers: res?.data,
          });
        } else showNotification("error", "Some error occured");
      })
      .catch(() => {
        showNotification("error", "Some error occured");
      });
  };

  checkIfSingleFilterIsPresent = () => {
    let count = 0;
    for (const key in this.state.filterObj) {
      if (this.state.filterObj[key].length === 1) count++;
    }
    if (count === 1) this.clearAllRef.current = true;
  };

  clearFilters = (e, allClear = false, filterType, filterValue) => {
    e.stopPropagation();
    if (allClear) {
      this.clearAllRef.current = true;
      this.setState({
        filterObj: { ...this.initialFilterObj },
      });
      return;
    } else {
      this.checkIfSingleFilterIsPresent();
      this.setState({
        filterObj: {
          ...this.state.filterObj,
          [filterType]: this.state.filterObj?.[filterType].filter(
            (f) => f !== filterValue
          ),
        },
      });
    }
  };

  handleTextSearch = (val , field) => {
    const trimmedVal = val?.trim(); // Trim the input value;
    const trimmedStateValue = this.state[`${field}`]?.trim(); // Trim the existing state value
    this.setState(
      {
        [`${field}`]: val,
      },
      () => {
        if (
          trimmedVal == trimmedStateValue || ( this.state[`${field}`] && //updating state when trim match and not calling api
          JSON.stringify(this.initialFilterObj) !==
            JSON.stringify(this.state.filterObj) )
        ) {
          this.setState({ filterObj: { ...this.initialFilterObj } }, () => {
            window.localStorage.setItem(
              "companiesPageFilterObj",
              JSON.stringify(this.state.filterObj)
            );
          });
        } else {
          this.debounceSearch();
        }
      }
    );
    if (['qtext','qtextComments'].includes(field)) {
      this.setState(
        { showRelevantCompanies: false },
      );
    }
  };

  getFilters = (filterHeaderSelected) => {
    const filters = {};
    filters.pageNum = this.state.pageNum;
    filters.pageSize = this.state.pageSize;
    if (this.state.qtext) {
      filters.isSearching = true;
      filters.qtext = this.state.qtext;
    }
    if (this.state.qtextComments) {
      filters.isSearchingComments = true;
      filters.qtextComments = this.state.qtextComments;
    }

    if (this.state.addonToggleBtn) {
      filters.qtext = "addon";
    }
    if (this.state.viewCompanyFilter) {
      switch(this.state.viewCompanyFilter) {
        case VIEW_FILTERS.VIEW_ADD_ON:
          filters.viewCompanyFilter = VIEW_FILTERS_API_KEY.VIEW_ADD_ON;
          break;
        case VIEW_FILTERS.VIEW_DS:
          filters.viewCompanyFilter = VIEW_FILTERS_API_KEY.VIEW_DS;
          break;
        case VIEW_FILTERS.VIEW_NEW:
          filters.viewCompanyFilter = VIEW_FILTERS_API_KEY.VIEW_NEW;
          break;
        default:
          filters.viewCompanyFilter = VIEW_FILTERS_API_KEY.VIEW_ALL;
        break;
      }
    }
    filters.isFiltering = true;
    filters.isSearching = true;

    const { filterObj } = this.state;
    let { underwriters } = this.state;
    if (isEmpty(underwriters)) {
      const result = JSON.parse(window.localStorage.getItem("underWriterList"));
      underwriters = result;
    }
    if (!isEmpty(filterObj?.underWriter)) {
      const uw_arr = filterObj?.underWriter.map((item) => {
        const uw_obj = underwriters.find((obj) => obj.agent_name === item);
        return (item = uw_obj?._id);
      });
      const newFilterObj = { ...filterObj, ["underWriter"]: uw_arr };
      filters.filters = newFilterObj;
    } else filters.filters = this.state.filterObj;
    return filters;
  };

  handleApplyFilters = () => {
    this.fetchCompanies();
  };

  handleSortingDataCoverage = () => {
    let dataCoverageSort = -1;
    if (this.state.sortBy.dataCoverage === 1) {
      dataCoverageSort = -1;
    }
    if (this.state.sortBy.dataCoverage === -1) {
      dataCoverageSort = 1;
    }
    this.setState(
      {
        sortBy: {
          dataCoverage: dataCoverageSort,
        },
      },
      () => {
        this.fetchCompanies(true);
      }
    );
  };

  handleSortingByDealPriority = () => {
    let sortByDealPriority = -1;
    if (this.state.sortBy.dealPriority === 1) {
      sortByDealPriority = -1;
    }
    if (this.state.sortBy.dealPriority === -1) {
      sortByDealPriority = 1;
    }
    this.setState(
      {
        sortBy: {
          dealPriority: sortByDealPriority,
        },
      },
      () => {
        this.fetchCompanies(true);
      }
    );
  };

  handleSliderFilter = (obj) => {
    let sliderobj = {};
    sliderobj[this.keyStateObjNameDict[obj.keyName]] = obj; // to set state of respective slider object
    this.setState(sliderobj, () => {
      this.handleApplyFilters();
    });
  };

  inputSelectorHandler = (obj) => {
    let selectorObj = {};
    selectorObj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(selectorObj, () => {
      this.handleApplyFilters();
    });
  };
  updateInvesteeOrgStats = (investee_org_id) => {
    callApi(
      `scheduler/update-single-investee-organization-stats/${investee_org_id}`,
      "get"
    ).then((res) => {
      if (res.status === "Success") {
        showNotification("Success", "Stats Updated!");
      } else {
        showNotification("error", "Some error occured");
      }
    });
  };

  AllCompaniesView = () => {
    return (
      <div>
        <div>
        {(this.state.loading) && 
        <PageLoader showOverlay={this.state.loading}/>}
          <div className={styles.filter_arr_wrapper}>
            {this.filterObjEnteries()?.map((o) => (
              <span
                onClick={(e) => this.clearFilters(e, false, o?.key, o?.value)}
              >
                {o?.value} <img src={clearIcon} alt="clear icon" />
              </span>
            ))}
            {!isEmpty(this.filterObjRep()) && (
              <span
                className={styles.clear_all}
                onClick={(e) => this.clearFilters(e, true)}
              >
                Clear All Filter
              </span>
            )}
          </div>
        </div>
        <div className={`px-4 ${styles.uw_table}`}>
          <div
            className={`table-head ${styles.uw_table_header}`}
            ref={this.table}
            style={{ marginTop: "12.5px" }}
          >
            <table>
              <CompaniesTableHeader
                qtext={this.state.qtext}
                handleTextSearch={this.handleTextSearch}
                filterObj={this.state.filterObj}
                setFilterObj={(state) => this.setState({ filterObj: state })}
                fetchCompanies={this.fetchCompanies}
                underwriters={this.state.underwriters}
                companyStatuses={this.state.companyStatuses}
                underWritingStatuses={this.state.underWritingStatuses}
                initialFilterObj={this.initialFilterObj}
                showRelevantCompanies={this.state.showRelevantCompanies}
                is_fetching_investees={this.state.is_fetching_investees}
                clearAllRef={this.clearAllRef}
                handleSortingDataCoverage={this.handleSortingDataCoverage}
                handleSortingByDealPriority={this.handleSortingByDealPriority}
                qtextComments={this.state.qtextComments}
              />
              <tbody style={{ display: "none" }}></tbody>
            </table>
          </div>
          <div className={`tableFixHead table-body ${styles.uw_table_body}`}>
            <table className="table team">
              <tbody>
                {this.state.investees &&
                  this.state.investees.map((item, index) => (
                    <CompaniesTableRow
                      key={item._id}
                      item={item}
                      loader={this.state.is_fetching_investees}
                      hasMore={this.hasMore}
                      loadMore={this.loadMore}
                      dataLength={this.state.investees.length}
                      index={index}
                      updateInvesteeOrgStats={this.updateInvesteeOrgStats}
                      firstRow={this.firstRow}
                      hideCompletedExpired={this.state.hideCompletedExpired}
                      currentTab={this.state.currentTab}
                      setCurrentTab={(state) =>
                        this.setState({ currentTab: state })
                      }
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  AggregateDetails = () => {
    const {
      approved,
      completed,
      expired,
      not_shared,
      partially_shared,
      others,
      query_pending,
      shared,
    } = this.state.aggregateNumbers;
    return (
      <div
        className={`d-flex justify-content-between ${styles.aggregateNumbers}`}
      >
        <div
          className="d-flex justify-content-between"
          style={{ width: "66%" }}
        >
          <div>
            <div>Not Shared</div>
            <div className={styles.not_shared}>{not_shared?.value}</div>
          </div>
          <div>
            <div>Partially Shared</div>
            <div className={styles.partially_shared}>{partially_shared?.value}</div>
          </div>
          <div>
            <div>Shared</div>
            <div className={styles.shared}>{shared?.value}</div>
          </div>
          <div>
            <div>Query Pending</div>
            <div className={styles.query_pending}>{query_pending?.value}</div>
          </div>
          <div>
            <div>Approved</div>
            <div className={styles.approved}>{approved?.value}</div>
          </div>
          <div>
            <div>Completed</div>
            <div className={styles.completed}>{completed?.value}</div>
          </div>
          <div>
            <div>Expired</div>
            <div className={styles.expired}>{expired?.value}</div>
          </div>
        </div>
        <div className={`d-flex ${styles.hideExpiredToggleBtn}`}>
          Hide Expired & Completed
          <div
            className="DocumentVaultSwitch ml-3"
            style={{ marginTop: "2.6px" }}
          >
            <ToggleButton
              checked={this.state.hideCompletedExpired}
              onChange={() => this.handleHideExpiredFilter()}
            />
          </div>
        </div>
      </div>
    );
  };

  handleViewFilter = (newFilter) => {
    this.setState(
      { viewFilter : newFilter }
    )
  }

  DealDetails = () => {
    return (
      <div
        className={`d-flex justify-content-between ${styles.aggregateNumbersV2}`}
      >
        <div className={`d-flex ${styles.showRelevant}`}>
          Show Relevant
          <div
            className="DocumentVaultSwitch ml-3"
            style={{ marginTop: "1.6px" }}
          >
            <ToggleButton
              checked={this.state.showRelevantDeals}
              onChange={() => this.handleRelevantDealFilter()}
            />
          </div>
        </div>
        <div className='mr-2'>
          <ViewFilters filterString={this.state.viewFilter} viewFilterOptions={viewFilterOptions} setFilterString={this.handleViewFilter}/>
        </div>
      </div>
    );
  };

  ComplianceDetails = () => {
    return (
        <div className={`d-flex ${styles.showRelevant}`}>
          Show Relevant
          <div
            className="DocumentVaultSwitch ml-3"
            style={{ marginTop: "2.6px" }}
          >
            <ToggleButton
              checked={this.state.showRelevantCompliance}
              onChange={() => this.handleRelevantComplianceFilter()}
            />
          </div>
      </div>
    );
  };

  handleRelevantFilter = () => {
    this.setState(
      { showRelevantCompanies: !this.state.showRelevantCompanies },
      () => {
        this.fetchCompanies(true);
      }
    );
  };

  handleCompanyViewFilter = (newFilter) => {
    this.setState(
      { viewCompanyFilter : newFilter },
      () => {
        this.fetchCompanies(true);
      }
    )
  }

  handleAddonFilter = () => {
    this.setState(
      {
        addonToggleBtn: !this.state.addonToggleBtn,
      },
      () => {
        this.fetchCompanies(true);
      }
    );
  };

  handleHideExpiredFilter = () => {
    this.setState({ hideCompletedExpired: !this.state.hideCompletedExpired });
  };

  handleRelevantDealFilter = () => {
    this.setState({ showRelevantDeals: !this.state.showRelevantDeals });
  };

  handleRelevantComplianceFilter = () => {
    this.setState({showRelevantCompliance: !this.state.showRelevantCompliance})
  }

  render() {
    return (
      <div className="main-body">
        <AdminHead />
        <section className="w-100">
          {/* id='admin-company' */}
          <div className="d-flex justify-content-between has-search p-4">
            <div className="fs21 fw600">Companies</div>

            <div
              style={{
                width: "max-content",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.0640844)",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              {this.state.currentTab === tabsEnum.COMPANIES && (
                <div
                  className="d-flex justify-content-between"
                  style={{ fontSize: "10px" }}
                >
                  {!isEmpty(this.state.aggregrateDetails) && <div
                    className={`d-flex ${styles.companiesAggregrate__wrapper}`}
                  >
                    <div>
                      <div>Not Started</div>
                      <div className={styles.not_started}>{this.state.aggregrateDetails[UNDERWRITING_STATUS.NOT_STARTED] ?? 0}</div>
                    </div>
                    <div>
                      <div>Ongoing</div>
                      <div className={styles.on_going}>{this.state.aggregrateDetails[UNDERWRITING_STATUS.ONGOING] ?? 0}</div>
                    </div>
                    <div>
                      <div>Need Info</div>
                      <div className={styles.need_info}>{this.state.aggregrateDetails[UNDERWRITING_STATUS.NEED_INFO] ?? 0}</div>
                    </div>
                    <div>
                      <div>Waitlisted</div>
                      <div className={styles.waitlisted}>{this.state.aggregrateDetails[UNDERWRITING_STATUS.WAITLISTED] ?? 0}</div>
                    </div>
                    <div>
                      <div>Expired</div>
                      <div className={styles.expired}>{this.state.aggregrateDetails[COMPANY_STATUS.EXPIRED] ?? 0}</div>
                    </div>
                  </div>}
                  <div className={`d-flex ${styles.toogleBtn}`}>
                    Add on
                    <div
                      className="DocumentVaultSwitch ml-3"
                      style={{ marginTop: "2.6px" }}
                    >
                      <ToggleButton
                        checked={this.state.addonToggleBtn}
                        onChange={() => this.handleAddonFilter()}
                      />
                    </div>
                  </div>
                  <div className={`d-flex ${styles.showRelevant}`}>
                    Show Relevant
                    <div
                      className="DocumentVaultSwitch ml-3"
                      style={{ marginTop: "2.6px" }}
                    >
                      <ToggleButton
                        checked={this.state.showRelevantCompanies}
                        onChange={() => this.handleRelevantFilter()}
                      />
                    </div>
                  </div>
                  <div className='d-flex mr-2'>
                    <ViewFilters filterString={this.state.viewCompanyFilter} viewFilterOptions={viewFilterOptions} setFilterString={this.handleCompanyViewFilter}/>
                  </div>
                </div>
              )}
              {this.state.currentTab === tabsEnum.APPROVED_LIMITS && (
                <this.AggregateDetails />
              )}
              {this.state.currentTab === tabsEnum.DEALS && (
                <this.DealDetails />
              )}
              {this.state.currentTab === tabsEnum.COMPLIANCE&&(
                <this.ComplianceDetails />
              )}
            </div>
          </div>
          <CompaniesStatusBar
            currentTab={this.state.currentTab}
            setCurrentTab={(state) => this.setState({ currentTab: state })}
          />
          {this.state.currentTab === tabsEnum.COMPANIES && (
            <this.AllCompaniesView />
          )}
          {this.state.currentTab === tabsEnum.APPROVED_LIMITS && (
            <ApprovedLimits
              hideCompletedExpired={this.state.hideCompletedExpired}
              fetchAggregateNumbers={this.fetchAggregateNumbers}
              setCurrentTab={(state) => this.setState({ currentTab: state })}
            />
          )}
          {this.state.currentTab === tabsEnum.DEALS && (
            <DealsViewPage 
              showRelevantDeals={this.state.showRelevantDeals}
              viewFilter={this.state.viewFilter}
            />)}
          {this.state.currentTab === tabsEnum.COMPLIANCE && (
            <ComplianceViewPage
              showRelevantCompliance = {this.state.showRelevantCompliance}
            />
          )}
        </section>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    investees: getInvestees(state),
    aggregrateDetails: getAggregrateDetailsUW(state),
    underwriters: getUnderwriters(state),
    companyStatuses: getCompanyStatues(state),
    underWritingStatuses: getUnderWritingStatuses(state),
    totalCount: getInvesteesTotalCount(state),
    is_fetching_investees: getIsFetchingInvestees(state),
    isUserHasUWViewAccess: getCheckIfUserHasRelevantPermissions(state, [
      "underwriting:edit",
    ]),
  };
}

export default connect(mapStateToProps)(CompaniesPage);
