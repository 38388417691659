import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import callApi from "../../../../../../../../../util/apiCaller";
import { showNotification } from "../../../../../../../../../util/utility";
import { fetchConnectedPGList } from "../../../../../../../AdminActions";
import { getInvesteesOrganization } from "../../../../../../../AdminReducers";

export default function ConnectPgModal({ show, onHide, pgToEdit }) {
  const dispatch = useDispatch();
  const investeeOrgId = useSelector(getInvesteesOrganization)?._id;
  const [formData, setFormData] = useState({
    pgName: "",
    accountId: "",
    accessToken: "",
    secretToken: "",
  });
  const [isOpenedFromEditClick, setIsOpenedFromEditClick] = useState(false);

  useEffect(() => {
    if (!isEmpty(pgToEdit)) {
      setFormData({
        pgId: pgToEdit.pgId,
        pgName: pgToEdit.pgName,
        accountId: pgToEdit.accountId,
        accessToken: "",
        secretToken: "",
      });
      setIsOpenedFromEditClick(true);
    } else {
      setFormData({
        pgName: "RAZORPAY",
        accountId: "",
        accessToken: "",
        secretToken: "",
      });
      setIsOpenedFromEditClick(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pgToEdit]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData((prevVal) => ({ ...prevVal, [name]: value }));
  };

  const handleConnectPgClick = () => {
    const endpoint = isOpenedFromEditClick ? "connection/edit" : "register";
    callApi(
      endpoint,
      "post",
      {
        companyId: investeeOrgId,
        requestId: `${investeeOrgId}${+new Date()}`,
        pgDetails: formData,
      },
      {},
      false,
      false,
      true
    ).then((res) => {
      if (res.responseData.responseCode === 20) {
        showNotification("success", res.responseData.responseMessage);
        dispatch(fetchConnectedPGList(investeeOrgId));
        onHide();
      } else {
        const message =
          res?.responseData?.responseMessage ?? "Some error occurred";
        showNotification("Error", message);
      }
    });
  };

  return (
    <Modal show={show} onHide={onHide} className="ConnectPGModal">
      <Modal.Header className="border-0">
        Connect New PG
        <button type="button" class="close">
          <img
            src="../../../../../assets/modal-cross.svg"
            alt=""
            onClick={onHide}
          />
        </button>
      </Modal.Header>
      <Modal.Body>
        <input
          disabled={isOpenedFromEditClick}
          placeholder="Select Payment Gateway"
          type="text"
          value={formData.pgName}
          className="mb-4"
          onChange={handleChange}
          name="pgName"
          required
        />
        <input
          disabled={isOpenedFromEditClick}
          placeholder="Account ID"
          type="text"
          value={formData.accountId}
          className="mt-2 mb-4"
          onChange={handleChange}
          name="accountId"
          required
        />
        <input
          type="text"
          placeholder="Access Token"
          value={formData.accessToken}
          className="mt-2 mb-4"
          onChange={handleChange}
          name="accessToken"
          required
        />
        <input
          type="text"
          placeholder="Secret Token"
          value={formData.secretToken}
          className="mt-2"
          onChange={handleChange}
          name="secretToken"
          required
        />
      </Modal.Body>
      <Modal.Footer className="border-0">
        <button className="w-100" onClick={handleConnectPgClick}>
          Connect PG
        </button>
      </Modal.Footer>
    </Modal>
  );
}
