import React, { useEffect, useState } from 'react'
import { getBankAlerts } from '../../../../../../../../../../../../../common/ApiHelpers'
import { isEmpty, isNil } from 'lodash'
import '../AlertBox/BankAlerts.scss'

export const BankAlerts=(input : {[key : string] : string})=>{
    const [rowData, setRowData] = useState<{[key : string] : string}[]>([])
    const [loading,setLoading] = useState(false)

    const getRowData = async (orgId : string)=> {
        setLoading(true)
        const alertData : {[key : string] : string}[] = await getBankAlerts(orgId)
            setRowData(alertData)
            setLoading(false)
    }
    useEffect(() => {
        if(isEmpty(rowData))getRowData(input.orgId)
    },[])
    
    return(
        <>
            <div>
                <div className='alertText'>Alerts</div>
                <div
                  className="alertBox"
                >
                {rowData && (
                    <div>
                        {rowData.map((element) => (
                            <>
                                <div className="dateText">{element.secondaryKey}</div>
                                <div className="messageText">{element.primaryKey} </div>  
                            </>
                              
                        ))}
                    </div>
                    )}
                </div>
            </div>
        </>
    )
}