import React, { useEffect, useRef } from "react";
import DealsHeader from "./components/DealsTableHeader";
import DealsRow from "./components/DealsTableRow";
import styles from "./deals.module.scss";
import { useState } from "react";
import { DEAL_HEADER_FILTER, SORT_COL_DIRECTION } from "../../../../../../common/ENUM";
import { showNotification } from "../../../../../../util/utility";
import callApi from "../../../../../../util/apiCaller";
import PageLoader from "../../../../../../components/loader/PageLoader";
import { useHistory, useLocation } from "react-router-dom";
import { queryParams } from "../../CompaniesPage";
import ViewDocsDrawer from "../../../CompaniesViewPage/components/CompaniesDetailPage/components/Overview/components/DealsOverview/components/ViewDocsDrawer/ViewDocsDrawer";
import { isEmpty } from "lodash";
import { queryTabs, VIEW_FILTERS, VIEW_FILTERS_API_KEY } from "../../../../../../constants/enums";
import ViewDocsModal from "../../../CompaniesViewPage/components/CompaniesDetailPage/components/Overview/components/DealsOverview/components/ViewDocsModal";

const DealsViewPage = ({showRelevantDeals,viewFilter}) => {
  const initialDealsdealFilterObj = {
    showRelevantDeals: showRelevantDeals,
    qTextInvestorOrgName: "",
    qTextInvesteeOrgName: "",
    actionStatus: "",
    sortBy: null,
    primarySortKey:"",
    viewFilter: returnViewFilterKey(viewFilter)
  };
  const isFirstRender = useRef(true);
  const history = useHistory();
  const location = useLocation();
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [sortConfig, setSortConfig] = useState([]);
  const [sortCompany, setSortCompany] = useState(null);
  const [sortInvestor, setSortInvestor] = useState(null);
  const [investorQuery, setInvestorQuery] = useState("");
  const [companyQuery, setCompanyQuery] = useState("");
  const [actionQuery, setActionQuery] = useState("");
  const [sortLastActivityDate, setSortLastActivityDate] = useState(null);
  const [sortCreateDate, setSortCreateDate] = useState(null);
  const [dataRows, setDataRows] = useState([]);
  const [dataHeaders, setDataHeaders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showViewDocDrawer, setShowViewDocDrawer] = useState(null);
  const [dealFilterObj, setDealFilterObj] = useState({
    ...initialDealsdealFilterObj,
  });

  const [selectedDealOffer, setSelectedDealOffer] = useState(null);
  const [loadingDealOffers, setLoadingDealOffers] = useState(false);

  const [selectedTotHistory,setSelectedTotHistory] = useState(null);
  const [loadingSelectedTot,setLoadingSelectedTot] = useState(false);
 
  const loadMore = () => {
    setPageNum(pageNum + 1);
  };

  useEffect(() => {
    if(pageNum > 1) fetchDealsData();
   },[pageNum])

  const hasMore = () => {
    return pageNum < Math.ceil(totalCount/pageSize);
  };
  
  // const [currentTab, setCurrentTab] = useState(null);

  useEffect(() => {
    if (investorQuery !== null) {
      setDealFilterObj({
        ...dealFilterObj,
        qTextInvestorOrgName: investorQuery,
      });
    } 
  }, [investorQuery]);

  useEffect(() => {
    if (actionQuery !== null) {
      setDealFilterObj({
        ...dealFilterObj,
        actionStatus: actionQuery,
      });
    }
  }, [actionQuery]);

  useEffect(() => {
    if (companyQuery !== null) {
      setDealFilterObj({
        ...dealFilterObj,
        qTextInvesteeOrgName: companyQuery,
      });
    }
  }, [companyQuery]);

  useEffect(() => {
    if (!isFirstRender.current) {
      const delayDebounceFn = setTimeout(() => {
        fetchDealsData(true);
      }, 400)
      return () => clearTimeout(delayDebounceFn)
    }
  }, [dealFilterObj]);

  useEffect(() => {
    if(!isFirstRender.current) {
      let filterKey = returnViewFilterKey(viewFilter);
      setDealFilterObj({
        ...dealFilterObj,
        viewFilter: filterKey,
      });
    }
  }, [viewFilter])

  function returnViewFilterKey (viewFilter) {
    switch(viewFilter) {
      case VIEW_FILTERS.VIEW_ADD_ON:
        return VIEW_FILTERS_API_KEY.VIEW_ADD_ON;
      case VIEW_FILTERS.VIEW_DS:
        return VIEW_FILTERS_API_KEY.VIEW_DS;
      case VIEW_FILTERS.VIEW_NEW:
        return VIEW_FILTERS_API_KEY.VIEW_NEW;
      case VIEW_FILTERS.VIEW_UNDRAWN_LINES:
        return VIEW_FILTERS_API_KEY.VIEW_UNDRAWN_LINES;
      default:
        return VIEW_FILTERS_API_KEY.VIEW_ALL;
    }
  }

  useEffect(() => {
    if(!isFirstRender.current) {
      dealFilterObj.showRelevantDeals = showRelevantDeals;
        history.replace({
         search: `?tab=${queryParams.DEALS}&show-relevant-deals=${showRelevantDeals}`
        })
        fetchDealsData(true);
    }
    else isFirstRender.current = false;
  }, [showRelevantDeals])

  useEffect(() => {
    if (sortCompany != null) {
      setDealFilterObj(prev => ({
        ...prev,
        sortBy: {
          ...prev.sortBy,
          companyName: sortCompany,
        },
      }));
    }
  }, [sortCompany])

  useEffect(() => {
    if (sortInvestor != null) {
      setDealFilterObj(prev => ({
        ...prev,
        sortBy: {
          ...prev.sortBy,
          investorName: sortInvestor,
        },
      }));
    }
  },[sortInvestor])

  useEffect(() => {
    if (sortLastActivityDate != null) {
      setDealFilterObj(prev => ({
        ...prev,
        sortBy: {
          ...prev.sortBy,
          lastActivityDate: sortLastActivityDate,
        },
        primarySortKey: "lastActivityDate"
      }));
    }
  },[sortLastActivityDate])

  useEffect(() => {
    if (sortCreateDate != null) {
      setDealFilterObj(prev => ({
        ...prev,
        sortBy: {
          ...prev.sortBy,
          createDate: sortCreateDate,
        },
        primarySortKey:"createDate"
      }));
    }
  },[sortCreateDate])
 
  const fetchDataAndOpenDrawer = (rowData) => {
    const onSuccess = () => setShowViewDocDrawer(rowData);
    fetchDealOffers(rowData?.investeeOrganizationId,rowData?.totId,rowData?.offerId,onSuccess);
  }

  const getFilters = () => {
    const filters = dealFilterObj;
    filters.requestId = Date.now();
    filters.pageNum = pageNum;
    return filters;
  }

  const fetchDealsData = (isReseting) => {
    setLoading(true);
    const filters = getFilters();
    if (isReseting) {
      filters.pageNum = 1;
      setPageNum(1);
    }
    setLoading(true);
    callApi("/admin/deal-page/get-all-deals", "post",  filters ).then(
      (res) => {
        setLoading(false);
        if (res.status === "Success") {
          if (res?.data?.dealsData?.rows && res.data.dealsData.rows.length === 0) {
            //setting it 0 so that api does not get called again
            setPageNum(0);
          }
          if (!isReseting) {
            setDataRows((prevData) => {
              const newData = [...prevData, ...res?.data?.dealsData?.rows];
              return newData;
            })
          } else {
            setDataRows(res?.data?.dealsData?.rows);
          }
          setDataHeaders(res?.data?.headers);
          setTotalCount(res?.data?.dealsData?.totalCount);
          setPageSize(res?.data?.dealsData?.pageSize);
          setLoading(false);
        } else {
          showNotification("Error", "Some error occured");
        }
      }
    );
  }

  const fetchDealOffers = (orgId, totId, offerId,onSuccess) => {
    setLoadingDealOffers(true);
    const payload = {
      investee_organization_id: orgId,
      tot_id: totId
    };
    callApi("/admin/deals/investee_organization/get_deals", "post", payload).then(
      (res) => {
        if (res.status === "Success") {
          const data = res?.data?.[0];
          const loanType = data?.loanType;
          const selectedOffer = data?.investor_deal_offer?.find((offer) => offer?.offer_id === offerId);
          selectedOffer && setSelectedDealOffer({
            ...selectedOffer,
            tot_id: totId
          });
          setSelectedDealOffer((selected) => ({...selected,loanType: loanType}))
          fetchSelectedTotHistory(orgId, totId,onSuccess)
        } else {
          showNotification("Error", "Some error in fetching offers");
        }
      }
    ).catch(() => showNotification('Error',"Some error in fetching offers"))
    .finally(() => {setLoadingDealOffers(false)})
  };


  const fetchSelectedTotHistory = (orgId,totId,onSuccess) => {
    setLoadingSelectedTot(true);
    callApi(`admin/tot-history/investee-organization/${orgId}/getAllTotHistory`)
        .then((res) => {
            if (res.status === 'Success') {
                const totHistory = res?.data?.totHistory || [];
                const selectedTot = totHistory?.find((tot) => tot?._id === totId);
                setSelectedTotHistory(selectedTot);
                onSuccess?.();

            } else {
                showNotification("error", "Error Fetching Tots")
            }
        })
        .catch((err) => showNotification("error", err))
        .finally(() => setLoadingSelectedTot(false))
}

  return (
    <div className="main-body">
      {(loading || loadingDealOffers || loadingSelectedTot) && 
      <PageLoader showOverlay={loading || loadingDealOffers || loadingSelectedTot}/>}

      <section className={styles.dealsSection}>
        <div className={`${styles.TableCard} mt-4`} >
          <table>
            <DealsHeader
              dataHeaders={dataHeaders}
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
              setSortCompany={setSortCompany}
              sortCompany={sortCompany}
              sortInvestor={sortInvestor}
              setSortInvestor={setSortInvestor}
              investorQuery={investorQuery}
              setInvestorQuery={setInvestorQuery}
              companyQuery={companyQuery}
              setCompanyQuery={setCompanyQuery}
              actionQuery={actionQuery}
              setActionQuery={setActionQuery}
              sortLastActivityDate={sortLastActivityDate}
              setSortLastActivityDate={setSortLastActivityDate}
              sortCreateDate={sortCreateDate}
              setSortCreateDate={setSortCreateDate}
            />
            <tbody>
              {!!dataRows?.length &&
                dataRows
                  // ?.filter((row) =>
                  //   row?.[filterItem]?.toLowerCase().includes(type)
                  // )
                  ?.map((item, index) => (
                    <DealsRow
                      key={index}
                      index={index}
                      row={item}
                      loadMore={loadMore}
                      hasMore={hasMore}
                      loader={loading}
                      dataLength={dataRows.length}
                      dataHeaders={dataHeaders}
                      sortConfig={sortConfig}
                      setShowViewDocDrawer={setShowViewDocDrawer}
                      fetchDataAndOpenDrawer={fetchDataAndOpenDrawer}
                    />
                  ))}
            </tbody>
          </table>
        </div>
      </section>

      {showViewDocDrawer && <ViewDocsModal
        showViewDocsDrawer={showViewDocDrawer}
        closeDealDrawer={() => {
          setShowViewDocDrawer(false)
          setSelectedDealOffer(null);
          setSelectedTotHistory(null);
        }}
        investor={selectedDealOffer}
        fetchDeals={fetchDealOffers}
        dealId={selectedDealOffer?.deal_id}
        totId={selectedDealOffer?.tot_id}
        dealsTotId={selectedDealOffer?.tot_id}
        investeeOrgId={selectedDealOffer?.investee_organization_id}
        resetQueryParams={() => {}}
        dealName={selectedDealOffer?.deal_name}
        totMaxTenure={selectedTotHistory?.custom_max_tenure}
        getAllDealsData={() => fetchDealsData(true)}
        
      />}
    </div>
  );
};

export default DealsViewPage;
