import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

import callApi from '../../../../../../../../../../util/apiCaller';
import { showNotification } from '../../../../../../../../../../util/utility';

const Heading = styled('h4')`
  font-size: 16px;
  font-weight: 600;
  margin: 32px 0px 20px;
  color: #1c1c1c;
`;
const UWRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:100%;
`;
const UWTitle = styled('span')`
  font-size: 14px;
  font-weight: 500;
  text-align:center;
`;
const UWValue = styled('span')`
  color: ${(props) => (props.isUndefined ? '#ff0000' : '#000000')};
  font-size: ${(props) => (props.isUndefined ? '10px' : '12px')};
  font-weight: ${(props) => (props.isUndefined ? '400' : '500')};
  width: 100%;
  height:40px;
  text-align: center;
  border: 2px solid #2F8FFF;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Button = styled('button')`
  background-color: #ffffff;
  box-shadow: none;
  border: 1px solid #2F8FFF;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 600;
  width: 40%;
  height: 30px;
  margin-left: 5px;
  background-color:#2F8FFF;
  color:white;
`;

const SIGNAL_OPTIONS = [
  { value: 'Fantastic', label: 'Fantastic' },
  { value: 'Good', label: 'Good' },
  { value: 'Spark', label: 'Spark' },
  { value: 'Difficult', label: 'Difficult' },
];
const FLAG_OPTIONS = [
  { value: 'Small Scale', label: 'Small Scale' },
  { value: 'Revenue Degrowth', label: 'Revenue Degrowth' },
  { value: 'High Debt', label: 'High Debt' },
  { value: 'High Customer Concentration', label: 'High Customer Concentration' },
  { value: 'Poor CIBIL', label: 'Poor CIBIL' },
  { value: 'Low runway', label: 'Low runway' },
  { value: 'High Receivables', label: 'High Receivables' },
  { value: 'Not ICP sector', label: 'Not ICP sector' },
  { value: 'No website / Social Presence', label: 'No website / Social Presence' },
  { value: 'Proprietorship Firm', label: 'Proprietorship Firm' },
  { value: 'High Related Party Transactions', label: 'High Related Party Transactions' },
];

const customStyles = {
  valueContainer: (provided) => ({
    ...provided,
    maxHeight: '30px',
    maxWidth: '100px',
  }),
  container: (provided) => ({
    ...provided,
    width: '100%',
    fontSize: 10,
    padding:'0px'
  }),
  placeholder: (provided) => ({
    ...provided,
    fontWeight: 500,
    fontSize: 10,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 10,
    fontWeight: 500,
  }),
};

const DataVaultUnderwriting = ({ investeeOrganization }) => {
  const [uwFlag, setUwFlag] = useState([]);
  const [uwSignal, setUwSignal] = useState();
  const [editFlag, setEditFlag] = useState(false);
  const [editSignal, setEditSignal] = useState(false);

  useEffect(() => {
    setUwFlag(investeeOrganization?.uw_flag ?? []);
    setUwSignal(investeeOrganization?.uw_signal ?? undefined);
  }, [investeeOrganization]);

  const handleSave = async () => {
    try {
      const response = await callApi(
        `admin/investee-organization/${investeeOrganization._id}/uw-update`,
        'post',
        { uwFlag, uwSignal }
      );
      if (response && response.status === 'Success') {
        showNotification('success', 'U/W flag updated');
        setEditFlag(false);
        setEditSignal(false);
      } else {
        showNotification('error', 'Error occurred while updating U/W Flag');
      }
    } catch (error) {
      showNotification('error', 'Error occurred while updating U/W Flag');
    }
  };

  return (
    <div style={{ width: "95%" }}>
      <div className='row'>
        <UWTitle className='col'>U/W Signal</UWTitle>
        <UWTitle className='col'>Rejected due to</UWTitle>
      </div>
      <div className="mb-3 mt-3 row" style={{ display: 'flex' }}>
        <UWRow className="col">
          {editSignal ? (
            <>
              <Select
                name="uw-signal-select"
                options={SIGNAL_OPTIONS}
                value={
                  uwSignal
                    ? { value: uwSignal, label: uwSignal }
                    : null
                }
                onChange={(e) => setUwSignal(e.value)}
                styles={customStyles}
              />
              <Button onClick={handleSave}>Save</Button>
            </>
          ) : (
            <>
              <UWValue isUndefined={!uwSignal}>{uwSignal ?? 'N.A.'}</UWValue>
              <Button onClick={() => setEditSignal(true)}>Edit</Button>
            </>
          )}
        </UWRow>
        <UWRow className="col" >
          {editFlag ? (
            <>
              <Select
                isMulti
                closeMenuOnSelect={false}
                options={FLAG_OPTIONS}
                name="uw-flag-select"
                value={
                  uwFlag ? uwFlag.map((f) => ({ value: f, label: f })) : []
                }
                onChange={(e) => setUwFlag(e.map((v) => v.value))}
                styles={customStyles}
                maxMenuHeight={140}
              />
              <Button onClick={handleSave}>Save</Button>
            </>
          ) : (
            <>
              <UWValue title={uwFlag.join(', ')} isUndefined={!(uwFlag.length > 0)}>
                {uwFlag.length > 0 ?
                  uwFlag[0] + "..." : 'N.A.'}
              </UWValue>
              <Button onClick={() => setEditFlag(true)}>Edit</Button>
            </>
          )}
        </UWRow>
      </div>
    </div>
  );
};

export default DataVaultUnderwriting;
