import React, { useState } from "react";
import Metrics_Box from "./MetricsBox/MetricsBox";
import styles from "./metrics.module.css";
import styled from "styled-components";
import { downloadFileUsingFetch } from "../../../../../../../../../util/utility";

export default function Metrics({ investeeOrganization }) {
  const [quarter, setQuarter] = useState(true);
  const [year, setYear] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [id, setId] = useState();

  const downloadFile = () => {
    setIsExporting(true);
    const endpoint = quarter
      ? `metrics/get-active-metrics?investeeOrgId=${investeeOrganization._id}&organisationMetricId=${id}&type=quarter&csv=true`
      : `metrics/get-active-metrics?investeeOrgId=${investeeOrganization._id}&organisationMetricId=${id}&type=yearly&csv=true`;
    downloadFileUsingFetch(
      endpoint,
      "get",
      { responseType: "blob" },
      `underwriting-metrics.csv`,
      () => {
        setIsExporting(false);
      },
      true
    );
  };
  return (
    <div>
      <div className={styles.QuaterYearBtnDiv} id={styles.QuaterYearBtnDiv}>
        <div className={styles.metrics_export}>
          <button onClick={downloadFile} className={styles.export_btn}>
            {!isExporting ? (
              <span>
                <img
                  src="/assets/download-dv.svg"
                  style={{ height: "12.5px", marginRight: "3.5px" }}
                />
              </span>
            ) : (
              <span id="loading-spinner">
                <img className="spin-icon" style={{ marginRight: "8px" }}></img>
              </span>
            )}
            Export
          </button>
        </div>

        <div className={styles.BtnList}>
          <button
            className={
              styles.QuaterBtn + " " + (quarter ? styles.BtnBackChange : "")
            }
            onClick={() => {
              setQuarter(true);
              setYear(false);
            }}
          >
            Quarterly
          </button>
          <button
            className={
              styles.YearBtn + " " + (year ? styles.BtnBackChange : "")
            }
            onClick={() => {
              setQuarter(false);
              setYear(true);
            }}
          >
            Yearly
          </button>
        </div>
      </div>
      <div className={styles.metrics_box}>
        <Metrics_Box
          investeeOrganization={investeeOrganization}
          year={year}
          quarter={quarter}
          id={id}
          setId={setId}
        />
      </div>
    </div>
  );
}
