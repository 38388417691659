import React from "react";
import { Modal } from "react-bootstrap";
import "../../../../../../../../../css/Modal.scss";

export const SanctionNotApprovedModal = ({
  sanctionNotApprovedModal,
  setSanctionNotApprovedModal,
  generateDrawdown,
  markApprovalStatus,
  approval_status
}) => {
  
  const handleClose = () => {
    setSanctionNotApprovedModal(false);
  };
  const handleContinue = () => {
    if(generateDrawdown){
      generateDrawdown(true);
      return;
    }
    if(markApprovalStatus){
      markApprovalStatus(approval_status, true);
      return;
    }
  }
  return (
    <Modal
      show={sanctionNotApprovedModal}
      onHide={handleClose}
      className="sanctionNotApprovedModal"
    >
      <button onClick={handleClose} className="closeModal">
        <img src="/assets/modal-cross.svg" alt=""></img>
      </button>
      <Modal.Body>
        <img
          src="/assets/warning-sign.svg"
          width="60"
          height="60"
          className="warningImg"
        ></img>
        <div className="notSignedLine">
          The sanction for this deal is still not signed.
        </div>
        <div className="continueLine">
          Do you still want to continue generating this drawdown?
        </div>
        <div>
          <button className="cancelBtn" onClick={handleClose}>
            Cancel
          </button>
          <button className="continueBtn" onClick={handleContinue}>
            Continue
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
