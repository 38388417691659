import React, { Component } from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Helmet from "react-helmet";
import "../../../../css/CompaniesDetail.css";
import AdminHead from "../../components/AdminHead.js";
import callApi from "../../../../util/apiCaller";
import { downloadFileUsingFetch, getPrevious12Months, showNotification } from "../../../../util/utility";
import CompaniesDetailPage from "./components/CompaniesDetailPage/CompaniesDetailPage";
import AddNewBankModal from "../../../../components/AddNewBankModal";
import { getCheckIfUserHasRelevantPermissions, getIsAddOnLimitedRequested } from "../../../App/AppReducer";
import { fetchInvesteeDetails, fetchDealReport } from "../../AdminActions";
import {
  getInvesteesOrganization,
  getInvesteeProfileMtrics,
  getInvesteeContractStats,
  getInvesteeOnboardingData,
} from "../../AdminReducers"
import CreateNachModal from "../../../../components/CreateNach";
import { setIsAddOnLimitRequested } from "../../../App/AppActions";
import { updateLastModerated } from "../../../../util/commonApi";
import { createFacilityNach, fetchOrgNachDetails } from "../../../../common/ApiHelpers";
import { DECENTRO_BANK_FAILURE, DECENTRO_BANK_PENDING, DECENTRO_BANK_VERIFIED_SUCCESS } from "./components/CompaniesDetailPage/components/DocumentVault/consts/consts";


export const MONTHLY_DATA_DOWNLOAD_TYPES = {
  MIS: 'mis',
  INVOICING: 'invoicing',
  GST_INVOICING: 'gstInvoicing',
  BANK: 'bankStatements'
}

export const MONTHLY_VIEW_DATA_TYPES = {
  NOT_UPLOADED: "Not Uploaded",
  UPLOADED: "Uploaded",
  INGESTED: "Ingested"
}
const { INGESTED, UPLOADED, NOT_UPLOADED } = MONTHLY_VIEW_DATA_TYPES;


class CompaniesViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bank:{},
      investee_contract_stats: {},
      investee_profile_metrics: {},
      investee_organization: {},
      investeeOnboardedId: "",
      onboardingData: {},
      showEditPage: false,
      show: false,
      file: null,
      fileContents: "",
      company_status: ["value1", "value2"],
      selectedSegment: "overview",
      members: [],
      contracts: [],
      banklist: [],
      lenderDetailList: [],
      nachList: [],
      finances: [],
      contractPageNum: 1,
      financePageNum: 1,
      contractPageSize: 25,
      disableCreateNachButton: false,
      financePageSize: 25,
      isAddBankModalVisible: false,
      isCreateNachModalVisible: false,
      capitalPartners: [],
      // contractSortBy: 25,
      monthlyData: [],
      contractsCount: 0,
      documentationData: {},
      integrationsObj: {
        misIntegrations: {
          razorpay: false,
          stripe: false,
          tally: false,
          zoho: false,
          by_user: false,
          by_admin: false,
        },
        invoicingIntegrations: {
          razorpay: false,
          stripe: false,
          tally: false,
          zoho: false,
          by_user: false,
          by_admin: false,
        },
        contractsIntegrations: {
          razorpay: false,
          stripe: false,
          tally: false,
          zoho: false,
          by_user: false,
          by_admin: false,
        },
        bankStatementsIntegrations: {
          razorpay: false,
          stripe: false,
          tally: false,
          zoho: false,
          by_user: false,
          by_admin: false,
        },
      },
      isModeratingDocumentation: false,
      monthlyViewData: {
        availableBalanceSheetMonthlyDates: {},
        availableBankTrxMonthlyDates: {},
        availableCashflowMonthlyDates: {},
        availableInvoiceGstMonthlyDates: {},
        availableInvoiceMonthlyDates: {},
        availablePnlMonthlyDates: {},
        bankStatementDates: {}
      }
    };
  }


  componentDidMount() {
    // this.fetchCompaniesDetails();
    this.fetchMetrics();
    // this.props.dispatch(fetchSalesPartner());
    // this.props.dispatch(fetchCapitalPartner());
    this.props.dispatch(fetchInvesteeDetails(this.props.match.params.id));
  }

  componentDidUpdate(prevProps) {
    if (this.props.investee_organization && this.props.investee_organization !== prevProps.investee_organization) {
      if (this?.props?.location?.state && "openDataVaultTab" in this.props.location?.state) {
        if (this.props.location.state?.openDataVaultTab) {
          this.setState({ selectedSegment: "data-vault" });
          this.fetchMonthlyData();
        }
      }
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.investeeOnboardedId !== this.state.investeeOnboardedId) {
  //     this.state.investeeOnboardedId && this.fetchOnboardingData();
  //   }
  // }

  fetchMetrics = () => {
    const id = this.props.match.params.id;
  };

  toggleSegment = (val, cb) => {
    this.setState(
      {
        selectedSegment: val,
      },
      () => {
        this.loadSegment(val);
      }
    );
    cb && cb();
  };

  loadSegment = val => {
    if (val === "contracts") {
      const id = this.props.match.params.id;
      callApi(`contract/get-filtered-contract`, "post", {
        custom_tenure: this.props.investee_organization.custom_tenure,
        pageNum: this.state.contractPageNum,
        pageSize: this.state.contractPageSize,
        sortBy: this.state.contractSortBy,
        filters: {
          isFiltering: "true",
          investee_organization_id: id,
          is_contract_listed: false,
          is_contract_traded: false,
        },
      }).then(res => {
        if (res.status === "Success") {
          this.setState({
            contracts: res.data.contracts,
          });
        }
      });
    }
    // else if (val === "finances") {
    //   const id = this.props.match.params.id;
    //   callApi(`investeeorganization/${id}/finance/transaction-list`, "post", {
    //     pageNum: this.state.financePageNum,
    //     pageSize: this.state.financePageSize,
    //     sortBy: this.state.financeSortBy,
    //     filters: {
    //       investee_organization_id: id,
    //       transaction_type: ""
    //     }
    //   }).then(res => {
    //     console.log(res);
    //     if (res.status === "Success") {
    //       this.setState({
    //         finances: res.data.transactions,
    //       });
    //     }
    //   });
    // } 
    else if (val === "banks") {
      this.fetchAllNach();
    } else if (val === "data-vault") {
      const id = this.props.match.params.id;
      let investeeOnboardedId = this.props.onboardingData._id;
      this.fetchMonthlyData();
      this.fetchIntegrations();
      this.fetchMonthlyViewData();
      if (this.props.onboardingData?.is_application_submitted) {
        this.fetchDocumentationData();
      }
    }
  };

  fetchBankList = () => {
    const id = this.props.match.params.id;
    callApi(`admin/repayments/organization/${id}/bank-list`, "post", {
      organization_type: "investee",
    }).then(res => {
      if (res.status === "Success") {
        this.setState({
          banklist: res.data.banklist,
        });
      }
    });
  };

  fetchDocumentationData = (isModerating = false) => {
    let investeeOnboardedId = this.props.onboardingData._id;
    if (this.props.onboardingData.status === "Approved") {
      callApi(
        `investee_kyc_application/investee_onboarding/${investeeOnboardedId}/admin/documents`,
        "get"
      ).then(res => {
        if (res.status === "Success") {
          if (isModerating) {
            this.setState({ documentationData: res.data, isModeratingDocumentation: true });
          }
          else {
            this.setState({ documentationData: res.data });
          }
        }
        else {
          const message = res.data?.message
            ? res.data?.message
            : "Some error occurred!";
          showNotification("Error", message);
        }
      });
    }
  }

  fetchMonthlyData = () => {
    let investeeOnboardedId = this.props.onboardingData?._id;
    callApi(
      `data-vault/${investeeOnboardedId}/monthly-data-upload-table`,
      "get"
    ).then(res => {
      if (res.status === "Success") {
        this.setState({ monthlyData: res.data, contractsCount: res.contractsCount });
      } else {
        const message = res.data?.message?.length
          ? res.data?.message
          : "Some error occurred!";
        showNotification("Error", message);
      }
    });
  };

  fetchMonthlyViewData = () => {
    const months = getPrevious12Months();
    let investeeOrgId = this.props.investee_organization._id;
    callApi(
      `insights/data-vault/${investeeOrgId}/monthly-view`,
      "get", {}, {}, false, true
    ).then(res => {
      if (res.statusCode === 200) {
        const data = res.data || {};
        let bankStatementDates = {};
        let availableGSTMonthlyDates = {};
        let gstStatus = '';
        months.forEach((month) => {
          let allIngested = true;
          let status = "";
          Object.keys(data?.availableBankTrxMonthlyDates || {}).forEach((account) => {
            if (allIngested && (!data.availableBankTrxMonthlyDates?.[account]?.[month] || data.availableBankTrxMonthlyDates?.[account]?.[month] !== INGESTED)) {
              allIngested = false;
            }
            if (!status) {
              if (data.availableBankTrxMonthlyDates?.[account]?.[month] === UPLOADED) status = UPLOADED;
            }
          });
          if (Object.keys(data?.availableBankTrxMonthlyDates ?? {}).length === 0) {
            allIngested = false;
          }
          bankStatementDates[month] = status ? status : allIngested ? INGESTED : NOT_UPLOADED;


          if (!isEmpty(data.availableInvoiceGstMonthlyDates)) {
            let allUploaded = true;
            let allIngested = true;
            Object.keys(data?.availableInvoiceGstMonthlyDates || {}).forEach((gst) => {
              if ((data.availableInvoiceGstMonthlyDates?.[gst]?.[month] !== UPLOADED) && allUploaded)
                allUploaded = false;

              if (data.availableInvoiceGstMonthlyDates?.[gst]?.[month] !== INGESTED && allIngested)
                allIngested = false;
            });
            if(allIngested) gstStatus = INGESTED;
            else if(allUploaded) gstStatus = UPLOADED;
            else gstStatus = NOT_UPLOADED;
          } else gstStatus = NOT_UPLOADED;
          availableGSTMonthlyDates[month] = gstStatus;
        });
        data.bankStatementDates = bankStatementDates;
        data.availableGSTMonthlyDates = availableGSTMonthlyDates;
        this.setState({ monthlyViewData: data });
      } else {
        const message = res.data?.message
          ? res.data?.message
          : "Some error occurred!";
        showNotification("Error", message);
      }
    });
  }

  fetchIntegrations = () => {
    let investeeOnboardedId = this.props.onboardingData?._id;
    callApi(
      `data-vault/${investeeOnboardedId}/investee-integration-info`,
      "get"
    )
      .then(res => {
        if (res.status === "Success") {
          const integrationsObj = { ...this.state.integrationsObj };
          const {
            integration_mis,
            integration_invoicing,
            integration_contracts,
            integration_bank_statements,
          } = res.data;
          integrationsObj.misIntegrations =
            integration_mis && integration_mis !== {}
              ? integration_mis
              : integrationsObj.misIntegrations;
          integrationsObj.invoicingIntegrations =
            integration_invoicing && integration_invoicing !== {}
              ? integration_invoicing
              : integrationsObj.invoicingIntegrations;
          integrationsObj.contractsIntegrations =
            integration_contracts && integration_contracts !== {}
              ? integration_contracts
              : integrationsObj.contractsIntegrations;
          integrationsObj.bankStatementsIntegrations =
            integration_bank_statements && integration_bank_statements !== {}
              ? integration_bank_statements
              : integrationsObj.bankStatementsIntegrations;
          this.setState({ integrationsObj });
        } else {
          showNotification("Error", "Some error occurred!");
        }
      })
      .catch(() => showNotification("Error", "Some error occurred!"));
  };

  downloadIntegrationFiles = (e, type) => {
    e.stopPropagation();
    let investeeOrgId = this.props.investee_organization._id;
    downloadFileUsingFetch(
      `downloadMonthlyDataFiles`,
      "post", null
      , `${type}.zip`, null, false,
      {
        investeeOrgId: investeeOrgId,
        type: type
      },
    ).then(res => {
      if (res.status === "Success") {
        showNotification("Success", "Downloaded zip successfully");
      } else {
        const message = res.data?.message?.length
          ? res.data?.message
          : "Some error occurred!";

        showNotification("Error", message);
      }
    });
  }

  addBankAccount = bank => {
    this.setState({
      isCreatingAccount: true,
    });
    const id = this.props.match.params.id;
    callApi(`repayments/organization/${id}/create-bank-account`, "post", {
      organization_type: "investee",
      bank: bank,
    }).then(res => {
      this.setState({
        isCreatingAccount: false,
        isAddBankModalVisible: false,
        bank : {}
      });
      if (res.status === "Success") {
        const pennyDropVerificationStatus = res.data?.organization_bank?.pennyDropVerificationStatus;
        // showNotification("Success", "Successfully created bank");
        if (res?.status === 'Success' && pennyDropVerificationStatus === DECENTRO_BANK_VERIFIED_SUCCESS) { 
          showNotification('Success', 'Bank Verified'); 
        } else if (pennyDropVerificationStatus === DECENTRO_BANK_FAILURE) { 
          showNotification( 'Error', 'Verification failed! Please check Account details and try again!', ); 
        } else if (pennyDropVerificationStatus === DECENTRO_BANK_PENDING) { 
          showNotification('Error', 'Something went wrong! Please try again later'); 
        } else { showNotification( 'Error', 'Not able to verify Bank Account ! please try after some', ); }
      } else {
        showNotification("Error", res.data.message);
      }
      this.fetchBankList();
    });
  };

  fetchAllNach = () => {
    callApi(`nach/company-mandates?orgId=${this.props.investee_organization._id}`, "get", {}, {}, false, false, false, false, false, false, true).then((res) => {
      if (res?.responseData?.responseCode === 20 && res.data) {
        this.setState({ nachList: res.data });
      } else {
        showNotification("error", res?.responseData?.responseMessage ?? "Error occured while fetching NACH list");
      }
    });
  }

  handleNachSyncStatus = () => {
    callApi(`/nach_details/sync_nach_status/escrow`, "post", {
      investee_organization_id: this.props.investee_organization._id
    }).then((res) => {
      if (res.status === "Success") {
        showNotification("success", "Status synced successfully");
        this.fetchAllNach();
      } else {
        showNotification("error", "Error occured while syncing status");
      }
    });
  }


  createNach = async (nach) => {
    const {lender_name, nach_type, end_date, nach_amount, prime_signatory, prime_signatory_phone} = nach;
    const referenceId = this.state.lenderDetailList.investorDeals.find(item => item.investorOrgId === lender_name).referenceId;
    const investorName = this.state.lenderDetailList.investorDeals.find(item => item.investorOrgId === lender_name).investorName;
    this.setState({disableCreateNachButton: true})

    let data = {
      orgId: this.props.investee_organization._id,
      mandateType: nach_type,
      nachAmount: nach_amount * 100000,
      payerPhone: prime_signatory_phone,
      endDate: end_date,
      investorOrgId: lender_name,
      referenceId: referenceId,
      payerName: prime_signatory
    }
    const res = await createFacilityNach(data);
    if(res) {
      this.setState(prevState => ({
        disableCreateNachButton: false,
        isCreateNachModalVisible: !prevState.isCreateNachModalVisible,
        nachList: [{...res, investorName: investorName}, ...prevState.nachList]
      }));
      showNotification("success", "Successfully created Facility Nach");
    } else {
      this.setState({
        disableCreateNachButton: false,
        isCreateNachModalVisible: !this.state.isCreateNachModalVisible,
      });
    }
  }

  toggleCreateNach = async () => {
    if(!isEmpty(this.state.lenderDetailList)) {
      this.setState({
        isCreateNachModalVisible: !this.state.isCreateNachModalVisible,
      });
    } else { 

      const fetchOrgNachResponse = await fetchOrgNachDetails(this.props.investee_organization._id);

      if(fetchOrgNachResponse) {
        this.setState({ 
          lenderDetailList: fetchOrgNachResponse,
          isCreateNachModalVisible: !this.state.isCreateNachModalVisible
        });
      }
    }
  };

  toggleAddBank = () => {
    this.setState({
      isAddBankModalVisible: !this.state.isAddBankModalVisible,
    });
  };

  setNachListState = (nachList) => {
    this.setState({
      nachList: nachList
    })
  }

  render() {
    return (
      <section className="row  main-body">
        <Helmet>
          <title>{"Admin-Company-Detail | Recur Club"}</title>
        </Helmet>
        <AdminHead />
        <CompaniesDetailPage
          setBank={(item)=>{
            this.setState({
              ...this.state,
              bank:item,
              isAddBankModalVisible:true
            })
          }}
          investee={this.state.investee}
          members={this.state.members}
          isCreatingAccount={this.state.isCreatingAccount}
          isCreatingNach={this.state.isCreatingNach}
          contracts={this.state.contracts}
          finances={this.state.finances}
          nachList={this.state.nachList}
          handleNachSyncStatus={this.handleNachSyncStatus}
          selectedSegment={this.state.selectedSegment}
          toggleSegment={this.toggleSegment}
          toggleAddBank={this.toggleAddBank}
          toggleCreateNach={this.toggleCreateNach}
          investeeOrganization={this.props.investee_organization}
          investeeProfileMetrics={this.props.investee_profile_metrics}
          investeeContractStats={this.props.investee_contract_stats}
          user={this.state.user}
          onboardingData={this.props.onboardingData}
          fetchMonthlyData={this.fetchMonthlyData}
          monthlyData={this.state.monthlyData}
          fetchDocumentationData={this.fetchDocumentationData}
          documentationData={this.state.documentationData}
          isModeratingDocumentation={this.state.isModeratingDocumentation}
          setIsModeratingDocumentation={val => this.setState({ isModeratingDocumentation: val })}
          integrationsObj={this.state.integrationsObj}
          capitalPartners={this.props.capitalPartners}
          isUserHasUWViewAccess={this.props.isUserHasUWViewAccess}
          contractsCount={this.state.contractsCount}
          investee_organization_id={this.props.match.params.id}
          monthlyViewData={this.state.monthlyViewData}
          downloadIntegrationFiles={this.downloadIntegrationFiles}
          setNachListState={this.setNachListState}
          fetchAllNach={this.fetchAllNach}
        />
        <>
          {this.state.isAddBankModalVisible ? (
            <AddNewBankModal
              isCreatingAccount={this.state.isCreatingAccount}
              show={this.state.isAddBankModalVisible}
              addBank={this.addBankAccount}
              closeModal={() =>{ 
                this.setState({
                  ...this.state,
                  bank:{},
                }) 
                this.toggleAddBank()
               } }
              bank={this.state.bank}
            />
          ) : null}
        </>
        <>
          {this.state.isCreateNachModalVisible ? (
            <CreateNachModal
              isCreatingNach={this.state.isCreatingNach}
              show={this.state.isCreateNachModalVisible}
              createNach={this.createNach}
              closeModal={() => this.toggleCreateNach()}
              banklist={this.state.banklist}
              lenderDetailList={this.state.lenderDetailList}
              disableCreateNachButton={this.state.disableCreateNachButton}
            />
          ) : null}
        </>
      </section>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    // capitalPartners: getCapitalPartners(state),
    investee_contract_stats: getInvesteeContractStats(state),
    investee_profile_metrics: getInvesteeProfileMtrics(state),
    investee_organization: getInvesteesOrganization(state),
    onboardingData: getInvesteeOnboardingData(state),
    isUserHasEditAccess: getCheckIfUserHasRelevantPermissions(state, ["app:edit"]),
    isUserHasUWViewAccess: getCheckIfUserHasRelevantPermissions(state, ["underwriting:edit"]),
    isLimitAddOnRequested: getIsAddOnLimitedRequested(state)
  };
}
export default withRouter(connect(mapStateToProps)(CompaniesViewPage));
