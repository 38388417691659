import React from "react";

const InvestorTrades = ({ contracts, to_list_contracts, onChangeCheckbox }) => {
  return (
    <>
      <div className='admin-data-table1 tableFixHead-contract'>
        <table className='table team'>
          <thead className='team-head'>
            <tr>
              <th scope='col' className='pl-2'>
                Customer
              </th>
              <th scope='col'>Contract ID</th>
              <th scope='col'>Term</th>
              <th scope='col'>Payments</th>
              <th scope='col'>Payouts</th>
            </tr>
          </thead>
          <tbody className='team-body'>
            {contracts.map((item) => {
              return (
                <tr>
                  {item.is_contract_approved ? (
                    <>
                      <td className='Contract-With-Company pl-2'>
                        <input
                          type='checkbox'
                          style={{ width: "30px" }}
                          value={JSON.stringify(item)}
                          name={item._id}
                          checked={
                            to_list_contracts &&
                            to_list_contracts.some((i) => i._id === item._id)
                          }
                          onClick={onChangeCheckbox}
                        />
                        {item.name}
                      </td>
                      <td>
                        {item.contract_id_num}
                      </td>
                      <td>
                        {item.contract_term_value} {item.contract_term_unit}
                      </td>
                      <td>
                        &#8377;{" "}
                        {item.recurring_payment_value.toLocaleString("en-IN")}
                      </td>
                      <td>
                        <b>
                          &#8377;{" "}
                          {(
                            item.recurring_payment_value *
                            item.contract_term_value *
                            item.contract_price
                          ).toLocaleString("en-IN")}
                        </b>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className='Contract-With-Company-Sync pl-2'>
                        <img src='/assets/sync-black-24-dp.svg' alt='' className="mr-2"></img>
                        {item.name}
                      </td>
                      <td className='Contract-Table-Row-Sync'>
                        {item.contract_id_num}
                      </td>
                      <td className='Contract-Table-Row-Sync'>
                        {item.contract_term_value} {item.contract_term_unit}
                      </td>
                      <td className='Contract-Table-Row-Sync'>
                        &#8377;{" "}
                        {item.recurring_payment_value.toLocaleString("en-IN")}
                      </td>
                      <td className='Contract-Table-Row-Sync'>
                        <b>
                          &#8377;{" "}
                          {(
                            item.recurring_payment_value *
                            item.contract_term_value *
                            item.contract_price
                          ).toLocaleString("en-IN")}
                        </b>
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default InvestorTrades;
