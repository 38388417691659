import { mergeByProperty } from "../../util/calculateTermUtil";
import {
  FETCHING_INVESTEES,
  FETCHING_TRANSACTIONS_BY_CONTRACT_ID,
  FETCH_INVESTEES,
  FETCH_TRANSACTIONS_BY_CONTRACT_ID,
  FETCHING_INVESTEES_DETAILS,
  FETCH_INVESTEES_DETAILS,
  UPDATING_INVESTEE_DETAILS,
  UPDATED_INVESTEE_DETAILS,
  FETCHING_KYC_DOCS,
  FETCH_KYC_DOCS,
  FETCHING_CONNECTED_PG_LIST,
  UPDATE_INVESTEE_ORGANIZATION,
  FETCH_TOGGLE_INFO,
  FETCHING_TOGGLE_INFO,
  FETCH_DEAL_REPORT,
  UPDATE_TOGGLE_INFO
} from "./AdminActions";
// import {FETCH_TEAM_MEMBERS, FETCHING_TEAM_MEMBERS, ADD_NEW_MEMBER} from "./InvestorActions";

const initialState = {
  is_fetching_members: true,
  members: [],
  dashboardStats: [],
  is_fetching_trade_contracts: false,
  trade_contracts: [],
  is_fetching_tradebook_contracts: false,
  tradebook_contracts: [],
  platform_faqs: [],
  is_fetching_transactions_by_contract_id: false,
  transactions_for_contract: [],
  isFetchingInvestees: false,
  investees: [],
  underwriters: [],
  investeesTotalCount: 0,
  isUpdatingInvesteeDetails: false,
  investee_organization: {},
  investee_profile_metrics: {},
  investee_contract_stats: {},
  is_fetching_investees_details: false,
  investeeOnboardingData: {},
  isFetchingKycDocs: false,
  kycDocs: {},
  connectedPGList: [],
  isEmptyConnectedPgList: false,
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
        case FETCHING_TEAM_MEMBERS:
            return {
                ...state,
                is_fetching_members: action.is_fetching_members,
            };

        case ADD_NEW_MEMBER:
            return {
                ...state,
            };

        case FETCH_TEAM_MEMBERS:
            return {
                ...state,
                is_fetching_members: action.is_fetching_members,
                members: action.members,
            };
        */

    case FETCHING_INVESTEES:
      return {
        ...state,
        isFetchingInvestees: action.is_fetching_investees,
      };

    case FETCH_INVESTEES:
      let investees = action.investees;
      if (!action.isReseting) {
        investees = mergeByProperty(
          [...state.investees],
          action.investees,
          "_id"
        );
      }
      return {
        ...state,
        investees,
        aggregrateDetails: action.aggregrateDetails,
        underwriters: action.underwriters,
        companyStatuses: action.companyStatuses,
        underWritingStatuses: action.underWritingStatuses,
        investeesTotalCount: action.totalCount,
        isFetchingInvestees: action.is_fetching_investees,
      };

    case FETCHING_KYC_DOCS:
      return {
        ...state,
        isFetchingKycDocs: action.isFetchingKycDocs,
      };

    case FETCH_KYC_DOCS:
      return {
        ...state,
        kycDocs: action.kycDocs,
        isFetchingKycDocs: action.isFetchingKycDocs,
      };

    case FETCHING_TRANSACTIONS_BY_CONTRACT_ID:
      return {
        ...state,
        is_fetching_transactions_by_contract_id:
          action.is_fetching_transactions_by_contract_id,
      };

    case FETCH_TRANSACTIONS_BY_CONTRACT_ID:
      return {
        ...state,
        transactions_for_contract: action.transactions_for_contract,
        is_fetching_transactions_by_contract_id:
          action.is_fetching_transactions_by_contract_id,
      };

    case FETCHING_INVESTEES_DETAILS:
      return {
        ...state,
        is_fetching_investees_details: action.is_fetching_investees_details,
      };

    case FETCH_INVESTEES_DETAILS:
      return {
        ...state,
        investee_organization: action.investee_organization,
        investee_profile_metrics: action.investee_profile_metrics,
        investee_contract_stats: action.investee_contract_stats,
        investeeOnboardingData: action.investeeOnboardingData,
        capitalRequested:action.capital_requested,
        is_fetching_investees_details: action.is_fetching_investees_details,
      };
    case FETCH_TOGGLE_INFO:
      return {
        ...state,
        gst_annual_rev: action.gst_annual_rev,
        rrf_toggle: action.rrf_toggle,
        debt_syndication_toggle: action.debt_syndication_toggle,
        is_fetching_toggle_info: action.is_fetching_toggle_info,
      };
    case UPDATE_TOGGLE_INFO:
      return {
      ...state,
        rrf_toggle: action.rrf_toggle,
        debt_syndication_toggle: action.debt_syndication_toggle,
    }
    case FETCHING_TOGGLE_INFO:
      return {
        ...state,
        is_fetching_toggle_info: action.is_fetching_toggle_info,
      };
    case FETCH_DEAL_REPORT:
      return {
        ...state,
        deal_report: action.deal_report,
      };

    case UPDATING_INVESTEE_DETAILS:
      return {
        ...state,
        isUpdatingInvesteeDetails: action.isUpdatingInvesteeDetails,
      };

    case UPDATED_INVESTEE_DETAILS:
      return {
        ...state,
        investee_organization: action.investee_organization,
        isUpdatingInvesteeDetails: action.isUpdatingInvesteeDetails,
      };

    case FETCHING_CONNECTED_PG_LIST:
      return {
        ...state,
        connectedPGList: action.connectedPGList,
        isEmptyConnectedPgList: action.isEmptyConnectedPgList,
      };
    case UPDATE_INVESTEE_ORGANIZATION:
      return {
        ...state,
        investee_organization: { ...action.data },
      };
    default:
      return state;
  }
};

export default adminReducer;

// selectors
export const getTeamMembers = (state) => state.investor.members;
export const getTeamMemberLoadingState = (state) =>
  state.investor.is_fetching_members;
export const getDashboardStats = (state) => state.investor.dashboardStats;
export const getTradeContracts = (state) => state.investor.trade_contracts;
export const getTradeBookContracts = (state) =>
  state.investor.tradebook_contracts;
export const getTransactionForContract = (state) =>
  state.admin.transactions_for_contract;
export const getInvestees = (state) => state.admin.investees;
export const getAggregrateDetailsUW = (state) => state.admin.aggregrateDetails;

export const getUnderwriters = (state) => state.admin.underwriters;
export const getCompanyStatues = (state) => state.admin.companyStatuses;
export const getUnderWritingStatuses = (state) =>
  state.admin.underWritingStatuses;

export const getIsFetchingInvestees = (state) =>
  state.admin.isFetchingInvestees;
export const getInvesteesTotalCount = (state) =>
  state.admin.investeesTotalCount;
export const getIsUpdatingInvesteeDetails = (state) =>
  state.admin.isUpdatingInvesteeDetails;
export const getInvesteesOrganization = (state) =>
  state.admin.investee_organization;
export const getInvesteesOrgDisplayName = (state) =>
  state.admin?.investee_organization?.display_name;
export const getInvesteeProfileMtrics = (state) =>
  state.admin.investee_profile_metrics;
export const getInvesteeContractStats = (state) =>
  state.admin.investee_contract_stats;
export const getInvesteeOnboardingData = (state) =>
  state.admin.investeeOnboardingData;
export const getCapitalRequested=(state)=> state.admin.capitalRequested
export const getIsFetchingInvesteesDetails = (state) =>
  state.admin.is_fetching_investees_details;
export const getGstAnnualRev = (state) => state.admin.gst_annual_rev;
export const getRrfToggleDetails = (state) => state.admin.rrf_toggle;
export const getDebtSyndicationToggleDetails = (state) =>
  state.admin.debt_syndication_toggle;
export const getDealReport = (state) => state.admin.deal_report;
export const getKycDocs = (state) => state.admin.kycDocs;
export const getIsFetchingKycDocs = (state) => state.admin.isFetchingKycDocs;
export const getConnectedPgList = (state) => state.admin.connectedPGList;
export const isEmptyConnectedPgList = (state) =>
  state.admin.isEmptyConnectedPgList;
