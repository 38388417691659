import React, {
    // Component
} from 'react';
// import {Link} from 'react-router-dom';
import FilterHeaderComponent from '../../../../../components/FilterHeaderComponent/FilterHeaderComponent';

function WithdrawalTableHeader(props) {
    return (
        <thead className="team-head withdraw-table">
        <tr>
            <th>Submitted On</th>
            <th>Organization Name</th>
            <th>Type</th>
            <th>Approval Status</th>
            <th>Account Type</th>
            <th>to Bank</th>
            <th>Amount</th>
            <th>Submitted by</th>
            <th></th>
            <th></th>
        </tr>
        </thead>
    )
}

export default WithdrawalTableHeader;
