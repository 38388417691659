import dayjs from "dayjs";
import React, { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { OVERVIEW_SCREENMAP } from "../../../../../../../common/ENUM";
import { changeScreenOnCompaniesOverview } from "../../../../../../App/AppActions";
import ctaArrow from "../../../../../../../assets/chevron-right.svg"
import styles from './ApprovedLimits.module.scss';
import { queryParams, tabsEnum } from "../../../CompaniesPage";

export const ApprovedLimitsTableRow = ({item, ...props}) => {

  const {
    company_link,
    dealStatusStyle,
    deal_status_expired,
    deal_status_completed,
    deal_status_not_shared,
    deal_status_shared,
    deal_status_approved,
    deal_status_query,
    deal_status_others,
    deal_status_deal_not_created,
    limits_action,
    deal_status_width,
    cta,
    tableBox,
    addOn,
    deal_status_partially_shared
  } = styles;

  const {
    companyName,
    companyStatus,
    limit,
    maxTenure,
    acceptedShared,
    underwriter,
    displayName,
    orgId,
    dealStatus,
    lastActivity,
    action,
    multipleActions,
    isAddOn
  } = item;

  const observer = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const lastInvesteeRef = useCallback(
    (node) => {
      if (props.loader) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && props.hasMore()) props.loadMore();
      });
      if (node) observer.current.observe(node);
    },
    [props.loader, props.hasMore()]
  );

  const getDealStatusClassName = () => {
    switch (dealStatus) {
      case "EXPIRED":
        return deal_status_expired;
      case "COMPLETED":
        return deal_status_completed;
      case "NOT SHARED":
        return deal_status_not_shared;
      case "SHARED":
        return deal_status_shared;
      case "PARTIALLY SHARED":
        return deal_status_partially_shared;
      case "APPROVED":
        return deal_status_approved;
      case "QUERY":
        return deal_status_query;
      case "OTHERS":
        return deal_status_others;
      case "DEAL NOT CREATED":
        return deal_status_deal_not_created;
      default:
        return "";
    }
  }

  const redirectToDealsPage = (e, companyName) => {
    e.preventDefault();
    e.stopPropagation();
    const stateObj = {
      searchCompanyName: companyName
    }
    history.push({
      pathname: `/admin/company/list`,
      search: `?tab=${queryParams.DEALS}`,
      state: stateObj,
    });
  }

  const handleAction = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const stateObj = {
      openDataVaultTab: false,
    };
    if(action === "RESOLVE QUERY") {
      history.push({
        pathname: "/admin/platformhelp/list",
      });
      return;
    }
    if (action === "CREATE TOT") {
      stateObj.openDataVaultTab = true;
    }
    else {
      dispatch(
        changeScreenOnCompaniesOverview(
          OVERVIEW_SCREENMAP.TOT
        )
      )
    }
    history.push({
      pathname: `/admin/company/${orgId}/view`,
      state: stateObj,
    });
  };


  const renderer = (
    <>
      <td className={tableBox} style={{ paddingLeft: "31px" }}>
      <Link className='link_anchor' to={`/admin/company/${orgId}/view`} onClick={e=> e.stopPropagation()}>
        <div className="d-flex h-100 align-items-center">
          <div
            title={displayName ?? companyName}
            className={`text-truncate ${company_link}`}
            style={{ maxWidth: "120px", paddingRight: "0px" }}
          >
            {displayName ?? companyName}
          </div>
          {!!isAddOn && <div className={addOn}>ADD ON</div>}
          </div>
        </Link>
      </td>
      <td>{companyStatus ?? "-"}</td>
      <td>{limit ?? "-"}</td>
      <td>{maxTenure ?? "-"}</td>
      <td
        style={{ color: "#4C8DFF" }}
        className="company-status"
      >
        {acceptedShared ?? "-"}
      </td>
      <td>{underwriter ?? "-"}</td>
      <td>
        <div
          className={`${dealStatusStyle} ${getDealStatusClassName()} ${deal_status_width}`}
        >
          {dealStatus ?? "-"}
        </div>
      </td>
      <td>{lastActivity ?? "-"}</td>
      <td>
        {action && action !== "-" ? (
          <button
            className={limits_action}
            onClick={(e)=> handleAction(e)}
            data-tip=""
            data-for={multipleActions.length > 1 ? `action-${props.index}` : ""}
          >
            <span>{action}</span>
            <ReactTooltip
              id={`action-${props.index}`}
              type="light"
              place="bottom"
              className="payout-tooltip"
              border={true}
              borderColor="black"
              delayShow={50}
              multiline={true}
            >
              <div className="tooltip-content">
                {multipleActions?.join(", ")}
              </div>
            </ReactTooltip>
          </button>
        ) : (
          <></>
        )}
      </td>
      <td>
        <div
          className={cta}
        >
          <img src={ctaArrow}></img>
        </div>
      </td>
    </>
  );

  if (props.dataLength === props.index + 1) {
    //@TODO fix css of this
    return <tr ref={lastInvesteeRef} className={cta} onClick={(e) => redirectToDealsPage(e, displayName ?? companyName)}>{renderer}</tr>;
  } else {
    return <tr className={cta} onClick={(e) => redirectToDealsPage(e, displayName ?? companyName)}>{renderer}</tr>;
  }
};
