import React, { useState } from "react";
import FromToDate from "./FromToDate/FromToDate";
import CompanyMetricsView from "./CompanyMetricsView/CompanyMetricsView";
import HistoryBox from "./HistoryBox/HistoryBox";
import styles from "../metrics.module.css";
const MetricsBox = ({ investeeOrganization, year, quarter, id, setId }) => {
  const [isReadonly, setIsReadonly] = useState(true);
  const [res, setRes] = useState([]);
  const [Reload, setReload] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [disableUpdate, setDisableUpdate] = useState(false);
  const [notActiveTabClicked, setNotActiveTabClicked] = useState();
  const [lastUpdate, setLastUpdate] = useState();
  const [activeType, setActiveType] = useState();
  const [activeClicked, setActiveClicked] = useState(true);
  const [makeActiveClicked, setMakeActiveClicked] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [editSave, setEditSave] = useState(false);
  const [disable_date, set_disable_date] = useState(true);
  const [reRenderId, setReRenderId] = useState(false);
  const [computeType, setComputeType] = useState(null);
  function formatDate(input) {
    input = input.split("-").join("/");
    let datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  function formatDateSlice(input) {
    const changed_format = input.slice(0, 10);
    return formatDate(changed_format);
  }

  function createdAtTime(input) {
    const time = input.slice(11, 16);
    return time;
  }

  function jsDate(dateString) {
    let dateParts = dateString.split("/");
    // month is 0-based, that's why we need dataParts[1] - 1
    let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    const date_obj_string = dateObject.toString().slice(4, 15);
    const month = date_obj_string.slice(0, 3);
    const day = date_obj_string.slice(4, 6);
    const year = date_obj_string.slice(7, 11);
    return `${day} ${month} ${year}`;
  }

  return (
    <section className={styles.full_section}>
      <div>
        <FromToDate
          investeeOrganization={investeeOrganization}
          setReload={setReload}
          setFirstRender={setFirstRender}
          setMakeActiveClicked={setMakeActiveClicked}
          setEditSave={setEditSave}
        />
        <HistoryBox
          isReadonly={isReadonly}
          setIsReadonly={setIsReadonly}
          investeeOrganization={investeeOrganization}
          res={res}
          setRes={setRes}
          id={id}
          setId={setId}
          Reload={Reload}
          setReload={setReload}
          firstRender={firstRender}
          disableUpdate={disableUpdate}
          setDisableUpdate={setDisableUpdate}
          setNotActiveTabClicked={setNotActiveTabClicked}
          formatDateSlice={formatDateSlice}
          createdAtTime={createdAtTime}
          jsDate={jsDate}
          setLastUpdate={setLastUpdate}
          setActiveType={setActiveType}
          setActiveClicked={setActiveClicked}
          makeActiveClicked={makeActiveClicked}
          setFromDate={setFromDate}
          setToDate={setToDate}
          editSave={editSave}
          set_disable_date={set_disable_date}
          setReRenderId={setReRenderId}
          setComputeType={setComputeType}
        />
      </div>
      <div>
        <CompanyMetricsView
          isReadonly={isReadonly}
          setIsReadonly={setIsReadonly}
          investeeOrganization={investeeOrganization}
          id={id}
          year={year}
          quarter={quarter}
          setReload={setReload}
          setFirstRender={setFirstRender}
          setDisableUpdate={setDisableUpdate}
          notActiveTabClicked={notActiveTabClicked}
          setNotActiveTabClicked={setNotActiveTabClicked}
          formatDateSlice={formatDateSlice}
          createdAtTime={createdAtTime}
          jsDate={jsDate}
          lastUpdate={lastUpdate}
          activeType={activeType}
          activeClicked={activeClicked}
          setMakeActiveClicked={setMakeActiveClicked}
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={setFromDate}
          setToDate={setToDate}
          setEditSave={setEditSave}
          disable_date={disable_date}
          set_disable_date={set_disable_date}
          reRenderId={reRenderId}
          setReRenderId={setReRenderId}
          computeType={computeType}
        />
      </div>
    </section>
  );
};

export default MetricsBox;
