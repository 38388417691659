import React from 'react';

function HelpRequestTableHeader(props) {
    return (
        <thead className="team-head admin-help">
        <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Email Subject</th>
            <th>Edit</th>
        </tr>
        </thead>
    )
}

export default HelpRequestTableHeader;
