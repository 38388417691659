import React, {Component} from 'react';
import HelpRequestTableHeader from './components/HelpRequestTableHeader';
import HelpRequestTableRow from './components/HelpRequestTableRow';
import AdminHead from '../../components/AdminHead.js'
import callApi from "../../../../util/apiCaller";
import {showNotification} from "../../../../util/utility";

class HelpRequestPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            platform_helps: [],
            pageNum: 1,
            pageSize: 25,
            totalCount: 0,
            qtext: '',
            searchObjContactName:{
                keyName: 'contact_name',
                value: ''
            },
            searchObjIssueDesc:{
                keyName: 'issue_description',
                value: ''
            },
            selectorObjIssueType : {
                keyName : "issue_type",
                elementsArray : [
                    {
                        label:"Issue 1",
                        selected:true 
                    },
                    {
                        label:"Issue 2",
                        selected:true 
                    }
                ]
            },
        }
    }

    keyStateObjNameDict = {
        contact_name: "searchObjContactName",
        issue_description:"searchObjIssueDesc",
        issue_type:"selectorObjIssueType"
    }

    componentDidMount() {
        this.fetchItems();
    }


    fetchItems = () => {
        let filters = this.getFilters();
        callApi(`admin/platformhelp/list`, "post", filters).then((res) => {
            if (res.status === 'Succes') {
                this.setState({
                    platform_helps: res.data.platform_helps,
                    totalCount: res.data.count
                })
            } else {
                showNotification("error", "Some error occured");
            }
        });
    }

    getFilters = () => {
        let filters = {};
        filters.pageNum = this.state.pageNum;
        filters.pageSize = this.state.pageSize;
        if (this.state.qtext) {
            filters.isSearching = true;
            filters.qtext = this.state.qtext;
        }
        filters.isFiltering = true
        filters.isSearching = true;
        filters[this.state.searchObjContactName.keyName] = this.state.searchObjContactName.value;
        filters[this.state.searchObjIssueDesc.keyName] = this.state.searchObjIssueDesc.value;
       
        filters[this.state.selectorObjIssueType.keyName] = {
            "in": this.state.selectorObjIssueType.elementsArray.map((item)=>{
                if(item.selected){
                    return item.label;
                }
            })
        }
        return filters;
    };

    handleApplyFilters = () => {
        this.fetchItems();
    };

    handleSearchFilter = (obj) => {
        let searchObj ={};
        searchObj[this.keyStateObjNameDict[obj.keyName]]=obj
        this.setState(searchObj, () => {
            this.handleApplyFilters();
        })
    };

    inputSelectorHandler = (obj) =>{
        let selectorObj ={};
        selectorObj[this.keyStateObjNameDict[obj.keyName]]=obj 
        this.setState(
            selectorObj,() => {
            this.handleApplyFilters();
        })
    }

    render() {
        return (
            <div className="main-body">
                <AdminHead/>
                <section id="admin-company">
                    <div className="request-data-table tableFixHead">
                        <table className="table team">
                            <HelpRequestTableHeader
                                searchObjContactName = {this.state.searchObjContactName}
                                searchObjIssueDesc = {this.state.searchObjIssueDesc}
                                selectorObjIssueType = {this.state.selectorObjIssueType}
                                inputSelectorHandler = {this.inputSelectorHandler}
                                handleSearchFilter = {this.handleSearchFilter}
                            />
                            <tbody className="team-body">
                            {this.state.platform_helps && this.state.platform_helps.map(item => (
                                <HelpRequestTableRow
                                    item={item}
                                />
                            ))}
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
        );
    }
}

export default HelpRequestPage;
