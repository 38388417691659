import React, {
    // Component
} from 'react';
// import {Link} from 'react-router-dom';
import FilterHeaderComponent from '../../../../../components/FilterHeaderComponent/FilterHeaderComponent';

function ClubAccessTableHeader(props) {
    return (
        <thead className="team-head admin-clubaccess club-table">
            <tr>
                <th style={{ position: 'unset' }}>User Name</th>
                <FilterHeaderComponent
                    isSortAllowed={true}
                    sorting_order={'asc'}
                    isFilterAllowed={true}
                    headerText={'Email Verify'}
                    labelText={'Filter Email Verify'}
                    filter_type={'selector'}
                    inputSelectorHandler = {props.inputSelectorHandler}
                    keyName = {props.selectorObjEmailVerify.keyName}
                    value = {props.selectorObjEmailVerify.value}
                    status = {props.selectorObjEmailVerify.status}
                />
                <th style={{ position: 'unset' }}>Phone Number</th>
                <FilterHeaderComponent
                    isSortAllowed={true}
                    sorting_order={'asc'}
                    isFilterAllowed={true}
                    headerText={'Club'}
                    labelText={'Filter Club'}
                    filter_type={'selector'}
                    inputSelectorHandler = {props.inputSelectorHandler}
                    keyName = {props.selectorObjClub.keyName}
                    value = {props.selectorObjClub.value}
                    status = {props.selectorObjClub.status}
                />
                <th style={{ position: 'unset' }}>Org. Name</th>
                <th style={{ position: 'unset' }}>User type</th>
                <th style={{ position: 'unset' }}>Email</th>
                <th style={{ position: 'unset' }}>Date requested</th>
                <FilterHeaderComponent
                    isSortAllowed={true}
                    sorting_order={'asc'}
                    isFilterAllowed={true}
                    headerText={'Status'}
                    labelText={'Filter Status'}
                    filter_type={'selector'}
                    inputSelectorHandler = {props.inputSelectorHandler}
                    keyName = {props.selectorObjStatus.keyName}
                    value = {props.selectorObjStatus.value}
                    status = {props.selectorObjStatus.status}
                    page = "Club Access"
                />
                <th style={{ position: 'unset' }}>Admin User</th>
                <th style={{ position: 'unset' }}>Last Updated</th>
                {/* <FilterHeaderComponent
                    isSortAllowed={true}
                    sorting_order={'desc'}
                    isFilterAllowed={true}
                    headerText={'User Name'}
                    labelText={'Search User Name'}
                    filter_type={'search'}
                    handleSearchFilter = {props.handleSearchFilter}
                    keyName = {props.searchObjUserName.keyName}
                    value = {props.searchObjUserName.value}
                />
                <th>Email Verify</th>
                <th>Phone Number</th>
                <FilterHeaderComponent
                    isSortAllowed={true}
                    sorting_order={'asc'}
                    isFilterAllowed={true}
                    headerText={'Club'}
                    labelText={'Filter Club'}
                    filter_type={'selector'}
                    inputSelectorHandler = {props.inputSelectorHandler}
                    elementsArray = {props.selectorObjClub.elementsArray}
                    keyName = {props.selectorObjClub.keyName}
                />
                <FilterHeaderComponent
                    isSortAllowed={true}
                    sorting_order={'desc'}
                    isFilterAllowed={true}
                    headerText={'Org. Name'}
                    labelText={'Search Org. Name'}
                    filter_type={'search'}
                    handleSearchFilter = {props.handleSearchFilter}
                    keyName = {props.searchObjOrgName.keyName}
                    value = {props.searchObjOrgName.value}
                />
                <FilterHeaderComponent
                    isSortAllowed={true}
                    sorting_order={'asc'}
                    isFilterAllowed={true}
                    headerText={'User type'}
                    labelText={'Filter User type'}
                    filter_type={'selector'}
                    inputSelectorHandler = {props.inputSelectorHandler}
                    elementsArray = {props.selectorObjUserType.elementsArray}
                    keyName = {props.selectorObjUserType.keyName}
                />
                <FilterHeaderComponent
                    isSortAllowed={true}
                    sorting_order={'desc'}
                    isFilterAllowed={true}
                    headerText={'Email'}
                    labelText={'Search Email'}
                    filter_type={'search'}
                    handleSearchFilter = {props.handleSearchFilter}
                    keyName = {props.searchObjEmail.keyName}
                    value = {props.searchObjEmail.value}
                />
                <FilterHeaderComponent
                    isFilterAllowed={true}
                    headerText={'Date requested'}
                    labelText={'Filter Date requested'}
                    filter_type={'datepicker'}
                    isSortAllowed={true}
                    sorting_order={'desc'}
                    handledatePicker = {props.handledatePicker}
                    date = {props.dateObjDateRequested.date}
                    keyName = {props.dateObjDateRequested.keyName}
                />
            
                <FilterHeaderComponent
                    isSortAllowed={true}
                    sorting_order={'asc'}
                    isFilterAllowed={true}
                    headerText={'Status'}
                    labelText={'Filter Status'}
                    filter_type={'selector'}
                    inputSelectorHandler = {props.inputSelectorHandler}
                    elementsArray = {props.selectorObjStatus.elementsArray}
                    keyName = {props.selectorObjStatus.keyName}
                />        
                <FilterHeaderComponent
                    isSortAllowed={true}
                    sorting_order={'desc'}
                    isFilterAllowed={true}
                    headerText={'Admin User'}
                    labelText={'Filter Admin User'}
                    filter_type={'search'}
                    handleSearchFilter = {props.handleSearchFilter}
                    keyName = {props.searchObjAdminUser.keyName}
                    value = {props.searchObjAdminUser.value}
                />    
                <FilterHeaderComponent
                    isFilterAllowed={true}
                    headerText={'Last Updated'}
                    labelText={'Filter Last Updated'}
                    filter_type={'datepicker'}
                    isSortAllowed={true}
                    sorting_order={'desc'}
                    handledatePicker = {props.handledatePicker}
                    date = {props.dateObjLastUpdated.date}
                    keyName = {props.dateObjLastUpdated.keyName}
                /> */}
            </tr>
        </thead>
    )
}

export default ClubAccessTableHeader;
