import dayjs from "dayjs";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState ,useRef} from "react";
import callApi from "../../../../util/apiCaller";
import { covertToNum, getS3IconLink, showNotification } from "../../../../util/utility";
import { useSelector } from "react-redux";
import { getUserName } from "../../../App/AppReducer";
import CollectionsProgressBar from "./CollectionsProgressBar";
import styles from "./components/NachCollectionsTableRow.module.scss";
import { PAYMENT_DEFAULT_RISKS, useNachToggleKeys } from "../../../../constants/enums";
import { METHODS } from "../../../../common/ENUM";
import { CancelNachModal } from "./CancelNachModal";
import InvestorBifercationTable from "./InvestorBifercationTable/InvestorBifercationTable";
import ReactTooltip from "react-tooltip";
import {RiskTagsTooltip} from "./components/RiskTagsTooltip";
import { FL_ICON_NAMES, ICON_NAMES } from "../../../../common/IconNames";

const { CollectionComments, commentsBox, UseNach, NoActive, YesActive , delayChargeText } = styles;

const getRiskClassNames = (riskType) => {
  if ([PAYMENT_DEFAULT_RISKS.LOW, PAYMENT_DEFAULT_RISKS.AGREED_FOR_NACH, PAYMENT_DEFAULT_RISKS.EARLY_REPAYMENT, PAYMENT_DEFAULT_RISKS.PARTIAL_RECEIVED, PAYMENT_DEFAULT_RISKS.NEFT_CONFIRMED].includes(riskType)) return PAYMENT_DEFAULT_RISKS.LOW;
  if ([PAYMENT_DEFAULT_RISKS.MEDIUM, PAYMENT_DEFAULT_RISKS.STOP_NACH, PAYMENT_DEFAULT_RISKS.CREDIT_CARD_PAYMENT, PAYMENT_DEFAULT_RISKS.LAST_DAY_PAYMENT].includes(riskType)) return PAYMENT_DEFAULT_RISKS.MEDIUM;
  if ([PAYMENT_DEFAULT_RISKS.HIGH, PAYMENT_DEFAULT_RISKS.GRACE_PERIOD, PAYMENT_DEFAULT_RISKS.NACH_FAILED, PAYMENT_DEFAULT_RISKS.PAYMENT_NOT_REFLECTING, PAYMENT_DEFAULT_RISKS.NEFT_CONFIRMED,PAYMENT_DEFAULT_RISKS.UNRESPONSIVE].includes(riskType)) return PAYMENT_DEFAULT_RISKS.HIGH;
  return '';
}
export const CollectionsTableRow = ({
  _id,
  item,
  growthPartners, needToShowInvestorBifurcation,
  riskTags,
  setCollectionData,
    detailedRiskTags,
  type,
  collectionData,
                                      metricsDate,
  closeAllDropDowns,
  setCloseAllDropDowns,
  index,
  fetchCollectionData,
  beta,
  changeInRiskFlag
}) => {
  const {
    collectionDate,
    companyName,
    collectedAmount,
    remainingCollectionAmt,
    collectionAmount,
    neftAmount,
    payment_default_risk,
    payment_method,
    owner,
    comments,
    investeeOrgId,
    successRate,
    availableNachAmount,
    usedNachAmount,
    useNach,
    useNachFilter,
    delayCharges,
    isFnnDateCrossed,
  } = item;

  const RISK_CLASS_NAMES = {
    [PAYMENT_DEFAULT_RISKS.LOW]: styles.GreenCircle,
    [PAYMENT_DEFAULT_RISKS.MEDIUM]: styles.YellowCircle,
    [PAYMENT_DEFAULT_RISKS.HIGH]: styles.RedCircle
 }

const dropdownType = {
  RISK: "Risk",
  OWNER: "Owner"
}
  const isCollectionDateLessThanThisMonth = moment(collectionDate).isBefore(metricsDate, 'month');
  const isInCurrentMonth = moment(collectionDate).isSame(new Date(), 'month') ? true : false;

  const loggedInUserEmail = useSelector(getUserName);

  const [riskLevel, setRiskLevel] = useState("");
  const [ownerName, setOwnerName] = useState("");

  const [collectionRowComments, setCollectionRowComments] = useState(null);
  const [isEditComments, setIsEditComments] = useState(false);

  const [showInvestorWiseBreakdown,setShowInvestorWiseBreakdown] = useState(false);
  const [showDetailedRiskTags,setShowDetailedRiskTags] = useState(false);
  const [useNachSelected, setUseNachSelected] = useState(false);

  const [showCancelNachModal, setShowCancelNachModal] = useState(false);
  const [nachComments, setNachComments] = useState(null);

  const userName = useSelector(state => getUserName(state));

  const commentsBoxRef = useRef(null);
  
  const updateApiPayload = {
    collectionDate: collectionDate,
    investeeOrgId: investeeOrgId,
    collectionAmount,
  };

  const modifyApiPayload = (keyName, keyValue) => {
     updateApiPayload[keyName] = keyValue;
  }

  useEffect(() => {
    if (riskLevel !== payment_default_risk && riskLevel !== "") {
      updateApiPayload.defaultRisk = riskLevel;
      setCollectionData((prevCollectionData) => {
        const newCollectionData = [...prevCollectionData];
        //if (type !== "") {
          const newIndex = newCollectionData.findIndex(
            (row) =>
              row.investeeOrgId === item.investeeOrgId &&
              row.collectionDate === item.collectionDate
          );
          newCollectionData[newIndex].payment_default_risk = riskLevel;
          return newCollectionData;
        //}
      });
      updateCollectionRow(updateApiPayload);
    }
  }, [riskLevel]);

  useEffect(() => {
    if (ownerName !== owner && ownerName !== "") {
      updateApiPayload.owner = ownerName;
      setCollectionData((prevCollectionData) => {
        const newCollectionData = [...prevCollectionData];
          const newIndex = newCollectionData.findIndex(
            (row) =>
              row.investeeOrgId === item.investeeOrgId &&
              row.collectionDate === item.collectionDate
          );
          newCollectionData[newIndex].owner = ownerName;
          return newCollectionData;
      });
      updateCollectionRow(updateApiPayload);
    }
  }, [ownerName]);
  useEffect(() => {
    /**
     * if clicked on outside of element
     */
      // Bind the event listener
      if(isEditComments) {
        document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
      }
  }, [collectionRowComments, isEditComments]);

  useEffect(() => {
    if(useNach && useNach === useNachToggleKeys.NO) {
      setUseNachSelected(false);
    }
    else if(useNach && useNach === useNachToggleKeys.YES) {
      setUseNachSelected(true);
    }
    else if(useNachFilter && useNachFilter === useNachToggleKeys.YES) setUseNachSelected(true);
    else if(payment_method === METHODS.NACH || payment_method === METHODS['NACH + NEFT']) {
      setUseNachSelected(true);
    }
    else setUseNachSelected(false);
  }, [payment_method ,useNach])

  const toggleUseNach = () => {
    if(useNachSelected) {
      setShowCancelNachModal(true);
    }
    else if (!useNachSelected && useNach && useNach === useNachToggleKeys.NO) {
      setShowCancelNachModal(true);
    }
  }

  const updateComments = (preserveComments, keepNach) => {
    if (preserveComments) updateApiPayload.comments = [
          
    ]
    updateApiPayload.comments = {
      data: preserveComments ? `${comments} ${collectionRowComments}` : collectionRowComments ?? nachComments,
      owner: userName,
    };
    const addTextBeforeNachComment = (updateComment) => {
      if(nachComments && !collectionRowComments) {
        if(keepNach) {
          if(updateComment) newCollectionData[newIndex].comments[0].data = `Keep NACH ${nachComments}`;
          else updateApiPayload.comments.data = `Keep NACH ${nachComments}`;
        }
        else {
          if(updateComment) newCollectionData[newIndex].comments[0].data = `Cancel NACH ${nachComments}`
          updateApiPayload.comments.data = `Cancel NACH ${nachComments}`;
        }
      }
    }
    addTextBeforeNachComment();
    const newCollectionData = [...collectionData];
    const newIndex = newCollectionData.findIndex(
      (row) =>
        row.investeeOrgId === item.investeeOrgId &&
        row.collectionDate === item.collectionDate
    );
    if (
      !isEmpty(newCollectionData[newIndex]?.comments) &&
      Array.isArray(newCollectionData[newIndex]?.comments)
    ) {
      newCollectionData[newIndex].comments.unshift({
        data: collectionRowComments ?? nachComments,
        date: new Date(),
        owner: userName,
      });
      addTextBeforeNachComment(true);
    } else {
      newCollectionData[newIndex].comments = [
        {
          data: preserveComments ? `${comments} ${collectionRowComments}` : collectionRowComments ?? nachComments,
          date: new Date(),
          owner: userName,
        },
      ];
      addTextBeforeNachComment(true);
    }
    setCollectionData(newCollectionData);
    if(nachComments && !collectionRowComments) {
      updateCollectionRow(updateApiPayload, true);
    }
    else {
      updateCollectionRow(updateApiPayload);
    }
    setCollectionRowComments(null);
    setNachComments(null);
  }

  function handleClickOutside(e) {
    if (!(commentsBoxRef.current && isEditComments && commentsBoxRef.current !== e.target)) return;
    const preserveComments = typeof comments === 'string' && !isEmpty(comments);
    if (collectionRowComments) {
      updateComments(preserveComments);
      }
      setIsEditComments(false);
  }

  const handleDropDown = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    if(closeAllDropDowns && index === closeAllDropDowns.index && type === closeAllDropDowns.identifier) {
      setCloseAllDropDowns(null);
      return;
    } 
    if(type === dropdownType.RISK) {
      setCloseAllDropDowns({
        identifier: dropdownType.RISK,
        index: index
      })
    }
    else if (type === dropdownType.OWNER) { 
      setCloseAllDropDowns({
        identifier: dropdownType.OWNER,
        index: index
      })
    }
  }
  const getIconsAccordingToRisk=(riskFlag)=>{
    switch (riskFlag) {
      case "HIGH":
        return 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/info-icon-red.svg'
      case "MEDIUM":
        return "https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/info-icon-yellow.svg";
      case "LOW":
        return "https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/info-icon-gree.svg";
    }
  }

  const getIconAccordingToChangeInRisk = (changeInRiskFlag) => {
    switch (changeInRiskFlag) {
      case "INCREASE":
        return getS3IconLink(FL_ICON_NAMES.RED_UP_ARROW);
      case "DECREASE":
        return getS3IconLink(FL_ICON_NAMES.GREEN_DOWN_ARROW);
      default:
        return getS3IconLink(FL_ICON_NAMES.GREEN_DOWN_ARROW);
    }
  }

  const updateCollectionRow = async (payload, reFetchCollectionData) => {
    const res = await callApi("collections/data-update", "put", { ...payload, updatedBy: loggedInUserEmail });
    if (res?.data?.status === 201) {
      if(reFetchCollectionData) {
        fetchCollectionData();
      }
      showNotification("success", "Data saved successfully", 1500, "top-right");
    } else showNotification("error", "Data update failed", 1500, "top-right");
  };

  // means open nach
  let greyedOutPrec =
    (covertToNum(availableNachAmount) + covertToNum(usedNachAmount))/
    covertToNum(collectionAmount);
  // means scheduled nach
  let lightGreenOutPrec =
    covertToNum(usedNachAmount) /
    covertToNum(collectionAmount);
  let greenOutPrec =
    covertToNum(collectedAmount) / covertToNum(collectionAmount);
  if (greenOutPrec < 0.0175) greenOutPrec = 0;
  if (greyedOutPrec < 0.0175) greyedOutPrec = 0; //@TODO check for better 
  if (lightGreenOutPrec < 0.0175) lightGreenOutPrec = 0; //@TODO check for better 

  const renderer = (
    <>
      <td className="pl-4">
        {moment(collectionDate).utc().format("DD MMMM YYYY")}
      </td>
      <td
        className="text-truncate"
        style={{ maxWidth: "30px", marginLeft: "10px" }}
      >
        <span data-bs-toggle="tooltip" data-bs-html="true" title={companyName}>
          {companyName}
        </span>
      </td>
      <td style={{ width: "26%",position:'relative' }}
        onMouseEnter={()=> {
          if (beta) setShowInvestorWiseBreakdown(true);
        }}
        onMouseLeave={()=> {
          if (beta) setShowInvestorWiseBreakdown(false)
        }} 
      >
      {/*  <td style={{ width: "26%",position:'relative' }} onClick={()=>setShowInvestorWiseBreakdown(!showInvestorWiseBreakdown)} >*/}
        <CollectionsProgressBar
          greyedOutPrec={greyedOutPrec}
          lightGreenOutPrec={lightGreenOutPrec}
          greenOutPrec={greenOutPrec}
          collectionAmount={collectionAmount}
          neftAmount={neftAmount}
          successRate={successRate}
          collectedAmount={collectedAmount}
          investeeOrgId={investeeOrgId}
          isFnnDateCrossed={isFnnDateCrossed}
        />
        {showInvestorWiseBreakdown && needToShowInvestorBifurcation &&  <InvestorBifercationTable investorBreakup={item.investorRepayments} isFnnDateCrossed={item.isFnnDateCrossed}/>}
      </td>
      <td style={{ width: "12%" }}>
          {isFnnDateCrossed ? (<del>₹ {remainingCollectionAmt}</del>) : (`₹ ${remainingCollectionAmt}`)}
       
          <span className={delayChargeText}>
          {delayCharges !== '0' && delayCharges &&
                                             (
                                                <span  className={delayChargeText}>
                                                    {' '}
                                                    incl {`₹ ${delayCharges}`} Charges
                                                </span>
                                            )}
          </span>

       
        </td>
      {/* <td style={{ width: "12%" }}> {isFnnDateCrossed ? (<del>₹ {remainingCollectionAmt}</del>) : (`₹ ${remainingCollectionAmt}`)}</td> */}
      <td style={{ width: "5.5%",position:'relative' }}>
        <div
          className={styles.CollectionDropdown}

        >
          <div style={{display:'flex',alignItems:'center',gap:'0.5rem', marginRight: '0.3rem'}}>
          <div className={styles.OptionSelected}  onClick={(e) => handleDropDown(e, dropdownType.RISK)}>
            <span className="dflex-ac mr-1.5">
              <div
              //  to be calculated 
                className={RISK_CLASS_NAMES[getRiskClassNames(payment_default_risk)]}
              ></div>
              <span>{payment_default_risk}</span>
            </span>
            <img src="../../../../../assets/dropdown-select.svg" alt="" />
          </div>
            {detailedRiskTags && <div onMouseEnter={()=>setShowDetailedRiskTags(true)} onMouseLeave={()=>setShowDetailedRiskTags(false)}>
              <img src={getIconsAccordingToRisk(detailedRiskTags?.riskFlag)}
                   alt=""/>
            </div>}
            {changeInRiskFlag && 
              <img src={getIconAccordingToChangeInRisk(changeInRiskFlag)}
                alt=""/>
            }
          </div>
          {closeAllDropDowns?.index === index && closeAllDropDowns?.identifier === dropdownType.RISK ? (
            <div className={styles.Options}>
              {Object.values(riskTags).map((item, i) => (
                <span
                  className="dflex-js"
                  onClick={() => setRiskLevel(item)}
                  style={{ cursor: "pointer" }}
                >
                  <div
                    className={RISK_CLASS_NAMES[getRiskClassNames(item)]}
                    key={i}
                  ></div>
                  {item}
                </span>
              ))}
            </div>
          ) : null}
        </div>
        {showDetailedRiskTags && <RiskTagsTooltip detailedRiskTags={detailedRiskTags}/>}
      </td>

      <td style={{ width: "9.5%"}}>
        <div className={UseNach} onClick={isCollectionDateLessThanThisMonth || !isInCurrentMonth ? null : toggleUseNach} style={isCollectionDateLessThanThisMonth || !isInCurrentMonth?{cursor: 'default'}:{}}>
          <span className={useNachSelected ? "" : NoActive}>{(useNachToggleKeys.NO).toUpperCase()}</span>
          <span className={useNachSelected ? YesActive : ""}>{(useNachToggleKeys.YES).toUpperCase()}</span>
        </div>
      </td>
      <td>
        <div
          className={styles.CollectionDropdownNach}
          onClick={(e) => handleDropDown(e, dropdownType.OWNER)}
        >
          <div className={styles.OptionSelectedNach}>
            <span className="mr-2">{owner}</span>
            <img src="../../../../../assets/dropdown-select.svg" alt="" />
          </div>
          {closeAllDropDowns?.index === index && closeAllDropDowns?.identifier === dropdownType.OWNER ? (
            <div className={styles.OptionsNach}>
              {growthPartners.map((item) => (
                <span
                  onClick={() => setOwnerName(item?.agent_name)}
                  style={{ cursor: "pointer" }}
                >
                  {item?.agent_name}
                </span>
              ))}
            </div>
          ) : null}
        </div>
      </td>
      <td className={CollectionComments} style={{ width: "15%" }}>
        {isEditComments ? (
          <textarea
            autoFocus={isEditComments}
            value={collectionRowComments}
            onChange={(e) => setCollectionRowComments(e.target.value)}
            style={{ width: "139px", height: "61px"}}
            ref={commentsBoxRef}
          />
        ) : (
          <div
            onDoubleClick={() => setIsEditComments(true)}
            className={commentsBox}
          >
            {Array.isArray(comments) ? (
              comments?.map((comment, index) => (
                <div className="mb-2" key={index}>
                  <div style={{ color: "#C5C5C5", fontSize: "8px" }}>
                    <span>
                      {dayjs(comment?.date).format("h:mm A")},&nbsp;
                      {dayjs(comment?.date).format("DD MMM YY")}
                    </span>
                    <span>&nbsp;by {comment?.owner}</span>
                  </div>
                  <div>{comment?.data}</div>
                </div>
              ))
            ) : (
              <div>{comments}</div>
            )}
          </div>
        )}
      </td>
    </>
  );
  return (
    <tr
      id={_id}
      className="ht71px"
      style={isCollectionDateLessThanThisMonth?{background:'#E4E4E4'}:{}}
      // style={item.execution_status !== "No Nach" ? {} : { color: "#b20600", opacity: '0.7' }}
    >
      {renderer}
      <CancelNachModal 
        showCancelNachModal={showCancelNachModal}
        setShowCancelNachModal={setShowCancelNachModal}
        updateComments={updateComments}
        nachComments={nachComments}
        setNachComments={setNachComments}
        useNachSelected={useNachSelected}
        setUseNachSelected={setUseNachSelected}
        modifyApiPayload={modifyApiPayload}
        useNach={useNach}
      />
    </tr>
  );
};