import React, { useState, useEffect } from "react";
import styled from "styled-components";
import styles from "../../metrics.module.css";
import callApi from "../../../../../../../../../../../util/apiCaller";
const Button = styled("button")`
  border-radius: 4px;
  background-color: ${(props) =>
    props.color === "blue" ? "#4c8dff" : "#ffffff"};
  color: ${(props) => (props.color === "blue" ? "#ffffff" : "#4c8dff")};
  font-size: 12px;
  font-weight: 500;
  padding: 6px 17px;
  border: ${(props) => (props.color === "blue" ? "none" : "1px solid #4c8dff")};
  cursor: pointer;
  max-height: 30px;
  img {
    width: 12px;
    margin-right: 6px;
  }
  &:disabled {
    background-color: #c7c7c7;
    color: #ffffff;
    cursor: not-allowed;
  }
`;
const DateInput = styled("input")`
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  outline: none;
  opacity: 0.44;
  color: #000000;
  font-size: 12px;
  padding: 5px 0px;
  &:hover,
  &:focus {
    border: 1px solid #4c8dff !important;
    background-color: #f4f8ff !important;
  }
`;
const months = [
  {
    label: "January",
    value: "01",
  },
  {
    label: "February",
    value: "02",
  },
  {
    label: "March",
    value: "03",
  },
  {
    label: "April",
    value: "04",
  },
  {
    label: "May",
    value: "05",
  },
  {
    label: "June",
    value: "06",
  },
  {
    label: "July",
    value: "07",
  },
  {
    label: "August",
    value: "08",
  },
  {
    label: "September",
    value: "09",
  },
  {
    label: "October",
    value: "10",
  },
  {
    label: "November",
    value: "11",
  },
  {
    label: "December",
    value: "12",
  },
];
const FromToDate = ({
  investeeOrganization,
  setReload,
  setFirstRender,
  setMakeActiveClicked,
  setEditSave,
}) => {
  const curr_year = new Date().getFullYear();
  const curr_month = new Date().getMonth() + 1;
  let curr_date = 0;
  curr_month < 10
    ? (curr_date = `${curr_year}-0${curr_month}`)
    : (curr_date = `${curr_year}-${curr_month}`);

  const [metricsDate, setMetricsDate] = useState(curr_date);

  const [formMonth, setformMonth] = useState("01");
  const [formYear, setformYear] = useState(curr_year);

  const [pivotData, setPivotData] = useState(true);
  const [spreadData, setSpreadData] = useState(false);

  const PIVOT_TYPE = {
    PIVOT: "pivot",
    SPREAD: "spread",
  };

  const calculateMetrics = (investeeOrganizationId, date) => {
    let new_month = date.slice(5, 7);
    let new_year = date.slice(0, 4);
    let new_day = new Date(new_year, new_month, 0).getDate();
    let new_date = `${date}-${new_day}`;
    const test = async () => {
      const type = pivotData ? PIVOT_TYPE.PIVOT : PIVOT_TYPE.SPREAD;
      const resp = await callApi(
        `metrics/calculate-metrics?investeeOrgId=${investeeOrganization._id}&date=${new_date}&calculationType=${type}`,
        "get",
        undefined,
        undefined,
        undefined,
        true
      );
      setFirstRender(false);
      setEditSave(false);
      setMakeActiveClicked(false);
      setReload(true);
    };
    test();
  };

  useEffect(() => {
    const nativePicker = document.querySelector(".nativeDatePicker");
    const fallbackPicker = document.querySelector(".fallbackDatePicker");
    const fallbackLabel = document.querySelector(".fallbackLabel");
    const yearSelect = document.querySelector("#year");
    // hide fallback initially
    fallbackPicker.style.display = "none";
    fallbackLabel.style.display = "none";
    // test whether a new date input falls back to a text input or not
    const test = document.createElement("input");
    try {
      test.type = "month";
    } catch (e) {
      console.log(e.description);
    }
    // if it does, run the code inside the if() {} block
    if (test.type === "text") {
      // hide the native picker and show the fallback
      nativePicker.style.display = "none";
      fallbackPicker.style.display = "block";
      fallbackLabel.style.display = "block";
      // populate the years dynamically
      // (the months are always the same, therefore hardcoded)
      populateYears();
    }
    function populateYears() {
      // get the current year as a number
      const date = new Date();
      const year = date.getFullYear();
      // Make this year, and the 100 years before it available in the year <select>
      for (let i = 0; i <= 100; i++) {
        const option = document.createElement("option");
        option.textContent = year - i;
        yearSelect.appendChild(option);
      }
    }
  });
  return (
    <div className={styles.from_to_date_box}>
      <div className={styles.metricsDateRow}>
        <form onSubmit={(e) => e.preventDefault()}>
          <div class="nativeDatePicker">
            <DateInput
              className="mr-3"
              type="month"
              style={{ width: "160px" }}
              id="metrics-date"
              name="metrics-date"
              onChange={(e) => setMetricsDate(e.target.value)}
              value={metricsDate}
            />
            <Button
              color="blue"
              disabled={metricsDate == ""}
              onClick={() =>
                calculateMetrics(investeeOrganization._id, metricsDate)
              }
            >
              <img src="/assets/moderate-btn-icon.svg" alt="Calculate" />
              Calculate New
            </Button>
            <span class="validity"></span>
          </div>
          <p class="fallbackLabel"></p>
          <div class="fallbackDatePicker">
            <div>
              <span>
                {/* <label for="month">Month &nbsp;</label> */}
                <select
                  id="month"
                  name="month"
                  className="mr-3 "
                  onChange={(e) => {
                    setformMonth(e.target.value);
                  }}
                  style={{
                    borderRadius: "5px",
                    fontWeight: "350",
                    width: "80px",
                    fontSize: "13px",
                  }}
                >
                  {months.map((m) => {
                    return (
                      <option value={m.value} key={m.value}>
                        {m.label}
                      </option>
                    );
                  })}
                </select>
              </span>
              <span>
                <select
                  id="year"
                  name="year"
                  className="mr-3"
                  onChange={(e) => setformYear(e.target.value)}
                  style={{
                    borderRadius: "5px",
                    fontWeight: "350",
                    width: "60px",
                    fontSize: "13px",
                  }}
                ></select>
              </span>
              <Button
                color="blue"
                onClick={() => {
                  calculateMetrics(
                    investeeOrganization._id,
                    `${formYear}-${formMonth}`
                  );
                }}
              >
                <img src="/assets/moderate-btn-icon.svg" alt="Calculate" />
                Calculate Metrics
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FromToDate;
