import dayjs from "dayjs";
import UTC from "dayjs/plugin/utc";
import isToday from "dayjs/plugin/isToday";
import { Drawer } from "@material-ui/core";
import styles from './ViewDocsDrawer.module.scss';
import UWQueries from "./UWQueries/UWQueries";
import RecurTabs from "../../../../../../../../../../../../components/RecurTabs/RecurTabs";
import { queryTabs, RECUR_TABS_COLORS } from "../../../../../../../../../../../../constants/enums";
import { useEffect, useState } from "react";
import { LAST_ACTIVITY_STATUS } from "../../../../../../../../../../../../common/ENUM";

dayjs.extend(isToday);
dayjs.extend(UTC);

export default function ViewDocsDrawer({
    show,
    onClose,
    deal,
    currentTab,
    setCurrentTab
}) {
    const {DOCUMENTS,DEAL_TERMS,KYC_DOCS,UW_QUERIES,KYC_QUERIES} = queryTabs;
    const tabs = [DOCUMENTS,DEAL_TERMS,KYC_DOCS,UW_QUERIES,KYC_QUERIES];

    useEffect(() => {
        if (currentTab === null && deal !== null) {
          if (deal?.lastActivityStatus === LAST_ACTIVITY_STATUS.kyc_query_recieved) {
            setCurrentTab(KYC_QUERIES);
          } else {
            setCurrentTab(UW_QUERIES);
          }
        }
    },[deal])

    const {
        Heading,
    } = styles;

    const { investorName } = deal ?? {
        investorName: ''
    };

    const renderCardContent = () => {
        return (
            <UWQueries 
                deal={deal} 
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}/>
        );
    }

    return (
        <Drawer
            anchor="right"
            open={show}
            onClose={() => {
                onClose()
            }}
            transitionDuration={200}
            PaperProps={{
                style: {
                    width: '60%',
                    height: "100%",
                    padding: "34px"
                },
            }}
            disableEnforceFocus
        >
            <div className="border-0 d-flex justify-content-between align-items-center">
                <div className={Heading}>{investorName} Documents</div>
                <img
                    src="../../../../../assets/modal-cross.svg"
                    alt="Close Modal"
                    onClick={() => onClose()}
                    className="cp"
                />
            </div>
            <div className="py-4">
                <div className="dflex">
                <RecurTabs
                    tabs={tabs}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    color={RECUR_TABS_COLORS.BLUE}
                    style={{fontSize: '14px',lineHeight: '14px',FontFamily: 'Gilroy-SemiBold'}}
                />
                </div>
            </div>
            {renderCardContent()}
        </Drawer>
    );
}
