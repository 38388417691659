import React from "react";

import styles from "./DetailsInput.module.scss";
const { detailsContainer } = styles;

export default function DetailsInput({ disabled = false, comment = "", handleChange }) {
  return (
    <div className={detailsContainer}>
      <div className="fs12 fw500 w-50">Details:</div>
      <input
        className="w-100"
        placeholder=""
        required
        disabled={disabled}
        onChange={(e) => handleChange(e.target.value)}
        value={comment}
      />
    </div>
  );
}
