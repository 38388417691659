import moment from 'moment';
import React from 'react';


function WithdrawalTableRow({item,approveRejectRequest,isApproving,idApproving}) {
    
    const  espOrMsaNotPresent = (!item?.investee_organization_id?.escrow_pay_agreement || !item?.investee_organization_id?.msa_agreement)
    return (
        <tr className="ht71px">
            
            <td style={{ width: '11%' }}>{item.withdraw_request_by === "investee" ? (espOrMsaNotPresent ?
                <i class="fa fa-thumbs-down" aria-hidden="true"></i> :
                <i class="fa fa-thumbs-up" aria-hidden="true"></i>) : 
                <i class="fa fa-window-minimize" aria-hidden="true"></i>

            }
                {" "}{moment(item.created_at).format("DD MMM YYYY")}</td>
            <td style={{width:'13%', maxWidth:'100px'}} class="text-truncate">
                <span data-bs-toggle="tooltip" data-bs-html="true" 
                title={
                    item.investee_organization_id ? item.investee_organization_id.name:item.investor_organization_id?.name
                    }>
                    {
                item.investee_organization_id ? item.investee_organization_id.name: item.investor_organization_id?.name
                }
                </span>
            </td>
            <td style={{width:'10%'}}>{item.withdraw_request_by}</td>
            <td style={{width:'13%'}} 
                className={`${item.approval_status.split(' ').join('-')}-status`}>
                <b>{item.approval_status}</b>
            </td>
            <td style={{width:'10%'}}>{item?.organization_bank_id?.account_holder_type}</td>
            <td style={{width:'13%', wordBreak:'break-all'}}>{item?.organization_bank_id?.bank_name ? `${item?.organization_bank_id?.bank_name}-${item?.organization_bank_id?.account_number}` : "-"}</td>
            <td style={{width:'10%', wordBreak:'break-all'}}>₹{item.amount}</td>
            <td style={{width:'10%'}}>{item?.submitted_by?.name ? item.submitted_by.name : "-"}</td>

            <td style={{width:'5%'}}>
                {item.approval_status==='Open'?
                <button disabled={isApproving} onClick={()=> approveRejectRequest(item._id,'Approved')} 
                style={{width:"30px", border:'none', backgroundColor:'white'}}>{(idApproving === item._id) && isApproving ? '...': '✔️'}</button>
                :null
            }
            </td>
            <td style={{width:'5%'}}>{item.approval_status==='Open'?
                <button disabled={isApproving} style={{width:"30px", border:'none', backgroundColor:'white'}} 
                onClick={()=> approveRejectRequest(item._id,'Rejected')}>❌</button>
                :null}
            </td>
        </tr>
    )
}

export default WithdrawalTableRow;
