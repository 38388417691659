import React, { useCallback, useRef } from "react";
// import {Link} from 'react-router-dom';
import moment from "moment";
import { displayTermUnit } from "../../../../../util/calculateTermUtil";
import _ from "lodash";
function AutoinvesTableRow({ item, loader, hasMore, loadMore, selected_trades_ids, onChangeTradeCheckbox, dataLength, index }) {
  const observer = useRef();
  const sortOrder = ["monthly", "quarterly", "semi-annually", "annually"];
  const recur_rating_options = ["Unrated", "C", "CC", "CCC", "B", "BB", "BBB", "A", "AA", "AAA"];

  const lastTradeRef = useCallback(
    (node) => {
      if (loader) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore()) loadMore();
      });
      if (node) observer.current.observe(node);
    },
    [loader, hasMore()]
  );
  const renderer = (
    <>
      {/* <td>
        <input
          type='checkbox'
          value={item._id}
          style={{ marginLeft: "5px", marginRight: "10px" }}
          checked={selected_trades_ids.some((i) => i === item._id)}
          disabled={
            item.trade_approval_status !== "Open" &&
            item.trade_approval_status !== "On Hold"
          }
          onChange={(e) => {
            onChangeTradeCheckbox(e);
          }}></input>
      </td> */}
      <td style={{width:'10%'}} className="pl-2">
        {item.investor_organization_name}
      </td>
      <td className='blue-table-cel' style={{width:'10%'}}>
        <span className="tooltips">
          <span>{item.name}</span>
          <span class="tooltiptexts after1" style={{ inset: 'auto', marginLeft: '-100px', marginTop: '20px' }}>
            <div>
              <div className="bb fw500 fs12">Company Filters</div>
              <div className="bb dflex">
                <span className="color1">Recur Grade</span>
                <span>{!item.recur_rating ? "Unrated" : recur_rating_options[item.recur_rating]}</span>
              </div>
              <div className="bb dflex">
                <span className="color1">ARR</span>
                <span>₹ {item?.min_arr?.toFixedNoRounding(2)} Cr+</span>
              </div>
              <div className="bb dflex">
                <span className="color1">ARR Growth</span>
                <span>{!item.min_arr_growth ? 'Any': item.min_arr_growth +' %+'}</span>
              </div>
              <div className="bb dflex">
                <span className="color1">Age</span>
                <span>{!item.min_age ? 'Any' : item.min_age +' months +'} </span>
              </div>
              <div className="bb dflex">
                <span className="color1">Funding</span>
                <span className="w-50">{!_.isEmpty(item.funding) && item.funding.length < 8 ? item.funding.join(','):'Any'}</span>
              </div>
              <div className="bb dflex">
                <span className="color1">Runway</span>
                <span>{item.min_runway ? item.min_runway + ' months +' : 'Any'}</span>
              </div>
              <div className="bb dflex">
                <span className="color1">LTV/CAC</span>
                <span>{item.min_ltv_cac ? item.min_ltv_cac : 'Any'}</span>
              </div>
              <div className="bb dflex">
                <span className="color1">Revenue Churn</span>
                <span>{'<'}{item.max_revenue_churn} %</span>
              </div>
              <div className="bb dflex">
                <span className="color1">Sectors</span>
                <span className="w-50">{item?.desired_company_sectors?.join(',')}</span>
              </div>
              <div className="pt-1 dflex">
                <span className="color1">Companies</span>
                <span>{!_.isEmpty(item.eligible_companies) ? item.eligible_companies.map(i => i.label).join(','): 'Any'}</span>
              </div>
            </div>
          </span>
        </span>
      </td>
      <td style={{width:'10%'}}>₹{0}</td>
      <td style={{width:'10%'}} className="pl-1">{item.target_irr}%</td>
      <td style={{width:'10%'}}>
        <i class='fas fa-rupee-sign'></i> {item.max_investment}
      </td>
      <td style={{width:'8%'}}>{item.max_concentration}%</td>
      <td style={{width:'13%'}}><i class='fas fa-rupee-sign'></i> {item?.repayment_types?.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b)).map((item, index) => `${index === 0 ? '' : ' / '}${displayTermUnit(item)}`)}</td>
      <td style={{width:'8%'}}>
        {item.invested}
      </td>
      <td style={{width:'14%'}}>{item.autoinvest_id}</td>
      <td className='bold-table-cel darkblue-table-cel' style={{width:'7%'}}>
        {item.is_active ? 'Active':"Inactive"}
      </td>
    </>
  );

  // if (dataLength === index + 1) {
  //   return <tr ref={lastTradeRef}>{renderer}</tr>;
  // } else {
    return <tr>{renderer}</tr>;
  // }
}

export default AutoinvesTableRow;
