import styles from "./compliance.module.scss";
import ctaArrow from "../../../../../../assets/chevron-right.svg";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

const ComplianceRow = ({data,index}) => {
    const {investeeOrganizationId, profileImage, companyName, startDate, lastUpdate, lastSpoc, nachSummary, totalRepaymentAmount, repaymentRemaining, totalRepayments, overallSummary}=data;
    const history = useHistory();

    const metPlusPartialValue = (overallSummary?.met ?? 0) + (overallSummary?.metUnverified ?? 0) + (overallSummary?.partially ?? 0);
    const totalValue = (overallSummary?.met ?? 0) + (overallSummary?.metUnverified ?? 0) + (overallSummary?.partially ?? 0) + (overallSummary?.notMet ?? 0);
    const metPlusPartialPerc = () => {
        const perc = (metPlusPartialValue/totalValue)*100;
        return {
            width: `${perc}%`
        }
    }

    const metPerc = () => {
        const perc = (((overallSummary.met ?? 0) + (overallSummary.metUnverified ?? 0))/metPlusPartialValue)*100;
        return {
            width: `${perc}%`
        }
    }

    const handleNavigate=()=>{
        history.push({
          pathname: `/admin/company/${investeeOrganizationId}/view/compliance-checks`,
        });
    }

    const {complianceRow, logo, truncate, status, statusMet,statusNotMet,statusPartially,progressBar,metPlusPartial,met,redColor}=styles;
  return (
    <tr 
    key={index}
    className={complianceRow}
    onClick={handleNavigate}>
        <td style={{ width: "80px" }}>
            <div className={logo}>
                <img
                    className="mr-2 col-lg-3"
                    src={profileImage ?? "/assets/company-logo.svg"}
                />
            </div>
        </td>
        <td><div className={truncate}>{companyName}</div></td>
        <td>{lastSpoc ?? "-"}</td>
        <td>{totalValue?
            <>
            <div className={status}>
                <span  className={statusMet}>{overallSummary.met + (overallSummary.metUnverified ?? 0)} Met{overallSummary.metUnverified ? ` (${overallSummary.metUnverified} Unverified)` : ''}</span>
                <span  className={statusPartially}>{overallSummary.partially} Partially</span>
                <span  className={statusNotMet}>{overallSummary.notMet} Not Met</span>
            </div>
            <div className={progressBar}>
            <div className={metPlusPartial} style={metPlusPartialPerc()}>
                <div className={met} style={metPerc()}></div>
            </div>
            </div>
            </>:"-"}
      </td>
      <td className={nachSummary !== 100 ? redColor : ''}>{nachSummary}%</td>
      <td>₹ {totalRepaymentAmount} ({repaymentRemaining}/{totalRepayments})</td>
      <td>{dayjs(startDate).format('DD MMM YYYY')}</td>
      <td>{lastUpdate}</td>
      <td><img src={ctaArrow}/></td>
    </tr>
  );
};
export default ComplianceRow;
