import React, { useEffect, useState } from "react";
import callApi from "../../../../../../../../../../../util/apiCaller";
import styles from "../../metrics.module.css";

const HistoryBox = ({
  isReadonly,
  setIsReadonly,
  investeeOrganization,
  res,
  setRes,
  id,
  setId,
  Reload,
  setReload,
  firstRender,
  disableUpdate,
  setDisableUpdate,
  setNotActiveTabClicked,
  formatDateSlice,
  createdAtTime,
  jsDate,
  setLastUpdate,
  setActiveType,
  setActiveClicked,
  makeActiveClicked,
  setFromDate,
  setToDate,
  editSave,
  set_disable_date,
  setReRenderId,
  setComputeType,
}) => {
  const [colorChange, setColorChange] = useState(null);
  const [update, set_update] = useState(null);
  const [Active, setActive] = useState(true);
  const toggle = (i) => {
    setColorChange(i);
  };
  const updating = (i) => {
    set_update(i);
  };

  useEffect(() => {
    const test = async () => {
      const resp = await callApi(
        `metrics?investeeOrgId=${investeeOrganization._id}`,
        "get",
        undefined,
        undefined,
        undefined,
        true
      );
      setRes(resp.data.metricsData);
      if (Reload && !makeActiveClicked && !editSave) {
        setNotActiveTabClicked(true);
        setColorChange(resp.data.metricsData[0].id);
        setActive(false);
        set_update(resp.data.metricsData[0].id);
        setFromDate(resp.data.metricsData[0].fromDate.slice(0, 10));
        setToDate(resp.data.metricsData[0].toDate.slice(0, 10));
        if (
          resp.data.metricsData[0].isActive &&
          resp.data.metricsData[0].active_type
        ) {
          setActiveType(resp.data.metricsData[0].active_type);
        } else setActiveType();
      }
      if (Reload && !makeActiveClicked && editSave) {
        setNotActiveTabClicked(true);
        setActive(false);
        setReRenderId(true);
      }
      if (makeActiveClicked) {
        for (let obj of resp.data.metricsData) {
          if (obj.isActive && obj.active_type) {
            setActiveType(obj.active_type);
          }
        }
      }
      let temp_id = "";
      for (let obj of resp.data.metricsData) {
        if (obj.isActive && firstRender) {
          temp_id = obj.id;
          setId(obj.id);
          setLastUpdate(obj.updatedAt);
          setActiveType(obj.active_type);
          setNotActiveTabClicked(false);
          setFromDate(obj.fromDate.slice(0, 10));
          setToDate(obj.toDate.slice(0, 10));
          setComputeType(obj.computeType);
        }
      }
      if (id == null && temp_id == "") {
        if (resp.data.metricsData != "") {
          setId(resp.data.metricsData[0].id);
          setFromDate(resp.data.metricsData[0].fromDate.slice(0, 10));
          setToDate(resp.data.metricsData[0].toDate.slice(0, 10));
          setLastUpdate(resp.data.metricsData[0].updatedAt);
          setActive(false);
          setColorChange(resp.data.metricsData[0].id);
          setNotActiveTabClicked(true);
          setComputeType(resp?.data?.metricsData[0].computeType);
        }
      }
      if (!firstRender && !makeActiveClicked && !editSave) {
        setId(resp.data.metricsData[0].id);
        setFromDate(resp.data.metricsData[0].fromDate.slice(0, 10));
        setToDate(resp.data.metricsData[0].toDate.slice(0, 10));
        setComputeType(resp?.data?.metricsData[0].computeType);
      }

      setReload(false);
    };
    test();
  }, [Reload]);

  return (
    <section className={styles.history_box}>
      <div className={styles.hist_row_one}>
        <div className={styles.history}>History</div>
        <div className={styles.update_button}>
          <button
            className={
              styles.update_btn +
              " " +
              (!isReadonly || disableUpdate ? styles.disable_update_btn : "")
            }
            disabled={!isReadonly || disableUpdate}
            onClick={() => {
              setIsReadonly(false);
              setNotActiveTabClicked(false);
              set_disable_date(false);
            }}
          >
            <img
              src="/assets/create.png"
              alt=""
              className={styles.pencilImage}
            />
            Update
          </button>
        </div>
      </div>
      {res&&(res.filter((d) => d.computeType !== "cem").length > 0) && (
        <div
          style={{
            marginLeft: "38px",
            marginBottom: "5px",
            marginTop: "5px",
            fontSize: "10px",
          }}
        >
          Product Calculated
        </div>
      )}
      <div className={styles.list}>
        {res
          .filter((d) => d.computeType !== "cem")
          .map((d) => {
            return (
              <div
                className={
                  styles.list_box +
                  " " +
                  (d.isActive && Active ? styles.list_box_back_change : "") +
                  " " +
                  (colorChange === d.id && !Active
                    ? styles.list_box_back_change
                    : "")
                }
                key={d.id}
                onClick={() => {
                  setId(d.id);
                  toggle(d.id);
                  setActive(false);
                  updating(d.id);
                  setIsReadonly(true);
                  setDisableUpdate(false);
                  setLastUpdate(d.updatedAt);
                  setFromDate(d.fromDate.slice(0, 10));
                  setToDate(d.toDate.slice(0, 10));
                  setComputeType(d.computeType);
                  if (!d.isActive) {
                    setNotActiveTabClicked(true);
                    setActiveClicked(false);
                  }
                  if (d.isActive) {
                    setNotActiveTabClicked(false);
                    setActiveClicked(true);
                  }
                  if (d.active_type && d.isActive) setActiveType(d.active_type);
                  else setActiveType();
                }}
              >
                <div className={styles.from_to_div}>
                  <div className={styles.created_time}>
                    {d.createdAt && createdAtTime(d.createdAt)},{" "}
                    {d.createdAt && jsDate(formatDateSlice(d.createdAt))}
                  </div>
                  {d.isActive && d.isActive ? (
                    <div className={styles.activee}>Active</div>
                  ) : isReadonly ? (
                    <div className={styles.inactive}></div>
                  ) : update === d.id ? (
                    <div className={styles.updating}>Updating</div>
                  ) : (
                    <div className={styles.inactive}></div>
                  )}
                  <div className={styles.from_date_hist}>
                    {d.fromDate && formatDateSlice(d.fromDate)}
                  </div>
                  <div className={styles.dash}>-</div>
                  <div className={styles.to_date_hist}>
                    {d.toDate && formatDateSlice(d.toDate)}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {res && res.filter((d) => d.computeType === "cem").length > 0 && (
        <div
          style={{
            marginLeft: "38px",
            marginBottom: "5px",
            marginTop: "30px",
            fontSize: "10px",
          }}
        >
          Synced with CEM
        </div>
      )}
      <div className={styles.list}>
        {res
          .filter((d) => d.computeType === "cem")
          .map((d) => {
            return (
              <div
                className={
                  styles.list_box +
                  " " +
                  (d.isActive && Active ? styles.list_box_back_change : "") +
                  " " +
                  (colorChange === d.id && !Active
                    ? styles.list_box_back_change
                    : "")
                }
                key={d.id}
                onClick={() => {
                  setId(d.id);
                  toggle(d.id);
                  setActive(false);
                  updating(d.id);
                  setIsReadonly(true);
                  setDisableUpdate(false);
                  setLastUpdate(d.updatedAt);
                  setFromDate(d.fromDate.slice(0, 10));
                  setToDate(d.toDate.slice(0, 10));
                  setComputeType(d.computeType);
                  if (!d.isActive) {
                    setNotActiveTabClicked(true);
                    setActiveClicked(false);
                  }
                  if (d.isActive) {
                    setNotActiveTabClicked(false);
                    setActiveClicked(true);
                  }
                  if (d.active_type && d.isActive) setActiveType(d.active_type);
                  else setActiveType();
                }}
              >
                <div className={styles.from_to_div}>
                  <div className={styles.created_time}>
                    {d.createdAt && createdAtTime(d.createdAt)},{" "}
                    {d.createdAt && jsDate(formatDateSlice(d.createdAt))}
                  </div>
                  {d.isActive && d.isActive ? (
                    <div className={styles.activee}>Active</div>
                  ) : isReadonly ? (
                    <div className={styles.inactive}></div>
                  ) : update === d.id ? (
                    <div className={styles.updating}>Updating</div>
                  ) : (
                    <div className={styles.inactive}></div>
                  )}
                  <div className={styles.from_date_hist}>
                    {d.fromDate && formatDateSlice(d.fromDate)}
                  </div>
                  <div className={styles.dash}>-</div>
                  <div className={styles.to_date_hist}>
                    {d.toDate && formatDateSlice(d.toDate)}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </section>
  );
};
export default HistoryBox;
