import React, { ReactElement, useEffect, useRef, useState } from "react";
import { showNotification } from "../../util/utility";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { getInvesteesOrganization } from "../../modules/Admin/AdminReducers";
import { getEmail, getUserName } from "../../modules/App/AppReducer";
import callApi from "../../util/apiCaller";
import {
  DOC_SERVICE_RESPONSE,
} from "../../constants/enums";
import AlertModal from "../../modules/Admin/pages/CompaniesViewPage/components/CompaniesDetailPage/components/Overview/components/DealsOverview/components/AlertModal";
import {
  getPrepareTermsTotDetails,
  reInitiateProductTot,
} from "../../common/ApiContainer";
import PageLoader from "../loader/PageLoader";

const FILE_SIZE = 11000000;

const FileUpload = ({
  validateBeforeUpload,
  docTypeName = "",
  categoryId = "",
  s3FolderName,
  onSuccess,
  flowId = "",
  docTypeId = "",
  disabled,
  onChange,
  handleDownloadEl,
  stageTotId,
  totData,
  originalTotData,
  selectedEngagementLetter,
  setSelectedEngagementLetter,
  investeeOnboardedId,
  setIsDisabledDropDown,
  totAccepted,
  totAcceptedValue,
  setTotAccepted,
  isAddOn,
  addOnId,
}) => {
  const inputFileRef = useRef();
  const callingApi = useRef(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const userName = useSelector(getUserName);
  const email = useSelector(getEmail);
  const investeeOrgData = useSelector(getInvesteesOrganization);
  const investeeOnboardingId = investeeOrgData?.investee_onboarded_id;
  const investeeOrgName = investeeOrgData?.name;
  const isCompanyActive = investeeOrgData?.status === "Active";

  useEffect(() => {
    if (callingApi.current) handleSubmission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles]);

  useEffect(() => {
    if (totData?.DebtSyndication?.signedEngagementLetter !== "" && totData?.DebtSyndication?.elUploadedType !== "Standard EL") {
      setIsDisabledDropDown(true);
    }
  }, [selectedFiles]);

  useEffect(() => {
    const signedEngagementLetter =
      originalTotData?.DebtSyndication?.signedEngagementLetter;
    if (signedEngagementLetter) {
      const ElUploadedFile = originalTotData?.DebtSyndication?.elUploadedType;
      if (ElUploadedFile === "Negotiated EL") {
        const fileName = { name: `Negotiated_EL_${signedEngagementLetter}` };
        setSelectedFiles([fileName]);
      } else if (ElUploadedFile === "Signed EL") {
        const fileName = { name: `Signed_EL_${signedEngagementLetter}` };
        setSelectedFiles([fileName]);
      }
      else if (totAccepted) {
        const fileName = { name: `Signed_EL_${signedEngagementLetter}` };
        setSelectedFiles([fileName]);
      }
    }
  }, [originalTotData]);

  const uploadNewFile = (e) => {
    inputFileRef?.current?.click();
  };

  const handleClickToDownload = () => {
    handleDownloadEl();
  };

  const handleFilesValidation = (files) => {
    let message = "";
    let isValid = true;
    let sizeFailure = false;
    files &&
      files.forEach((file) => {
        if (file?.size > FILE_SIZE) sizeFailure = true;
      });
    if (files && files.length > 1) {
      isValid = false;
      message = "You cannot upload more than one files at a time";
    } else if (sizeFailure)
      message = "You cannot upload files greater than 10MB";
    return {
      isValid,
      message,
      sizeFailure,
    };
  };

  const getFileNameFromSignedUrl = (preSignedUrl) => {
    return decodeURIComponent(preSignedUrl)
      ?.split(s3FolderName + "/")[1]
      .split("?")[0];
  };

  const actionOnAlert = async () => {
    setLoading(true);
    setSelectedFiles([]);
    onChange("");
    setAlertMessage(false);
    try {
      if(totData?.signedEngagementLetter !== "") {
        await reInitiateProductTot(stageTotId); 
      }
      const data = isAddOn ? 
      { investee_organization_id: investeeOrgData?._id, add_on_limit_id: addOnId } : 
      { investee_organization_id: investeeOrgData?._id, onboarding_id: investeeOnboardedId };
      const res = await getPrepareTermsTotDetails (
        data,
      );
      const elUploadedType = res?.data?.data?.DebtSyndication?.elUploadedType;
      setSelectedEngagementLetter(elUploadedType);
      setIsDisabledDropDown(false);
      setTotAccepted(false);
    } finally {
      setLoading(false); 
      setIsDisabledDropDown(false);
      setTotAccepted(false);
    }
  };
  /*   const deleteFile = async() => {
        let obj = {
            input: {},
        };
        const keyName = DOC_KEY_MAPPING[docTypeName?.split("(")[0]];

            obj.input[keyName] = {};
            if (beneficialOwnerId) obj.input[keyName].user_id = beneficialOwnerId;
        const res = await callApi(`investee-kyc-application/${investeeOnboardingId}/delete-file`, "post", obj);
        if (res?.status === API_STATUS.SUCCESS) {
            if (onDeleteSuccess) onDeleteSuccess();
        }
        else {
            const message = res?.data?.message ?? "Some error occurred";
            showNotification("Error", message);
        }
        setShowConfirmDeletePopUp(false);
    }
    
} */

  const uploadDocument = async ({ file }) => {
    //let purpose = "single_upload";
    try {
      const data = { url: "" };
      const orgName = investeeOrgName.split(" ");
      let investeeOrgNameToSend = orgName[0] + " " + (orgName[1] ?? "");
      if (!categoryId) return;
      const signResponse = await callApi(
        "file/signedUrl",
        "post",
        {
          categoryId,
          fileName: file.name,
          docTypeName: docTypeName?.split("(")[0],
          investeeOrgName: investeeOrgNameToSend,
        },
        {},
        false,
        false,
        false,
        false,
        false,
        true
      );
      data.url = signResponse.signedUrl;
      const requestOptions = {
        method: "put",
        body: file,
        headers: new Headers({
          "Content-Type": file.type,
        }),
      };
      if (data.url) {
        const uploadResponse = await fetch(data.url, requestOptions);
        if (uploadResponse.ok) {
          const data_to_post = {
            categoryId,
            fileName: getFileNameFromSignedUrl(data.url),
            fileSizeKb: file.size / 1_000,
            fileType: file.type,
            uploadedBy: userName,
            flowId,
            docTypeId,
            emailAddress: email,
          };
          const updateResponse = await callApi(
            "file/saveMetadata",
            "post",
            data_to_post,
            {},
            false,
            false,
            false,
            false,
            false,
            true
          );
          if (
            updateResponse?.responseData?.responseCode ===
            DOC_SERVICE_RESPONSE.SUCCESS
          ) {
            const fileMetadata = updateResponse?.fileMetadata;
            if (onSuccess) onSuccess(fileMetadata);
            return;
          } else throw new Error("Couldn't update metaData!");
        } else throw new Error(uploadResponse);
      } else throw new Error("Couldn't receive signed url!");
    } catch (error) {
      console.error("UploadDoc: ", error);
      showNotification("Error", "Failed to upload, something went wrong");
    } finally {
      inputFileRef.current = "";
      setIsUploading(false);
    }
  };
  const handleSubmission = async () => {
    // API call to upload file
    const file = selectedFiles[0];
    if (!isEmpty(file)) setIsUploading(true);
    callingApi.current = false;
    await uploadDocument({ file });
  };

  const onFileChangeCapture = async (e, isDropped = false) => {
    const filesArr = isDropped
      ? [...e.dataTransfer.files]
      : [...e.target.files];
    let filteredFilesArray;
    let files;
    const { isValid, message, sizeFailure } = handleFilesValidation(filesArr);
    if (!isValid) showNotification("Error", message);
    else {
      if (sizeFailure) {
        showNotification("Error", message);
        filteredFilesArray = filesArr.filter((file) => file.size < FILE_SIZE);
      }
      files = filteredFilesArray ? filteredFilesArray : filesArr;
      callingApi.current = true;
      setSelectedFiles(files);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // validate before uploading
    if (!isEmpty(validateBeforeUpload)) {
      const { isValid, message } = validateBeforeUpload;
      if (!isValid) showNotification("Error", message);
      return;
    }
    onFileChangeCapture(e, true);
    e.dataTransfer.clearData();
  };

  return (
    <>
      {loading && <PageLoader />}
      {isEmpty(selectedFiles) ||
      !totData?.DebtSyndication?.signedEngagementLetter ? (
        <div
          onClick={uploadNewFile}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          className="moderation_upload"
        >
          <img
            className="mr-1"
            src="/assets/cloud_upload.svg"
            alt=""
            style={{ width: "25px" }}
          />
          <span className="fs10 fw5 mr-1">Upload Signed Document</span>
          <input
            type="file"
            disabled={disabled}
            ref={inputFileRef}
            onChangeCapture={onFileChangeCapture}
            style={{ display: "none" }}
          />
        </div>
      ) : (
        <div
          className="moderation_upload text-truncate"
          style={{
            border: "none",
            background: "#F8F8F8",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {" "}
          <span
            id={"FileName"}
            onClick={handleClickToDownload}
            style={{
              fontSize: "10px",
              fontFamily: "Gilroy-semibold",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "calc(100% - 20px)",
            }}
          >
            {selectedFiles[0]?.name}{" "}
          </span>{" "}
          {(totAcceptedValue?.elUploadedType !== "Standard EL") &&
            (
            <span
              onClick={() => !disabled && setAlertMessage(true)}
              style={{
                marginRight: "10px",
                fontSize: "10px",
                fontFamily: "Gilroy-semibold",
              }}
            >
              X
            </span>
            )
          }
        </div>
      )}
      {alertMessage && (
        <div>
          <AlertModal
            title="Are you sure?"
            show={alertMessage}
            textMsg={`Do you want to cancel the current ${selectedEngagementLetter} document?`}
            buttonName="Yes"
            actionMethod={actionOnAlert}
            handleClose={() => setAlertMessage(false)}
          />
        </div>
      )}
      <></>
    </>
  );
};

export default FileUpload;
