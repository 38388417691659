import React from 'react';

function TransactionListHeader(props) {
    return (
        <thead className="team-head repay-table">
            <tr>
                <th>Company Name</th>
                <th>Investor Name</th>
                <th># Contracts</th>
                <th>Repayments</th>
                <th>Repayments Date</th>
            </tr>
        </thead>
    );
}

export default TransactionListHeader;