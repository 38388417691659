
import React from 'react';
import FilterHeaderComponent from '../../../../../components/FilterHeaderComponent/FilterHeaderComponent';

function HelpRequestTableHeader(props) {
    return (
        <thead className="team-head admin-help">
        <tr>
            <FilterHeaderComponent
                isSortAllowed={true}
                sorting_order={'desc'}
                isFilterAllowed={true}
                headerText={'Contact Name'}
                labelText={'Search User Name'}
                filter_type={'search'}
                handleSearchFilter = {props.handleSearchFilter}
                keyName = {props.searchObjContactName.keyName}
                value = {props.searchObjContactName.value}
             />
             <th>Type</th>
             <th>Phone Number</th>
             <th>Email Address</th>
              <FilterHeaderComponent
                isSortAllowed={false}
                sorting_order={'asc'}
                isFilterAllowed={true}
                headerText={'Issue Type'}
                labelText={'Filter Issue Type'}
                filter_type={'selector'}
                inputSelectorHandler = {props.inputSelectorHandler}
                elementsArray = {props.selectorObjIssueType.elementsArray}
                keyName = {props.selectorObjIssueType.keyName}
           />        
           <FilterHeaderComponent
                isSortAllowed={false}
                sorting_order={'desc'}
                isFilterAllowed={true}
                headerText={'Issue Description'}
                labelText={'Search Issue Description'}
                filter_type={'search'}
                handleSearchFilter = {props.handleSearchFilter}
                keyName = {props.searchObjIssueDesc.keyName}
                value = {props.searchObjIssueDesc.value}
             />
            <th>Requested On</th>
        </tr>
        </thead>
    )
}

export default HelpRequestTableHeader;
