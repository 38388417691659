import React, { Fragment } from 'react';
import styles from '../DocumentVault.module.scss';
import DocumentVaultTableSectionRow from './DocumentVaultTableSectionRow';
import DocumentVaultTableSectionHeader from './DocumentVaultTableSectionHeader';
import { isEmpty } from 'lodash';
import { CATEGORY_SECTION_MAPPING } from '../consts/consts';

const {
    TableCard,
    w650,
    DownloadAllButton
} = styles;

export default function DocumentVaultTable({ docsData, moderatorName, fetchData , downloadAllFiles ,isDownloadAllDocs }) {
    const docsDataArr = docsData ? Object.keys(docsData) : [];

    return (
        <div className={`mt-4 ${TableCard}`}>
            <table>
                <thead>
                    <tr>
                        <th>Documents Type</th>
                        <th className={w650}>Document Checks</th>
                        <th>Last updated</th>
                        <th>Status</th>
                        {/* <th>Shared</th> */}
                        <th></th>
                        <th>{ <button
                    className={DownloadAllButton}
                    onClick={() => downloadAllFiles()}
                    >
                        <div className={styles.DivClass}>
                            {isDownloadAllDocs ? 
                                <div id="loading-spinner" className={styles.DownloadAllSpinner}>
                                    <div className="spin-icon"></div>
                                </div>
                                :
                                <img src="/assets/download-dv.svg" className={styles.DownloadAllIcon} alt="" />
                            }
                                <span className="">Unsigned</span>
                        </div>
                </button>}</th>
                    </tr>
                </thead>
                <tbody>
                    {docsDataArr?.map((category) => (
                        <Fragment key={category}>
                            <DocumentVaultTableSectionHeader
                                key={category}
                                category={category}
                                docsData={docsData}
                            />
                            {docsData?.[category] && !isEmpty(docsData[category]?.docs) && Object.keys(docsData[category]?.docs).map((docType) => (
                                <DocumentVaultTableSectionRow
                                    key={docType}
                                    docData={docsData[category]?.docs?.[docType]?.[0]}
                                    docTypeName={docType}
                                    moderatorName={moderatorName}
                                    section={CATEGORY_SECTION_MAPPING[category]}
                                    fetchData={fetchData}
                                />
                            ))}
                        </Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    )
}