import ProgressBar from "../ProgessBar/ProgressBar";
import styles from "../deals.module.scss";
import clsx from 'clsx';
import { ACTION_STATUS, OVERVIEW_STATUS } from "../../../../../../../common/ENUM";
import { useCallback, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DealsProgressSummary from "./DealsVisibility/dealProgressSummary";
import { EXPIRED_OR_PASSED_DEALS_STAGES, SHOW_NOTIFICATION } from "../../../../../../../constants/enums";
import { addDealComments } from "../../../../../../../common/ApiHelpers";
import { showNotification } from "../../../../../../../util/utility";
import { isEmpty } from "lodash";
import { getEmail, getUserName } from "../../../../../../App/AppReducer";
import moment from "moment";
const DealsRow = (props) => {
  const observer = useRef();
  const commentsBoxRef = useRef(null);
  const [isEditComments, setIsEditComments] = useState(false);
  const [rowComments, setRowComments] = useState(null);
  const loggedInUserName = useSelector(getUserName);
  const loggedInUserEmail = useSelector(getEmail);
  const dispatch = useDispatch();
  const lastInvesteeRef = useCallback(
    (node) => {
      if (props.loader) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && props.hasMore()) props.loadMore();
      });
      if (node) observer.current.observe(node);
    },
    [props.loader, props.hasMore()]
  );
  useEffect(() => {
    /**
     * if clicked on outside of element
     */
      // Bind the event listener
      if(isEditComments) {
        document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
      }
  }, [rowComments, isEditComments]);
  const isPassedOrExpired = () => {
    const lastCompletedStageTop = (props.row?.lastCompletedStageUpper) ? props.row?.lastCompletedStageUpper : props.row?.lastCompletedStageDown;
    if (EXPIRED_OR_PASSED_DEALS_STAGES.includes(lastCompletedStageTop)) {
        return true;
    }
    return false;
}
  const formatDate = (date) => {
    const newDate = new Date(date).toDateString();
    return `${newDate.slice(8, 10)} ${newDate.slice(4, 7)} ${newDate.slice(
      11,
      15
    )}`;
  };
  const timeDate = (date) => {
    const newDate = new Date(date).toString();
    return `${newDate.slice(16, 21)}, ${newDate.slice(8, 10)} ${newDate.slice(
      4,
      7
    )} ${newDate.slice(11, 15)}`;
  };

  const companyRedirectionURL = `/admin/company/${props.row?.investeeOrganizationId}/view`;

  const openDrawer = () => {
    props.fetchDataAndOpenDrawer(props.row);
  }

  const onActionStatusButtonClick = (actionStatus) => {
    if (actionStatus) openDrawer();
  }

  const saveDealComments = async (comment) => {
    const payload = comment;
    const res = await addDealComments(payload);
    if (res) {
      showNotification(SHOW_NOTIFICATION.SUCCESS, 'Comments added successfully');
    } else {
      if (props?.row?.comments?.length > 0)
        props?.row?.comments?.shift();
      showNotification(SHOW_NOTIFICATION.ERROR, 'Failed to add comments');
    }
  }

  const getStyle = (item) => {
    return item.key === "investorDisplayName"
    ? { maxWidth: "100px", paddingRight: "15px", width: "15%" }
    : item.key === "companyName"
      ? { maxWidth: "100px", paddingRight: "15px", width: "18%" }
      : item.key === "actionStatus"
        ? { width: "12%" }
        : item.key === "drawnAllocatedSanction"
          ? { width: "10%" }
          : item.key === "overviewStatus"
            ? { width: "20%" }
            : {}

  }

  function handleClickOutside(e) {
    if (!(commentsBoxRef.current && isEditComments && commentsBoxRef.current !== e.target)) return;
    const rowCommentsInput = rowComments;
    const comments = typeof rowComments === 'string' && !isEmpty(rowComments) && !isEmpty(rowComments.trim());
    if (rowComments?.trim()?.length > 2000) {
      showNotification(SHOW_NOTIFICATION.ERROR, 'Comments should not exceed 2000 characters');
      return;
    }
    if (comments) {
      const newCommentPayload = {
        comment: rowCommentsInput,
        owner: loggedInUserName,
        ownerEmail: loggedInUserEmail,
        created_at: moment().toDate(),
        investeeOrganizationId: props?.row?.investeeOrganizationId,
        dealOfferId: props?.row?.offerId,
      }
      saveDealComments(newCommentPayload);
      if (props?.row?.comments) {
        props.row.comments.unshift(newCommentPayload);
      }
    }
    setRowComments(null);
    setIsEditComments(false);
  }

  const getActionClassName = (status) => {
    return clsx({
      [styles.action_deal]: status === ACTION_STATUS.deal || status === ACTION_STATUS.resolve_query,
      [styles.action_send_kyc]: status === ACTION_STATUS.send_kyc,
      [styles.action_drawdown]: status === ACTION_STATUS.drawdown,
      [styles.action_reminder]: status === ACTION_STATUS.reminder,
      [styles.action_resend_kyc]: status === ACTION_STATUS.resend_kyc,
      [styles.action_completed]: status === ACTION_STATUS.completed,
      [styles.action_sanction]: ![ACTION_STATUS.deal, ACTION_STATUS.resolve_query, ACTION_STATUS.completed, ACTION_STATUS.send_kyc, ACTION_STATUS.drawdown, ACTION_STATUS.reminder, ACTION_STATUS.resend_kyc].includes(status),
    });
  };

  const renderer = (
    <>
      {/* id={
        props.row["overviewStatus"] === OVERVIEW_STATUS.passed ||
          props.row["overviewStatus"] === OVERVIEW_STATUS.expired
          ? styles.row_fade_out
          : ""
      }
      style={{ cursor: "pointer" }}
      className={styles.cta}
    > */}
      {props.dataHeaders &&
        props.dataHeaders.map((item, index) => (
          <td
            key={index}
            className={item.key === "companyName" ? styles.tableBox : item.key === "investorDisplayName" ? "text-truncate" : item.key === "actionStatus" ? styles.dealComments : ""}
            style={getStyle(item)}
          >
            {item.key === "overviewStatus" ? (
              (!(props.row?.isDebtSyndication))? <DealsProgressSummary dealData={props.row}/>:<ProgressBar status={props.row[item.key]} height={8} />              
            ) : item.key === "actionStatus" ? (
              <div className={styles.tabsWrap}>
                <td className="comment_box_wrapper" style={{ width: "70%" }}>
                    {isEditComments ? (
                      <textarea
                        autoFocus={isEditComments}
                        value={rowComments}
                        onChange={(e) => setRowComments(e.target.value)}
                        style={{ width: "167px", height: "55px" }}
                        ref={commentsBoxRef} 
                        //adding limit of 2000 characters
                        maxlength="2000"/>
                    ) : (
                    <div
                      onDoubleClick={() => setIsEditComments(true)}
                      className={styles.commentsBox}
                    >
                      {Array.isArray(props.row.comments) ? (
                        props.row.comments?.map((comment, index) => {
                          const commentArray = comment?.comment?.split("\n");
                          return (
                            <div className="mb-2" key={index}>
                            <div className={styles.commentRow} key={index}>
                              <div style={{ color: "#C5C5C5", fontSize: "8px" }}>
                                <span>
                                  {moment(comment?.created_at).format("h:mm A")},&nbsp;
                                  {moment(comment?.created_at).format("DD MMM YY")}
                                </span>
                                <span>&nbsp;by {comment?.owner}</span>
                              </div>
                              <div>{commentArray?.map((part) => (
                                <>{part && <p className={styles.comment}>{part}</p>}</>
                                ))}
                              </div>
                            </div>
                            </div>
                          )
                        })
                      ) : (
                        <div></div>
                      )}
                    </div>
                    
                  )}
                </td>
                <div className={styles.tabsWrapper}>
                  {props.row[item.key] ? (
                    <><div onClick={() => onActionStatusButtonClick(props.row[item.key])}>
                        <button
                          className={getActionClassName(props.row[item.key])}
                        >
                          {props.row[item.key]}
                        </button>
                      </div>
                      <td onClick={() => openDrawer()}>
                          <span>
                            <img
                              className="mr-2"
                              src="/assets/cta.svg"
                              style={{ width: "16px" }}
                              alt="" />
                          </span>
                        </td>
                    </>
                  ): (
                    <td onClick={() => openDrawer()} style={{ marginLeft: "auto" }}>
                          <span>
                            <img
                              className="mr-2"
                              src="/assets/cta.svg"
                              style={{ width: "16px"}}
                              alt="" />
                          </span>
                        </td>
                  )}
                </div>
              </div>
            ) : item.key === "createDate" ? (
              <span
                style={{ color: isPassedOrExpired() ? "#c3c3c3" : "inherit" }}
              >
                {formatDate(props.row[item.key])}
              </span>
            ) : item.key === "lastActivityStatus" ? (
              <div className={styles.lastActivity}  style={{ color: isPassedOrExpired() ? "#c3c3c3" : "inherit" }}>
                <div className={styles.lastActivityStatus}>
                  {props.row[item.key]}
                </div>
                <div className={styles.timing}>
                  {props.row?.lastActivityDate &&
                    (props.row.lastActivityDate !== "-" ? (
                      <div className={styles.timeDate}>
                        on {timeDate(props.row?.lastActivityDate)}
                      </div>
                    ) : (
                      ""
                    ))}
                </div>
              </div>
            ) : item.key === "investorDisplayName" ? (
              <span
                style={{
                  color: isPassedOrExpired() ? "#c3c3c3" : "inherit",
                }}
                data-bs-toggle="tooltip"
                data-bs-html="true"
                title={props.row[item.key]}
              >
                {props.row[item.key]}
                {/* a very long text for testing width of investor name column, in this case we need to truncate it. */}
              </span>
            ) : item.key === "companyName" ? (
              <div className="d-flex">
                <div style={{ height: 'inherit' }}>
                  <a className="link_anchor w-100 h-100" href={companyRedirectionURL} target='_blank' rel='noreferrer'>
                    <div
                      data-bs-toggle="tooltip"
                      data-bs-html="true"
                      title={props.row[item.key]}
                      className={`text-truncate ${styles.company_link}`}
                      style={{ maxWidth: "110px", width: "100%" }}
                    >
                      {props.row[item.key]}
                    </div>
                  </a>
                </div>
                {!!props.row['isAddOn'] && <div className={styles.addOn}>ADD</div>}
                {!!props.row['isDebtSyndication'] && <div className={styles.debtSyndication}>DS</div>}
                {!props.row['isDebtSyndication'] && <div className={styles.rrf}>RRF</div>}
              </div>
            ) : item.key === "drawnAllocatedSanction" ? (
              ((!isNaN(props.row[item.key])) ?
                <span style={ { display: "flex", color: isPassedOrExpired() ? "#c3c3c3" : "#A4A4A4", justifyContent: "flex-start", paddingRight: "20px" }}>{
                  `₹${props.row[item.key]}L`
                }</span> :
                <span style={{
                  color: isPassedOrExpired() ? "#c3c3c3" : "#000000",
                }} className={"d-flex align-items-start justify-content-start"}>{
                  props.row[item.key]
                }</span>
              )) : (
              props.row[item.key]
            )}
          </td>
        ))}
    </>
  );
  if (props.dataLength === props.index + 1) {
    return <tr ref={lastInvesteeRef}
    id={
      props.row["overviewStatus"] === OVERVIEW_STATUS.passed ||
        props.row["overviewStatus"] === OVERVIEW_STATUS.expired ||
          props.row["overviewStatus"] === OVERVIEW_STATUS.missed
        ? styles.row_fade_out
        : ""
    }
    style={{ cursor: "pointer" }}
    className={styles.cta}>{renderer}</tr>;
  } else {
    return <tr
    id={
      props.row["overviewStatus"] === OVERVIEW_STATUS.passed ||
        props.row["overviewStatus"] === OVERVIEW_STATUS.expired ||
          props.row["overviewStatus"] === OVERVIEW_STATUS.missed
        ? styles.row_fade_out
        : ""
    }
    style={{ cursor: "pointer" }}
    className={styles.cta}>{renderer}</tr>;
  }
};
export default DealsRow;
