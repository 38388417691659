import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCheckIfUserHasRelevantPermissions } from "../../../../../../../../../App/AppReducer";
import DataVaultIntegration from "../components/DataVaultIntegration";
import DataVaultTableRows from "../components/DataVaultTableRows";
import DocumentationTableRows from "../components/DocumentationTableRows";
import ModerateRowBox from "../components/ModerateBox/ModerateRowBox";
import ModerateOnboarding from "../components/ModerateBox/ModerateOnboarding";
import MonthlyIntegrationTable from "../components/MonthlyIntegrationTable/MonthlyIntegrationTable";
import OverviewStatusBar from "./components/OverviewStatusBar";
import AddOnModerate from "../components/ModerateBox/AddOnModerate/AddOnModerate";
import isEmpty from "lodash/isEmpty";
import ModerateRowBoxNew from "../components/ModerateBox/ModerateRowBoxNew";
import './DataVaultOverview.scss';
import NeedInfoTable from "../components/NeedInfoTable/NeedInfoTable";

export const tabs = {
  DATA_VAULT: "DataVault",
  MONTHLY_VIEW: "MonthlyView",
};
export const statusBar = [
  {
    label: "Data Vault",
    value: "DataVault",
  },
  {
    label: "Monthly View",
    value: "MonthlyView",
  },
];

export default function DataVaultOverview({
  investeeOrganization,
  investeeOnboardedId,
  isModeratingDocumentation,
  setIsModeratingDocumentation,
  documentationData,
  fetchDocumentationData,
  monthlyData,
  fetchMonthlyData,
  contractsCount,
  integrationsObj,
  monthlyViewData,
  downloadIntegrationFiles
}) {
  const isUserHasEditAccess = useSelector((state) =>
    getCheckIfUserHasRelevantPermissions(state, ["app:edit"])
  );
  const isUserHasUWViewAccess = useSelector((state) =>
    getCheckIfUserHasRelevantPermissions(state, ["underwriting:edit"])
  );

  const [currentTab, setCurrentTab] = useState(tabs.DATA_VAULT);
  const [moderateRow,setModerateRow] = useState(null);

  useEffect(() => {
    if (isModeratingDocumentation) {
      onModerateDocumentation();
      setIsModeratingDocumentation(false);
    }
  }, [documentationData]);

  const onModerateDocumentation = () => {
    const obj = { ...documentationData };
    obj.dataType = "documentation";
    obj.due_date = "Documentation";
    setModerateRow(obj);
  };

  const renderModerateRowBox = () => {
    if (moderateRow) {
      if (moderateRow.due_date === "Onboarding") {
        return (
          <ModerateOnboarding
            investeeOrgId={investeeOrganization._id}
            investeeOnboardedId={moderateRow?._id}
            fetchMonthlyData={fetchMonthlyData}
            fetchDocumentationData={fetchDocumentationData}
            dataLength={monthlyData.length}
            monthlyData={monthlyData}
            moderateRow={moderateRow}
            price={investeeOrganization.company_price?.toFixed(4)}
            tradingLimit={investeeOrganization.trading_limit}
            irr={
              investeeOrganization.irr ? investeeOrganization.irr + "%" : null
            }
            contractsCount={contractsCount}
          />
        );
      }
      if (moderateRow.due_date === "Documentation") {
        return (
          <div style={{position: "relative"}}>
          <ModerateRowBox
            investeeOnboardedId={investeeOnboardedId}
            fetchMonthlyData={fetchMonthlyData}
            fetchDocumentationData={fetchDocumentationData}
            dataLength={monthlyData.length}
            moderateRow={moderateRow}
            setModerateRow={setModerateRow}
            price={investeeOrganization.company_price?.toFixed(4)}
            tradingLimit={investeeOrganization.trading_limit}
            irr={
              investeeOrganization.irr ? investeeOrganization.irr + "%" : null
            }
            contractsCount={contractsCount}
          />
          </div>
        );
      }
      return (
        <AddOnModerate
          monthlyViewData={monthlyViewData}
          moderateRow={moderateRow}
          monthlyData={monthlyData}
          fetchMonthlyData={fetchMonthlyData}
        />
      );
    }
    return null;
  };

  const Documentation = () => {
    return (
      <>
        <div className="fs16 fw600 mb-2 mt-4">Documentation</div>
        <div className="admin-data-table1 m-0">
          <table className="table team data-vault">
            <thead className="team-head">
              <tr>
                <th scope="col" className="pl-2" style={{ width: "15%" }}>
                  Data Type
                </th>
                <th scope="col" style={{ width: "10%" }}>
                  KYC
                </th>
                <th scope="col" style={{ width: "11%" }}>
                  Aut. Doc.
                </th>
                <th scope="col" style={{ width: "12%" }}>
                  Company Aut.
                </th>
                <th scope="col" style={{ width: "11%" }}>
                  Add'l
                </th>
                <th scope="col" style={{ width: "12%" }}>
                  Submitted
                </th>
                <th scope="col" style={{ width: "13%" }}>
                  Status
                </th>
                <th scope="col" style={{ width: "13%" }}></th>
                <th scope="col" style={{ width: "3%" }}></th>
              </tr>
            </thead>
            <tbody className="team-body">
                <DocumentationTableRows
                  investeeOrganizationId={investeeOrganization._id}
                  companyName={investeeOrganization.name}
                  documentationData={documentationData}
                  onModerate={onModerateDocumentation}
                />
            </tbody>
          </table>
        </div>
      </>
    )
  }

  const MonthlyData = () => {
    return (
      <>
        <div className="fs16 fw600 mb-2">Monthly Data</div>
        <div className="admin-data-table1 m-0">
          <table className="table team data-vault">
            <thead className="team-head">
              <tr>
                <th scope="col" className="pl-2">
                  Data Type
                </th>
                <th scope="col">
                Prov.Fs (MIS)
                </th>
                <th scope="col">
                  Invoicing
                </th>
                <th scope="col">
                  Bank St.
                </th>
                <th scope="col">
                  Audt. Fs
                </th>
                {/* <th scope="col">
                  Prov. Fs
                </th> */}
                <th scope="col">
                  GST
                </th>
                <th scope="col">
                  Debt Sch.                  
                </th>
                <th scope="col">
                  Dir. Details
                </th>
                <th scope="col">
                  Submitted
                </th>
                <th scope="col">
                  Status
                </th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody className="team-body">
              <DataVaultTableRows
                investeeOrganizationId={investeeOrganization._id}
                companyName={investeeOrganization.name}
                monthlyData={monthlyData}
                setModerateRow={setModerateRow}
              />
            </tbody>
          </table>
        </div>
      </>
    )
  }

  return (
    <div className='DataVaultOverview'>


      <div className="BottomSection">
        <div>
          <MonthlyData />
          {!isEmpty(documentationData) && <Documentation />}
        </div>

        <div>
          {(isUserHasEditAccess || isUserHasUWViewAccess) && moderateRow?.dataType !== "documentation" && (
            <DataVaultIntegration
              investeeOrgId={investeeOrganization._id}
              investeeOnboardedId={investeeOnboardedId}
              integrationsObj={integrationsObj}
              fetchMonthlyData={fetchMonthlyData}
            />
          )}
        </div>
      </div>
      <div className="mt-5">
        <NeedInfoTable monthlyData={monthlyData}/>
      </div>
      <div>
        <MonthlyIntegrationTable
          monthlyViewData={monthlyViewData}
          integrationsObj={integrationsObj}
          downloadIntegrationFiles={downloadIntegrationFiles}
        />
      </div>
      {!isEmpty(moderateRow) && renderModerateRowBox()}
    </div>
  );
}
