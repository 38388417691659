import React from 'react'
import './InvestorBifercationTable.scss'
import RecurTable from "../../../../../components/RecurTable/RecurTable.jsx";
import {isEmpty} from "lodash";

interface InvestorBiFercationTableProps {
    investorBreakup: InvestorRepayments[],
}

interface InvestorRepayments {
    investorName?: string,
    collectionAmount?: string,
    collectedAmount?: string,
    availableNachAmount?: string,
    usedNachAmount?: string,
    neftAmount?: string,
    neftColor?: string,
    remainingAmount?: string,
    delayCharges?:string,
}

function InvestorBifercationTable(props: InvestorBiFercationTableProps) {
    const {investorBreakup,isFnnDateCrossed} = props
    const getHeader = () => [
        {
            jsx: (
                <div className='TableHeader__InvestorName'>Investor Name</div>
            )
        },
        {
            jsx: (
                <div className='TableHeader__CollectionAmount'>Collection Overview</div>
            )
        },
        {
            jsx: (

                <div className='TableHeader__NACH'>NACH</div>
            )
        },
        {
            jsx: (
                <div className='TableHeader__NEFT'>NEFT</div>
            )
        },
        {
            jsx: (
                <div className='TableHeader__DelayCharges'>Delay Charges</div>
            )
        },
        {
            jsx: (
                <div className='TableHeader__Remaining'>Remaining<span className='includeText'>(inc.delay)</span></div>
            )
        },
    ]
    const getRows = () => {
        if (isEmpty(investorBreakup)) {
            return (
                <tbody className='noDataInTable'>
                <tr>
                    <td colSpan={6}>No InvestorData</td>
                </tr>
                </tbody>
            );
        } else {
            return (
                <tbody>
                {
                    investorBreakup.map((investor: InvestorRepayments, index: number) => {
                        return (
                            <tr style={{height:'40px'}} key={index}>
                                <td className='TableRow__InvestorName'>{investor.investorName}</td>
                                <td className='TableRow__CollectionAmount'><span style={{color:'#00AA00'}}>₹{investor.collectedAmount}</span>/₹{investor.collectionAmount}</td>
                                <td className='TableRow__NACH'>₹{investor.usedNachAmount}</td>
                                <td className='TableRow__NEFT' style={{color:`${investor.neftColor}`}}>{isFnnDateCrossed ? (<del>₹ {investor.neftAmount}</del>) : (`₹ ${investor.neftAmount}`)}</td>
                                <td className='TableRow__Remaining'>₹{investor.delayCharges}</td>
                                <td className='TableRow__Remaining'>{isFnnDateCrossed ? (<del>₹ {investor.remainingAmount}</del>) : (`₹ ${investor.remainingAmount}`)}</td>

                            </tr>
                        )
                    })
                }
                </tbody>
        )
        }
    }
    return (
        <>
            <div className='TableContainer'>
                <RecurTable
                    headers={getHeader()}
                    renderRows={getRows()}
                    changeColorOnHover={false}
                    style={{width: '100%', margin: 0,height:'100%'}}
                    headerStyle={{height: '20px'}}
                    loading={false}
                />
            </div>
        </>
    )
}

export default InvestorBifercationTable