import React from 'react';
import './ToggleButton.scss';

export default function ToggleButton({
                                         checked,
                                         onChange,
    disabled,companyLevelBtn
                                     }: {
    checked: boolean;
    onChange: () => void;
    disabled?: boolean;
    companyLevelBtn:Object
}) {
    return (
        <div className={companyLevelBtn ? "switchContainerCompanyLevel" : "switchContainer"}>
        <label className="switch1">
            <input disabled={disabled} type="checkbox" checked={checked} onChange={onChange} />
            <span className="slider round"></span>
        </label>
        </div>
    );
}
