import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { getInvesteeOnboardingData, getInvesteesOrganization } from '../../../../../../AdminReducers';
import StatusBar from '../DataVault/components/StatusBar';
import callApi from '../../../../../../../../util/apiCaller';
import { showNotification } from '../../../../../../../../util/utility';
import { UPDATE_INVESTEE_ORGANIZATION } from '../../../../../../AdminActions';
import storage from '../../../../../../../../util/storageService';
import { CERBERUS_TOKEN, USER_ID } from '../../../../../../../../constants/enums';

function Insights() {
    const tabs = ["Open", "Waitlisted", "Approved"];
    const statusBar = {
        1: "Open",
        2: "Waitlisted",
        3: "Approved"
    }
    const insightsStatus = useSelector(state => getInvesteesOrganization(state)?.is_insights_unlocked) ?? statusBar[1];
    const investeeOrgId = useSelector(state => getInvesteeOnboardingData(state)?.investee_organization_id);
    const [currentCheckoutStatus, setCheckoutStatus] = React.useState(insightsStatus);
    const [showModal, setShowModal] = React.useState(false);
    const cerberusToken = storage.get(CERBERUS_TOKEN);
    const userId = storage.get(USER_ID);
    const dispatch = useDispatch();
    const closeModal = () => {
        setCheckoutStatus(insightsStatus);
        setShowModal(false)
    }

    const changeStatus = (label) => {
        setCheckoutStatus(label);
        setShowModal(true);
    }

    const onSaveClick = () => {
        callApi(`/admin/insights/update-insights-access`, "post", {
            investee_organization_id: investeeOrgId,
            insights_status: currentCheckoutStatus
        }).then(
            res => {
                if (res.status === "Success") {
                    closeModal();
                    const data = res.data;
                    setCheckoutStatus(res?.data?.is_insights_unlocked ?? statusBar[1]);
                    dispatch({
                        type: UPDATE_INVESTEE_ORGANIZATION,
                        data: data
                    })
                    showNotification("Success", "Successfully updated insights status");
                } else {
                    showNotification("Error", "Couldn't update insights status");
                }
            }
        );
    }

    const openInsights = () => {
        window.open(
            `${process.env.REACT_APP_INSIGHTS_URL}?investeeOrgId=${investeeOrgId}&cerberusToken=${cerberusToken}&userId=${userId}`,
        );
    }

    return (
        <div className='container-fluid pt-4 main-onboarding'>
            <div className='insights-settings'>
                <div>
                    Open Dashboard
                    <div className='insights-settings-button' onClick={openInsights}>
                        Go To Insights &rarr;
                    </div>
                </div>
                <div>
                    Status
                    <StatusBar tabs={tabs} currentTab={currentCheckoutStatus} setCurrentTab={changeStatus} allowOnlyForwardSelection={false} />

                </div>
            </div>

            {showModal ? (
                <Modal show={showModal} onHide={closeModal}>
                    <Modal.Header closeButton>
                        Attention!
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to change the status to {currentCheckoutStatus ?? ""}?
                        <div className='error-msg'>Cannot move back to {insightsStatus} once changes are saved.</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={onSaveClick}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : null}
        </div>
    )
}

export default Insights