import React, { useEffect, useMemo, useState } from 'react'
import AdminHead from '../../components/AdminHead'
import callApi from '../../../../util/apiCaller'
import { getQuarterStartAndEnd, showNotification } from '../../../../util/utility'
import moment from 'moment'
import RecurTable from '../../../../components/RecurTable/RecurTable'
import clsx from 'clsx'
import { debounce, isEmpty } from 'lodash'
import './Tds.scss'
import TdsModal from './TdsModal'
import { getCheckIfUserHasRelevantPermissions } from '../../../App/AppReducer'
import { useSelector } from 'react-redux'

function Tds() {
    const BUTTON_ENUMS = {
        CURRENT_QTR: 'Current Qtr',
        LAST_QTR: 'Last Qtr',
        ALL_TIME: 'All Time',
    };
    const [query, setQuery] = useState({
        investeeOrgName: "",
        investorOrgName: ""
      });
    const [rowData, setRowData] = useState([])
    const [loading,setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [buttonSelected, setButtonSelected] = useState(BUTTON_ENUMS.CURRENT_QTR)
    const isUserWithCaEditAccess = useSelector((state) => getCheckIfUserHasRelevantPermissions(state, ["ca:edit"]));
    const [index, setIndex] = useState(null)
    const STATUS_ENUMS = {
        SUBMITTED: 'Submitted',
        APPROVED: 'Approved',
        REJECTED: 'Rejected',
        SENT_TO_INV: 'Sent to Inv',
        RESEND_TO_INV: 'Resend to Inv',
    };
    const getTableRows = (isAllTime,dates) => {
        setLoading(true)
        let startDate = null
        let endDate = null
        if (!isAllTime) {
            startDate = dates.quarterStart
            endDate = dates.quarterEnd
        }
        const allTime = isAllTime
        callApi(`/tds/admin/getTdsClaimsData?startDate=${startDate}&endDate=${endDate}&allTime=${allTime}&investeeOrgName=${query.investeeOrgName}&investorOrgName=${query.investorOrgName}`, 'get', null, null, false, false, false, false, false, false, true).then((res) => {
            if (res.responseData.responseCode === 20) {
                setRowData(res?.data)
                setLoading(false)
            }
            else {
                showNotification('error', res.responseData.responseMessage)
                setLoading(false)
            }
        })
    }

    const debouncedGetTableRows = debounce(getTableRows, 300);

    useEffect(()=>{
        if(buttonSelected === BUTTON_ENUMS.CURRENT_QTR){
            debouncedGetTableRows(false,getQuarterStartAndEnd(moment()))
        }
        else if(buttonSelected === BUTTON_ENUMS.LAST_QTR){
            debouncedGetTableRows(false,getQuarterStartAndEnd(moment().subtract(1, 'quarter')));
        } else{
            debouncedGetTableRows(true)
        }
        return () => {
            if (debouncedGetTableRows) {
                debouncedGetTableRows.cancel();
            }
          };
    },[buttonSelected, query])


    const changeQuery = (keyName, keyValue) => {
        setQuery((prevQuery) => ({
             ...prevQuery,
                [keyName]: keyValue 
            }));
      };

    const renderClass = (status) => {
        if (status === 'APPROVED') {
            return 'WhiteBtn'
        }
        else if (status === 'SUBMITTED') {
            return 'BlueBtn1'
        }
        else if (status === 'REJECTED') {
            return 'BlueBtn1'
        }
        else if (status === 'SENT_TO_INV'||status === 'RESEND_TO_INV') {
            return 'WhiteBtn'
        }
        else {
            return 'dNone'
        }
    }
    const getHeaders = () => {
        return [
            {
                jsx: <span>TDS Refund For</span>,
            },
            {
                jsx: (
                  <span>
                    <input
                      type="text"
                      placeholder="Company Name"
                      value={query.investeeOrgName}
                      onChange={(e) => changeQuery("investeeOrgName", e.target.value)}
                      style={{ border: "none" }}
                    />
                  </span>
                ),
            },
            {
                jsx: (
                  <span>
                    <input
                      type="text"
                      placeholder="Investor Name"
                      value={query.investorOrgName}
                      onChange={(e) => changeQuery("investorOrgName", e.target.value)}
                      style={{ border: "none" }}
                    />
                  </span>
                ),
            },
            {
                jsx: <span>TDS Amount</span>,
            },
            {
                jsx: <span>Last Activity</span>,
            },
            {
                jsx: <span>Status</span>,
            },
            isUserWithCaEditAccess&&{
                jsx: <span>Action</span>,
            },
        ];
    };
    const getDaysDiff = (date) => {
        const today = moment();
        const lastActivity = moment(date);
        const diff = today.diff(lastActivity, 'days');
        if (diff === 0) return 'Today';
        else if (diff === 1) return 'Yesterday';
        else return `${diff} days ago`;
    };
    const getStatusToShow = (adminStatus, isSentToInvestor, investorStatus) => {
        if (investorStatus === 'APPROVED' ) return 'Approved By Investor';
        if (investorStatus === 'NEED_UPDATE') return 'Rejected By Investor';
        return STATUS_ENUMS?.[adminStatus]
    }
    const renderRows = () => {
        if (isEmpty(rowData)) {
            return (
                <tbody className='noDataInTable'>
                    <tr>
                        <td colSpan={6}>No TDS Data available</td>
                    </tr>
                </tbody>
            );
        } else {
            return (
                <tbody>
                    {rowData.map((row, index) => {
                        return (
                            <tr key={index} style={{ fontSize: '14px' }}>
                                <td style={{ width: '15%', paddingLeft: '2rem' }}>{`${moment(row.startDate).format(
                                    'MMM YYYY',
                                )}-${moment(row.endDate).format('MMM YYYY')}`}</td>
                                <td>{row.investeeOrgName}</td>
                                <td>{row.organizationName}</td>
                                <td>₹ {row.tdsAmount}</td>
                                <td>{getDaysDiff(row.lastActivity)}</td>
                                <td style={{ width: '17%', marginRight: '1rem' }}>
                                    <span
                                        className={clsx({
                                            ['Refunded']:  (row?.investorStatus && row?.investorStatus === 'APPROVED') || row?.adminStatus === 'APPROVED',
                                            ['NotClaimed']: (row?.adminStatus === 'SENT_TO_INV'|| row?.adminStatus === 'RESEND_TO_INV'),
                                            ['Submitted']: row?.adminStatus === 'SUBMITTED',
                                            ['Rejected']: (row?.investorStatus && row?.investorStatus === 'NEED_UPDATE' || row?.adminStatus === 'REJECTED')
                                        })}
                                    >
                                        {getStatusToShow(row?.adminStatus, row?.isSentToInvestor, row?.investorStatus)}
                                    </span>
                                </td>
                                {isUserWithCaEditAccess&&<td style={{ width: '10%' }}>
                                    <button
                                        onClick={() => {
                                            setShowModal(!showModal);
                                            setIndex(index);
                                        }}
                                        className={renderClass(row.adminStatus)}
                                    >
                                        View Details
                                    </button>
                                </td>}
                            </tr>
                        );
                    })}
                </tbody>
            );
        }
    };
    return (
        <>
            <div className="main-body">
                <AdminHead />
                <section className='w-100 py-5'>
                    <div className='fs21 px-5 pb-3'>TDS</div>
                    <div className="page-head-bottom-border"></div>
                    <div className='pickDate'>
                            <div className='TableLabel'>
                                Showing <b>{isEmpty(rowData) ? 0 : 1} - {rowData?.length}</b> of {rowData?.length} entries
                            </div>
                        <div className='btnConatiner'>
                            {
                                Object.keys(BUTTON_ENUMS)?.map((key) => {
                                    return (
                                        <div>
                                            {BUTTON_ENUMS[key] === BUTTON_ENUMS.CURRENT_QTR && (
                                                <label htmlFor="button-label">
                                                    Show statement for last
                                                </label>
                                            )}
                                            <button
                                            id={
                                                BUTTON_ENUMS[key] === BUTTON_ENUMS.CURRENT_QTR
                                                    ? 'button-label'
                                                    : ''
                                            }
                                                className={clsx({
                                                    ['Active']: BUTTON_ENUMS[key] === buttonSelected,
                                                    ['Inactive']: BUTTON_ENUMS[key] !== buttonSelected,
                                                })}
                                                onClick={() => {
                                                    setQuery({investeeOrgName:"", investorOrgName: ""})
                                                    setButtonSelected(BUTTON_ENUMS[key]);
                                                }}
                                            >
                                                {BUTTON_ENUMS[key]}
                                            </button>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <RecurTable
                        headers={getHeaders()}
                        loading={loading}
                        renderRows={renderRows()}
                    />
                </section>
                {showModal&&<TdsModal setRowData={setRowData} showModal={showModal} setShowModal={setShowModal} rowData={rowData} index={index}/>}
            </div>
        </>
    )
}

export default Tds