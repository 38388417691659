import clsx from 'clsx';
import React from 'react';
// import './UploadCard.module.scss';
import styles from './UploadCard.module.scss';
import { CATEGORIES } from './uploadDocUtils';
import Spinner from './Spinner';

function SingleFile({
    file,
    downloadFile,
    isInvestor,
    deleteFile,
    disabled,
    isDownloading,
    isDeletingFile,
    category,
    isTally,
    truncateSmallerText,
    renderInAModal = false,
    showDocInModal,
    renderModal,
    customStyleForFileName = {},
    hideDeleteIcon = false,
}: any) {
    const {
        rejected_rows,
        accepted_rows,
        is_file_empty,
        missing_col,
        // file_url,
        total_rows,
        upload_error,
    } = file?.errors || {};
    const fileInvalid = !!(is_file_empty || accepted_rows === 0 || upload_error);

    const { UploadedPDF, UploadedPDFError, downloadable, textTruncate, textTruncate2, underline, shortname,
            Link, Imported, ErrorBlock} = styles;
    const getFileErrors = () => {
        if (fileInvalid)
            return ` Imported but not processed automatically. ${
                file?.errors?.is_file_empty
                    ? '(File is Empty)'
                    : file?.errors?.missing_col?.length
                    ? ` (headers missing: ${file?.errors?.missing_col?.join(',')})`
                    : ''
            }`;
        else return ``;
    };

    return (
        <>
            <div
                className={clsx({
                    [UploadedPDF]: true,
                    [UploadedPDFError]: fileInvalid,
                })}
            >
                <div className="display-f">
                    <span title={`${file.doc_name}${getFileErrors()}`}>
                        {fileInvalid && <img src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/red-info.svg" alt="red" height="12px" />}
                        <div
                            className={clsx(downloadable, textTruncate, {
                                [textTruncate2]: truncateSmallerText,
                                [underline]: fileInvalid,
                                [shortname]: fileInvalid && missing_col?.length,
                            })}
                            style={customStyleForFileName}
                            onClick={() =>
                                renderInAModal
                                    ? showDocInModal(file.doc_link, file.doc_name)
                                    : downloadFile(file.doc_link, file.doc_name)
                            }
                        >
                            {file.doc_name}
                        </div>
                        {getFileErrors()}
                        {renderInAModal && renderModal()}
                    </span>
                    {isDownloading && isDownloading === file.doc_link && <Spinner />}
                </div>

                <span className="cursor-pointer display-f">
                    {isDeletingFile && isDeletingFile === file.doc_link ? (
                        <div id="loading-spinner" style={{ marginLeft: '5px' }}>
                            <div className="spin-icon" style={{ width: '12px', height: '12px' }} />
                        </div>
                    ) : (
                        <>
                            {fileInvalid ? (
                                <span className={Link}>
                                        <></>
                                </span>
                            ) : (
                                <>
                                    <div className={Imported}>
                                        {total_rows > 0 && (
                                            <span>{`${accepted_rows}/${total_rows} Imported`}</span>
                                        )}

                                        {!hideDeleteIcon ? (
                                            <img
                                                onClick={() =>
                                                    deleteFile(
                                                        file.doc_link,
                                                        file._id,
                                                        file.doc_name,
                                                    )
                                                }
                                                src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/clear-blue.svg"
                                                alt="clear-doc"
                                            />
                                            )
                                        : null}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </span>
            </div>
            {!fileInvalid && rejected_rows > 0 && (
                <div className={ErrorBlock}>
                    <img src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/red-info.svg" alt="red" height="12px" />
                    <>There are some issues in the file.</>

                    <span
                        className={Link}
                        onClick={() =>
                            downloadFile(
                                file?.errors?.file_url,
                                `Errors.${file?.errors?.file_url?.split('.')?.[1] ?? 'xlsx'}`,
                                true,
                            )
                        }
                    >
                    ' View data with issues'
                    </span>
                </div>
            )}
        </>
    );
}

export default SingleFile;
