import React, {useState} from 'react';
import './InvestorFilters.scss'
import { isEmpty } from 'lodash';

interface InvestorFiltersProps{
    investorFilterOptions: string[]
    setFilterObj: (filterObj: any)=>void
    filterObj: any
}
export const InvestorFilters=(props: InvestorFiltersProps)=>{
    const {investorFilterOptions,filterObj} = props;
    const [selectedInvestor,setSelectedInvestor] = useState<string>('')
    const handleSelect = (value:string)=>{
        setSelectedInvestor(value)
        props.setFilterObj({...filterObj,investor:[value]})
    }

    if (isEmpty(investorFilterOptions)) return null;

    return(
        <div className='FilterContainer'>
            <select
                className='FilterSelect'
                value={selectedInvestor}
                onChange={(e)=>handleSelect(e.target.value)}
            >
                <option value=''>
                    All Investors
                </option>
                {investorFilterOptions.map((option,index)=>{
                    return(
                        <option
                            key={index}
                            value={option}
                        >
                            {option}
                        </option>
                    )
                })
                }
            </select>
        </div>
    )
}