import React, { useEffect, useState } from 'react';
import callApi from '../../../../../../../util/apiCaller';
import { showNotification } from '../../../../../../../util/utility';

export default function Users({
    investeeOrgId
}) {
    const [members, setMembers] = useState(null);
    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = () => {
        callApi(`investeeOrganization/${investeeOrgId}/profile/get-team`, "get").then(
            res => {
                if (res.status === "Success") {
                    setMembers(res.data.investees);
                } else {
                    showNotification("Error", "Couldn't fetch Users!");
                }
            }
        );
    }

    return (
        <div className="admin-data-table1">
            <table className="table team">
                <thead className="team-head">
                    <tr>
                        <th scope="col" className="pl-2">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Primary User</th>
                        <th scope="col">Role</th>
                    </tr>
                </thead>
                <tbody className="team-body">
                    {members && members.map(item => (
                        <tr>
                            <td className="pl-2">{item.contact_name ? item.contact_name : ''}</td>
                            <td>{item.contact_email ? item.contact_email : ''}</td>
                            <td>{item.phone_number ? item.phone_number : 'NA'}</td>
                            <td>{item.is_primary_user ? "Yes" : "No"}</td>
                            <td>{item.designation ? item.designation : 'NA'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
