import { Drawer } from "@material-ui/core";
import { Button } from "react-bootstrap";
import "./NeedInfoDrawer.scss";
import NeedInfoForm from "../NeedInfoForm/NeedInfoForm";

function NeedInfoDrawer({ open, onClose,needInfoFormArray,setNeedInfoFormArray,handleSubmit,investorOptions,setInvestorOptions,data }) {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      transitionDuration={200}
      PaperProps={{
        style: {
          width: "50%",
          height: "100%",
          padding: "30px 10px 30px 10px",
          borderTopLeftRadius: "20px",
          borderBottomLeftRadius: "20px",
          fontFamily: "Graphik",
        },
      }}
    >
      <div className="needInfoDrawer">
        <div className="needInfoHeader">
          <p className="headingText">New Info Request</p>
          <img
            src="/assets/clear-grey.svg"
            alt="close"
            className="closeDrawer"
            onClick={onClose}
          />
        </div>
        <hr className="horizontalLine" />
        <main className="needInfoMainContainer">
          <NeedInfoForm
            needInfoFormArray={needInfoFormArray}
            setNeedInfoFormArray={setNeedInfoFormArray}
            investorOptions={investorOptions}
            setInvestorOptions={setInvestorOptions}
            data={data}
          />
        </main>
        <div className="needInfofooter">
          <hr className="horizontalLine" />
          <Button
            variant="primary"
            size="sm"
            onClick={(e)=>{
              handleSubmit(e)
            }}
            style={{ float: "right" }}
          >
            Submit Request
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

export default NeedInfoDrawer;
