import { isEmpty, isNil } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import callApi from '../../../../../../../../../../util/apiCaller';
import { updateLastModerated } from '../../../../../../../../../../util/commonApi';
import { downloadFileUsingFetch } from '../../../../../../../../../../util/utility';
import { getCheckIfUserHasRelevantPermissions, getIsAddOnLimitedRequested } from '../../../../../../../../../App/AppReducer';
import { getInvesteeOnboardingData, getInvesteesOrganization } from '../../../../../../../../AdminReducers';

export default function DataVaultTableRows({ investeeOrganizationId, companyName, monthlyData, setModerateRow }) {
    const [isDownloadingFile, setIsDownloadingFile] = useState(null);
    const onboardingData = useSelector(getInvesteeOnboardingData);
    const isUserHasUWViewAccess = useSelector((state) => getCheckIfUserHasRelevantPermissions(state, ["underwriting:edit"]));
    const isUserHasEditAccess = useSelector(state => getCheckIfUserHasRelevantPermissions(state, ["app:edit"]));
    const isLimitAddOnRequested = useSelector(state => getIsAddOnLimitedRequested(state));
    const investee_organization = useSelector(state => getInvesteesOrganization(state));

    const dataType = {
        ONBOARDING: "Onboarding",
        ADD_ON_LIMIT: "ADD_ON_LIMIT"
    }

    const NEED_INFORMATION = "Need Information";
    const APPROVED_FOR_DEBT_SYNDICATION = "Approved For Debt Syndication";
    const SYNDICATE_APPROVE = "Syndicate Approve";
    const NEED_INFO = "Need Info";

    const onModerate = index => {
        const obj =  JSON.parse(JSON.stringify(monthlyData[index]));
        obj.dataType = 'monthly';
        setModerateRow && setModerateRow(obj);
    }

    const downloadFiles = (dueDate) => {
        if(dueDate === dataType.ONBOARDING || dueDate.includes(dataType.ADD_ON_LIMIT)) {
            const payload = {};
            if (isLimitAddOnRequested?.[investee_organization?._id] && dueDate.includes(dataType.ADD_ON_LIMIT)) {
                payload.is_limit_add_on_requested = true;
                payload.dueDate = dueDate;
            }
            updateLastModerated(payload, investee_organization?._id);
        }
        setIsDownloadingFile(dueDate);
        const remainingUrl = dueDate === dataType.ONBOARDING ? dueDate : dueDate.includes('ADD_ON_LIMIT') ? dueDate : moment(dueDate).format("YYYY-MM");
        const fileName = companyName.replace(/ /g, '_') + '_' + (dueDate === dataType.ONBOARDING ? "onboarding-files" : dueDate.includes('ADD_ON_LIMIT') ? dueDate : moment(dueDate).format("MMM-YYYY")) + '.zip';
        downloadFileUsingFetch(`admin/zip-stream-investee-docs/${investeeOrganizationId}?path=${remainingUrl}`, "get", {
            "responseType": 'blob'
        }, fileName, () => {
            setIsDownloadingFile(null);
        });
    }

    const hideInvoicingStep = (due_date,gstin) => {
        if (due_date === dataType.ONBOARDING) {
            const GSTINS = gstin ?? {};
            const gstinsCount = Object.keys(GSTINS).length;
            return gstinsCount <= 5;
        }
        return false;
    }

    return (
        monthlyData.map((data, index) => {
            const {
                due_date,
                is_data_finances_mis_complete: misCheck,
                is_data_invoicing_complete: invoicingCheck,
                is_fetch_bank_account_complete: bankStatementCheck,
                is_audited_financial_statement_complete: auditedFinancialCheck,
                mis,
                invoicing,
                gstin,
                bank_account,
                submission_date,
                status,
                is_quarterly,
                financial_statement,
                debtSchedule,
                cibil,
                all_director_consent_granted,
                title
            } = data;

            const isCategoryCheckTrue = category => {
                if (category === 'bank') {
                    if (due_date === dataType.ONBOARDING) {
                        const ADD_BANK = 'is_add_bank_account';
                        if (!bank_account || Object.keys(bank_account).length <= 0) return false;
                        let temp = true;
                        for (let accountNo in bank_account) {
                            if (accountNo !== ADD_BANK) {
                                if (!(bank_account[accountNo]?.is_doc_uploaded === true)) {
                                    temp = false;
                                    break;
                                }
                            }
                            else {
                                if (bank_account[ADD_BANK]) temp = false;
                            }
                        }
                        return temp;
                    }
                    return bankStatementCheck;
                }
                if (category === 'gst') {
                    if (due_date === dataType.ONBOARDING) {
                        const { cin_number, gstin_number, company_website } = onboardingData;
                        const isVerifyBusinessComplete = Boolean(cin_number && gstin_number && company_website);
                        if (!isVerifyBusinessComplete) return false;
                        const GSTs = data?.gstin ?? {};
                        if (isEmpty(GSTs)) return false;
                        else {
                            let gstsCount = Object.keys(GSTs)?.length;
                            let fetchedGstsCount = 0;
                            for (let gst in GSTs) if (GSTs[gst].user_id && GSTs[gst].password) fetchedGstsCount++;
                            if (gstsCount > 5 && fetchedGstsCount >= 1) return true;
                            else if (gstsCount <= 5 && fetchedGstsCount === gstsCount) return true;
                            else return false;
                        }
                    }
                    return null;
                }
                if (category === 'mis') {
                    if (due_date === dataType.ONBOARDING) {
                        return (mis?.is_doc_uploaded === true);
                    }
                    return misCheck;
                }
                if (category === 'invoicing') {
                    if (due_date === dataType.ONBOARDING) {
                        return (invoicing?.is_doc_uploaded === true);
                    }
                    return invoicingCheck;
                }
                if (category === 'auditedFS') {
                    if (due_date === dataType.ONBOARDING) {
                        return (financial_statement?.audited_financial_statement?.is_doc_uploaded === true);
                    }
                    return auditedFinancialCheck;
                }
                if (category === 'provisionalFS') {
                    if (due_date === dataType.ONBOARDING) {
                        return (financial_statement?.provisional_financial_statement?.is_doc_uploaded === true);
                    }
                    return null;
                }
                if (category === 'debt') {
                    if (due_date === dataType.ONBOARDING || due_date.includes(dataType.ADD_ON_LIMIT)) {
                        return (debtSchedule?.is_doc_uploaded || debtSchedule?.noDebtSchedules);
                    }
                    return null;
                }
                if(category === 'cibil') {
                    if (due_date === dataType.ONBOARDING) {
                        return all_director_consent_granted;
                    }
                    return null;
                }
            }

            const dirDetailCheck = () => {
                if (due_date !== dataType.ONBOARDING) {
                    return <img src="/assets/hifen-onboarding.svg" alt="" />
                }else{
                    return(
                        (!isCategoryCheckTrue('cibil') || isNil(cibil)) ? (
                            <img src="/assets/pending.svg" alt="" />
                        ) : (
                            <img src="/assets/done.svg" alt="" />
                        ) 
                    )                    
                }
            }

            const gstCheck = isCategoryCheckTrue('gst');

            const getRowTitle = () => {
                if (due_date === dataType.ONBOARDING) {
                    return title;
                }
                if (due_date?.includes('ADD_ON_LIMIT')) return due_date;
                return moment(due_date).format("DD MMM YYYY");
            }

            // const allChecked = misCheck && invoicingCheck && contractsCheck && bankStatementCheck;
            return (
                <tr key={data._id}>
                    <td className="pl-2">
                        <span className="color1">
                            {getRowTitle()}
                        </span>
                    </td>
                    <td>
                        {(isCategoryCheckTrue('mis')) ?
                                <img src="/assets/done.svg" alt="" />
                                :
                                <img src="/assets/pending.svg" alt="" />
                        }
                    </td>
                    <td>
                        {is_quarterly && !hideInvoicingStep(due_date,gstin)? (
                            isCategoryCheckTrue('invoicing') ?
                                <img src="/assets/done.svg" alt="" />
                                :
                                <img src="/assets/pending.svg" alt="" />)
                            :
                            <img src="/assets/hifen-onboarding.svg" alt="" />
                        }
                    </td>
                    <td>
                        {isCategoryCheckTrue('bank') ?
                            <img src="/assets/done.svg" alt="" />
                            :
                            <img src="/assets/pending.svg" alt="" />
                        }
                    </td>
                    <td>
                        {!!(isCategoryCheckTrue('auditedFS')) ?
                            <img src="/assets/done.svg" alt="" />
                            :
                            <img src="/assets/pending.svg" alt="" />
                        }
                    </td>
                    {/* <td>
                        {isNil(isCategoryCheckTrue('provisionalFS')) ?
                            <img src="/assets/hifen-onboarding.svg" alt="" /> 
                            :
                            isCategoryCheckTrue('provisionalFS') 
                            ? 
                            <img src="/assets/done.svg" alt="" />
                            :
                            <img src="/assets/pending.svg" alt="" />
                        }
                    </td> */}
                    <td>
                        {isNil(gstCheck) ? (
                            <img src="/assets/hifen-onboarding.svg" alt="" />
                        ) : gstCheck ? (
                            <img src="/assets/done.svg" alt="" />
                        ) : (
                            <img src="/assets/pending.svg" alt="" />
                        )}
                    </td>
                    <td>
                        {isNil(isCategoryCheckTrue('debt')) ?
                            <img src="/assets/hifen-onboarding.svg" alt="" />
                            :
                            isCategoryCheckTrue('debt') ?
                                <img src="/assets/done.svg" alt="" />                                
                                :
                                <img src="/assets/pending.svg" alt="" />
                        }                        
                    </td>
                    <td>
                        {dirDetailCheck()}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                        {submission_date ?
                            (submission_date === "-" ? submission_date : moment(submission_date).format("DD MMM YYYY"))
                            :
                            "-"
                        }
                    </td>
                    {/* <td><span className="submitted-bg">{status}</span></td> */}
                    <td>
                        <div className={`${status?.split(' ').join('-')}-bg`} style={{textAlign:'center'}}>
                            {status === NEED_INFORMATION ? NEED_INFO : status === APPROVED_FOR_DEBT_SYNDICATION ? SYNDICATE_APPROVE : status}
                        </div>
                    </td>
                    {(isUserHasEditAccess||isUserHasUWViewAccess) ? <td>
                        <span className="dflex-ac" onClick={() => onModerate(index)} data-toggle="modal" data-target="#Moderation-Comment">
                            <img className="mr-1" src="/assets/moderate.svg" alt="" />
                            <span className="color2 fw500">Moderate</span>
                        </span>
                    </td> :
                        <td>
                            <span className="dflex-ac" data-toggle="modal" data-target="#Moderation-Comment">
                                <span className="color2 fw500"></span>
                            </span>
                        </td>}
                    <td className='text-center'>
                        {isDownloadingFile && isDownloadingFile === due_date ?
                            <div id="loading-spinner">
                                <div className="spin-icon"></div>
                            </div>
                            :
                            <span onClick={() => downloadFiles(due_date)}>
                                <img src="/assets/download-dv.svg" alt="" />
                            </span>
                        }
                    </td>
                </tr>
            )
        })
    )
}
