import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styles from './AlertModal.module.scss';
function AlertModal(props) {
    const { title, show, textMsg, buttonName, actionMethod, handleClose } = props;
    const { modalTitleAlert, customModal, generateBtnCancel, generateBtnAction } = styles;
    return (
        <div>
            <Modal
                show={show}
                onHide={()=>handleClose()}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                animation={false}
                className={customModal}
            >
                <Modal.Header closeButton style={{ border: "none" ,height:"40px"}}>
                    <Modal.Title className={modalTitleAlert}>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ border: "none", height:"35px", fontSize: "14px" }}>
                    {textMsg}
                </Modal.Body>

                <Modal.Footer style={{ border: "none" }}>
                    <div
                        className={`generate-btn ${generateBtnCancel}`}
                        onClick={()=>handleClose()}
                    >
                        Cancel
                    </div>
                    <div
                        onClick={()=>actionMethod()}
                        className={`generate-btn ${generateBtnAction}`}
                    >
                        {buttonName}
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AlertModal;