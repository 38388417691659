import moment from 'moment';
import React from 'react';
import { showNotification } from '../../../../../../../../../../util/utility';
import styles from './DocExpiryDate.module.scss';

export default function DocExpiryDate({ docExpiryDate, expiryDate, setExpiryDate, checked, setChecked, setAreChangesDone }) {
    const { H21 } = styles;

    const handleCheckChange = () => {
        if (setAreChangesDone) setAreChangesDone(true);
        setChecked(!checked);
    }

    const handleDateChange = (e) => {
        if (Date.parse(new Date(e.target.value)) <= Date.parse(new Date())) {
            showNotification("Error", "Cannot set older expiry date");
            return;
        }
        setExpiryDate(e.target.value);
    }

    return (
        <div className={`dflex-ac mb-2 ${H21}`}>
            <input
                type="checkbox"
                className='mr-2'
                checked={checked}
                onChange={handleCheckChange}
            />
            <label>
                <span>Document Expiry Date:{' '}
                    {checked ? (
                        <input
                            type="date"
                            value={expiryDate ? expiryDate.split(" ")[0] : ""}
                            onChange={handleDateChange}
                        />
                    ) : (
                        <span>{docExpiryDate ? moment(docExpiryDate).format('DD MMM YYYY') : ''}</span>
                    )}
                </span>
            </label>
        </div>
    )
}
