import {DRAWER_ENUMS, getTdsStatusColors} from "../CustomerRequests.tsx";
import {Drawer} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import './OtherTicketDrawer.scss'
import RequestType from "../RequestType/RequestType.tsx";
import {showNotification} from "../../../../../util/utility.js";
import {GENERIC_ERROR, SHOW_NOTIFICATION, SHOW_NOTIFICATION_STATUS} from "../../../../../constants/enums.js";
import {
    fetchPresignedUrl,
    getSupportQueryData,
    updateSupportQuery
} from "../../../../../common/ApiHelpers.ts";
import {useSelector} from "react-redux";
import {getEmail} from "../../../../App/AppReducer.js";
import { isEmpty, isNil } from "lodash";
import moment from "moment";
import UploadCard from "../../../../../components/UploadCard/UploadCard.tsx";
import DragDropUpload from "../../../../../components/DragDropUpload/index.tsx";

interface OtherTicektDrawerProps {
    openDrawer: string,
    setOpenDrawer: React.Dispatch<React.SetStateAction<string>>
    claimData: any
    getClaimData: () => void
}

interface ViewOnlyData {
    investeeOrganizationId: string;
    submittedOn: string;
    lastActivityOn: string;
    documents: Document[];
    ticketDetails: TicketDetails;
    status: string;
    customerQueryId: string;
    investeeName: string;
    expectedResolutionDate: string;
}

interface TicketDetails {
    ticketSubType?: string;
    userComments?: [];
    adminComments?: [];
}

interface Document {
    documentName: string;
    docId: string;
    owner: string;
}

const OtherTicketDrawer = (props: OtherTicektDrawerProps) => {
    const emptyData: ViewOnlyData = {
        investeeOrganizationId: '',
        documents: [],
        submittedOn: '',
        lastActivityOn: '',
        status: '',
        customerQueryId: '',
        investeeName: '',
        expectedResolutionDate: '',
        ticketDetails: {},
    };
    const {openDrawer, setOpenDrawer, claimData} = props
    const [data, setData] = useState<ViewOnlyData>(emptyData)
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [adminComment, setAdminComment] = React.useState('');
    const email = useSelector(getEmail)
    const [files, setFiles] = React.useState<any>([]);
    const [userDocuments, setUserDocuments] = React.useState<any>([]);
    const getViewOnlyData = async (customerSupportQueryId: string) => {
        if(customerSupportQueryId == null) return;
        const res = await getSupportQueryData(customerSupportQueryId);
        if (res) {
            setData(res);
            setUserDocuments(res.documents.filter((document: Document) => document.owner === 'USER'))
        } else {
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Something went wrong');
        }
    };
    useEffect(() => {
        if (!isEmpty(claimData) && openDrawer === DRAWER_ENUMS.OTHER_TICKET) {
            getViewOnlyData(claimData.customerQueryId);
        }
    }, [claimData,openDrawer]);
    const downloadDocument = async (docId:string) => {
        const res = await fetchPresignedUrl(docId);
        if (res) {
            window.open(res.signedUrl, '_blank');
        } else {
            showNotification(SHOW_NOTIFICATION.ERROR, GENERIC_ERROR);
        }
    }

    useEffect(() => {
        if (!isEmpty(adminComment)) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [adminComment]);

    const handleTextChange = (event: any) => {
        setAdminComment(event.target.value);
    };

    const updateDocumentList = async (docId: string, fileName?: string,) => {
        let document = {
            docId: docId,
            documentName: fileName,
            owner: 'ADMIN',
        };
        //@ts-ignore
        setFiles((prev) => prev.concat(document));
        return true;
    };
    const deleteDocument = async (docId: string) => {
        setFiles(files.filter((document: any) => document.docId !== docId));
        return true;
    };

    const getFilesInProperFormat = () => {
        let tempFile: any = [];
        files.forEach((file: any) => {
            tempFile.push({
                doc_name: file.documentName,
                doc_link: file.docId,
            });
        });
        return tempFile;
    };

    const resolveTicket = async () => {
        setDisabled(true);
        const payload = {
            customerQueryId: data.customerQueryId,
            ticketDetails: {
                adminComments: [adminComment]
            },
            documents: files,
            status: 'RESOLVED',
            activityBy: email,
        };
        const res = await updateSupportQuery(payload);
        if (res) {
            setFiles([]);
            setAdminComment('');
            setOpenDrawer('');
            props.getClaimData();
        } else {
            showNotification(
                SHOW_NOTIFICATION_STATUS.ERROR,
                'Error in submitting request, please try again later!',
            );
            setDisabled(false);
        }
    }
    
    return (
        <Drawer
            anchor="right"
            open={openDrawer === DRAWER_ENUMS.OTHER_TICKET}
            ModalProps={
                {

                    style: {
                        zIndex: 100,
                    }
                }}
            PaperProps={{
                style: {
                    width: '37%',
                    zIndex: 100
                },
            }}
            disableEnforceFocus
        >
            <div className={'BalanceConfirmationWrapper'}>
                <div className={'DrawerHeading'}>
                    <div className={'HeadingText'}>
                        <div>Ticket Details</div>
                        <div className={'StatusBanner'}
                             style={{
                                 color: getTdsStatusColors(claimData.status).color,
                                 background: getTdsStatusColors(claimData.status).banner,
                             }}>
                            {claimData.status}
                        </div>
                    </div>
                    <div className={'CrossIcon'}>
                        <img onClick={() => setOpenDrawer('')} src="../../../../../assets/modal-cross.svg"
                             alt=""></img>
                    </div>
                </div>
                <div className={'QuestionContainer'}>
                    <div className={'DrawerBody'}>
                        <RequestType requestType={'Others'} showChangeText={false}/>
                        <div className={'detailsContainer'}>
                        <div className={'ViewOnlyTextContainer'}>
                            <p>
                                Ticket ID: <span>{data.customerQueryId}</span>
                            </p>
                            <p>
                                Submitted on:{' '}
                                <span>{moment(data.submittedOn).format('DD MMM YYYY')}</span>
                            </p>
                            {data.status === 'RESOLVED' && (
                                <p>
                                    Resolved on:{' '}
                                    <span>{moment(data.lastActivityOn).format('DD MMM YYYY')}</span>
                                </p>
                            )}
                            <p>
                                Company Name: <span>{data.investeeName}</span>
                            </p>
                            <p>
                                Subject: <span>{data.ticketDetails?.ticketSubType}</span>
                            </p>
                            <p>
                                Details: <span>{data.ticketDetails?.userComments}</span>
                            </p>
                        </div>
                        <p style={{ margin: '1rem 0rem 0.25rem' }}>Uploaded Document(s)</p>

                        {!isNil(
                            data?.documents?.find(
                                (document: Document) => document.owner === 'USER',
                            ),
                        ) ? (
                            <div className={'DrawdownsContainer'}>
                                <div className={'DrawDownListContainer'}>
                                {userDocuments?.map((document: any, index: any) => {
                                    return (
                                        <div key={index} className={ `DrawdownItem ${(userDocuments.length - 1) === index ? 'noBorderBottom' : ''}`} >
                                            <span
                                                onClick={() => downloadDocument(document.docId)}
                                            >
                                                {document.documentName}
                                            </span>
                                        </div>
                                    );
                                })}
                                </div>
                            </div>
                        ) : (
                            <span
                                style={{
                                    fontFamily: 'Gilroy-Medium, serif',
                                    color: '#7f7f7f',
                                    fontSize: '0.875rem',
                                }}
                            >
                                No Files Attached
                            </span>
                        )}
                    </div>
                        {data.status !== 'RESOLVED' && (
                        <div>
                            <p style={{ margin: '2rem 0rem 0rem 0rem' }}>Add Comments</p>
                            <div className="TextArea">
                                <textarea
                                    id="userComment"
                                    placeholder="add comments" 
                                    value={adminComment}
                                    onChange={handleTextChange}
                                    rows={6}
                                    cols={50}
                                ></textarea>
                            </div>
                        </div>
                        )}
                    </div>
                    { data.status === 'RESOLVED' && 
                        <><p style={{ margin: '2rem 0 0 0' }}>Added Comments</p>
                            <div className={'detailsContainer'} style={{ margin: '0.5rem 0rem 0rem 0rem'}}>
                                <div className={'ViewOnlyTextContainer'}>
                                    <p>
                                        <span>{data.ticketDetails?.adminComments}</span>
                                    </p>
                                </div>
                            </div></>}

                    { data.status === 'RESOLVED' && !isNil(
                            data?.documents?.find((document: Document) => document.owner === 'ADMIN'),
                        ) && <><p style={{ margin: '2rem 0 0.5rem 0' }}>Attached Document(s)</p>
                        <div className={'AttachmentContainer'}>
                            {data?.documents?.map((document, index) => {
                                if (document.owner === 'ADMIN') {
                                    return (
                                        <div key={index} className={'AttachmentItem'}>
                                            <span onClick={() => downloadDocument(document.docId)}>
                                                {document.documentName}
                                            </span>
                                        </div>
                                    );
                                }
                            })}
                        </div></>}

                    { data.status !== 'RESOLVED' && (<><p style={{ margin: '0' }}>Upload Supporting Document</p><p
                        style={{
                            color: '#979797',
                            fontSize: '0.625rem',
                            margin: '0 0 1rem 0',
                        }}
                    >
                        supported file formats: pdf
                    </p></>)}
                    { data.status !== 'RESOLVED' && 
                        <UploadCard
                            renderComponent={<DragDropUpload />}
                            category="customer_support_other"
                            isInvestor={false}
                            files={getFilesInProperFormat()}
                            allowMultiple={true}
                            onSuccess={(docId: string | number, fileName?: string) =>
                                updateDocumentList(docId.toString(), fileName)
                            }
                            isMnemosyneFlow={true}
                            disableUpload={true}
                            onDelete={(docId: string | number) => deleteDocument(docId.toString())}
                            noOfFiles={files.length + 1}
                            investeeOrganizationId={data.investeeOrganizationId}
                            fileTypeAllowed={['pdf']}
                        />}
                </div>
            </div>
            <div className={'TdsDrawerFooter'}>
                <div className={'FooterButtonsContainer'}>
                    <button
                        onClick={() => {
                            setOpenDrawer('');
                            setAdminComment('');
                            setFiles([]);
                            setData(emptyData);
                        }}
                        className={'FooterRejectBtn'}
                    >
                        Close
                    </button>
                    {claimData.status!=='RESOLVED'&&<button
                        onClick={() => {
                            resolveTicket()
                        }}
                        disabled={disabled}
                        className={'FooterSendReminderBtn'}
                    >
                        Resolve
                    </button>}
                </div>
            </div>
        </Drawer>
    )
}

export default OtherTicketDrawer

