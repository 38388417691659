import moment from 'moment';
import { useEffect, useState } from 'react';
import styles from './Details.module.scss'
import { useSelector } from 'react-redux';
import { getInvesteeProfileMtrics, getInvesteeOnboardingData,getCapitalRequested } from '../../../../../../AdminReducers';
import DetailsEdit from './components/DetailsEdit';
import _, { isEmpty, isNil, isNull, isUndefined } from "lodash";
import { convertArrayToEnum, convertToCR, priceFormat, showNotification } from '../../../../../../../../util/utility';
import { getCheckIfUserHasRelevantPermissions } from '../../../../../../../App/AppReducer';
import callApi from '../../../../../../../../util/apiCaller';
import { API_STATUS,CAPITAL_REQUESTED_FIELDS, SHOW_NOTIFICATION_STATUS } from '../../../../../../../../constants/enums';
import ComplianceDetails from './components/ComplianceDetails';
import { fetchKarzaFetchedStatusHelper, getCibilScoreHelper, refetchCibilScoreHelper, refetchKarzaDataHelper } from '../../../../../../../../common/ApiHelpers.ts';
import { DOC_SERVICE_RESPONSE, GENERIC_ERROR } from '../../../../../../../../constants/enums';

const mapRCIFOptions = {
    SearchEngine: "Search Engine(Google, Bing etc.)",
    SocialMedia: "Social Media(LinkedIn, Twitter etc.)",
    Recommended: "Recommended by friend or colleague",
    News: "News or Publication",
    Other: "Other"
}

export default function Details({
    investeeOrganization,
    onboardingData,
    investeeContractStats,
    isEditingCompany,
    setIsEditingCompany
}) {
    const { FetchDataContainer,InProgressText,FetchButtonEnabled,FetchButtonDisabled} = styles;
    const contractStatsRows = [
        {
            label: "Opening",
            td1: "₹ " + investeeContractStats?.opening_arr,
            td2: investeeContractStats?.opening_change + "%"
        },
        {
            label: "New",
            td1: "₹ " + investeeContractStats?.new_arr,
            td2: investeeContractStats?.new_change + "%"
        },
        {
            label: "Expansion",
            td1: "₹ " + investeeContractStats?.expansion_arr,
            td2: investeeContractStats?.expansion_change + "%"
        },
        {
            label: "Contraction",
            td1: "₹ " + investeeContractStats?.contraction_arr,
            td2: investeeContractStats?.contraction_change + "%"
        },
        {
            label: "Churn",
            td1: "₹ " + investeeContractStats?.churn_arr,
            td2: investeeContractStats?.churn_change + "%"
        },
        {
            label: "Closing",
            td1: "₹ " + investeeContractStats?.closing_arr,
            td2: investeeContractStats?.closing_change + "%"
        }
    ];
    const extraMetricsRows = [
        {
            label: "Burn as a % of revenue",
            td1: "₹ " + investeeOrganization?.burn_as_perc_revenue
        },
        {
            label: "Customer Diversity (Locality)",
            td1: "₹ " + investeeOrganization?.customer_diversity_local
        },
        {
            label: "Customer Diversity (Client concentration)",
            td1: "₹ " + investeeOrganization?.customer_diversity_cc
        },
        {
            label: "NTM financing outflow/cash in bank",
            td1: "₹ " + investeeOrganization?.ntm_fin_outflow
        },
        {
            label: "Receivables cycle",
            td1: "₹ " + investeeOrganization?.receivables_cycle
        },
        {
            label: "Cash Balance@Evaluation",
            td1: "₹ " + investeeOrganization?.debt_amount
        },
        {
            label: "Amount of debt",
            td1: "₹ " + investeeOrganization?.cash_bal_eval
        },
        {
            label: "D:E",
            td1: "₹ " + investeeOrganization?.de_ratio
        },
        {
            label: "Remarks",
            td1: "₹ " + investeeOrganization?.remarks
        }
    ];

    const detailsToRender = {
        "SAAS METRICS": [
            {
                label: "ARR Growth",
                value: (investeeOrganization?.arr_growth ?? '-') + '%'
            },
            {
                label: "Net $ Retention",
                value: (investeeOrganization?.net_dollar_retention ?? '-') + '%'
            },
            {
                label: "ARPA",
                value: "₹ " + (investeeOrganization?.arpa ?? '-')
            },
            {
                label: "Revenue Churn",
                value: (investeeOrganization?.revenue_churn ?? '-') + '%'
            },
            {
                label: "Customer Churn",
                value: (investeeOrganization?.customer_churn ?? '-') + '%'
            }
        ],
        "BUSINESS METRICS": [
            {
                label: "LTV / CAC",
                value: (investeeOrganization?.ltv_cac ?? '-')
            },
            {
                label: "CAC Payback",
                value: (investeeOrganization?.cac_payback ?? '-') + " months"
            }
        ],
        "FINANCIAL METRICS": [
            {
                label: "Gross Margin",
                value: (investeeOrganization?.gross_margin ?? '-') + '%'
            },
            {
                label: "EBITDA",
                value: "₹ " + (investeeOrganization?.ebitda ?? '-')
            },
            {
                label: "Cashburn / month",
                value: "₹ " + (investeeOrganization?.cashburn_permonth ?? '-')
            },
            {
                label: "Runway",
                value: (investeeOrganization?.runway ?? '-') + " months"
            }
        ]
    };

    const fundingCalcAmount = (amount) => {
        if (amount >= 100000) {
            if (amount >= 10000000) {
                return `${(Math.trunc((amount / 10000000) * 100) / 100).toFixed(2)} Cr`;
            } else {
                return `${(Math.trunc((amount / 100000) * 100) / 100).toFixed(2)} L`;
            }
        } else if (amount < 0) return 0;
        else {
            return priceFormat(Math.floor(amount));
        }
    };

    const salaryBreakupData = [
        {
            label: "Sales Marketing",
            name: 'salesMarketing',
            value: investeeOrganization?.salaryBreakup?.salesMarketing || 0
        },
        {
            label: "Customer Operations",
            name: 'customerOperations',
            value: investeeOrganization?.salaryBreakup?.customerOperations || 0
        },
        {
            label: "Others",
            name: 'others',
            value: investeeOrganization?.salaryBreakup?.others || 100
        }
    ]

    const [investeeDetails, setInvesteeDetails] = useState(null);
    const [directorDetails, setDirectorDetails] = useState(null);
    const [updateDateDir, setUpdateDateDir] = useState(null);
    const [updateDateChar, setUpdateDateChar] = useState(null);
    const [chargesDetails,setChargesDetails] = useState(null);
    const [disableCibilFetch,setDisableCibilFetch] = useState(false)
    const [disableCibilReportDownload,setDisableCibilReportDownload] = useState(false)
    const [founderDetails, setFounderDetails] = useState(null);
    const [isFetchDataEnabled,setIsFetchDataEnabled] = useState(false);
    const [isKarzaFetchInProgress,setIsKarzaFetchInProgress] = useState(false);
    const [complianceDetails, setComplianceDetails] = useState(null);
    const investeeProfileMetrics = useSelector(state => getInvesteeProfileMtrics(state));
    const investeeOnboardingData = useSelector(state => getInvesteeOnboardingData(state));
    const capitalRequested = useSelector(state=>getCapitalRequested(state));
    const isUserHasEditAccess = useSelector(state => getCheckIfUserHasRelevantPermissions(state, ["app:edit"]));
    const isUserHasUWViewAccess = useSelector((state) => getCheckIfUserHasRelevantPermissions(state, ["underwriting:edit"]));
    const [cibilMapping,setCibilMapping] = useState(null)
    const compSubscription = investeeOnboardingData?.subscription_manager_funding_cal?.split(",").filter(option => option !== "Other")
    const paymentPlatforms = investeeOnboardingData?.payment_platforms_funding_cal?.split(",").filter(option => option !== "Other")
    const financeManager = investeeOnboardingData?.finance_managers_funding_cal?.split(",").filter(option => option !== "Other")
    const sourceClubInfo = investeeOnboardingData?.recur_club_info_funding_cal?.split(",").filter(option => option !== "Other").map(option => mapRCIFOptions[option] ? mapRCIFOptions[option] : option)
    const estCapital = (investeeOnboardingData?.recur_funding * 10000000) - ((investeeOnboardingData?.recur_funding * 10000000) % 100000)
    const investeeOrgId = investeeOnboardingData?.investee_organization_id;

    const {
        name,
        display_name,
        company_sector,
        company_sub_sector,
        founded_in,
        funding,
        authorized_designation,
        authorized_signature,
        profile_image,
        company_overview,
        website_url,
        pan_card,
        company_cin,
        gstin_number,
        tan_number,
        address,
        documents,
        last_details_updated,
        salaryBreakup,
        latestKarzaSuccessfulFetchDatetime
    } = investeeOrganization;

    useEffect(() => {
        const obj = { ...investeeDetails };
        obj.name = name;
        obj.display_name = display_name;
        obj.company_sector = company_sector;
        obj.company_sub_sector = company_sub_sector;
        obj.founded_in = founded_in;
        obj.funding = funding;
        obj.authorized_designation = authorized_designation;
        obj.authorized_signature = authorized_signature;
        obj.profile_image = profile_image;
        obj.company_overview = company_overview;
        obj.website_url = website_url;
        obj.pan_card = pan_card;
        obj.company_cin = company_cin;
        obj.gstin_number = gstin_number;
        obj.address = address;
        obj.documents = documents;
        obj.last_details_updated = last_details_updated;
        obj.salaryBreakup = salaryBreakup;
        setInvesteeDetails(obj);
        getKarzaDataFetchingStatus();
    }, [investeeOrganization]);

    useEffect(() => {
        if(!directorDetails&&!chargesDetails){
            fetchDirectorDetails()
        }
        else{
            const directorDates = latestKarzaSuccessfulFetchDatetime?.directorDetailsFetchDatetime ?? directorDetails?.[0]?.updated_at ??  null;
            const chargesDates = latestKarzaSuccessfulFetchDatetime?.chargesDetailsFetchDatetime ?? chargesDetails?.[0]?.updated_at ?? null;
            setUpdateDateDir(directorDates ? new Date(directorDates)?.toLocaleDateString(undefined,{ year: "numeric", month: "long", day: "numeric" }) : null)
            setUpdateDateChar(chargesDates ? new Date(chargesDates)?.toLocaleDateString(undefined,{ year: "numeric", month: "long", day: "numeric" }) : null)
        }
    }, [chargesDetails])

    useEffect(() => {
        fetchFounderDetails();
        fetchComplianceDetails();
        //eslint-disable-next-line
    }, [])

    const fetchDirectorDetails = () => {
        callApi('admin/company_charges/company_users/get_director_charges_info', 'post', {
            investee_organization_id: investeeOnboardingData?.investee_organization_id
        }).then((res) => {
            if (res.status === API_STATUS.SUCCESS) {
                setDirectorDetails(res.data?.director_details);
                setChargesDetails(res.data?.charges_details)
            }
            else {
                showNotification(res.error ?? 'Some Error Occured', 'error');
            }
        })
    }

    const fetchFounderDetails = () => {
        callApi(`organization/founder-details?orgId=${investeeOrgId}`, 'get', false, false, false, true)
            .then((res) => {
                if(res.statusCode === 200) {
                    const data = res?.data?.dataList;
                    setFounderDetails(data ?? []);
                }
            })
            .catch((err) => console.error(err))
    }

    const fetchComplianceDetails = () => {
        callApi(`organization/compliance-details?orgId=${investeeOrgId}`, 'get', false, false, false, true)
            .then((res) => {
                let data = res.data;
                const rows = data?.rows ?? [];
                const headers = data?.headers ?? [];
                if(headers?.length > 1) {
                    data.rows = rows.map((rowdata) => {
                        return {
                            ...rowdata,
                            dataMap: convertArrayToEnum(rowdata?.data ?? [], 'period')
                        }
                    })
                    setComplianceDetails(data);
                }
            }).catch(err => console.error(err))
    }

    const fetchComplianceDataFromKarza = () => {
        callApi(`organization/fetch-complaince-data-karza?orgId=${investeeOrgId}`, 'get', false, false, false, true)
            .then((res) => {
                if (res.statusCode === 200) {
                    showNotification("Success", "Intiaited compliance data fetch from Karza")
                } else {
                    showNotification("Error", "Something Went Wrong")
                }
            })
            .catch((err) => console.error(err));
    }
    const getKarzaDataFetchingStatus = async () => {
        const payload = {
            investee_organization_id: investeeOrgId,
        }
        const res = await fetchKarzaFetchedStatusHelper(payload);
        if (res?.data && res?.data?.karzaFetchStatus) {
            const karzaFetchStatus = res?.data?.karzaFetchStatus;
            if (karzaFetchStatus?.karzaDetailsFetching) {
                setIsKarzaFetchInProgress(true);
            } else setIsKarzaFetchInProgress(false);
            if (karzaFetchStatus?.needKarzaDetailsFetch) {
                setIsFetchDataEnabled(true);
            } else setIsFetchDataEnabled(false)
        }
    }
    const refetchKarzaData = async () => {
        if (!isFetchDataEnabled) return;
        setIsKarzaFetchInProgress(true);
        setIsFetchDataEnabled(false);
        const payload = {
            investee_organization_id: investeeOrgId,
        }
        const res = await refetchKarzaDataHelper(payload)
        await getKarzaDataFetchingStatus();
        if(res?.data?.message){
            showNotification((res?.data?.status ==="error")?SHOW_NOTIFICATION_STATUS.ERROR:SHOW_NOTIFICATION_STATUS.SUCCESS, res?.data?.message);
        }
    }

    const getExperienceDates = (startdate, enddate) => {
        if (startdate && enddate) {
            return `(${moment(startdate).format("MMM'YYYY")} - ${moment(enddate).format("MMM'YYYY")})`
        } else if (startdate && !enddate) {
            return `(${moment(startdate).format("MMM'YYYY")} - present)`
        } else return "";
    }
    const refetchCibil=async(dirName)=>{
        setDisableCibilFetch(true)
        const payload = {
            investeeOrganizationId: investeeOrgId,
            directorName: dirName
        }
        const res = await refetchCibilScoreHelper(payload)
        if(res?.responseData?.responseCode === 20){
            fetchCibil()
            setDisableCibilFetch(false)
        }
        else{
            setDisableCibilFetch(false)
            showNotification('error',res?.responseData?.responseMessage??'Some Error Occured')
        }
    }
    const downloadCibilReport=async(docId)=>{
        setDisableCibilReportDownload(true)
        if(isNull(docId) || isUndefined(docId)) {
            setDisableCibilReportDownload(false)
            showNotification('error','Cibil report not available')
            return};
        const res = await callApi(`file/downloadFilePresigned?fileId=${docId}`, "post", {}, {}, false, false, false, false, false, true);
        if (res?.responseData?.responseCode === DOC_SERVICE_RESPONSE.SUCCESS) {
            let url = res?.signedUrl;
            window.open(url);
            setDisableCibilReportDownload(false)
        }
        else {
            const message = res.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification('error',res?.responseData?.responseMessage??'Some Error Occured')
            setDisableCibilReportDownload(false)
        }
    }
    const fetchCibil=async()=>{
        if(!directorDetails) return
        let directorNames = directorDetails.filter((item)=> !isEmpty(item.name)).map((item)=>item.name);
        if(directorNames.length === 0) return;
        const payload = {
            investeeOrganizationId: investeeOrgId,
            directorNames: directorNames.join(',')
        }
        const res = await getCibilScoreHelper(payload)
        if(res?.responseData?.responseCode === 20){
            setCibilMapping(res?.data)
        }
        else{
            showNotification('error',res?.responseData?.responseMessage)
        }
    }
    useEffect(()=>{
        if(!isEmpty(directorDetails))fetchCibil()
    },[directorDetails])
    if (isEditingCompany) {
        return (
            <DetailsEdit
                investeeOrgId={investeeOrganization._id}
                investeeProfileMetrics={investeeProfileMetrics}
                contractStatsRows={contractStatsRows}
                extraMetricsRows={extraMetricsRows}
                detailsToRender={detailsToRender}
                setIsEditingCompany={setIsEditingCompany}
                investeeDetails={investeeDetails}
                setInvesteeDetails={setInvesteeDetails}
                directorDetails={directorDetails}
                chargesDetails={chargesDetails}
                updateDateDir={updateDateDir}
                updateDateChar={updateDateChar}
                salaryBreakupData={salaryBreakupData}
                originalInvesteeDetails={investeeOrganization}
            />
        )
    }

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap align-items-center company-row1 p-4">
                <div className='d-flex align-items-center'>
                    <span className="fs21 mr-4 fw600">Details</span>
                </div>
                <div className="d-flex align-items-center" >
                    {(isUserHasEditAccess || isUserHasUWViewAccess) &&
                        <>
                            <p className="fs10 fw300 mb-0 mr-4">
                                <b>Last edited on:&nbsp;&nbsp;</b>
                                {last_details_updated && (
                                    <span>
                                        {moment(last_details_updated.updated_at).format(
                                            "h:mm:ss, DD MMMM YYYY"
                                        )}{" "}
                                        by {last_details_updated.updated_by}
                                    </span>
                                )}
                            </p>
                            <button className="btn5 d-flex align-items-center" onClick={() => setIsEditingCompany(true)}>
                                <img
                                    src="/assets/create.png"
                                    alt="" style={{ width: '11px' }}
                                    className='mr-1'
                                />
                                Edit Company
                            </button>
                        </>}
                </div>
            </div>
            <div className="row m-0" style={{ padding: "0 1.5rem" }}>
                <div className="col-lg-5">
                    <div className="row">
                        <div className="col-lg-9">
                            <p className="cmp-row2-head">Company Name</p>
                            <input
                                disabled
                                value={name
                                    ? name
                                    : onboardingData.registered_company_name
                                }
                                className="col-lg-9 cmp-row2-field"
                                type="text"
                                id="comp-name"
                                name="comp-name"
                            />
                            <br></br>
                            <p className="cmp-row2-head asterisk">Display Name</p>
                            <input
                                disabled
                                value={display_name}
                                className="col-lg-9 cmp-row2-field"
                                type="text"
                                id="comp-name"
                                name="comp-name"
                            />
                            <br></br>
                            <p className="cmp-row2-head asterisk">Company Sector</p>
                            <input
                                disabled
                                value={company_sector}
                                className="col-lg-9 cmp-row2-field"
                                type="text"
                                id="comp-name"
                                name="comp-name"
                            />
                            <br></br>
                            <p className="cmp-row2-head">Company Sub Sector</p>
                            <input
                                disabled
                                value={company_sub_sector}
                                className="col-lg-9 cmp-row2-field"
                                type="text"
                                id="comp-name"
                                name="comp-name"
                            />
                            <br></br>
                        </div>
                        <div className="col-lg-3" style={{ textAlign: "right" }}>
                            <img
                                className="cmp-logo"
                                src={profile_image
                                    ? profile_image
                                    : "/assets/company-logo.svg"
                                }
                                alt="profile_image"
                            ></img>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-lg-6">
                            <p className="cmp-row2-head asterisk">Founded</p>
                            <input
                                disabled
                                value={founded_in ? moment(founded_in).format("DD MMMM YYYY") : "NA"}
                                className="col-lg-6 cmp-row2-field"
                                type="text"
                                id="comp-name"
                                name="comp-name"
                            />
                            <br></br>
                        </div>
                        <div
                            className="col-lg-6"
                            style={{ padding: "0px 15px 0px 0px" }}
                        >
                            <p className="cmp-row2-head asterisk">Funding</p>
                            <input
                                disabled
                                value={funding}
                                className="col-lg-6 cmp-row2-field"
                                type="text"
                                id="comp-name"
                                name="comp-name"
                            />
                            <br></br>
                        </div>
                    </div>
                    <div>
                        <div>
                            <p className="cmp-row2-head">Authorized Designation</p>

                            <input
                                disabled
                                value={authorized_designation}
                                className="col-lg-9 cmp-row2-field"
                                type="text"
                                id="comp-name"
                                name="comp-name"
                            />
                        </div>
                        <div>
                            <p className="cmp-row2-head">Authorized Signature</p>
                            <img
                                className=""
                                src={authorized_signature
                                    ? authorized_signature
                                    : "/assets/company-logo.svg"
                                }
                                alt="authorized_signature"
                            ></img>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div>
                        <p className="cmp-row2-head asterisk">Company Overview</p>
                        <p className="cmp-row2-field cmp-overview">
                            {company_overview}
                        </p>
                    </div>
                    <div>
                        <p className="cmp-row2-head asterisk">Website</p>
                        <input
                            disabled
                            value={website_url
                                ? website_url
                                : onboardingData?.company_website
                            }
                            className="col-lg-9 cmp-row2-field"
                            type="text"
                            id="comp-name"
                            name="comp-name"
                        />
                    </div>
                    <div>
                        <p className="cmp-row2-head asterisk">Pancard</p>
                        <input
                            disabled
                            value={pan_card
                                ? pan_card
                                : onboardingData?.pan_number
                            }
                            className="col-lg-9 cmp-row2-field"
                            type="text"
                            id="comp-name"
                            name="comp-name"
                        />
                    </div>
                    <div>
                        <p className="cmp-row2-head asterisk">CIN/LLPIN</p>
                        <input
                            disabled
                            value={company_cin
                                ? company_cin
                                : onboardingData?.cin_number
                            }
                            className="col-lg-9 cmp-row2-field"
                            type="text"
                            id="comp-name"
                            name="comp-name"
                        />
                    </div>
                    <div>
                        <p className="cmp-row2-head">GST</p>
                        <input
                            disabled
                            value={gstin_number
                                ? gstin_number
                                : onboardingData?.gstin_number
                            }
                            className="col-lg-9 cmp-row2-field"
                            type="text"
                            id="comp-name"
                            name="comp-name"
                        />
                    </div>
                    <div>
                        <p className="cmp-row2-head">Tan Number</p>
                        <input
                            disabled
                            value={tan_number
                                ?? "-"
                            }
                            className="col-lg-9 cmp-row2-field"
                            type="text"
                            id="comp-name"
                            name="comp-name"
                        />
                    </div>
                    <div>
                        <p className="cmp-row2-head asterisk">Address</p>
                        <input
                            disabled
                            value={address
                                ? address
                                : onboardingData?.current_company_address
                            }
                            className="cmp-row2-field company-address"
                            type="text"
                            id="comp-name"
                            name="comp-name"
                        />
                        <br></br>
                    </div>
                    <div className='mb-2'>
                        <p className="cmp-row2-head">
                            Additional Documents (for Investors)
                        </p>
                        <div className="cmp-row2-table">
                            <table className="table company-table pay-schedule-table">
                                <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            className="cmp-tbl-head"
                                            style={{ opacity: "0.27" }}
                                        >
                                            FILE NAME
                                        </th>
                                        <th scope="col" className="cmp-tbl-head">
                                            DATE ADDDED
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {documents && documents.map(
                                        (item, index) => (
                                            <tr key={index} className="company-addition-doc">
                                                <td className="company-file-name">
                                                    <a href={item.file_url} target={`_blank`}>
                                                        {item.file_name}
                                                    </a>
                                                </td>
                                                <td>
                                                    {moment(new Date(item.created_at)).format(
                                                        "DD/MM/YYYY"
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="mb-4">
                    <p className="d-flex justify-content-between fs12">
                        <span className='fs16'>{CAPITAL_REQUESTED_FIELDS.CAPITAL_REQUESTED}</span>
                        <span className='color4 fw300'>DATE SUBMITTED : {(capitalRequested?.date_submitted &&(capitalRequested?.date_submitted!=="")) ? moment(capitalRequested?.date_submitted).format("DD MMM YYYY") : "N/A"}
</span>
                        </p>
                        {/* <p className="big-fonts">Capital Requested</p> */}
                        <div className="border1">
                             <div className='dflex-ac bb2 bb1 p-2'>
                                        <span className='w-50 fs14'><b>{CAPITAL_REQUESTED_FIELDS.EXPECTED_REVENUE}</b></span>
                                        <span className='w-50 ml-1 fs12 fw300'> {(capitalRequested?.exp_revenue && capitalRequested?.exp_revenue !== "") ?`${(capitalRequested?.exp_revenue)?.toFixed(2)} cr` :"-"}</span>
                             </div>
                             <div className='dflex-ac bb2 bb1 p-2'>
                                        <span className='w-50 fs14'><b>{CAPITAL_REQUESTED_FIELDS.CAPITAL_EXPECTATION}</b></span>
                                        <span className='w-50 ml-1 fs12 fw300'> {(capitalRequested?.capital_expectation &&capitalRequested?.capital_expectation!=="" )? `${(convertToCR(capitalRequested?.capital_expectation ))?.toFixed(2)} cr`:" _"  }</span>
                             </div>
                             <div className='dflex-ac bb2 bb1 p-2'>
                                        <span className='w-50 fs14'><b>{CAPITAL_REQUESTED_FIELDS.TENTATIVE_TIMELINE}</b></span>
                                        <span className='w-50 ml-1 fs12 fw300'> {(capitalRequested?.tentative_timeline&& capitalRequested?.tentative_timeline!=="" ) ? capitalRequested?.tentative_timeline:"-"}</span>
                             </div>
                    </div>
                    </div>
                    <p className="d-flex justify-content-between fs12">
                        <span className=''>Funding Calculator</span>
                        <span className='color4 fw300'>DATE SUBMITTED : {moment(investeeOnboardingData?.updated_at).format("DD MMM YYYY")}</span>
                    </p>
                    <div className="">
                        <div className='border1'>
                            <div className='dflex-ac bb2 p-2 color4 fw500'>
                                <span className='w-50 fs14'>QUESTIONS</span>
                                <span className='w-50 fs12'>ANSWERS</span>
                            </div>
                            <div className='dflex-ac bb1 p-2'>
                                <span className='w-50 fs14'><b>ARR</b></span>
                                <span className='w-50 fs12 fw300'>{!investeeOnboardingData?.arr_funding_cal ? "_" : `${(investeeOnboardingData?.arr_funding_cal)?.toFixed(2)} Cr`}</span>
                            </div>
                            <div className='dflex-ac bb1 p-2'>
                                <span className='w-50 fs14'><b>Annual Growth Rate</b></span>
                                <span className='w-50 fs12 fw300'>{!investeeOnboardingData?.annual_revenue_growth_rate_funding_cal ? "_" : `${(investeeOnboardingData?.annual_revenue_growth_rate_funding_cal)?.toFixed(2)}%`}</span>
                            </div>
                            <div className='dflex-ac bb1 p-2'>
                                <span className='w-50 fs14'><b>EBITDA Margin</b></span>
                                <span className='w-50 fs12 fw300'>{investeeOnboardingData?.ebitda_margin_funding_cal === -100 ? "_" : `${(investeeOnboardingData?.ebitda_margin_funding_cal)?.toFixed(2)}%`}</span>
                            </div>
                            <div className='dflex-ac bb1 p-2'>
                                <span className='w-50 fs14'><b>Cash in Bank</b></span>
                                <span className='w-50 fs12 fw300'>{!investeeOnboardingData?.cib_funding_cal ? "_" : `${(investeeOnboardingData?.cib_funding_cal)?.toFixed(2)} Cr`}</span>
                            </div>
                            <div className='dflex-ac bb1 p-2'>
                                <span className='w-50 fs14'><b>Secured Debt</b></span>
                                <span className='w-50 fs12 fw300'>{investeeOnboardingData?.existing_secure_debt_funding_cal ? "Yes" : "No"}</span>
                            </div>
                            <div className='dflex-ac bb1 p-2'>
                                <span className='w-50 fs14'><b>Sector</b></span>
                                <span className='w-50 fs12 fw300'>{investeeOnboardingData?.sector_funding_cal}</span>
                            </div>
                            <div className='dflex-ac bb1 p-2'>
                                <span className='w-50 fs14'><b>Subscription</b></span>
                                <span className='w-50 fs12 fw300'>{_.join(compSubscription, ', ')}</span>
                            </div>
                            <div className='dflex-ac bb1 p-2'>
                                <span className='w-50 fs14'><b>Payments Platform</b></span>
                                <span className='w-50 fs12 fw300'>{_.join(paymentPlatforms, ', ')}</span>
                            </div>
                            <div className='dflex-ac bb1 p-2'>
                                <span className='w-50 fs14'><b>Finance Manager</b></span>
                                <span className='w-50 fs12 fw300'>{_.join(financeManager, ', ')}</span>
                            </div>
                            <div className='dflex-ac bb1 p-2'>
                                <span className='w-50 fs14'><b>Source</b></span>
                                <span className='w-50 fs12 fw300'>{_.join(sourceClubInfo, ', ')}</span>
                            </div>
                            <div className='dflex-ac p-2'>
                                <span className='w-50 fs14'><b>Est. Capital</b></span>
                                <span className='w-50 fs12 fw300'>{(estCapital === 0 || !estCapital) ? '' : `₹ ${priceFormat(estCapital)}`}</span>
                            </div>
                        </div>
                    </div>


                    <div className="mt-4">
                        <p className="big-fonts">Salary Breakup</p>
                        <div className="border1">
                            {salaryBreakupData.map((data) => {
                                return (
                                    <div className='dflex-ac bb2 bb1 p-2'>
                                        <span className='w-50 fs14'><b>{data.label}</b></span>
                                        <span className='w-50 fs12 fw300'>{!isNil(data.value) ? data.value : "_"}</span>
                                    </div>
                                )
                            })}

                        </div>
                    </div>

                </div>
            </div>
            <div className="page-head-bottom-border"></div>
            <div className='p-5'>
                <div className={FetchDataContainer}>
                    <div style={{ textAlign: "left" }}>Official Details</div>
                    <div style={{ textAlign: "right" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {(isKarzaFetchInProgress) && <div className={InProgressText}>
                                Data fetch in progress
                            </div>}
                            <button className={(isFetchDataEnabled) ? FetchButtonEnabled : FetchButtonDisabled} onClick={() => refetchKarzaData()} disabled={!isFetchDataEnabled}>
                                <img
                                    src="https://recur-club-assets.s3.ap-south-1.amazonaws.com/fetch.svg"
                                    alt=""
                                    style={{ width: '16px' }}
                                    className='mr-1'
                                />
                                Fetch Data
                            </button>
                        </div>
                    </div>
                </div>
                <div className='m-2 director-details'>
                    Director Details
                    <p className='cmp-row2-head1'>{updateDateDir && `Last Updated on: ${updateDateDir}`}</p>
                </div>
                <div className='row table-head'>
                    <table className='table team'>
                        <thead className='team-head order-table'>
                            <tr> 
                                <th>STATUS</th>
                                <th>DIRECTOR NAME</th>
                                <th>DATE OF BIRTH</th>
                                <th>DESIGNATION</th>
                                <th>PAN</th>
                                <th>GENDER</th>
                                <th>DIN NO.</th>
                                <th>APPOINTMENT DATE</th>
                                <th>ADDRESS</th>
                                <th style={{whiteSpace:'nowrap'}}>CREDIT SCORE</th>
                                <th style={{whiteSpace:'nowrap'}}>A/C WITH DPD’S</th>
                            </tr>
                        </thead>
                        <tbody className='noDataInTable'>
                            {updateDateDir && directorDetails && directorDetails.length === 0 &&
                        <tr>
                            <td  
                            colSpan={12}style={{textAlign: 'center'}} >No Directors Fetched</td>
                        </tr>}
                        </tbody>
                        <tbody className='director-heading'>
                            {directorDetails && directorDetails.map((item, index) => {
                                return (
                                    <>
                                        {(item?.is_deleted) ? <tr key={index} className='table-content'>
                                            <td style={{ color: "#BCBCBC" }}>Former</td>
                                            <td style={{ whiteSpace: 'nowrap', color: "#BCBCBC" }}>{item.name ?? '-'}</td>
                                            <td style={{ color: "#BCBCBC" }}>{item.dob ?? '-'}</td>
                                            <td style={{ color: "#BCBCBC" }}>{item?.type ?? '-'}</td>
                                            <td style={{ color: "#BCBCBC" }}>{item.pan?.[0] ?? '-'}</td>
                                            <td style={{ color: "#BCBCBC" }}>{item.gender ?? '-'}</td>
                                            <td style={{ color: "#BCBCBC" }}>{item.din ?? '-'}</td>
                                            <td style={{ color: "#BCBCBC" }}>{item.tenure_begin_date ?? '-'}</td>
                                            <td style={{ width: '30%', color: "#BCBCBC" }}>{item.address ?? '-'}</td>
                                            <td style={{ color: "#BCBCBC" }}>{cibilMapping?.[item.name]?.cibilScore ?? '-'}</td>
                                            <td style={{ color: "#BCBCBC" }}>
                                                <div className="dflex align-items-center justify-content-space-between">
                                                    {cibilMapping?.[item.name]?.countDPD ?? '-'}
                                                    <img src='https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/fetchGrey.svg' alt='refetch' className='ml-2' />
                                                    <img src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/downloadGrey.svg" alt='dowload' className='img-cibil-download' />
                                                </div>
                                                <p className="last-updated" style={{ color: "#BCBCBC" }}> Last updated: {cibilMapping?.[item.name]?.lastUpdatedAt ?? ' -'}</p>

                                            </td>

                                        </tr> : (
                                            <tr key={index} className='table-content'>
                                                <td>Current</td>
                                                <td style={{ whiteSpace: 'nowrap' }}>{item.name ?? '-'}</td>
                                                <td>{item.dob ?? '-'}</td>
                                                <td>{item?.type ?? '-'}</td>
                                                <td>{item.pan?.[0] ?? '-'}</td>
                                                <td>{item.gender ?? '-'}</td>
                                                <td>{item.din ?? '-'}</td>
                                                <td>{item.tenure_begin_date ?? '-'}</td>
                                                <td style={{ width: '30%' }}>{item.address ?? '-'}</td>
                                                <td>{cibilMapping?.[item.name]?.cibilScore ?? '-'}</td>
                                                <td>
                                                    <div className="dflex align-items-center justify-content-space-between">
                                                        {cibilMapping?.[item.name]?.countDPD ?? '-'}
                                                        <img onClick={() => refetchCibil(item.name)} style={disableCibilFetch ? { cursor: 'not-allowed' } : { cursor: 'pointer' }} src='https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/refetchCibilBlue.svg' alt='refetch' className='ml-2' />
                                                        <img onClick={() => downloadCibilReport(cibilMapping?.[item.name]?.docId)} style={disableCibilReportDownload || (!cibilMapping?.[item.name]?.docId) ? { cursor: 'not-allowed' } : { cursor: 'pointer' }} src="/assets/download-dv.svg" alt='dowload' className='img-cibil-download' />
                                                    </div>
                                                    <p className="last-updated"> Last updated: {cibilMapping?.[item.name]?.lastUpdatedAt ?? ' -'}</p>

                                                </td>

                                            </tr>
                                        )
                                        }
                                    </>
                                )
                            })}
                            
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='px-5'>
                <div className='mx-2 director-details'>
                    Charges Details
                    <p className='cmp-row2-head1'>{updateDateChar && `Last Updated On: ${updateDateChar}`}</p>
                </div>
                <div className='mb-5 row table-head'>
                    <table className='table team'>
                        <thead className='team-head order-table wtspc'>
                            <tr className='director-heading'>
                                <th>STATUS</th>
                                <th>CHARGE HOLDER NAME</th>
                                <th>AMOUNT</th>
                                <th>ASSET UNDER CHARGE</th>
                                <th>CREATION DATE</th>
                                <th>MODIFICATION DATE</th>
                                <th>SATISFACTION DATE</th>
                            </tr>
                        </thead>
                        <tbody className='noDataInTable'>
                            {updateDateChar && chargesDetails && chargesDetails.length === 0 &&
                        <tr>
                            <td  
                            colSpan={10}style={{textAlign: 'center'}} >No Charges Incurred</td>
                        </tr>}
                        </tbody>
                        <tbody className='director-heading'>
                            {chargesDetails && chargesDetails.map((item, index) => {
                                return (
                                    <>
                                        <tr key={index} className='table-content'>
                                            <td>{item?.status ?? '-'}</td>
                                            <td>{item?.charge_holder_name ?? '-'}</td>
                                            <td>{item?.amount ?? '-'}</td>
                                            <td>{item?.asset_under_charge ?? '-'}</td>
                                            <td>{item?.date_of_creation ?? '-'}</td>
                                            <td>{item?.modification_date === '' ? '-' : item.modification_date ?? '-'}</td>
                                            <td>{item?.satisfaction_date === '' ? '-' : item.satisfaction_date ?? '-'}</td>
                                        </tr>
                                    </>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='px-5'>
                <div className='mx-2 director-details'>
                    Founder Details
                    <p className='cmp-row2-head1'>{chargesDetails?.length > 0 && `Last Updated On: ${updateDateChar}`}</p>
                </div>
                <div className='mb-5 row table-head'>
                    <table className='table team'>
                        <thead className='wtspc'>
                            <tr>
                                <th style={{ width: "180px" }}>NAME</th>
                                <th style={{ width: "80px" }}>AGE</th>
                                <th style={{ width: "100px" }}>FOLLOWERS</th>
                                <th>EDUCATION</th>
                                <th>WORK EXPERIENCE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {founderDetails?.length && founderDetails?.map((founder, i) => (
                                <tr key={i}>
                                    <td>{founder?.name ?? "-"}</td>
                                    <td>{founder?.age ?? "-"}</td>
                                    <td>{founder?.followers ? founder?.followers : "-"}</td>
                                    <td>
                                        {founder?.user_educations?.length ? founder?.user_educations?.map((education, j) => (
                                            <div key={j} className='d-flex justify-content-between align-items-start mt-2'>
                                                <div style={{ width: "320px" }}>{education?.degree ? `${j + 1}. ${education?.degree}, ` : ""} {education?.schoolName ?? ""}</div>
                                                <div className='font-italic fs10 text-right' style={{ width: "140px" }}>{getExperienceDates(education?.startDate, education?.endDate)}</div>
                                            </div>
                                        )) : "-"}
                                    </td>
                                    <td>
                                        {founder?.user_work_exps?.length ? founder?.user_work_exps?.map((company, j) => (
                                            <div key={j} className='d-flex justify-content-between align-items-center mt-2'>
                                                <div style={{ width: "320px" }}>{company?.jobTitle ? `${j + 1}. ${company?.jobTitle} - ` : ""} {company?.companyName}</div>
                                                <div className='font-italic fs10 text-right' style={{ width: "140px" }}>{getExperienceDates(company?.startDate, company?.endDate)}</div>
                                            </div>
                                        )) : "-"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {!!complianceDetails && <ComplianceDetails complianceDetails={complianceDetails} fetchComplianceDataFromKarza={fetchComplianceDataFromKarza}/>}
        </>
    )
}
