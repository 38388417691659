import { isEmpty } from "lodash";
import moment from "moment";
import React, { useCallback, useRef, useState } from "react";
import { useEffect } from "react";
import { getAmountInLacs, getToggleConfigValueForDelayPaid, priceFormat } from "../../../../../util/utility";
import styles from './OrderTransactionListRow.module.scss'
// import {Link} from 'react-router-dom';

const {
  repayments,
  topRow,
  repaymentBar,
  greenBar,
  paidAmount,
remainingAmount,
OrderTransactionListRow_input_box,
DelayPaidToggle,
navTabs,
navItem,
navLink,
active,nav,delayChargeAsterisk,disableToggle
} = styles;
function OrderTransactionListRow(props) {
    const observer = useRef();
    const [eachRowCollectedAmount,setEachRowCollectedAmount] = useState(null);
    const [delayPaidToggle,setDelayPaidToggle] = useState(null);
    const lastContractRef = useCallback(
        (node) => {
            if (props.loader) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && props.hasMore()) props.loadMore();
            });
            if (node) observer.current.observe(node);
        },
        [props.loader, props.hasMore()]
    );

    useEffect(() => {
      if (!props.is_marking_transaction_status && eachRowCollectedAmount !== null) {
        setEachRowCollectedAmount(null);
      }
    }, [props.is_marking_transaction_status]);

    const setGreenBarWidth = () => {
      const totalAmount = props?.item?.total_repayment;
      const paidAmount = props?.item?.repayment_done ?? 0;
      let perc = ((paidAmount/totalAmount)*100);
      if(totalAmount <= 0) perc = 0;
      else if(perc < 3.5) perc = 3.5;
      return {
        width: `${perc}%`
      }
    }

    const getRemainingAmount = () => {
       const totalAmount = props?.item?.total_repayment ?? 0;
       const paidAmount = props?.item?.repayment_done ?? 0;
       const remainingAmount = Number(((totalAmount ?? 0) - (paidAmount ?? 0)).toFixed(2));
       return remainingAmount;
    }

    const handleChange = (amount) => {
        // if(!regex.test(amount)) return;
        if(amount) amount = Number(amount);
        const minAmount = Math.min(Number(((props?.item?.total_repayment ?? 0) - (props?.item?.repayment_done ?? 0)).toFixed(2)), props.item?.investee_organization?.escrow_account_balance);
        // if(amount <= minAmount && !isNaN(minAmount)) {
        setEachRowCollectedAmount(amount);
        if(!isEmpty(props.selected_repayments_payload)) {
            const temp_selected_repayments_payload = [...props.selected_repayments_payload];
            temp_selected_repayments_payload?.forEach((repayment) => {
                if(repayment.investor_organization_id === props.item.investor_organization?._id && repayment.transaction_date === props.item?.transaction_date && repayment.investee_organization_id === props.item.investee_organization?._id) {
                   repayment.repaymentAmountToBeDone = Number(amount);
                   let repaymentUpd = {...props.item};
                   repaymentUpd.repaymentAmountToBeDone = Number(amount);
                   repayment.delayPaidToggle = getToggleConfigValueForDelayPaid(repaymentUpd).toggle
                   setDelayPaidToggle(repayment.delayPaidToggle)
                }
            });
            props.setSelectedRepaymentsPayload(temp_selected_repayments_payload);
        }
        else {
           const temp_repayments = [...props.repayments];
           temp_repayments[props.index].repaymentAmountToBeDone = amount;
           temp_repayments[props.index].delayPaidToggle = getToggleConfigValueForDelayPaid( temp_repayments[props.index]).toggle;
           setDelayPaidToggle(temp_repayments[props.index].delayPaidToggle)
           props.setRepayments(temp_repayments);
        }
        // }
    };

    const handleChangeToggle = (val) => {
        let repayment = {...props.item};
        repayment.repaymentAmountToBeDone = eachRowCollectedAmount;
        if(getToggleConfigValueForDelayPaid(repayment).disabled) return;
        if(!isEmpty(props.selected_repayments_payload)) {

            const temp_selected_repayments_payload = [...props.selected_repayments_payload];
                temp_selected_repayments_payload?.forEach((repayment) => {
                    if(repayment.investor_organization_id === props.item.investor_organization?._id && repayment.transaction_date === props.item?.transaction_date && repayment.investee_organization_id === props.item.investee_organization?._id) {
                        repayment.delayPaidToggle = val;
                    }
                });
                props.setSelectedRepaymentsPayload(temp_selected_repayments_payload);
                setDelayPaidToggle(val);

        } else {
            const temp_repayments = [...props.repayments];
           temp_repayments[props.index].delayPaidToggle = val;
           props.setRepayments(temp_repayments);
           setDelayPaidToggle(val);
        }

    }

    const getRepaymentAmount = () => {
        let repaymentAmount = props?.item?.repaymentAmountToBeDone;
        if(!Number.isInteger(repaymentAmount) && repaymentAmount){
            return repaymentAmount.toFixed(2);
        } 
        return repaymentAmount;
    }

    const getDpd = () => {
        const dpd = moment().utcOffset(330).startOf("day").diff(moment(props?.item?.transaction_date,"YYYY-MM-DD[T]HH:mm:ss.SSS[Z]").startOf("day"),"days");
        if(dpd < 0) return 0;
        return dpd;
    }

    const getCssClassDelayPaidToggle = (forButton) => {
        let ret = "";
        let dpt = delayPaidToggle
        if(delayPaidToggle == null){
            dpt = props.item?.delayPaidToggle
        } 
        if(forButton === "Yes"){
            ret = `${navLink} ${dpt === "on" ? active : ""}`
        } else {
            ret = `${navLink} ${dpt === "on" ? "" : active}`
        }
        let repayment = {...props.item};
        repayment.repaymentAmountToBeDone = eachRowCollectedAmount;
        if(getToggleConfigValueForDelayPaid(repayment).disabled) ret = ret + " "+ disableToggle

        return ret;
    }
    const renderer = (
        <>
            <td>
                 <input
                    type='checkbox'
                    value={props.index}
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                    checked={props.selected_repayments_payload.some(
                      (i) => (i?.investor_organization_id === props.item?.investor_organization?._id && i?.transaction_date === props.item?.transaction_date && i?.investee_organization_id === props.item?.investee_organization?._id)
                    )}
                   onChange={(e) => props.onChangeRepaymentsCheckbox(e.target.checked, props.item)}
                 />
            </td>
            <td style={{maxWidth: "180px"}} className="text-truncate">
            <span data-bs-toggle="tooltip" data-bs-html="true" title={props.item?.investor_organization?.name}>
               {props.item?.investor_organization?.name}
            </span>
                </td>
            <td style={{maxWidth: "180px"}} className="text-truncate">
            <span data-bs-toggle="tooltip" data-bs-html="true" title={props.item?.investee_organization?.name}>
               {props.item?.investee_organization?.name}
            </span>
            </td>
            <td style={{color: "#49A71B"}}> {props.item?.investee_organization?.escrow_account_balance && `₹ ${priceFormat(Math.floor(props.item?.investee_organization?.escrow_account_balance))}`}</td>
            <td>
                <div className={repayments}>
                    <div className={topRow}>
                        <div className={paidAmount}>₹ {(props?.item?.repayment_done ?? 0)}</div>
                        <div className={`${remainingAmount} ${props.item?.delay_charge_amount > 0 ? delayChargeAsterisk : ""}`}>
                            ₹ {getRemainingAmount()}
                        </div>
                    </div>
                    <div className={repaymentBar}>
                        <div className={greenBar} style={setGreenBarWidth()}></div>
                    </div>
                    {props.item?.delay_charge_amount > 0 &&
                        <div className = {delayChargeAsterisk}style={{ color: "#818181" }}>
                            (₹ {props.item?.repayment_remaining_without_delay_charges} +<span style={{color:"#ce4a3e"}}> ₹ {props.item?.delay_charge_amount}</span>)
                        </div>}
                </div>
            </td>
            <td>{moment(props?.item?.transaction_date).utcOffset(0).format('DD MMM YYYY')}</td>
            <td style={{color: "#980D0D"}}>{getDpd()} days</td>
            <td className={OrderTransactionListRow_input_box}>
              <input type="number" value={eachRowCollectedAmount ?? getRepaymentAmount() ?? ''} onChange={(e) => handleChange(e.target.value)} />
            </td>
            <td >
            <div className={DelayPaidToggle}>
                <div className={`b-b b-theme nav-active-theme mb-3`}>
                    <ul className={`${navTabs} ${nav}`} id="myTab" role="tablist">
                        <li className={navItem}><a className={getCssClassDelayPaidToggle("Yes")} id="pending-tab" onClick={() => handleChangeToggle("on")} data-toggle="tab" href="#pending" role="tab" aria-controls="pending" aria-selected="true">Yes</a></li>
                        <li className={navItem}><a className={getCssClassDelayPaidToggle("No")} id="completed-tab" onClick={() => handleChangeToggle("off")} data-toggle="tab" href="#completed" role="tab" aria-controls="completed" aria-selected="false">No</a></li>
                    </ul>
                </div>
            </div>
            </td>
            <td onClick={() => props.handleRightArrowClick({
                investee_organization_id: props.item?.investee_organization?._id,
                investor_organization_id: props.item?.investor_organization?._id,
                transaction_date: props.item?.transaction_date
            })} className="pr-3">
                <img src='../../../../../assets/right-arrow-order.svg' alt=''></img>
            </td>
        </>
    );

    if (props.dataLength === props.index + 1) {
        return <tr ref={lastContractRef}>{renderer}</tr>;
    } else {
        return <tr>{renderer}</tr>;
    }
}

export default OrderTransactionListRow;
