import React from 'react';
import { showNotification } from '../../../../../../../../../../../../../../util/utility';
import { SHOW_NOTIFICATION } from '../../../../../../../../../../../../../../constants/enums';
import { LIMIT_TYPES } from '../../../../../../../../../../../../../../common/ENUM';

export default function RenderTextBox({ state, setState, detectChange, setDetectChange, category, disabled, isModerating, field, totData }) {
    const handleChange = (val, index) => {
        const obj = {...detectChange};
        if (!obj[category]) {
            obj[category] = true;
            setDetectChange(obj);
        }
        if (state instanceof Array) {
            const arr = [...state];
            arr[index] = val;
            setState(arr);
        }
        else {
            setState(val);
        }
    }

    const deleteCPCSItemAndValidate = (state, setState, index) => {
        const totObj = totData;
        const recurLimitCondition = totObj?.[LIMIT_TYPES.RECUR_LIMIT]?.[field] ?? [];
        const updatedState = [...state];
        if (isModerating && updatedState[index] !== "") {
            const updatedDetectChange = {...detectChange};
            if (!updatedDetectChange[category]) {
                updatedDetectChange[category] = true;
                setDetectChange(updatedDetectChange);
            }
            updatedState.splice(index, 1);
            if (isValidAfterDeletion(updatedState, recurLimitCondition, category)) {
                setState(updatedState);
            } else {
                showNotification(SHOW_NOTIFICATION.ERROR, "CP/CS cannot be less than Recur Limit Collection Methods");
            }
            return;
        }
    }

    const areRecurLimitConditionsMissing = (condition, recurLimitCond) => {
        let standardLimitCondition = condition ?? [];
        let recurLimitCondition = recurLimitCond ?? [];
        return recurLimitCondition.some(objB => 
            !standardLimitCondition.some(objA => JSON.stringify(objA) === JSON.stringify(objB))
        );
    }

    const isValidAfterDeletion = (updatedState, recurLimitCondition, category) => {
        return !areRecurLimitConditionsMissing(updatedState, recurLimitCondition) || category === LIMIT_TYPES.RECUR_LIMIT;
    }

    if (Array.isArray(state)) {
        return state.map((item, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <textarea
              key={index}
              className="w-100"
              placeholder="Conditions will be commented here"
              required
              disabled={disabled}
              value={item}
              onChange={(e) => handleChange(e.target.value, index)}
            />
            {item !== "" && (
              <img
                style={{ paddingLeft: "0.5rem" }}
                src="/assets/remove.svg"
                onClick={(e) => {
                    deleteCPCSItemAndValidate(state, setState, index);
                }}
              />
            )}
          </div>
        ));
    }
    return (
        <textarea
            className='w-100'
            placeholder='Conditions will be commented here'
            required
            disabled={disabled}
            onChange={e => handleChange(e.target.value)}
            value={state}
        />
    );
}
