import moment from "moment";
import { useCallback, useRef } from "react";
import { priceFormat } from "../../../../../util/utility";

function CompletedOrderRepaymentsTableRow(props) {
  const observer = useRef();
  const lastContractRef = useCallback(
    (node) => {
        if (props.loader) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && props.hasMore()) props.loadMore();
        });
        if (node) observer.current.observe(node);
    },
    [props.loader, props.hasMore()]
  );
    const renderer = (
        <>
          <td style={{ maxWidth: '100px', width: '13%' }}>
            {props.item?.investor_organization_id?.name}
          </td>
          <td className="text-truncate" style={{ maxWidth: '100px', width: '9%' }}>
            <span data-bs-toggle="tooltip" data-bs-html="true" title={props.item?.investee_organization_id?.name}>
              {props.item?.investee_organization_id?.name}
            </span>
          </td>
          <td style={{ width: '9%' }}>
            {props.item.transaction_date ? moment(props.item.repayment_date).format("DD MMM YYYY") : "NA"}
          </td>
          <td style={{ width: '9%', color: "#49A71B"}}>
            ₹ {priceFormat(props.item.transaction_amount,2)}
          </td>
          <td style={{ width: '9%' }}>
          {props.item.repayment_date ? moment(props.item.transaction_date).format("DD MMM YYYY") : "NA"}
          </td>
          <td style={{ width: '9%', color: "#980D0D"}}>{props.item.daysDelayed < 0 ? 0 : props.item.daysDelayed} Days</td>
        </>
    );

    if (props.dataLength === props.index + 1) {
      return <tr ref={lastContractRef}>{renderer}</tr>;
    } else {
      return <tr>{renderer}</tr>;
    }
}
  
  export default CompletedOrderRepaymentsTableRow;