import React, { Component } from 'react';
import AdminHead from '../../components/AdminHead.js'
import callApi from "../../../../util/apiCaller";
import { showNotification } from "../../../../util/utility";
import SwapsTableHeader from './components/SwapsTableHeader.js';
import SwapsTableRow from './components/SwapsTableRow.js';
import { Button, Modal } from 'react-bootstrap';
import _ from 'lodash';
import PageLoader from '../../../../components/loader/PageLoader.js';

class SwapsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      swapRequests: [],
      pageNum: 1,
      pageSize: 100,
      totalCount:0,
      qtext: '',
      showSwapModal: undefined,
      selectedEntry: {},
      contractsForSwap: [],
      selectedTotalAllocation: 0,
      allocationMappingToContractId: {},
      isSwapping:false,
      callingApi:false
    }
  }
 
  keyStateObjNameDict = {
    contact_name: "searchObjContactName",
    issue_description: "searchObjIssueDesc",
    issue_type: "selectorObjIssueType"
  }

  componentDidMount() {
    this.fetchItems();
  }

  hasMore = () => {
    return this.state.totalCount + 1 > this.state.pageNum * this.state.pageSize;
  };

  loadMore = () => {
    this.setState({ pageNum: this.state.pageNum + 1 });
    this.fetchCompanies();
  };

  fetchItems = () => {
    callApi(`swap/fetchAllSwapTrades`, "post", {}).then((res) => {
      if (res.status === 'Success' && res.data) {
        this.setState({
          swapRequests: res.data.trades
        })
      } else {
        showNotification("error", "Some error occured");
      }
    });
  }

  fetchContractsForSwap = (trade_id) => {
    this.setState({callingApi:true});
    callApi(`swap/getAllSwappableContracts`, "post", { trade_id: trade_id }).then((res) => {
      if (res.status === 'Success' && res.data) {
        this.setState({
          contractsForSwap: res.data,
          callingApi:false
        })
      } else {
        showNotification("error", res.data.message);
        this.setState({callingApi:false,contractsForSwap: []});
      }
    });
  }

  requestSwap = () => {
    this.setState({isSwapping:true});
    let input = {};
    let selectedContractsWithAllocation = [];
    Object.keys(this.state.allocationMappingToContractId).forEach(_id=>{
      if(this.state.allocationMappingToContractId[_id]?.isSelected){
        selectedContractsWithAllocation.push({
          contract_id: _id,
          allocation: Number(this.state.allocationMappingToContractId[_id].value),
        });
      }
    })
    input.array = selectedContractsWithAllocation;
    input.old_contract_id = this.state.selectedEntry.trade_contract_id;
    callApi(`swap/swapOldContract`, "post", input).then((res) => {
      if (res.status === 'Success') {
        showNotification("Success", "Swapped Successfully");
        this.handleClose();
        this.setState({isSwapping:false});
      } else {
        showNotification("error", res.data.message);
        this.setState({isSwapping:false});
      }
    });
  }
  getFilters = () => {
    let filters = {};
    filters.pageNum = this.state.pageNum;
    filters.pageSize = this.state.pageSize;
    // if (this.state.qtext) {
    //     filters.isSearching = true;
    //     filters.qtext = this.state.qtext;
    // }
    // filters.isFiltering = true
    // filters.isSearching = true;
    // filters[this.state.searchObjContactName.keyName] = this.state.searchObjContactName.value;
    // filters[this.state.searchObjIssueDesc.keyName] = this.state.searchObjIssueDesc.value;

    // filters[this.state.selectorObjIssueType.keyName] = {
    //     "in": this.state.selectorObjIssueType.elementsArray.map((item)=>{
    //         if(item.selected){
    //             return item.label;
    //         }
    //     })
    // }
    return filters;
  };

  handleApplyFilters = () => {
    this.fetchItems();
  };

  handleSearchFilter = (obj) => {
    let searchObj = {};
    searchObj[this.keyStateObjNameDict[obj.keyName]] = obj
    this.setState(searchObj, () => {
      this.handleApplyFilters();
    })
  };

  inputSelectorHandler = (obj) => {
    let selectorObj = {};
    selectorObj[this.keyStateObjNameDict[obj.keyName]] = obj
    this.setState(
      selectorObj, () => {
        this.handleApplyFilters();
      })
  }
  
  handleClose = () => {
    this.setState({ 
      showSwapModal: undefined ,
      allocationMappingToContractId:{},
      selectedTotalAllocation : 0
    });
  }
  openModal = (selectedEntry, modalState) => {
    this.setState({ showSwapModal: modalState, selectedEntry }, () => {
      if(modalState === 'swap'){
        this.fetchContractsForSwap(selectedEntry._id)
      }
    })
  }
  onAllocationChange = (e, item) => {
    if (e.target.value > item.recurring_payment_value) {
      showNotification("Error", "Allocation amount cannot be greater than contract's Repayment Amount")
    } 
    else if(e.target.value < 0){
      showNotification("Error", "Allocation amount cannot be -ve");
    } 
    else {
      let allocationMappingToContractId = this.state.allocationMappingToContractId;
      allocationMappingToContractId[item._id] = { value: e.target.value, isSelected: false };
      this.setState({ allocationMappingToContractId })
      this.getTotalAllocation();
    }

  }

  getTotalAllocation = () => {
    const selectedTotalAllocation = Object.keys(this.state.allocationMappingToContractId).reduce((total, curr) => {
      if (this.state.allocationMappingToContractId[curr] && this.state.allocationMappingToContractId[curr].value && this.state.allocationMappingToContractId[curr].isSelected) {
        return total + Number(this.state.allocationMappingToContractId[curr].value)
      } else {
        return total + 0;
      }
    }, 0)
    this.setState({ selectedTotalAllocation })
  }

  onChangeCheckBox = (e, _id) => {
    let allocationMappingToContractId = this.state.allocationMappingToContractId;
    if (!allocationMappingToContractId[_id]) {
      allocationMappingToContractId[_id] = {};
    }
    if (e.target.checked) {
      if (allocationMappingToContractId[_id].value) {
        allocationMappingToContractId[_id].isSelected = true
      } else {
        showNotification("Error", "Please Allocate Some Value First")
        allocationMappingToContractId[_id].isSelected = false;
      }
    } else {
      allocationMappingToContractId[_id].isSelected = false;
    }

    this.setState({ allocationMappingToContractId });
    this.getTotalAllocation();
  }

  liquidateContract = () => {
    if(!this.state.selectedEntry.is_recur_fee_waived_for_investor && !this.state.fee_amount_liquidate){
        showNotification("Error","Please Enter Fee Amount")
        return;
    }
    this.setState({isSwapping:true});
    let input = {
      trade_id: this.state.selectedEntry._id,
      recur_fee_amount: Number(this.state.fee_amount_liquidate),
    };
    callApi(`swap/liquidateOldContract`, "post",input ).then((res) => {
      if (res.status === 'Success') {
        showNotification("Success", "Liquidated Successfully");
        this.fetchItems();
        this.setState({ isSwapping: false });
        this.handleClose();
      } else {
        this.setState({ isSwapping: false });
        showNotification("Error", res.message);
      }
    });  
  }

  render() {
    let selectedEntry = this.state.selectedEntry;
    console.log(selectedEntry);
    return (
      <div className="main-body">
        <AdminHead />
        <section id="admin-company">
          <div className="table-head">
          {/* request-data-table tableFixHead */}
            <table className="table team">
              <SwapsTableHeader
              // searchObjContactName = {this.state.searchObjContactName}
              // searchObjIssueDesc = {this.state.searchObjIssueDesc}
              // selectorObjIssueType = {this.state.selectorObjIssueType}
              // inputSelectorHandler = {this.inputSelectorHandler}
              // handleSearchFilter = {this.handleSearchFilter}
              />
              <tbody style={{ display: 'none' }}></tbody>
            </table>
          </div>
          <div className='tableFixHead table-body'>
            <table
                className='table team'
            >
              <thead></thead>
              <tbody className="team-body">
                {!_.isEmpty(this.state.swapRequests) &&
                  this.state.swapRequests.map((item) => (
                    <SwapsTableRow
                      item={item}
                      approveRejectRequest={this.approveRejectRequest}
                      openModal={this.openModal}
                      isApproving={this.state.isApproving}
                      idApproving={this.state.idApproving}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </section>
        <Modal
          show={this.state.showSwapModal}
          onHide={this.handleClose}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedEntry.investee_organization_name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {this.state.callingApi && <PageLoader></PageLoader>}
            <div style={{
              display: 'flex',
              alignContent: 'flex-end',
              justifyContent: 'space-around',
              flexWrap: 'nowrap'
            }}
            >
              <p className="black-text small-fonts"> Contract ID: &nbsp;{" "} <span>
                {selectedEntry.contract_id_num}
              </span>
              </p>
              <p className="black-text small-fonts">
                Repayment: &nbsp;
                <span>₹{selectedEntry.recurring_payment_value} </span>
              </p>
              <p className="black-text small-fonts">Term: &nbsp;{" "}<span>
                {selectedEntry.contract_term_value_words}
              </span>
              </p>
            </div>
            {this.state.showSwapModal === 'swap' ?
              <div className="container custom-scrollbar" style={{ border: '1px solid #ccc', borderRadius: '5px',  maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto' }}>
                {!_.isEmpty(this.state.contractsForSwap) ?
                  <table style={{ overflowY: 'auto' }} className="table team">
                    <thead className="team-head admin-contract-head">
                      <tr>
                        <th></th>
                        <th>Contract ID</th>
                        <th>Repayments</th>
                        <th>Allocation</th>
                      </tr>
                    </thead>
                    <tbody className="team-body">
                      {
                        this.state.contractsForSwap.map((item) => {
                          return (<tr style={{ height: '30px' }}>
                            <td>
                              <input
                                type="checkbox"
                                //    value = {props.item._id}
                                //    style={{marginLeft:'5px', paddingRight:'5px'}}
                                checked={this.state.allocationMappingToContractId[item._id] && this.state.allocationMappingToContractId[item._id].isSelected}
                                onChange={(e) => this.onChangeCheckBox(e, item._id)}
                              />
                            </td>
                            <td>{item.contract_id_num}</td>
                            <td>
                              {item.recurring_payment_value} /{" "}
                              {item.contract_term_unit}
                            </td>
                            <td>
                              <input type="number" value={this.state.allocationMappingToContractId[item._id]?.value} onChange={(e) => this.onAllocationChange(e, item)}></input>
                            </td>
                          </tr>)
                        })
                      }

                    </tbody>
                  </table>
                  :
                  <p style ={{fontSize: '14px',textAlign: 'center'}}>No Contracts available for Swap !</p>
                }
              </div>
              : null
            }

          </Modal.Body>
          <Modal.Footer style={{ justifyContent: 'center' }}>
            {
              this.state.showSwapModal === 'swap' ?
                <div style={{ display: 'block' }}>
                  <p className="black-text small-fonts"> Total Allocation: &nbsp;{" "} <span style={{fontSize:"16px"}}>
                    ₹{this.state.selectedTotalAllocation}
                  </span>
                  </p>
                  <Button
                    style={{ width: "300px", align: "center", fontSize: '14px' }}
                    variant={this.state.selectedTotalAllocation !== selectedEntry.recurring_payment_value ? "secondary" : "primary"}
                    disabled={this.state.selectedTotalAllocation !== selectedEntry.recurring_payment_value || this.state.isSwapping}
                    onClick={() => this.requestSwap()}
                  >
                    <img style={{ height: '14px', marginRight: '5px' }} src="/assets/swap_icon.svg"></img>{this.state.isSwapping ? "Swapping" : "Swap"}
                  </Button>
                </div>
                :
                <>
                <div style={{ display: 'contents'}} >
                  <p className="black-text small-fonts"> Foreclosure Amount: &nbsp;{" "} <span style={{fontSize:"16px"}}>
                    ₹{selectedEntry.foreclosure_amount}
                  </span>
                  </p>
                  <p className=" small-fonts"> Investor Fees: <span style = {{fontWeight:"bold"}}>{selectedEntry.recur_fees_for_investor}{"%("}{selectedEntry.is_recur_fee_waived_for_investor ? 'waived':'not waived'}{")"}</span>
                  {<span style = {{marginLeft:"10px"}}> Deduct Fees: </span>} 
                  {<input  type="text" type = "number" onChange = {(e) => this.setState({fee_amount_liquidate:e.target.value})} placeholder = "0"></input>}
                  </p>
                </div>
                <div>
                  <Button
                    style={{ width: "300px", align: "center", fontSize: '14px', backgroundColor: "#A46023" }}
                    onClick={() => this.liquidateContract()}
                    disabled = {this.state.isSwapping}
                  >
                    <img style={{ height: '18px', marginRight: '5px' }} src="/assets/liquidate.svg"></img>{this.state.isSwapping ? "Liquidating" : "Liquidate"}
                  </Button>
                  </div>
               </>
            }

          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default SwapsPage;
