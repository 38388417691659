import React, {Component} from 'react';
import AdminHead from '../../components/AdminHead.js'
import callApi from "../../../../util/apiCaller";
import {showNotification} from "../../../../util/utility";
import WithdrawalTableHeader from './components/WithdrawalTableHeader.js';
import WithdrawalTableRow from './components/WithdrawalTableRow.js';

class WithdrawalsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            withdrawalRequests: [],
            pageNum: 1,
            pageSize: 400,
            qtext: '',
            isApproving:false,
            searchObjContactName:{
                keyName: 'contact_name',
                value: ''
            },
            searchObjIssueDesc:{
                keyName: 'issue_description',
                value: ''
            },
            selectorObjIssueType : {
                keyName : "issue_type",
                elementsArray : [
                    {
                        label:"Issue 1",
                        selected:true 
                    },
                    {
                        label:"Issue 2",
                        selected:true 
                    }
                ]
            },
            idApproving : ''
        }
    }

    keyStateObjNameDict = {
        contact_name: "searchObjContactName",
        issue_description:"searchObjIssueDesc",
        issue_type:"selectorObjIssueType"
    }

    componentDidMount() {
        this.fetchItems();
    }


    fetchItems = () => {
        let filters = this.getFilters();
        callApi(`admin/withdrawal-request/list`, "post", filters).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    withdrawalRequests: res.data.result.withdrawalRequestList,
                    totalCount: res.data.totalCount
                })
            } else {
                showNotification("error", "Some error occured");
            }
        });
    }

    getFilters = () => {
        let filters = {};
        filters.pageNum = this.state.pageNum;
        filters.pageSize = this.state.pageSize;
        // if (this.state.qtext) {
        //     filters.isSearching = true;
        //     filters.qtext = this.state.qtext;
        // }
        // filters.isFiltering = true
        // filters.isSearching = true;
        // filters[this.state.searchObjContactName.keyName] = this.state.searchObjContactName.value;
        // filters[this.state.searchObjIssueDesc.keyName] = this.state.searchObjIssueDesc.value;
       
        // filters[this.state.selectorObjIssueType.keyName] = {
        //     "in": this.state.selectorObjIssueType.elementsArray.map((item)=>{
        //         if(item.selected){
        //             return item.label;
        //         }
        //     })
        // }
        return filters;
    };

    handleApplyFilters = () => {
        this.fetchItems();
    };

    handleSearchFilter = (obj) => {
        let searchObj ={};
        searchObj[this.keyStateObjNameDict[obj.keyName]]=obj
        this.setState(searchObj, () => {
            this.handleApplyFilters();
        })
    };

    inputSelectorHandler = (obj) =>{
        let selectorObj ={};
        selectorObj[this.keyStateObjNameDict[obj.keyName]]=obj 
        this.setState(
            selectorObj,() => {
            this.handleApplyFilters();
        })
    }
    approveRejectRequest = (id,status) =>{
        this.setState({isApproving:true,idApproving:id});
        callApi(`/admin/mark-withdraw-request-status/${id}`, "post", {approval_status:status}).then((res) => {
            if (res.status === 'Success') {
                showNotification("Success", "Updated Successfully");
                this.fetchItems();
                this.setState({isApproving:false});
            } else {
                this.setState({isApproving:false});
                showNotification("Error", res.message);
            }
        });
    }
    render() {
        return (
            <div className="main-body">
                <AdminHead/>
                <section id="admin-company">
                    {/* request-data-table class */}
                   <span style={{fontSize:"13px"}}><i class="fa fa-thumbs-down" aria-hidden="true"></i>  - MSA/EP Not Present</span>
                    <div className="table-head">
                        <table className="table team">
                            <WithdrawalTableHeader
                                // searchObjContactName = {this.state.searchObjContactName}
                                // searchObjIssueDesc = {this.state.searchObjIssueDesc}
                                // selectorObjIssueType = {this.state.selectorObjIssueType}
                                // inputSelectorHandler = {this.inputSelectorHandler}
                                // handleSearchFilter = {this.handleSearchFilter}
                            />
                        <tbody style={{ display: 'none' }}></tbody>
                        </table>
                    </div>
                    <div className='tableFixHead table-body'>
                        <table
                            className='table team'
                        >
                            <thead></thead>
                            <tbody className="team-body">
                            {this.state.withdrawalRequests && this.state.withdrawalRequests.map(item => (
                                <WithdrawalTableRow
                                    item={item}
                                    approveRejectRequest = {this.approveRejectRequest}
                                    isApproving = {this.state.isApproving}
                                    idApproving = {this.state.idApproving}
                                />
                            ))}
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
        );
    }
}

export default WithdrawalsPage;
