import { limitRoadmapMonths } from '../../../../../../../../../../../../../constants/commonConstants';
import { showNotification } from '../../../../../../../../../../../../../util/utility';
import './LimitRoadmap.scss';

function LimitRoadmap({ limitRoadmapData, setLimitRoadmapData,onLimitRoadmapChange,isModerating,isTotAccepted }) {

  const changeLimitRoadmap = (e) => {
    if(e.target.validity.valid) {
      setLimitRoadmapData((data) => ({
        ...data,
        limitRoadmap: e.target.value
      }));
      onLimitRoadmapChange();
    }
  }

  const addMore = () => {
    const numberOfTranches = limitRoadmapData?.tranches?.length;
    if(numberOfTranches) {
      if(limitRoadmapData?.tranches?.[numberOfTranches-1] && !limitRoadmapData?.tranches?.[numberOfTranches-1]?.tenure) {
        showNotification("Error",`Please select timeline for Tranche ${numberOfTranches+1}`)
        return;
      }

    }
    setLimitRoadmapData((data) => ({
      ...data,
      tranches: [...data.tranches, {
        tenure: '',
        amount: '',
        comments: ''
      }]
    }))
    onLimitRoadmapChange();
  }

  const changeValue = (e, index, type) => {
    let data = JSON.parse(JSON.stringify(limitRoadmapData));
    data.tranches[index][type] = e.target.value;
    setLimitRoadmapData(data);
    onLimitRoadmapChange();
  }

  const removeTranch = (index) => {
    let data = JSON.parse(JSON.stringify(limitRoadmapData));
    if (data?.tranches?.length <= 1) {
      setLimitRoadmapData({
        limitRoadmap: '',
        tranches: [
            {
                tenure: '',
                amount: '',
                comments: ''
            }
          ]
      })
    }
    else{
      data?.tranches?.splice(index, 1);
      setLimitRoadmapData(data);
    }
    onLimitRoadmapChange();
  }

  const getRemainingMonths = (index) => {
    if(index === 0) return [...limitRoadmapMonths];
    else {
      const lastTrancheTenure = limitRoadmapData?.tranches[index-1]?.tenure;
      return [...limitRoadmapMonths.slice(limitRoadmapMonths.indexOf(lastTrancheTenure)+1)]
    }
  }

  return (
    <div className='LimitRoadmap'>

      <div className='LRContainer'>
        <div className='LRLeft'>
          <div>Limit Roadmap (INR lacs)</div>
          <input 
          className='LRInput'
          pattern="^[0-9]+(\.[0-9]*)?$"
          value={limitRoadmapData?.limitRoadmap} 
          onChange={changeLimitRoadmap} 
          disabled={!isModerating || isTotAccepted}
          />
        </div>

        <div className='LRRight'>
        {limitRoadmapData?.tranches?.map((tranch, i) => {
            return(
              <div className='Tranch' key={i}>

              <div>
                Tranche {i + 2}
                <div className='greysub'>(INR Lacs)</div>
              </div>

              <select className='LRInput' onChange={(e) => changeValue(e, i, 'tenure')} defaultValue={""} value={tranch.tenure} disabled={!isModerating || isTotAccepted}>
              <option value="" disabled hidden>Timeline</option>
                                {getRemainingMonths(i).map((month, j) => {
                  return(
                    (
                      <option key={j} value={month}>{month}</option>
                    )
                  )
                })}
              <option value=""  disabled hidden>Timeline</option>
              <option value={tranch.tenure} disabled hidden>{tranch.tenure}</option>
              </select>

              <input 
              className='LRInput'
              placeholder='Amount'
              pattern="^[0-9]+(\.[0-9]*)?$"
              onChange={(e) => e.target.validity.valid && changeValue(e, i, 'amount')} 
              value={tranch?.amount} 
              disabled={!isModerating || isTotAccepted}
              />

              <input 
              className='LRInputArr'
              placeholder='ARR'
              pattern="^[0-9]+(\.[0-9]*)?$"
              onChange={(e) => e.target.validity.valid && changeValue(e, i, 'arr')} 
              value={tranch?.arr} 
              disabled={!isModerating || isTotAccepted}
              />

              <input 
              className='LRInput Comments' 
              placeholder='Condition to unlock' 
              onChange={(e) => changeValue(e, i, 'comments')} 
              value={tranch?.comments} 
              disabled={!isModerating || isTotAccepted}
              />
              {(isModerating && !isTotAccepted)? <img className="LRRemove" src="/assets/remove.svg" alt="MAB" onClick={() => removeTranch(i)} />: null}
            </div>
          )
        })}
          {(isModerating && !isTotAccepted) && limitRoadmapData?.tranches?.length < 10 ? <div className='Addmore' onClick={addMore}>+ Add More</div>: null}
        </div>
      </div>

    </div>
  )
}

export default LimitRoadmap