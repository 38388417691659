import { Table } from "react-bootstrap";
import { get, isFunction } from "lodash";
import "./CommonTable.scss"

function CommonTable({columns, data,tableHeadingClass,tableClassName,responsive=true,hover=true}){
    return (
        <>
          <Table responsive={responsive} hover={hover} className={tableClassName?.length ? tableClassName :""}>
            <thead className={tableHeadingClass?.length ? tableHeadingClass :"styledTableHeader"}>
              <tr>
                {columns.map((column, index) => (
                  <th key={column.heading} className="commontableHeadings" {...column?.thProps} >
                    {isFunction(column.heading) ? column.heading() : column.heading}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data && data?.length !== 0 ? (
                data?.map((row, rowId) => {
                  return (
                    <tr key={row.id || `row-${rowId}`} >
                      {columns.map((column, columnId) => {
                        const value = get(row, column.path);
                        return (
                          <td
                            key={columnId}
                            // border={"1px solid #ccc"}
                            {...column?.tdProps}
                          >
                            {column.cell
                              ? isFunction(column.cell)
                                ? column.cell(value, row)
                                : column.cell
                              : value}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="nodatarow" colSpan={12}>
                    {"No Data Availble"}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </>
      );
}

export default CommonTable