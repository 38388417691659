import React, { useEffect, useState } from "react";
import AdminHead from "../../components/AdminHead.js";
import callApi from "../../../../util/apiCaller";
import { showNotification } from "../../../../util/utility";
import styled from "styled-components";
import NachCollectionsTableRow from "./components/NachCollectionsTableRow.js";
import NachCollectionsTableHeader from "./components/NachCollectionsTableHeader.js";
import _ from "lodash";
import { mergeByProperty } from "../../../../util/calculateTermUtil.js";
import { getCheckIfUserHasRelevantPermissions } from "../../../App/AppReducer.js";
import { useSelector } from "react-redux";
import styles from "./NachCollectionsPage.module.scss";
import RecurTable from "../../../../components/RecurTable/RecurTable.jsx";
import { Tabs } from "./tabs.js";
import { CollectionsPage } from "./CollectionsPage.js";
import NachCollections from "./NachCollections/NachCollections";
import { Recovery } from "./Recovery/Recovery.jsx";
import { isEnvProd,mixPanelTrack } from "../../../../util/utility"
import { getEmail, getUserName, getUserId } from "../../../App/AppReducer.js";

const {
  CollectionsHeaderRight,
  CollectionProgressBar,
  HeaderSubPart,
  CollectionDropdown,
  CollectionDropdownOther,
  Green,
  Grey,
  Blue,
  Red,
  GreyPart,
  GreenPart,
  ProgressBottom,
  ProgressTop,
  greenText,
  redText,
} = styles;

const RefreshBtn = styled.button`
  height: 40px;
  width: 163px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #000000;
  border-radius: 6px;
  background-color: #ffffff;
  margin-right: 15px;
`;
export const NACH_COLLECTIONS_TAB = "NACH COLLECTONS";
export const COLLECTIONS_TAB = "COLLECTIONS";
export const RECOVERY_TAB="RECOVERY";

export default function NachCollectionsPage() {
  const PAGE_SIZE = 200;
  const [nachList, setNachList] = useState([]);
  const [selectedNachList, setSelectedNachList] = useState([]);
  const [showEditIcon, setShowEditIcon] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [isFetchingNachList, setIsFetchingNachList] = useState(false);
  const [qtext, setQText] = useState("");
  const [executionDateOrder, setExecutionDateOrder] = useState("desc");
  const [showDropdown, setShowDropdown] = useState(false);
  const [tab, setTab] = useState(COLLECTIONS_TAB);
  const [beta, setBeta] = useState(true);
  const userName = useSelector((state) => getUserName(state));
  const userEmail = useSelector((state) => getEmail(state));
  const userId = useSelector((state) => getUserId(state));

  const [debounceSearch] = useState(() =>
    _.debounce((text) => {
      fetchNachList(true, text);
    }, 2000)
  );
  const isUserHasEditAccess = useSelector((state) =>
    getCheckIfUserHasRelevantPermissions(state, ["app:edit"])
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchNachList(true, qtext);
  }, [executionDateOrder]);

  const fetchNachList = (isReseting = false, text = "",index,item) => {
    let filters = getFilters();
    setIsFetchingNachList(true);
    if (isReseting) filters["pageNum"] = 1;
    if (text) {
      filters["isSearching"] = true;
      filters["qtext"] = text;
    }
    callApi(
      `nach_details/get_all_executable_nach/nach_scheduling`,
      "POST",
      filters
    ).then((res) => {
      if (res.status === "Success") {
        setNachList((prevNachList) => {
          if (isReseting) return res.data.nach_for_scheduling;
          if(index&&item){
            prevNachList.splice(index,1,item);
            return mergeByProperty(
              [...prevNachList],
              res.data.nach_for_scheduling,
              "_id"
            );
          } 
          else
            return mergeByProperty(
              [...prevNachList],
              res.data.nach_for_scheduling,
              "_id"
            );
        });
        setIsFetchingNachList(false);
        setSelectedNachList([]); // Unselect all selected
        setTotalCount(res.data.nach_count);
      } else {
        const message = res?.data ? res.data : "Some error occured";
        showNotification("error", message);
      }
    });
  };

  const handleRefresh = () => {
    callApi("nach_scheduler/remaining_repayment_division_for_naches")
      .then((res) => {
        if (res.status === "Success") {
          showNotification("success", "Refreshed Scheduled");
        }
      })
      .catch((err) => showNotification("error", err));
  };

  const getFilters = () => {
    let filters = {
      pageNum,
      pageSize: PAGE_SIZE,
      execution_date_order: executionDateOrder,
    };
    return filters;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (qtext) debounceSearch(qtext);
  }, [qtext]);
  const handleTextSearch = (e) => {
    setQText(e.target.value);
  };

  const handleCheckBoxClick = (isChecked, nach) => {
    setSelectedNachList((prevNachList) => {
      let nachList = [...prevNachList];
      if (isChecked) nachList.push(nach);
      else if (!isChecked) _.remove(nachList, (n) => _.isEqual(n, nach));
      return nachList;
    });
  };

  const handleScheduleBtnClick = () => {
    if (selectedNachList.length > 0)
      callApi("nach_details/nach_scheduling/schedule_nach", "POST", {
        nach: selectedNachList,
      }).then((res) => {
        if (res.status === "Success") {
          setSelectedNachList([]); // Unselect all selected
          fetchNachList(); // fetch updated
        }
      });
  };

  const hasMore = () => {
    return totalCount + 1 > pageNum * PAGE_SIZE;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (pageNum > 1) fetchNachList();
  }, [pageNum]);
  const loadMore = () => {
    setPageNum((prevVal) => prevVal + 1);
  };

  const getHeaders = () => [
    {
      jsx: <span>Date</span>,
    },
    {
      jsx: <span>Company</span>,
    },
    {
      jsx: <span>Collection Overview</span>,
    },
    {
      jsx: <span>Remaining</span>,
    },
    {
      jsx: <span>Risk</span>,
    },
    {
      jsx: <span>Method</span>,
    },
    {
      jsx: <span>Owner</span>,
    },
    {
      jsx: <span>Comments</span>,
    },
  ];

  if (tab === COLLECTIONS_TAB) {
    if (isEnvProd) {
      mixPanelTrack({
        id: userId,
        trackUserObj: {
          tab_name: COLLECTIONS_TAB,
          user_name: userName,
          user_email: userEmail
        },
        trackCategoryName: "collections_tab_clicked",
      });
    }
    return <CollectionsPage tab={tab} setTab={setTab} beta={beta} setBeta={setBeta} />;
  }
  if (tab === NACH_COLLECTIONS_TAB) {
    if (isEnvProd) {
      mixPanelTrack({
        id: userId,
        trackUserObj: {
          tab_name: NACH_COLLECTIONS_TAB,
          user_name: userName,
          user_email: userEmail
        },
        trackCategoryName: "collections_tab_clicked",
      });
    }
    return (
      // <div className="main-body">
      //   <AdminHead />
      //   <section className="w-100">
      //     <div className="d-flex justify-content-between align-items-center has-search p-4" style={{height:'100px'}}>
      //       {/* <div className="fs21 fw600">NACH Collections</div> */}
      //       <Tabs tab={tab} setTab={setTab} />
      //       <span className="d-flex">
      //         {isUserHasEditAccess && (
      //           <button
      //             disabled={_.isEmpty(selectedNachList)}
      //             style={
      //               _.isEmpty(selectedNachList)
      //                 ? {
      //                     backgroundColor: "rgb(183, 183, 183)",
      //                     color: "rgb(255, 255, 255)",
      //                     cursor: "no-drop",
      //                   }
      //                 : {}
      //             }
      //             className="Scheduled-btn"
      //             onClick={handleScheduleBtnClick}
      //           >
      //             Schedule
      //           </button>
      //         )}
      //         <RefreshBtn onClick={handleRefresh}>Refresh Schedule</RefreshBtn>
      //         <div>
      //           <span className="form-control-feedback">
      //             <img
      //               alt=""
      //               src="../../../../../assets/search.png"
      //               style={{ height: "14px", width: "14px" }}
      //             />
      //           </span>
      //           <input
      //             value={qtext}
      //             type="text"
      //             onChange={handleTextSearch}
      //             className="form-control"
      //             placeholder="Search Company"
      //             style={{ margin: "auto 0px" }}
      //           />
      //         </div>
      //       </span>
      //
      //     </div>
      //     <div className="page-head-bottom-border"></div>
      //     <div style={{padding: '0 35px'}}>
      //       <div className="table-head">
      //         <table className="table team">
      //           <NachCollectionsTableHeader
      //             executionDateOrder={executionDateOrder}
      //             setExecutionDateOrder={setExecutionDateOrder}
      //             fetchNachList={fetchNachList}
      //           />
      //           <tbody style={{ display: "none" }}></tbody>
      //         </table>
      //       </div>
      //       <div className="tableFixHead table-body">
      //         <table className="table team">
      //           <thead></thead>
      //           <tbody className="team-body">
      //             {nachList?.map((item, index) => (
      //               <NachCollectionsTableRow
      //                 item={item}
      //                 index={index}
      //                 fetchNachList={fetchNachList}
      //                 showEditIcon={showEditIcon}
      //                 setShowEditIcon={setShowEditIcon}
      //                 selectedNachList={selectedNachList}
      //                 handleCheckBoxClick={handleCheckBoxClick}
      //                 hasMore={hasMore}
      //                 loadMore={loadMore}
      //                 loader={isFetchingNachList}
      //                 dataLength={nachList.length}
      //                 isUserHasEditAccess={isUserHasEditAccess}
      //               />
      //             ))}
      //           </tbody>
      //         </table>
      //       </div>
      //     </div>
      //   </section>
      // </div>
        <NachCollections beta={beta} setBeta={setBeta} setTab={setTab} tab={tab}/>
    );
  }
  if(tab === RECOVERY_TAB){
    if (isEnvProd) {
      mixPanelTrack({
        id: userId,
        trackUserObj: {
          tab_name: RECOVERY_TAB,
          user_name: userName,
          user_email: userEmail
        },
        trackCategoryName: "collections_tab_clicked",
      });
    }
    return  <Recovery beta={beta} setBeta={setBeta} setTab={setTab} tab={tab}/>
  }
}
