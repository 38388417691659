import React, { useCallback, useRef } from "react";
// import {Link} from 'react-router-dom';
import moment from "moment";
import { displayTermUnit } from "../../../../../util/calculateTermUtil";

function TradesTableRow({item,loader,hasMore,loadMore,selected_trades_ids,onChangeTradeCheckbox,dataLength,index}) {
  const observer = useRef();
  const lastTradeRef = useCallback(
    (node) => {
      if (loader) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore()) loadMore();
      });
      if (node) observer.current.observe(node);
    },
    [loader, hasMore()]
  );

  const renderer = (
    <>
      <td style={{width:'9%'}} className="pl-2">
        {/*  className='blue-table-cel' */}
        <div className="d-flex align-items-center">
          <input
            type='checkbox'
            value={item._id}
            style={{ marginLeft: "5px", marginRight: "10px" }}
            checked={selected_trades_ids.some((i) => i === item._id)}
            disabled={
              item.trade_approval_status !== "Open" &&
              item.trade_approval_status !== "On Hold"
            }
            onChange={(e) => {
              onChangeTradeCheckbox(e);
            }}></input>
          <span className="text-truncate" style={{maxWidth:'72px'}}>
            <span data-bs-toggle="tooltip" data-bs-html="true" title={item.contract_investor_organization_id.name}>
              {item.contract_investor_organization_id.name}
            </span>
          </span>
        </div>
      </td>
      <td style={{width:'7%',maxWidth:'97px'}} className="text-truncate">
        <span data-bs-toggle="tooltip" data-bs-html="true" title={item.contract_investor_id
          ? item.contract_investor_id.contact_name
          : ""}>
          {item.contract_investor_id
            ? item.contract_investor_id.contact_name
            : ""}
        </span>
      </td>
      <td style={{width:'9%',maxWidth:'118px'}} className="text-truncate">
        <span data-bs-toggle="tooltip" data-bs-html="true" title={item.investee_organization_id.name}>
          {item.investee_organization_id.name}
        </span>
      </td>
      <td style={{width:'7%',maxWidth:'60px'}} className="text-truncate">
        <span data-bs-toggle="tooltip" data-bs-html="true" title={item.contract_id_num}>
            {item.contract_id_num}
        </span>
      </td>
      <td style={{width:'7%',maxWidth:'98px'}} className="text-truncate">
        <span data-bs-toggle="tooltip" data-bs-html="true" title={item.investor_payment_amount}>
          <i class='fas fa-rupee-sign'></i> {item.investor_payment_amount}
        </span>
      </td>
      <td style={{width:'7%'}}>{item.contract_term_value_words}</td>
      <td style={{width:'7%',maxWidth:'98px'}} className="text-truncate">
        <span data-bs-toggle="tooltip" data-bs-html="true" title={`${item.recurring_payment_value} / ${displayTermUnit(item.contract_term_unit)}`}>
          <i class='fas fa-rupee-sign'></i> {item.recurring_payment_value} / {displayTermUnit(item.contract_term_unit)}
        </span>
      </td>
      <td style={{width:'10%'}}>
        {moment(item.first_repayment).format('DD MMM YYYY')}
      </td>
      <td style={{width:'9%'}}>{moment(item.contract_traded_date).format("DD MMMM YYYY")}</td>
      <td  className={`${item?.trade_approval_status?.split(' ').join('-')}-status`} style={{width:'6%'}}>
        <b>{item.trade_approval_status}</b>
      </td>
      <td style={{width:'7%',maxWidth:'60px'}} className="text-truncate">
        <span data-bs-toggle="tooltip" data-bs-html="true" title={item.trade_id}>
          {item.trade_id}
        </span>
      </td>
      <td style={{width:'4%'}}>{item.order_id}</td>
      <td style={{width:'8%'}} className="pr-2">{moment(item.lastupdated_at).format("DD MMMM YYYY")}</td>
    </>
  );

  if (dataLength === index + 1) {
    return <tr ref={lastTradeRef} >{renderer}</tr>;
  } else {
    return <tr>{renderer}</tr>;
  }
}

export default TradesTableRow;
