import React, {Component} from 'react';
import '../../../css/Signup.css'
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import LoginForm from './components/LoginForm';
import {
    getApprovedState,
    getAuthenticatedState,
    getEmailVerifiedState,
    getLoggingState,
    getUserDetails, getUserProfileFetchingState
} from "../AppReducer";
import storage from "../../../util/storageService";
import {fetchUserProfile, verifyUserEmail, resendVerifyUserEmail, logOutUser} from "../AppActions";

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                username: '',
                password: ''
            },
            isLoginSubmitted: false,
        };
    }

    componentDidMount() {
        if (this.props.urlQuery && this.props.urlQuery !== '') {
            this.verifyEmailForUser(this.props.urlQuery);
        }
        if (storage.get("cerberus_token")&& this.props.isUserApproved) {
            if (storage.get("admin")) {
                this.props.history.push("/admin");
            } else {
                storage.flush();
            }
        }
        let userId = storage.get("user_id") ? storage.get("user_id") : "";
        if (userId && !this.props.isAuthenticated && !this.props.is_fetching_user) {
            this.props.dispatch(fetchUserProfile(userId));
        }
    }

    verifyEmailForUser = (query) => {
        this.props.dispatch(verifyUserEmail(query));
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.isAuthenticated && this.props.isAuthenticated && this.props.isUserApproved) {
            if (storage.get("admin") !== null && storage.get("admin") !== undefined) {
                this.props.history.push("/admin");
            } else {
                storage.flush();
            }
        }
    }

    toggleVerifyEmail = (val) => {
        this.setState({
            isLoginSubmitted: val
        });
    };

    resendVerifyEmail = () => {
        if(this.props.user){
            this.props.dispatch(resendVerifyUserEmail(this.props.user._id))
        }
    };

    handleLogoClick = ()=>{
        this.props.dispatch(logOutUser());
     }

    render() {
        return (
            <>
            <div className="container-fluid banner">
            <Helmet>
            <title>{'Admin Login | Recur Club'}</title>
            </Helmet>
            <div className="recur-logo">
            <img src='/assets/recur.svg' onClick = {this.handleLogoClick} alt=""/>
            </div>
            <div className="row">
            <div className="col-md-6 col-sm-12 recur-revenue">
            <p className="for-companies-with-revenue">FOR COMPANIES WITH RECURRING REVENUE</p>
        <span><img alt="" src='/assets/invalid-name.png' style={{position: 'absolute',left: '-23px'}}/></span> <h3 className="access-to-your-annual">Access
        to your annual cashflow - Today!<img alt="" src='/assets/invalid-name-right.png' className="login-inverted-comma"/></h3>
            <p className="recur-allows-you">Recur allows you to get instant cash for your recurring
        revenue without debt or dilution.</p>
        </div>
        <LoginForm
        {...this.props}
        toggleVerifyEmail={(val) => this.toggleVerifyEmail(val)}/>
        </div>
        </div>
        </>
    );
    }
}

function mapStateToProps(state, props) {
    return {
        fromState: props.location && props.location.state && props.location.state.from ? props.location.state.from : "",
        urlQuery: props.location.search,
        isLoggingIn: getLoggingState(state),
        user: getUserDetails(state),
        isAuthenticated: getAuthenticatedState(state),
        isUserApproved: getApprovedState(state),
        isEmailVerified: getEmailVerifiedState(state),
        is_fetching_user: getUserProfileFetchingState(state),
    }
}

export default connect(mapStateToProps)(LoginPage);
