import moment from "moment";
import { useEffect } from "react";
// import {Link} from 'react-router-dom';

function RepaymentsTableRow(props) {

  useEffect(() => {
    return () => {
      props.emptySelectedIds()
    }
  }, [])

  return (
    <tr>
      <td className='blue-table-cel text-center' style={{ width: '5%' }}>
        <input
          type='checkbox'
          value={props.item._id}
          style={{ marginLeft: "10px", marginRight: "10px" }}
          checked={props.selected_repayment_ids.some(
            (i) => i === props.item._id
          )}
          onChange={props.onChangeTransactionCheckbox.bind(this)}
        />
        {props.item.name}
      </td>
      <td style={{ maxWidth: '100px', width: '13%' }}>
        {props.item.investor_organization_name}
      </td>
      {/* <td>{props.item.investor_name}</td> */}
      <td className="text-truncate" style={{ maxWidth: '100px', width: '9%' }}>
        <span data-bs-toggle="tooltip" data-bs-html="true" title={props.item.investee_organization_name}>
          {props.item.investee_organization_name}
        </span>
      </td>
      <td style={{ width: '9%' }}>{props.item.contract_id_num}</td>
      <td style={{ width: '9%' }}>{props.item.payment_number}</td>
      <td style={{ width: '9%' }}>
        <i class='fas fa-rupee-sign'></i> {props.item.transaction_amount}
      </td>
      <td style={{ width: '9%' }}>
        {moment(props.item.scheduled_recur_payment_date).format("DD MMM YYYY")}
      </td>
      <td style={{ width: '9%' }}>
        <span className='bold-table-cel'>{props.item.transaction_status}</span>
      </td>
      <td className="text-truncate" style={{ maxWidth: '100px', width: '9%' }}>
        <span data-bs-toggle="tooltip" data-bs-html="true" title={props.item.transaction_id}>
          {props.item.transaction_id}
        </span>
      </td>
      <td style={{ width: '9%' }}>{moment(props.item.updated_at).format("DD MMM YYYY")}</td>
      <td style={{ width: '10%' }}>{props.item.investor_repayment_status ? props.item.investor_repayment_status : '-'}</td>
    </tr>
  );
}

export default RepaymentsTableRow;
