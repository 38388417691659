import axios from 'axios';
import { useState } from 'react';
import { applyInterceptor } from './Interceptor.ts';
import { useDispatch } from 'react-redux';

function NavigateFunctionComponent() {
    const [ran, setRan] = useState(false);
    const dispatch = useDispatch();
    /* only run setup once */
    if (!ran) {
        applyInterceptor(axios,dispatch);
        setRan(true);
    }
    return <></>;
}

export default NavigateFunctionComponent;
