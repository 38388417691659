import React, { DragEvent, useEffect, useRef, useState } from 'react';
import './UploadCard.module.scss';
// @ts-ignore
import styles from './UploadCard.module.scss';
import { customTimeout, showNotification } from '../../util/utility.js';
import { API_STATUS, SHOW_NOTIFICATION_STATUS } from '../../constants/enums.js';
import callApi from '../../util/apiCaller.js';
import {
    CATEGORIES,
    getFileExtension,
    getInputAcceptValue,
    handleFilesValidation,
    modifyFileNameBeforeUpload,
} from './uploadDocUtils';

import { getSignedUrlToUpload,
    saveDocMetaDataInDocService,
    deleteFileFromDocService,
    fetchPresignedUrl,
    deleteFile
 } from '../../common/ApiHelpers'


import { UploadAnotherFile, UploadDocBox } from './UploadButtons';
import SingleFile from './SingleFile';
// import { useSearchParams } from 'react-router-dom';

interface SelectedFile {
    file?: File;
    id?: number;
    isUploading?: boolean;
    uploadingFailed?: boolean;
}

export default function UploadCard({
    onSuccess,
    hideDragDrop,
    isInvestor,
    path,
    mainText,
    noOfFiles,
    deleteKeyName,
    keyName,
    category,
    files,
    hideUploadAnother = false,
    onDelete,
    isTally = false,
    renderComponent,
    allowMultiple = false,
    isMnemosyneFlow = false,
    truncateSmallerText = false,
    fileTypeAllowed,
    disabled,
    hideDeleteIcon = false,
    investeeOrganizationId,
    investorOrganizationId,
}: any) {

    const { UploadDocBox, ProcessingContainer, ProcessingFile, textTruncate, downloadable, smallText, ProgressContainer, Bar } = styles;

    const countDownLimit = 25;
    const [params, setParams] = new URLSearchParams();
    const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isDownloading, setIsDownloading] = useState<string | null>(null);
    const [disableDelButton, setDisableDelButton] = useState(false);
    const [isDeletingFile, setIsDeletingFile] = useState<string | null>(null);
    const [countDown, setCountDown] = useState(countDownLimit);
    const [startTimer, setStartTimer] = useState(false);
    const inputFileRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const callingApi = useRef(false);
    const extensionCategory = category;

    const handleClick = () => {
        if (!disabled) inputFileRef.current.click();
    };

    const fileTypeExtensionMap: Record<string, string> = {
        pdf: 'application/pdf',
    };

    /**
     * splitting the mnemosyne and core flow for upload document
     */
    useEffect(() => {
        if (callingApi.current) {
            handleSubmissionMnemosyne();
        }
        // eslint-disable-next-line
    }, [selectedFiles]);

    //required for mnemosyne link generation
    const getcategoryId = () => {
        switch (category) {
            case CATEGORIES.FD:
            case CATEGORIES.OD:
                return 9;
            case CATEGORIES.TDS:
                return 4;
            case CATEGORIES.CP:
                return 22;
            case CATEGORIES.CUSTOMER_SUPPORT_OTHER:
            case CATEGORIES.CUSTOMER_SUPPORT:
                return 23;
            default:
                return 0;
        }
    };
    const getFlowId = () => {
        switch (category) {
            case CATEGORIES.FD:
            case CATEGORIES.OD:
                return 3;
            case CATEGORIES.TDS:
            case CATEGORIES.CP:
                return 1;
            case CATEGORIES.CUSTOMER_SUPPORT_OTHER:    
            case CATEGORIES.CUSTOMER_SUPPORT:
                return 5;
            default:
                return 0;
        }
    };

    const onFileChangeCapture = async (e: any, isDropped = false) => {
        const filesArr = isDropped ? [...e.dataTransfer.files] : [...e.target.files];
        const { filteredFiles } = handleFilesValidation(filesArr, category, keyName);
        if (filteredFiles.length === 0) {
            setIsUploading(false);
            return;
        }
        if(category===CATEGORIES.CUSTOMER_SUPPORT_OTHER && noOfFiles>10){
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, `Cannot upload more than ${noOfFiles} files`);
                return;
        }
        let filesToBeMapped = filteredFiles;
        const files = filesToBeMapped?.map((file, index) => {
            return {
                file: file,
                id: index + 1,
                isUploading: true,
                uploadProgress: 0,
            };
        });

        callingApi.current = true;
        setSelectedFiles(files);
    };

    useEffect(() => {
        if (startTimer) {
            const interval = setInterval(() => {
                setCountDown((prevCountDown) => prevCountDown - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setCountDown(countDownLimit);
        }
    }, [startTimer]);


    const deletingFile = async (docLink: string, docId: string) => {
        setIsDeletingFile(docLink);
        let data_to_post: any = {};
        data_to_post.keyname = deleteKeyName ?? keyName;
        data_to_post.doc_link = docLink;

        const deleteResponse = await deleteFile(
            {
                investeeOrganizationId,
                path,
            },
            data_to_post,
            false
        );

        if (deleteResponse?.status === 'Success') {
            const data = deleteResponse?.data?.investeeOnboardingData;
            if (onDelete) onDelete(data);
            else if (onSuccess) onSuccess(data, docId);
        } else {
            const message = deleteResponse.data?.message
                ? deleteResponse.data?.message
                : 'Some error occurred!';
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
        setIsDeletingFile(null);
        setDisableDelButton(false);
    };

    //delete from mnemosyne
    const deleteFileFromMnemosyne = async (docLink: string) => {
        setIsDeletingFile(docLink);
        await deleteFileFromDocService({
            docId: Number(docLink),
            onSuccess: () => {
                if (onDelete) {
                    const res = onDelete(docLink);
                    if (res) {
                        setIsDeletingFile(null);
                        setDisableDelButton(false);
                    }
                }
            },
        });
    };
    useEffect(() => {
        if(category===CATEGORIES.CUSTOMER_SUPPORT_OTHER) {
            if (isUploading)
                setDisableDelButton(true)
            else if (!isUploading) setDisableDelButton(false)
        }
    }, [isUploading]);
    //splitting the delete flow for mnemosyne and core
    const handleDeleteFile = (docLink: string, docId: string, docName: string) => {
        if (!disableDelButton) {
            if (inputFileRef.current) inputFileRef.current.value = '';
            setDisableDelButton(true);
            if (!isMnemosyneFlow) {
                deletingFile(docLink, docId);
            } else {
                deleteFileFromMnemosyne(docLink);
            }
        }
    };

    //download functionlity provided by mnemosyne
    const downloadFileFromMnemosyne = async (url: string) => {
        const response = await fetchPresignedUrl(Number(url));
        if (response) {
            const url = response.uri;
            // download the file in new tab
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.click();
            setIsDownloading(null);
        } else {
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Not Able to fetch the file to show');
        }
    };

    //splitting the mnemosyne and core flow for downloading individual files
    const downloadFile = (url: string, fileName: string, isErrorFile = false) => {
        if (isMnemosyneFlow) {
            downloadFileFromMnemosyne(url);
            return;
        }
        const commonStringInUrl = 'amazonaws.com/';
        const indexOfCommonString = url.indexOf(commonStringInUrl);
        const urlToSend = isErrorFile
            ? url
            : url.slice(indexOfCommonString + commonStringInUrl.length);
        let input: {
            url?: string;
        } = {};
        input.url = urlToSend;
        setIsDownloading(url);
        callApi(`download-file`, 'post', input)
            .then((res: any) => {
                if (res.status === API_STATUS.SUCCESS) {
                    let array = new Uint8Array(res.data.file.Body.data);
                    const blob = new Blob([array]);
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloading(null);
                } else {
                    showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Some error occurred');
                    setIsDownloading(null);
                }
            })
            .catch((err: any) => {
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Some error occurred');
                setIsDownloading(null);
            });
    };

    const handleDrop = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!hideUploadAnother) {
            if (fileTypeAllowed) {
                const fileTypeExtension = fileTypeAllowed.map(
                    (ext: string) => fileTypeExtensionMap[ext] || '',
                );
                const droppedFiles = Array.from(e.dataTransfer.files);
                // If non-PDF files
                const nonPdfFiles = droppedFiles.filter(
                    (file) => !fileTypeExtension.includes(file.type),
                );
                if (nonPdfFiles.length > 0) {
                    showNotification('error', 'Upload PDF files only');
                    return;
                }
            }
            if (!disabled) {
                onFileChangeCapture(e, true);
                e.dataTransfer.clearData();
            } else {
                showNotification(
                    SHOW_NOTIFICATION_STATUS.ERROR,
                    "Can't upload multiple files at a time",
                );
            }
        }
    };

    const handleDrag = (e: DragEvent) => {
        if (!disabled) {
            e.preventDefault();
            e.stopPropagation();
            if (e.type === 'dragenter' || e.type === 'dragover') {
                // setDragActive(true);
                e.dataTransfer.dropEffect = 'copy';
            } else if (e.type === 'dragleave') {
                // setDragActive(false);
            }
        }
    };

    /** flow for mnemosynupload
     * 1. Modify File name
     * 2. one by one generate signed URL from mnemosyne
     * 3. Upload on that generating S3 link
     * 4. Save metadata in mnemosyne
     * 5. Save metadata in investeeOnboardingDoc olympus
     */
    const handleSubmissionMnemosyne = async () => {
        const selected_files = [...selectedFiles];
        setStartTimer(true);
        setIsUploading(true);
        let index = 0;
        callingApi.current = false;

        for (const fileObj of selected_files) {
            const { file, id } = fileObj as { file: File; id: number };

            let file_name = modifyFileNameBeforeUpload(file.name, category, isTally);
            const input = {
                fileName: file_name,
                flowId: getFlowId(),
                categoryId: getcategoryId(),
            };

            setSelectedFiles((files) =>
                files.map((file, i) => ({
                    ...file,
                    ...(i === 0 && {
                        uploadProgress: 0,
                    }),
                })),
            );
            await uploadDocumentMnemosyne(index, id, file, input);
            index++;
        }
    };
    const uploadDocumentMnemosyne = async (index: number, id: number, file: File, input: any) => {
        try {
            const { signedUrl, docTypeId } = await getSignedUrlToUpload({
                input: {
                    categoryId: input.categoryId,
                    fileName: input.fileName,
                },
                orgId: investeeOrganizationId ?? investorOrganizationId,
            });

            await customTimeout(500, () => {
                setSelectedFiles((files) =>
                    files.map((file, i) => ({
                        ...file,
                        ...(i === 0 && {
                            uploadProgress: 20,
                        }),
                    })),
                );
            });

            const requestOptions = {
                method: 'put',
                body: file,
                headers: new Headers({
                    'Content-Type': file.type,
                }),
            };

            if (signedUrl) {
                const uploadResponse: any = await fetch(signedUrl, requestOptions);
                if (uploadResponse.ok) {
                    await customTimeout(500, () => {
                        setSelectedFiles((files) =>
                            files.map((file, i) => ({
                                ...file,
                                ...(i === 0 && {
                                    uploadProgress: 40,
                                }),
                            })),
                        );
                    });
                    const input_saveMetadata = {
                        categoryId: input?.categoryId,
                        fileName: input?.fileName,
                        fileSizeKb: file.size / 1_000,
                        flowId: input.flowId,
                    };
                    const docData = await saveDocMetaDataInDocService({
                        input: input_saveMetadata,
                        orgId: investeeOrganizationId ?? investorOrganizationId,
                    });
                    await customTimeout(500, () => {
                        setSelectedFiles((files) =>
                            files.map((file, i) => ({
                                ...file,
                                ...(i === 0 && {
                                    uploadProgress: 60,
                                }),
                            })),
                        );
                    });

                    const docId = docData?.id;
                    if (docId) {
                        onSuccess(docId, input?.fileName);
                        await customTimeout(500, () => {
                            setSelectedFiles((files) =>
                                files.map((file, i) => ({
                                    ...file,
                                    ...(i === 0 && {
                                        uploadProgress: 100,
                                        isUploading: false,
                                    }),
                                })),
                            );
                        });
                        setIsUploading(false);
                        setSelectedFiles((files) => {
                            if (files?.length === 1) return [];
                            else return files.filter((file, i) => 0 !== i);
                        });
                    }
                } else {
                    throw new Error(uploadResponse);
                }
            } else throw new Error("Couldn't receive signed url!");
        } catch (err) {
            showNotification(
                SHOW_NOTIFICATION_STATUS.ERROR,
                'Failed to upload, something went wrong',
            );
        }
    };
    const completeFileUpload = async () => {
        setStartTimer(false);
        await customTimeout(500, () => {
            setSelectedFiles((files) =>
                files.map((file, i) => ({
                    ...file,
                    ...(i === 0 && {
                        uploadProgress: 100,
                        isUploading: false,
                    }),
                })),
            );
        });
        setSelectedFiles((files) => {
            if (files?.length === 1) return [];
            else return files.filter((file, i) => 0 !== i);
        });
        setIsUploading(false);
        setDisableDelButton(false);
    };

    return (
        <>
            <div
                style={hideDragDrop ? { height: 0 } : { height: '6em' }}
                className={UploadDocBox}
                onDrop={handleDrop}
                onDragOver={handleDrag}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
            >
                <input
                    type="file"
                    ref={inputFileRef}
                    onChangeCapture={onFileChangeCapture}
                    style={{ display: 'none' }}
                    onClick={(e) => (e.currentTarget.value = '')}
                    accept={getInputAcceptValue(extensionCategory, keyName)}
                    multiple={allowMultiple}
                />

                {!!!renderComponent ? (
                    <div className="text-grey15 fs-10 ff-gm" style={{ marginBottom: '5px' }}>
                        {mainText}
                    </div>
                ) : (
                    <div
                        onClick={handleClick}
                        onDrop={handleDrop}
                        onDragOver={handleDrag}
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        style={
                            isUploading || disabled ? { cursor: 'no-drop' } : { cursor: 'pointer' }
                        }
                    >
                        {renderComponent}
                    </div>
                )}
            </div>
            <div>
                {files?.map((file: any, index: number) => {
                    if (!file.source && !file?.is_deleted) {
                        return (
                            <SingleFile
                                disabled={disabled}
                                isInvestor={isInvestor}
                                hideDeleteIcon={hideDeleteIcon}
                                key={index}
                                file={file}
                                downloadFile={downloadFile}
                                deleteFile={handleDeleteFile}
                                isDownloading={isDownloading}
                                isDeletingFile={isDeletingFile}
                                customStyleForFileName={isInvestor ? { color: '#6021B3' } : {}}
                                category={category}
                                isTally={isTally}
                                truncateSmallerText={truncateSmallerText}
                            />
                        );
                    }
                    return null;
                })}
                {selectedFiles?.length
                    ? selectedFiles?.map((file: any, index: number) => (
                          <div className={ProcessingContainer} key={index}>
                              <div
                                  className={ProcessingFile}
                                  key={`${file.id}-${file.name}`}
                              >
                                  <span
                                      style={isInvestor ? { color: '#6021B3' } : {}}
                                      className={`${styles.textTruncate} ${styles.downloadable}`}
                                      title={file.doc_name}
                                  >
                                      {file.file.name}
                                  </span>
                              </div>
                              {file?.isUploading ? (
                                  <div className={ProgressContainer}>
                                      <div
                                          className={Bar}
                                          style={
                                              isInvestor
                                                  ? {
                                                        width: `${file.uploadProgress}%`,
                                                        backgroundColor: '#6021B3',
                                                    }
                                                  : { width: `${file.uploadProgress}%` }
                                          }
                                      ></div>
                                  </div>
                              ) : null}
                          </div>
                      ))
                    : null}

                {!renderComponent && !isUploading && (
                    <>
                        {files?.length ? (
                            hideUploadAnother ? (
                                <></>
                            ) : (
                                <UploadAnotherFile handleClick={handleClick} />
                            )
                        ) : (
                            <UploadDocBox
                                handleClick={handleClick}
                                handleDrag={handleDrag}
                                handleDrop={handleDrop}
                                mainText={mainText}
                            />
                        )}
                    </>
                )}

            </div>
        </>
    );
}
