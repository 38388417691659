import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useState } from 'react';
import { API_STATUS } from '../../../../../constants/enums';
import callApi from '../../../../../util/apiCaller';
import { priceFormat, showNotification } from '../../../../../util/utility';
import './RecurCheckoutTableRow.scss';

export default function RecurCheckoutTableRow({ data, updateCheckoutData, index }) {
    const [isCustomerPayment, setIsCustomerPayment] = useState(false);
    const [contractName, setContractName] = useState('');
    const [typingTimeout, setTypingTimeout] = useState(0);
    const [contracts, setContracts] = useState();

    useEffect(() => {
        if (data) {
            const { is_customer_payment, contractName: name } = data ?? {
                is_customer_payment: false,
                contractName: ''
            };
            setContractName(name);
            setIsCustomerPayment(is_customer_payment);
        }
    }, [data]);

    const toggleIsCustomerPayment = () => {
        updateCheckoutData({ accountStatementId: data._id, isCustomerPayment: !isCustomerPayment, index });
    }

    const searchChange = (e) => {
        const { value } = e.target;
        if (typingTimeout) clearTimeout(typingTimeout);
        setContractName(value);
        if (value && value.length > 1) {
            setTypingTimeout(setTimeout(() => fetchContractsOptions(e.target.value), 700));
        } else if (contracts && contracts.length !== 0) setContracts(null);
    }

    const fetchContractsOptions = (value) => {
        callApi("recur-checkout/get-all-contracts", "post", {
            investeeOrganizationId: data.investeeOrganizationId,
            contractName: value
        }).then(res => {
            if (res.status === API_STATUS.SUCCESS) {
                if (res.data && res.data.length !== 0) setContracts(res.data);
            }
            else {
                const message = res.data?.message ?? "Some error occurred!";
                showNotification("Error", message);
            };
        });
    }

    const updateContract = ({ contract, index }) => {
        updateCheckoutData({ accountStatementId: data._id, contract, index, cb: () => setContracts(null) });
    }

    const renderContractsOptions = (contracts) => {
        const caseInsensitiveReplacement = (str, search) => {
            const preg_quote = (str, delimiter) => {
                return (str + '').replace(
                    new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\' + (delimiter || '') + '-]', 'g'),
                    '\\$&',
                );
            }
            return str.replace(new RegExp('(' + preg_quote(search) + ')', 'gi'), ',$1,');
        }

        const splitWithoutRemovingDelimiter = ({ mainStr, subStr }) => {
            if (typeof mainStr !== 'string' || typeof subStr !== 'string') return;
            const newStr = caseInsensitiveReplacement(mainStr, subStr);
            return newStr.split(',');
        }

        return contracts.map((contract) => {
            const arrToRender = splitWithoutRemovingDelimiter({ mainStr: contract.name, subStr: contractName });
            return (
                <div
                    key={contract._id}
                    onClick={() => updateContract({ contract, index })}
                    className='CustomerName'
                >
                    {arrToRender.map((name, index) => (
                        <span className={index % 2 !== 0 ? 'HighlightText' : null}>{name}</span>
                    ))}
                </div>
            );
        });
    }

    const { txn_date, orgDisplayName, amount, contract_added_by } = data;
    const canEditContractName = contract_added_by !== "Company" && isCustomerPayment;
    return (
        <tr className='CheckoutRow'>
            <td>
                {txn_date ? dayjs(txn_date).format('DD MMM YYYY') : '-'}
            </td>
            <td>
                {orgDisplayName ?? '-'}
            </td>
            <td>
                <div className='CustomPayment' onClick={toggleIsCustomerPayment}>
                    <span
                        className={isCustomerPayment ? '' : 'NoActive'}>
                        NO
                    </span>
                    <span
                        className={isCustomerPayment ? 'YesActive' : ''}>
                        YES
                    </span>
                </div>
            </td>
            <td>
                <div className='HasSearch'>
                    <input
                        type="search"
                        value={contractName}
                        placeholder="Select Customer"
                        onChange={searchChange}
                        required
                        disabled={!canEditContractName}
                    />
                    {canEditContractName && (
                        <span className='SearchLogo'>
                            <img alt="" src="../../../../../assets/search.png" />
                        </span>
                    )}
                </div>
                {contracts && contracts.length > 0 && (
                    <div className='BoxWithBorder'>{renderContractsOptions(contracts)}</div>
                )}
            </td>
            <td>
                {amount ? '₹ ' + priceFormat(amount, 2) : 'NA'}
            </td>
            <td>
                {/* <span className='In_Process'>in process</span> */}
                <span className='Collected'>Collected</span>
            </td>
        </tr>
    );
}
