import React from // Component
"react";
// import {Link} from 'react-router-dom';
import FilterHeaderComponent from "../../../../../components/FilterHeaderComponent/FilterHeaderComponent";

function TradesTableHeader(props) {
  return (
    <thead className='team-head auto-invest-table'>
      <tr>
        <th style={{ position: 'unset' }} className="pl-2">Investor Org</th>
        <th style={{ position: 'unset' }}>Auto Invest Name</th>
        <th style={{ position: 'unset' }}>Funds Avl.</th>
        <th style={{ position: 'unset' }}>Target IRR</th>
        <th style={{ position: 'unset' }}>Max. Investment</th>
        <th style={{ position: 'unset' }}>Concentration</th>
        <th style={{ position: 'unset' }}>Repayments</th>
        <th style={{ position: 'unset' }}>% Invested</th>
        <th style={{ position: 'unset' }}>Auto Invest ID</th>
        <th style={{ position: 'unset' }}>Status</th>
      </tr>
    </thead>
  );
}

export default TradesTableHeader;
