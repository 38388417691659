import React, {Component} from 'react';
import HelpRequestTableHeader from './components/HelpRequestTableHeader';
import HelpRequestTableRow from './components/HelpRequestTableRow';
import AdminHead from '../../Admin/components/AdminHead.js'
import callApi from "../../../util/apiCaller";
import {showNotification} from "../../../util/utility";
import {Button, Modal} from "react-bootstrap";
import _ from 'lodash';

class PdfTemplateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pdftemplates: [],
            pageNum: 1,
            pageSize: 200,
            totalCount: 0,
            qtext: '',
            editItem: {},
            isAdding: false
        }
    }

    componentDidMount() {
        this.fetchItems();
    }


    fetchItems = () => {
        let filters = this.getFilters();
        callApi(`pdfs/list`, "post", filters).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    pdftemplates: res.data.pdftemplates,
                    totalCount: res.data.totalCount
                })
            } else {
                showNotification("error", "Some error occured");
            }
        });
    }

    addItems = () => {
            let item = {"dummy string": "Dummy String"};     
            this.toggleEditItem(item);
            this.setState({
                isAdding : true
            })
    }

    getFilters = () => {
        let filters = {};
        filters.pageNum = this.state.pageNum;
        filters.pageSize = this.state.pageSize;
        return filters;
    };

    handleApplyFilters = () => {
        this.fetchItems();
    };

    handleSearchFilter = (obj) => {
        let searchObj ={};
        searchObj[this.keyStateObjNameDict[obj.keyName]]=obj
        this.setState(searchObj, () => {
            this.handleApplyFilters();
        })
    };

    inputSelectorHandler = (obj) =>{
        let selectorObj ={};
        selectorObj[this.keyStateObjNameDict[obj.keyName]]=obj
        this.setState(
            selectorObj,() => {
            this.handleApplyFilters();
        })
    }

    handleChange = (keyname, keyvalue) => {
        let editItem = this.state.editItem;
        editItem[keyname] = keyvalue;
        this.setState({
            editItem: editItem
        })
    };

    saveTemplate = () => {
        let editItem = this.state.editItem;
        this.setState({
            isSaving: true
        });
        callApi(`pdfs/${editItem._id}/data-update`, "post", {
            pdf : editItem
        }).then((res) => {
            this.setState({
                isSaving: false
            });
            if (res.status === 'Success') {
                showNotification("success", "Template Updated");
            } else {
                showNotification("error", "Some error occured");
            }
        });
    };

    addTemplate = () => {
        let editItem = this.state.editItem;
        callApi(`pdfs/create-new`, "post", {
            pdf : editItem
        }).then((res) => {
            if (res.status === 'Success') {
                this.fetchItems();
                this.handleClose();
                showNotification("success", "Template Added");
            } else {
                showNotification("error", "Some error occured");
            }
        });
        this.setState({
            isAdding: false
        })
    };

    toggleEditItem = (editItem) => {
        this.setState({
            editItem: editItem
        })
    };

    handleClose = () => {
        this.setState({
            isAdding : false
        })
        this.toggleEditItem({});
    }
    preview = (html) => {
        let  win =window.open(" ",'scrollbars=1');
        win.document.write("<div id ='popup'>"+html+"</div>");
        }
    render() {
        return (
            <div className="row" style={{width:'100%'}}>
                <AdminHead/>
                <section id="admin-company" class="col">
                <Button
                    variant='primary'
                    onClick={() => {
                        this.addItems()}}>
                    Add New
                </Button>
                    <div className="request-data-table tableFixHead">
                        <table className="table team">
                            <HelpRequestTableHeader
                                searchObjContactName = {this.state.searchObjContactName}
                                searchObjIssueDesc = {this.state.searchObjIssueDesc}
                                selectorObjIssueType = {this.state.selectorObjIssueType}
                                inputSelectorHandler = {this.inputSelectorHandler}
                                handleSearchFilter = {this.handleSearchFilter}
                            />
                            <tbody className="team-body">
                            {this.state.pdftemplates && this.state.pdftemplates.map(item => (
                                <HelpRequestTableRow
                                    item={item}
                                    toggleEditItem={this.toggleEditItem}
                                />
                            ))}
                            </tbody>
                        </table>
                    </div>
                </section>
                {(!_.isEmpty(this.state.editItem)) ?
                    <Modal
                        show={!_.isEmpty(this.state.editItem)}
                        onHide={this.handleClose}
                        size='lg'
                        aria-labelledby='contained-modal-title-vcenter'
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Template</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                            <div className="row">
                                   <input
                                       type={'text'}
                                       value={this.state.editItem.template_name}
                                       onChange={(e) => this.handleChange('template_name', e.target.value)}
                                   />
                               </div>
                                <div className="row">
                                   <input
                                       type={'text'}
                                       value={this.state.editItem.template_type}
                                       onChange={(e) => this.handleChange('template_type', e.target.value)}
                                   />
                               </div>
                                <div className="row">
                                    <textarea
                                        value={this.state.editItem.html}
                                        rows='10'
                                        cols='50'
                                        style={{}}
                                        onChange={(e) => this.handleChange('html', e.target.value)}
                                    />
                                </div>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant='secondary' onClick={() => this.handleClose()}>
                                Close
                            </Button>
                            <Button
                                variant='primary'
                                disabled={this.state.isSaving}
                                onClick={() => this.state.isAdding ? this.addTemplate() : this.saveTemplate()}>
                                {this.state.isSaving ? "Saving" : "Save"}
                            </Button>
                            <Button
                                variant='primary'
                                onClick={() => this.preview(this.state.editItem.html)}>
                               <i class="fa fa-eye" aria-hidden="true"></i> Preview
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    :
                    null
                }

            </div>
        );
    }
}

export default PdfTemplateList;
