import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DOC_SERVICE_RESPONSE, FLOW_ID, GENERIC_ERROR } from '../../../../../../../../constants/enums';
import callApi from '../../../../../../../../util/apiCaller';
import { downloadFileUsingFetch, generateRequestId, showNotification } from '../../../../../../../../util/utility';
import { getDataSpecialist } from '../../../../../../../App/AppReducer';
import DocumentVaultCards from './components/DocumentVaultCards';
import DocumentVaultTable from './components/DocumentVaultTable';
import { TOTAL_COUNT, VERIFIED_COUNT } from './consts/consts';
import { getInvesteesOrganization } from '../../../../../../AdminReducers';

export default function DocumentVault() {
    const DOC_SCORE = "docScore";
    const MODERATOR_NAME = "moderatorName";
    const DOC_SHARED_WITH_INVESTOR = "docSharedWithInvestor";
    const LAST_UPDATED = "updatedAt";
    const [docsData, setDocsData] = useState();
    const [docsScoreData, setDocsScoreData] = useState();
    const [docScoreDataArr, setDocsScoreDataArr] = useState();
    const dataSpecialist = useSelector(getDataSpecialist);
    const investeeOrganizationId = useSelector(getInvesteesOrganization)?._id;
    const organizationName = useSelector(getInvesteesOrganization)?.name;
    const [isDownloadAllDocs, setIsDownloadAllDocs] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (docsScoreData) updateDocScoreDataArr();
    }, [docsScoreData]);

    const updateDocScoreDataArr = () => {
        const defaultDocScoreObj = { label: "Documents Score", [VERIFIED_COUNT]: 0, [TOTAL_COUNT]: 0 };
        const defaultModeratorObj = { label: "Moderator", value: dataSpecialist?.agent_name ?? "N/A" };
        const defaultDocSharedWithInvestorObj = { label: "Documents shared with Investor", [VERIFIED_COUNT]: 0, [TOTAL_COUNT]: 0 };
        const defaultLastUpdatedObj = { label: "Last Updated", value: "N/A" };
        const arr = [];
        arr.push(docsScoreData[DOC_SCORE] ? {
            ...defaultDocScoreObj,
            ...docsScoreData[DOC_SCORE],
        } : defaultDocScoreObj);
        arr.push(docsScoreData[MODERATOR_NAME] ? {
            ...defaultModeratorObj,
            value: docsScoreData[MODERATOR_NAME] ?? dataSpecialist?.agent_name,
        } : defaultModeratorObj);
        arr.push(docsScoreData[DOC_SHARED_WITH_INVESTOR] ? {
            ...defaultDocSharedWithInvestorObj,
            ...docsScoreData[DOC_SHARED_WITH_INVESTOR],
        } : defaultDocSharedWithInvestorObj);
        arr.push(docsScoreData[LAST_UPDATED] ? {
            ...defaultLastUpdatedObj,
            value: (docsScoreData[LAST_UPDATED] ?? 'N/A'),
        } : defaultLastUpdatedObj);
        setDocsScoreDataArr(arr);
    }
    const downloadAllFiles = () => {
        setIsDownloadAllDocs(true);
        downloadFileUsingFetch(
            `file/getAllFilesForPrefixData?downloadRequest=UnsignedDocs&organizationId=${investeeOrganizationId}&requestId=${generateRequestId()}`,
            "get",
            {
                responseType: "blob",
            },
            `${organizationName}_${investeeOrganizationId}.zip`,
            () => {setIsDownloadAllDocs(false)},
            null,null, false , true 
        );
    };

    const fetchAllDocs = async() => {
        const res = await callApi("file/fetchAllDocs", "post", {
            flowId: FLOW_ID.KYC
        }, {}, false, false, false, false, false, true);
        if (res?.responseData?.responseCode === DOC_SERVICE_RESPONSE.SUCCESS) setDocsData(res?.docs ?? {});
        else {
            const message = res.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification("Error", message);
        }
    }

    const fetchDocStats = async() => {
        const res = await callApi("file/getDocumentStats", "post", {
            flowId: FLOW_ID.KYC
        }, {}, false, false, false, false, false, true);
        if (res?.responseData?.responseCode === DOC_SERVICE_RESPONSE.SUCCESS) setDocsScoreData(res?.docStats ?? {});
        else {
            const message = res.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification("Error", message);
        }
    }

    const fetchData = () => {
        fetchDocStats();
        fetchAllDocs();
    }

    return (
        <div className='m-4'>
            <div className='row'>
                {docScoreDataArr && docScoreDataArr.map((item, index) => (
                    <div key={index} className='col-md-3'>
                        <DocumentVaultCards item={item} />
                    </div>
                ))}
            </div>
            <DocumentVaultTable
                docsData={docsData}
                moderatorName={docsScoreData?.[MODERATOR_NAME]}
                fetchData={fetchData}
                downloadAllFiles = {downloadAllFiles} 
                isDownloadAllDocs = {isDownloadAllDocs}
            />
        </div>
    )
}
