import React from "react";
import { Tabs } from "react-bootstrap";
import styles from "./StepperTabs.module.scss";
import { MODERATION } from "../../constants/enums";
import { useDispatch, useSelector } from "react-redux";
import { updateToggleInfo } from "../../modules/Admin/AdminActions";
import { getUserName } from "../../modules/App/AppReducer";

const {
  stepTabs,
  stepStyle,
  disabled,
  greyBackground,
  greenColor,
  blackColor,
  greenBackground,
  toggle,
} = styles;

function StepperTabs({
  Steps,
  currentStep,
  setCurrentStep,
  rrfTermToggle,
  setRrfTermToggle,
  debtSyndicationToggle,
  setDebtSyndicationToggle,
  id,
  isAddOn,
  toggleState,
  isApplicationExpired = false,
  toggleEnabled = true,
}) {
  const userName = useSelector((state) => getUserName(state));
  const dispatch = useDispatch();
  const handleToggle = (label) => {
    return () => {
      const value = {};
      const input = {};
      input.isAddOn = isAddOn;
      value.updatedBy = userName;
      if (label === MODERATION.RRF_TERM) {
        if (rrfTermToggle === true) {
          value.isOn = false;
          setRrfTermToggle(false);
        } else {
          value.isOn = true;
          setRrfTermToggle(true);
        }
        input.rrfToggle = value;
        dispatch(updateToggleInfo(id, input));
      } else if (label === MODERATION.DEBT_SYNDICATION_TERM) {
        if (debtSyndicationToggle === true) {
          setDebtSyndicationToggle(false);
          value.isOn = false;
        } else {
          setDebtSyndicationToggle(true);
          value.isOn = true;
        }
        input.debtSyndicationToggle = value;
        dispatch(updateToggleInfo(id, input));
      }
    };
  };
  const checkOn = (label) => {
    if (label === MODERATION.DEBT_SYNDICATION_TERM) {
      return debtSyndicationToggle === true;
    } else {
      return rrfTermToggle === true;
    }
  };

  return (
    <div className={stepTabs}>
      {Steps.map((step, index) => {
        const toggleType =
          step.label === "Swift RRF Terms" ? "rrfToggle" : "debtSynToggle";
        return (
          <>
            <div
              key={index}
              onClick={() => (toggleEnabled || isApplicationExpired) && setCurrentStep(step.value)}
              className={`${stepStyle} ${(toggleEnabled || isApplicationExpired) ? '' : disabled}`}
            >
              <div
                className={
                  currentStep === step.value ? greenBackground : greyBackground
                }
              >
                {index + 1}
              </div>
              <div
                style={{
                  marginTop: "-6px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                }}
              >
                <div
                  className={
                    currentStep === step.value ? greenColor : blackColor
                  }
                  style={{ width: "100%", justifyContent: "left" }}
                >
                  {step.label}
                  {(step.label === MODERATION.RRF_TERM ||
                    step.label === MODERATION.DEBT_SYNDICATION_TERM) && toggleEnabled && (
                    <div className={toggle}>
                      <label
                        className="checkbox-container"
                        style={{ width: "60px", marginLeft: "25px" }}
                      >
                        <input
                          type="checkbox"
                          checked={checkOn(step.label)}
                          disabled={isApplicationExpired}
                          onChange={handleToggle(step.label)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  )}
                </div>
                {(step.label === MODERATION.RRF_TERM ||
                  step.label === MODERATION.DEBT_SYNDICATION_TERM) && (
                  <div
                    style={{
                      marginLeft: "0px",
                      justifyContent: "left",
                      fontFamily: "Gilroy",
                      fontWeight: 500,
                      fontSize: "10px",
                      lineHeight: "11.77px",
                      letterSpacing: "0px",
                      color: "#8D8D8D",
                    }}
                  >
                    By: {toggleState[toggleType]?.updatedBy} on{" "}
                    {toggleState[toggleType]?.updatedAt}
                  </div>
                )}
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
}

export default StepperTabs;
