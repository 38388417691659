import React, {
    // Component
} from 'react';
// import {Link} from 'react-router-dom';
import FilterHeaderComponent from '../../../../../components/FilterHeaderComponent/FilterHeaderComponent';

function ContractsTableHeader(props) {
    return (
        <thead className="team-head contract-table">
            {/* admin-contract-head */}
        <tr>
            <th>
                <input type="checkbox"
                    //    value = {props.item._id}
                       style={{marginLeft:'5px', paddingRight:'5px'}}
                    //    checked={props.selected_contract_ids.some(i => i === props.item._id)}
                    //    onChange={props.onChangeContractCheckbox.bind(this)} 
                />
            </th>
            <FilterHeaderComponent
                isSortAllowed={true}
                sorting_order={'desc'}
                isFilterAllowed={true}
                headerText={'Contract ID'}
                labelText={'Search Contract ID'}
                filter_type={'search'}
                handleSearchFilter = {props.handleSearchFilter}
                keyName = {props.searchObjContractID.keyName}
                value = {props.searchObjContractID.value}
            />
            <FilterHeaderComponent
                isSortAllowed={true}
                sorting_order={'desc'}
                isFilterAllowed={true}
                headerText={'Company Name'}
                labelText={'Search Company Name'}
                filter_type={'search'}
                handleSearchFilter = {props.handleSearchFilter}
                keyName = {props.searchObjCompanyName.keyName}
                value = {props.searchObjCompanyName.value}
             />
             <FilterHeaderComponent
                isSortAllowed={true}
                sorting_order={'desc'}
                isFilterAllowed={true}
                headerText={'Customer'}
                labelText={'Search Customer'}
                filter_type={'search'}
                handleSearchFilter = {props.handleSearchFilter}
                keyName = {props.searchObjCustomer.keyName}
                value = {props.searchObjCustomer.value}
             />
           
             <FilterHeaderComponent
                isFilterAllowed={true}
                headerText={'Contract Start'}
                labelText={'Filter Contract Start'}
                filter_type={'datepicker'}
                isSortAllowed={true}
                sorting_order={'desc'}
                handledatePicker = {props.handledatePicker}
                date = {props.dateObjContractStart.date}
                keyName = {props.dateObjContractStart.keyName}
            />
             <FilterHeaderComponent
                isFilterAllowed={true}
                headerText={'Contract End'}
                labelText={'Contract End'}
                filter_type={'datepicker'}
                isSortAllowed={true}
                sorting_order={'desc'}
                handledatePicker = {props.handledatePicker}
                date = {props.dateObjContractEnd.date}
                keyName = {props.dateObjContractEnd.keyName}
            />
            <FilterHeaderComponent
                isFilterAllowed={true}
                headerText={'Term'}
                labelText={'Filter Term'}
                filter_type={'range_search'}
                handleSliderFilter={props.handleSliderFilter}
                minVal = {props.sliderObjTerm.minVal}
                maxVal = {props.sliderObjTerm.maxVal}
                currentMin = {props.sliderObjTerm.currentMin}
                currentMax = {props.sliderObjTerm.currentMax}
                minValueBetween ={props.sliderObjTerm.minValueBetween}
                keyName = {props.sliderObjTerm.keyName}
            />
            <th>Delay</th>
             <FilterHeaderComponent
                isFilterAllowed={true}
                headerText={'Payments'}
                labelText={'Filter Payments'}
                filter_type={'range_search'}
                handleSliderFilter={props.handleSliderFilter}
                minVal = {props.sliderObjPayments.minVal}
                maxVal = {props.sliderObjPayments.maxVal}
                currentMin = {props.sliderObjPayments.currentMin}
                currentMax = {props.sliderObjPayments.currentMax}
                minValueBetween ={props.sliderObjPayments.minValueBetween}
                keyName = {props.sliderObjPayments.keyName}
            />
           <FilterHeaderComponent
                isSortAllowed={true}
                sorting_order={'desc'}
                isFilterAllowed={true}
                headerText={'Requested By'}
                labelText={'Filter Requested By'}
                filter_type={'search'}
                handleSearchFilter = {props.handleSearchFilter}
                keyName = {props.searchObjRequesteBy.keyName}
                value = {props.searchObjRequesteBy.value}
            />
           <FilterHeaderComponent
                isFilterAllowed={true}
                headerText={'Date requested'}
                labelText={'Filter Date requested'}
                filter_type={'datepicker'}
                isSortAllowed={true}
                sorting_order={'desc'}
                handledatePicker = {props.handledatePicker}
                date = {props.dateObjDateRequested.date}
                keyName = {props.dateObjDateRequested.keyName}
            />

            <FilterHeaderComponent
                isSortAllowed={true}
                sorting_order={'asc'}
                isFilterAllowed={true}
                headerText={'Status'}
                labelText={'Filter Status'}
                filter_type={'selector'}
                inputSelectorHandler = {props.inputSelectorHandler}
                elementsArray = {props.selectorObjStatus.elementsArray}
                keyName = {props.selectorObjStatus.keyName}
           />
           <FilterHeaderComponent
                isSortAllowed={true}
                sorting_order={'desc'}
                isFilterAllowed={true}
                headerText={'Admin User'}
                labelText={'Filter Admin User'}
                filter_type={'search'}
                handleSearchFilter = {props.handleSearchFilter}
                keyName = {props.searchObjAdminUser.keyName}
                value = {props.searchObjAdminUser.value}
            />
             <FilterHeaderComponent
                isFilterAllowed={true}
                headerText={'Last Updated'}
                labelText={'Filter Last Updated'}
                filter_type={'datepicker'}
                isSortAllowed={true}
                sorting_order={'desc'}
                handledatePicker = {props.handledatePicker}
                date = {props.dateObjLastUpdated.date}
                keyName = {props.dateObjLastUpdated.keyName}
            />
            <th className='text-truncate' style={{ maxWidth: "60px"}}>
                <span
                    data-bs-toggle='tooltip'
                    data-bs-html='true'
                    title='Source (CSV, Zoho)'>
                        Source (CSV, Zoho)
                </span>
            </th>
        </tr>
        </thead>
    )
}

export default ContractsTableHeader;
