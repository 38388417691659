import React from 'react';
import StatusBar from './components/StatusBar';
import UnderwritingTool from './Data/UnderwritingTool';
import Metrics from './Metrics/Metrics';
import DataVaultOverview from './Overview/DataVaultOverview/DataVaultOverview';

export default function DataVault({
    investeeOrganization,
    investeeOnboardedId,
    isModeratingDocumentation,
    setIsModeratingDocumentation,
    documentationData,
    fetchDocumentationData,
    monthlyData,
    fetchMonthlyData,
    contractsCount,
    integrationsObj,
    monthlyViewData,
    downloadIntegrationFiles
}) {
    const tabs = ["Overview", "Data", "Metrics"];
    const statusBar = {
        1: "Overview",
        2: "Data",
        3: "Metrics",
    }

    const [currentTab, setCurrentTab] = React.useState(statusBar[1])

    return (
        <div className="container-fluid pt-4 main-onboarding">
            <StatusBar tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab} />
            {currentTab === statusBar[1] &&
                <DataVaultOverview
                    investeeOrganization={investeeOrganization}
                    investeeOnboardedId={investeeOnboardedId}
                    isModeratingDocumentation={isModeratingDocumentation}
                    setIsModeratingDocumentation={setIsModeratingDocumentation}
                    documentationData={documentationData}
                    fetchDocumentationData={fetchDocumentationData}
                    monthlyData={monthlyData}
                    fetchMonthlyData={fetchMonthlyData}
                    contractsCount={contractsCount}
                    integrationsObj={integrationsObj}
                    monthlyViewData={monthlyViewData}
                    downloadIntegrationFiles={downloadIntegrationFiles}
                />
            }
            {currentTab === statusBar[2] && (
                <UnderwritingTool investeeOrganization={investeeOrganization} />
            )}
            {currentTab === statusBar[3] && (
                <Metrics investeeOrganization={investeeOrganization} />
            )}
        </div>
    )
}
