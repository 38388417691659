import React from 'react'
import RecurTable from '../../../../../../../../../../components/RecurTable/RecurTable';
import { ComplianceTableRow } from './components/ComplianceTableRow';
import styles from './ComplianceTable.module.scss'
const {tableData} = styles;
const ComplianceTable = ({complianceData,setComplianceData,setRcm,setOverall,setSpoc}) => {
    const getHeaders = () => [
        {
          jsx: <span>Deal Name</span>,
        },
        {
          jsx: <span>Start Date</span>,
        },
        {
          jsx: <span>CP/CS</span>,
        },
        {
          jsx: <span>Category</span>,
        },
        {
          jsx: <span>Details</span>,
        },
        {
          jsx: <span>Status</span>,
        },
        {
          jsx: <span>Last Updated</span>,
        },
        {
          jsx: <span>Comments</span>,
        },
      ];

      const renderRows = () => {
        return (
          <tbody>
          {complianceData?.map((value, index) => (
              <ComplianceTableRow
                key={value?._id}
                value={value}
                index={index}
                setComplianceData={setComplianceData}
                setRcm={setRcm}
                setOverall={setOverall}
                setSpoc={setSpoc}
              />
          ))}
          </tbody>
          )
      }
  return (
    <div>
        <RecurTable headers={getHeaders()} renderRows={renderRows()}/>
    </div>
  )
}

export default ComplianceTable