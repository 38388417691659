import React from 'react';
import { useSelector } from 'react-redux';
import styled from "styled-components";
import { priceFormat } from '../../../../../../../../../../../../util/utility';
import { getInvesteesOrganization } from '../../../../../../../../../../AdminReducers';

const SmallSection = styled.span`
    border: 0.5px solid #E5E5E5;
    border-radius: 3px;
    background-color: #F9F9F9;
    color: #000000;
    font-size: 8px;
    font-weight: 300;
    padding: 5px 16px;
`;

export default function TradeTerms() {
    const investeeOrg = useSelector(state => getInvesteesOrganization(state));
    const {
        trading_limit,
        irr,
        max_tenure_price,
        custom_tenure_limit_min,
        custom_tenure_limit_max,
        fees,
        recur_fees_waived
    } = investeeOrg;

    return (
        <div className='bgclr2 br10 p-4 mr-4 mt-4 dflex-ja'>
            <div className='fs12 fw500 w-25'>Trade Terms:</div>
            <div className='w-75'>
                <div className='bgclr9 p-2 br4 dflex-ac mb-3'>
                    <div className='w-50 fs10 fw300'>Trading Limit (INR lacs)</div>
                    <div className='color6 fs12 fw500'>{priceFormat(trading_limit * 1_00000)}</div>
                </div>
                <div className='bgclr9 p-2 br4 dflex-ac mb-3'>
                    <div className='w-50 fs10 fw300'>XIRR (Price max tenure)</div>
                    <div className='color6 fs12 fw500'>
                        <span>{irr}%</span>
                        <SmallSection className='ml-2'><i>{max_tenure_price && (max_tenure_price * 100).toFixed(2)}%</i></SmallSection>
                    </div>
                </div>
                <div className='bgclr9 p-2 br4 dflex-ac mb-3'>
                    <div className='w-50 fs10 fw300'>Tenure (months)</div>
                    <div className='color6 fs12 fw500'>{custom_tenure_limit_min} - {custom_tenure_limit_max}</div>
                </div>
                <div className='bgclr9 p-2 br4 dflex-ac'>
                    <div className='w-50 fs10 fw300'>Fee (without GST)</div>
                    <div className='color6 fs12 fw500'>
                        <span>{fees}%</span>
                        {recur_fees_waived && <span className='Waived-bg ml-2'>Waived</span>}
                    </div>
                </div>
            </div>
        </div>
    );
}
