import React from "react";

function CompletedOrderRepaymensListHeader(props) {
  return (
    <thead>
      <tr>
        <th>Investor Name</th>
        <th>Company Name</th>
        <th>Due Date</th>
        <th>Collection Amount</th>
        <th>Collection Date</th>
        <th>DPD's</th>
      </tr>
    </thead>
  );
}

export default CompletedOrderRepaymensListHeader;