import './RequestType.scss'

interface RequestTypeProps {
    requestType: string;
    showChangeText: boolean;
    isInvestor?: boolean;
}

const RequestType = (props: RequestTypeProps) => {
    return (
        <>
            <div className={'Container'}>
                <div className={'RequestChangeContainer'}>
                    Assistance required for
                </div>
                <div
                    style={props.isInvestor ? { background: '#F8F2FF', color: '#6021B3' } : {}}
                    className={'RequestTypeBanner'}
                >
                    {props.requestType}
                </div>
            </div>
        </>
    );
};
export default RequestType;
