import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { downloadFileUsingFetch, formatMonthName, getPrevious12Months } from '../../../../../../../../../../../util/utility';
import nextArrow from '../../../../../../../../../../../assets/next-arrow.svg';
import yellowDot from '../../../../../../../../../../../assets/yellow-dot.svg';
import notAvailable from '../../../../../../../../../../../assets/not-available.svg';
import greenTick from '../../../../../../../../../../../assets/green-tick.svg';
import zoho from '../../../../../../../../../../../assets/zoho-integrated.svg';
import tally from '../../../../../../../../../../../assets/tally-integrated.svg';
import styles from './MonthlyIntegrationTable.module.scss';
import { useSelector } from 'react-redux';
import { getInvesteesOrganization } from '../../../../../../../../../AdminReducers';
import { MONTHLY_DATA_DOWNLOAD_TYPES, MONTHLY_VIEW_DATA_TYPES } from '../../../../../../../CompaniesViewPage';

const integrationLogos = {
    zoho: zoho,
    tally: tally,
};

function MonthlyIntegrationTable(props) {

    const {
        monthlyViewData,
        integrationsObj,
        downloadIntegrationFiles
    } = props;

    const months = getPrevious12Months();
    const integrations = ['zoho', 'tally'];
    const investeeOrganizationId = useSelector(getInvesteesOrganization)?._id;
    const organizationName = useSelector(getInvesteesOrganization)?.name;
    // DROPDOWN STATES
    const [showMIS, setShowMIS] = useState(false);
    const [showInvoicing, setShowInvoicing] = useState(false);
    const [showBankAccounts, setShowBankAccounts] = useState(false);
    const [isDownloadAllDocs, setIsDownloadAllDocs] = useState(false);
    const [integrationData, setIntegrationData] = useState({
        integration_mis: null,
        integration_invoicing: null,
        integration_bank: null
    })
    const {
        availableBalanceSheetMonthlyDates,
        availableBankTrxMonthlyDates,
        availableCashflowMonthlyDates,
        availableInvoiceGstMonthlyDates,
        availableInvoiceMonthlyDates,
        availablePnlMonthlyDates,
        bankStatementDates,
        availableGSTMonthlyDates
    } = monthlyViewData;

    useEffect(() => {
        let misIntegration, invoicingIntegration, bankIntegration;
        const { invoicingIntegrations, misIntegrations, bankStatementsIntegrations } = integrationsObj;
        integrations.forEach((type) => {
            if (misIntegrations && misIntegrations[type] && !misIntegration)
                misIntegration = type;
            if (invoicingIntegrations && invoicingIntegrations[type] && !invoicingIntegration)
                invoicingIntegration = type;
            if (bankStatementsIntegrations && bankStatementsIntegrations[type] && !bankIntegration)
                bankIntegration = type;
        });

        setIntegrationData({
            integration_mis: misIntegration,
            integration_invoicing: invoicingIntegration,
            integration_bank: bankIntegration
        })
        //eslint-disable-next-line
    }, [integrationsObj])

    const getStatusIcon = (status) => {
        switch (status) {
            case MONTHLY_VIEW_DATA_TYPES.INGESTED: return greenTick;
            case MONTHLY_VIEW_DATA_TYPES.UPLOADED: return yellowDot;
            case MONTHLY_VIEW_DATA_TYPES.NOT_UPLOADED:
            default: return notAvailable;
        }
    }

    const getCumulativeMisStatusIcon = (month) => {
        if (availablePnlMonthlyDates?.[month] === availableBalanceSheetMonthlyDates?.[month] && availableBalanceSheetMonthlyDates?.[month] === availableCashflowMonthlyDates?.[month])
            return getStatusIcon(availablePnlMonthlyDates?.[month]) //if all statuses are same
        else {
            if ([availablePnlMonthlyDates?.[month], availableBalanceSheetMonthlyDates?.[month], availableCashflowMonthlyDates?.[month]].includes(MONTHLY_VIEW_DATA_TYPES.UPLOADED))
                return getStatusIcon(MONTHLY_VIEW_DATA_TYPES.UPLOADED);
            if ([availablePnlMonthlyDates?.[month], availableBalanceSheetMonthlyDates?.[month], availableCashflowMonthlyDates?.[month]].includes(MONTHLY_VIEW_DATA_TYPES.NOT_UPLOADED))
                return getStatusIcon(MONTHLY_VIEW_DATA_TYPES.NOT_UPLOADED);
        }
    }

    const getCumulativeInvoicingStatusIcon = (month) => {
        if (availableInvoiceMonthlyDates?.[month] === availableGSTMonthlyDates?.[month])
            return getStatusIcon(availableInvoiceMonthlyDates?.[month]) //if all statuses are same
        else {
            if ([availableInvoiceMonthlyDates?.[month], availableGSTMonthlyDates?.[month]].includes(MONTHLY_VIEW_DATA_TYPES.UPLOADED))
                return getStatusIcon(MONTHLY_VIEW_DATA_TYPES.UPLOADED);
            // if ([availableInvoiceMonthlyDates?.[month], availableGSTMonthlyDates?.[month]].includes(MONTHLY_VIEW_DATA_TYPES.NOT_UPLOADED))
                return getStatusIcon(MONTHLY_VIEW_DATA_TYPES.NOT_UPLOADED);
        }
    }
    const downloadFile = (month) => {
        const path = month ? `?path=${month.split("-").reverse().join("-")}` : '';
        downloadFileUsingFetch(
            `admin/zip-stream-investee-docs/${investeeOrganizationId}${path}`,
            "get",
            {
                responseType: "blob",
            },
            `${path}_${investeeOrganizationId}.zip`,
        );
    };

    const downloadAllFile = () => {
        setIsDownloadAllDocs(true);
        downloadFileUsingFetch(
            `doc/downloadAllDoc`,
            "post",
            {
                responseType: "blob",
            },
            `${organizationName}_${investeeOrganizationId}.zip`,
            () => {setIsDownloadAllDocs(false)},
            null,{"investeeOrganizationId": investeeOrganizationId}, true
        );
    };

    return (
        <div className={styles.DataIntegration}>
            <div className={styles.Title}>
                <div>
                    Monthly View
                    <img src="/assets/download-dv.svg" className={styles.Icon} alt="download" onClick={() => downloadFile()} />
                </div>
                
                <button
                    className={styles.DownloadAllButton}
                    onClick={() => downloadAllFile()}
                    >
                        <div className={styles.DivClass}>
                            {isDownloadAllDocs ? 
                                <div id="loading-spinner" className={styles.DownloadAllSpinner}>
                                    <div className="spin-icon"></div>
                                </div>
                                :
                                <img src="/assets/download-dv.svg" className={styles.DownloadAllIcon} alt="" />
                            }
                                <span className="">Download folder wise Data</span>
                        </div>
                </button>
            </div>
            <div className={styles.Top}>
                <div className={styles.Titles}>
                    <div>Data</div>
                    <div>Integration</div>
                </div>
                <div className={styles.Months}>
                    {months.map((month, i) => (
                        <div className={styles.Month} key={i}>
                            {formatMonthName(month)}
                            {/* <img src="/assets/download-dv.svg" height="10px" alt="download" onClick={() => downloadFile(month)} /> */}
                        </div>
                    ))}
                </div>
            </div>

            <div className={styles.CollapseHead}>
                <div className={styles.Title} onClick={() => setShowMIS((show) => !show)}>
                    <div>
                        Provisional Fs.
                        <img
                            src={nextArrow}
                            alt="next"
                            height="12px"
                            style={{ transform: showMIS ? 'rotate(90deg)' : '' }}
                        />
                    </div>
                    <div>
                        {integrationLogos[integrationData.integration_mis] ? (
                            <img
                                src={integrationLogos[integrationData.integration_mis]}
                                alt="zoho"
                                width="85px"
                            />
                        ) : null}
                        <img className={styles.DownloadImg} src="/assets/download-dv.svg" height="12px" alt="download" onClick={(e) => downloadIntegrationFiles(e, MONTHLY_DATA_DOWNLOAD_TYPES.MIS)} />
                    </div>

                </div>
                <div className={styles.Data}>
                    {months.map((month, i) => (
                        <div className={styles.DataItem} key={i}>
                            <img src={getCumulativeMisStatusIcon(month)} alt="status" height="13px" />
                        </div>
                    ))}
                </div>
            </div>

            <div className={clsx({ [styles.CollapseBody]: true, [styles.Expand]: showMIS })}>
                <div className={styles.CollapseBodyItem}>
                    <div className={clsx([styles.Title, styles.TitleFirst])}>
                        PNL
                        <div>
                            {integrationLogos[integrationData.integration_mis] ? (
                                <img
                                    src={integrationLogos[integrationData.integration_mis]}
                                    alt="zoho"
                                    width="85px"
                                />
                            ) : null}
                            <img className={styles.DownloadImg} src="/assets/download-dv.svg" height="12px" alt="download" onClick={(e) => downloadIntegrationFiles(e, MONTHLY_DATA_DOWNLOAD_TYPES.MIS)} />
                        </div>
                    </div>

                    <div className={styles.Data}>
                        {months.map((month, i) => (
                            <div className={styles.DataItem} key={i}>
                                <img src={getStatusIcon(availablePnlMonthlyDates?.[month])} alt="status" height="13px" />
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.CollapseBodyItem}>
                    <div className={clsx([styles.Title, styles.TitleMid])}>
                        Cashflow
                        <div>
                            {integrationLogos[integrationData.integration_mis] ? (
                                <img
                                    src={integrationLogos[integrationData.integration_mis]}
                                    alt="zoho"
                                    width="85px"
                                />
                            ) : null}
                            <img className={styles.DownloadImg} src="/assets/download-dv.svg" height="12px" alt="download" onClick={(e) => downloadIntegrationFiles(e, MONTHLY_DATA_DOWNLOAD_TYPES.MIS)} />
                        </div>
                    </div>

                    <div className={styles.Data}>
                        {months.map((month, i) => (
                            <div className={styles.DataItem} key={i}>
                                <img src={getStatusIcon(availableCashflowMonthlyDates?.[month])} alt="status" height="13px" />
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.CollapseBodyItem}>
                    <div className={clsx([styles.Title, styles.TitleLast])}>
                        Balance Sheet
                        <div>
                            {integrationLogos[integrationData.integration_mis] ? (
                                <img
                                    src={integrationLogos[integrationData.integration_mis]}
                                    alt="zoho"
                                    width="85px"
                                />
                            ) : null}
                            <img className={styles.DownloadImg} src="/assets/download-dv.svg" height="12px" alt="download" onClick={(e) => downloadIntegrationFiles(e, MONTHLY_DATA_DOWNLOAD_TYPES.MIS)} />
                        </div>
                    </div>

                    <div className={styles.Data}>
                        {months.map((month, i) => (
                            <div className={styles.DataItem} key={i}>
                                <img src={getStatusIcon(availableBalanceSheetMonthlyDates?.[month])} alt="status" height="13px" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className={styles.CollapseHead}>
                <div className={styles.Title} onClick={() => setShowInvoicing((show) => !show)}>
                    <div>
                        Invoicing
                        <img
                            src={nextArrow}
                            alt="next"
                            height="12px"
                            style={{ transform: showInvoicing ? 'rotate(90deg)' : '' }}
                        />
                    </div>
                    <div>
                        {integrationLogos[integrationData.integration_invoicing] ? (
                            <img
                                src={integrationLogos[integrationData.integration_invoicing]}
                                alt="zoho"
                                width="85px"
                            />
                        ) : null}
                        <img className={styles.DownloadImg} src="/assets/download-dv.svg" height="12px" alt="download" onClick={(e) => downloadIntegrationFiles(e, MONTHLY_DATA_DOWNLOAD_TYPES.INVOICING)} />
                    </div>
                </div>
                <div className={styles.Data}>
                    {months.map((month, i) => (
                        <div className={styles.DataItem} key={i}>
                            <img src={getCumulativeInvoicingStatusIcon(month)} alt="status" height="13px" />
                        </div>
                    ))}
                </div>
            </div>

            <div className={clsx({ [styles.CollapseBody]: true, [styles.Expand]: showInvoicing })}>
                <div className={styles.CollapseBodyItem}>
                    <div className={clsx([styles.Title, styles.TitleFirst])}>
                        Invoicing
                        <div>
                            {integrationLogos[integrationData.integration_invoicing] ? (
                                <img
                                    src={integrationLogos[integrationData.integration_invoicing]}
                                    alt="zoho"
                                    width="85px"
                                />
                            ) : null}
                            <img className={styles.DownloadImg} src="/assets/download-dv.svg" height="12px" alt="download" onClick={(e) => downloadIntegrationFiles(e, MONTHLY_DATA_DOWNLOAD_TYPES.INVOICING)} />
                        </div>
                    </div>

                    <div className={styles.Data}>
                        {months.map((month, i) => (
                            <div className={styles.DataItem} key={i}>
                                <img src={getStatusIcon(availableInvoiceMonthlyDates?.[month])} alt="status" height="13px" />
                            </div>
                        ))}
                    </div>
                </div>

                {/* <div className={styles.CollapseBodyItem}>
                    <div className={clsx([styles.Title, styles.TitleLast])}>
                        GST
                        <div>
                            {integrationLogos[integrationData.integration_invoicing] ? (
                                <img
                                    src={integrationLogos[integrationData.integration_invoicing]}
                                    alt="zoho"
                                    width="85px"
                                />
                            ) : null}
                            <img className={styles.DownloadImg} src="/assets/download-dv.svg" height="12px" alt="download" onClick={(e) => downloadIntegrationFiles(e, MONTHLY_DATA_DOWNLOAD_TYPES.GST_INVOICING)} />
                        </div>
                    </div>

                    <div className={styles.Data}>
                        {months.map((month, i) => (
                            <div className={styles.DataItem} key={i}>
                                <img src={getStatusIcon(availableInvoiceGstMonthlyDates?.[month])} alt="status" height="13px" />
                            </div>
                        ))}
                    </div>
                </div> */}

                {Object.keys(availableInvoiceGstMonthlyDates).map((gst, i) => (
                    <div className={styles.CollapseBodyItem} key={`${gst}-${i}`}>
                        <div
                            className={clsx([
                                styles.Title,
                                {
                                    [styles.TitleMid]: true,
                                    [styles.TitleLast]:
                                        Object.keys(availableInvoiceGstMonthlyDates)?.length - 1 ===
                                        i,
                                },
                            ])}
                        >
                            {gst}
                        </div>

                        <div className={styles.Data}>
                            {months.map((month, i) => (
                                <div className={styles.DataItem} key={i}>
                                    {/* {availableInvoiceGstMonthlyDates?.[gst]?.[month] ===
                                    MONTHLY_VIEW_DATA_TYPES.INGESTED ? (
                                        <img src={available_slim} alt="green" height="15px" />
                                    ) : ( */}
                                        <img
                                            src={getStatusIcon(availableInvoiceGstMonthlyDates?.[gst]?.[month])}
                                            alt="green"
                                            height="13px"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                // setModalStep(
                                                //     DATA_UPLOAD_STEPS.GST_INVOICING_STEPS.GST_DATA,
                                                // );
                                                // showUploadModal && showUploadModal();
                                            }}
                                        />
                                    {/* )} */}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            <div className={styles.CollapseHead}>
                <div className={styles.Title} onClick={() => setShowBankAccounts((show) => !show)}>
                    <div>
                        Bank Statement
                        <img
                            src={nextArrow}
                            alt="next"
                            height="12px"
                            style={{ transform: showBankAccounts ? 'rotate(90deg)' : '' }}
                        />
                    </div>
                    <div>
                        <img className={styles.DownloadImg} src="/assets/download-dv.svg" height="12px" alt="download" onClick={(e) => downloadIntegrationFiles(e, MONTHLY_DATA_DOWNLOAD_TYPES.BANK)} />
                    </div>
                </div>
                <div className={styles.Data}>
                    {months.map((month, i) => (
                        <div className={styles.DataItem} key={i}>
                            <img src={getStatusIcon(bankStatementDates?.[month])} alt="status" height="13px" />
                        </div>
                    ))}
                </div>
            </div>

            <div
                className={clsx({ [styles.CollapseBody]: true, [styles.Expand]: showBankAccounts })}
            >
                {availableBankTrxMonthlyDates && Object.keys(availableBankTrxMonthlyDates)?.length
                    ? Object.keys(availableBankTrxMonthlyDates)?.map((account, i) => (
                        <div className={styles.CollapseBodyItem} key={i}>
                            <div
                                className={clsx({
                                    [styles.Title]: true,
                                    [styles.TitleFirst]: i === 0,
                                    [styles.TitleMid]:
                                        i > 0 &&
                                        i < Object.keys(availableBankTrxMonthlyDates)?.length - 1,
                                    [styles.TitleLast]:
                                        i ===
                                        Object.keys(availableBankTrxMonthlyDates)?.length - 1,
                                })}
                            >
                                {account}
                            </div>

                            <div className={styles.Data}>
                                {months.map((month, i) => (
                                    <div className={styles.DataItem} key={i}>
                                        <img src={getStatusIcon(availableBankTrxMonthlyDates?.[account]?.[month])} alt="status" height="13px" />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                    : null}
            </div>
        </div>
    );
}

export default MonthlyIntegrationTable;
