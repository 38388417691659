import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from '../components/SetupFrequencyModal.module.scss';
import '../../../../../../../../../../../css/Modal.scss';
import { PG_CONFIG_TYPE } from '../../../../../../../../../../../constants/enums';
import callApi from '../../../../../../../../../../../util/apiCaller';
import { monthDaysArray, showNotification } from '../../../../../../../../../../../util/utility';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';

export default function SetupFrequencyModal({pgId, handleShow, show, handleClose, type, updateStateBasedOnValueChange, fixedAmountDaily, handleChange, setupFreqClick, endOfMonth, disabledState}) {
  const monthDays = monthDaysArray(endOfMonth);
  const { SetupFrequency, SetupFrequencyModalBody, Heading, SetupFrequencyModalFooter } = styles;
  const PG_CONFIGS = [PG_CONFIG_TYPE.FIXED_AMOUNT_DAILY];
  return (
    <div>
      <span onClick={handleShow} className={SetupFrequency}>
        <img src="/assets/setup-frequency.svg" alt="" />
      </span>

      <Modal show={show} onHide={handleClose} className="SetupFrequencyModalWidth">
        <Modal.Header className="border-0">
          <div className={Heading}>Setup Frequency for collection</div>
          <button type="button" class="close">
            <img
              src="../../../../../assets/modal-cross.svg"
              alt=""
              onClick={handleClose}
            />
          </button>
        </Modal.Header>
        <Modal.Body className={SetupFrequencyModalBody}>
          <select
                disabled={disabledState}
                required
                value={type}
                onChange={(e) => {
                  updateStateBasedOnValueChange('type', e.target.value);
              }}
            >
                <option value="" disabled selected>Select Configuration</option>
                {PG_CONFIGS.map((item, index) => (
                    <option key={`pg-config-${index}`} value={item}>
                        {item}
                    </option>
                ))}
          </select>
          <div className='row'>
            <div className="col-md-6">
              <select
                  disabled={disabledState}
                  required
                  value={fixedAmountDaily?.startDay}
                  onChange={(e) => {
                    handleChange('startDay', Number(e.target.value), type);
                }}
              >
                  <option value="" disabled selected>Select Start Date of the month</option>
                  {monthDays?.map((item, index) => (
                    <option key={`pg-config-${index}`} value={Number(item)}>
                        {item}
                    </option>
                ))}
              </select>
            </div>
            <div className="col-md-6">
              <select
                    disabled={disabledState}
                    required
                    value={fixedAmountDaily?.endDay}
                    onChange={(e) => {
                      handleChange('endDay', Number(e.target.value), type);
                  }}
                >
                  <option value="" disabled selected>Select End Date of the month</option>
                  {monthDays?.map((item, index) => (
                    <option key={`pg-config-${index}`} value={Number(item)}>
                        {item}
                    </option>
                ))}
              </select>
            </div>
          </div>
          <input disabled={disabledState} type='number' value={fixedAmountDaily?.collectionAmount} placeholder='Enter Collection Amount (in Rs)' required
            onChange={(e)=> {
              const collectionAmount = e.target.value ? Number(e.target.value) : null;
              handleChange('collectionAmount', collectionAmount, type);
            }}
          />
        </Modal.Body>
        <Modal.Footer className={SetupFrequencyModalFooter}>
          <button disabled={disabledState} onClick={() => setupFreqClick(pgId, type, fixedAmountDaily)}>Setup Frequency</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
