import {
  ATTEMPT_TO_LOGIN,
  ATTEMPT_TO_LOGOUT,
  FETCH_USER_PROFILE,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  FETCHING_USER_PROFILE,
  FETCHING_ESCROW_ACCOUNT_INFO,
  FETCH_ESCROW_ACCOUNT_INFO,
  SHOW_MENU_ON_RIGHT_CLICK,
  // FETCH_CAPITAL_PARTNER,
  FETCHING_UNDERWRITING_DATA,
  FETCH_UNDERWRITING_DATA,
  FETCH_UNDERWRITING_CLASSIFICATION_DATA,
  FETCHING_UNDERWRITING_CLASSIFICATION_DATA,
  POST_UNDERWRITING_EDITED_DATA,
  POSTING_UNDERWRITING_EDITED_DATA,
  POSTING_UNDERWRITING_CREATED_SUBCLASSIFICATION,
  POST_UNDERWRITING_CREATED_SUBCLASSIFICATION,
  FETCH_AGENTS,
  CHANGE_SCREEN_ON_COMPANIES_OVERVIEW,
  UPDATE_SELECTED_TOT_ID_TO_REDIRECT,
  SET_REDIRECT_FROM_DEALS_PAGE,
  FETCH_USER_ROLES,
  SET_IS_ADD_ON_LIMIT_REQUESTED,
  FETCHING_ARR_DATA,
  FETCH_ARR_DATA
  // FETCH_SALES_PARTNER
} from "./AppActions";

const initialState = {
  isTokenFound: true,
  isVerifyingToken: false,
  isAuthenticated: false,
  isEmailVerified: false,
  isUserApproved: false,
  isLoggingIn: false,
  user: {},
  admin: {},
  isAdmin: false,
  isFetchingEscrowAccountInfo: false,
  escrowAccountInfo: {},
  idOnRightClick: "",
  // salesPartners: [],
  // capitalPartners: [],
  salesPartner: [],
  dataSpecialist: [],
  underwriters: [],
  isFetchingAgentsData: false,
  is_fetching_underwriting_data: false,
  underwriting_data_headers: [],
  underwriting_data_rows: [],
  is_fetching_underwriting_classification_data: false,
  underwriting_classification_data: [],
  isposting_underwriting_edited_data: false,
  newSubClassificationId: 0, // zero id also belongs to unclassified Id of subclassification
  underwriting_row_index_to_update_subclassificationId: null,
  permissions: [],
  viewOnCompaniesOverview: "",
  selectedTotIdToRedirect: "",
  redirectFromDealsPage: {},
  roleObjectMap: {},
  limitAddOnObj: {},
  isFetchingArrData: false,
  arrData: {}
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MENU_ON_RIGHT_CLICK:
      return {
        ...state,
        idOnRightClick: action.idOnRightClick,
      };

    case ATTEMPT_TO_LOGIN:
      return {
        ...state,
        isLoggingIn: action.isLoggingIn,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: action.isLoggingIn,
        isEmailVerified: action.isEmailVerified,
        isAuthenticated: action.isAuthenticated,
        isUserApproved: action.isUserApproved,
        user: action.user,
      };

    case LOGIN_FAILED:
      return {
        ...state,
        isLoggingIn: action.isLoggingIn,
        isAuthenticated: action.isAuthenticated,
      };

    case FETCH_USER_PROFILE:
      return {
        ...state,
        admin: action.admin,
        permissions: action.permissions,
      };

    case FETCHING_USER_PROFILE:
      return {
        ...state,
        is_fetching_user: action.is_fetching_user,
      };

    case ATTEMPT_TO_LOGOUT:
      return {
        ...state,
        isVerifyingToken: action.isVerifyingToken,
        isAuthenticated: action.isAuthenticated,
        user: action.user,
        token: action.token,
      };

    case FETCHING_ESCROW_ACCOUNT_INFO:
      return {
        ...state,
        isFetchingEscrowAccountInfo: action.isFetchingEscrowAccountInfo,
      };

    case FETCH_ESCROW_ACCOUNT_INFO:
      return {
        ...state,
        isFetchingEscrowAccountInfo: action.isFetchingEscrowAccountInfo,
        escrowAccountInfo: action.escrowAccountInfo,
      };

    // case FETCH_SALES_PARTNER:
    //   return {
    //     ...state,
    //     salesPartners: mergeByProperty(
    //       state.salesPartners,
    //       action.salesPartners,
    //       "_id"
    //     ),
    //   };
    // case FETCH_CAPITAL_PARTNER:
    //   return {
    //     ...state,
    //     capitalPartners: mergeByProperty(
    //       state.capitalPartners,
    //       action.capitalPartners,
    //       "_id"
    //     ),
    //   };
    case FETCH_AGENTS:
      return {
        ...state,
        salesPartner: action.salesPartner,
        dataSpecialist: action.dataSpecialist,
        underwriters: action.underwriters,
        isFetchingAgentsData: action.isFetchingAgentsData,
      };

    case FETCHING_UNDERWRITING_DATA:
      return {
        ...state,
        is_fetching_underwriting_data: action.is_fetching_underwriting_data,
        ...(action.underwriting_data_headers && {
          underwriting_data_headers: action.underwriting_data_headers,
        }),
        ...(action.underwriting_data_rows && {
          underwriting_data_rows: action.underwriting_data_rows,
        }),
      };

    case FETCH_UNDERWRITING_DATA:
      return {
        ...state,
        is_fetching_underwriting_data: action.is_fetching_underwriting_data,
        ...(action.reset_offset ? {
          underwriting_data_headers: action.underwriting_data_headers,
          underwriting_data_rows: action.underwriting_data_rows,
        } : {
          underwriting_data_rows: [...state.underwriting_data_rows, ...action.underwriting_data_rows],
        }),
        lastDate: action.lastDate,
        currentBalance: action.currentBalance,
        currentBalanceStr: action.currentBalanceStr,
        odLimitDetails: action.odLimitDetails,
        fdBalance: action.fdBalance,
        numberOdAccounts: action.numberOdAccounts,
        odLimit: action.odLimit,
        accounts: action.accounts,
        contractThreshold: action?.contractThreshold,
        contractSynced: action?.contractSynced,
        contractSyncDate: action.contractSyncDate,
        mutualFundAmount: action.mutualFundAmount
      };
    case FETCHING_UNDERWRITING_CLASSIFICATION_DATA:
      return {
        ...state,
        is_fetching_underwriting_classification_data:
          action.is_fetching_underwriting_classification_data,
      };
    case FETCH_UNDERWRITING_CLASSIFICATION_DATA:
      return {
        ...state,
        is_fetching_underwriting_classification_data:
          action.is_fetching_underwriting_classification_data,
        underwriting_classification_data:
          action.underwriting_classification_data,
        underwriting_row_index_to_update_subclassificationId:
          action.underwriting_row_index_to_update_subclassificationId,
      };
    case POSTING_UNDERWRITING_EDITED_DATA:
      return {
        ...state,
        isposting_underwriting_edited_data:
          action.isposting_underwriting_edited_data,
      };
    case POST_UNDERWRITING_EDITED_DATA:
      return {
        ...state,
        isposting_underwriting_edited_data:
          action.isposting_underwriting_edited_data,
      };
    case POSTING_UNDERWRITING_CREATED_SUBCLASSIFICATION:
      return {
        ...state,
        isposting_underwriting_created_subclassification:
          action.isposting_underwriting_created_subclassification,
        newSubClassificationId: action.newSubClassificationId,
        has_posted_underwriting_created_subclassification:
          action.has_posted_underwriting_created_subclassification,
      };
    case POST_UNDERWRITING_CREATED_SUBCLASSIFICATION:
      return {
        ...state,
        isposting_underwriting_created_subclassification:
          action.isposting_underwriting_created_subclassification,
      };

    case FETCHING_ARR_DATA:
      return {
        ...state,
        isFetchingArrData: action.isFetchingArrData
      };

    case FETCH_ARR_DATA:
      return {
        ...state,
        arrData: action.arrData
      };

    case CHANGE_SCREEN_ON_COMPANIES_OVERVIEW:
      return {
        ...state,
        viewOnCompaniesOverview: action.viewOnCompaniesOverview,
      };
    case UPDATE_SELECTED_TOT_ID_TO_REDIRECT:
      return {
        ...state,
        selectedTotIdToRedirect: action.selectedTotIdToRedirect,
      };
    case SET_REDIRECT_FROM_DEALS_PAGE:
      return {
        ...state,
        redirectFromDealsPage: action.redirectFromDealsPage
      }
    case FETCH_USER_ROLES:
      return {
        ...state,
        roleObjectMap: action.roleObjectMap
      }
    case SET_IS_ADD_ON_LIMIT_REQUESTED:
      return {
        ...state,
        limitAddOnObj: action.limitAddOnObj
      }
    default:
      return state;
  }
};

export default appReducer;

export const getUserProfileFetchingState = (state) =>
  state.app.is_fetching_user;
export const getAuthenticatedState = (state) => state.app.isAuthenticated;
export const getApprovedState = (state) => state.app.isUserApproved;
export const getEmailVerifiedState = (state) => state.app.isEmailVerified;
export const getLoggingState = (state) => state.app.isLoggingIn;
export const getUserDetails = (state) => state.app.user;
export const getIfUserIsAdmin = (state) => state.app.isAdmin;
export const getUserId = (state) => (state.app.user ? state.app.user._id : '');
export const getUserName = (state) =>
  state.app.user && state.app.user.contact_name
    ? state.app.user.contact_name
    : "";
export const getEmail = (state) => state.app.user.contact_email;
export const getEscrowAccountInfo = (state) => state.app.escrowAccountInfo;
export const getisFetchingEscroeAccountInfo = (state) =>
  state.app.isFetchingEscrowAccountInfo;
export const getIdOnRightClick = (state) => state.app.idOnRightClick;
// export const getSalesPartners = state => state.app.salesPartners;
// export const getCapitalPartners = state => state.app.capitalPartners;
export const getSalesPartner = (state) => state.app.salesPartner;
export const getDataSpecialist = (state) => state.app.dataSpecialist;
export const getUnderwriters = (state) => state.app.underwriters;
export const getUnderwritingDataHeaders = (state) =>
  state.app.underwriting_data_headers;
export const getUnderwritingDataRows = (state) =>
  state.app.underwriting_data_rows;

export const getUnderwritingLastDate = (state) => state.app.lastDate;
export const getUnderwritingCurrBalance = (state) => state.app.currentBalance;
export const getUnderwritingCurrBalanceStr = (state) => state.app.currentBalanceStr;
export const getOdLimitDetails = (state) => state.app.odLimitDetails;
export const getFdBalance = (state) => state.app.fdBalance;
export const getMutualFundAmount = (state) => state.app.mutualFundAmount;

export const getUnderwritingODAccounts = (state) => state.app.numberOdAccounts;
export const getUnderwritingODLimits = (state) => state.app.odLimit;
export const getUnderwritingODLimitDetails = (state) => state.app.odLimitDetails;
export const getUnderwritingAccNo = (state) => state.app.accounts;

export const getUnderwritingClassificationData = (state) =>
  state.app.underwriting_classification_data;
export const getIsFetchingUnderwritingData = (state) =>
  state.app.is_fetching_underwriting_data;
export const getIsFetchingUnderwritingClassificationData = (state) =>
  state.app.is_fetching_underwriting_classification_data;
export const getIsPostingUnderwritingEditedData = (state) =>
  state.app.isposting_underwriting_edited_data;
export const getNewSubclassificationId = (state) => {
  return state.app.newSubClassificationId;
};
export const getViewOnCompaniesOverview = (state) =>
  state.app.viewOnCompaniesOverview;
export const getSelectedTotIdToRedirect = (state) =>
  state.app.selectedTotIdToRedirect;

export const getRedirectFromDealsPage = (state) =>
  state.app.redirectFromDealsPage;

export const getContractThreshold = (state) =>
  state.app.contractThreshold;

export const getContractsSynced = (state) =>
  state.app?.contractSynced

export const getLastSyncedDate = (state) =>
  state.app?.contractSyncDate


export const getUnderwritingIndexToUpdateSubclassificationData = (state) =>
  state.app.underwriting_row_index_to_update_subclassificationId;

// RBAC
export const getUserPermissionsFromRoles = (state) => state.app.permissions;
export const getCheckIfUserHasRelevantPermissions = (
  state,
  shouldHavePermissions
) => {
  if (shouldHavePermissions && shouldHavePermissions.length > 0) {
    let userPermissions = getUserPermissionsFromRoles(state);
    if (userPermissions) {
      let leftPermissions = shouldHavePermissions.filter(
        (item) => userPermissions.indexOf(item) !== -1
      );
      return leftPermissions && leftPermissions.length > 0;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const getRoleObjectMap = (state) => {
  return state.app.roleObjectMap;
}

export const getIsAddOnLimitedRequested = (state) => {
  return state.app.limitAddOnObj;
}

export const getIfFetchingArrData = state => state.app.isFetchingArrData;
export const getArrData = state => state.app.arrData;