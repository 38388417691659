import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import FileReader from '../../../../../../../../../components/FileReader/FileReader';
import { DOC_SERVICE_RESPONSE, FLOW_ID, GENERIC_ERROR } from '../../../../../../../../../constants/enums';
import '../../../../../../../../../css/Modal.scss';
import callApi from '../../../../../../../../../util/apiCaller';
import { showNotification } from '../../../../../../../../../util/utility';
import { getUserName } from '../../../../../../../../App/AppReducer';
import { getInvesteesOrganization } from '../../../../../../../AdminReducers';
import { NEED_UPDATE, RULES, RULE_STATUS } from '../consts/consts';
import DocExpiryDate from './DocExpiryDate/DocExpiryDate';
import UploadDoc from './UploadDoc';

export default function DocumentVaultModal({
    showModal,
    toggleModal,
    docData,
    docTypeName,
    isSharedWithInvestor,
    moderatorName,
    section,
    toggleSharedWithInvestor,
    onUploadSuccess,
    onModerateSuccess,
    onDeleteSuccess,
    descriptionProp,
    setShowModal
}) {
    const [properties, setProperties] = useState();
    const [note, setNote] = useState("");
    const [expiryDateChecked, setExpiryDateChecked] = useState(false);
    const [expiryDate, setExpiryDate] = useState('');
    const [areChangesDone, setAreChangesDone] = useState(false);
    const [isUploadedNew, setIsUploadedNew] = useState(false);
    const [docDetails, setDocDetails] = useState();
    const [doProductSign,setDoProductSign] = useState(true);
    const [description,setDescription] = useState("");
    const userName = useSelector(getUserName);
    const investeeOrgData = useSelector(getInvesteesOrganization);
    const isCompanyActive = investeeOrgData?.status === "Active";




    useEffect(() => {
        if (docData) updateAllStates();
    }, [docData]);

    const getFileType = (mimeType) => {
        if (mimeType === 'application/.pdf') return 'application/pdf';
        if (mimeType === 'application/text') return 'text/plain';
        return mimeType;
    };

    const fetchDoc = async(docId) => {
        if (!docId) return;
        const res = await callApi(`file/downloadFilePresigned?fileId=${docId}`, "post", {}, {}, false, false, false, false, false, true);
        if (res?.responseData?.responseCode === DOC_SERVICE_RESPONSE.SUCCESS) {
            setDocDetails({
                uri: res?.signedUrl,
                fileType: getFileType(res?.mimeType)
            });
        }
        else {
            const message = res.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification("Error", message);
        }
    }

    const updateAllStates = () => {
        setProperties(docData?.rules ?? []);
        setNote(docData?.comment ?? "");
        if (docData?.docId) fetchDoc(docData?.docId);
        // For Expiry Date
        if (docData?.expiryDate) {
            setExpiryDateChecked(true);
            setExpiryDate(docData?.expiryDate);
        }
    }

    const handleClose = () => {
        toggleModal(false);
    }

    const handlePropertyChange = (status, index) => {
        setAreChangesDone(true);
        const arr = JSON.parse(JSON.stringify(properties));
        arr[index].status = status === RULE_STATUS.VERIFIED ? RULE_STATUS.NOT_VERIFIED : RULE_STATUS.VERIFIED;
        setProperties(arr);
    }

    const handleNoteChange = (e) => {
        setAreChangesDone(true);
        setNote(e.target.value);
    }

    // To Check if DSC_SIGNED or SIGNED_N_STAMPED available or if all are checked
    const checkIfValidBeforeVerifying = (properties) => {
        if (!properties) return false;
        const mandatoryStatuses = [RULES.SIGNED_N_STAMPED, RULES.DSC_SIGNED,RULES.SIGNED_FIRST_LAST];       // Either one of them with status verified if present
        let isValid = true;
        let isOneValidAmongMandatoryStatuses = false;
        let propertiesContainMandatoryStatuses = false;
        for (let property of properties) {
            if(note) return true;
            if (property.name === RULES.BORROWING_CLAUSE) continue;
            else if (mandatoryStatuses.includes(property.name)) {
                propertiesContainMandatoryStatuses = true;
                if (isOneValidAmongMandatoryStatuses) continue;
                else if (property.status === RULE_STATUS.VERIFIED) isOneValidAmongMandatoryStatuses = true;
            }
            else if (property.status !== RULE_STATUS.VERIFIED) isValid = false;
        }
        return propertiesContainMandatoryStatuses ? isOneValidAmongMandatoryStatuses && isValid : isValid;
    }

    const isVerifyButtonDisabled = () => {
        if (isDocVerified()) return false;
        if (!properties) return true;
        if (checkIfValidBeforeVerifying(properties)) return false;
        return true;
    }

    const isDocVerified = () => {
        return docData?.status?.toLowerCase() === RULE_STATUS.VERIFIED?.toLowerCase();
    }

    const verifyDoc = () => {
        if (isDocVerified()) return;
        updateModeration(true);
    }

    const needUpdateOnDoc = (status) => {
        if (status !== RULE_STATUS.VERIFIED) updateModeration();
    }

    const validateBeforeModerating = () => {
        let isValid = true;
        let message = '';
        if (!areChangesDone) {
            isValid = false;
            message = "No moderation is done";
        }
        else if (!docData?.docId) {
            isValid = false;
            message = "No document is present";
        }
        else if (expiryDateChecked && !expiryDate) {
            isValid = false;
            message = "You need to enter expiry date";
        }
        return { isValid, message };
    }

    const updateModeration = async(isVerifyingDoc) => {
        const { isValid, message } = validateBeforeModerating();
        if (!isValid) {
            if (message) showNotification("Error", message);
            return;
        }
        const body = {
            docId: docData?.docId,
            rules: properties,
            comment: note,
            moderatorName: userName
        };
        if (expiryDateChecked) body.expiryDate = expiryDate + " 00:00:00";
        if (isVerifyingDoc) body.status = RULE_STATUS.VERIFIED;
        else body.status = NEED_UPDATE;
        const res = await callApi("file/moderateDoc", "post", body, {}, false, false, false, false, false, true);
        if (res?.responseData?.responseCode === DOC_SERVICE_RESPONSE.SUCCESS) {
            onModerateSuccess(res?.moderatedDoc);
            showNotification("Success", "Updated successfully");
        }
        else {
            const message = res.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification("Error", message);
        }
        setAreChangesDone(false);
    }

    const validateCommentBeforeUploadOrDelete = (note) => {
        let isValid = true;
        let message = '';
        if((docTypeName.includes("Additional") || docTypeName.includes("Deal Doc")) && (!description && !descriptionProp)) {
            isValid = false;
            message = "You need to add description for additional docs";
        }
        if (!note) {
            isValid = false;
            message = "You need to add comment before upload or delete";
        }
        return {
            isValid,
            message
        }
    }

    const onUploadSuccessFiltered = () => {
        if (onUploadSuccess) onUploadSuccess();
        setIsUploadedNew(true);
    }

    const onDeleteSuccessFiltered = () => {
        if (onDeleteSuccess) onDeleteSuccess();
        setDocDetails(null);
    }

    const onDocsUpdateSuccess = () => {
        setIsUploadedNew(false);
    }

    return (
        <Modal show={showModal} onHide={handleClose} className="CompanyDocumentVaultModal">
            <Modal.Body className='p-0'>
                <div className='row'>
                    <div className='col-md-8 PDFOuterSection'>
                        <FileReader
                            docs={docDetails ? [{ ...docDetails }] : []}
                            shouldUpdateDocs={isUploadedNew}
                            onDocsUpdateSuccess={onDocsUpdateSuccess}
                        />
                    </div>
                    <div className='col-md-4 pl-0'>
                        <div className='ModalBody'>
                            <div className='dflexJA p-3'>
                                <span className='color3 title'>{docTypeName} Document Moderation</span>
                                <button type="button" className="close">
                                    <img
                                        src="../../../../../assets/modal-cross.svg"
                                        alt=""
                                        onClick={handleClose}
                                    />
                                </button>
                            </div>
                            { isCompanyActive && <div className='p-3'>
                                <input type="checkBox" value={doProductSign} defaultChecked={doProductSign} onChange = {()=>setDoProductSign(!doProductSign)}/> Product Sign <i class="fa-solid fa-signature"></i>
                            </div>}
                            <UploadDoc
                                doProductSign = {doProductSign}
                                preSignedUrl={docDetails?.uri}
                                docId={docData?.docId}
                                docTypeName={docTypeName}
                                docTypeId={docData?.docTypeId}
                                flowId={FLOW_ID.KYC}
                                validateBeforeUpload={() => validateCommentBeforeUploadOrDelete(note)}
                                validateBeforeDelete={() => validateCommentBeforeUploadOrDelete(note)}
                                comment={note}
                                onSuccess={onUploadSuccessFiltered}
                                onDeleteSuccess={onDeleteSuccessFiltered}
                                section={section}
                                beneficialOwnerId={docData?.beneficiaryId}
                                beneficialOwnerName={docData?.beneficiaryName}
                                description = {description}
                                showUploadNew = {!docTypeName.includes("Additional")}
                                setShowModal = {setShowModal}
                                refId = {docData?.refId}
                                refName = {docData?.refName}
                            />
                            <div className='p-3'>
                                {(docTypeName.includes("Additional") || docTypeName.includes("Deal Doc")) && <input type="text" value={description} placeholder={descriptionProp ?? "Add Description"} onChange = {(e)=>setDescription(e.target.value)}/>}
                            </div>
                            <div className='CheckList mx-3 mb-3'>
                                {!isEmpty(properties) && properties.map((property, index) => (
                                    <div key={property?.ruleId} className={index === 0 ? 'dflex-ac mb-2 mt-2' : 'dflex-ac mb-2'}>
                                        <input
                                            type="checkbox"
                                            className='mr-2'
                                            checked={property.status === RULE_STATUS.VERIFIED}
                                            onChange={() => handlePropertyChange(property.status, index)}
                                        />
                                        <label>{property.name}</label>
                                    </div>
                                ))}
                                <DocExpiryDate
                                    docExpiryDate={docData?.expiryDate}
                                    expiryDate={expiryDate}
                                    setExpiryDate={setExpiryDate}
                                    checked={expiryDateChecked}
                                    setChecked={setExpiryDateChecked}
                                    setAreChangesDone={setAreChangesDone}
                                />
                            </div>
                            <textarea placeholder='Add any comments here' className='mx-3' value={note} onChange={handleNoteChange} />
                            <div className='dflexJA flex-wrap m-3' style={{ visibility: 'hidden' }}>
                                <span className='shareCurrent'>Share current document with Investors?</span>
                                <div className='DocumentVaultSwitch'>
                                    <label className="switch">
                                        <input type="checkbox" checked={isSharedWithInvestor} onChange={toggleSharedWithInvestor} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <div className='dflexJA flex-wrap mx-3 mb-3 ModalBtn'>
                                <button
                                    className='NeedUpdate'
                                    onClick={() => needUpdateOnDoc(docData?.status)}
                                    disabled={docData?.status === RULE_STATUS.VERIFIED}
                                >Need Update</button>
                                {isVerifyButtonDisabled() ? (
                                    <button className='VerifiedDisable cursor-default'>Verify</button>
                                ) : (
                                    <button className={isDocVerified() ? 'Verified cursor-default' : 'Verified'} onClick={verifyDoc}>
                                        {isDocVerified() ? "Verified" : "Verify"}
                                    </button>
                                )}
                            </div>
                            <div className='dflexJA flex-wrap mx-3 mb-3 fs10'>
                                <span><b>Uploaded:</b> {docData?.uploadedAt ? moment(docData?.uploadedAt).format("DD MMM YYYY") : '-'}</span>
                                <span><b>Moderated:</b> {docData?.updatedAt ? moment(docData?.updatedAt).format("DD MMM YYYY") : '-'} by {moderatorName ?? 'N/A'}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
