import callApi from "../../util/apiCaller";
import { isEnvProd, showNotification } from "../../util/utility";
import storage from "../../util/storageService";
import _ from "lodash";
import moment from "moment";
import { CURRENT_TAB_DATA_TYPE, CURRENT_TAB_TYPE } from "../../common/ENUM";
import { ADMIN, CERBERUS_TOKEN, EMAIL_ID, GENERIC_ERROR, ORG_ID, SHOW_NOTIFICATION, USER_ID } from "../../constants/enums";
import { API_STATUS } from "../../constants/enums";

export const ATTEMPT_TO_LOGIN = "ATTEMPT_TO_LOGIN";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const ATTEMPT_TO_LOGOUT = "ATTEMPT_TO_LOGOUT";

export const FETCHING_USER_PROFILE = "FETCHING_USER_PROFILE";
export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";

export const FETCHING_ESCROW_ACCOUNT_INFO = "FETCHING_ESCROW_ACCOUNT_INFO";
export const FETCH_ESCROW_ACCOUNT_INFO = "FETCH_ESCROW_ACCOUNT_INFO";

export const SHOW_MENU_ON_RIGHT_CLICK = "SHOW_MENU_ON_RIGHT_CLICK";

// export const FETCH_SALES_PARTNER = "FETCH_SALES_PARTNER";
// export const FETCH_CAPITAL_PARTNER = "FETCH_CAPITAL_PARTNER";
export const FETCH_AGENTS = "FETCH_AGENTS";

export const FETCHING_INVESTEE_ACCOUNT_STATEMENT =
  "FETCHING_INVESTEE_ACCOUNT_STATEMENT";

export const FETCHING_UNDERWRITING_DATA = "FETCHING_UNDERWRITING_DATA";
export const FETCH_UNDERWRITING_DATA = "FETCH_UNDERWRITING_DATA";

export const FETCH_UNDERWRITING_CLASSIFICATION_DATA =
  "FETCH_UNDERWRITING_CLASSIFICATION_DATA";
export const FETCHING_UNDERWRITING_CLASSIFICATION_DATA =
  "FETCHING_UNDERWRITING_CLASSIFICATION_DATA";

export const POST_UNDERWRITING_EDITED_DATA = "POST_UNDERWRITING_EDITED_DATA";
export const POSTING_UNDERWRITING_EDITED_DATA =
  "POSTING_UNDERWRITING_EDITED_DATA";

export const POST_UNDERWRITING_CREATED_SUBCLASSIFICATION =
  "POST_UNDERWRITING_CREATED_SUBCLASSIFICATION";
export const POSTING_UNDERWRITING_CREATED_SUBCLASSIFICATION =
  "POSTING_UNDERWRITING_CREATED_SUBCLASSIFICATION";

export const CHANGE_SCREEN_ON_COMPANIES_OVERVIEW =
  "CHANGE_SCREEN_ON_COMPANIES_OVERVIEW";

export const UPDATE_SELECTED_TOT_ID_TO_REDIRECT =
  "UPDATE_SELECTED_TOT_ID_TO_REDIRECT";

export const SET_REDIRECT_FROM_DEALS_PAGE = "SET_REDIRECT_FROM_DEALS_PAGE";
export const FETCH_USER_ROLES = "FETCH_USER_ROLES";
export const SET_IS_ADD_ON_LIMIT_REQUESTED = "SET_IS_ADD_ON_LIMIT_REQUESTED";

export const FETCHING_ARR_DATA = "FETCHING_ARR_DATA";
export const FETCH_ARR_DATA = "FETCH_ARR_DATA";

export function showMenuOnRightClick(id) {
  return (dispatch) => {
    dispatch({
      type: SHOW_MENU_ON_RIGHT_CLICK,
      idOnRightClick: id,
    });
  };
}
export function loginWithOtp(creds) {
  return async (dispatch) => {
    storage.flush();
    dispatch({
      type: ATTEMPT_TO_LOGIN,
      isLoggingIn: true,
    });
    const res = await callApi('/mfa/validateOtp', 'post', creds, {}, false, false, false, true);
    if (res.responseData.responseCode === 20) {
      if (res.user.authToken) {
        storage.set(USER_ID, res.user.userId);
        storage.set(CERBERUS_TOKEN, res.user.authToken);
        storage.set(ORG_ID, res.user.organizationId);
        storage.set(EMAIL_ID, res.user.emailId);
        if (res.user && res.user.isAdmin) {
          storage.set(ADMIN, 'ADMIN')
        }

        if(isEnvProd && window.hj) {
            window.hj('identify', res.user.userId, {
          });
        }
        await dispatch(fetchUserProfile(res.user.userId))
      }
    }
    else {
      dispatch({
        type: LOGIN_FAILED,
        isLoggingIn: false,
        error: res.responseData.responseMessage,
      });
      showNotification('error', res.responseData.responseMessage);
    }
  }
}

export function verifyUserEmail(query) {
  return (dispatch) => {
    return callApi(`auth/verify-email${query}`, "get").then((res) => {
      if (res.status === "Success" && res.data && res.data.message) {
        showNotification("Success", res.data.message);
      }
    });
  };
}

export function resendVerifyUserEmail(user_id) {
  return (dispatch) => {
    return callApi(
      `auth/user/${user_id}/resend-verification-email`,
      "get"
    ).then((res) => {
      if (res.status === "Success" && res.data && res.data.message) {
        showNotification("Success", res.data.message);
      }
    });
  };
}

export function fetchUserProfile(user_id) {
  return (dispatch) => {
    dispatch({
      type: FETCHING_USER_PROFILE,
      is_fetching_user: true,
    });
    return callApi(`auth/profile/${user_id}/user/info`, "get").then((res) => {
      if (res.status === "Success") {
        dispatch({
          type: FETCH_USER_PROFILE,
          investee: res.data.investee,
          investeeOrganization: res.data.investeeOrganization,
          investeeProfileMetrics: res.data.investeeProfileMetrics,
          investeeContractStats: res.data.investeeContractStats,
          investor: res.data.investor,
          investorOrganization: res.data.investorOrganization,
          admin: res.data.admin,
          permissions: res.data.permissions,
        });
        dispatch({
          type: LOGIN_SUCCESS,
          isAuthenticated: true,
          isEmailVerified: res.data.user.is_email_verified,
          isUserApproved: res.data.user.is_user_approved,
          user: res.data.user,
          isLoggingIn: false,
        });
      } else {
        storage.flush();
        dispatch({
          type: LOGIN_FAILED,
          isAuthenticated: false,
          isLoggingIn: false,
        });
        dispatch({
          type: FETCHING_USER_PROFILE,
          is_fetching_user: false,
        });
      }
    });
  };
}

export function logOutUser() {
  storage.flush();
  window.location = "/";
  return (dispatch) => {
    dispatch({
      type: ATTEMPT_TO_LOGOUT,
      isVerifyingToken: false,
      isAuthenticated: false,
      user: {},
      token: "",
    });
  };
}

export function fetchEscrowAccountInfo(org_id, type) {
  return (dispatch) => {
    dispatch({
      type: FETCHING_ESCROW_ACCOUNT_INFO,
      isFetchingEscrowAccountInfo: true,
    });
    let url = `repayments/investor-organization/${org_id}/balance-info`;
    if (type === "investee") {
      url = `repayments/investee-organization/${org_id}/balance-info`;
    }
    return callApi(url, "get").then((res) => {
      if (res.status === "Success") {
        if (res.data) {
          dispatch({
            type: FETCH_ESCROW_ACCOUNT_INFO,
            // isFetchingEscrowAccountInfo: false,
            escrowAccountInfo: res.data.data[0],
          });
        } else {
          dispatch({
            type: FETCH_ESCROW_ACCOUNT_INFO,
            // isFetchingEscrowAccountInfo: false,
            escrowAccountInfo: {
              user_id: "NA",
              name: "NA",
              balance: "NA",
              escrow_account: "NA",
              bank_name: "NA",
              ifsc_code: "NA",
            },
          });
        }
      } else {
        dispatch({
          type: FETCHING_ESCROW_ACCOUNT_INFO,
          // isFetchingEscrowAccountInfo: false,
        });
        dispatch({
          type: FETCH_ESCROW_ACCOUNT_INFO,
          // isFetchingInvestorEscrowAccountInfo: false,
          escrowAccountInfo: {
            user_id: "NA",
            name: "NA",
            balance: "NA",
            escrow_account: "NA",
            bank_name: "NA",
            ifsc_code: "NA",
          },
        });
      }
    });
  };
}

// export function fetchSalesPartner() {
//   return dispatch => {
//     return callApi(`sales-partner/get-all-sales-partner`, "get").then(res => {
//       if (res.status === "Success" && !_.isEmpty(res.data)) {
//         dispatch({
//           type: FETCH_SALES_PARTNER,
//           salesPartners: res.data
//         });
//       } else {
//         showNotification("Error", "Couldn't fetch sales partners!");
//       }
//     });
//   }
// }

// export function fetchCapitalPartner() {
//   return dispatch => {
//     return callApi(`agents/get-all-agents`, "get").then(res => {
//       if (res.status === "Success" && !_.isEmpty(res.data)) {
//         dispatch({
//           type: FETCH_CAPITAL_PARTNER,
//           capitalPartners: res.data
//         });
//       } else {
//         showNotification("Error", "Couldn't fetch capital partners!");
//       }
//     });
//   };
// }

export function fetchAgentsData(
  sales_partner_id,
  data_specialist_id
) {
  return (dispatch) => {
    dispatch({
      type: FETCH_AGENTS,
      isFetchingAgentsData: true,
    });
    return callApi(`/agents/get-all-agents`, "get").then((res) => {
      if (res.status === "Success" && res.data.length > 0) {
        const salesPartner =
          !_.isEmpty(res.data) &&
          res.data?.find((p) => {
            return (
              p._id === sales_partner_id && p.agent_type === "sales_partner"
            );
          });
        const dataSpecialist =
          !_.isEmpty(res.data) &&
          res.data?.find((p) => {
            return (
              p._id === data_specialist_id && p.agent_type === "data_specialist"
            );
          });
        const underwriters =
          !_.isEmpty(res.data) &&
          res.data?.filter((p) => {
            if (p.agent_type === "underwriter") {
              return p;
            }
          });
        dispatch({
          type: FETCH_AGENTS,
          salesPartner: salesPartner,
          dataSpecialist: dataSpecialist,
          underwriters: underwriters,
          isFetchingAgentsData: false,
        });
      } else {
        const message = res.data?.message
          ? res.data?.message
          : "Some error occurred!";
        showNotification("Error", message);
        dispatch({
          type: FETCH_AGENTS,
          isFetchingAgentsData: false,
        });
      }
    });
  };
}

let currentOffset = 0;
const limit = 200;
export function fetchUnderwritingData(
  investeeOrganizationId,
  currentTab,
  dataType,
  startDate,
  endDate,
  investee_onboarded_id,
  resetOffset,
  finbox_entity_id,
  setHasMore
) {
  startDate = moment(startDate, "YYYY-MM-DD").format("MM-DD-YYYY");
  endDate = moment(endDate, "YYYY-MM-DD").format("MM-DD-YYYY");
  return (dispatch) => {
    dispatch({
      type: FETCHING_UNDERWRITING_DATA,
      is_fetching_underwriting_data: resetOffset ? true : false,
    });
    let endPoint = "";
    if (currentTab == CURRENT_TAB_TYPE.bank_statements) {
      if (dataType == "pdf") {
        endPoint = `/investee_onboarding/${investee_onboarded_id}/bank-statements`;
      } else if (dataType == "raw") {
        if (resetOffset) {
          currentOffset = 0;
        } else {
          currentOffset += limit;
        }
        endPoint = `/bank/bank_stmt/${dataType}?investeeOrgId=${investeeOrganizationId}&limit=${limit}&offset=${currentOffset}`;
      } else if (dataType === CURRENT_TAB_DATA_TYPE.excel_report) {
        endPoint = `/bank/bank_stmt/${dataType}?investeeOrgId=${investeeOrganizationId}&finboxEntityId=${finbox_entity_id}`;
      } else {
        endPoint = `/bank/bank_stmt/${dataType}?investeeOrgId=${investeeOrganizationId}`;
      }
    } else if (currentTab === CURRENT_TAB_TYPE.invoices) {
      if (resetOffset) {
        currentOffset = 0;
      } else currentOffset += limit;
      endPoint = `/${currentTab}/${dataType}?investeeOrgId=${investeeOrganizationId}&startDate=${startDate}&endDate=${endDate}&limit=${limit}&offset=${currentOffset}`;
    } else if (currentTab === CURRENT_TAB_TYPE.GST) {
      if (dataType === CURRENT_TAB_DATA_TYPE.pivot){
        setHasMore(false); //@todo: Need a more elegant way to handle this unnecessary api call
        endPoint = `/invoices/classified?investeeOrgId=${investeeOrganizationId}&gst=true`;
      }
      else if (dataType === CURRENT_TAB_DATA_TYPE.spread) {
        endPoint = `/invoices/${dataType}?investeeOrgId=${investeeOrganizationId}&gst=true`;
      } else if (dataType === CURRENT_TAB_DATA_TYPE.profile) {
        endPoint = `/invoices/gst/${dataType}?investeeOrgId=${investeeOrganizationId}`;
      } else if (
        dataType === CURRENT_TAB_DATA_TYPE.gst ||
        dataType === CURRENT_TAB_DATA_TYPE.gstr2a
      ) {
        if (resetOffset) {
          currentOffset = 0;
        } else currentOffset += limit;
        endPoint = `/invoices/raw/${dataType}?investeeOrgId=${investeeOrganizationId}&limit=${limit}&offset=${currentOffset}`;
      } else {
        endPoint = `/invoices/raw/${dataType}?investeeOrgId=${investeeOrganizationId}`;
      }
    } else if (currentTab === CURRENT_TAB_TYPE.contracts) {
      endPoint = `${dataType}?investeeOrgId=${investeeOrganizationId}`;
    } else if (currentTab === CURRENT_TAB_TYPE.debt_schedule) {
      endPoint = `debt/debt_schedule/raw?investeeOrgId=${investeeOrganizationId}`
    } else {
      endPoint = `/mis/${currentTab}/${dataType}?investeeOrgId=${investeeOrganizationId}&startDate=${startDate}&endDate=${endDate}`;
    }
    if (currentTab == CURRENT_TAB_TYPE.bank_statements && dataType == "pdf") {
      setHasMore(false);
      return callApi(
        endPoint,
        "get",
        undefined,
        undefined,
        undefined,
        false
      ).then((res) => {
        if (res.data) {
          dispatch({
            type: FETCH_UNDERWRITING_DATA,
            reset_offset: resetOffset,
            is_fetching_underwriting_data: false,
            underwriting_data_headers: res.data.data?.headers,
            underwriting_data_rows: res.data.data?.rows,
          });
        } else {
          dispatch({
            type: FETCHING_UNDERWRITING_DATA,
            is_fetching_underwriting_data: false,
            underwriting_data_headers: [],
            underwriting_data_rows: [],
          });
        }
      });
    }
    return callApi(endPoint, "get", undefined, undefined, undefined, true).then(
      (res) => {
        if (res.success && res.data) {
          if (res?.data?.data?.rows?.length < limit) {
            setHasMore && setHasMore(false);
          }
          dispatch({
            type: FETCH_UNDERWRITING_DATA,
            is_fetching_underwriting_data: false,
            reset_offset: resetOffset,
            underwriting_data_headers: res.data.summaryData ? res.data?.summaryData : res.data.data?.headers,
            underwriting_data_rows: !_.isEmpty(res.data.summaryData) ? res.data?.summaryData?.[0].rows : !_.isEmpty(res.data.data?.rows) ? res.data.data?.rows : "",
            lastDate: res.data.summaryData ? res.data?.lastDate : res.data.data?.lastDate,
            currentBalance: res.data.summaryData ? res.data?.currentBalance : res.data.data?.currentBalance,
            currentBalanceStr: res.data.summaryData ? res.data?.currentBalanceStr : res.data.data?.currentBalanceStr,
            numberOdAccounts: res.data.summaryData ? res.data?.numberOdAccounts : res.data.data?.numberOdAccounts,
            odLimit: res.data.summaryData ? res.data?.odLimit : res.data.data?.odLimit,
            odLimitDetails: res.data.summaryData ? res.data?.odLimitDetails : res.data.data?.odLimitDetails,
            fdBalance:  res.data.summaryData ? res.data?.fdBalance : res.data.data?.fdBalance,
            accounts: res.data.summaryData ? res.data?.accounts : res.data.data?.accounts ?? "",
            contractThreshold: res?.data?.contractThreshold,
            contractSynced: res?.data?.contractSynced,
            contractSyncDate: res?.data?.contractSyncDate,
            mutualFundAmount: res.data.summaryData ? res.data?.mutualFundAmount : res.data.data?.mutualFundAmount
          });
        } else {
          if (!resetOffset) {
            setHasMore && setHasMore(false);
          } setHasMore(false);
          dispatch({
            type: FETCHING_UNDERWRITING_DATA,
            is_fetching_underwriting_data: false,
            underwriting_data_headers: resetOffset ? [] : null,
            underwriting_data_rows: resetOffset ? [] : null,
          });
        }
      }
    );
  };
}

export function fetchArrData(investee_organization_id) {
  return (dispatch) => {
    dispatch({
      type: FETCHING_ARR_DATA,
      isFetchingArrData: true
    });
    return callApi(
      `/investeeorganization/${investee_organization_id}/get-arr-data`,
      "get"
    )
      .then((res) => {
        if (res.status === API_STATUS.SUCCESS) dispatch({
          type: FETCH_ARR_DATA,
          arrData: res.data
        });
        else showNotification(SHOW_NOTIFICATION.ERROR, GENERIC_ERROR);
      })
      .catch((err) => showNotification(SHOW_NOTIFICATION.ERROR, GENERIC_ERROR))
      .finally(() => dispatch({
          type: FETCHING_ARR_DATA,
          isFetchingArrData: false
        })
      );
  }
}

export function fetchUnderwritingClassificationData(
  currentTab,
  isFirstRender,
  isReseting,
  index
) {
  return (dispatch) => {
    dispatch({
      type: FETCHING_UNDERWRITING_CLASSIFICATION_DATA,
      is_fetching_underwriting_classification_data: true,
    });
    return callApi(
      `/classifications/?className=${currentTab}`,
      "get",
      undefined,
      undefined,
      undefined,
      true
    ).then((res) => {
      if (res.success && res.data) {
        dispatch({
          type: FETCH_UNDERWRITING_CLASSIFICATION_DATA,
          is_fetching_underwriting_classification_data: false,
          underwriting_classification_data: res.data.data,
          isReseting: isReseting,
          underwriting_row_index_to_update_subclassificationId: index,
        });
      } else {
        dispatch({
          type: FETCHING_UNDERWRITING_CLASSIFICATION_DATA,
          is_fetching_underwriting_classification_data: false,
        });
      }
    });
  };
}

export function postUnderwritingEditedData(
  investeeOrganizationId,
  currentTab,
  dataType,
  startDate,
  endDate,
  underwritingEditedData,
  setEditClassification,
  setEditedItems,
  setIsSaving
) {
  return (dispatch) => {
    dispatch({
      type: POSTING_UNDERWRITING_EDITED_DATA,
      isposting_underwriting_edited_data: true,
    });
    return callApi(
      `/mis/${currentTab}/update-classifications?investeeOrgId=${investeeOrganizationId}`,
      "put",
      underwritingEditedData,
      undefined,
      undefined,
      true
    ).then((res) => {
      if (res.success) {
        dispatch({
          type: POSTING_UNDERWRITING_EDITED_DATA,
          isposting_underwriting_edited_data: false,
        });
        showNotification("Success", res.message);
        setEditClassification(false);
        setEditedItems([]);
        setIsSaving(true);
        dispatch(
          fetchUnderwritingData(
            investeeOrganizationId,
            currentTab,
            dataType,
            startDate,
            endDate
          )
        );
      } else {
        showNotification("error", res.message);
        dispatch({
          type: POSTING_UNDERWRITING_EDITED_DATA,
          isposting_underwriting_edited_data: false,
        });
      }
    });
  };
}

export async function uploadRawMisData(
  investeeOrganizationId,
  currentTab,
  data
) {
  try {
    const res = await callApi(
      `/mis/${currentTab}/add-data?investeeOrgId=${investeeOrganizationId}`,
      "post",
      data,
      undefined,
      undefined,
      true
    );
    if (res && res.success) {
      showNotification("success", res.message);
      return true;
    } else {
      showNotification("error", "Error occurred while uploading csv");
    }
  } catch (err) {
    showNotification("error", "Error occurred while uploading csv");
  }
  return false;
}

export async function uploadRawInvoiceData(investeeOrganizationId, data) {
  try {
    const res = await callApi(
      `/invoices/add-raw-invoices?investeeOrgId=${investeeOrganizationId}`,
      "post",
      data,
      undefined,
      undefined,
      true
    );
    if (res && res.success) {
      showNotification("success", res.message);
      return true;
    } else {
      showNotification("error", "Error occurred while uploading raw invoices");
    }
  } catch (err) {
    showNotification("error", "Error occurred while uploading raw invoices");
  }
  return false;
}

export async function uploadInvoiceSpreadData(investeeOrganizationId, data) {
  try {
    const res = await callApi(
      `/invoices/add-spread-invoices?investeeOrgId=${investeeOrganizationId}`,
      "post",
      data,
      undefined,
      undefined,
      true
    );
    if (res && res.success) {
      showNotification("success", res.message);
      return true;
    } else {
      showNotification("error", "Error occurred while uploading pivots");
    }
  } catch (err) {
    showNotification("error", "Error occurred while uploading pivots");
  }
  return false;
}

export async function updateInvoiceFrequencies(investeeOrgId, data) {
  try {
    if (!investeeOrgId) throw new Error("Invalid ID");
    const res = await callApi(
      `/invoices/update-invoices?investeeOrgId=${investeeOrgId}`,
      "post",
      data,
      undefined,
      undefined,
      true
    );
    if (res && res.success) {
      showNotification("success", res.message);
      return true;
    } else {
      showNotification("error", res.message);
    }
  } catch (err) {
    showNotification("error", "Error occurred while uploading csv");
  }
  return false;
}

export async function updateBankStatementsClassification(investeeOrganizationId, data) {
  try {
    const res = await callApi(
      `/bank/bank_stmt/update-classifications?investeeOrgId=${investeeOrganizationId}`,
      "put",
      data,
      undefined,
      undefined,
      true
    );
    if (res && res.success) {
      showNotification("success", res.message);
      return true;
    } else {
      showNotification("error", res.message);
    }
  } catch (err) {
    showNotification("error", "Error occurred while uploading csv");
  }
  return false;
}

export async function updateGstClassification(investeeOrganizationId, data) {
  try {
    const res = await callApi(
      `/invoices/gst/update-classifications?investeeOrgId=${investeeOrganizationId}`,
      "put",
      data,
      undefined,
      undefined,
      true
    );
    if (res && res.success) {
      showNotification("success", res.message);
      return true;
    } else {
      showNotification("error", res.message);
    }
  } catch (err) {
    showNotification("error", "Error occurred while uploading csv");
  }
  return false;
}

export async function synchronizeContracts(investeeOrganizationId, getUnderwritingData) {
  try {
    const res = await callApi(
      `/contracts/syncContracts?investeeOrgId=${investeeOrganizationId}`,
      "get",
      {},
      undefined,
      undefined,
      true
    );
    if (res && res.success) {
      showNotification("success", res.message);
      getUnderwritingData()
      return true;
    } else {
      showNotification("error", res.message);
    }
  } catch (err) {
    showNotification("error", "Error occurred while uploading csv");
  }
  return false;
}

export async function updateContracts(data, investeeOrganizationId) {
  try {
    const res = await callApi(
      `/contracts/update-contracts?investeeOrgId=${investeeOrganizationId}`,
      "post",
      data,
      undefined,
      undefined,
      true
    );
    if (res && res.success) {
      showNotification("success", res.message);
      return true;
    } else {
      showNotification("error", res.message);
    }
  } catch (err) {
    showNotification("error", "Error occurred while uploading csv");
  }
  return false;
}
export async function refreshSourceData(investeeOrganizationId) {
  try {
    const res = await callApi(
      `/lambda/trigger-integration?investeeOrgId=${investeeOrganizationId}`,
      "get",
      undefined,
      undefined,
      undefined,
      true
    );
    if (res && res.success && res.data.status) {
      showNotification("success", res.message);
      return true;
    } else {
      showNotification("error", "Error occurred while refreshing source data");
    }
  } catch (error) {
    showNotification("error", "Error occurred while refreshing source data");
  }
  return false;
}

export async function triggerBankDetails(investeeOrganizationId, finboxEntityId) {
  try {
    const res = await callApi(
      `/lambda/trigger-bank-details?investeeOrgId=${investeeOrganizationId}&finboxEntityId=${finboxEntityId}`,
      "get",
      undefined,
      undefined,
      undefined,
      true
    );
    if (res && res.success) {
      showNotification("success", res.message);
      return true;
    } else {
      showNotification("error", "Error occurred while triggering bank details");
    }
  } catch (error) {
    showNotification("error", "Error occurred while triggering bank details");
  }
  return false;
}


export async function updateGst(investeeOrganizationId) {
  try {
    const res = await callApi(
      `/invoices/update-gst?investeeOrgId=${investeeOrganizationId}`,
      "get",
      undefined,
      undefined,
      undefined,
      true
    );
    if (res && res.success) {
      showNotification("success", res.message);
      return true;
    } else {
      showNotification("error", "Error occurred while fetching gst details");
    }
  } catch (error) {
    showNotification("error", "Error occurred while fetching gst details");
  }
  return false;
}


export async function syncInsights(investeeOrganizationId) {
  try {
    const res = await callApi(
      `/classifications/sync-insights?investeeOrgId=${investeeOrganizationId}`,
      "get",
      undefined,
      undefined,
      undefined,
      true
    );
    if (res && res.success) {
      showNotification("success", res.message);
      return true;
    } else {
      showNotification("error", "Error occurred while syncing insights");
    }
  } catch (error) {
    showNotification("error", "Error occurred while syncing insights");
  }
  return false;
}

export async function triggerClassificationModel(investeeOrganizationId) {
  try {
    const res = await callApi(
      `/lambda/trigger-classification?investeeOrgId=${investeeOrganizationId}`,
      "get",
      undefined,
      undefined,
      undefined,
      true
    );
    if (res && res.success && res.data.status) {
      showNotification("success", res.message);
      return true;
    } else {
      showNotification(
        "error",
        "Error occurred while invoking classification model"
      );
    }
  } catch (error) {
    showNotification(
      "error",
      "Error occurred while invoking classification model"
    );
  }
  return false;
}

export async function triggerInvoicesPivot(investeeOrganizationId) {
  try {
    const res = await callApi(
      `/lambda/trigger-invoices-pivot?investeeOrgId=${investeeOrganizationId}`,
      "get",
      undefined,
      undefined,
      undefined,
      true
    );
    if (res && res.success && res.data.status) {
      showNotification("success", res.message);
      return true;
    } else {
      showNotification("error", "Error occurred while invoking invoices pivot");
    }
  } catch (error) {
    showNotification("error", "Error occurred while invoking invoices pivot");
  }
  return false;
}

export async function investmentReportS3Sign(file, investee_organization_id) {
  try {
    const extension = file.name.split(".")[1];
    const body = {
      extension,
      fileName: file.name,
      investee_organization_id,
      file_type: file.type,
    };
    const response = await callApi(
      "/investee-investment-report/s3/sign",
      "post",
      body
    );
    if (response && response.status === "Success" && !_.isEmpty(response.data))
      return response;
  } catch (error) {
    console.error(error);
  }
  return null;
}

export async function uploadDocumentToS3(file, sign) {
  try {
    const data = {
      ...sign.data.fields,
      "Content-Type": file.type,
      file,
    };
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    const response = await fetch(sign.data.url, {
      method: "post",
      body: formData,
    });
    if (response.ok) return true;
    return false;
  } catch (error) {
    console.error(error);
    showNotification(
      "error",
      "Error occurred while signing investment reports"
    );
  }
  return false;
}

export async function updateInvesteeDocuments(dealId, body) {
  try {
    const response = await callApi(
      `admin/deals/${dealId}/data-update-investment-report`,
      "post",
      body
    );
    if (response && response.status === "Success") return true;
  } catch (error) {
    console.error(error);
  }
  return false;
}

export function changeScreenOnCompaniesOverview(view) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_SCREEN_ON_COMPANIES_OVERVIEW,
      viewOnCompaniesOverview: view,
    });
  };
}
export function updateSelectedTotIdToRedirect(totId) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SELECTED_TOT_ID_TO_REDIRECT,
      selectedTotIdToRedirect: totId,
    });
  };
}

export function setRedirectFromDealsPage(isRedirect, totId, offerId) {
  return (dispatch) => {
    dispatch({
      type: SET_REDIRECT_FROM_DEALS_PAGE,
      redirectFromDealsPage: { isRedirect: isRedirect, totId: totId, offerId: offerId }
    })
  }
}

// Get all roles, and generate roleObjectMap
export function fetchAllRoles() {
  return async (dispatch) => {
    callApi(`/investee_organization/get-all-roles`, 'post').then((res) => {
      if (res.status === API_STATUS.SUCCESS) {
        const roles = res.data.roles;
        const roleObjectMap = roles.reduce((prev, curr) => ({ ...prev, [curr._id]: curr }), {});
        dispatch({
          type: FETCH_USER_ROLES,
          roleObjectMap,
        });
      } else {
        showNotification('error', 'Some error occured');
      }
    });
  };
}

export function setIsAddOnLimitRequested(limitAddOnObj) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_ADD_ON_LIMIT_REQUESTED,
      limitAddOnObj: { ...limitAddOnObj }
    })
  }
}