import React from 'react';

function Spinner() {
    return (
        <div id="loading-spinner" style={{ marginLeft: '5px' }}>
            <div className="spin-icon" style={{ width: '12px', height: '12px' }} />
        </div>
    );
}

export default Spinner;
