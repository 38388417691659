import _, { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import "../../../../../../../../css/Drawdowns.css";
import callApi from '../../../../../../../../util/apiCaller';
import { mergeByProperty } from '../../../../../../../../util/calculateTermUtil';
import { showNotification } from '../../../../../../../../util/utility';
import DrawdownsTableHead from './components/DrawdownsTableHead';
import DrawdownsTableRow from './components/DrawdownsTableRow';
import TradeContracts from './components/TradeContracts';

export default function Drawdowns({investeeOrg}) {
    const statusArray = ["Listed","Active","Processing","Uploaded"]
    const [contracts,setContracts] = useState([]);
    const [contractsForDrawdown,setContractsForDrawdown] = useState([]);
    const [selectedContractValue,setselectedContractValue] = useState(0);
    const [selectedPayOut,setselectedPayOut] = useState(0);
    const [drawdownStatus,setdrawdownStatus] = useState("NG");// not generated
    const [investorDeals,setInvestorDeals] = useState([])
    const [selectedInvestorDeal,setSelectedInvestorDeal] = useState("");
    const [sliderValue,setSliderValue] = useState(0);
    const [repaymentSchedule,setRepaymentSchedule] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [totalCount, setTotalCount] = useState(0);
    const [isFetchingContracts, setIsFetchingContracts] = useState(false);
    const [drawdownMode,setDrawDownMode] = useState("Listed");
    const [sanctionNotApprovedModal, setSanctionNotApprovedModal] = useState(false);
    
    useEffect(() => {
      getContracts();
    },[]);

    useEffect(() => {
      getContracts(true);
    },[drawdownMode])

    const clearStatus = () => {
      setdrawdownStatus("NG");
      getContracts(true);
      setContractsForDrawdown([]);
      setselectedPayOut(0);
      setselectedContractValue(0);
      setSliderValue(0);
      setPageNum(1);
    }
    
    useEffect(() => {
      if(!_.isEmpty(contractsForDrawdown)){
        getScheduleByContractsIds();
      } else {
        setRepaymentSchedule([])
      }
    },[contractsForDrawdown])

    const getContracts = (isReset) => {
      setIsFetchingContracts(true);
      let pageNumber = pageNum;
      if(isReset){
        pageNumber = 1;
        setPageNum(pageNumber);
      }
      callApi("admin/deals/get-contracts-drawdown","post",
      {investee_organization_id:investeeOrg._id,
        pageNum : pageNumber,
        pageSize : pageSize,
        status : drawdownMode
      }).then(res => {
        if(res && res.status === "Success"){
          setContracts(isReset ? res?.data?.contracts : mergeByProperty([...contracts], res?.data?.contracts, '_id'))
          setIsFetchingContracts(false);
          setTotalCount(res?.data?.count);
          setInvestorDeals(res?.data?.investorNameAndDealName);
        } else {
          setIsFetchingContracts(false);
          showNotification("Error","Error getting contracts");
        }
      })
    }

    const onSliderChangeCommited = () => {
      if(!selectedInvestorDeal){
        showNotification("Error","Please select investor first") ;
        return;
      } 
        const drawdowns = [];
        let selectedContractValueTemp = 0;
        let selectedPayoutValueTemp = 0;
        const listedContracts = contracts.filter(x => x.status === "Listed")
        for (let i = 0; i < listedContracts.length; i++) {
            const arr_elm_to_pick = listedContracts[i].keys_to_pick[listedContracts[i].custom_tenure_listed - 1];
            const xnpv_price_term = listedContracts[i].contract_xnpv_price_terms[arr_elm_to_pick];
            selectedContractValueTemp += listedContracts[i].recurring_payment_value * xnpv_price_term.terms * ((listedContracts[i].listed_perc ?? 100)/100 );
            selectedPayoutValueTemp += Number(xnpv_price_term.xnpv)  * getCorrectListedPerc(listedContracts[i].listed_perc,listedContracts[i].contract_xnpv_price_terms[0].listed_perc);

            if (selectedPayoutValueTemp <= sliderValue) {
              drawdowns.push(listedContracts[i]._id)
            } else {
                selectedContractValueTemp = selectedContractValueTemp - listedContracts[i].recurring_payment_value * xnpv_price_term.terms * ((listedContracts[i].listed_perc ?? 100)/100 );
                selectedPayoutValueTemp -= Number(xnpv_price_term.xnpv) * getCorrectListedPerc(listedContracts[i].listed_perc,listedContracts[i].contract_xnpv_price_terms[0].listed_perc);;
                break;
            }
        }        
      setContractsForDrawdown(drawdowns);
      setselectedContractValue(selectedContractValueTemp);
      setselectedPayOut(selectedPayoutValueTemp)
      setSliderValue(selectedContractValueTemp);
    }

    const getCorrectListedPerc = (listed_perc,xnpv_listed_perc) => {
      if(listed_perc === xnpv_listed_perc) return 1;
      else return listed_perc/100;
    }
    const onChangeCheckBox = (e,contract) => {
      if(!selectedInvestorDeal){
        showNotification("Error","Please select investor first") ;
        return;
      } 
        
      let drawdowns = [...contractsForDrawdown];
      let selectedContractValueTemp = selectedContractValue;
      let selectedPayoutValueTemp = selectedPayOut; 
     if(e.target.checked) {
      drawdowns.push(contract._id);
      selectedContractValueTemp += contract.recurring_payment_value*contract.terms * ((contract.listed_perc ?? 100)/100 );
      selectedPayoutValueTemp += contract.xnpv * getCorrectListedPerc(contract.listed_perc,contract.contract_xnpv_price_terms.listed_perc);
     } else {
       drawdowns.splice(drawdowns.indexOf(contract._id),1)
       selectedContractValueTemp -= contract.recurring_payment_value*contract.terms * ((contract.listed_perc ?? 100)/100 );
       selectedPayoutValueTemp -= contract.xnpv * getCorrectListedPerc(contract.listed_perc,contract.contract_xnpv_price_terms.listed_perc);;
     }
     setContractsForDrawdown(drawdowns);
     setselectedContractValue(selectedContractValueTemp);
     setselectedPayOut(selectedPayoutValueTemp);
     setSliderValue(selectedContractValueTemp);
    }

  const hasMoreRecords = () => {
      return contracts.length  < totalCount;
  }

  const loadMoreRecords = () => {
    setPageNum(pageNum + 1);
    getContracts();
  }
    const generateDrawdown = (isContinue = false) => {
      if(selectedPayOut > available_limit){
        showNotification("Error","Limit exceeded") ;
        return;
      }
      setdrawdownStatus("GI");//Generating
      const payload = {
        investee_organization_id:investeeOrg._id,
        investor_deal_id : selectedInvestorDeal,
        contract_ids : contractsForDrawdown,
        investment_value : selectedPayOut
      }
      if(isContinue === true) payload.is_continue = true;
      callApi("admin/deals/create-drawdown","post",payload).then(res => {
        if(res && res.status === "Success"){
          setdrawdownStatus("G");
        } else {
            if(res?.data?.message === "The sanction letter not generated yet") {
              setSanctionNotApprovedModal(true);
              setdrawdownStatus("NG");
              return;
            }
          setdrawdownStatus("NG");
          const message = res?.data?.message ?? "Error generating Drawdown";
          showNotification("Error",message);
        }
      })
    }

    const getScheduleByContractsIds = () => {
      if(!_.isEmpty(contractsForDrawdown)){
        callApi("admin/contract-transactions/view-schedule","post",
        {contract_ids : contractsForDrawdown,
          is_for_listed:true,
        }).then(res => {
          if(res && res.status === "Success"){
            setRepaymentSchedule(res.data);
          } else {
            showNotification("Error","Error generating Schedule");
          }
        })
      }
    }
    function delistContracts({investeeOrgId}) {
      if(isEmpty(investeeOrgId)) showNotification("Error","no investeeOrgId")
      return callApi(`investeeOrganization/${investeeOrgId}/delistAllContracts`, 'get').then((res) => {
          if (res.status === "Success") {
              getContracts(true);
              showNotification("Success", 'Contract(s) Delisted');
          } else {
            showNotification("Error", 'Contract Delisting Failed');
          }
      });
  }
    const toShowContracts = drawdownStatus === "G" ? contracts.filter(i => contractsForDrawdown.includes(i._id)) : contracts;
    const selectedInvestorDealObj = investorDeals.find(i => i._id === selectedInvestorDeal);
    const available_limit = (selectedInvestorDealObj?.allocated_amount - selectedInvestorDealObj?.drawn)*1_00000;
    return (
        <div className='p-4'>
            <div className="fs21 mr-4 fw600">Contracts</div>
            <select
              value={drawdownMode}
              onChange={(e) =>
              setDrawDownMode(e.target.value)
              }
              className="admin-form-field mt-3" style={{color: 'black'}}
              placeholder='Status'
              name=''
              id=''>
              {statusArray.map((item, index) => (
              <option key={index} value={item}>{item}</option>
                ))}
              </select>
              {drawdownMode === "Listed" && <button  className="delist-btn mt-3"
                  onClick={() => delistContracts({ investeeOrgId : investeeOrg._id})}>Delist All Contracts  <i class="fa fa-times fa-0.5x" aria-hidden="true"></i>
                  </button>}
            <div className='row'>
                <div className='col-md-8'>
                <div className='table-head no-lb'>
                    <table className='table team'>
                      <DrawdownsTableHead 
                      drawdownStatus = {drawdownStatus}
                      />
                      <tbody style={{ display: 'none' }}></tbody>
                    </table>
                  </div>
                  <div className='tableFixHead table-body'>
                    <table className='table team'>
                      <thead></thead>
                     { !_.isEmpty(toShowContracts) && toShowContracts.map((contract,index) => (
                        <DrawdownsTableRow 
                        key = {index}
                        loader={isFetchingContracts}
                        hasMore={hasMoreRecords}
                        loadMore={loadMoreRecords}
                        dataLength={contracts.length}
                        index={index}
                        custom_tenure = {investeeOrg.custom_tenure}
                        contract = {contract}
                        onChangeCheckBox = {onChangeCheckBox}
                        contractsForDrawdown = {contractsForDrawdown}
                        drawdownStatus = {drawdownStatus}
                        />
                     ))}
                    </table>
                  </div>
                </div>
                <div className='col-md-4'>
                   <TradeContracts 
                      selectedContracts = {contractsForDrawdown.length}
                      selectedContractValue = {selectedContractValue}
                      selectedPayOut = {selectedPayOut}
                      investorDeals = {investorDeals}
                      setSelectedInvestorDeal = {setSelectedInvestorDeal}
                      selectedInvestorDeal = {selectedInvestorDeal}
                      generateDrawdown = {generateDrawdown}
                      sanctionNotApprovedModal={sanctionNotApprovedModal}
                      setSanctionNotApprovedModal={setSanctionNotApprovedModal}
                      drawdownStatus = {drawdownStatus}
                      investee_organization_id = {investeeOrg._id}
                      available_limit = {available_limit}
                      onSliderChangeCommited = {onSliderChangeCommited}
                      sliderValue = {sliderValue}
                      setSliderValue = {setSliderValue}
                      repaymentSchedule = {repaymentSchedule}
                      clearStatus = {clearStatus}
                      setdrawdownStatus = {setdrawdownStatus}
                      selectedCompanyName = {investeeOrg.name}
                      selectedInvestorName = {selectedInvestorDealObj?.investor_name}
                    />
                </div>
            </div>
        </div>
    )
}
