import moment from 'moment';
import React, {
    // Component
} from 'react';
// import {Link} from 'react-router-dom';
// import moment from 'moment'

function HelpRequestTableRow(props) {
    return (
        <tr>
            <td class="text-truncate expand" style={{maxWidth:'100px',paddingLeft:'47px'}}>{props.item.contact_name}</td>
            <td>{props.item.reference}</td>
            <td>{props.item.phone_number}</td>
            <td>{props.item.contact_email}</td>
            <td>{props.item.issue_type}</td>
            <td class="text-truncate expand" style={{maxWidth:'100px'}}>{props.item.issue_description}</td>
            <td>{props.item.created_at ? moment(props.item.created_at).format("DD MMM YYYY hh:mm:ss") : "NA"}</td>
        </tr>
    )
}

export default HelpRequestTableRow;
