import React, { useState } from 'react';
import styles from './RenderStatus.module.scss';

export default function RenderStatus({ status, setStatus, statusOptions, isModerating, isExpired = false }) {
    const { SelectBox } = styles;

    return (
        <div className='dflex-ja mr-4'>
            <div className='fs12 fw500 w-25'>Status:</div>
            <div className='w-75'>
                <select className={SelectBox} value={status} onChange={e => setStatus(e.target.value)} disabled={!isModerating || isExpired}>
                    <option value={status} disabled hidden>{status.trim() ? status : "Select status"}</option>
                    {statusOptions.map((item, index) => (
                        <option key={index} value={item}>{item}</option>
                    ))}
                </select>
            </div>
        </div>
    );
}
