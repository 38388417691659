import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "../../../../../css/CompaniesDetail.css";
import InvestorInvestbook from "./InvestorInvestbook";
import InvestorTrades from "./InvestorTrades";
import InvestorOrgMembers from "./InvestorOrgMembers";
import InvestorFinances from "./InvestorFinances";
import InvestorBanks from "./InvestorBanks";
import { connect } from "react-redux";
import { getEscrowAccountInfo, getisFetchingEscroeAccountInfo, getRoleObjectMap } from "../../../../App/AppReducer";
import { fetchAllRoles, fetchEscrowAccountInfo } from "../../../../App/AppActions";
import Loader from 'react-loader-spinner';
import { FEE_STRUCTURE, feesStructureObjReverse } from "../../../../../constants/investor_fees_structure";
import RepaymentUpdateEmail from "./RepaymentUpdateEmail/RepaymentUpdateEmail";
import callApi from "../../../../../util/apiCaller";
import { API_STATUS, ROLES_CONST } from "../../../../../constants/enums";
import { isEmpty } from "lodash";
import { getRolesFromFields, getUserNameFromArray } from "../../../../../util/utility";
class InvestorDetailView extends Component {

  componentDidMount(){
    let id  = this.props.match?.params.id;
    this.props.dispatch(fetchEscrowAccountInfo(id));
   }
   

  render() {
    let segment;
    if (this.props.selectedSegment === "details") {
      segment = (
        <>
          <div className='row company-detail-row2'>
            <div className='col-sm-6'>
              <div className='row'>
                <div className='col-md-6'>
                  <p className='cmp-row2-head'>Investor Name</p>
                  <input
                    disabled
                    value={
                      this.props.investorstatsDetails
                        ? this.props.investorstatsDetails.name
                        : ""
                    }
                    className='investor-field'
                    type='text'
                    id='comp-name'
                    name='comp-name'
                    placeholder='Company'></input>
                </div>
                <div className='col-md-6'>
                  <p className='cmp-row2-head'>Investor Type</p>
                  <input
                    disabled
                    value={
                      this.props.investorstatsDetails
                        ? this.props.investorstatsDetails.investor_type
                        : ""
                    }
                    className='investor-field'
                    type='text'
                    id='comp-name'
                    name='comp-name'
                    placeholder='Investor Type'></input>
                </div>
                <div className='col-md-6'>
                  <p className='cmp-row2-head'>GSTIN</p>
                  <input
                    disabled
                    value={
                      this.props.investorstatsDetails
                        ? this.props.investorstatsDetails.gstin_number
                        : ""
                    }
                    className='investor-field'
                    type='text'
                    id='comp-name'
                    name='comp-name'
                    placeholder='GSTIN'></input>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <p className='cmp-row2-head'>Pan Card</p>
                  <input
                    disabled
                    value={
                      this.props.investorstatsDetails
                        ? this.props.investorstatsDetails.pan_card
                        : ""
                    }
                    className='investor-field'
                    type='text'
                    id='comp-name'
                    name='comp-name'
                    placeholder='Pan Card'></input>
                </div>
                <div className='col-md-6'>
                  <p className='cmp-row2-head'>Organization Registration</p>
                  <input
                    disabled
                    value={
                      this.props.investorstatsDetails
                        ? this.props.investorstatsDetails.organization_registration
                        : ""
                    }
                    className='investor-field'
                    type='text'
                    id='comp-name'
                    name='comp-name'
                    placeholder='Organization Registration'></input>
                </div>
              </div>
              <div>
                <p className='cmp-row2-head'>Address</p>
                <input
                  disabled
                  value={
                    this.props.investorstatsDetails
                      ? this.props.investorstatsDetails.address
                      : ""
                  }
                  className='investor-field'
                  type='text'
                  id='comp-name'
                  name='comp-name'></input>
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='row'>
                <div className='col-md-6'>
                  <p className='cmp-row2-head'>Authorized Designation</p>
                  <input
                    disabled
                    value={
                      this.props.investorstatsDetails
                        ? this.props.investorstatsDetails.authorized_designation
                        : ""
                    }
                    className='investor-field'
                    type='text'
                    id='comp-name'
                    name='comp-name'
                    placeholder='Designation'></input>
                </div>
                <div className='col-md-6'>
                  <p className='cmp-row2-head'>Authorized Signature</p>
                  <img
                    className='cmp-logo'
                    src={
                      this.props.investorstatsDetails &&
                        this.props.investorstatsDetails.authorized_signature
                        ? this.props.investorstatsDetails.authorized_signature
                        : "/assets/company-logo.svg"
                    }
                    alt=''></img>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else if (this.props.selectedSegment === "users") {
      segment = <InvestorOrgMembers members={this.props.members} />;
    } else if (this.props.selectedSegment === "trades") {
      segment = (
        <InvestorTrades
          contracts={this.props.contracts}
          to_list_contracts={this.props.to_list_contracts}
          onChangeCheckbox={this.props.onChangeCheckbox}
        />
      );
    } else if (this.props.selectedSegment === "investbook") {
      segment = <InvestorInvestbook tradebook={this.props.tradebook} />;
    } else if (this.props.selectedSegment === "finances") {
      segment = <InvestorFinances finances={this.props.finances} />;
    } else if (this.props.selectedSegment === "banks") {
      segment = (
        <InvestorBanks
          toggleAddBank={this.props.toggleAddBank}
          banklist={this.props.banklist}
        />
      );
    }
    return (
      <section className="col p-0">
        <div id="company-detail" className="company-detail">
          <div className="company-detail-row1">
            <div className="d-flex justify-content-between align-items-center company-row1">
              <div>
                <p className="grey-text small-fonts">
                  <span>Investor &nbsp;&nbsp;</span>&nbsp;
                  {this.props.investorstatsDetails
                    ? this.props.investorstatsDetails.name
                    : ""}
                </p>
                <h3 className="company-name">
                  {this.props.investorstatsDetails
                    ? this.props.investorstatsDetails.name
                    : ""}
                </h3>
                <p className="black-text small-fonts">
                  Status: &nbsp;
                  <span>
                    {this.props.investorstatsDetails
                      ? this.props.investorstatsDetails.status
                      : ""}
                  </span>
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p className="small-fonts last-edit">
                  Last edited on:&nbsp;&nbsp;
                  <span>
                    {/* {moment(this.props.investorstatsDetails.updated_at).format('h:mm:ss, DD MMMM YYYY')} */}
                    by Admin User
                  </span>
                </p>
                <div style={{ minWidth: "50px", marginRight: "10px" }}>
                  <span style={{display:"inline-block", marginRight:"5px"}}>Funds : </span>
                  <span style={{display:"inline-block",marginRight:"5px"}}>
                    {this.props.isFetchingEscrowAccountInfo ? (
                      <Loader
                        type="ThreeDots"
                        color="black"
                        height="100"
                        width="50"
                        inline={true}
                      />
                    ) : (
                      '₹'+this.props.escrowAccountInfo?.balance
                    )}
                  </span>
                </div>
                <button
                  style={{ marginRight: "10px" }}
                  onClick={() => this.props.createZeusUser()}
                  className="btn-bg-clr medium-fonts company-big-btn"
                >
                  Create Zeus User
                </button>
                <div style={{ minWidth: "50px", marginRight: "10px" }}>
                    ESP ID: {this.props.investorstatsDetails.escrow_pay_user_id ?? "NA"}
                  </div>
                <button
                  onClick={() => this.props.toggleEditView(true)}
                  className="btn-bg-clr medium-fonts company-big-btn"
                >
                  Edit Investor
                </button>
              </div>
            </div>
            <div>
              <button
                onClick={() => this.props.toggleSegment("details")}
                className="btn-bg-clr company-small-btn"
              >
                Details
              </button>
              <button
                onClick={() => this.props.toggleSegment("users")}
                className="btn-bg-clr company-small-btn"
              >
                Users
              </button>
              {/* <button
                onClick={() => this.props.toggleSegment("trades")}
                className="btn-bg-clr company-small-btn"
              >
                Trades
              </button> */}
              {/* <button
                onClick={() => this.props.toggleSegment("details")}
                className="btn-bg-clr company-small-btn"
              >
                Repayments
              </button> */}
              {/* <button
                onClick={() => this.props.toggleSegment("investbook")}
                className="btn-bg-clr company-small-btn"
              >
                Investbook
              </button> */}
              {/* <button
                onClick={() => this.props.toggleSegment("finances")}
                className="btn-bg-clr company-small-btn"
              >
                Finances
              </button> */}
              <button
                onClick={() => this.props.toggleSegment("banks")}
                className="btn-bg-clr company-small-btn"
              >
                Accounts
              </button>
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex justify-content-left flex-wrap mt-3" style={{ width: '70%' }}>
                <div className="mr-3 mb-3">
                  <p className="price-irr-fees small-fonts">Commited Capital</p>
                  <div className="price-irr-number">
                    {this.props.investorstatsDetails
                      ? this.props.investorstatsDetails.committed_capital
                      : ""}
                  </div>
                </div>
                <div className="mr-3 mb-3">
                  <p className="price-irr-fees small-fonts">Charge Fee</p>
                  <div className="price-irr-number">
                    {this.props.investorstatsDetails
                      && this.props.investorstatsDetails.charge_fee}
                  </div>
                </div>
                <div className="mr-3 mb-3">
                  <p className="price-irr-fees small-fonts">Fees Structure</p>
                  <div className="price-irr-number">
                    {this.props.investorstatsDetails
                      && this.props.investorstatsDetails.fee_type ? feesStructureObjReverse[this.props.investorstatsDetails.fee_type]
                      : ""}
                  </div>
                </div>
                <div className="mr-3 mb-3">
                  <p className="price-irr-fees small-fonts">{ [FEE_STRUCTURE.INVESTOR_FEE_FIXED_ROI_PAYMENTS,FEE_STRUCTURE.INVESTOR_FEE_ROI_PMT].includes(this.props.investorstatsDetails.fee_type) ? "Fixed ROI(%)" : "Fixed IRR (% if applicable)"}</p>
                  <div className="price-irr-number">
                    {this.props.investorstatsDetails
                      ? this.props.investorstatsDetails.fixed_IRR
                      : ""}
                  </div>
                </div>
                <div className="mr-3 mb-3">
                  <p className="price-irr-fees small-fonts">
                    Fees and Expenses (%)
                  </p>
                  <div className="price-irr-number">
                    {this.props.investorstatsDetails
                      ? this.props.investorstatsDetails.recur_fees
                      : ""}
                  </div>
                </div>
                <div className="mr-3 mb-3">
                  <p className="price-irr-fees small-fonts">Fee Waiver</p>
                  <div className="price-irr-number">
                    {this.props.investorstatsDetails &&
                    this.props.investorstatsDetails.recur_fees_waived
                      ? "Yes"
                      : "No"}
                  </div>
                </div>
                <div className="mr-3 mb-3">
                  <p className="price-irr-fees small-fonts">PSA Structure</p>
                  <div className="price-irr-number">
                    {this.props.investorstatsDetails
                      && this.props.investorstatsDetails.pdf_template_type ? this.props.investorstatsDetails.pdf_template_type
                      : "order_psa_pdf"}
                  </div>
                </div>
                <div className="mr-3 mb-3">
                  <p className="price-irr-fees small-fonts">Market View</p>
                  <div className="price-irr-number">
                    {this.props.investorstatsDetails
                      && this.props.investorstatsDetails.market_view ? this.props.investorstatsDetails.market_view
                      : "All"}
                  </div>
                </div>
                <div className="mr-3 mb-3">
                  <p className="price-irr-fees small-fonts">FLDG (%)</p>
                  <div className="price-irr-number">
                    {this.props.investorstatsDetails
                      && this.props.investorstatsDetails.fldg}
                  </div>
                </div>
                <div className="mr-3 mb-3">
                  <p className="price-irr-fees small-fonts">Funded FLDG (%)</p>
                  <div className="price-irr-number">
                    {this.props.investorstatsDetails
                      && this.props.investorstatsDetails.funded_fldg}
                  </div>
                </div>
                <div className="mr-3 mb-3">
                  <p className="price-irr-fees small-fonts">Deal Availability (Days)</p>
                  <div className="price-irr-number">
                    {this.props.investorstatsDetails
                      && this.props.investorstatsDetails.deal_availability}
                  </div>
                </div>
                <div className="mr-3 mb-3">
                  <p className="price-irr-fees small-fonts">Counter Signing Authority</p>
                  <div className="price-irr-number">
                    {this.props.counterSignAuthority
                      && getUserNameFromArray(this.props.counterSignAuthority, this.props.investors)}
                  </div>
                </div>
                <div className="mr-3 mb-3">
                  <p className="price-irr-fees small-fonts">Repayment Day (Day of Month)</p>
                  <div className="price-irr-number">
                    {this.props.investorstatsDetails?.repayment_date}
                  </div>
                </div>
                <div className="mr-3 mb-3">
                  <p className="price-irr-fees small-fonts">Calendar Days</p>
                  <div className="price-irr-number">
                    {this.props.investorstatsDetails
                      && this.props.investorstatsDetails.calendar_days}
                  </div>
                </div>
                <div className="mr-3 mb-3">
                  <p className="price-irr-fees small-fonts">Prepayment Charge</p>
                  <div className="price-irr-number">
                    {this.props.investorstatsDetails
                      && (this.props.investorstatsDetails.preparationCharge ?? 0)}
                  </div>
                </div>
                <div className="mr-3 mb-3">
                  <p className="price-irr-fees small-fonts">Penal Charge</p>
                  <div className="price-irr-number">
                    {this.props.investorstatsDetails
                      && (this.props.investorstatsDetails.penalCharge ?? 0)}
                  </div>
                </div>
              </div>
              <div className="mr-3 mb-3">
                <p className="price-irr-fees small-fonts">Auto-Withdrawal</p>
                <div className="price-irr-number">
                  {this.props.investorstatsDetails &&
                  this.props.investorstatsDetails.is_aw_on
                    ? "Yes"
                    : "No"}
                </div>
              </div>
              <RepaymentUpdateEmail investorOrgId={this.props.investorstatsDetails._id} />
            </div>
          </div>
          {segment}
        </div>
      </section>
    );
  }
}
function mapStateToProps(state, props) {
  return {
    escrowAccountInfo: getEscrowAccountInfo(state),
    isFetchingEscrowAccountInfo: getisFetchingEscroeAccountInfo(state),
    roleObjectMap : getRoleObjectMap(state)
  };
}
export default connect(mapStateToProps)(withRouter(InvestorDetailView));
