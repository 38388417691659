import moment from "moment";
import React from "react";
import { SHOW_NOTIFICATION } from "../../../../../constants/enums";
import { downloadFile, downloadFileFromArrayBuffer, extractFilePath, segregateDocLink, showNotification } from "../../../../../util/utility";
import { DELAY_CHARGES, ID_CONSTANT, ORG_TYPE_COMPANY, ORG_TYPE_INVESTEE, ORG_TYPE_INVESTOR } from "../../CompaniesViewPage/components/CompaniesDetailPage/components/DocumentVault/consts/consts";

function InvoicesTableRow(props) {

  const handleMarkAsPaidClick = (invoice_id) => {
    props.updateInvoiceStats(invoice_id);
  }
  
 const downloadDocument = (docLink, name, invoiceNumber, month) => {
   if (!docLink || docLink.length <= 0) {
     showNotification(SHOW_NOTIFICATION.ERROR, "Invoice not found");
   }
   const segregatedLink = segregateDocLink(docLink);
   let modifiedInvoiceNumber = invoiceNumber.replace(/\//g, "_");
   const ext = ".pdf";
   let fileName = name + "_" + month + "_" + modifiedInvoiceNumber + ext;
   fileName = fileName.replace(/[-\s]/g, "_");
   try {
     if (segregatedLink.type === ID_CONSTANT) {
       downloadFile({ docId: segregatedLink.link, fileName });
     } else {
       fileName = extractFilePath(docLink);
       const getBuffer = (data) => {
         return data.file.Body.data;
       };
       const body = { url: fileName };
       downloadFileFromArrayBuffer(
         "deals/admin/download-from-s3",
         "post",
         body,
         null,
         fileName,
         { type: "application/pdf" },
         getBuffer
       );
     }
   } catch (error) {
     console.error("Error occurred while downloading file:", error);
     showNotification(SHOW_NOTIFICATION.ERROR, "Failed to download file");
   }
 };

 const getType = (orgType) => {
   if (orgType === ORG_TYPE_INVESTEE) {
     return ORG_TYPE_COMPANY;
   } else if (orgType === DELAY_CHARGES) {
     return orgType;
   } else return ORG_TYPE_INVESTOR;
 };


  const renderer = (
    <>
      <td style={{ width: "15%", maxWidth: '100px', fontWeight: "600", className: "text-truncate" }}>
        <span data-bs-toggle="tooltip" data-bs-html="true">
        {props.item.organization_name}
            </span>
      </td>

      <td style={{ width: "7%" }}>{getType(props.item.organization_type)}</td>
      <td style={{ width: "7%" }}>
        {moment(new Date(2020,props.item.invoice_month.split("-")[0]-1),0,0).format("MMM") + " " + props.item.invoice_month.split("-")[1]}
      </td>
      <td style={{ width: "7%" }}>
      <i class='fa fa-rupee-sign'></i>  {props.item.invoice_amount_without_GST.toFixedNoRounding(2)}
      </td>
      <td style={{ width: "7%" }}>
      <i class='fa fa-rupee-sign'></i>  {(props.item.invoice_amount - props.item.invoice_amount_without_GST).toFixedNoRounding(2)}
      </td>
      <td style={{ width: "7%", fontWeight: "600" }}>
      <i class='fa fa-rupee-sign'></i> {props.item.invoice_amount.toFixedNoRounding(2)}
      </td>
      <td style={{ width: "8%" }}>
        {moment(props.item.invoice_date).format("DD MMM YYYY")}
      </td>
      <td style={{ width: "7%", color: "#047BFB" }} onClick={() =>downloadDocument( props.item.invoice_pdf,props.item.organization_name,props.item.invoice_number,props.item.invoice_month) }>
        {"#" + props.item.invoice_number}
      </td>
      <td style={{ width: "7%" }}>
        <span className={`invoices-status-${props.item.status}`}>{props.item.status}</span>
      </td>
      {props.item.status==="Paid" ? (
        <td style={{ width: "10%", fontFamily: "Graphik Light", color: "#4C4C4C", fontSize: "10px" }}>
          <i>{"Paid on "+ moment(props.item.paid_on).format("DD MMM YYYY")}</i>
        </td>
      ) : (
        <td style={{ width: "10%" }}>
          <button
            className="mark-as-paid"
            onClick={() => handleMarkAsPaidClick(props.item._id)}
          >
            Mark As Paid
          </button>
        </td>
      )}
    </>
  );
 
  return <tr>{renderer}</tr>;
}

export default InvoicesTableRow;
