import React, { useCallback, useRef } from "react";
import { Link } from "react-router-dom";

function InvestorsTableRow(props) {
  const observer = useRef();
  const lastInvestorRef = useCallback(
    (node) => {
      if (props.loader) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && props.hasMore()) props.loadMore();
      });
      if (node) observer.current.observe(node);
    },
    [props.loader, props.hasMore()]
  );

  const renderer = (
    <>
      <td className="text-truncate" style={{ maxWidth: '110px', paddingLeft: "5px", width:'10%'}}>
      <span data-bs-toggle="tooltip" data-bs-html="true" title={props.item.name}>
        <Link to={`/admin/investor/${props.item._id}/view`}>
          {props.item.name}
        </Link>
      </span>
      </td>
      <td style={{width:'9%'}}>{props.item.investor_type}</td>
      <td style={{width:'9%'}}>
        <i class='fas fa-rupee-sign'></i> {props.item.budget}
      </td>
      <td style={{width:'7%'}}>{props.item.recur_fees}%</td>
      <td className='blue-table-cel' style={{width:'7%'}}>{props.item.user_count}</td>
      <td className='blue-table-cel' style={{width:'7%'}}>{props.item.total_contract_count}</td>
      <td className='blue-table-cel' style={{width:'9%'}}>{props.item.trade_count}</td>
      <td style={{width:'9%'}}>
        <i class='fas fa-rupee-sign'></i> {props.item.next_payment}
      </td>
      <td style={{width:'11%'}}>
        <i class='fas fa-rupee-sign'></i> {props.item.remaining_payment}
      </td>
      <td style={{width:'9%'}}>
        <i class='fas fa-rupee-sign'></i> {props.item.total_investor}
      </td>
      <td style={{width:'5%'}}>
        <span className='bold-table-cel'>{props.item.status === "Active" ?
          <span style={{color:"#279C13"}}>Active</span>
          :
          <span style={{color:"#8C8C8C"}}>Inactive</span>
        }</span>
      </td>
      <td style={{width:'13%'}}>
        <button className="update-stats" onClick={() => props.updateInvestorOrgStats(props.item._id)}>
          Update Stats
        </button>
      </td>
      {/* <td className="pr-2"><img style={{width: '13px'}} src="/assets/pencil-icon.svg"
                                        alt=""></img></td> */}
    </>
  );

  if (props.dataLength === props.index + 1) {
    return <tr ref={lastInvestorRef}>{renderer}</tr>;
  } else {
    return <tr>{renderer}</tr>;
  }
}

export default InvestorsTableRow;
