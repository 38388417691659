import React, { Component } from "react";
import "../css/Filters.css";
import 'react-calendar/dist/Calendar.css';
import { getTrackBackground, Range } from "react-range";
// import { Link } from 'react-router-dom';
// import { connect } from 'react-redux';

class InputSlider extends Component {
    constructor(props) {
        super(props);
    }

    setInputMin = e => {
        const inputMin = e.target.value;
        let values = [...this.props.values];
        values[0] = inputMin;
        this.props.setInputMin(inputMin);
        this.props.setValues(values);
    }

    setInputMax = e => {
        const inputMax = e.target.value;
        let values = [...this.props.values];
        values[1] = inputMax;
        this.props.setInputMax(inputMax);
        this.props.setValues(values);
    }

    handleChange = values => {
        this.props.setValues(values);
        this.props.setInputMin(values[0]);
        this.props.setInputMax(values[1]);
    }

    handleFinalChange = () => {
        this.props.handleSliderFilter({
            minVal: this.props.min,
            maxVal: this.props.max,
            currentMin: this.props.inputMin,
            currentMax: this.props.inputMax,
            keyName: this.props.keyName
        });
    }

    render() {
        const { min, max, step, values, inputMin, inputMax } = this.props;
        return (
            <section>
                <div className={(this.props.keyName === "contract_term_value" || this.props.keyName === "term") ? "term-filter-slider border-shadow" : "filter-slider border-shadow"}>
                    <div className="filter-type">{this.props.labelText}</div>
                    <div className="d-flex justify-content-between" style={{ margin: '20px 0px' }}>
                        <input
                            className="filter-value"
                            id="min-input"
                            type="number"
                            onChange={e => this.setInputMin(e)}
                            value={inputMin}
                            disabled
                            placeholder="Minimum"/>
                        <br />
                        <input
                            className="filter-value"
                            id="max-input"
                            type="number"
                            onChange={e => this.setInputMax(e)}
                            value={inputMax}
                            disabled
                            placeholder="Maximum" />
                    </div>

                    <div className="investor-slider">
                        <Range
                            values={values}
                            step={step}
                            min={min}
                            max={max}
                            onChange={values => this.handleChange(values)}
                            onFinalChange={this.handleFinalChange}
                            renderTrack={({ props, children }) => (
                                <div
                                    onMouseDown={props.onMouseDown}
                                    onTouchStart={props.onTouchStart}
                                    style={{
                                        ...props.style,
                                        height: "30px",
                                        display: "flex",
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        // className="slider-color"
                                        ref={props.ref}
                                        style={{
                                            height: "5px",
                                            width: "100%",
                                            borderRadius: "4px",
                                            background: getTrackBackground({
                                                values: values,
                                                colors: ["#CCC", "#006DFF", "#CCC"],
                                                min: min,
                                                max: max,
                                            }),
                                            alignSelf: "center"
                                        }}
                                    >
                                        {children}
                                    </div>
                                </div>
                            )}
                            renderThumb={({ props, isDragged }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        height: "20px",
                                        width: "9px",
                                        border: '2px solid #FFFFFF',
                                        borderRadius: "4px",
                                        backgroundColor: "#29153B",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        boxShadow: "0px 2px 6px #AAA",
                                    }}
                                >
                                    <div
                                        style={{
                                            height: "16px",
                                            width: "5px",
                                            backgroundColor: isDragged ? "#CCC" : "#006DFF"
                                        }}
                                    />
                                </div>
                            )}
                        />
                    </div>
                    {this.props.termUnitSelector !== undefined && (
                        this.props.termUnitSelector
                    )}
                </div>
            </section>
        );
    }
}
export default InputSlider;
