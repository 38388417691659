import { isEmpty } from "lodash";
import React, { useState } from "react";
import { DEAL_HEADER_FILTER, SORT_COL_DIRECTION } from "../../../../../../../common/ENUM";
import styles from "../deals.module.scss";

const DealsHeader = (props) => {
  const dataHeaders = props.dataHeaders;
  const requestSort = (key) => {

    //Using this to update react states to send sorting Requests to BE accordingly.
    // 1 is for Asc and -1 for Desc.
    if(key === DEAL_HEADER_FILTER.company) {
      if (props.sortCompany != null) {
        props.setSortCompany(-1 * props.sortCompany);
      } else {
        props.setSortCompany(1);
      }
    }
    if(key === DEAL_HEADER_FILTER.investor) {
      if (props.sortInvestor != null) {
        props.setSortInvestor(-1 * props.sortInvestor);
      } else {
        props.setSortInvestor(1);
      }
    }
    if(key === DEAL_HEADER_FILTER.status) {
      if (props.sortLastActivityDate != null) {
        props.setSortLastActivityDate(-1 * props.sortLastActivityDate);
      } else {
        props.setSortLastActivityDate(1);
      }
    }

    if(key === DEAL_HEADER_FILTER.createDate) {
      if (props.sortCreateDate != null) {
        props.setSortCreateDate(-1 * props.sortCreateDate);
      } else {
        props.setSortCreateDate(1);
      }
    }
  };

  return (
    <thead>
      <tr>
        {dataHeaders &&
          dataHeaders.map((item, index) => {
            return (
              <th key={index}>
                {item.key === "investorDisplayName" ? (
                  <span className={styles.headerBtn}>
                    <input
                      type="text"
                      placeholder={item.name}
                      value={props.investorQuery}
                      onChange={(e) => props.setInvestorQuery(e.target.value)}
                      className={styles.deal_input}
                    />
                    <button
                      onClick={() => requestSort(item.key)}
                      style={{ border: "none" }}
                    >
                      &#8645;
                    </button>
                  </span>
                ) : item.key === "companyName" ? (
                  <span className={styles.headerBtn}>
                    <input
                      type="text"
                      placeholder={item.name}
                      value={props.companyQuery}
                      onChange={(e) => props.setCompanyQuery(e.target.value)}
                      className={styles.deal_input}
                    />
                    <button
                      onClick={() => requestSort(item.key)}
                      style={{ border: "none" }}
                    >
                      &#8645;
                    </button>
                  </span>
                ) : item.key === "drawnAllocatedSanction" ? (
                  <span className={styles.headerBtn}>
                   {item.name}
                  </span>
                ) : item.key === "lastActivityStatus" ? (
                  <span className={styles.headerBtn}>
                     {item.name}
                    <button
                      onClick={() => requestSort(item.key)}
                      style={{ border: "none" }}
                    >
                      &#8645;
                    </button>
                  </span>
                ): item.key === "actionStatus" ? (
                  <span className={styles.headerBtn} style={{ paddingLeft: "10px" }}>
                    <input
                      type="text"
                      placeholder={item.name}
                      value={props.actionQuery}
                      onChange={(e) => props.setActionQuery(e.target.value)}
                      style={{
                        border: "none",
                        width: "90px",
                      }}
                    />
                  </span>
                ) : item.key === "createDate" ? (
                  <span className={styles.headerBtn}>
                     {item.name}
                    <button
                      onClick={() => requestSort(item.key)}
                      style={{ border: "none" }}
                    >
                      &#8645;
                    </button>
                  </span>
                ) : (
                  item.name
                )}
              </th>
            );
          })}
        <th></th>
      </tr>
    </thead>
  );
};

export default DealsHeader;
