
export const handleValidationLogin = (user) => {
    let isValid = true;
    let message = '';
    if (!user.emailId) {
        message = "Email cannot be empty"
        isValid = false;
    } else if (!user.emailId.match(/^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,20})+$/)) {
        message = "Email not valid"
        isValid = false;
    }
    else if(!user.otp){
        message = "OTP cannot be empty"
        isValid = false;
    }
    return {
        isValid,
        message
    }
};

export const  handleValidationSignup = (user) => {
    let isValid = true;
    let message = '';
    if (!user.contact_name  || !user.contact_name.trim().length) {
        message = "Full Name cannot be empty"
        isValid = false;
    } 
    else if (user.contact_name.length < 3) {
        message = "Full Name too short"
        isValid = false;
    } else if (user.contact_name.length > 30) {
        message = "Full Name too long"
        isValid = false;
    }  else if (!user.company_name || !user.company_name.trim().length) {
        message = "Company Name cannot be empty"
        isValid = false;
    } 
    else if (user.company_name.length < 3) {
        message = "Company Name too short"
        isValid = false;
    } else if (user.company_name.length > 30) {
        message = "Company Name too long"
        isValid = false;
    }
     else if (!user.phone_number) {
        message = "Contact No cannot be empty"
        isValid = false;
    } else if (user.phone_number.length !== 10) {
        message = "Contact No should be 10 digits number"
        isValid = false;
    } else if (!user.phone_number.match(/^[0-9]+$/)) {
        message = "Contact No can only contain digits";
        isValid = false;
    } else if (!user.contact_email) {
        message = "Email cannot be empty"
        isValid = false;
    } else if (!user.contact_email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/)) {
        message = "Email not valid"
        isValid = false;
    } else if (!user.password) {
        message = "Password cannot be empty"
        isValid = false;
    } 
    else if (user.password.length < 8) {
        message = "Password cannot be less than 8 digits long"
        isValid = false;
    }
    return {
        isValid,
        message
    }
};

export const handleInvestorValidationSignup = (user) => {
    let isValid = true;
    let message = '';
    if (!user.contact_name  || !user.contact_name.trim().length) {
        message = "Name cannot be empty"
        isValid = false;
    }  else if (user.contact_name.length > 30) {
        message = "Name too long"
        isValid = false;
    } else if (!user.phone_number) {
        message = "Phone no cannot be empty"
        isValid = false;
    } else if (user.phone_number.length !== 10) {
        message = "Phone no is not valid"
        isValid = false;
    } else if (!user.phone_number.match(/^[0-9]+$/)) {
        message = "Phone no can only contain digits";
        isValid = false;
    } else if (!user.contact_email) {
        message = "Email cannot be empty"
        isValid = false;
    } else if (!user.contact_email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/)) {
        message = "Email not valid"
        isValid = false;
    }else if (!user.password) {
        message = "Password cannot be empty"
        isValid = false;
    } else if (user.password.length < 8) {
        message = "Password cannot be less than 8 chars long"
        isValid = false;
    } else if (!user.investor_type  || !user.investor_type.trim().length) {
        message = "Investor type cannot be empty"
        isValid = false;
    } else if (!user.company_name  || !user.company_name.trim().length) {
        message = "Company Name cannot be empty"
        isValid = false;
    }
    return {
        isValid,
        message
    }
};
