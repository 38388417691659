import React, { useEffect } from 'react';
// import {Link} from 'react-router-dom';
import FilterHeaderComponent from '../../../../../components/FilterHeaderComponent/FilterHeaderComponent';

function NachCollectionsTableHeader({ executionDateOrder, setExecutionDateOrder, ...props }) {
    return (
        <thead className="team-head nachcollection-table">
            <tr>
                <th style={{"width":"30%"}}>Company Name</th>
                <th>Mandate ID</th>
                <th>Max Value</th>
                <th>Payment Amount</th>
                <th>Execution Date
                    {executionDateOrder === "desc" ?
                        <img
                            alt=''
                            src='/assets/down-arrow.svg'
                            style={{ width: '13px', marginLeft: '5px' }}
                            onClick={() => setExecutionDateOrder("asc")}
                        />
                        : <img
                            alt=""
                            src="/assets/blue-up-arrow.svg"
                            style={{ width: '13px', marginLeft: '5px' }}
                            onClick={() => setExecutionDateOrder("desc")}
                        />
                    }
                </th>
                <th>Status</th>
                <th></th>
            </tr>
        </thead >
    )
}

export default NachCollectionsTableHeader;
