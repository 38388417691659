import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { companyHelpModal } from "../../../../App/AppActions";
// import { getCompanyHelpModalState } from "../../../../App/AppReducer";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux'
import { CSVReader, CSVDownloader } from 'react-papaparse';
import _ from "lodash";
import Select from 'react-select'
import callApi from '../../../../../util/apiCaller';
import { showNotification } from '../../../../../util/utility';

let currency_array = ["INR","USD"];
let term_array = ["monthly","quarterly","semi-annually", "annually"];
let company_status_array = ["active","delinquent","cancelled"];
let admin_status_array = ["Approved", "Not Approved", "On Hold",''];

function AddNewModal(props) {
    const  [contractsUpload,setContractsUpload] = useState([]);
    const  [selectedInvesteeOrgId,setselectedInvesteeOrgId] = useState(props.investee_organization_id);
    const [isReset,setIsReset] = useState(true);
    const [isUploadingContracts,setiSUploadingContracts] = useState(false);
    
    const [typeIdDropdown, setTypeIdDropdown] = useState([])
    // const modalOpen = useSelector(state => getCompanyHelpModalState(state))

    // const toggleModal = (val) => {
    //     if (val == false) {
    //         props.handleClearData();
    //     }
    //     dispatch(companyHelpModal(val))
    // }

    const handleFileUpload = (results) => {
        let flagValid = true;
        if (results.errors.length == 0) {
             for(let i=0; i<=results.data.length-1; i++){
              if(!results.data[i].name){
                flagValid = false;
                showNotification('error','Contract Name missing on line ' +(i+1));
                break;
              }
              if(!Number(results.data[i].recurring_payment_value)){
                flagValid = false;
                showNotification('error','Contract ' +results.data[i].name+ '- invalid recurring payment value');
                break;
              }
              if(!currency_array.includes(results.data[i].currency)) {
                flagValid = false;
                showNotification('error','Contract ' +results.data[i].name+ '- invalid currency');
                break;
              } else if (!term_array.includes(results.data[i].contract_term_unit)) {
                flagValid = false;
                showNotification('error','Contract ' +results.data[i].name+ '- invalid term unit');
                break;
              } else if(!company_status_array.includes(results.data[i].company_status)){
                flagValid = false;
                showNotification('error','Contract ' +results.data[i].name+ '- invalid status');
                break;
              } else if (!admin_status_array.includes(results.data[i].admin_status)){
                flagValid = false;
                showNotification('error','Contract ' +results.data[i].name+ '- invalid admin status');
                break;
              } else if(!Number.isFinite(Number.parseInt(results.data[i].score, 10))){
                flagValid = false;
                showNotification('error','Contract ' +results.data[i].name+ '- invalid score');
                break;
              }
             }
          if(!flagValid){
            return;
          }

          setContractsUpload(results.data);
        //   this.setState({
        //     contracts_upload: ,
        //     is_parsing_data: false,
        //   });
        } else {
            setContractsUpload([]);
        //   this.setState({
        //     contracts_upload: [],
        //     is_parsing_data: false,
        //   });
          showNotification("error", "Uploaded File is not Valid");
        }
      };
    
      const handleChangeOrg = (selected) => {
        setselectedInvesteeOrgId(selected);
      };
    
      const handleDeleteFile = () => {
        setContractsUpload([]);
        setIsReset(!isReset)
        // this.setState({
        //   contracts_upload: [],
        //   isReset: !this.state.isReset,
        // });
      };
    
      const validate = () => {
        if (!selectedInvesteeOrgId) {
          return { isValid: false, message: "Please select Investee organization" };
        } else {
          return { isValid: true };
        }
      };
      const handleUploadContracts = () => {
        setiSUploadingContracts(true);
        let investee_organization_id = props.isDisabled ? props.investee_organization_id : selectedInvesteeOrgId.value;
        let check = validate();
        if (check.isValid && !_.isEmpty(contractsUpload)) {
          callApi(
            `investeeorganization/${investee_organization_id}/contracts/bulk-upload`,
            "post",
            {
              contracts: contractsUpload,
            }
          ).then((res) => {
            setiSUploadingContracts(false);
            if (res.status === "Success") {
              showNotification("Success", "Contracts Added Successfully");
            } else {
              showNotification("Error", "Some Error Occured");
            }
          });
        } else {
          showNotification("Error", check.message);
        }
      };

    const searchOrganization = (searchText,userType) => {
        if(searchText && searchText.length >2){
             let body = {
                 "OrganizationType" : userType,
                 "OrganizationName" : searchText
             }
             callApi(`admin/user/searchOrganization`, "post", body).then((res) => {
                 if (res.status === 'Success') {
                     let dropdownArray = [];
                     if(res.data && res.data.organizations){
                         dropdownArray = res.data.organizations.map(item=>{
                             return  { label:item.name,  value : item._id };
                         })
                     }
                     setTypeIdDropdown(dropdownArray);
                 } else {
                     showNotification("error", "Some error occured");
                 }
             });
         }
     }
     const closeModal = () =>{
        handleChangeOrg({label:'',value:''})
        handleDeleteFile(false)
        document.getElementById('modalClose').click(); 
    }
    return (

        <div className="modal fade" id="addnewmodal" role="dialog">
            <div className="modal-dialog">


                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Upload Contracts</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick = {closeModal}>
                            <img src="../../../../../assets/modal-cross.svg" alt=""></img>
                        </button>
                        <button hidden id = "modalClose" type="button" className="close" data-dismiss="modal">
                           
                           </button>

                    </div>
                    <Modal.Body>
                        <div>
                           { !props.isDisabled && <Select 
                                className="mt-3"
                                value={selectedInvesteeOrgId}
                                options={typeIdDropdown}
                                onInputChange ={(e) => searchOrganization(e,'investee')}
                                onChange = {(e) => handleChangeOrg(e)}
                                isSearchable
                                placeholder = {`Select Investee Organization`}
                                disabled = {props.isDisabled}
                            />}
                            <br/>
                            <CSVDownloader
                                data={[
                                    {
                                        "Customer name or unique identifier": "Company Name",
                                        "Contract Start Date": "01/01/2021",
                                        "Contract End Date": "31/12/2024",
                                        "Recurring Payment": "200000",
                                        "Currency (INR/ USD)": "INR",
                                        "Frequency of Recurring Payment (monthly / quarterly / semi-annually / annually)": "monthly",
                                        "Status (active / delinquent / cancelled)": "active",
                                        "Admin Status (Approved / Not Approved / On Hold)": "Approved",
                                        "Score": "8"
                                    },
                                ]}
                                filename={'Contract_Upload_Admin'}
                                bom={true}
                            >
                                <p><a className=""
                                    style={{ cursor: "pointer", color: '#4c8dff', fontWeight:'300' }}>Download
                                    sample CSV format.</a></p>
                            </CSVDownloader>
                        </div>
                        <div className="custom-csv">
                            <CSVReader
                                isReset={isReset}
                                // onDrop={this.handleFileUpload}
                                config={{
                                    header: true,
                                    skipEmptyLines: true,
                                    transformHeader: h => {
                                        if (h === "Customer name or unique identifier") {
                                            return "name"
                                        } else if (h === "Contract Start Date") {
                                            return "contract_start_date"
                                        } else if (h === "Contract End Date") {
                                            return "contract_end_date"
                                        } else if (h === "Recurring Payment") {
                                            return "recurring_payment_value"
                                        } else if (h === "Currency (INR/ USD)") {
                                            return "currency"
                                        } else if (h === "Frequency of Recurring Payment (monthly / quarterly / semi-annually / annually)") {
                                            return "contract_term_unit"
                                        } else if (h === "Status (active / delinquent / cancelled)") {
                                            return "company_status"
                                        } else if (h ===  "Admin Status (Approved / Not Approved / On Hold)") {
                                            return "admin_status"
                                        } else if (h === "Score") {
                                            return "score"
                                        }
                                        else {
                                            return h
                                        }
                                    },
                                    complete: (results) => {
                                        handleFileUpload(results)
                                    }
                                }}
                                noProgressBar
                                style={{
                                    dropArea: {
                                        borderColor: '#979797',
                                    },
                                    removeButton: {
                                        color: '#4c8dff',
                                    },
                                }}
                            >
                                <span style={{ fontSize: '17px', color: '#a7a7a7', fontWeight: '300' }}>Click or drag here to upload.</span>

                            </CSVReader>
                            {_.isEmpty(contractsUpload) ?
                                <div></div>
                                :
                                <div>
                                    <div><img className="csv-icon" alt="" src='/assets/des.svg' /></div>
                                    {isUploadingContracts ?
                                        <button type="button"
                                            className="upload">Uploading...
                                        </button>
                                        :

                                        <div className="row">
                                            <div className="col-6">
                                                <button type="button"
                                                    className="upload upload-del"
                                                    onClick={() => handleDeleteFile()}>Delete
                                                </button>
                                            </div>
                                            <div className="col-6">
                                                <button type="button"
                                                    className="upload  upload-up"
                                                    onClick={() => handleUploadContracts()}>Upload
                                                </button>
                                            </div>
                                        </div>
                                    }

                                </div>
                            }
                        </div>
                    </Modal.Body>
                </div>

            </div>
        </div>
    )
}

export default AddNewModal;
