import React from // Component
"react";
// import {Link} from 'react-router-dom';
import FilterHeaderComponent from "../../../../../components/FilterHeaderComponent/FilterHeaderComponent";

function InvestorsTableHeader(props) {
  return (
    <thead className='team-head investor-table'>
      <tr>
        <th scope='col' style={{ paddingLeft: "5px"}}>
          Investor
          <img
            style={{ width: "13px", marginLeft: "5px" }}
            src='/assets/down-arrow.svg'
            alt=''></img>
        </th>

        <FilterHeaderComponent
          isSortAllowed={true}
          sorting_order={"asc"}
          isFilterAllowed={true}
          headerText={"Type"}
          labelText={"Filter Type"}
          filter_type={"selector"}
          inputSelectorHandler={props.inputSelectorHandler}
          elementsArray={props.selectorObjType.elementsArray}
          keyName={props.selectorObjType.keyName}
        />
        <FilterHeaderComponent
          isFilterAllowed={true}
          headerText={"Funds"}
          labelText={"Filter Budget"}
          filter_type={"range_search"}
          handleSliderFilter={props.handleSliderFilter}
          minVal={props.sliderObjBudget.minVal}
          maxVal={props.sliderObjBudget.maxVal}
          currentMin={props.sliderObjBudget.currentMin}
          currentMax={props.sliderObjBudget.currentMax}
          minValueBetween={props.sliderObjBudget.minValueBetween}
          keyName={props.sliderObjBudget.keyName}
        />
        <FilterHeaderComponent
          isFilterAllowed={true}
          headerText={"Fees"}
          labelText={"Filter Fees"}
          filter_type={"range_search"}
          handleSliderFilter={props.handleSliderFilter}
          minVal={props.sliderObjFees.minVal}
          maxVal={props.sliderObjFees.maxVal}
          currentMin={props.sliderObjFees.currentMin}
          currentMax={props.sliderObjFees.currentMax}
          minValueBetween={props.sliderObjFees.minValueBetween}
          keyName={props.sliderObjFees.keyName}
        />

        <th scope='col'># Users</th>
        <th scope='col'># Contracts</th>
        <th scope='col'># Trades</th>
        <FilterHeaderComponent
          isFilterAllowed={true}
          headerText={"Next Payment"}
          labelText={"Filter Next Payment"}
          filter_type={"range_search"}
          handleSliderFilter={props.handleSliderFilter}
          minVal={props.sliderObjNextPayment.minVal}
          maxVal={props.sliderObjNextPayment.maxVal}
          currentMin={props.sliderObjNextPayment.currentMin}
          currentMax={props.sliderObjNextPayment.currentMax}
          minValueBetween={props.sliderObjNextPayment.minValueBetween}
          keyName={props.sliderObjNextPayment.keyName}
        />
        <FilterHeaderComponent
          isFilterAllowed={true}
          headerText={"Remaining Payments"}
          labelText={"Filter Remaining Payments"}
          filter_type={"range_search"}
          handleSliderFilter={props.handleSliderFilter}
          minVal={props.sliderObjRemainingPayment.minVal}
          maxVal={props.sliderObjRemainingPayment.maxVal}
          currentMin={props.sliderObjRemainingPayment.currentMin}
          currentMax={props.sliderObjRemainingPayment.currentMax}
          minValueBetween={props.sliderObjRemainingPayment.minValueBetween}
          keyName={props.sliderObjRemainingPayment.keyName}
        />
        <FilterHeaderComponent
          isFilterAllowed={true}
          headerText={"Total Investments"}
          labelText={"Filter Total Investments"}
          filter_type={"range_search"}
          handleSliderFilter={props.handleSliderFilter}
          minVal={props.sliderObjTotalInvestment.minVal}
          maxVal={props.sliderObjTotalInvestment.maxVal}
          currentMin={props.sliderObjTotalInvestment.currentMin}
          currentMax={props.sliderObjTotalInvestment.currentMax}
          minValueBetween={props.sliderObjTotalInvestment.minValueBetween}
          keyName={props.sliderObjTotalInvestment.keyName}
        />
        <FilterHeaderComponent
          isSortAllowed={true}
          sorting_order={"asc"}
          isFilterAllowed={true}
          headerText={"Status"}
          labelText={"Filter Status"}
          filter_type={"selector"}
          inputSelectorHandler={props.inputSelectorHandler}
          elementsArray={props.selectorObjStatus.elementsArray}
          keyName={props.selectorObjStatus.keyName}
        />
        <th></th>
      </tr>
    </thead>
  );
}

export default InvestorsTableHeader;
