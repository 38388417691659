import moment from "moment";
import styles from "../Recovery.module.scss";
import { Player } from "@lottiefiles/react-lottie-player";

const ACTIONS = [
  {
    label: "Log Call",
    value: "Call Logged",
  },
  {
    label: "Log Email",
    value: "Email Logged",
  },
  {
    label: "Log Text",
    value: "Text Logged",
  },
  {
    label: "Log Recovery Action Visit",
    value: "Recovery Visit Logged",
  },
  {
    label: "Log Lender Action",
    value: "Lender Action Logged",
  },
  {
    label: "Legal Action",
    value: "Legal Action",
  },
  {
    label: "Log Payment",
    value: "Payment Logged",
  },
];

type EditCommentDrawerProps = {
  item: any;
  setIsEditing: Function
  createNotes: any
  setCreateNotes: Function
  handleCreateNotesSave: Function
  saveLoading: boolean
};

const EditCommentDrawer = ({
  item,
  setIsEditing,
  createNotes,
  setCreateNotes,
  handleCreateNotesSave,
  saveLoading
}:EditCommentDrawerProps) => {
  const {
    editCommentWrapper,
    header,
    ctaWrapper,
    cancle,
    save,
    filtersWrapper,
    filterWrap,
    dateInput,
    selectaction,
    editTextArea,
  } = styles;

  const handleChange=(e:any)=>{
    const {name, value} = e.target;
    setCreateNotes((prev:any)=>{
      return {
        ...prev,
        [name]:value
      }
    })
  }

  return (
    <section className={editCommentWrapper}>
      <div className={header}>
        <p>Added Notes</p>
        <div className={ctaWrapper}>
          <button
            className={cancle}
            onClick={() => {
              setIsEditing(false);
              setCreateNotes({
                action: "",
                ptpdate: "",
                description: "",
              });
            }}
          >
            Cancel
          </button>
          <button className={save} onClick={()=>{
            handleCreateNotesSave(item)
          }}>Save</button>
        </div>
      </div>

      <div className={filtersWrapper}>
        <div className={filterWrap}>
          <label>Select Action</label>
          <select
            className={selectaction}
            value={createNotes.action}
            name="action"
            onChange={handleChange}
          >
            <option value={""} disabled>Select Action</option>
            {ACTIONS.map((item) => {
              return <option value={item.value}>{item.label}</option>;
            })}
          </select>
        </div>

        <div className={filterWrap}>
          <label>Promise to Pay Date</label>
          <input
            type={"date"}
            name={"ptpdate"}
            className={dateInput}
            value={moment(createNotes.ptpdate).format("YYYY-MM-DD")}
            min={moment().add(0, "day").format("YYYY-MM-DD")}
            onChange={handleChange}
          />
        </div>
      </div>
      {
        saveLoading ? (
          <Player
            autoplay
            loop
            src='https://fl-fe-assets.s3.ap-south-1.amazonaws.com/json/tableLoader.json'
            style={{ height: '500px', width: '500px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}
          />
        ) : (
          <textarea
            className={editTextArea}
            value={createNotes.description}
            name={"description"}
            onChange={handleChange}
          />
        )
      }
    </section>
  );
};

export default EditCommentDrawer;
