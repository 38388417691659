import React, { useCallback, useRef } from "react";
import {
  getValueOr_,
  getValueWithRsOr_,
} from "../../../../../../../../../util/utility";
import styles from "../PgCollections/PgCollections.module.scss";

function PgCollectionsTableRow({
  index,
  selectedPgCollectionsArr,
  handleCheckBoxClick,
  item,
  hasMore,
  loadMore,
  loader,
  dataLength,
  handleChange,
}) {
  const observer = useRef();
  const lastCollectionRef = useCallback(
    (node) => {
      if (loader) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) loadMore();
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loader, hasMore]
  );

  const {
    collectionId,
    collectionDate,
    customerEmail,
    pgName,
    amountCollected,
    recurCollection,
    collectionAmount,
    status,
    enableCollection,
  } = item;

  const tableData = (
    <>
      <td>
        {enableCollection && (
          <input
            type="checkbox"
            checked={selectedPgCollectionsArr.some((id) => id === collectionId)}
            onChange={(e) =>
              handleCheckBoxClick(e.target.checked, collectionId, index)
            }
          />
        )}
      </td>
      <td>{getValueOr_(collectionDate)}</td>
      <td>{getValueOr_(customerEmail)}</td>
      <td>
        <span className={styles[pgName]}>{getValueOr_(pgName)}</span>
      </td>
      <td>{getValueWithRsOr_(collectionAmount.toFixed(2))}</td>
      <td>
        {enableCollection
          ? getValueWithRsOr_((collectionAmount - amountCollected).toFixed(2))
          : 0}
      </td>
      <td>
        {enableCollection ? (
          <input
            type="number"
            value={item?.amountToCollect}
            placeholder="Amount to Collect"
            onChange={(e) =>
              handleChange(e.target.value, "amountToCollect", index, true)
            }
            className={styles.AmountToCollectInput}
            required
          />
        ) : (
          getValueWithRsOr_(recurCollection)
        )}
      </td>
      <td>
        <span className={styles[status]}>{getValueOr_(status)}</span>
      </td>
    </>
  );

  if (dataLength === index + 1) {
    return (
      <tr key={index} ref={lastCollectionRef}>
        {tableData}
      </tr>
    );
  } else {
    return <tr key={index}>{tableData}</tr>;
  }
}

export default PgCollectionsTableRow;
