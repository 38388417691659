import React, { Component } from "react";
// import {Link} from 'react-router-dom';
import InvestorsTableHeader from "../InvestorsPage/components/InvestorsTableHeader";
import InvestorsTableRow from "../InvestorsPage/components/InvestorsTableRow";
import AdminHead from "../../components/AdminHead.js";
import callApi from "../../../../util/apiCaller";
import { showNotification } from "../../../../util/utility";
import _ from "lodash";
import { mergeByProperty } from "../../../../util/calculateTermUtil";

class InvestorsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_fetching_investors: false,
      investors: [],
      pageNum: 1,
      pageSize: 200,
      totalCount: 0,
      qtext: "",
      sliderObjBudget: {
        minVal: 0.85,
        maxVal: 0.95,
        currentMax: 0.95,
        currentMin: 0.85,
        keyName: "contract_price",
        minValueBetween: 0.1,
      },
      sliderObjRemainingPayment: {
        minVal: 0,
        maxVal: 300000,
        currentMax: 300000,
        currentMin: 0,
        keyName: "payout",
        minValueBetween: 10000,
      },
      sliderObjTotalInvestment: {
        minVal: 0,
        maxVal: 300000,
        currentMax: 300000,
        currentMin: 0,
        keyName: "recurring_payment_value",
        minValueBetween: 10000,
      },
      sliderObjFees: {
        minVal: 0,
        maxVal: 12,
        currentMax: 12,
        currentMin: 0,
        keyName: "disbursed",
        minValueBetween: 1,
      },
      sliderObjNextPayment: {
        minVal: 0,
        maxVal: 12,
        currentMax: 12,
        currentMin: 0,
        keyName: "disbursed",
        minValueBetween: 1,
      },
      selectorObjStatus: {
        keyName: "status",
        elementsArray: [
          {
            label: "Active",
            selected: true,
          },
          {
            label: "Inactive",
            selected: true,
          },
        ],
      },
      selectorObjType: {
        keyName: "type",
        elementsArray: [
          {
            label: "Individual",
            selected: true,
          },
          {
            label: "Institutional",
            selected: true,
          },
        ],
      },
    };
    this.debounceSearch = _.debounce(() => this.fetchInvestor(true), 400);
  }

  keyStateObjNameDict = {
    contract_term_value: "sliderObjArr",
    recurring_payment_value: "sliderObjPrice",
    disbursed: "sliderObjTradeLimit",
    contract_price: "sliderObjPrice",
    payout: "sliderObjTradedArr",
    fees: "sliderObjFees",
    contract_id: "searchObjContractID",
    contract_trade_id: "searchObjTradeID",
    status: "selectorObjStatus",
    type: "selectorObjType",
  };

  componentDidMount() {
    this.fetchInvestor();
  }

  hasMore = () => {
    return this.state.totalCount + 1 > this.state.pageNum * this.state.pageSize;
  };

  loadMore = () => {
    this.setState({ pageNum: this.state.pageNum + 1 });
    this.fetchInvestor();
  };

  fetchInvestor = (isReseting) => {
    this.setState({ is_fetching_investors: true });
    let filters = this.getFilters();
    if (isReseting) {
      filters.pageNum = 1;
    }
    callApi(`admin/investor/list`, "post", filters).then((res) => {
      if (res.status === "Success") {
        let investors = res.data.investors;
        if (!isReseting) {
          investors = mergeByProperty(this.state.investors, res.data.investors, "_id");
        }
        this.setState({
          investors,
          totalCount: res.data.count,
        });
      } else {
        showNotification("error", "Some error occured");
      }
      this.setState({ is_fetching_investors: false });
    });
  };

  handleTextSearch = (val) => {
    this.setState(
      {
        qtext: val,
      },
      () => {
        this.debounceSearch();
      }
    );
  };

  getFilters = () => {
    let filters = {};
    filters.pageNum = this.state.pageNum;
    filters.pageSize = this.state.pageSize;
    if (this.state.qtext) {
      filters.isSearching = true;
      filters.qtext = this.state.qtext;
    }
    filters.isFiltering = true;
    filters.isSearching = true;

    filters.sort_by = "";
    filters[this.state.sliderObjBudget.keyName] = {
      min: this.state.sliderObjBudget.currentMin,
      max: this.state.sliderObjBudget.currentMax,
    };
    filters[this.state.sliderObjRemainingPayment.keyName] = {
      min: this.state.sliderObjRemainingPayment.currentMin,
      max: this.state.sliderObjRemainingPayment.currentMax,
    };
    filters[this.state.sliderObjTotalInvestment.keyName] = {
      min: this.state.sliderObjTotalInvestment.currentMin,
      max: this.state.sliderObjTotalInvestment.currentMax,
    };
    filters[this.state.sliderObjFees.keyName] = {
      min: this.state.sliderObjFees.currentMin,
      max: this.state.sliderObjFees.currentMax,
    };
    filters[this.state.sliderObjNextPayment.keyName] = {
      min: this.state.sliderObjNextPayment.currentMin,
      max: this.state.sliderObjNextPayment.currentMax,
    };
    filters[this.state.selectorObjStatus.keyName] = {
      in: this.state.selectorObjStatus.elementsArray.map((item) => {
        if (item.selected) {
          return item.label;
        }
      }),
    };
    filters[this.state.selectorObjType.keyName] = {
      in: this.state.selectorObjType.elementsArray.map((item) => {
        if (item.selected) {
          return item.label;
        }
      }),
    };
    return filters;
  };
  handleApplyFilters = () => {
    this.fetchInvestor();
  };

  handleSliderFilter = (obj) => {
    let sliderobj = {};
    sliderobj[this.keyStateObjNameDict[obj.keyName]] = obj; // to set state of respective slider object
    this.setState(sliderobj, () => {
      this.handleApplyFilters();
    });
  };

  inputSelectorHandler = (obj) => {
    let selectorObj = {};
    selectorObj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(selectorObj, () => {
      this.handleApplyFilters();
    });
  };

  updateInvestorOrgStats = (investor_org_id) => {
    callApi(
      `scheduler/update-single-investor-organization-stats/${investor_org_id}`,
      "get"
    ).then((res) => {
      if (res.status === "Success") {
        showNotification("Success", "Stats Updated!");
      } else {
        showNotification("error", "Some error occured");
      }
    });
  };
  render() {
    return (
      <div className="main-body">
        <AdminHead />
        <section id='admin-company'>
          <div className='d-flex justify-content-between form-group has-search'>
            <span className='form-control-feedback'>
              <img
                alt=''
                src='../../../../../assets/search.png'
                style={{ height: "14px", width: "14px", marginTop: "-7px" }}
              />
            </span>
            <input
              value={this.state.qtext}
              onChange={(e) => this.handleTextSearch(e.target.value)}
              type='text'
              className='form-control'
              placeholder='Search...'
              style={{ margin: "auto 0px" }}></input>
            {/*<button className="admin-company-btn">*/}
            {/*<img src="../../../../../assets/plus.ico" alt=""></img>*/}
            {/*Add New</button>*/}
          </div>
          <div className='table-head'>
            <table className='table team'>
              <InvestorsTableHeader
                sliderObjBudget={this.state.sliderObjBudget}
                sliderObjRemainingPayment={this.state.sliderObjRemainingPayment}
                sliderObjTotalInvestment={this.state.sliderObjTotalInvestment}
                sliderObjFees={this.state.sliderObjFees}
                sliderObjNextPayment={this.state.sliderObjNextPayment}
                handleSliderFilter={this.handleSliderFilter}
                inputSelectorHandler={this.inputSelectorHandler}
                selectorObjStatus={this.state.selectorObjStatus}
                selectorObjType={this.state.selectorObjType}
              />
              <tbody style={{ display: 'none' }}></tbody>
            </table>
          </div>
          <div className='tableFixHead table-body'>
            <table
                className='table team'
            >
              <thead></thead>
              <tbody className='team-body'>
                {this.state.investors &&
                  this.state.investors.map((item, index) => (
                    <InvestorsTableRow
                      key={item._id}
                      item={item}
                      loader={this.state.is_fetching_investors}
                      hasMore={this.hasMore}
                      loadMore={this.loadMore}
                      dataLength={this.state.investors.length}
                      index={index}
                      updateInvestorOrgStats={this.updateInvestorOrgStats}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    );
  }
}

export default InvestorsPage;
