import './ActionModals.scss'
import {Modal} from "react-bootstrap";
import React, {useEffect} from "react";
import {numberOnly} from "../../../../../util/utility.js";

interface ApprovalModalProps{
    show:boolean
    onClose:()=>void
    approveClaim:(approvedLimit:string)=>void
    amount:string
}
const ApprovalModal = (props:ApprovalModalProps)=>{
    const {show,onClose,approveClaim} = props;
    const [approvalAmount,setApprovalAmount] = React.useState<string>(props.amount??'');
    const [disabled,setDisabled] = React.useState<boolean>(false);
    useEffect(() => {
        if(approvalAmount===''){
            setDisabled(true)
        }else{
            setDisabled(false)
        }
    }, [approvalAmount]);
    const handleClick= async ()=>{
        setDisabled(true)
        await approveClaim(approvalAmount)
        setDisabled(false)
    }
    return(
        <Modal
            show={show}
            onHide={onClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>Verify TDS Claim</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <input
                        className="approval-input"
                        placeholder="Enter Verify Amount"
                        style={{color:'black'}}
                        value={approvalAmount}
                        onChange={(e)=>setApprovalAmount(numberOnly(e.target.value))}
                    />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className={'RejectionCancelBtn'}>Cancel</button>
                <button disabled={disabled} onClick={()=>handleClick()} className={'ApproveBtn'}>Verify Claim</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ApprovalModal;