import _ from 'lodash';
import React, {
    // Component
    useState,
    useEffect
} from 'react';
// import { Link } from 'react-router-dom';
// import { companyHelpModal } from "../../../../App/AppActions";
// import { getCompanyHelpModalState } from "../../../../App/AppReducer";
import { Modal } from "react-bootstrap";
import { connect, useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { REGEX_DOMAIN_EXT } from '../../../../../common/ENUM';
import callApi from '../../../../../util/apiCaller';
import { showNotification } from '../../../../../util/utility';
import { getUserDetails } from '../../../../App/AppReducer';
import ContractsPage from '../../ContractsPage/ContractsPage';

function AddNewModal(props) {
    const dispatch = useDispatch();
    const [userType, setUserType] = useState('unclaimedCompany');
    const [selectedTypeId, setSelectedTypeId] = useState({label: null, value: null});
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [domain, setDomain] = useState('')
    const [orgName, setOrgName] = useState('')
    const [partnerReferral, setPartnerReferral] = useState('')

    const [typeIdDropdown, setTypeIdDropdown] = useState([])

    const addUser = () => {
        let result = validateInput();
        if(result.isValid){
            let body ={};
            let apiPath = ""
            if(userType==='investee'){
                body ={
                    "club":userType,
                    "investee_organization_id":selectedTypeId.value,
                    "user_id":props.user? props.user._id:'',
                    "user":{
                        "name":name,
                        "email": email
                    }

                }
                apiPath = "admin/user/addUser"
            }
            else if(userType === 'investor' ){
                body ={
                    "club":userType,
                    "investor_organization_id":selectedTypeId.value,
                    "user_id":props.user? props.user._id:'',
                    "user":{
                        "name":name,
                        "email": email
                    }
                }
                apiPath = "admin/user/addUser"

            }
            else if(userType === 'admin'){
                body ={
                    "club":userType,
                    "user_id":props.user? props.user._id:'',
                    "admin":{
                        "name":name,
                        "email": email
                    }
                }
                apiPath = "admin/user/addUser"
            }else if(userType){  
                body = {
                    domain ,
                    orgName,
                    partnerReferral,
                    requestId : Date.now()
                }
                apiPath = "auth/addUnclaimedCompany"
            }
        
            callApi(`${apiPath}`, "post", body).then((res) => {
                if (res && res.status === 'Success') {
                    new ContractsPage().fetchContracts();
                    showNotification("success", capitalizeFirstLetter(userType)+" User added Successfully");
                    closeModal();
                } else {
                    showNotification("error", res.data.message);
                }

            });
        }
        else{
            showNotification("error", result.message);
        }


    }

    const validateInput = () => {
        let isValid = true;
        let message = '';
        if (!userType) {
            message = "Please select be user Type"
            isValid = false;
        } else if(userType === 'unclaimedCompany'){
            if (!domain || !domain.match(REGEX_DOMAIN_EXT))  {
                message = "Please enter a valid domain"
                isValid = false;
            } else if(!orgName){
                message = "Please enter a organization name"
                isValid = false;
            }
        }else if (!selectedTypeId) {
            message = "Please select the Organization"
            isValid = false;
        }
        else if (!name) {
            message = "Name cannot be empty"
            isValid = false;
        }
         else if (name.length > 30) {
            message = "Name too long"
            isValid = false;
        } else if (!email) {
            message = "Email cannot be empty"
            isValid = false;
        } else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/)) {
            message = "Email not valid"
            isValid = false;
        }
        return {
            isValid,
            message
        }
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    const searchOrganization = (searchText) => {
       if(searchText && searchText.length >2){
            let body = {
                "OrganizationType" : userType,
                "OrganizationName" : searchText
            }
            callApi(`admin/user/searchOrganization`, "post", body).then((res) => {
                if (res.status === 'Success') {
                    let dropdownArray = [];
                    if(res.data && res.data.organizations){
                        dropdownArray = res.data.organizations.map(item=>{
                            return  { label:item.name,  value : item._id };
                        })
                    }
                    setTypeIdDropdown(dropdownArray);
                } else {
                    showNotification("error", "Some error occured");
                }
            });
        }
    }
    const handleChangeOrg = selected =>{
        setSelectedTypeId(selected)
    }

    const closeModal = () =>{
        setSelectedTypeId({label: null, value: null});
        setName('');
        setEmail('');
        document.getElementById('modalClose').click();
    }
    return (

        <div className="modal fade" id="addnewmodal" role="dialog">
            <div className="modal-dialog">


                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Add New User</h4>
                        <button  type="button" className="close"  onClick = {closeModal} >
                            <img src="../../../../../assets/modal-cross.svg" alt=""></img>

                        </button>
                        <button hidden id = "modalClose" type="button" className="close" data-dismiss="modal">

                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <select
                                value={userType}
                                onChange={(e) => setUserType(e.target.value)}
                                className="admin-form-field mt-3"
                                placeholder="Select user type"
                                style={{color: 'black'}}>
                                <option value="unclaimedCompany">Unclaimed Company</option>
                            </select>
                            { ! ["unclaimedCompany" ,"admin"].includes(userType)?
                                <Select
                                    className="mt-3"
                                    value={selectedTypeId}
                                    options={typeIdDropdown}
                                    onInputChange ={(e) => searchOrganization(e,userType)}
                                    onChange = {(e) => handleChangeOrg(e)}
                                    isSearchable
                                    placeholder = {`Select ${capitalizeFirstLetter(userType)} Organization`}
                                    />
                            :null}
                            {/* {userType !== 'admin' && <select
                                data-live-search={true}
                                value={selectedTypeId}
                                onChange={(e) => setSelectedTypeId(e.target.value)}
                                className="admin-form-field mt-3"
                                placeholder="Select company"
                                style={{color: 'black'}}>
                                    {typeIdDropdown.map(drop => (
                                        <option data-tokens={drop.orgId} value={drop.orgId}>{drop.orgName}</option>
                                    ))}
                            </select>} */} 
                            {userType !=="unclaimedCompany" ? 
                           <div className="admin-form-fields">
                           <input
                             value={name}
                             required
                             onChange={(e) => setName(e.target.value)}
                             className="admin-form-field mt-3"
                             style={{ color: 'black' }}
                             type="text"
                             placeholder="Enter your name"
                           />
                           <input
                             value={email}
                             required
                             onChange={(e) => setEmail(e.target.value)}
                             className="admin-form-field mt-3"
                             style={{ color: 'black' }}
                             type="email"
                             placeholder="Enter your email"
                           />
                         </div>: 
                         <div className="admin-form-fields">
                         <input
                           value={domain}
                           required
                           onChange={(e) => setDomain(e.target.value)}
                           className="admin-form-field mt-3"
                           style={{ color: 'black' }}
                           type="text"
                           placeholder="Enter domain name"
                         />
                         <input
                           value={orgName}
                           required
                           onChange={(e) => setOrgName(e.target.value)}
                           className="admin-form-field mt-3"
                           style={{ color: 'black' }}
                           type="email"
                           placeholder="Enter company name"
                         />
                         <input
                           value={partnerReferral}
                           required
                           onChange={(e) => setPartnerReferral(e.target.value)}
                           className="admin-form-field mt-3"
                           style={{ color: 'black' }}
                           type="email"
                           placeholder="Enter partner ID/Name"
                         />
                       </div>
                            }
                        </form>
                        <button
                                onClick = {() => addUser()}
                                className="admin-company-btn admin-modal-btn mt-3">
                                Submit
                            </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
       user:getUserDetails(state)
    }
}

export default connect(mapStateToProps)(AddNewModal);
