import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import './ClaimsErrorMessage.scss'


interface Theme {
    color: string;
    bgColor: string;
    icon: string;
}
const ClaimsErrorMessage = (props: { message: string | ReactJSXElement; status: string }) => {
    const { message, status } = props;
    const getColorsAndIcons = (status: string): Theme => {
        switch (status) {
            case 'Success':
                return {
                    color: '#00AA00',
                    bgColor: '#E5FCDE',
                    icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/TickMarkGreen.svg',
                };
            case 'Error':
                return {
                    color: '#D06D6D',
                    bgColor: '#FFE9E9',
                    icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/info-red.svg',
                };
            case 'Warning':
                return {
                    color: '#000000',
                    bgColor: '#FFF7E9',
                    icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/infoYellow.svg',
                };
            default:
                return {
                    color: '',
                    bgColor: '',
                    icon: '',
                };
        }
    };

    return (
        <div
            style={{ background: getColorsAndIcons(status).bgColor }}
            className={'MessageConatiner'}
        >
            <div className={'IconContainer'}>
                <img
                    className={'Icon'}
                    src={getColorsAndIcons(status).icon}
                    alt={status}
                />
            </div>
            <div className={'MessageContainer'}>
                <div style={{ color: getColorsAndIcons(status).color }}>{message}</div>
            </div>
        </div>
    );
};
export default ClaimsErrorMessage;