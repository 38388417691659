import { func } from 'joi';
import { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { updateNachAccount } from '../../../../../common/ApiHelpers';
import { SHOW_NOTIFICATION_STATUS } from '../../../../../constants/enums';
import { showNotification } from '../../../../../util/utility';
import { DECENTRO_BANK_VERIFIED_SUCCESS, NOT_VERIFIED, VERIFIED } from '../../CompaniesViewPage/components/CompaniesDetailPage/components/DocumentVault/consts/consts';
import styles from './NachAccountChangeTooltip.module.scss';

export default function NachAccountChangeTooltip({bankListData,fetchBankListData}) {

    const [checkedAccount, setCheckedAccount] = useState(null)
    const [sortedBankListData, setSortedBankListData] = useState([]);

    const {SetNachdrawAcc, sNText, subText, toolTipArrow,accountNoLabel, bankNameLabel, Status,Verified,NotVerified,VerifiedText,NotVerifiedText, bankLineItem, disableColor} = styles;

    useEffect(()=>{
        if(bankListData?.length){
            const sortedData = [...bankListData].sort((a, b) => {
                const isASuccess = a.pennyDropVerificationStatus === DECENTRO_BANK_VERIFIED_SUCCESS;
                const isBSuccess = b.pennyDropVerificationStatus === DECENTRO_BANK_VERIFIED_SUCCESS;

                if (isASuccess === isBSuccess) {
                    return 0;
                } else if (isASuccess) {
                    return -1;
                } else {
                    return 1;
                }
            });

            // Update the sortedBankListData state with the sorted data
            setSortedBankListData(sortedData);
            setCheckedAccount(bankListData?.find(obj => obj.is_nach_enabled === true)?.account_number)
        }
    }, [bankListData])

    const handleRadioChange = async (data, index) => {
        if(data?.organization_id && data?.account_number){
            const obj={
                investeeOrgId:data.organization_id,
                nachAccountNumber: data.account_number
            }
            const result = await updateNachAccount(obj);
            if(result){
                showNotification(SHOW_NOTIFICATION_STATUS.SUCCESS, result);
                fetchBankListData();
                setCheckedAccount(index);
            }
        }
      };

    return (
        <ReactTooltip
            className={SetNachdrawAcc}
            id="nach-account-change"
            type="light"
            place="bottom"
            effect="solid"
            arrowColor="transparent"
            border={true}
            borderColor="black"
            delayShow={50}
            delayHide={1000}
            delayUpdate={500}
            multiline={true}
        >
            <div className={toolTipArrow}></div>
            <div className={sNText}>Set NACH Account</div>
            <div className={subText}>
                Only verified accounts can be selected as NACH account
            </div>
            <div className={subText}>
                {sortedBankListData?.map((option, index) => (
                    <div className={bankLineItem} key={index} >
                    <input
                        type="radio"
                        id={`option-${index}`}
                        name="options"
                        value={option}
                        checked={checkedAccount===option?.account_number}
                        onChange={() => handleRadioChange(option,index)} 
                        disabled={option?.pennyDropVerificationStatus!==DECENTRO_BANK_VERIFIED_SUCCESS}
                    />
                    <label className={option?.pennyDropVerificationStatus!==DECENTRO_BANK_VERIFIED_SUCCESS ? disableColor: ''} htmlFor={`option-${index}`}>
                        <span className={bankNameLabel}>{option.bank_name?.slice(0, 4)}...</span>
                        <span className={accountNoLabel}> - xxx{option?.account_number?.slice(-4)}</span>
                        </label>
                        {
                                option.pennyDropVerificationStatus===DECENTRO_BANK_VERIFIED_SUCCESS ? (
                                    <span className={`${Status} ${Verified}`}>
                                        <span className={VerifiedText}>{VERIFIED.toUpperCase()}</span>
                                    </span>
                                ) : (
                                    <span className={`${Status} ${NotVerified}`}>
                                        <span className={NotVerifiedText}>{NOT_VERIFIED.toUpperCase()}</span>
                                    </span>
                                    )
                            }
                    </div>
                ))}
            </div>
        </ReactTooltip>
    );
}
