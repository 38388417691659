import React, { useEffect, useRef, useState } from // Component
"react";
// import {Link} from 'react-router-dom';
import ReactAutoComplete from "react-autocomplete";
import { useHistory } from "react-router-dom";
import styles from '../components/CompaniesTableRow.module.scss'

function CompaniesTableHeader({qtext, filterObj, setFilterObj, fetchCompanies, underwriters, companyStatuses=[], underWritingStatuses=[], initialFilterObj, showRelevantCompanies, handleTextSearch, clearAllRef, handleSortingDataCoverage, handleSortingByDealPriority , qtextComments}) {

  const [query, setQuery] = useState({
    companyStatus: "",
    underWriter: "",
    underWritingStatus: ""
  })

  const isFirstRender = useRef(true);
  const previousRef = useRef(showRelevantCompanies);
  const history = useHistory();

  const intitalMenuStyle = {
    borderRadius: "3px",
    boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
    background: "rgba(255, 255, 255, 0.9)",
    padding: "2px 5px",
    fontSize: "13px",
    fontWeight: "normal",
    position: "fixed",
    overflow: "auto",
    maxHeight: "50%",
    zIndex: 2,
    fontFamily: "Gilroy-SemiBold",
    cursor: "pointer",
    marginTop: "5px",
  };

  useEffect(() => {
    if (previousRef.current !== showRelevantCompanies) {
      history.push({
        search: `?show-relevant-filter=${showRelevantCompanies}`
      })
    }
  }, [showRelevantCompanies])

  useEffect(() => {
    if ((!isFirstRender.current && JSON.stringify(initialFilterObj) !== JSON.stringify(filterObj)) || clearAllRef.current === true) {
      const list = JSON.parse(window.localStorage.getItem('underWriterList')) || [];
        window.localStorage.setItem('companiesPageFilterObj', JSON.stringify(filterObj));
          window.localStorage.setItem(
            "underWriterList",
            JSON.stringify([...underwriters, ...list])
          )
        fetchCompanies(true);
        clearAllRef.current = false;
      }
      else {
        isFirstRender.current = false;
      }
  }, [filterObj])

  useEffect(() => {
    const filterObjFromLocalStorage = JSON.parse(
      window.localStorage.getItem("companiesPageFilterObj")
    );
    if (
      filterObjFromLocalStorage &&
      JSON.stringify(filterObjFromLocalStorage) !==
        JSON.stringify(initialFilterObj)
    )
      setFilterObj(filterObjFromLocalStorage);
    else fetchCompanies(true);
  }, []);

  const onOptionSelect = (value, filterType) => {
    if (!value || filterObj[filterType]?.includes(value)) return;
    setFilterObj({
      ...filterObj,
      [filterType]: [...filterObj[filterType], value],
    });
  };

  const shouldRender = (item, value) =>
    item?.label?.toLowerCase().indexOf(value.toLowerCase()) > -1;

  const getHeaders = () => {
    return [
      {
        jsx: <span className={styles.header_input}>
          <input
            value={qtext}
            onChange={(e) => handleTextSearch(e.target.value , "qtext")}
            type='text'
            className='form-control'
            placeholder='Name'
            style={{ border: "none", fontSize: "14px", paddingTop:0, paddingBottom:0}}>
          </input> 
        </span>
      },
      {
        jsx: (
          <span className={`${styles.header_input} ${styles.input_company_status} `}>
            <ReactAutoComplete
              inputProps={{
                type: "text",
                name: "companyStatus",
                placeholder: "Status",
              }}
              items={companyStatuses.map((i) => ({ label: i, id: i }))}
              value={query?.companyStatus}
              getItemValue={(item) => item.label}
              shouldItemRender={shouldRender}
              onChange={(e) => {
                setQuery({ companyStatus: e.target.value });
              }}
              onSelect={(value) => {
                onOptionSelect(value, "companyStatus");
              }}
              renderItem={(item, isHighlighted) => (
                <div
                  style={{
                    background: isHighlighted ? "#2F8FFF" : "white",
                    padding: "5px",
                    margin: "-2px -5px",
                    opacity: "1"
                  }}
                >
                  {item.label}
                </div>
              )}
              menuStyle={{ ...intitalMenuStyle }}
            />
          </span>
        ),
      },
      {
        jsx: <span>
          D/C
          <button
            onClick={() => handleSortingDataCoverage()}
            style={{ border: "none", marginLeft: "7px" }}
          >
            &#8645;
          </button>
      </span>
      },
      {
        jsx: <span>
          Priority
          <button
            onClick={() => handleSortingByDealPriority()}
            style={{ border: "none", marginLeft: "7px" }}
          >
            &#8645;
          </button>
      </span>
      },
      {
        jsx: (
          <span className={`${styles.header_input} ${styles.input_underwriter} `}>
            <ReactAutoComplete
              inputProps={{
                type: "text",
                name: "underWriter",
                placeholder: "Underwriter",
              }}
              items={Object.values(underwriters || []).map((i) => ({ label: i.agent_name, id: i._id }))}
              value={query?.underWriter}
              getItemValue={(item) => item.label}
              shouldItemRender={shouldRender}
              onChange={(e) => {
                setQuery({ underWriter: e.target.value });
              }}
              onSelect={(value) => {
                onOptionSelect(value, "underWriter");
              }}
              renderItem={(item, isHighlighted) => (
                <div
                  style={{
                    background: isHighlighted ? "#2F8FFF" : "white",
                    padding: "5px",
                    margin: "-2px -5px",
                    opacity: "1"
                  }}
                >
                  {item.label}
                </div>
              )}
              menuStyle={{ ...intitalMenuStyle }}
            />
          </span>
        ),
      },
      {
        jsx: (
          <span className={`${styles.header_input} ${styles.input_uw_status} `}>
            <ReactAutoComplete
              inputProps={{
                type: "text",
                name: "underWritingStatus",
                placeholder: "U/W Status",
              }}
              items={underWritingStatuses.map((i) => ({ label: i, id: i }))}
              value={query?.underWritingStatus}
              getItemValue={(item) => item.label}
              shouldItemRender={shouldRender}
              onChange={(e) => {
                setQuery({ underWritingStatus: e.target.value });
              }}
              onSelect={(value) => {
                onOptionSelect(value, "underWritingStatus");
              }}
              renderItem={(item, isHighlighted) => (
                <div
                  style={{
                    background: isHighlighted ? "#2F8FFF" : "white",
                    padding: "5px",
                    margin: "-2px -5px",
                    opacity: "1"
                  }}
                >
                  {item.label}
                </div>
              )}
              menuStyle={{ ...intitalMenuStyle }}
            />
          </span>
        ),
      },
      {
        jsx: <span>Last Uploaded</span>
      },
      {
        jsx: <span>
        Moderation
      </span>
      },
      {
        jsx: <span className={styles.header_input}>
        <input
          value={qtextComments}
          onChange={(e) => handleTextSearch(e.target.value , "qtextComments")}
          type='text'
          className='form-control'
          placeholder='Comments'
          style={{ border: "none", fontSize: "14px", paddingTop:0, paddingBottom:0}}>
        </input> 
      </span>
      },
      {
        jsx: <span>
      </span>
      },
    ]
  }

  const renderHeader = (header, index) => {
    const { jsx } = header;
    return (
        <th key={index}>
            {jsx}
        </th>
    );
  };
  return (
    <thead className='team-head'>
      <tr>
        {getHeaders().map((header, index) => renderHeader(header, index))}
      </tr>
    </thead>
  );
}

export default CompaniesTableHeader;
