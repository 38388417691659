import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    calculateRevenue,
    differenceInPercentage,
    downloadFile,
    getFeeWithoutGst,
    getIrrFromRoi,
    priceFormat,
    showNotification
} from '../../../../../../../../../../../../../util/utility';
import styles from '../MultipleTotComponents/PrepareTerms.module.scss';
import InputCollectionMethod from './CollectionMethod/InputCollectionMethod/InputCollectionMethod';
import {useSelector} from 'react-redux';
import {getArrData, getCheckIfUserHasRelevantPermissions} from '../../../../../../../../../../../../App/AppReducer';
import {
    GST_VALUE,
    PREPARE_TERMS_TAB,
    SHOW_NOTIFICATION
} from '../../../../../../../../../../../../../constants/enums';
import dayjs from 'dayjs';
import FileUpload from '../../../../../../../../../../../../../components/FileUpload/FileUpload';
import moment from "moment";
import ToggleButton from "./ToggleButton/ToggleButton";
import {getMoratMonths} from "../../../../../../../../../../../../../common/ApiHelpers";
import {debounce} from "lodash";
import { getInvesteesOrganization } from '../../../../../../../../../../../AdminReducers';
import { getPrepareTermsTotDetails } from '../../../../../../../../../../../../../common/ApiContainer';

const {
    PrepareTermsTitle,
    PrepareTermsCard,
    OuterCardPositionRelative,
    InnerCardPositionAbsolute,
    OuterCardHeader,
    InnerCardPositionAbsoluteDS,
    OuterCardPositionRelativeDS,
    p_20,
    w_15,
    w_18,
    w_20,
    w_25,
    w_27,
    w_30,
    w_35,
    w_60,
    p_3,
    centerAlign,
    left, middle, right,
    TwoInput,
    greyCard,
    greyCardHeading,
    whiteCard,
    whiteCardHeading,
    blueCard,
    blueCardHeading,
    yellowCard,
    yellowCardHeading,
    ShowMaxLimitCheckbox,
    ShowStandardLimitCheckbox,
    ProjectedTradingLimit,
    TotalAvailableLimit,
    tradingLimitInput,
    diffPercentage,
    negativeRevenue,
    w_80,
    dropdownOptions,
    option,
    checkboxLabel,
    customDropdown,
    customDropdownDisabled,
    customDropdownText,
    downArrowImage,
    optionsContainer,
    optionsBox,
    topCornersRounded,
    bottomCornersRounded,
    downloadEL,
    engagementLetter,
    signingDateStyling,
    lowOpacity,
    engagementLetterFlex,
} = styles;

export default function PrepareTerms({
                                        standardProps,
                                        maxProps,
                                        recurLimitProps,
                                        renderNote4,
                                        isModerating,
                                        moratDropdownList,
                                        totData,
                                        setTotData,
                                        initialTotData,
                                        showMaxLimit,
                                        setShowMaxLimit,
                                        detectChange,
                                        setDetectChange,
                                        userNameForCoborrower,
                                        setUserNameForCoborrower,
                                        showStandardLimit,
                                        setShowStandardLimit,
                                        originalTotData,
                                        totalAvailableLimit,
                                        updateToDefaultUserTotData,
                                        isTotAccepted,
                                        setCurrentModerateTab,
                                        currentModerateTab,
                                        investeeOnboardedId,
                                        addOnId,
                                        isAddOn,
                                     }) {

    // Destructure standardProps
    const {
        renderCP1,
        renderCS1,
        renderNote1,
        addMoreCP1,
        addMoreCS1,
    } = standardProps;
    const setStandardCP = standardProps.setCp;
    const setStandardCS = standardProps.setCs;

    // Destructure maxProps
    const {
        renderCP2,
        renderCS2,
        renderNote2,
        addMoreCP2,
        addMoreCS2,
    } = maxProps;
    const setMaxCP = maxProps.setCp;
    const setMaxCS = maxProps.setCs;

    // Destructure recurLimitProps
    const {
        renderCP3,
        renderCS3,
        renderNote3,
        addMoreCP3,
        addMoreCS3,
    } = recurLimitProps;
    const recurLimitCP = recurLimitProps.cp;
    const recurLimitCS = recurLimitProps.cs;

    useEffect(() => {
        const handleWheel = (event) => {
            const activeElement = document.activeElement;
            if (
                activeElement &&
                activeElement.type === "number" &&
                activeElement.classList.contains("noscroll")
            ) {
                activeElement.blur();
            }
        };

        document.addEventListener("wheel", handleWheel);

        return () => {
            document.removeEventListener("wheel", handleWheel);
        };
    }, []);

    useEffect(()=>{
        const obj={...detectChange}
        if(showStandardLimit && isModerating){
            setStandardCS(recurLimitCS)
            obj[STANDARD]=true;
        }
        if(showMaxLimit && isModerating){
            setMaxCS(recurLimitCS)
            obj[MAX]=true;
        }
        setDetectChange(obj)
    },[showStandardLimit, showMaxLimit, recurLimitCS])

    useEffect(()=>{
        const obj={...detectChange}
        if(showStandardLimit && isModerating){
            setStandardCP(recurLimitCP)
            obj[STANDARD]=true;
        }
        if(showMaxLimit && isModerating){
            setMaxCP(recurLimitCP)
            obj[MAX]=true;
        }
        setDetectChange(obj)
    },[recurLimitCP,showStandardLimit, showMaxLimit])

    const RECUR_LIMIT = "Recurlimit"
    const STANDARD = "Standard";
    const DEBT_SYNDICATION = "DebtSyndication";
    const MAX = "Max";
    const WAIVED = "Waived";
    const NOT_WAIVED = "Not Waived";
    const ACCEPTED = "Accepted"

    const FINANCING_STRUCTURES_ENUM = {
        TERMS_LOAN: 'Term Loan',    
        WORKING_CAPITAL: 'Working Capital Demand Loan',
        SALES_INVOICING: 'Sales Invoice Discounting',
        PURCHASE_INVOICING: 'Purchase Invoice Discounting',
        OVERDRAFT: 'Overdraft',
        DROP_LINE_OVER_DRAFT: 'Drop Line Over Draft',
        VENTURE_DEBT:"Venture Debt",
        OPERATING_LEASE:"Operating Lease",
        VENDOR_FINANCING: "Vendor Financing",
        CREDIT_CARD: "Credit Card",
        LOAN_AGAINST_PROPERTY: "Loan Against Property"
    }
    const availableTradeLimit = useSelector(getArrData)?.available_limit;
    const isUserHasUWEditAccess = useSelector((state) => getCheckIfUserHasRelevantPermissions(state, ["underwriting:edit"]));
    const [securityValues, setSecurityValues] = useState([]);
    const [financingStructureValues, setFinancingStructureValues] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [financingStructureDropdownVisible, setFinancingStructureDropdownVisible] = useState(false);
    const [engagementLetterVisible, setEngagementLetterVisible] = useState(false);
    const [selectedEngagementLetter, setSelectedEngagementLetter] = useState("");
    const [disabledDropDown, setIsDisabledDropDown] = useState(false);
    const [totAcceptedValue, setTotAcceptedValue] = useState({});
    const [totAccepted, setTotAccepted] = useState(false);
    const [engagementSignDate, setEngagementSignDate] = useState('');

    const dropdownRef = useRef(null);
    const financeDropdownRef = useRef(null);
    const engagementLetterRef = useRef(null);
    const securityOptions = ['All', 'Personal Guarantee', 'Escrow Of Cash Flows', 'Charge On Assets', 'FD upto 25%', 'FD between 25% - 50%', 'Property','Corporate Guarantee','Warrants'];
    const financingStructureOptions = ['All', ...Object.values(FINANCING_STRUCTURES_ENUM)];
    const engagementLetterOptions = ['Standard EL', 'Negotiated EL', 'Signed EL'];
    const stageTotId = originalTotData[DEBT_SYNDICATION]?.stage_id;
    const investeeOrganizationName = useSelector(getInvesteesOrganization)?.display_name; 
    const investeeOrgData = useSelector(getInvesteesOrganization);   

    useEffect(()=>{
        const optionsWithoutAll = securityOptions.filter(option => option !== "All");
        let updatedValues;
         const totSecurityValues = totData?.[DEBT_SYNDICATION]?.security;
        if(totSecurityValues && totSecurityValues?.length){
            updatedValues = JSON.stringify(totSecurityValues?.sort()) === JSON.stringify(optionsWithoutAll.sort()) ? [...securityOptions] : totSecurityValues
        }else{
            updatedValues = []
        }

        const financingOptionsWithoutAll = financingStructureOptions.filter(option => option !== "All");
        let financeUpdatedValues;
         const totFinanceValues = totData?.[DEBT_SYNDICATION]?.financingStructure;
        if(totFinanceValues && totFinanceValues?.length){
            financeUpdatedValues = JSON.stringify(totFinanceValues?.sort()) === JSON.stringify(financingOptionsWithoutAll.sort()) ? [...financingStructureOptions] : totFinanceValues
        }else{
            financeUpdatedValues = []
        }
        
        const selectedElType = originalTotData?.DebtSyndication?.elUploadedType;
        if(selectedElType !== "") {
            selectEngagementLetterOption(selectedElType);
        }

        setSecurityValues(updatedValues)
        setFinancingStructureValues(financeUpdatedValues)
    },[])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownVisible && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownVisible(false);
            }
        };
    
        if (dropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownVisible]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (financingStructureDropdownVisible && financeDropdownRef.current && !financeDropdownRef.current.contains(event.target)) {
                setFinancingStructureDropdownVisible(false);
            }
        };
    
        if (financingStructureDropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [financingStructureDropdownVisible]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (engagementLetterVisible && engagementLetterRef.current && !engagementLetterRef.current.contains(event.target)) {
                setEngagementLetterVisible(false);
            }
        };
        if (engagementLetterVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [engagementLetterVisible]);

    useEffect(()=>{
        let transformedArray;
        if(currentModerateTab === PREPARE_TERMS_TAB.DS_TERMS){
            if(securityValues?.length){
                transformedArray = securityValues.includes("All")
                ? securityValues.filter(value => value !== "All")
                    : [...securityValues]
               
            } else{
                transformedArray = []
            }
            handleChange(DEBT_SYNDICATION, "security", transformedArray)
        }
        
    },[securityValues])

    useEffect(()=>{
        let transformedArray;
        if(currentModerateTab === PREPARE_TERMS_TAB.DS_TERMS){
            if(financingStructureValues?.length){
                transformedArray = financingStructureValues.includes("All")
                ? financingStructureValues.filter(value => value !== "All")
                    : [...financingStructureValues]
               
            } else{
                transformedArray = []
            }
            handleChange(DEBT_SYNDICATION, "financingStructure", transformedArray)
        }
        
    },[financingStructureValues])

    useEffect(()=>{
        if(!isModerating){
            setDropdownVisible(isModerating)
            setFinancingStructureDropdownVisible(isModerating)
        } 
    },[isModerating])

    useEffect(() => {
        handleChange(DEBT_SYNDICATION, "elUploadedType", selectedEngagementLetter)
    }, [selectedEngagementLetter, setSelectedEngagementLetter])

    useEffect(() => {
        const fetchData = async () => {
          const data = isAddOn ? 
            { investee_organization_id: investeeOrgData?._id, add_on_limit_id: addOnId } : 
            { investee_organization_id: investeeOrgData?._id, onboarding_id: investeeOnboardedId };
          const res = await getPrepareTermsTotDetails(
            data,
          );
          if(res?.data?.data?.DebtSyndication?.tot_status === ACCEPTED) {
            setTotAccepted(true);
            setTotAcceptedValue(res?.data?.data?.DebtSyndication);
          }
        }
        fetchData();
      },[])

      useEffect(() => {
        const date = totAcceptedValue?.engagementSignDate;
        if (date) {
            const newDate = dayjs(date).format("YYYY-MM-DD");
            setEngagementSignDate(newDate);
        }
      }, [totAcceptedValue]);
    
    const handleDownloadEl = () => {
        if (!totData?.[DEBT_SYNDICATION]?.signedEngagementLetter) {
            showNotification('error', 'upload signed engagement letter first')
            return;
        } 
        else if(totData?.[DEBT_SYNDICATION]?.elUploadedType === 'Signed EL' ) { 
            downloadFile({ docId: totData[DEBT_SYNDICATION].signedEngagementLetter, fileName: `Signed_EL_${totData[DEBT_SYNDICATION].signedEngagementLetter}.pdf` });
        }
        else if(totData?.[DEBT_SYNDICATION]?.elUploadedType === 'Negotiated EL') {
            downloadFile({ docId: totData[DEBT_SYNDICATION].signedEngagementLetter, fileName: `Negotiated_EL_${totData[DEBT_SYNDICATION].signedEngagementLetter}.pdf` });
        }
        else if(totAccepted && totAcceptedValue?.elUploadedType === "Standard EL") {
            downloadFile({docId: totAcceptedValue?.signedEngagementLetter, fileName: `Signed_EL_${totData[DEBT_SYNDICATION].signedEngagementLetter}.pdf`});
        }
    }

    const validateTotData = (keyName, keyValue) => {
        const obj = JSON.parse(JSON.stringify(totData));
        const recurLimit = obj[RECUR_LIMIT];

        const showError = (message, baseValue) => ({
            message,
            showError: true,
            baseValue
        });
        switch (keyName) {
            case 'tradingLimit':
                const tradingLimit = Number(keyValue);
                return tradingLimit > Number(recurLimit.tradingLimit)
                    ? showError("Trading Limit cannot be more than Recur Limit Trading Limit", recurLimit.tradingLimit)
                    : tradingLimit < 0
                        ? showError("Trading Limit cannot be negative", 0)
                        : {message: "", showError: false, baseValue: 0};

            case 'roi':
                return Number(keyValue) < Number(recurLimit.roi)
                    ? showError("ROI cannot be less than Recur Limit ROI", recurLimit.roi)
                    : {message: "", showError: false, baseValue: 0};

            case 'maxTenure':
                const maxTenure = Number(keyValue);
                return maxTenure > Number(recurLimit.maxTenure)
                    ? showError("Tenure cannot be more than Recur Limit Tenure", recurLimit.maxTenure)
                    : maxTenure < 1
                        ? showError("Tenure cannot be less than 1", 1)
                        : {message: "", showError: false, baseValue: 0};
            case 'fee':
                const fee = Number(keyValue)* GST_VALUE;
                if (recurLimit.feeRes.isDiscountApplicable) {
                    return fee < Number(recurLimit.fee) / 2 ? showError("Fee cannot be less than 50% of Recur Limit Fee", Number(recurLimit.fee) / 2) : {
                        message: "",
                        showError: false,
                        baseValue: 0
                    };
                } else {
                    return fee < Number(recurLimit.fee) ? showError("Fee cannot be less than Recur Limit Fee", Number(recurLimit.fee)) : {
                        message: "",
                        showError: false,
                        baseValue: 0
                    };
                }
            case 'moratorium_months':
                const moratorium_months = Number(keyValue);
                return moratorium_months > Number(recurLimit.moratorium_months)
                    ? showError("Moratorium cannot be more than Recur Limit Moratorium", recurLimit.moratorium_months)
                    : moratorium_months < 0
                        ? showError("Moratorium cannot be Less than the current Month", 0)
                        : {message: "", showError: false, baseValue: 0};
            case 'collectionMethods':
                const collectionMethods = keyValue;
                return checkWhetherCollectionMethodsAreLessThanRecurLimit(collectionMethods, recurLimit.collectionMethods)
                    ? showError("Collection Methods cannot be less than Recur Limit Collection Methods", recurLimit.collectionMethods)
                    : {message: "", showError: false, baseValue: 0};
            default:
                return {message: "", showError: false, baseValue: 0};
        }
    };
    const checkWhetherCollectionMethodsAreLessThanRecurLimit = (collectionMethods, recurLimitCollectionMethods) => {
        let standardLimitKeys = Object.keys(collectionMethods);
        let recurLimitKeys = Object.keys(recurLimitCollectionMethods);
        const allKeyPresent = recurLimitKeys?.every((key) => {
            if (Array.isArray(collectionMethods[key]) && Array.isArray(recurLimitCollectionMethods[key])) {
                const arrayA = collectionMethods[key]
                const arrayB = recurLimitCollectionMethods[key]
                return arrayB?.every(objB =>
                    arrayA?.some(objA => JSON.stringify(objA) === JSON.stringify(objB))
                );
            }
            return standardLimitKeys?.includes(key) || collectionMethods[key] === null
        })
        return !allKeyPresent;
    }
    const handleValidation = (category,keyName,keyValue) => {
        const validation = validateTotData(keyName, keyValue);
        if (validation.showError) {
            showNotification(SHOW_NOTIFICATION.ERROR, validation.message);
            const obj = JSON.parse(JSON.stringify(totData));
            if (keyName === 'fee') {
                obj[category]["feeRes"].isDiscountApplicable = true;
                obj[category]["feeRes"].discountExpiryDate = new Date().setDate(new Date().getDate() + 7);
                obj[RECUR_LIMIT]["feeRes"].discountExpiryDate = new Date().setDate(new Date().getDate() + 7);
                obj[RECUR_LIMIT].discount_expiry_date = new Date().setDate(new Date().getDate() + 7);
                obj[category].discount_expiry_date = new Date().setDate(new Date().getDate() + 7);
            }
            obj[category][keyName] = validation.baseValue;
            setTotData(obj);
            return validation
        }
    }
    const handleChange = (category, keyName, keyValue) => {
        const tempObj = {...detectChange};
        if(category!==RECUR_LIMIT&&category!==DEBT_SYNDICATION&&keyName==='collectionMethods'){
            const validation = validateTotData(keyName,keyValue);
            if(validation.showError){
                showNotification(SHOW_NOTIFICATION.ERROR, validation.message);
                const obj = JSON.parse(JSON.stringify(totData));
                obj[category][keyName] = validation.baseValue;
                setTotData(obj);
                if (!tempObj[category]) {
                    tempObj[category] = true;
                }
                setDetectChange(tempObj);
                return;
            }else{
                const obj = JSON.parse(JSON.stringify(totData));
                obj[category][keyName] = keyValue;
                setTotData(obj);
                if (!tempObj[category]) {
                    tempObj[category] = true;
                }
                setDetectChange(tempObj);
                return;
            }
        }
        if (keyName === "fee") {
            keyValue = keyValue * GST_VALUE;
        }
        if (!tempObj[category]) {
            tempObj[category] = true;
        }
        const obj = JSON.parse(JSON.stringify(totData));
        if (!obj[category]) obj[category] = {};
        obj[category][keyName] = keyValue;
        if (keyName === "roi") {
            obj[category]["irr"] = getIrrFromRoi(keyValue);
        }
        if (keyName === 'fee' && obj[category].totStatus !== ACCEPTED && category!==DEBT_SYNDICATION) {
            obj[category]["feeRes"].isDiscountApplicable = true;
            obj[category]["feeRes"].discountExpiryDate = new Date().setDate(new Date().getDate() + 7);
            obj[RECUR_LIMIT]["feeRes"].discountExpiryDate = new Date().setDate(new Date().getDate() + 7);
            obj[RECUR_LIMIT].discount_expiry_date = new Date().setDate(new Date().getDate() + 7);
            obj[category].discount_expiry_date = new Date().setDate(new Date().getDate() + 7);
        }
        if (keyName === 'collectionMethods' && category === RECUR_LIMIT) {
            obj[category]["collectionMethods"] = keyValue;
            if (showStandardLimit) {
                obj[STANDARD]["collectionMethods"] = keyValue;
                if (!tempObj[STANDARD]) {
                    tempObj[STANDARD] = true;
                }
            }
            if (showMaxLimit) {
                obj[MAX]["collectionMethods"] = keyValue;
                if (!tempObj[MAX]) {
                    tempObj[MAX] = true;
                }
            }
        }
        if (keyName === 'discount_expiry_date') {
            obj[category]["feeRes"].discountExpiryDate = keyValue;
            obj[RECUR_LIMIT]["feeRes"].discountExpiryDate = keyValue;
            obj[RECUR_LIMIT].discount_expiry_date = keyValue;
            obj[category].discount_expiry_date = keyValue;
        }
        if (keyName === 'showRoi') {
            obj[RECUR_LIMIT]["showRoi"] = keyValue;
            obj[category]["showRoi"] = keyValue;
        }
        // Set minTenure as maxTenure in case of standard and max tot
        if (keyName === "maxTenure" && [STANDARD, MAX].includes(category)) {
            obj[category]["minTenure"] = keyValue;
        }
        setDetectChange(tempObj);
        setTotData(obj);
    }

    const renderAddMore = ({label, onClick}) => {
        return (
            <span className={`fs10 fw300 mt-2 ${p_20} ${w_30} ${left} color2 cp`}
                  onClick={onClick}> + Add more {label}</span>
        );
    }

    const isDisabled = (category, fieldName = null) => {
        if (category === STANDARD) return !isModerating || !showStandardLimit || checkBaseTotAccepted(category, fieldName);
        if (category === MAX) return !isModerating || !showMaxLimit || checkBaseTotAccepted(category, fieldName);
        if (fieldName === 'totActive') return !isModerating;
        if (category === DEBT_SYNDICATION) return !isModerating;
        return !isModerating || !isUserHasUWEditAccess;
    }

    const isFileUploadDisabled = (category) => {
        if (category === DEBT_SYNDICATION) {
            return !(initialTotData?.[DEBT_SYNDICATION] && isModerating)};
    }



    const checkBaseTotAccepted = (category, fieldName = null) => {
        // Check if base tot accepted and block the tot not accepted by user
        if (isTotAccepted && totData?.[category]?.totStatus !== ACCEPTED) return true;
        // Otherwise only allow rcm to be updated
        return (isTotAccepted && (fieldName === null || fieldName !== "collectionMethod"));
    }

    const handleStandardCheckChange = (value) => {
        if (showMaxLimit && !value) {
            showNotification("Error", "Please, uncheck Max Limit First !");
            return;
        }
        if (!value && (MAX in originalTotData || STANDARD in originalTotData)) setShowStandardLimit(true);
        else {
            if (value) updateToDefaultUserTotData({
                category: STANDARD,
                oldRcm: totData[RECUR_LIMIT]?.collectionMethods
            });
            setShowStandardLimit(value);
        }
        if ((isEmpty(originalTotData) || RECUR_LIMIT in originalTotData) && value) {
            setTotData((prevTotData) => {
                const temp_data = {...prevTotData};
                if (!isEmpty(temp_data)) temp_data[STANDARD] = temp_data?.[RECUR_LIMIT];
                return temp_data;
            })
        }
    }

    const handleMaxCheckChange = (value) => {
        if (!showStandardLimit && value) {
            showNotification("Error", "Please, Select Standard Limit First !");
            return;
        }
        const oldRcm = RECUR_LIMIT in totData ? totData[RECUR_LIMIT]?.collectionMethods : totData[STANDARD]?.collectionMethods;
        if (value) updateToDefaultUserTotData({category: MAX, oldRcm});
        setShowMaxLimit(value);
        if ((STANDARD in totData && !(MAX in originalTotData)) && showStandardLimit && value) {
            setTotData((prevTotData) => {
                const temp_data = {...prevTotData};
                if (!isEmpty(temp_data)) temp_data[MAX] = temp_data?.[STANDARD];
                return temp_data;
            })
        }
    }
    const showTotalAvailableLimit = () => {
        if (!isNil(totalAvailableLimit)) return priceFormat(totalAvailableLimit);
        if (!isNil(availableTradeLimit)) return priceFormat(availableTradeLimit);
        return "N.A.";
    }

    const recurLimitRevenue = useMemo(() => {
        return calculateRevenue(
            totData?.[RECUR_LIMIT]?.isWaived ? 0 : totData?.[RECUR_LIMIT]?.fee,
            totData?.[RECUR_LIMIT]?.tradingLimit,
            totData?.[RECUR_LIMIT]?.roi,
            totData?.[RECUR_LIMIT]?.maxTenure
        );
    }, [totData]);

    const standardLimitRevenue = useMemo(() => {
        return calculateRevenue(
            totData?.[STANDARD]?.isWaived ? 0 : totData?.[STANDARD]?.fee,
            totData?.[STANDARD]?.tradingLimit,
            totData?.[STANDARD]?.roi,
            totData?.[STANDARD]?.maxTenure
        );
    }, [totData]);
    const maxLimitRevenue = useMemo(() => {
        return calculateRevenue(
            totData?.[MAX]?.isWaived ? 0 : totData?.[MAX]?.fee,
            totData?.[MAX]?.tradingLimit,
            totData?.[MAX]?.roi,
            totData?.[MAX]?.maxTenure
        );
    }, [totData]);

    const standardLimitDifference = useMemo(() => {
        return differenceInPercentage(recurLimitRevenue, standardLimitRevenue);
    }, [recurLimitRevenue, standardLimitRevenue]);

    const maxLimitDifference = useMemo(() => {
        return differenceInPercentage(recurLimitRevenue, maxLimitRevenue);
    }, [recurLimitRevenue, maxLimitRevenue]);

   const handleCheckboxChange = (value,key = null) => {
        if (value === "All" && key === "Security") {
            if (!securityValues?.includes("All")) {
                setSecurityValues([...securityOptions]);
            } else {
                setSecurityValues([]);
            }
        }else if (key === "Security") {
            setSecurityValues((prevSecurityValues) => {

                let updatedValues = prevSecurityValues?.includes(value)
                                        ? prevSecurityValues.filter((securityValue) => securityValue !== value)
                                            : [...prevSecurityValues, value];

                if (prevSecurityValues?.includes(value)) {        
                    updatedValues = updatedValues?.includes("All") ? updatedValues.filter((securityValue) => securityValue !== "All") : updatedValues;
                    return updatedValues;
                } else {
                    const optionsWithoutAll = securityOptions.filter(option => option !== "All");
                    if (JSON.stringify(updatedValues?.sort()) === JSON.stringify(optionsWithoutAll.sort())) {
                    updatedValues = [...securityOptions]; 
                    }
                    return updatedValues;
                }
            });
        } else if (value === "All" && key === "Financing Structure") {
            if (!financingStructureValues?.includes("All")) {
                setFinancingStructureValues([...financingStructureOptions]);
            } else {
                setFinancingStructureValues([]);
            }
        }else if (key === "Financing Structure") {
            setFinancingStructureValues((prevFinanceValues) => {

                let updatedValues = prevFinanceValues?.includes(value)
                                        ? prevFinanceValues.filter((financeValue) => financeValue !== value)
                                            : [...prevFinanceValues, value];

                if (prevFinanceValues?.includes(value)) {        
                    updatedValues = updatedValues?.includes("All") ? updatedValues.filter((financeValue) => financeValue !== "All") : updatedValues;
                    return updatedValues;
                } else {
                    const optionsWithoutAll = financingStructureOptions.filter(option => option !== "All");
                    if (JSON.stringify(updatedValues?.sort()) === JSON.stringify(optionsWithoutAll.sort())) {
                    updatedValues = [...financingStructureOptions]; 
                    }
                    return updatedValues;
                }
            });
        }
    }
    
    const toggleDropdown = (value = null) => {
        if (value === "Financing structure")
            setFinancingStructureDropdownVisible((prevVisible) => !prevVisible);
        else if (value === "Security")
            setDropdownVisible((prevVisible) => !prevVisible);
        else 
            setEngagementLetterVisible((prevVisible) => !prevVisible);
    };

    const selectEngagementLetterOption = (value) => {
        if(value != null && value !== "") {
            setSelectedEngagementLetter(value);
        } 
        setEngagementLetterVisible(prev => !prev);
    };

    return (
        <div>
            <div className={PrepareTermsTitle}>
                <div className="modal-header pb-0 d-flex">
                    <div className="dflex">
                        {/* <RecurTabs
                        tabs={PREPARE_TERMS_TAB_ARRAY}
                        currentTab={currentModerateTab}
                        setCurrentTab={setCurrentModerateTab}
                        color={RECUR_TABS_COLORS.BLUE}
                    /> */}
                    </div>
                    <span className={ProjectedTradingLimit}>
                        <span className={TotalAvailableLimit}>Total Available Limit: </span>
                        <span className="w-50 color6">{showTotalAvailableLimit()}</span>
                    </span>
                </div>
            </div>
            {currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS && (
                <div className={OuterCardHeader}>
                    <div className={`${w_20} ${whiteCardHeading}`}>Prepare Terms</div>
                    <div className={`${w_30} ${greyCardHeading}`}>
                        Revenue*: ₹ {recurLimitRevenue} L
                    </div>
                    <div className={`${w_30} ${blueCardHeading}`}>
                        Revenue*: ₹ {standardLimitRevenue} L <span
                        className={`${diffPercentage} ${standardLimitDifference.includes('-') ? negativeRevenue : ''}`}>({standardLimitDifference}%)</span>
                    </div>
                    <div className={`${w_30} ${yellowCardHeading}`}>
                        Revenue*: ₹ {maxLimitRevenue} L <span
                        className={`${diffPercentage} ${maxLimitDifference.includes('-') ? negativeRevenue : ''}`}>({maxLimitDifference}%)</span>
                    </div>
                </div>
            )}
            <div className={PrepareTermsCard}>
                {currentModerateTab === PREPARE_TERMS_TAB.DS_TERMS && (<div className={OuterCardPositionRelative} style={{overflow:'auto'}}>
                    
                    <div className={`${w_18} ${whiteCard}`}></div>
                    <div className={`${w_27} ${blueCard}`}></div>
                    <div className={InnerCardPositionAbsolute} style={{width: '45.45%'}}>
                        <br></br>
                        <div className='dflexJAT w-30'>
                            <div className={`fs10 fw300 mt-2 ${p_20} ${w_20} ${left}`}></div>
                            <div className={`${p_20} ${w_60} ${middle}`}>
                                <div className='fs10 fw300 text-center mb-4 '><i>DEBT SYND. LIMIT</i></div>
                            </div>
                        </div>
                        <div className='dflexJAT w-30'>
                            <div className={`fs10 fw300 mt-2 ${p_20} ${w_20} ${left}`}>Trading Limit (INR lacs)</div>
                            <div className={`${p_20} ${w_60} ${middle} ${TwoInput} `}>
                                <input
                                    type='number'
                                    required={true}
                                    className={`noscroll ${tradingLimitInput}`}
                                    disabled={isDisabled(DEBT_SYNDICATION)}
                                    value={totData?.[DEBT_SYNDICATION]?.tradingLimit}
                                    onChange={e => handleChange(DEBT_SYNDICATION, "tradingLimit", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='dflexJAT w-30'>
                            <div className={`fs10 fw300 mt-2 ${p_20} ${w_20} ${left}`}>ROI</div>
                            <div className={`${p_20} ${w_60} ${middle} ${TwoInput} gap-0.02`}>
                                <input
                                    type='number'
                                    required
                                    className="noscroll"
                                    min={0}
                                    disabled={isDisabled(DEBT_SYNDICATION)}
                                    value={totData?.[DEBT_SYNDICATION]?.irr}
                                    onChange={e => handleChange(DEBT_SYNDICATION, "irr", Number(e.target.value))}
                                />
                                {/* This is Introduced for Debt PEC Matching Percentage */}
                                <input
                                    type='number'
                                    required
                                    className="noscroll"
                                    min={0}
                                    disabled={isDisabled(DEBT_SYNDICATION)}
                                    value={totData?.[DEBT_SYNDICATION]?.maxIrr}
                                    onChange={e => handleChange(DEBT_SYNDICATION, "maxIrr", Number(e.target.value))}
                                />
                            </div>
                        </div>
                        <div className='dflexJAT w-30'>
                            <div className={`fs10 fw300 mt-2 ${p_20} ${w_20} ${left}`}>Tenure (months)</div>
                            <div className={`${p_20} ${w_60} ${middle} ${TwoInput} gap-0.02`}>
                                <input
                                    type='number'
                                    required
                                    className='noscroll'
                                    disabled={isDisabled(DEBT_SYNDICATION)}
                                    value={totData?.[DEBT_SYNDICATION]?.minTenure}
                                    onChange={e => handleChange(DEBT_SYNDICATION, "minTenure", Number(e.target.value))}
                                />
                                <input
                                    type='number'
                                    required
                                    className='noscroll'
                                    disabled={isDisabled(DEBT_SYNDICATION)}
                                    value={totData?.[DEBT_SYNDICATION]?.maxTenure}
                                    onChange={e => handleChange(DEBT_SYNDICATION, "maxTenure", Number(e.target.value))}
                                />
                            </div>
                        </div>
                        <div className='dflexJAT w-30'>
                            <div className={`fs10 fw300 mt-2 ${p_20} ${w_20} ${left}`}>Fee (without GST)</div>
                            <div className={`${p_20} ${w_60} ${middle} ${TwoInput} gap-0.02`}>
                                <input
                                    type='number'
                                    required
                                    className='noscroll'
                                    disabled={isDisabled(DEBT_SYNDICATION)}
                                    // Take input without GST but save with GST
                                    value={totData?.[DEBT_SYNDICATION]?.fee ? getFeeWithoutGst(totData[DEBT_SYNDICATION].fee) : null}
                                    onChange={e => handleChange(DEBT_SYNDICATION, "fee", e.target.value)}
                                />
                                <select
                                    required
                                    name=''
                                    id=''
                                    disabled={isDisabled(DEBT_SYNDICATION)}
                                    value={totData?.[DEBT_SYNDICATION]?.isWaived}
                                    onChange={e => handleChange(DEBT_SYNDICATION, "isWaived", e.target.value === 'true')}
                                >
                                    <option value={false}>{NOT_WAIVED}</option>
                                    <option value={true}>{WAIVED}</option>
                                </select>
                            </div>
                        </div> 
                        <div className='dflexJAT w-30'>
                            <div className={`fs10 fw300 mt-2 ${p_20} ${w_20} ${left}`}>Financing structure</div>
                                <div className={`${p_20} ${w_60} ${middle} ${TwoInput} column-container`} style={{ position: 'relative' }} ref={financeDropdownRef} >
                                    <div className={isDisabled(DEBT_SYNDICATION) ? customDropdownDisabled : customDropdown} onClick={() => !isDisabled(DEBT_SYNDICATION) && toggleDropdown("Financing structure")} disabled={isDisabled(DEBT_SYNDICATION)}>
                                        <span className={customDropdownText}>
                                            { 
                                                financingStructureValues?.length > 0 ? (
                                                    financingStructureValues?.includes("All") ? (
                                                        financingStructureOptions.filter(option => option !== "All").join(', ')
                                                    ) : (
                                                        financingStructureValues?.join(', ')
                                                    )
                                                ) : (
                                                    'Select Options'
                                                )
                                            }
                                        </span>
                                        <span>
                                            {isDisabled(DEBT_SYNDICATION) ? <img src='https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/svgviewer-output.svg' alt="" />
                                                                        : (financingStructureDropdownVisible) 
                                                                            ? <img className={downArrowImage} src='https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/up-arrow.svg' alt="" />
                                                                                : <img className={downArrowImage} src='https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/down-arrow.svg' alt="" />}
                                        </span>
                                    </div>
                                    {financingStructureDropdownVisible && !isDisabled(DEBT_SYNDICATION) && (
                                        <div className={optionsContainer}>
                                            {financingStructureOptions.map((option, index) => (                               
                                                <div key={index} className={`${optionsBox}`}>
                                                    <input
                                                    type="checkbox"
                                                    id={option}
                                                    style={{alignItems:'flex-start', width:'20px'}}
                                                    checked={financingStructureValues?.includes(option)}
                                                    onChange={() => handleCheckboxChange(option,"Financing Structure")}
                                                    />
                                                    <label htmlFor={option}>
                                                        <div className={checkboxLabel}>
                                                            {option}
                                                        </div>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                        </div>  
                        <div className='dflexJAT w-30' style={{ overflow: 'visible' }}>
                            <div className={`fs10 fw300 mt-2 ${p_20} ${w_20} ${left}`}>Security</div>
                                <div className={`${p_20} ${w_60} ${middle} ${TwoInput} column-container`} style={{ position: 'relative' }} ref={dropdownRef} >
                                    <div className={isDisabled(DEBT_SYNDICATION) ? customDropdownDisabled : customDropdown} onClick={()=> isDisabled(DEBT_SYNDICATION) ? null : toggleDropdown("Security")} disabled={isDisabled(DEBT_SYNDICATION)}>
                                        <span className={customDropdownText}>
                                            {
                                                securityValues?.length > 0 ? (
                                                    securityValues?.includes("All") ? (
                                                        securityOptions.filter(option => option !== "All").join(', ')
                                                    ) : (
                                                        securityValues?.join(', ')
                                                    )
                                                ) : (
                                                    'Select Options'
                                                )
                                            }
                                        </span>
                                        <span>
                                            {isDisabled(DEBT_SYNDICATION) ? <img src='https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/svgviewer-output.svg' alt="" />
                                                                        : (dropdownVisible) 
                                                                            ? <img className={downArrowImage} src='https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/up-arrow.svg' alt="" />
                                                                                : <img className={downArrowImage} src='https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/down-arrow.svg' alt="" />}
                                        </span>
                                    </div>
                                    {dropdownVisible && !isDisabled(DEBT_SYNDICATION) && (
                                        <div className={optionsContainer}>
                                            {securityOptions.map((option, index) => (                               
                                                <div key={index} className={`${optionsBox}`}>
                                                    <input
                                                    type="checkbox"
                                                    id={option}
                                                    style={{alignItems:'flex-start', width:'20px'}}
                                                    checked={securityValues?.includes(option)}
                                                    onChange={() => handleCheckboxChange(option,"Security")}
                                                    />
                                                    <label htmlFor={option}>
                                                        <div className={checkboxLabel}>
                                                            {option}
                                                        </div>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                        </div>

                        <div className='dflexJAT w-100'>
                            <div className={`fs10 fw300 mt-2 ${p_20} ${w_20} ${left}`}>
                                Details
                            </div>
                            <div className={`${p_20} ${w_60} ${left}`}>
                                {renderNote4(isDisabled(DEBT_SYNDICATION), DEBT_SYNDICATION)}
                            </div>
                        </div>
                        <div className='dflexJAT w-100'>
                            <div className={`fs10 fw300 mt-2 ${p_20} ${w_20} ${left}`}>
                                Engagement Letter
                            </div>
                            <div className={`${p_20} ${w_60} ${middle} ${TwoInput} column-container`} style={{ position: 'relative' }} ref={engagementLetterRef}>
                                <div className={isFileUploadDisabled(DEBT_SYNDICATION) || disabledDropDown || totAccepted ? customDropdownDisabled : customDropdown} onClick={isFileUploadDisabled(DEBT_SYNDICATION) || disabledDropDown || totAccepted ? null : toggleDropdown}>
                                    <span className={customDropdownText}>
                                        {
                                            selectedEngagementLetter === "" ? (
                                                setSelectedEngagementLetter(engagementLetterOptions[0])
                                            ) : (
                                                selectedEngagementLetter
                                            )
                                        }
                                    </span>
                                    <span>
                                        {isFileUploadDisabled(DEBT_SYNDICATION) || disabledDropDown ? <img src='https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/svgviewer-output.svg' alt="" />
                                                                    : (engagementLetterVisible) 
                                                                        ? <img className={downArrowImage} src='https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/up-arrow.svg' alt="" />
                                                                            : <img className={downArrowImage} src='https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/down-arrow.svg' alt="" />}
                                    </span>
                                </div>
                                {engagementLetterVisible && !isFileUploadDisabled(DEBT_SYNDICATION) && !disabledDropDown && !totAccepted && (
                                    <div className={optionsContainer}> 
                                        {engagementLetterOptions.map((option, index) => (                               
                                            <div key={index} onClick={()=> selectEngagementLetterOption(option)} className={`${optionsBox}`}>
                                                <label htmlFor={option}>
                                                    <div className={checkboxLabel}>
                                                        {option}
                                                    </div>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    )}
                                </div>
                        </div>
                        <div className={engagementLetterFlex}>
                                {/* 
                                {totData?.[DEBT_SYNDICATION]?.totStatus === ACCEPTED && totData?.[DEBT_SYNDICATION]?.signedEngagementLetter && (
                                    <div onClick={handleDownloadEl} className={`fs10 fs-11 mt-2 ${downloadEL}`}>
                                    <img width={14} src="/assets/download-dv.svg" alt="" />
                                    <span className="ml-1">Download Signed EL</span>
                                    </div>
                                )} */}

                                {selectedEngagementLetter === "Signed EL" ? 
                                (
                                    <>
                                    <div className={`w-100 fs12 d-flex align-item-center mt-2 ${signingDateStyling} ${isDisabled(DEBT_SYNDICATION) && lowOpacity}`}>
                                        <span style={{ marginRight: '10px' }}>Signing Date </span>
                                        <input
                                        title="index"
                                        name="engagementSignDate"
                                        disabled={isDisabled(DEBT_SYNDICATION) || disabledDropDown}
                                        value={!totData?.[DEBT_SYNDICATION]?.signedEngagementLetter ? dayjs().format('YYYY-MM-DD')
                                            : dayjs(totData?.[DEBT_SYNDICATION]?.engagementSignDate).format("YYYY-MM-DD")}
                                        type='date'
                                        style={{ fontSize: '10px', padding: '3px' }}
                                        onChange={(e) => {
                                            if (!totData?.[DEBT_SYNDICATION]?.signedEngagementLetter) {
                                            showNotification('error', 'upload signed engagement letter first');
                                            } else {
                                            handleChange(DEBT_SYNDICATION, "engagementSignDate", e.target.value);
                                            }
                                        }}
                                        />
                                    </div>
                                    <div className={`${engagementLetter} ${isFileUploadDisabled(DEBT_SYNDICATION) && lowOpacity}`}>
                                        <div className="w-100 fs12">
                                        <FileUpload
                                            validateBeforeUpload={() => console.log('hello')}
                                            docTypeName=""
                                            categoryId={11}
                                            s3FolderName="engagementLetter"
                                            onSuccess={(fileMetaData) => handleChange(DEBT_SYNDICATION, "signedEngagementLetter", fileMetaData.id)}
                                            flowId={1}
                                            // docTypeId={27}
                                            disabled={isFileUploadDisabled(DEBT_SYNDICATION)}
                                            onChange={(signedUrl) => handleChange(DEBT_SYNDICATION, "signedEngagementLetter", signedUrl)}
                                            handleDownloadEl={handleDownloadEl}
                                            stageTotId={stageTotId}
                                            totData={totData}
                                            originalTotData = {originalTotData}
                                            selectedEngagementLetter={selectedEngagementLetter}
                                            setSelectedEngagementLetter={setSelectedEngagementLetter}
                                            investeeOnboardedId={investeeOnboardedId}
                                            setIsDisabledDropDown={setIsDisabledDropDown}
                                            totAccepted={totAccepted}
                                            totAcceptedValue={totAcceptedValue}
                                            setTotAccepted={setTotAccepted}
                                            isAddOn={isAddOn}
                                            addOnId={addOnId}
                                        />
                                        </div>
                                    </div>
                                    </>
                                ) : selectedEngagementLetter === "Negotiated EL" ? (
                                    <>
                                    {totAccepted && totAcceptedValue?.elUploadedType === "Negotiated EL" && (
                                        <div className={`w-100 fs12 d-flex align-item-center mt-2 ${signingDateStyling} ${isDisabled(DEBT_SYNDICATION) && lowOpacity}`}>
                                            <span style={{ marginRight: '10px' }}>Signing Date </span>
                                            <input
                                            title="index"
                                            name="engagementSignDate"
                                            value={engagementSignDate}
                                            disabled={totAccepted}
                                            type='date'
                                            style={{ fontSize: '10px', padding: '3px' }}
                                            />
                                        </div>
                                    )}
                                    <div className={`${engagementLetter} ${isFileUploadDisabled(DEBT_SYNDICATION) && lowOpacity}`}>
                                        <div className="w-100 fs12">
                                        <FileUpload
                                            validateBeforeUpload={() => console.log('hello')}
                                            docTypeName=""
                                            categoryId={11}
                                            s3FolderName="engagementLetter"
                                            onSuccess={(fileMetaData) => handleChange(DEBT_SYNDICATION, "signedEngagementLetter", fileMetaData.id)}
                                            flowId={1}
                                            // docTypeId={27}
                                            disabled={isFileUploadDisabled(DEBT_SYNDICATION)}
                                            onChange={(signedUrl) => handleChange(DEBT_SYNDICATION, "signedEngagementLetter", signedUrl)}
                                            handleDownloadEl={handleDownloadEl}
                                            stageTotId={stageTotId}
                                            totData={totData}
                                            originalTotData = {originalTotData}
                                            selectedEngagementLetter={selectedEngagementLetter}
                                            setSelectedEngagementLetter={setSelectedEngagementLetter}
                                            investeeOnboardedId={investeeOnboardedId}
                                            setIsDisabledDropDown={setIsDisabledDropDown}
                                            totAccepted={totAccepted}
                                            totAcceptedValue={totAcceptedValue}
                                            setTotAccepted={setTotAccepted}
                                            isAddOn={isAddOn}
                                            addOnId={addOnId}
                                        />
                                        </div>
                                    </div>
                                    </>
                                ): (totAccepted && totAcceptedValue?.elUploadedType === "Standard EL") ? (
                                    <>
                                    <div className={`w-100 fs12 d-flex align-item-center mt-2 ${signingDateStyling} ${isDisabled(DEBT_SYNDICATION) && lowOpacity}`}>
                                        <span style={{ marginRight: '10px' }}>Signing Date </span>
                                        <input
                                        title="index"
                                        name="engagementSignDate"
                                        value={engagementSignDate}
                                        disabled={totAccepted}
                                        type='date'
                                        style={{ fontSize: '10px', padding: '3px' }}
                                        />
                                    </div>
                                    <div className={`${engagementLetter} ${isFileUploadDisabled(DEBT_SYNDICATION) && lowOpacity}`}>
                                        <div className="w-100 fs12">
                                            <FileUpload
                                            validateBeforeUpload={() => console.log('hello')}
                                            docTypeName=""
                                            categoryId={11}
                                            s3FolderName="engagementLetter"
                                            flowId={1}
                                            // docTypeId={27}
                                            disabled={isFileUploadDisabled(DEBT_SYNDICATION)}
                                            handleDownloadEl={handleDownloadEl}
                                            stageTotId={stageTotId}
                                            totData={totData}
                                            originalTotData = {originalTotData}
                                            selectedEngagementLetter={selectedEngagementLetter}
                                            setSelectedEngagementLetter={setSelectedEngagementLetter}
                                            investeeOnboardedId={investeeOnboardedId}
                                            setIsDisabledDropDown={setIsDisabledDropDown}
                                            totAccepted={totAccepted}
                                            totAcceptedValue={totAcceptedValue}
                                            setTotAccepted={setTotAccepted}
                                            isAddOn={isAddOn}
                                            addOnId={addOnId}
                                            />
                                        </div>
                                    </div>
                                </>
                                ): null} 
                            </div>
                        </div>
                    </div>
                )}

                {currentModerateTab !== PREPARE_TERMS_TAB.DS_TERMS && (<div className={OuterCardPositionRelative}>
                    <div className={`${w_20} ${whiteCard}`}></div>
                    <div className={`${w_30} ${greyCard}`}></div>
                    <div className={`${w_30} ${blueCard}`}></div>
                    <div className={`${w_30} ${yellowCard}`}></div>
                    <div className={InnerCardPositionAbsolute}>
                        <br></br>
                        <div className='dflexJAT w-100'>
                            <div className={`fs10 fw300 mt-2 ${p_20} ${w_20} ${left}`}></div>
                            <div className={`${p_20} ${w_30} ${middle}`}>
                                <div className='fs10 fw300 text-center mb-4'><i>RECUR LIMIT</i></div>
                            </div>
                            {/* <div className={`${p_20} ${w_30} ${middle}`}>
                                <div className='fs10 fw300 text-center mb-4'><i>STANDARD LIMIT</i></div>
                            </div> */}
                            <div className={`d-flex justify-content-center align-items-start ${p_20} ${w_30}`}>
                                <input
                                    type="checkbox"
                                    className={`mr-2 ${ShowStandardLimitCheckbox}`}
                                    checked={showStandardLimit}
                                    onChange={e => isModerating && handleStandardCheckChange(e.target.checked)}
                                    disabled={isDisabled(null, 'totActive')}
                                />
                                <div className='fs10 fw300 text-center mb-4'><i>Show “STANDARD LIMIT”</i></div>
                            </div>
                            <div className={`d-flex justify-content-center align-items-start ${p_20} ${w_30}`}>
                                <input
                                    type="checkbox"
                                    className={`mr-2 ${ShowMaxLimitCheckbox}`}
                                    checked={showMaxLimit}
                                    onChange={e => isModerating && handleMaxCheckChange(e.target.checked)}
                                    disabled={isDisabled(null, 'totActive')}
                                />
                                <div className='fs10 fw300 text-center mb-4'><i>Show “MAX LIMIT”</i></div>
                            </div>
                        </div>
                        <div className='dflexJAT w-100'>
                            <div className={`fs10 fw300 mt-2 ${p_20} ${w_20} ${left}`}>Trading Limit (INR lacs)</div>
                            <div className={`${p_20} ${w_30} ${middle} ${TwoInput}`}>
                                <input
                                    type='number'
                                    required
                                    className={`w-100 noscroll ${tradingLimitInput}`}
                                    disabled={isDisabled(RECUR_LIMIT)}
                                    value={totData?.[RECUR_LIMIT]?.tradingLimit}
                                    onChange={e => handleChange(RECUR_LIMIT, "tradingLimit", e.target.value)}
                                />
                            </div>
                            <div className={`${p_20} ${w_30} ${middle} ${TwoInput}`}>
                                <input
                                    type='number'
                                    required
                                    className={`w-100 noscroll ${tradingLimitInput}`}
                                    disabled={isDisabled(STANDARD)}
                                    value={totData?.[STANDARD]?.tradingLimit}
                                    onChange={e => {
                                        handleChange(STANDARD, "tradingLimit", e.target.value)
                                        debounce(()=>handleValidation(STANDARD,"tradingLimit",e.target.value),2000)();
                                    }}
                                />
                            </div>
                            <div className={`${p_20} ${w_30} ${middle} ${TwoInput}`}>
                                <input
                                    type='number'
                                    required
                                    className={`w-100 noscroll ${tradingLimitInput}`}
                                    disabled={isDisabled(MAX)}
                                    value={totData?.[MAX]?.tradingLimit}
                                    onChange={e => {
                                        handleChange(MAX, "tradingLimit", e.target.value)
                                        debounce(()=>handleValidation(MAX,"tradingLimit",e.target.value),2000)();
                                    }}
                                />
                            </div>
                        </div>
                        <div className='dflexJAT w-100'>
                            <div className={`fs10 fw300 mt-2 ${p_20} ${w_20} ${left}`}>ROI (Price for tenure)</div>
                            <div className={`${p_20} ${w_30} ${middle} ${TwoInput}`}>
                                <div style={{position: 'relative'}}>
                                    <input
                                        type='number'
                                        required
                                        className='noscroll'
                                        disabled={isDisabled(RECUR_LIMIT)}
                                        value={totData?.[RECUR_LIMIT]?.roi}
                                        onChange={e => {
                                            handleChange(RECUR_LIMIT, "roi", e.target.value)
                                        }}
                                    />
                                </div>
                                <input
                                    type='number'
                                    className='noscroll'
                                    required
                                    disabled
                                    value={totData?.[RECUR_LIMIT]?.price ? (totData?.[RECUR_LIMIT]?.price * 100).toFixed(2) : ''}
                                />
                            </div>
                            <div className={`${p_20} ${w_30} ${middle} ${TwoInput}`}>
                                <div style={{position: 'relative'}}>
                                    <input
                                        type='number'
                                        required
                                        className='noscroll'
                                        disabled={isDisabled(STANDARD)}
                                        value={totData?.[STANDARD]?.roi}
                                        onChange={e => {
                                            handleChange(STANDARD, "roi", e.target.value)
                                            debounce(()=>handleValidation(STANDARD,"roi",e.target.value),2000)();
                                        }}
                                    />
                                    <ToggleButton disabled={isDisabled(STANDARD)}
                                                  checked={totData?.[RECUR_LIMIT]?.showRoi}
                                                  onChange={(e) => handleChange(STANDARD, "showRoi", e.target.checked)}/>
                                </div>
                                <input
                                    type='number'
                                    className='noscroll'
                                    required
                                    disabled
                                    value={totData?.[STANDARD]?.price ? (totData?.[STANDARD]?.price * 100).toFixed(2) : ''}
                                />
                            </div>
                            <div className={`${p_20} ${w_30} ${right} ${TwoInput}`}>
                                <div style={{position: 'relative'}}>
                                    <input
                                        type='number'
                                        className='noscroll'
                                        required
                                        disabled={isDisabled(MAX)}
                                        value={totData?.[MAX]?.roi}
                                        onChange={e =>{
                                            handleChange(MAX, "roi", e.target.value)
                                            debounce(()=>handleValidation(MAX,"roi",e.target.value),2000)();
                                        }}
                                    />
                                    <ToggleButton disabled={isDisabled(MAX)} checked={totData?.[RECUR_LIMIT]?.showRoi}
                                                  onChange={(e) => handleChange(MAX, "showRoi", e.target.checked)}/>
                                </div>
                                <input
                                    type='number'
                                    className='noscroll'
                                    required
                                    disabled
                                    value={totData?.[MAX]?.price ? (totData?.[MAX]?.price * 100).toFixed(2) : ''}
                                />
                            </div>
                        </div>
                        <div className='dflexJAT w-100'>
                            <div className={`fs10 fw300 mt-2 ${p_20} ${w_20} ${left}`}>Tenure (months)</div>
                            <div className={`${p_20} ${w_30} ${middle} ${TwoInput}`}>
                                <input
                                    type='number'
                                    className='noscroll'
                                    required
                                    disabled={isDisabled(RECUR_LIMIT)}
                                    value={totData?.[RECUR_LIMIT]?.minTenure}
                                    onChange={e => handleChange(RECUR_LIMIT, "minTenure", e.target.value)}
                                />
                                <input
                                    type='number'
                                    className='noscroll'
                                    required
                                    disabled={isDisabled(RECUR_LIMIT)}
                                    value={totData?.[RECUR_LIMIT]?.maxTenure}
                                    onChange={e => handleChange(RECUR_LIMIT, "maxTenure", e.target.value)}
                                />
                            </div>
                            <div className={`${p_20} ${w_30} ${middle} ${TwoInput}`}>
                                {/* <input
                                    type='number'
                                    className='noscroll'
                                    required
                                    disabled={isDisabled(STANDARD)}
                                    value={totData?.[STANDARD]?.minTenure}
                                    onChange={e => handleChange(STANDARD, "minTenure", e.target.value)}
                                /> */}
                                <input
                                    type='number'
                                    className='noscroll'
                                    required
                                    disabled={isDisabled(STANDARD)}
                                    value={totData?.[STANDARD]?.maxTenure}
                                    onChange={e => {
                                        handleChange(STANDARD, "maxTenure", e.target.value)
                                        debounce(()=>handleValidation(STANDARD,"maxTenure",e.target.value),2000)();
                                    }}
                                />
                            </div>
                            <div className={`${p_20} ${w_30} ${right} ${TwoInput}`}>
                                {/* <input
                                    type='number'
                                    className='noscroll'
                                    required
                                    disabled={isDisabled(MAX)}
                                    value={totData?.[MAX]?.minTenure}
                                    onChange={e => handleChange(MAX, "minTenure", e.target.value)}
                                /> */}
                                <input
                                    type='number'
                                    className='noscroll'
                                    required
                                    disabled={isDisabled(MAX)}
                                    value={totData?.[MAX]?.maxTenure}
                                    onChange={e => {
                                        handleChange(MAX, "maxTenure", e.target.value)
                                        debounce(()=>handleValidation(MAX,"maxTenure",e.target.value),2000)();
                                    }}
                                />
                            </div>
                        </div>
                        <div className='dflexJAT w-100'>
                            <div className={`fs10 fw300 mt-2 ${p_20} ${w_20} ${left}`}>Fee (without GST) (%)</div>
                            <div className={`${p_20} ${w_30} ${middle} ${TwoInput}`}>
                                <input
                                    type='number'
                                    className='noscroll'
                                    required
                                    disabled={isDisabled(RECUR_LIMIT)}
                                    // Take input without GST but save with GST
                                    value={totData?.[RECUR_LIMIT]?.fee ? getFeeWithoutGst(totData[RECUR_LIMIT].fee) : null}
                                    onChange={e => handleChange(RECUR_LIMIT, "fee", e.target.value)}
                                />
                            </div>
                            <div className={`${p_20} ${w_30} ${middle} ${TwoInput}`}>
                                <input
                                    type='number'
                                    className='noscroll'
                                    required
                                    disabled={isDisabled(STANDARD)}
                                    // Take input without GST but save with GST
                                    value={totData?.[STANDARD]?.fee ? getFeeWithoutGst(totData[STANDARD].fee) : null}
                                    onChange={e => {
                                        handleChange(STANDARD, "fee", e.target.value)
                                        debounce(()=>handleValidation(STANDARD,"fee",e.target.value),2000)();
                                    }}
                                />
                                <input
                                    type={'date'}
                                    className='noscroll'
                                    required
                                    disabled={isDisabled(STANDARD)}
                                    value={totData?.[RECUR_LIMIT]?.feeRes?.discountExpiryDate ? moment(totData?.[STANDARD]?.feeRes?.discountExpiryDate).format('YYYY-MM-DD') : null}
                                    onChange={e => handleChange(STANDARD, "discount_expiry_date", new Date(e.target.value).toISOString())}
                                />
                            </div>
                            <div className={`${p_20} ${w_30} ${right} ${TwoInput}`}>
                                <input
                                    type='number'
                                    className='noscroll'
                                    required
                                    disabled={isDisabled(MAX)}
                                    // Take input without GST but save with GST
                                    value={totData?.[MAX]?.fee ? getFeeWithoutGst(totData[MAX].fee) : null}
                                    onChange={e => {
                                        handleChange(MAX, "fee", e.target.value)
                                        debounce(()=>handleValidation(MAX,"fee",e.target.value),2000)();
                                    }}
                                />
                                <input
                                    type={'date'}
                                    className='noscroll'
                                    required
                                    disabled={isDisabled(MAX)}
                                    value={totData?.[RECUR_LIMIT]?.feeRes?.discountExpiryDate ? moment(totData?.[MAX]?.feeRes?.discountExpiryDate).format('YYYY-MM-DD') : null}
                                    onChange={e => handleChange(MAX, "discount_expiry_date", new Date(e.target.value).toISOString())}
                                />
                            </div>
                        </div>
                        <div className='dflexJAT w-100'>
                            <div className={`fs10 fw300 mt-2 ${p_20} ${w_20} ${left}`}>Repayment Starts</div>
                            <div className={`${p_20} ${w_30} ${middle} ${TwoInput}`}>
                                <select
                                    required
                                    name='recur-limit-morat'
                                    id='recur-limit-morat'
                                    disabled={isDisabled(RECUR_LIMIT)}
                                    value={totData?.[RECUR_LIMIT]?.moratorium_months}
                                    onChange={e => handleChange(RECUR_LIMIT, "moratorium_months", e.target.value)}
                                >
                                    <option value={0}>{moratDropdownList[0]}</option>
                                    <option
                                        value={1}>{moratDropdownList[1]}</option>
                                    <option
                                        value={2}>{moratDropdownList[2]}</option>
                                    <option
                                        value={3}>{moratDropdownList[3]}</option>
                                </select>
                            </div>
                            <div className={`${p_20} ${w_30} ${middle} ${TwoInput}`}>
                                <select
                                    required
                                    name='recur-limit-morat'
                                    id='recur-limit-morat'
                                    disabled={isDisabled(STANDARD)}
                                    value={totData?.[STANDARD]?.moratorium_months}
                                    onChange={e => {
                                        handleChange(STANDARD, "moratorium_months", e.target.value)
                                        handleValidation(STANDARD,"moratorium_months",e.target.value)
                                    }}
                                >
                                    <option value={0}>{moratDropdownList[0]}</option>
                                    <option
                                        value={1}>{moratDropdownList[1]}</option>
                                    <option
                                        value={2}>{moratDropdownList[2]}</option>
                                    <option
                                        value={3}>{moratDropdownList[3]}</option>
                                </select>
                            </div>
                            <div className={`${p_20} ${w_30} ${middle} ${TwoInput}`}>
                                <select
                                    required
                                    name='recur-limit-morat'
                                    id='recur-limit-morat'
                                    disabled={isDisabled(MAX)}
                                    value={totData?.[MAX]?.moratorium_months}
                                    onChange={e => {
                                        handleChange(MAX, "moratorium_months", e.target.value)
                                        handleValidation(MAX,'moratorium_months',e.target.value)
                                    }}
                                >
                                    <option value={0}>{moratDropdownList[0]}</option>
                                    <option
                                        value={1}>{moratDropdownList[1]}</option>
                                    <option
                                        value={2}>{moratDropdownList[2]}</option>
                                    <option
                                        value={3}>{moratDropdownList[3]}</option>
                                </select>
                            </div>
                        </div>
                        <div className='dflexJAT w-100'>
                            <div className={`fs10 fw300 mt-2 ${p_20} ${w_20} ${left}`}>Collection Method</div>
                            <div className={`${p_20} ${w_30} ${middle}`}>
                                <InputCollectionMethod
                                    data={totData?.[RECUR_LIMIT]?.collectionMethods}
                                    twoInput={TwoInput}
                                    handleChange={(keyName, keyValue) => handleChange(RECUR_LIMIT, keyName, keyValue)}
                                    isDisabled={isDisabled(RECUR_LIMIT, "collectionMethod")}
                                    userNameForCoborrower={userNameForCoborrower}
                                    setUserNameForCoborrower={setUserNameForCoborrower}
                                />
                            </div>
                            <div className={`${p_20} ${w_30} ${middle}`}>
                                <InputCollectionMethod
                                    data={totData?.[STANDARD]?.collectionMethods}
                                    twoInput={TwoInput}
                                    handleChange={(keyName, keyValue) => handleChange(STANDARD, keyName, keyValue)}
                                    isDisabled={isDisabled(STANDARD, "collectionMethod")}
                                    userNameForCoborrower={userNameForCoborrower}
                                    setUserNameForCoborrower={setUserNameForCoborrower}
                                />
                            </div>
                            <div className={`${p_20} ${w_30} ${right}`}>
                                <InputCollectionMethod
                                    data={totData?.[MAX]?.collectionMethods}
                                    twoInput={TwoInput}
                                    handleChange={(keyName, keyValue) => handleChange(MAX, keyName, keyValue)}
                                    isDisabled={isDisabled(MAX, "collectionMethod")}
                                    userNameForCoborrower={userNameForCoborrower}
                                    setUserNameForCoborrower={setUserNameForCoborrower}
                                />
                            </div>
                        </div>
                        <div className='dflexJAT w-100'>
                            <div className={`fs10 fw300 mt-2 ${p_20} ${w_20} ${left}`}>
                                Condition Precedent <br></br>
                            </div>
                            <div className={`${p_20} ${w_30} ${middle}`}>
                                {renderCP3(isDisabled(RECUR_LIMIT), RECUR_LIMIT)}
                                {renderAddMore({
                                    label: "CP",
                                    onClick: () => addMoreCP3(isDisabled(RECUR_LIMIT))
                                })}
                            </div>
                            <div className={`${p_20} ${w_30} ${middle}`}>
                                {/* {renderCP1(isDisabled(RECUR_LIMIT), STANDARD)} */}
                                {renderCP1(isDisabled(STANDARD), STANDARD)}
                                {renderAddMore({
                                    label: "CP",
                                    onClick: () => addMoreCP1(isDisabled(STANDARD))
                                })}
                            </div>
                            <div className={`${p_20} ${w_30} ${right}`}>
                                {/* {renderCP2(isDisabled(RECUR_LIMIT), MAX)} */}
                                {renderCP2(isDisabled(MAX), MAX)}
                                {renderAddMore({
                                    label: "CP",
                                    onClick: () => addMoreCP2(isDisabled(MAX))
                                })}
                            </div>
                        </div>
                        <div className='dflexJAT w-100'>
                            <div className={`fs10 fw300 mt-2 ${p_20} ${w_20} ${left}`}>
                                Condition Subsequent <br></br>
                            </div>
                            <div className={`${p_20} ${w_30} ${middle}`}>
                                {renderCS3(isDisabled(RECUR_LIMIT), RECUR_LIMIT)}
                                {renderAddMore({
                                    label: "CS",
                                    onClick: () => addMoreCS3(isDisabled(RECUR_LIMIT))
                                })}
                            </div>
                            <div className={`${p_20} ${w_30} ${middle}`}>
                                {/* {renderCS1(true, STANDARD)} */}
                                {renderCS1(isDisabled(STANDARD), STANDARD)}
                                {renderAddMore({
                                    label: "CS",
                                    onClick: () => addMoreCS1(isDisabled(STANDARD))
                                })}
                            </div>
                            <div className={`${p_20} ${w_30} ${right}`}>
                                {/* {renderCS2(true, MAX)} */}
                                {renderCS2(isDisabled(MAX), MAX)}
                                {renderAddMore({
                                    label: "CS",
                                    onClick: () => addMoreCS2(isDisabled(MAX))
                                })}
                            </div>
                        </div>
                        <div className='dflexJAT w-100'>
                            <div className={`fs10 fw300 mt-2 ${p_20} ${w_20} ${left}`}>
                                Details
                            </div>
                            <div className={`${p_20} ${w_30} ${middle}`}>
                                {renderNote3(isDisabled(RECUR_LIMIT), RECUR_LIMIT)}
                            </div>
                            <div className={`${p_20} ${w_30} ${middle}`}>
                                {renderNote1(isDisabled(STANDARD), STANDARD)}
                            </div>
                            <div className={`${p_20} ${w_30} ${right}`}>
                                {renderNote2(isDisabled(MAX), MAX)}
                            </div>
                        </div>

                    </div>
                </div>)}
            </div>
        </div>
    )
}