// todo top navigation
import React from 'react';
import styled from 'styled-components';
import styles from './Stepper.module.scss';

const {
    DownloadIconActive,
    DownloadIconNotActive,
    ProgressBar,
    LineBox,
    LineSubBox,
    SmallDone,
    SmallNotDone,
} = styles;

const LabelText = styled('span')`
    margin-bottom: -4px;
`;
const SmallInnerText = styled('div')`
    margin-left: 35px;
`;
const Circle = styled('span')`
    height: 8px;
    width: 8px;
    background-color: ${({ isGreen }) => (isGreen ? '#00BD00' : '#E9E9E9')};
    border-radius: 50%;
    margin-right: 10px;
    float: left;
`;

const getSmallTextColor = ({ blocked, isCurrent, isDone, currentNum, currentStep, isReview }) => {
    if (blocked) return '#DBDBDB';
    if (!isCurrent && !isDone && currentNum < currentStep) {
        return '#E7B316';
    }
    if (isReview) return '#4C8DFF';
    else if (isCurrent) return '#00BA00';
    return '#070707';
};
const CircleText = styled('span')`
    color: ${(props) => getSmallTextColor(props)};
    font-size: 10px;
    font-weight: 300;
    cursor: ${(props) => (props.notAllowed ? 'default' : 'pointer')};
`;
const VerticalLine = styled('div')`
    height: ${({ lineHeight }) => (lineHeight === 'small' ? '60px' : '110px')};
    width: 2.5px;
    background-color: ${({ lineColor }) => lineColor};
    margin-left: 20.5px;
`;
const getContentBigCircle = (props) => {
    const { isCurrent, currentStepContent } = props;
    if (isCurrent) return;
    if (currentStepContent === 'done') return `url("/assets/Done2.svg")`;
    if (currentStepContent === 'pending') return `url("/assets/not-done.svg")`;
    if (typeof currentStepContent === 'string') return `url(${currentStepContent})`;
    return String(currentStepContent);
};
const getBGBigCircle = (props) => {
    const { isCurrent, currentStepContent, bgColor, blockSteps } = props;
    if (blockSteps && isCurrent) return '#B4B4B4';
    if (isCurrent) return '#00BA00';
    if (currentStepContent === 'pending') return '#FFFFFF';
    if (typeof currentStepContent === 'string' && bgColor) return bgColor;
    return '#E9E9E9';
};
const styleIcon = (currentStepContent) => {
    if (
        currentStepContent !== 'done' &&
        currentStepContent !== 'pending' &&
        typeof currentStepContent === 'string'
    )
        return 'padding: 5px;';
    return null;
};
const getBorderBigCircle = (props) => {
    const { blockSteps, isCurrent } = props;
    if (blockSteps) return 'border: 9px solid #E3E3E3;';
    if (isCurrent) return 'border: 9px solid #D7F3CA;';
    return null;
};
const getBoxShadowBigCircle = (props) => {
    const { blockSteps, isCurrent } = props;
    if (blockSteps) return null;
    if (isCurrent) return 'box-shadow:  0px 0px 3px 3px  #E1F3D9;';
    return null;
};
const BigCircle = styled('div')`
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    height: ${({ isCurrent }) => (isCurrent ? '43px' : '24px')};
    width: ${({ isCurrent }) => (isCurrent ? '43px' : '24px')};
    background-color: ${(props) => getBGBigCircle(props)};
    color: ${({ isCurrent }) => (isCurrent ? '#FFFFFF' : '#7F7F7F')};
    ${(props) => getBorderBigCircle(props)}
    ${(props) => getBoxShadowBigCircle(props)}
    border-radius: 50%;
    content: ${(props) => getContentBigCircle(props)};
    ${({ currentStepContent }) => styleIcon(currentStepContent)}
    margin-top: ${({ isCurrent }) => (isCurrent ? '-31px' : '-20px')};
    margin-left: ${({ isCurrent }) => (isCurrent ? '0px' : '9px')};
    transition: background 0.5s;
    transition: box-shadow 0.8s;
`;
const getTextColor = ({ isCurrent, textColor, blocked, currentStepContent, blockSteps }) => {
    if (blockSteps) return '#000';
    if (blocked) return '#DBDBDB';
    if (currentStepContent === 'pending') return '#E7B316';
    if (textColor) return textColor;
    if (isCurrent) return '#00BA00';
    return '#070707';
};
const getFontSize = ({ blockSteps, isCurrent, isLimitSigningCard }) => {
    if (isLimitSigningCard) {
        return !blockSteps && isCurrent ? '14px' : '12px';
    }
    return !blockSteps && isCurrent ? '14px' : '14px';
};
const ProgressBarLi = styled('li')`
    margin-left: 60px;
    list-style: none;
    color: ${(props) => getTextColor(props)};
    font-size: ${(props) => getFontSize(props)};
    font-family: ${({ isCurrent }) => (isCurrent ? 'Gilroy-SemiBold' : 'Gilroy-Medium')};
    ${({ isLimitSigningCard }) => (isLimitSigningCard ? 'font-family: Gilroy-Medium;' : null)};
    margin-top: -10px;
    display: flex;
    align-items: center;
`;

export default function Stepper({
    steps,
    currentStep,
    setCurrentStep,
    currentStepRef,
    lineHeight,
    isLimitSigningCard,
    blockSteps,
}) {
    const handleClick = (val, subVal, hasSubParts, blocked, blockWithNormalStyle) => {
        if (!blockSteps && !blocked && !blockWithNormalStyle) {
            if (subVal) {
                val = val + subVal / 10;
            }
            if (hasSubParts) {
                val += 0.1;
            }
            currentStepRef.current = currentStep;
            setCurrentStep(val);
        }
    };

    const getLineColor = (currentNum, currentStepContent) => {
        if (currentStepContent === 'pending') return '#E7B316';
        if (currentNum < Math.floor(currentStep)) {
            return '#00BD00';
        }
        return '#E9E9E9';
    };

    const getCurrentStepContent = (currentNum, check, iconPath, blockSteps) => {
        let content;
        if (blockSteps) return currentNum;
        if (currentNum < Math.floor(currentStep)) {
            if (check) content = 'done';
            else content = 'pending';
        } else {
            if (iconPath) content = iconPath;
            else content = currentNum;
        }
        return content;
    };

    const getSmallCircle = (currentNum, check) => {
        if (check) {
            return (
                <img
                    className={SmallDone}
                    src="/assets/s-done.svg"
                    alt=""
                    style={{ width: '8px' }}
                />
            );
        } else {
            if (currentNum < currentStep) {
                return (
                    <img
                        className={SmallNotDone}
                        src="/assets/s-not-done.svg"
                        alt=""
                        style={{ width: '8px' }}
                    />
                );
            }
            return <Circle check={check} isGreen={currentStep === currentNum} />;
        }
    };

    return (
        <ul className={ProgressBar}>
            {steps.map((item, index) => {
                let label;
                let onImgClick;
                let isDownloadingFile;
                let subParts;
                let mainCheck;
                let textColor;
                let iconPath;
                let bgColor;
                let blocked;
                let blockWithNormalStyle;
                if (typeof item === 'object') {
                    label = item.label;
                    onImgClick = item.onImgClick;
                    isDownloadingFile = item.isDownloadingFile;
                    blocked = item.block;
                    blockWithNormalStyle = item.blockWithNormalStyle;
                    mainCheck = item.check;
                    if (item.subParts) subParts = item.subParts;
                    if (item.iconPath) {
                        iconPath = item.iconPath;
                        bgColor = item.bgColor;
                    }
                } else label = item;
                const currentNum = index + 1;
                const isCurrent = Math.floor(currentStep) === currentNum;
                const currentStepContent = getCurrentStepContent(
                    currentNum,
                    mainCheck,
                    iconPath,
                    blockSteps,
                );
                return (
                    <React.Fragment key={index}>
                        <span
                            key={index}
                            style={
                                !blockSteps && !blocked && !blockWithNormalStyle
                                    ? { cursor: 'pointer', height: '43px' }
                                    : { cursor: 'default', height: '43px' }
                            }
                            onClick={() =>
                                handleClick(
                                    currentNum,
                                    null,
                                    subParts,
                                    blocked,
                                    blockWithNormalStyle,
                                )
                            }
                        >
                            <ProgressBarLi
                                label={label}
                                isLimitSigningCard={isLimitSigningCard}
                                isCurrent={isCurrent}
                                currentStep={currentStep}
                                className={isCurrent ? 'active' : null}
                                textColor={textColor}
                                sanct
                                blocked={blocked}
                                currentStepContent={currentStepContent}
                                blockSteps={blockSteps}
                                style={
                                    !blockSteps && !blocked && !blockWithNormalStyle
                                        ? { cursor: 'pointer' }
                                        : { cursor: 'default' }
                                }
                                onClick={() =>
                                    handleClick(
                                        currentNum,
                                        null,
                                        subParts,
                                        blocked,
                                        blockWithNormalStyle,
                                    )
                                }
                            >
                                <LabelText>{label}</LabelText>
                                {isLimitSigningCard &&
                                    (isDownloadingFile ? (
                                        <div id="loading-spinner" style={{ marginLeft: '15px' }}>
                                            <div
                                                className="spin-icon"
                                                style={{ width: '20px', height: '20px' }}
                                            />
                                        </div>
                                    ) : (
                                        <span
                                            onClick={onImgClick}
                                            className={
                                                blockSteps
                                                    ? DownloadIconNotActive
                                                    : DownloadIconActive
                                            }
                                        >
                                            <img
                                                src={'/assets/new-download.svg'}
                                                alt="download-icon"
                                            />
                                        </span>
                                    ))}
                            </ProgressBarLi>
                            <BigCircle
                                isCurrent={isCurrent}
                                currentStepContent={currentStepContent}
                                bgColor={bgColor}
                                blockSteps={blockSteps}
                                style={
                                    !blockSteps && !blocked && !blockWithNormalStyle
                                        ? { cursor: 'pointer' }
                                        : { cursor: 'default' }
                                }
                                onClick={() =>
                                    handleClick(
                                        currentNum,
                                        null,
                                        subParts,
                                        blocked,
                                        blockWithNormalStyle,
                                    )
                                }
                            >
                                {(typeof currentStepContent === 'number' || iconPath) && currentNum}
                            </BigCircle>
                        </span>
                        <div className={LineBox}>
                            <VerticalLine
                                lineHeight={lineHeight}
                                lineColor={getLineColor(currentNum, currentStepContent)}
                            />
                            {subParts && (
                                <SmallInnerText>
                                    {subParts.map((i, idx) => {
                                        const pureCurrentNum = currentNum + (idx + 1) / 10;
                                        return (
                                            <div className={LineSubBox} key={idx}>
                                                {getSmallCircle(pureCurrentNum, i.check)}
                                                <CircleText
                                                    isDone={i.check}
                                                    blocked={blocked}
                                                    currentNum={pureCurrentNum}
                                                    currentStep={currentStep}
                                                    currentStepContent={currentStepContent}
                                                    isCurrent={pureCurrentNum === currentStep}
                                                    onClick={() =>
                                                        handleClick(
                                                            currentNum,
                                                            idx + 1,
                                                            null,
                                                            blocked,
                                                        )
                                                    }
                                                    notAllowed={blocked}
                                                >
                                                    {i.name}
                                                </CircleText>
                                            </div>
                                        );
                                    })}
                                </SmallInnerText>
                            )}
                        </div>
                    </React.Fragment>
                );
            })}
        </ul>
    );
}
