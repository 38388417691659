import { isNil } from "lodash";
import React, { useState } from "react";
import { covertToNum } from "../../../../util/utility";
// import GenerateCollectionLinkModal from "./components/GenerateCollectionLinkModal/GenerateCollectionLinkModal";
import styles from "./components/NachCollectionsTableRow.module.scss";

const CollectionsProgressBar = ({
  greyedOutPrec,
  greenOutPrec,
  lightGreenOutPrec,
  collectionAmount,
  neftAmount,
  // nach_details,
  collectedAmount,
  successRate,
  investeeOrgId,
  isFnnDateCrossed,
}) => {
  const { ProgressBottom, ProgressTop, greenText, redText } = styles;
  const totalWidth = 260;
  const calcGreyedOutWidthPerc = totalWidth * greyedOutPrec;
  const calcLightGreenOutWidthPerc = totalWidth * lightGreenOutPrec;
  const calcGreenOutWidthPerc = totalWidth * greenOutPrec;
  
  const Parentdiv = {
    height: "10px",
    width: `${totalWidth}px`,
    background: isFnnDateCrossed ? "repeating-linear-gradient(-45deg,#D80100,#D80100 4px,#000000 6px)" : "#D80100",
    borderRadius: "40px",
    overflow: "hidden"
  };

  const ChildGreyDiv = {
    backgroundColor: "#EEEEED",
    width: `${calcGreyedOutWidthPerc + calcGreenOutWidthPerc}px`,
    height: "10px",
    borderRadius: "40px",
    display: 'flex'
  };

  const ChildGreenDiv = {
    backgroundColor: "#03AA00",
    width: `${calcGreenOutWidthPerc}px`,
    height: "10px",
    borderRadius: "40px",
  };

  const ChildLightGreenDiv = {
    backgroundColor: "#DDFFDD",
    width: `${calcGreenOutWidthPerc + calcLightGreenOutWidthPerc}px`,
    height: "10px",
    borderRadius: "40px"
  };

  // const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <div className={ProgressTop}>
        <span>
          <span className={greenText}>₹ {collectedAmount}</span>/ ₹{" "}
          {collectionAmount}
        </span>
          <div className="d-flex">
            <span className={`pr-2 ${redText}`}>{isFnnDateCrossed ? (<del>₹ {neftAmount}</del>) : (`₹ ${neftAmount}`)} NEFT</span>
            {/* <div className="cp" onClick={() => setShowModal(true)}>
              <img className="mb-1" src="/assets/link.svg" width="10px" alt="collection link"/>
            </div> */}
          </div>
      </div>
      <div style={Parentdiv}>
        <div className="grey_bar" style={ChildGreyDiv}>
          <div className="light_green" style={ChildLightGreenDiv}>
          {covertToNum(collectedAmount) > 0 && <div className="green_bar" style={ChildGreenDiv}></div>}
          </div>
        </div>
      </div>
      {!isNil(successRate) && <div className={ProgressBottom}>
        {successRate}% Success{/*  ({successRate}) */}
      </div>}
      {/* {showModal && (
        <GenerateCollectionLinkModal
          showModal
          closeModal={() => setShowModal(false)}
          investeeOrgId={investeeOrgId}
          neftAmount={neftAmount}
        />
      )} */}
    </div>
  );
};

export default CollectionsProgressBar;
