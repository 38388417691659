import {
    getValueOr_,
    getValueWithRsOr_,
    showNotification,
} from "../../../../../../../../../../../util/utility";
import SetupFrequencyModal from "./SetupFrequencyModal";
import styles from "../../PgIntegrations/PgIntegration.module.scss";
import { useEffect, useRef, useState } from "react";
import { PG_CONFIG_STATUS, PG_CONFIG_TYPE } from "../../../../../../../../../../../constants/enums";
import callApi from "../../../../../../../../../../../util/apiCaller";
import dayjs from "dayjs";
import { isEmpty, isNaN, isNil } from "lodash";
import { useDispatch } from "react-redux";
import { fetchConnectedPGList } from "../../../../../../../../../AdminActions";

const { pointerCursor, pg_integration__container, account_id, mr10 } = styles;

export default function PgIntegrationRow({ index, pg, handleEditClick, investeeOrgId }) {
    const dispatch = useDispatch();
    const isFirstRender = useRef(true);
    const endOfMonth = dayjs().daysInMonth();
    const {
        dateConnected,
        accountId,
        pgName,
        pgCollectionAmount,
        recurCollectionAmount,
        status,
        pgConfigStatus,
        pgConfig,
        pgId
    } = pg;
    const [type, setType] = useState(pgConfig?.pgConfigType ? pgConfig?.pgConfigType : PG_CONFIG_TYPE.FIXED_AMOUNT_DAILY);
    const [fixedAmountDaily, setFixedAmountDaily] = useState({
        startDay: pgConfig?.startDay ? Number(pgConfig?.startDay) : 1,
        endDay: pgConfig?.endDay ? Number(pgConfig?.endDay) : 1,
        collectionAmount: Number(pgConfig?.collectionAmount),
    });

    const [show, setShow] = useState(false);
    const handleClose = () => {
        // debugger
        // Fetch updated connection/all data
        dispatch(fetchConnectedPGList(investeeOrgId));
        
        // Set modal show: false
        setShow(false);
    }
    
    const handleShow = () => {
        setShow(true);
    }

    const [pgConfigStatusState, setPgConfigStatusState] = useState(pgConfigStatus || PG_CONFIG_STATUS.INACTIVE);
    const handleSwitchChange = (e) => {
        if (isEmpty(pgConfig)) {
            showNotification("Error", "Please set pg config, before making it active");
            return;
        }
        if (e.target.checked) setPgConfigStatusState(PG_CONFIG_STATUS.ACTIVE);
        else setPgConfigStatusState(PG_CONFIG_STATUS.INACTIVE);
    };

    const setupFreqClick = async (pgId, type, fixedAmountDaily) => {
        const validationRes = validateSetConfigInput(
            pgId,
            type,
            fixedAmountDaily
        );
        if (!validationRes.isValid) {
            const message = validationRes?.message
                ? validationRes?.message
                : "Some error occured";
            showNotification("Error", message);
            handleShow(true);
            return;
        }
        const res = await callApi(
            "connection/set-config",
            "post",
            { pgConfig: validationRes.body },
            {},
            false,
            false,
            true
        );
        if (res?.responseData?.responseCode === 20) {
            handleClose();
            showNotification("Success", "PGConfig updated");
        } else {
            const message =
                res?.responseData?.responseMessage ?? "Some error occurred";
            showNotification("Error", message);
        }
    };

    const validateSetConfigInput = (pgId, configType, fixedAmountDaily) => {
        let isValid = true;
        let message = "";
        let body = {
            pgConfigType: configType,
        };
        // debugger
        switch (configType) {
            case PG_CONFIG_TYPE.FIXED_AMOUNT_DAILY:
                if (isEmpty(type)) {
                    isValid = false;
                    message = "Please select config type";
                } else if (isNaN(fixedAmountDaily.startDay) || (fixedAmountDaily.startDay) < 1) {
                    isValid = false;
                    message = "Start day cannot be less than 1";
                } else if (isNaN(fixedAmountDaily.endDay) || (fixedAmountDaily.endDay) > endOfMonth) {
                    isValid = false;
                    message = "End day cannot be greater than endof month";
                } else if (isNaN(fixedAmountDaily.startDay) || isNaN(fixedAmountDaily.endDay) ||
                    (fixedAmountDaily.startDay) > (fixedAmountDaily.endDay)
                ) {
                    isValid = false;
                    message = "Start day cannot be greater than end day";
                } else if (isNaN(fixedAmountDaily.collectionAmount) || (fixedAmountDaily.collectionAmount) <= 0) {
                    isValid = false;
                    message = "Please select collectionAmount greater than 0";
                } else {
                    body.pgConfigStatus = pgConfigStatusState;
                    body.collectionAmount = (fixedAmountDaily?.collectionAmount);
                    body.pgId = pgId;
                    body.startDay = (fixedAmountDaily?.startDay);
                    body.endDay = (fixedAmountDaily?.endDay);
                    body.requestId = `${pgId}${+new Date()}`;
                }
        }
        return {
            isValid,
            message,
            body,
        };
    };

    const handleChange = (key, value, type) => {
        if (isEmpty(type)) {
            showNotification("Error", "Please select config type");
        }
        switch (type) {
            case PG_CONFIG_TYPE.FIXED_AMOUNT_DAILY:
                const obj = { ...fixedAmountDaily };
                obj[key] = value;
                setFixedAmountDaily(obj);
                return;
        }
    };

    const updateStateBasedOnValueChange = (key, value) => {
        if (key === "type") {
            setType(value);
        }
    };

    useEffect(() => {
        if (!isFirstRender.current) {
            // debugger
            setupFreqClick(pgId, type, fixedAmountDaily);
        } else {
            isFirstRender.current = false;
        }
        
    }, [pgConfigStatusState]);

    useEffect(() => {
        // Reset states back to status in db
        const obj = {};
        obj.startDay = pgConfig?.startDay ? Number(pgConfig?.startDay) : 1;
        obj.endDay = pgConfig?.endDay ? Number(pgConfig?.endDay) : 1;
        obj.collectionAmount = Number(pgConfig?.collectionAmount);
        setFixedAmountDaily(obj);
        setType(pgConfig?.pgConfigType ? pgConfig?.pgConfigType : PG_CONFIG_TYPE.FIXED_AMOUNT_DAILY);
    }, [pg]);
    
    return (
        <tr key={index} className={pg_integration__container}>
            <td>{getValueOr_(dateConnected)}</td>
            <td className={account_id}>
                <div className={mr10}>{getValueOr_(accountId)}</div>
                
                <span className={(status === "IN ERROR" ? styles["IN_ERROR"] : styles[status])}>{getValueOr_(status)}</span>
            </td>
            <td>
                <span className={styles[pgName]}>{getValueOr_(pgName)}</span>
            </td>
            <td>{getValueWithRsOr_(pgCollectionAmount)}</td>
            <td>
                <b>{getValueWithRsOr_(recurCollectionAmount)}</b>
            </td>
            <td>
                <SetupFrequencyModal
                    pgId={pgId}
                    pgConfig={pgConfig}
                    type={type}
                    fixedAmountDaily={fixedAmountDaily}
                    handleChange={handleChange}
                    updateStateBasedOnValueChange={updateStateBasedOnValueChange}
                    setupFreqClick={setupFreqClick}
                    show={show}
                    handleClose={handleClose}
                    handleShow={handleShow}
                    endOfMonth={endOfMonth}
                    disabledState={pgConfig != null}
                />
            </td>
            <td className="CustomRadioButton">
                <label class="switch">
                    <input type="checkbox" checked={pgConfigStatusState===PG_CONFIG_STATUS.ACTIVE} onChange={(e) => handleSwitchChange(e)} />
                    <span class="slider round"></span>
                </label>
            </td>
            <td className={pointerCursor} onClick={() => handleEditClick(pg)}>
                <img src="/assets/editIcon.svg" alt="" />
            </td>
        </tr>
    );
}
