import React, {Component} from 'react';
import TableHeader from './components/TableHeader';
import TableRow from './components/TableRow';
import AdminHead from '../../Admin/components/AdminHead.js'
import callApi from "../../../util/apiCaller";
import {showNotification} from "../../../util/utility";
import {Button, Modal} from "react-bootstrap";
import _ from 'lodash';

class SiteContentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contents: [],
            pageNum: 1,
            pageSize: 25,
            totalCount: 0,
            qtext: '',
            editItem: {}
        }
    }

    componentDidMount() {
        this.fetchItems();
    }

    fetchItems = () => {
        let filters = this.getFilters();
        callApi(`contents/list`, "post", filters).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    contents: res.data.contents,
                    totalCount: res.data.totalCount
                })
            } else {
                showNotification("error", "Some error occured");
            }
        });
    }

    getFilters = () => {
        let filters = {};
        filters.pageNum = this.state.pageNum;
        filters.pageSize = this.state.pageSize;
        return filters;
    };

    handleApplyFilters = () => {
        this.fetchItems();
    };

    handleSearchFilter = (obj) => {
        let searchObj = {};
        searchObj[this.keyStateObjNameDict[obj.keyName]] = obj
        this.setState(searchObj, () => {
            this.handleApplyFilters();
        })
    };

    inputSelectorHandler = (obj) => {
        let selectorObj = {};
        selectorObj[this.keyStateObjNameDict[obj.keyName]] = obj
        this.setState(
            selectorObj, () => {
                this.handleApplyFilters();
            })
    }

    handleChange = (keyname, keyvalue) => {
        let editItem = this.state.editItem;
        editItem[keyname] = keyvalue;
        this.setState({
            editItem: editItem
        })
    };

    saveTemplate = () => {
        let editItem = this.state.editItem;
        this.setState({
            isSaving: true
        });
        if (editItem._id) {
            callApi(`contents/${editItem._id}/data-update`, "post", {
                content: editItem
            }).then((res) => {
                this.setState({
                    isSaving: false
                });
                if (res.status === 'Success') {
                    showNotification("success", "Content Updated");
                } else {
                    showNotification("error", "Some error occured");
                }
            });
        } else {
            callApi(`contents/new`, "post", {
                content: editItem
            }).then((res) => {
                this.setState({
                    isSaving: false
                });
                if (res.status === 'Success') {
                    this.fetchItems();
                    this.toggleEditItem({});
                    showNotification("success", "Content Added");
                } else {
                    showNotification("error", "Some error occured");
                }
            });
        }
    };

    toggleEditItem = (editItem) => {
        this.setState({
            editItem: editItem
        })
    };

    handleClose = () => {
        this.toggleEditItem({});
    }

    addParam = () => {
        let editItem = this.state.editItem;
        if (editItem.params) {
            editItem.params.push({
                label: "",
                value: ""
            })
        } else {
            editItem.params = [];
            editItem.params.push({
                label: "",
                value: ""
            })
        }
        this.setState({
            editItem: editItem
        })
    };

    handleParamChange = (index, keyName, keyValue) => {
        let editItem = this.state.editItem;
        editItem.params = editItem.params.map((item, i) => {
            if (i === index) {
                item[keyName] = keyValue;
            }
            return item;
        });
        this.setState({
            editItem: editItem
        })
    };

    removeParam = (index) => {
        let editItem = this.state.editItem;
        editItem.params = editItem.params.filter((item, i) => i !== index);
        this.setState({
            editItem: editItem
        })
    };

    render() {
        return (
            <div style={{width: '100%'}}>
                <AdminHead/>
                <section id="admin-company">
                    <div className='d-flex justify-content-between form-group has-search'>
                        <button
                            onClick={() => this.toggleEditItem({name: "", code: "", params: [{label: "", value: ""}]})}
                            className='admin-company-btn'>
                            <img src='../../../../../assets/plus.ico' alt=''></img>
                            Add New
                        </button>
                    </div>
                    <div className="request-data-table tableFixHead">
                        <table className="table team">
                            <TableHeader/>
                            <tbody className="team-body">
                            {this.state.contents && this.state.contents.map(item => (
                                <TableRow
                                    item={item}
                                    toggleEditItem={this.toggleEditItem}
                                />
                            ))}
                            </tbody>
                        </table>
                    </div>
                </section>
                {!_.isEmpty(this.state.editItem) ?
                    <Modal
                        show={!_.isEmpty(this.state.editItem)}
                        onHide={this.handleClose}
                        size='lg'
                        aria-labelledby='contained-modal-title-vcenter'
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Add/Edit Content</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Code</label>
                                    <input type="text" className="form-control"
                                           aria-describedby="emailHelp" placeholder="Enter Code"
                                           value={this.state.editItem.code}
                                           onChange={(e) => this.handleChange('code', e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Name</label>
                                    <input type="text" className="form-control"
                                           aria-describedby="emailHelp" placeholder="Enter Name"
                                           value={this.state.editItem.name}
                                           onChange={(e) => this.handleChange('name', e.target.value)}
                                    />
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <Button onClick={() => this.addParam()}>
                                            Add Value
                                        </Button>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Label</label>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Value</label>
                                                </div>
                                            </div>
                                            {this.state.editItem && this.state.editItem.params && this.state.editItem.params.map((paramItem, index) => (
                                                <div className="row" key={index}>
                                                    <div className="col-lg-5">
                                                        <input type="text" className="form-control"
                                                               aria-describedby="emailHelp" placeholder="Enter Name"
                                                               value={paramItem.label}
                                                               onChange={(e) => this.handleParamChange(index, 'label', e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-lg-5">
                                                        <input type="text" className="form-control"
                                                               aria-describedby="emailHelp" placeholder="Enter Name"
                                                               value={paramItem.value}
                                                               onChange={(e) => this.handleParamChange(index, 'value', e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <a onClick={() => this.removeParam(index)}> <i className="fa fa-trash"></i></a>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant='secondary' onClick={() => this.handleClose()}>
                                Close
                            </Button>
                            <Button
                                variant='primary'
                                disabled={this.state.isSaving}
                                onClick={() => this.saveTemplate()}>
                                {this.state.isSaving ? "Saving" : "Save"}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    :
                    null
                }
            </div>
        );
    }
}

export default SiteContentList;
