import React, { useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import _, { entries } from "lodash";
import { calculateTerm } from "../../../../../util/calculateTermUtil";

function ContractsTableRow(props) {
  const observer = useRef();
  const lastContractRef = useCallback(
    (node) => {
      if (props.loader) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && props.hasMore()) props.loadMore();
      });
      if (node) observer.current.observe(node);
    },
    [props.loader, props.hasMore()]
  );

  const renderer = (
    <>
      <td style={{width:'2%'}}>
        <input
          type='checkbox'
          value={props.item._id}
          style={{ marginLeft: "5px", marginRight: "10px" }}
          checked={props.selected_contract_ids.some(
            (i) => i === props.item._id
          )}
          onChange={props.onChangeContractCheckbox.bind(this)}
        />
      </td>
      <td style={{width:'7%'}}>
        <span
          style={{ color: "#037BFF" }}
          onClick={() =>
            props.handleSegmentChange("contractDetail", props.item)
          }
          className='blue-table-cel'>
          {props.item.contract_id_num}
        </span>
      </td>
      <td className='text-truncate' style={{ maxWidth: "60px",width:'9%' }}>
        <span
          data-bs-toggle='tooltip'
          data-bs-html='true'
          title={props.item.investee_organization_id.name}>
          {props.item.investee_organization_id.name}
        </span>
      </td>
      <td className='text-truncate pr-3' style={{ maxWidth: "60px",width:'7%' }}>
        <span
          data-bs-toggle='tooltip'
          data-bs-html='true'
          title={props.item.name}>
          {props.item.name}
        </span>
      </td>
      <td style={{width:'8%'}}>{moment(props.item.contract_start_date).format("DD MMMM YYYY")}</td>
      <td style={{width:'8%'}}>{moment(props.item.contract_end_date).format("DD MMMM YYYY")}</td>
      <td style={{width:'7%'}}>{props.item.contract_term_value_words}</td>
      <td style={{width:'7%'}}>
        ₹ {props.item.payments}
        {props.item.recurring_payment_value} / {props.item.contract_term_unit}
      </td>
      <td style={{width:'6%'}}></td>
      <td className='blue-table-cel' style={{width:'7%'}}>{props.item.requested_by}</td>
      <td style={{width:'7%'}}>{moment(props.item.created_at).format("DD MMMM YYYY")}</td>
      <td  className={`bold-table-cel status-${_.kebabCase(
          props.item.approval_status
        )}-table-cel`}  style={{width:'5%'}}>
          {
         props.item.approval_status === 'Approved' && props.item.status !== 'Uploaded' ? (
          props.item.status
         ):props.item.approval_status
      }
      </td>
      <td style={{width:'6%'}}>{props.item.admin_user}</td>
      <td style={{width:'7%'}}>{moment(props.item.updated_at).format("DD MMMM YYYY")}</td>
      <td style={{width:'7%'}}></td>
    </>
  );

  if (props.dataLength === props.index + 1) {
    return <tr ref={lastContractRef}>{renderer}</tr>;
  } else {
    return <tr>{renderer}</tr>;
  }
}

export default ContractsTableRow;
