import React, { Component } from "react";
// import {Link} from 'react-router-dom';
import TradesTableHeader from "../TradesPage/components/TradesTableHeader";
import TradesTableRow from "../TradesPage/components/TradesTableRow";
import OrdersTableHeader from "../TradesPage/components/OrdersTableHeader";
import OrdersTableRow from "../TradesPage/components/OrdersTableRow";
import AdminHead from "../../components/AdminHead.js";
import callApi from "../../../../util/apiCaller";
import { isEnvProd, mixPanelTrack, showNotification } from "../../../../util/utility";
import _ from "lodash";
import { mergeByProperty } from "../../../../util/calculateTermUtil";
import { getCheckIfUserHasRelevantPermissions, getUserName } from "../../../App/AppReducer";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { SanctionNotApprovedModal } from "../CompaniesViewPage/components/CompaniesDetailPage/components/Drawdown/components/SanctionNotApprovedModal";

const statusOptions = ['Approved', 'Open', 'Not Approved', 'On Hold'];
const APPROVED = "Approved";
const APPROVED_WP = "Approved-without-payment";
class TradesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trades: [],
      orders: [],
      is_fetching_trades: false,
      is_fetching_orders: false,
      is_marking_contract_status: false,
      selected_trades_ids: [],
      selected_order_ids: [],
      pageNum: 1,
      pageSize: 200,
      pageNumOrders: 1,
      pageSizeOrders: 200,
      totalCount: 0,
      totalCountOrders: 0,
      qtext: "",
      isFiltering: false,
      viewMode: 'orders',
      sanctionNotApprovedModal: false,
      approval_status: "",
      // searchObjInvestorOrg: {
      //   keyName: "contract_name",
      //   value: "",
      // },
      // searchObjInvestorUser: {
      //   keyName: "contract_id",
      //   value: "",
      // },
      // searchObjContractID: {
      //   keyName: "investor_name",
      //   value: "",
      // },
      // searchObjAdminUser: {
      //   keyName: "investor_name",
      //   value: "",
      // },
      // sliderObjPaymentsTerm: {
      //   minVal: 0,
      //   maxVal: 12,
      //   currentMax: 12,
      //   currentMin: 0,
      //   keyName: "contract_term_value",
      //   minValueBetween: 1,
      // },
      // sliderObjPayments: {
      //   minVal: 0,
      //   maxVal: 100,
      //   currentMax: 70,
      //   currentMin: 20,
      //   keyName: "gross_cashflow",
      //   minValueBetween: 10,
      // },
      // sliderObjInvestment: {
      //   minVal: 0,
      //   maxVal: 100,
      //   currentMax: 70,
      //   currentMin: 20,
      //   keyName: "gross_cashflow",
      //   minValueBetween: 10,
      // },
      // dateObjDateRequested: {
      //   date: [new Date(), new Date()],
      //   keyName: "payment_to_investor_date",
      // },
      // dateObjLastUpdated: {
      //   date: [new Date(), new Date()],
      //   keyName: "payment_to_investor_date",
      // },
      repaymentStartDateObj: {
        keyName: "first_repayment",
        date: ['', '']
      },
      selectorObjStatus: {
        keyName: "trade_approval_status",
        value: statusOptions
      },
    };
  }

  keyStateObjNameDict = {
    contract_term_value: "sliderObjArr",
    recurring_payment_value: "sliderObjPrice",
    disbursed: "sliderObjTradeLimit",
    contract_price: "sliderObjPrice",
    payout: "sliderObjTradedArr",
    fees: "sliderObjFees",
    contract_id: "searchObjContractID",
    contract_trade_id: "searchObjTradeID",
    first_repayment: "repaymentStartDateObj",
    trade_approval_status: "selectorObjStatus",
    type: "selectorObjType",
  };

  componentDidMount() {
    this.setState({ isFiltering: false });
    this.fetchOrders();
  }

  fetchTrades = (isFiltering = false) => {
    this.setState({ is_fetching_trades: true });
    let filters = this.getFilters();
    callApi(`admin/trade/list`, "post", filters).then((res) => {
      // console.log(res);
      if (res.status === "Succes") {
        // console.log("TRADE_COUNT ", res.data.trade.count);
        let trades = this.state.trades;
        if (isFiltering) {
          this.setState({
            trades: res.data.trade.contract,
            totalCount: res.data.trade.count,
          })
        }
        else {
          this.setState({
            trades: mergeByProperty(trades, res.data.trade.contract, "_id"),
            totalCount: res.data.trade.count,
          });
        }
      } else {
        showNotification("error", "Some error occured");
      }
      this.setState({ is_fetching_trades: false });
    });
  };

  fetchTradesByOrderId = (orderId, isFiltering = false) => {
    this.setState({ is_fetching_trades: true });
    let filters = this.getFilters();
    callApi(`admin/order/${orderId}/get-trades-by-order`, "post", filters).then((res) => {
      // console.log(res);
      if (res.status === "Success") {
        // console.log("TRADE_COUNT ", res.data.trade.count);
        // let trades = this.state.trades;
        // if (isFiltering) {
        this.setState({
          trades: res.data.trades,
          totalCount: res.data.count,
        })
        // }
        // else {
        //   this.setState({
        //     trades: mergeByProperty(trades, res.data.trades, "_id"),
        //     totalCount: res.data.count,
        //   });
        // }
      } else {
        showNotification("error", "Some error occured");
      }
      this.setState({ is_fetching_trades: false });
    });
  }

  fetchOrders = (isFiltering = false) => {
    this.setState({ is_fetching_orders: true });
    let filters = this.getFilters();
    callApi(`admin/orders/order-list`, "post", filters).then((res) => {
      // console.log(res);
      if (res.status === "Success") {
        // console.log("TRADE_COUNT ", res.data.trade.count);
        let orders = this.state.orders;
        if (isFiltering) {
          this.setState({
            orders: res.data.orders,
            totalCountOrders: res.data.count,
          })
        }
        else {
          this.setState({
            orders: mergeByProperty(orders, res.data.orders, "_id"),
            totalCount: res.data.count,
          });
        }
      } else {
        showNotification("error", "Some error occured");
      }
      this.setState({ is_fetching_orders: false });
    });
  };

  hasMore = () => {
    // console.log("HAS-MORE ", this.state.totalCount, this.state.pageSize, this.state.pageNum);
    if (this.state.viewMode === 'trades') {
      return this.state.totalCount + 1 > this.state.pageSize * this.state.pageNum;
    } else {
      return this.state.totalCountOrders + 1 > this.state.pageSizeOrders * this.state.pageNumOrders;
    }

  };

  loadMore = () => {
    if (this.state.viewMode === 'trades') {
      this.setState({ pageNum: this.state.pageNum + 1, isFiltering: false });
      this.fetchTrades();
    } else {
      this.setState({ pageNumOrders: this.state.pageNumOrders + 1, isFiltering: false });
      this.fetchOrders();
    }
  };

  getFilters = () => {
    const { selectorObjStatus, repaymentStartDateObj } = this.state;
    let filters = {};
    filters.pageNum = this.state.pageNum;
    filters.pageSize = this.state.pageSize;
    if (this.state.qtext) {
      filters.isSearching = true;
      filters.qtext = this.state.qtext;
    }
    filters.isFiltering = true;
    filters.isSearching = true;
    // filters.sort_by = ''
    // filters[this.state.searchObjCustomer.keyName] = this.state.searchObjCustomer.value;
    // filters[this.state.searchObjContractID.keyName] = this.state.searchObjContractID.value;
    // filters[this.state.searchObjInvestor.keyName] = this.state.searchObjInvestor.value;
    // filters[this.state.sliderObjTerm.keyName] = {
    //     "min": this.state.sliderObjTerm.currentMin,
    //     "max": this.state.sliderObjTerm.currentMax
    // };
    // filters[this.state.sliderObjPaymentNo.keyName] = {
    //     "min": this.state.sliderObjPaymentNo.currentMin,
    //     "max": this.state.sliderObjPaymentNo.currentMax
    // };
    // filters[this.state.sliderObjGrossCashFlow.keyName] = {
    //     "min": this.state.sliderObjGrossCashFlow.currentMin,
    //     "max": this.state.sliderObjGrossCashFlow.currentMax
    // };
    // filters[this.state.sliderObjFees.keyName] = {
    //     "min": this.state.sliderObjFees.currentMin,
    //     "max": this.state.sliderObjFees.currentMax
    // };
    // filters[this.state.paymentDateObj.keyName] = {
    //     "from":this.state.paymentDateObj.date[0],
    //     "to":this.state.paymentDateObj.date[1]
    // };
    filters[repaymentStartDateObj.keyName] = {
      "from": repaymentStartDateObj.date[0],
      "to": repaymentStartDateObj.date[1]
    }
    filters[selectorObjStatus.keyName] = selectorObjStatus.value;
    return filters;
  };

  handleApplyFilters = () => {
    this.setState({ isFiltering: true });
    this.fetchTrades(true);
  };

  handleSearchFilter = (obj) => {
    let searchObj = {};
    searchObj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(searchObj, () => {
      this.handleApplyFilters();
    });
  };

  handleSliderFilter = (obj) => {
    let sliderobj = {};
    sliderobj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(sliderobj, () => {
      this.handleApplyFilters();
    });
  };

  handledatePicker = (obj) => {
    let datePickerObj = {};
    datePickerObj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(datePickerObj, () => {
      this.handleApplyFilters();
    });
  };

  inputSelectorHandler = (obj) => {
    let selectorObj = {};
    selectorObj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(selectorObj, () => {
      this.handleApplyFilters();
    });
  };

  markApprovalStatus = (status, isContinue = false) => {
    if (this.state.viewMode === 'orders') {
      if (_.isEmpty(this.state.selected_order_ids)) {
        showNotification("error", "please select an Order Id")
        return;
      }
      this.setState({
        is_marking_contract_status: true,
      });
      const payload = {
        order_id: this.state.selected_order_ids[0],
        approval_status: status,
      }
      if(isContinue === true) payload.is_continue = true;
      callApi(`/admin/mark-order-approval-status`, "post", payload).then((res) => {
        if (res.status === "Success") {
          const order = this.state.orders.find((order) => order?._id === payload?.order_id);
          if(isEnvProd && (status === APPROVED || status === APPROVED_WP)) {
            mixPanelTrack({
              id: order?._id,
              trackUserObj: {
                clicked_by: this.props.userName,
                drawdown_amount: order?.total_investment,
                investor_name: order?.investor_org_id?.investor_name,
                order_id: order?.order_id
              },
              trackCategoryName: "Mark Order as approved on Admin",
            });
          }
          showNotification("success", "Status Updated Successfully!");
          this.setState({
            is_marking_contract_status: false,
            selected_order_ids: [],
          });
          this.fetchTrades();
        } else {
          if(res?.data?.message === "The sanction letter not generated yet") {
            this.setState({ sanctionNotApprovedModal: true });
            this.setState({ approval_status: status });
            this.setState({
              is_marking_contract_status: false,
            });
            return;
          }
          showNotification("error", "Some error occured");
          this.setState({
            is_marking_contract_status: false,
          });
        }
      });
    }
    else {
      this.setState({
        is_marking_contract_status: true,
        mark_contract_status_as: status,
      });
      callApi(`/admin/mark-contracts-trade-approved`, "post", {
        trade_ids: this.state.selected_trades_ids,
        approval_status: status,
      }).then((res) => {
        console.log(res);
        if (res.status === "Success") {
          showNotification("success", "Status Updated Successfully!");
          this.setState({
            is_marking_contract_status: false,
            selected_trades_ids: [],
          });
          this.fetchTrades();
        } else {
          showNotification("error", "Some error occured");
          this.setState({
            is_marking_contract_status: false,
          });
        }
      });
    }

  }

  onChangeTradeCheckbox = (val) => {
    if (this.state.viewMode === 'trades') {
      let selected_trades_ids = this.state.selected_trades_ids;
      if (val.target.checked) {
        selected_trades_ids.push(val.target.value);
      } else if (!val.target.checked) {
        _.remove(selected_trades_ids, (n) => {
          return n === val.target.value;
        });
      }
      this.setState({
        selected_trades_ids: selected_trades_ids,
      });
    } else {
      let selected_order_ids = this.state.selected_order_ids;
      if (val.target.checked) {
        selected_order_ids.push(val.target.value);
      } else if (!val.target.checked) {
        _.remove(selected_order_ids, (n) => {
          return n === val.target.value;
        });
      }
      this.setState({
        selected_order_ids: selected_order_ids,
      });
    }

  };

  changeView = (viewMode, selectedOrderID = 0) => {
    if (viewMode === 'trades' && !selectedOrderID) {
      this.fetchTrades();
      this.setState({
        viewMode: viewMode,
        selected_order_for_trade_view: selectedOrderID,
        selected_trades_ids: [],
        selected_order_ids: []
      })
    } else if (viewMode === 'trades' && selectedOrderID) {
      this.fetchTradesByOrderId(selectedOrderID);
      this.setState({
        viewMode: viewMode,
        selected_order_for_trade_view: selectedOrderID,
        selected_trades_ids: [],
        selected_order_ids: []
      })
    } else {
      this.setState({
        viewMode: viewMode,
        selected_order_for_trade_view: undefined,
        selected_trades_ids: [],
        selected_order_ids: []
      })
    }
  }
  freezeOrder = () => {
    this.setState({
      is_marking_contract_status: true,
    });
    callApi(`admin/freeze-investorOrders`, "post", {
      order_ids: this.state.selected_order_ids}).then((res) => {
        this.setState({
          is_marking_contract_status: false
        });
      if (res.status === "Success") {
        showNotification("success", "Frozen Successfully!");
        this.setState({
          selected_order_ids:[]
        })
        this.fetchOrders();
      } else {
        showNotification("error", "Some error occured");
      }
    });
  }

  render() {
    return (
      <div className="main-body">
        <AdminHead />
        <section id='admin-company'>
          <div className='d-flex justify-content-between form-group has-search'>
            <div className='d-flex justify-content-between w-100'>
              <div className="trade-order">
                <div className="b-b b-theme nav-active-theme mb-3">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item"><a className={"nav-link " + (this.state.viewMode === "orders" ? "active" : "")} id="orders-tab" onClick={() => this.changeView('orders')} data-toggle="tab" href="#orders" role="tab" aria-controls="orders" aria-selected="true">ORDERS</a></li>
                    <li className="nav-item"><a className={"nav-link " + (this.state.viewMode === "orders" ? "" : "active")} id="trades-tab" onClick={() => this.changeView('trades')} data-toggle="tab" href="#trades" role="tab" aria-controls="trades" aria-selected="false">TRADES</a></li>
                  </ul>
                </div>
              </div>
              {
                // this.state.viewMode === "orders" && 
                <div>
                  <button
                    className='app-btn btn-approve'
                    disabled={this.state.is_marking_contract_status}
                    onClick={() => {
                      this.markApprovalStatus("Approved");
                    }}>
                    <img src='../../../../../assets/icon-done.svg' alt=''></img>
                    {(this.state.is_marking_contract_status ? 'Approving' : 'Approve')}
                  </button>
                  <button
                    className='app-btn btn-approve-nopayment'
                    disabled={this.state.is_marking_contract_status}
                    onClick={() => {
                      this.markApprovalStatus("Approved-without-payment");
                    }}>
                    <img src='../../../../../assets/icon-done.svg' alt=''></img>
                    {(this.state.is_marking_contract_status ? 'Approving' : 'Approve WP')}
                  </button>
                  <button
                    // disabled={this.props.isUserHasUWViewAccess}
                    className='app-btn btn-no-approve'
                    onClick={() => {
                      this.markApprovalStatus("Not Approved");
                    }}>
                    <img src='../../../../../assets/icon-clear.svg' alt=''></img>Not
                    Approved
                  </button>
                  {this.state.viewMode === "orders" && <button
                    // disabled={this.props.isUserHasUWViewAccess}
                    className='app-btn btn-hold mr-0'
                    onClick={this.freezeOrder}>
                    <img src='../../../../../assets/icon-history.svg' alt=''></img>
                    Freeze
                  </button>}
                </div>}
            </div>
          </div>
          <div className="tab-content mb-4">
            <div className={"tab-pane fade " + (this.state.viewMode === "orders" ? "show active" : "")} id="orders" role="tabpanel" aria-labelledby="orders-tab">
              <div className='TableCard'>
                <table>
                  <OrdersTableHeader
                    searchObjInvestorOrg={this.state.searchObjInvestorOrg}
                    searchObjInvestorUser={this.state.searchObjInvestorUser}
                    searchObjContractID={this.state.searchObjContractID}
                    searchObjAdminUser={this.state.searchObjAdminUser}
                    sliderObjPaymentsTerm={this.state.sliderObjPaymentsTerm}
                    sliderObjPayments={this.state.sliderObjPayments}
                    sliderObjInvestment={this.state.sliderObjInvestment}
                    dateObjDateRequested={this.state.dateObjDateRequested}
                    dateObjLastUpdated={this.state.dateObjLastUpdated}
                    repaymentStartDateObj={this.state.repaymentStartDateObj}
                    selectorObjStatus={this.state.selectorObjStatus}
                    handleSliderFilter={this.handleSliderFilter}
                    inputSelectorHandler={this.inputSelectorHandler}
                    handleSearchFilter={this.handleSearchFilter}
                    handledatePicker={this.handledatePicker}
                  />
                  {/* <tbody style={{ display: 'none' }}></tbody>
                </table>
              </div>
              <div className='tableFixHead table-body'>
                <table
                  className='table team'
                >
                  <thead></thead> */}
                  <tbody>
                    {this.state.orders &&
                      this.state.orders.map((item, index) => (
                        <OrdersTableRow
                          key={item._id}
                          loader={this.state.is_fetching_orders}
                          hasMore={this.hasMore}
                          loadMore={this.loadMore}
                          dataLength={this.state.orders.length}
                          index={index}
                          selected_order_ids={this.state.selected_order_ids}
                          onChangeTradeCheckbox={this.onChangeTradeCheckbox}
                          changeView={this.changeView}
                          item={item}
                        />
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className={"tab-pane fade " + (this.state.viewMode === "orders" ? "" : "show active")} id="trades" role="tabpanel" aria-labelledby="trades-tab">
              <div>
                <div>
                <SanctionNotApprovedModal 
                    sanctionNotApprovedModal={this.state.sanctionNotApprovedModal} 
                    setSanctionNotApprovedModal={(val) => this.setState({ sanctionNotApprovedModal: val })}
                    markApprovalStatus={this.markApprovalStatus}
                    approval_status={this.state.approval_status}
                />
                  <div className='TableCard'>
                    <table>
                      <TradesTableHeader
                        searchObjInvestorOrg={this.state.searchObjInvestorOrg}
                        searchObjInvestorUser={this.state.searchObjInvestorUser}
                        searchObjContractID={this.state.searchObjContractID}
                        searchObjAdminUser={this.state.searchObjAdminUser}
                        sliderObjPaymentsTerm={this.state.sliderObjPaymentsTerm}
                        sliderObjPayments={this.state.sliderObjPayments}
                        sliderObjInvestment={this.state.sliderObjInvestment}
                        dateObjDateRequested={this.state.dateObjDateRequested}
                        dateObjLastUpdated={this.state.dateObjLastUpdated}
                        repaymentStartDateObj={this.state.repaymentStartDateObj}
                        selectorObjStatus={this.state.selectorObjStatus}
                        handleSliderFilter={this.handleSliderFilter}
                        inputSelectorHandler={this.inputSelectorHandler}
                        handleSearchFilter={this.handleSearchFilter}
                        handledatePicker={this.handledatePicker}
                      />
                      {/* <tbody style={{ display: 'none' }}></tbody>
                    </table>
                  </div>
                  <div className='tableFixHead table-body'>
                    <table className='table team'>
                      <thead></thead> */}
                      <tbody>
                        {this.state.trades &&
                          this.state.trades.map((item, index) => (
                            <TradesTableRow
                              key={item._id}
                              loader={this.state.is_fetching_trades}
                              hasMore={this.hasMore}
                              loadMore={this.loadMore}
                              dataLength={this.state.trades.length}
                              index={index}
                              selected_trades_ids={this.state.selected_trades_ids}
                              onChangeTradeCheckbox={this.onChangeTradeCheckbox}
                              item={item}
                            />
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    isUserHasUWViewAccess: getCheckIfUserHasRelevantPermissions(state,["underwriting:edit"]),
    userName: getUserName(state)
  };
}

export default withRouter(connect(mapStateToProps)(TradesPage)) ;

