import { useState, useRef, useLayoutEffect } from "react";
import styles from "../Recovery.module.scss";
import BreakUpTable from "./BreakUpTable";

const RecoveryviewProgress = ({ item, isHovering, setIsHovering }: { item: any, isHovering: Boolean, setIsHovering: Function }) => {
  const collectedGraphWrapperRef = useRef(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const {
    collectedGraphWrapper,
    upperAmount,
    graph,
    filledGraph,
    lowerAmount,
    tablebreakdown,
  } = styles;
  const { collected, total, lastTrxAmt, lastTrxTimeDiff } = item;
  const totalNumber = parseFloat(total.replace(/[^\d.-]/g, ""));
  const collectedNumber = parseFloat(collected.replace(/[^\d.-]/g, ""));
  const filledGraphValue = (collectedNumber / totalNumber) * 100;

  useLayoutEffect(() => {
    //@ts-ignore
    const rect = collectedGraphWrapperRef.current.getBoundingClientRect();
    setTooltipPosition({ x: rect.left, y: rect.bottom });
  }, []);

  const handleMouseEnter = () => {
    //@ts-ignore
    const rect = collectedGraphWrapperRef.current.getBoundingClientRect();
    setTooltipPosition({ x: rect.left, y: rect.bottom + 10 });
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <td
      style={{
        width: "170px",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div>
        <div className={collectedGraphWrapper} ref={collectedGraphWrapperRef}>
          <div className={upperAmount}>
            <span>{collected} </span>/ {total}
          </div>
          <div
            className={graph}
            data-tip=""
            data-for={`breakdown`}
            data-event="click"
            data-event-off="dblclick"
          >
            <div
              className={filledGraph}
              style={{ width: `${filledGraphValue}%` }}
            ></div>
          </div>
          {lastTrxTimeDiff ? (
            <p className={lowerAmount}>
              {lastTrxAmt}, {lastTrxTimeDiff}
            </p>
          ) : null}
        </div>
        <div
          className={tablebreakdown}
          style={{
            position: "absolute",
            overflow: "overlay",
            visibility: isHovering ? "visible" : "hidden",
            transition: "all 0.5s ease-in",
            display: isHovering ? "block" : "none",
          }}
        >
          <BreakUpTable item={item} />
        </div>
      </div>
    </td>
  );
};

export default RecoveryviewProgress;
