import React, { Component } from "react";
import "../css/Filters.css";
// import { Link } from 'react-router-dom';
// import { connect } from 'react-redux';

class InputSelector extends Component {
    constructor(props) {
        super(props);
    }

    selectorToggleHandler = async(idx) => {
        let elementsArray = JSON.parse(JSON.stringify(this.props.elementsArray));
        elementsArray[idx].selected = !elementsArray[idx].selected;
        await this.props.setElementsArray(elementsArray);
        let selector = [];
        this.props.elementsArray.forEach((el) => {
            if (el.selected) {
                selector.push(el.label);
            }
        });
        await this.props.setSelector(selector);
        this.props.inputSelectorHandler({
            keyName: (this.props.keyName === "contract_term_value" || this.props.keyName === "term") ? this.props.keyNameUnit : this.props.keyName,
            value: this.props.selector
        });
    }

    inputSelectorJsxArray = () =>{
        return this.props.elementsArray.map((el,idx) => {
            return (
                <div className="col input-selected inactive-input-select" onClick = {()=>this.selectorToggleHandler(idx)}>
                    {el.selected?<img alt="" src="/assets/filter-mul.svg" />:<img alt="" src="/assets/filter-add.svg" />}{el.label}
                </div>
            )
        })
    }

    render() {
        if (this.props.keyName === "contract_term_value" || this.props.keyName === "term") {
            return (
                <div className="row" style={{marginLeft:'0px', marginRight:'0px', marginTop:'18px', cursor: 'pointer'}}>
                    {this.inputSelectorJsxArray()}
                </div>
            )
        }

        return (
            <section>
                <div className="filter-selector border-shadow">
                    <div className="filter-type">{this.props.labelText}</div>
                    <div className="row" style={{marginLeft:'0px', marginRight:'0px', marginTop:'18px', cursor: 'pointer'}}>
                        {this.inputSelectorJsxArray()}
                    </div>
                </div>
            </section>
        );
    }
}

export default InputSelector;
