import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "./ViewDocsModal.scss";
import { getEmail, getUserName } from "../../../../../../../../../../App/AppReducer";
import {
  ADDITIONAL_DEAL_DOC_CATEGORY_ID,
  ZEUS_EMAIL_KYC_AUDIT_TRAIL,
  DOC_SERVICE_RESPONSE,
  GENERIC_ERROR,
  API_STATUS,
  TOAST_TYPES,
  FLOW_ID,
} from "../../../../../../../../../../../constants/enums";
import { isEmpty, isNil } from "lodash";
import { getInvesteesOrganization } from "../../../../../../../../../AdminReducers";
import callApi from "../../../../../../../../../../../util/apiCaller";
import Dropdown from "./Dropdown";
import { deleteDebtDoc, deleteDoc, downloadFile, fetchDocDetails, getFileNameFromSignedUrl, getS3IconLink, showNotification } from "../../../../../../../../../../../util/utility";
import { AUDIT_HANDLER_URI, IPA_DOC, MARK_SANCTION_COMPLETE, OVERVIEW_STATUS, UPLOADING, UPLOAD_DOC, kycFolderNames } from "../../../../../../../../../../../common/ENUM";
import { DEBT_DOCS, DEBT_SANCTION_STATUS, INVOICE_AMOUNT, IP_CAPTURE_ACTIONS, MARK_SANCTION_COMPLETED, SANCTIONED_LIMIT } from "../../../../../../../../../../../constants/commonConstants";
import { Modal } from "react-bootstrap";

// Status checks
const statusChecks = {
  [DEBT_SANCTION_STATUS.SANCTION_RECEIVED]: [DEBT_SANCTION_STATUS.SANCTION_ACCEPTED, DEBT_SANCTION_STATUS.SANCTION_DISBURSED, DEBT_SANCTION_STATUS.SANCTION_RECEIVED],
  [DEBT_SANCTION_STATUS.SANCTION_ACCEPTED]: [DEBT_SANCTION_STATUS.INCOMPLETE, DEBT_SANCTION_STATUS.SANCTION_ACCEPTED, DEBT_SANCTION_STATUS.SANCTION_DISBURSED],
  [DEBT_SANCTION_STATUS.SANCTION_DISBURSED]: [DEBT_SANCTION_STATUS.INCOMPLETE, DEBT_SANCTION_STATUS.SANCTION_RECEIVED, DEBT_SANCTION_STATUS.SANCTION_DISBURSED]
};

export default function TransactionDocuments({ investor, fetchDeals }) {
  const { docsLastEditedDate, docsLastEditedBy, debtDocuments,offer_id,debtSanctionComplete } = investor;
  const [hasClickedAddAdditionalDocs, setHasClickedAddAdditionalDocs] =
    useState(false);
  const [additionalDocDescription, setAdditionalDocDescription] = useState("");
  const [sanctionedLimit, setSanctionedLimit] = useState("");
  const [invoiceFees, setInvoiceFees] = useState("");
  const [checkIfDealPassed, setCheckIfDealPassed] = useState(false)
  const [sanctionCompleted, setSanctionCompleted] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const userName = useSelector(getUserName);
  const emailAddress = useSelector(getEmail);
  const investeeOrgData = useSelector(getInvesteesOrganization);
  const investeeOrgName = investeeOrgData?.name;

  const options = [
    DEBT_DOCS.SANCTION_LETTER,
    DEBT_DOCS.TRANSACTION_INVOICES,
    DEBT_DOCS.REPAYMENT_SCHEDULE
  
  ];

  const sanctionCompleteOptions = [
    DEBT_SANCTION_STATUS.SANCTION_RECEIVED,
    DEBT_SANCTION_STATUS.SANCTION_ACCEPTED,
    DEBT_SANCTION_STATUS.SANCTION_DISBURSED
  ]

  const [selectedValues, setSelectedValues] = useState({
    DROP_1: DEBT_DOCS.SANCTION_LETTER,
    DROP_2: DEBT_DOCS.TRANSACTION_INVOICES,
    DROP_3: DEBT_DOCS.REPAYMENT_SCHEDULE,
    DROP_4: debtSanctionComplete === DEBT_SANCTION_STATUS.INCOMPLETE || isNil(debtSanctionComplete) ? null : debtSanctionComplete
  });
  const [uploadedDocs, setUploadedDocs] = useState({
    DROP_1: null,
    DROP_2: null,
    DROP_3: null,
  });
  const [uploading, setUploading] = useState({
    DROP_1: false,
    DROP_2: false,
    DROP_3: false,
    // Add more drop keys as needed
  });

  const [additionalDocsData, setAdditionalDocsData] = useState({});

  useEffect(() => { 
    if(investor.offer_status === OVERVIEW_STATUS.passed) setCheckIfDealPassed(true)
    updateUploadedDocs()
    fetchAdditionalDocsData();
    if(debtSanctionComplete === MARK_SANCTION_COMPLETE.COMPLETE){
      setSanctionCompleted(true)
    }
    if(!isNaN(investor?.sanctionedLimit) && investor?.sanctionedLimit > 0) setSanctionedLimit(Number(investor.sanctionedLimit))
    if(!isNaN(investor?.invoiceFees) && investor?.invoiceFees > 0) setInvoiceFees(Number(investor.invoiceFees))
    checkSanctionStatus()
  }, [investor]);
  const fetchAdditionalDocsData = async () => {
    const updatedAdditionalDocsData = {};

    for (const doc of debtDocuments) {
      if (!options.includes(doc.description)) {
        const docDetails = await fetchDocDetails(doc.url); 

        updatedAdditionalDocsData[doc.url] = {
          ...doc,
          fileName : docDetails?.fileName
        };
      }
    }
    setAdditionalDocsData(updatedAdditionalDocsData);
  };

  const checkSanctionStatus = () =>{
    return !isEmpty(sanctionedLimit) && !isEmpty(invoiceFees)
  }

  const checkDataForSanction = () => {
    let isValid = true;
    let missingItems = [];
    let message = '';
    let filesUploaded = true;
    const updatedSanctionedStatus = selectedValues.DROP_4;

    if(isNil(updatedSanctionedStatus)) {
        message = "Please select a status to mark the deal as complete";
        isValid = false;
        return { message, isValid };
    }

    const prevStates = {
        [DEBT_SANCTION_STATUS.SANCTION_RECEIVED]: DEBT_SANCTION_STATUS.INCOMPLETE,
        [DEBT_SANCTION_STATUS.SANCTION_ACCEPTED]: DEBT_SANCTION_STATUS.SANCTION_RECEIVED,
        [DEBT_SANCTION_STATUS.SANCTION_DISBURSED]: DEBT_SANCTION_STATUS.SANCTION_ACCEPTED
    };

    if (statusChecks[updatedSanctionedStatus]?.includes(debtSanctionComplete)) { 
        // prevstate of updatedSanctionedStatus should be marked first
        message = `${prevStates[updatedSanctionedStatus]} needs to be marked first`;
        isValid = false;

        if(updateUploadedDocs === DEBT_SANCTION_STATUS.SANCTION_RECEIVED) {
            message = "Please select appropriate status to mark the deal as complete";
        }
    }

    if (!isValid) return { message, isValid };

    if (updatedSanctionedStatus === DEBT_SANCTION_STATUS.SANCTION_RECEIVED) {
        if (isEmpty(uploadedDocs) || isEmpty(uploadedDocs.DROP_1)) {
            missingItems.push(DEBT_DOCS.SANCTION_LETTER);
            isValid = false;
            filesUploaded = false;
            message = "Please upload Sanction Letter.";
        }

        if (isValid && sanctionedLimit <= 0) {
            message = "Sanctioned Limit should be greater than 0";
            isValid = false;
        }

        return { message, isValid };
    }

    if (isEmpty(uploadedDocs)) return;

    let hasSanctionLetter = false;
    let hasTransactionInvoices = false;

    for (const key in uploadedDocs) {
        if (uploadedDocs[key]?.description === DEBT_DOCS.SANCTION_LETTER) {
            hasSanctionLetter = true;
        }
        if (uploadedDocs[key]?.description === DEBT_DOCS.TRANSACTION_INVOICES) {
            hasTransactionInvoices = true;
        }
    }

    if (!hasSanctionLetter) {
        missingItems.push(DEBT_DOCS.SANCTION_LETTER);
        isValid = false;
        filesUploaded = false;
    }
    if (!hasTransactionInvoices) {
        missingItems.push(DEBT_DOCS.TRANSACTION_INVOICES);
        isValid = false;
        filesUploaded = false;
    }
    if (isValid) {
        if (isNaN(sanctionedLimit)) {
            missingItems.push(SANCTIONED_LIMIT);
            isValid = false;
        }
        if (isNaN(invoiceFees)) {
            missingItems.push(INVOICE_AMOUNT);
            isValid = false;
        }
    }

    message = `Please ${filesUploaded ? 'provide' : 'upload'} ${missingItems.join(' and ')} to mark the deal as complete`;

    if (isValid && sanctionedLimit <= 0) {
        message = "Sanctioned Limit should be greater than 0";
        isValid = false;
    } else if (isValid && invoiceFees <= 0) {
        message = "Invoice Amount should be greater than 0";
        isValid = false;
    }


    return { message, isValid };
};

  const updateUploadedDocs = async () => {
    const updatedUploadedDocs = { ...uploadedDocs };
    if (debtDocuments) {
      for (const dropKey in selectedValues) {
        if (selectedValues.hasOwnProperty(dropKey)) {
          const docDesc = selectedValues[dropKey];
          const doc = debtDocuments.find((doc) => doc.description === docDesc);
          if(doc){
            const docDetails = doc && await fetchDocDetails(doc.url);
            updatedUploadedDocs[dropKey] = {
              ...doc,
              fileName: docDetails? docDetails?.fileName : null,
            };
        }
        else {
          updatedUploadedDocs[dropKey] = null;
        }
      }
      }
    }
    setUploadedDocs(updatedUploadedDocs);
  }
  const onSuccess = () => {
    setHasClickedAddAdditionalDocs(false);
    fetchDeals();
  };
  const uploadDocument = async ({ file, flowId = FLOW_ID.TRANSACTION_DOCUMENTS, fileDesc }) => {
    const data = { url: "" };
    const orgName = investeeOrgName?.split(" ");
    let investeeOrgNameToSend = orgName ? orgName[0] + " " + (orgName[1] ?? "") : null;
    if(investeeOrgNameToSend) investeeOrgNameToSend += "_" + "";
    let categoryId = Number(ADDITIONAL_DEAL_DOC_CATEGORY_ID);
    const signResponse = await callApi(
      "file/signedUrl",
      "post",
      {
        categoryId,
        fileName: file.name,
        investeeOrgName: investeeOrgNameToSend,
      },
      {},
      false,
      false,
      false,
      false,
      false,
      true
    );
    data.url = signResponse.signedUrl;
    const requestOptions = {
      method: "put",
      body: file,
      headers: new Headers({
        "Content-Type": file.type,
      }),
    };

    const uploadResponse = await fetch(data.url, requestOptions);
    const data_to_post = {
      categoryId,
      fileName: file.name,
      fileSizeKb: file.size / 1_000,
      fileType: file.type,
      uploadedBy: userName,
      flowId,
      description: fileDesc,
      emailAddress: ZEUS_EMAIL_KYC_AUDIT_TRAIL,
    };
    const updateResponse = await callApi(
      "file/saveMetadata",
      "post",
      data_to_post,
      {},
      false,
      false,
      false,
      false,
      false,
      true
    );
    if (
      updateResponse?.responseData?.responseCode ===
      DOC_SERVICE_RESPONSE.SUCCESS
    ) {
      const fileMetadata = updateResponse?.fileMetadata;
      let data_to_post = {
        investor_deal_id: investor._id,
        debtDocuments: {
          description: fileDesc,
          url: fileMetadata?.id,
        },
        lastEditedBy:{
          docsLastEditedBy : userName
        },
        auditData:{
          requestUri : AUDIT_HANDLER_URI.TRANSACTION_DOCUMENTS,
          changeOwner : userName,
          orgID : investor?.investor_organization_id,
        }
      };
      const response2 = await callApi(
        "deal/saveDebtDocs",
        "post",
        data_to_post,
        {},
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
      );
      if (response2 && response2.responseData.responseCode === 20) {
        onSuccess();
      } else {
        throw new Error("Couldn't update metaData!");
      }
    }
  };
  const deletingDebtDoc = async (uploadedDoc) => {
    const obj ={
      investor_deal_id: investor._id,
      delete_debt_docs: {
        description: uploadedDoc.description,
      },
      docsLastEditedBy : userName,
      auditData:{
        requestUri : AUDIT_HANDLER_URI.TRANSACTION_DOCUMENTS,
        changeOwner : userName,
        orgID : investor?.investor_organization_id,
      }
    }
    const response = deleteDoc({
      uploadedDoc:uploadedDoc,
      dataToPost :obj ,
      sanctionCompleted : sanctionCompleted,
      apiRoute : "deal/saveDebtDocs"
    })
    if(response){
      onSuccess()
    }
  };
  const onFileChangeCapture = async ({e, fileDesc,dropKey}) => {
    if(!fileDesc){
      showNotification(TOAST_TYPES.ERROR, "Please Enter Description")
      return
     }
    const file = e.target.files[0];
    setUploading({ ...uploading, [dropKey]: true });
    try{
    await uploadDocument({ file, fileDesc });
          onSuccess()
    } finally {
    setUploading({ ...uploading, [dropKey]: false }); 
  }
  };

  const checkIfDealReadyForSanction = () =>{
    if(sanctionCompleted) return;
    const validation = checkDataForSanction()
    if(!validation.isValid){
      showNotification("error", validation.message)
      return false
    }

    if(selectedValues.DROP_4 === DEBT_SANCTION_STATUS.SANCTION_ACCEPTED) {
      setShowModal(true)
    } else {
      markDebtSanctionComplete();
    }
  }
  const markDebtSanctionComplete = (triggerInvoiceMail = false) => {
    if(sanctionCompleted) return

    callApi("deal/saveDebtSanctionStatus", "post", {
      investor_deal_id: investor._id,
      debtSanctionComplete: selectedValues.DROP_4,
      sanction_date: new Date(),
      ipObj:{
        ip_address :window.localStorage.getItem('myIp'),
        email:emailAddress,
        ref_name : userName,
        ref_id : investor._id,
        action : IP_CAPTURE_ACTIONS.DEBT_SANCTION_COMPLETED 
      },   
      docsLastEditedBy : userName,
      sanctionedLimit, 
      invoiceFees,
      triggerInvoiceMail
    },{},
    false,
    false,
    false,
    false,
    false,
    false,
    true,
    false).then((res) => {
      if (res.responseData.responseCode === 20) {
        showNotification(TOAST_TYPES.SUCCESS, MARK_SANCTION_COMPLETED);
        onSuccess()
      } else if(res.responseData.responseCode === 40){
        showNotification(TOAST_TYPES.ERROR, res.responseData?.responseMessage ?? GENERIC_ERROR);
      }
    });
    setShowModal(false)
  };
  const handleDropdownChange = (dropdownName) => (e) => {
    
    // Update selected value for the dropdown
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [dropdownName]: e.target.value,
    }));
  };
  // Filter options based on selected values
  const filteredOptions = (dropdownName) => {
    
    const usedValues = Object.values(selectedValues);
    return options.filter(
      (option) =>
        !usedValues.includes(option) || option === selectedValues[dropdownName]
    );
  };
  const renderUploadCard = (dropKey) => {
    const uploadedDoc = uploadedDocs?.[dropKey];
    return (
      <div className="UploadCard">
        <label>
          <input
            type="file"
            hidden
            onChangeCapture={(e) => onFileChangeCapture({e:e,fileDesc:selectedValues[dropKey],dropKey:dropKey})}
            disabled={uploadedDocs[dropKey]}
          />
          <div className="UploadedDocCard">
            {uploadedDocs?.[dropKey] ? (
              <img
                className="UploadCardIcons"
                src="/assets/blue-download.svg"
                alt=""
                onClick={() => downloadFile({ docId: uploadedDoc.url, fileName: uploadedDoc?.fileName })}
              />
            ) : (
              <img
                className="CloudUpload"
                src="/assets/cloud_upload.svg"
                alt=""
              />
            )}
            <span
              className={`fs10 fw5 truncated-text`}
              style={{ alignItems: "center", marginLeft: "auto" }}
            >              
              {uploading[dropKey] ? UPLOADING : uploadedDoc?.fileName ? uploadedDoc.fileName : UPLOAD_DOC}
            </span>
            {(uploadedDocs?.[dropKey] && !sanctionCompleted) ? (
              <img
                className="UploadCardIcons"
                src="/assets/clear-grey.svg"
                alt=""
                onClick={() => deletingDebtDoc(uploadedDoc)}
              />
            ) : ""}
          </div>
        </label>
      </div>
    );
  };
  return (
    <>
      <div className="DocsCard">
        <div
          className="RowItems1 ButtonsRow"
        >
          <div className="RowTitle PaddingTop24Left24">
            Transaction Documents
          </div>
          <div className="UploadName PaddingTop24Right26">
            Last Edited By {docsLastEditedBy || "-"} on{" "}
            {docsLastEditedDate ? dayjs(docsLastEditedDate)
        .utc()
        .format("DD/MM/YYYY") : "-" }
          </div>
        </div>
        {/* Row-1 */}
        <div style={{ display: "flex" }}>
          <div className="RowTitle PaddingTop24Left24">
            <Dropdown
              className="OptionSelect"
              value={selectedValues.DROP_1}
              onChange={handleDropdownChange("DROP_1")}
              options={filteredOptions("DROP_1")}
            />
          </div>
          <div className="RowTitle PaddingUploadCard">
            {renderUploadCard("DROP_1")}
          </div>
          <div className="RowTitle PaddingTop11Left14">
            <div>
              <div className="SanctionLimitText"
              style={{ visibility: sanctionedLimit ? 'visible' : 'hidden' }}
              >Sanctioned Limit (Cr)</div>
            </div>
            <div>
              <input
                className="SanctionLimit"
                style={{ fontSize: "12px" }}
                type="number"
                value={sanctionedLimit}
                placeholder="Sanctioned Limit (Cr)"
                disabled={sanctionCompleted}
                onChange={(e) => {
                  setSanctionedLimit(Number(e.target.value));
                }}
              />
            </div>
          </div>
        </div>
        {/* Row-2 */}
        <div style={{ display: "flex" }}>
          <div className="RowTitle PaddingTop24Left24">
            <Dropdown
              value={selectedValues.DROP_2}
              onChange={handleDropdownChange("DROP_2")}
              options={filteredOptions("DROP_2")}
            />
          </div>
          <div className="RowTitle PaddingUploadCard">
            {renderUploadCard("DROP_2")}
          </div>
          <div>
          <div className="RowTitle PaddingTop11Left14 DFlex">
            
              <div className="SanctionLimitText DFlex"
              style={{ visibility: invoiceFees ? 'visible' : 'hidden' }}
              >Invoice Amount (without GST)</div>
            </div>

          <div className="RowTitle PaddingLeft14">
            <input
              className="SanctionLimit"
              style={{fontSize:'12px'}}
              type="number"
              value={invoiceFees}
              placeholder="Invoice Amount (without GST)"
              disabled={sanctionCompleted}
              onChange={(e) => {
                setInvoiceFees(Number(e.target.value));
              }}
            />
          </div>
          </div>
        </div>
        {/* Row-3 */}
        <div style={{ display: "flex" }}>
          <div className="RowTitle PaddingTop24Left24">
            <Dropdown
              value={selectedValues.DROP_3}
              onChange={handleDropdownChange("DROP_3")}
              options={filteredOptions("DROP_3")}
            />
          </div>

          <div className="RowTitle PaddingUploadCard">
            {renderUploadCard("DROP_3")}
          </div>
        </div>
        {/* Adding Additional Deal Docs */}
        {
  investor?.debtDocuments?.map((doc) => {
    if (!options.includes(doc.description) && doc.description!==IPA_DOC) {
      const docDetails = additionalDocsData[doc.url];
      if (docDetails) {
        return (
                <div  key={doc._id} style={{ display: "flex"}}>
                <div className="RowTitle PaddingTop24Left24">
                  <input
                    className="OptionSelect PaddingLeft10"
                    style={{fontSize:'12px'}}
                    type="text"
                    value={doc.description}
                    disabled={true}
                  />
                  </div>
                 <div className="RowTitle PaddingUploadCard">
                    <div className="UploadCard">
                      <label>
                        <div className="AddnUploadCard">
                          <img
                            className="UploadCardIcons"
                            src="/assets/blue-download.svg"
                            alt=""
                            onClick={()=>downloadFile({docId : doc?.url,fileName : docDetails?.fileName})}
                          />
                          <span
                            className={`fs10 fw5 truncated-text`}
                            style={{ alignItems: "center" }}
                          >
                            {docDetails?.fileName}
                          </span>
                          {/* Delete Button */}
                          {!sanctionCompleted && (
                            <img
                              className="UploadCardIcons"
                              src="/assets/clear-grey.svg"
                              alt=""
                              onClick={() => deletingDebtDoc(doc)}
                            />
                          )}
                        </div>
                      </label>
                    </div>
                  </div>
              </div>
            );
          }
        }
        return null;
      })
    }

        {/* Additional Documents Upload */}
        {hasClickedAddAdditionalDocs && (
          <div style={{ display: "flex" }}>
            <div className="RowTitle PaddingTop24Bottom24Left24">
              <input
                className="OptionSelect"
                style={{fontSize:'12px'}}
                type="text"
                value={additionalDocDescription}
                placeholder="Enter File Description"
                onChange={(e) => {setAdditionalDocDescription(e.target.value)}}
              />
            </div>
            <div className="RowTitle PaddingTop24Right24Left14">
              <div className="UploadCard">
                <label>
                  <div className="AddnUploadCard">
                  <input
                    type="file"
                    hidden
                    onChangeCapture={(e) => onFileChangeCapture({e:e,fileDesc:additionalDocDescription,dropKey:additionalDocDescription})}
                  />
                  <img
                   className="CloudUpload"
                   src="/assets/cloud_upload.svg"
                   alt=""
                  />
                  <span
              className={`fs10 fw5 truncated-text`}
              style={{ alignItems: "center", marginLeft: "auto" }}
            > 
                   {uploading[additionalDocDescription] ? UPLOADING
                : UPLOAD_DOC}
                  </span>
                  </div>
                </label>
              </div>
            </div>
          </div>
        )}

        {/* Add Additional Docs */}
        <div className="PaddingTop24Bottom24Left24">
          <div
            className="add-Additional-docs"
            disabled={sanctionCompleted}
            onClick={() => {
              setHasClickedAddAdditionalDocs(true);
              setAdditionalDocDescription("")
            }}
          >
            <label className="dflex-ac mb-0">
              <img
                src="/assets/Add-blue.svg"
                alt="Add"
                width="10px"
                height="10px"
                style={{ margin: "0 5px" }}
              />
              Additional Deal Documents
            </label>
          </div>
        </div>
      </div>
      <div className="MarkSanctionStatusDiv">
        <select 
          disabled={sanctionCompleted || checkIfDealPassed} 
          className="OptionSelect MarkSanctionSelect"
          id={selectedValues.DROP_4} 
          value={selectedValues.DROP_4 || ""} 
          onChange={handleDropdownChange("DROP_4")} 
          style={{ backgroundImage: `url(${getS3IconLink("down-arrow")})` }}
        >
          <option value="" disabled>Select an option</option>
          {sanctionCompleteOptions.map((option) => (
            <option key={option} value={option}>
              {option === DEBT_SANCTION_STATUS.SANCTION_DISBURSED ? "Disbursed" : option}
            </option>
          ))}
        </select>
        <button
          className={
            !sanctionCompleted
              ? `MarkSanctionButton`
              : `MarkSanctionButtonDisabled`
          }
          disabled={statusChecks[selectedValues.DROP_4]?.includes(debtSanctionComplete) || sanctionCompleted || checkIfDealPassed}
          onClick={() => {
            checkIfDealReadyForSanction()
          }}
        >
          <div
            className={
              !sanctionCompleted
                ? `MarkSanctionText`
                : `MarkSanctionTextDisabled`
            }
          >
            Save
          </div>
        </button>
      </div>
      {
        showModal && (
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            size="xl"
            className="ConfirmModal"
            backdrop={false}
          >
            <Modal.Body closeButton>
              <div>
                <div className="ButtonsRow">
                  <div className="SanctionText">
                      Sanction succesfully completed!
                  </div>
                  <div className="SanctionCompleteClear">
                    <img
                      className="clearIcon"
                      src="/assets/clear-grey.svg"
                      alt=""
                      onClick={() => setShowModal(false)}
                    />
                  </div>
                </div>
                <div className="SanctionBoxInnerText">
                  
                    Do you wish to share the sanction documents with the
                    company?
                  
                </div>
                <div className="ButtonsContainer">
                  <div className="ButtonsRow">
                  <div className="PaddingRight16">
                    <button className="SkipButton" onClick={()=> {markDebtSanctionComplete()}}>Skip Email</button>
                  </div>
                  <div>
                    <button className="SendMailButton" onClick={()=> {markDebtSanctionComplete(true)}}>Send Email</button>
                  </div>
                </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )
      }
    </>
  );
}
