import "./RiskTagsTooltip.scss";
import{ pluralize} from '../../../../../util/utility'

export const RiskTagsTooltip = (props: any) => {
  const getColorCodeForRiskTag = (riskFlag: string) => {
    switch (riskFlag) {
      case "HIGH":
        return "#D80000";
      case "MEDIUM":
        return "#FCBF07";
      case "LOW":
        return "#00AA00";
    }
  };
  const returnCollectionMethod = ()=>{
    if(detailedRiskTags.params.isPGConnected&&detailedRiskTags.params.isNachConnected) return <span style={{color:'#00AA00'}}>Nach, Rzp</span>
    if(detailedRiskTags.params.isPGConnected&&!detailedRiskTags.params.isNachConnected) return <><span style={{color:'#00AA00'}}>Rzp, </span><span style={{color:'#D80000'}}>Nach</span></>
    if(!detailedRiskTags.params.isPGConnected&&detailedRiskTags.params.isNachConnected) return <><span style={{color:'#00AA00'}}>Nach, </span><span style={{color:'#D80000'}}>Rzp</span></>
    if(!detailedRiskTags.params.isPGConnected&&!detailedRiskTags.params.isNachConnected) return <span style={{color:'#D80000'}}>Nach, Rzp</span>
  }
  const { detailedRiskTags } = props;
  return (
    <div className="riskTooltipWrapper">
      <h6>
        Collection Risk:{" "}
        <span
          style={{ color: getColorCodeForRiskTag(detailedRiskTags?.riskFlag) }}
        >
          {detailedRiskTags.riskFlag}
        </span>
      </h6>
      <div className="riskRow">
        <div className="riskRowLeft">Requested Grace</div>
        <div
          className="requestedRowRight"
          style={
            detailedRiskTags.params.grace_period > 0
            ?{ color: "#D80000" }: { color: "#00AA00" }
          }
        >
          {pluralize('time','times',detailedRiskTags.params.grace_period)}
        </div>
      </div>
      <div className="riskRow">
        <div className="riskRowLeft">NACH Failed</div>
        <div
          className="requestedRowRight"
          style={
            detailedRiskTags.params.nach_failures > 0
            ?{ color: "#D80000" }: { color: "#00AA00" }
          }
        >
          {pluralize('time','times',detailedRiskTags.params.nach_failures)}
        </div>
      </div>
      <div className="riskRow">
        <div className="riskRowLeft">Delayed Repayments </div>
        <div
          className="requestedRowRight"
          style={
            detailedRiskTags.params.dpd > 0
              ?{ color: "#D80000" }: { color: "#00AA00" }
          }
        >
          {detailedRiskTags.params.dpd} Days
        </div>
      </div>
      <div className="riskRow">
        <div className="riskRowLeft">Debt post funding as % ARR</div>
        <div
          className="requestedRowRight"
          style={
            detailedRiskTags.params.debt > 0
              ?{ color: "#D80000" }: { color: "#00AA00" }
          }
        >
          {detailedRiskTags.params.debt>0?`${detailedRiskTags.params.debt}`:"No Debt"}
        </div>
      </div>
      <div className="riskRow">
        <div className="riskRowLeft">Bounce Transaction</div>
        <div
          className="requestedRowRight"
          style={
            detailedRiskTags.params.bounceTransactions > 0
              ?{ color: "#D80000" }: { color: "#00AA00" }
          }
        >
          {detailedRiskTags.params.bounceTransactions}
        </div>
      </div>
      <div className="riskRow">
        <div className="riskRowLeft">Collection Method</div>
        <div
          className="requestedRowRight"
        >
          {returnCollectionMethod()}
        </div>
      </div>
      {detailedRiskTags.params.gstDelay!==null&&<div className="riskRow">
        <div className="riskRowLeft">GST Delay</div>
        <div
          className="requestedRowRight"
          style={
            detailedRiskTags.params.gstDelay > 0
              ?{ color: "#D80000" }: { color: "#00AA00" }
          }
        >
          {pluralize('day','days',detailedRiskTags.params.gstDelay)}
        </div>
      </div>}
      {detailedRiskTags.params.gstDefault!==null&&<div className="riskRow">
        <div className="riskRowLeft">GST Default</div>
        <div
          className="requestedRowRight"
          style={
            detailedRiskTags.params.gstDefault > 0
              ?{ color: "#D80000" }: { color: "#00AA00" }
          }
        >
          {detailedRiskTags.params.gstDefault}
        </div>
      </div>}
      {detailedRiskTags.params.epfDelay!==null&&<div className="riskRow">
        <div className="riskRowLeft">EPF Delay</div>
        <div
          className="requestedRowRight"
          style={
            detailedRiskTags.params.epfDelay > 0
              ?{ color: "#D80000" }: { color: "#00AA00" }
          }
        >
          {pluralize('day','days',detailedRiskTags.params.epfDelay)}
        </div>
      </div>}
      {detailedRiskTags.params.epfDefault!==null&&<div className="riskRow">
        <div className="riskRowLeft">EPF Default</div>
        <div
          className="requestedRowRight"
          style={
            detailedRiskTags.params.epfDefault > 0
              ?{ color: "#D80000" }: { color: "#00AA00" }
          }
        >
          {detailedRiskTags.params.epfDefault}
        </div>
      </div>}
     <div className="riskRow">
        <div className="riskRowLeft">Runway <br/>(Last statement month - {detailedRiskTags?.params?.runwayDate} )</div>
        <div
          className="requestedRowRight"
          style={
            detailedRiskTags.params.runway < 3
              ?{ color: "#D80000" }: { color: "#00AA00" }
          }
        >
          {pluralize("Month","Months",detailedRiskTags.params.runway)}
        </div>
      </div>
    </div>
  );
};
