import { useState } from 'react'
import styles from './AdvanceFilters.module.scss';
import { COLLECTION_VIEW_FILTER, COLLECTION_VIEW_FILTER_API_KEY } from '../../../../../constants/enums';

interface AdvanceFiltersProps{
    advanceFiltersOptions: string[]
    setFilterObj: (filterObj: any)=>void
    filterObj: any
}

type CollectionKeys = keyof typeof COLLECTION_VIEW_FILTER;

export const AdvanceFilters=(props: AdvanceFiltersProps)=>{

    const { advanceFiltersOptions, filterObj } = props;
    const [selectedFilter, setSelectedFilter] = useState<string>('');

    const {
        AdvanceFilterContainer,
        FilterSelect
    } = styles;

    const handleSelect = (value:CollectionKeys)=>{
        console.log(value)
        setSelectedFilter(value)
        props.setFilterObj({
            ...filterObj,
            [COLLECTION_VIEW_FILTER_API_KEY[value]]: true
        });
    }

    return(
        <div className={AdvanceFilterContainer}>
            <select
                className={FilterSelect}
                value={selectedFilter}
                onChange={(e)=>handleSelect(e.target.value as CollectionKeys)}
            >
                <option value=''>
                    Advanced Filters
                </option>
                {Object.entries(advanceFiltersOptions).map(([key, value]) => {
                    return (
                        <option
                            key={key}
                            value={key as CollectionKeys}
                        >
                            {COLLECTION_VIEW_FILTER[key as CollectionKeys]}
                        </option>
                    )
                })}
            </select>
        </div>
    )
}