import React from 'react'

function ComplianceDetails({ complianceDetails, fetchComplianceDataFromKarza }) {
  const updateDate = complianceDetails?.updated_at ? new Date(complianceDetails?.updated_at)?.toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric" }) : null
  return (
    <div className='compliance-details-table'>
      <div className='compliance-details'>
        <div>
          Compliance Details
          {!!updateDate && <p className='cmp-row2-head1'>{`Last Updated On: ${updateDate}`}</p>}
        </div>
        <div className='update-button' onClick={fetchComplianceDataFromKarza}>
          Update Data
        </div>

      </div>
      <div>
        <table className='table team'>
          <thead className="wtspc table-head">
            <tr>
              {complianceDetails?.headers?.map((header, index) => (
                <th
                  key={index}
                  style={{
                    minWidth: index === 0 ? "180px" : "70px",
                    whiteSpace: "nowrap",
                    borderTopLeftRadius: index === 0 ? "6px" : "",
                    borderTopRightRadius: index === complianceDetails?.headers?.length - 1 ? "6px" : ""
                  }}
                >
                  {header?.name ?? "-"}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-body">
            {complianceDetails?.rows?.map((row, index) => (
              <tr key={index}>
                {complianceDetails?.headers?.map((header, i) => {
                  if (header.key !== 'period')
                    return (
                      <td>{row?.[header?.key] ?? "-"}</td>
                    )
                  else
                    return (
                      <td>{row?.dataMap?.[header?.name]?.value || "-"}</td>
                    )
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ComplianceDetails;