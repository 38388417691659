import React, { Component } from "react";
// import { Link } from 'react-router-dom';
import ContractsTableHeader from "../ContractsPage/components/ContractsTableHeader";
import ContractsTableRow from "../ContractsPage/components/ContractsTableRow";
import AdminHead from "../../components/AdminHead.js";
import callApi from "../../../../util/apiCaller";
import { showNotification } from "../../../../util/utility";
import AddNewModal from "./components/AddNewModal";
import _ from "lodash";
import ContractDetailPage from "./components/ContractDetailPage";
import {
  calculateTerm,
  mergeByProperty,
} from "../../../../util/calculateTermUtil";
class ContractsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_parsing_data: false,
      selected_investee_org_id: null,
      contracts: [],
      is_fetching_contracts: false,
      pageNum: 1,
      pageSize: 100,
      totalCount: 0,
      qtext: "",
      activeSegment: "contractlist",
      contractDetails: {},
      selected_contract_ids: [],
      is_marking_contract_status: false,
      mark_contract_status_as: "",
      searchObjCompanyName: {
        keyName: "investee_organization_name",
        value: "",
      },
      searchObjCustomer: {
        keyName: "name",
        value: "",
      },
      searchObjContractID: {
        keyName: "contract_id_num",
        value: "",
      },
      searchObjAdminUser: {
        keyName: "admin_user",
        value: "",
      },
      searchObjRequesteBy: {
        keyName: "requested_by",
        value: "",
      },
      sliderObjTerm: {
        minVal: 0,
        maxVal: 12,
        currentMax: 12,
        currentMin: 0,
        keyName: "contract_term_value",
        minValueBetween: 1,
      },
      sliderObjPayments: {
        minVal: 0,
        maxVal: 100,
        currentMax: 70,
        currentMin: 20,
        keyName: "recurring_payment_value",
        minValueBetween: 10,
      },
      dateObjContractStart: {
        date: [new Date(), new Date()],
        keyName: "contract_start_date",
      },
      dateObjContractEnd: {
        date: [new Date(), new Date()],
        keyName: "contract_end_date",
      },
      dateObjDateRequested: {
        date: [new Date(), new Date()],
        keyName: "created_at",
      },
      dateObjLastUpdated: {
        date: [new Date(), new Date()],
        keyName: "updated_at",
      },
      selectorObjStatus: {
        keyName: "approval_status",
        elementsArray: [
          {
            label: "Uploaded",
            selected: true,
          },
          {
            label: "Open",
            selected: true,
          },
          {
            label: "Approved",
            selected: true,
          },
          {
            label: "Cancelled",
            selected: true,
          },
          {
            label: "Churned",
            selected: true,
          },
        ],
      },
    };
    this.debounceSearch = _.debounce(this.fetchContracts, 400);
  }

  keyStateObjNameDict = {
    investee_organization_name: "searchObjCompanyName",
    name: "searchObjCustomer",
    contract_id_num: "searchObjContractID",
    contract_start_date: "dateObjContractStart",
    contract_end_date: "dateObjContractEnd",
    contract_term_value: "sliderObjTerm",
    recurring_payment_value: "sliderObjPayments",
    created_at: "dateObjDateRequested",
    approval_status: "selectorObjStatus",
    updated_at: "dateObjLastUpdated",
    admin_user: "searchObjAdminUser",
    requested_by: "searchObjRequesteBy",
  };
  componentDidMount() {
    this.fetchContracts();
  }

  onChangeContractCheckbox = (val) => {
    let selected_contract_ids = this.state.selected_contract_ids;
    if (val.target.checked) {
      selected_contract_ids.push(val.target.value);
    } else if (!val.target.checked) {
      _.remove(selected_contract_ids, (n) => {
        return n === val.target.value;
      });
    }
    this.setState({
      selected_contract_ids: selected_contract_ids,
    });
  };

  fetchContracts = () => {
    this.setState({ is_fetching_contracts: true });
    let filters = this.getFilters();
    callApi(`admin/contract/list`, "post", filters).then((res) => {
      console.log(res);
      let contracts = [];
      if (res.status === "Success") {
        if (res.data && res.data.contracts) {
          var incoming_contracts = res.data.contracts.map((item) => {
            let termObj = calculateTerm(
              item,
              item.contract_payment_delay
                ? item.contract_payment_delay
                : item.investee_organization_id.global_delay
            );
            // let xnpv = calculateXNPV(item.investee_organization_id.irr,termObj.transactionsArr,item.recurring_payment_value);
            // item.payOut = Number(xnpv);
            item.term = termObj.term;
            item.term_numeric = termObj.term_numeric;
            return item;
          });
        }
        let contracts = this.state.contracts;
        this.setState({
          contracts: mergeByProperty(contracts, incoming_contracts, "_id"),
          totalCount: res.data.count,
          selected_contract_ids: [],
        });
      } else {
        showNotification("error", "Some error occured");
      }
      this.setState({ is_fetching_contracts: false });
    });
  };

  markContractApprovalStatus = (status) => {
    this.setState({
      is_marking_contract_status: true,
      mark_contract_status_as: status,
    });
    callApi(`admin/mark-contracts-approval-status`, "post", {
      contract_ids: this.state.selected_contract_ids,
      approval_status: status,
    }).then((res) => {
      console.log(res);
      if (res.status === "Success") {
        showNotification("success", "Status Updated Successfully!");
        this.setState({
          is_marking_contract_status: false,
        });
        this.fetchContracts();
      } else {
        showNotification("error", "Some error occured");
        this.setState({
          is_marking_contract_status: false,
        });
      }
    });
  };

  getFilters = () => {
    let filters = {};
    filters.pageNum = this.state.pageNum;
    filters.pageSize = this.state.pageSize;
    if (this.state.qtext) {
      filters.isSearching = true;
      filters.qtext = this.state.qtext;
    }
    filters.isFiltering = true;
    filters.isSearching = true;
    filters[this.state.searchObjCompanyName.keyName] =
      this.state.searchObjCompanyName.value;
    filters[this.state.searchObjCustomer.keyName] =
      this.state.searchObjCustomer.value;
    filters[this.state.searchObjContractID.keyName] =
      this.state.searchObjContractID.value;
    filters[this.state.searchObjAdminUser.keyName] =
      this.state.searchObjAdminUser.value;
    filters[this.state.searchObjRequesteBy.keyName] =
      this.state.searchObjRequesteBy.value;
    filters[this.state.sliderObjTerm.keyName] = {
      min: this.state.sliderObjTerm.currentMin,
      max: this.state.sliderObjTerm.currentMax,
    };
    filters.sort_by = "";
    filters[this.state.sliderObjPayments.keyName] = {
      min: this.state.sliderObjPayments.currentMin,
      max: this.state.sliderObjPayments.currentMax,
    };
    filters[this.state.dateObjContractStart.keyName] = {
      from: this.state.dateObjContractStart.date[0],
      to: this.state.dateObjContractStart.date[1],
    };
    filters[this.state.dateObjContractEnd.keyName] = {
      from: this.state.dateObjContractEnd.date[0],
      to: this.state.dateObjContractEnd.date[1],
    };
    filters[this.state.dateObjLastUpdated.keyName] = {
      from: this.state.dateObjLastUpdated.date[0],
      to: this.state.dateObjLastUpdated.date[1],
    };
    filters[this.state.dateObjDateRequested.keyName] = {
      from: this.state.dateObjDateRequested.date[0],
      to: this.state.dateObjDateRequested.date[1],
    };
    filters[this.state.selectorObjStatus.keyName] = {
      in: this.state.selectorObjStatus.elementsArray.map((item) => {
        if (item.selected) {
          return item.label;
        }
      }),
    };
    return filters;
  };

  hasMore = () => {
    console.log(
      "TOTAL_COUNT ",
      this.state.totalCount,
      this.state.pageSize,
      this.state.pageNum
    );
    return this.state.totalCount + 1 > this.state.pageNum * this.state.pageSize;
  };

  loadMore = () => {
    this.setState({ pageNum: this.state.pageNum + 1 });
    this.fetchContracts();
  };

  handleApplyFilters = () => {
    this.fetchContracts();
  };

  handleSearchFilter = (obj) => {
    let searchObj = {};
    searchObj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(searchObj, () => {
      this.handleApplyFilters();
    });
  };

  handleSliderFilter = (obj) => {
    let sliderobj = {};
    sliderobj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(sliderobj, () => {
      this.handleApplyFilters();
    });
  };

  handledatePicker = (obj) => {
    let datePickerObj = {};
    datePickerObj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(datePickerObj, () => {
      this.handleApplyFilters();
    });
  };

  inputSelectorHandler = (obj) => {
    let selectorObj = {};
    selectorObj[this.keyStateObjNameDict[obj.keyName]] = obj;
    this.setState(selectorObj, () => {
      this.handleApplyFilters();
    });
  };

  handleSegmentChange = (segmentName, contractDetails) => {
    this.setState({
      activeSegment: segmentName,
      contractDetails: contractDetails,
    });
  };

  render() {
    return (
      <div className="row main-body">
        <AdminHead />
        {this.state.activeSegment === "contractlist" ? (
          <section id='admin-company' className="col">
            <div class='d-flex justify-content-between form-group has-search'>
              <div className='d-flex justify-content-between'>
                {this.state.is_marking_contract_status &&
                this.state.mark_contract_status_as === "Approved" ? (
                  <button className='app-btn btn-approve' disabled={true}>
                    <img src='../../../../../assets/icon-done.svg' alt=''></img>
                    Loading...
                  </button>
                ) : (
                  <button
                    className='app-btn btn-approve'
                    onClick={() => this.markContractApprovalStatus("Approved")}>
                    <img src='../../../../../assets/icon-done.svg' alt=''></img>
                    Approve
                  </button>
                )}
                <button className='app-btn btn-no-approve' onClick={() => this.markContractApprovalStatus("Not Approved")}>
                  <img
                    src='../../../../../assets/icon-clear.svg'
                    alt=''></img>
                  Not Approved
                </button>
                <button className='app-btn btn-hold' onClick={() => this.markContractApprovalStatus("On Hold")}>
                  <img
                    src='../../../../../assets/icon-history.svg'
                    alt=''></img>
                  On Hold
                </button>
              </div>
              <button
                data-toggle='modal'
                data-target='#addnewmodal'
                className='admin-company-btn'>
                <img src='../../../../../assets/plus.ico' alt=''></img>
                Add New
              </button>

              {/* <!-- Modal --> */}
              <AddNewModal/>
            </div>
            <div className='table-head'>
              <table className='table team'>
                <ContractsTableHeader
                  searchObjCompanyName={this.state.searchObjCompanyName}
                  searchObjCustomer={this.state.searchObjCustomer}
                  searchObjContractID={this.state.searchObjContractID}
                  searchObjAdminUser={this.state.searchObjAdminUser}
                  searchObjRequesteBy={this.state.searchObjRequesteBy}
                  sliderObjTerm={this.state.sliderObjTerm}
                  sliderObjPayments={this.state.sliderObjPayments}
                  sliderObjTradedArr={this.state.sliderObjTradedArr}
                  dateObjContractStart={this.state.dateObjContractStart}
                  dateObjContractEnd={this.state.dateObjContractEnd}
                  dateObjDateRequested={this.state.dateObjDateRequested}
                  dateObjLastUpdated={this.state.dateObjLastUpdated}
                  selectorObjStatus={this.state.selectorObjStatus}
                  handleSliderFilter={this.handleSliderFilter}
                  inputSelectorHandler={this.inputSelectorHandler}
                  handleSearchFilter={this.handleSearchFilter}
                  handledatePicker={this.handledatePicker}
                />
                <tbody style={{ display: 'none' }}></tbody>
              </table>
          </div>
          <div className='tableFixHead table-body'>
            <table
                className='table team'
            >
              <thead></thead> 
                <tbody className='team-body'>
                  {this.state.contracts &&
                    this.state.contracts.map((item, index) => (
                      <ContractsTableRow
                        key={item._id}
                        item={item}
                        loader={this.state.is_fetching_contracts}
                        hasMore={this.hasMore}
                        loadMore={this.loadMore}
                        dataLength={this.state.contracts.length}
                        index={index}
                        onChangeContractCheckbox={this.onChangeContractCheckbox}
                        selected_contract_ids={this.state.selected_contract_ids}
                        handleSegmentChange={this.handleSegmentChange}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </section>
        ) : (
          <ContractDetailPage
            contractDetails={this.state.contractDetails}
            handleSegmentChange={this.handleSegmentChange}
          />
        )}
      </div>
    );
  }
}

export default ContractsPage;
