import React from 'react';
import { Modal } from "react-bootstrap";
import "../../css/Modal.scss";
import styles from './ModeratorNotes.module.scss';

const ModeratorNotes = ({ showModal = false, setShowModal, children }) => {
  const { ModeratorNotesModal, crossBtn } = styles;
  const closeModal = () => setShowModal(false);

  return (
    <Modal
      show={showModal}
      className={ModeratorNotesModal}
      backdrop="static"
      keyboard={false}
      onHide={closeModal}
    >
      <Modal.Header className="border-0 mb-2">
        <div className="saveRow">
          <div>Moderator Notes</div>
        </div>
        <button className={crossBtn} >
          <span onClick={closeModal}>
            <img src="../../../../../assets/modal-cross.svg" alt=""></img>
          </span>
        </button>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
    </Modal>
  )
}

export default ModeratorNotes;