import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import callApi from '../../../../../util/apiCaller';
import { priceFormat, showNotification } from '../../../../../util/utility';
import '../style/Nach.css';
import styles from './NachCollectionsTableRow.module.scss';
import moment from "moment";
import _ from 'lodash';

const {
    CollectionProgressBar, GreyPart, GreenPart, ProgressBottom, ProgressTop, greenText, redText,
    CollectionComments
} = styles;

const TableInput = styled.input`
    height: 24px;
    width: 100%;
    max-width: 101px;
    opacity: 0.7;
    color: #000000;
    font-size: 12px;
    font-weight: 500;
    border: 0.5px solid #4C8DFF;
    border-radius: 4px;
`;

function NachCollectionsTableRow({
    item,
    fetchNachList,
    showEditIcon,
    setShowEditIcon,
    loader,
    hasMore,
    loadMore,
    selectedNachList,
    handleCheckBoxClick,
    dataLength,
    index,
    isUserHasEditAccess
}) {

    const observer = useRef();
    const lastInvesteeRef = useCallback(
        (node) => {
            if (loader) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore()) loadMore();
            });
            if (node) observer.current.observe(node);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loader, hasMore()]
    );

    useEffect(() => {
        setIsEditing(false)
        setData({
            payment_amount: item?.payment_amount || 0,
            execution_date: item?.execution_date,
        })

        return () => {
            setIsEditing(false)
            setShowEditIcon(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    const [isEditing, setIsEditing] = useState(false)
    const [showRisk, setShowRisk] = useState(false);
    const [showMethod, setShowMethod] = useState(false);

    const [data, setData] = useState({
        payment_amount: item?.payment_amount || 0,
        execution_date: item?.execution_date,
    })
    const { payment_amount, execution_date } = data

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === 'payment_amount' && value > item?.nach_amount) {
            showNotification("Error", "Payment Amount can't be greater than Max value")
            return
        }
        setData((prevVal) => ({ ...prevVal, [name]: value }))
    }

    const updateNachDetails = () => {
        let obj = { ...data }
        let tempItem = item
        obj['_id'] = item?._id
        callApi('nach_details/nach_scheduling/nach_update', 'post', obj)
            .then(res => {
                if (res.status === "Success") {
                    setIsEditing(false)
                    tempItem.execution_date = res?.data?.execution_date
                    tempItem.payment_amount = res?.data?.payment_amount
                    fetchNachList(false,"",index,tempItem)
                    setShowEditIcon(true)
                }
                else showNotification("Error", 'Some error occured')
            })
    }
    const handleEditClick = () => {
        setIsEditing(true)
        setShowEditIcon(false)
    }

    const handleClose = () => {
        setIsEditing(false)
        setShowEditIcon(true)
    }

    const renderer = (
        <>
            <td style={{ minWidth: '150px', maxWidth: '160px' }} className="text-truncate">
                <div className='dflex-ac'>
                    <input
                        checked={selectedNachList.some(n => n._id === item._id)}
                        // disabled={["Approved", "Not Approved"].includes(item.approval_status)}
                        type='checkbox'
                        onChange={(e) => handleCheckBoxClick(e.target.checked, item)}
                        disabled={item?.execution_status === "No Nach" || item?.execution_status === "Scheduled"}
                        className="mr-2"
                    ></input>
                    <span
                        style={{ cursor: "pointer" }}
                        title={item?.name}>
                        {item?.name}
                    </span>
                </div>
            </td>
            <td style={{ width: '12%' }}>
                {item?.mandate_id}
            </td>
            <td style={{ width: '12%' }}>
                {!_.isNil(item.nach_amount) ? `₹ ${priceFormat(item?.nach_amount)}` : '_'}
            </td>
            <td style={{ width: '12%' }}>
                {isEditing ?
                    (<TableInput
                        required
                        className="mb-0 team-input"
                        type="number"
                        name="payment_amount"
                        onChange={handleChange}
                        value={payment_amount} />)
                    : (<b>{!_.isNil(item.payment_amount) ? `₹ ${priceFormat(Math.ceil(item?.payment_amount))}` : '_'}</b>)
                }
            </td>
            <td style={{ width: '12%', wordBreak: 'break-all' }}>
                {isEditing ?
                    (<TableInput
                        required
                        className="mb-0 team-input"
                        type="date"
                        name="execution_date"
                        onChange={handleChange}
                        value={execution_date} />)
                    : (<span>{item?.execution_date ? moment(item?.execution_date).format("DD MMM YYYY") : '_'}</span>)
                }
            </td>
            <td style={{ width: '12%' }}>
                {/* <span className='No-Nach-nbg nach-table-status'>Bounced</span> */}
                <span className={`${_.replace(item?.execution_status, ' ', '-')}-nbg nach-table-status`}>
                    {item?.execution_status}
                </span>
            </td>
            <td
                style={{ width: '10%' }}
                className="text-center"
            >
                {isEditing && (<>
                    <button
                        onClick={updateNachDetails}
                        className='white border-0 mr-2'>
                        <img src="/assets/save.svg" alt="" />
                    </button>
                    <button
                        className='white border-0 mr-2'
                        onClick={handleClose}>
                        <img
                            src="/assets/clear-red.svg"
                            alt="" style={{ width: '16px' }} />
                    </button>
                </>)}
                {isUserHasEditAccess && !isEditing && showEditIcon && item.mandate_id && item?.execution_status !== "No Nach" && item?.execution_status !== "Scheduled" && (
                    <button
                        className='white border-0 mr-2'
                        onClick={handleEditClick}>
                        <img src="/assets/editIcon.svg" alt="" />
                    </button>
                )}
            </td>
        </>

    )

    if (dataLength === index + 1) {
        return <tr className="ht71px" ref={lastInvesteeRef} 
        // style={item.execution_status !== "No Nach" ? {} : { color: "#b20600", opacity: '0.7' }}
        >{renderer}</tr>;
    } else {
        return <tr className="ht71px" 
        // style={item.execution_status !== "No Nach" ? {} : { color: "#b20600", opacity: '0.7' }}
        >{renderer}</tr>;
    }
}

export default NachCollectionsTableRow;