export const feesStructureObj = {
    "Fixed Fee (with payments)": "INVESTOR_FEE_HNI_REGULAR",
    "Fixed IRR (with payments)": "INVESTOR_FEE_NBFC_REGULAR",
    "Fixed IRR (advance with first repayment)": "INVESTOR_FEE_NBFC_ADVANCE_FIRST_REPAYMENT",
    // "Fixed IRR (advance with payout)": "INVESTOR_FEE_NBFC_ADVANCE_PAYOUT"
    "Fixed ROI (with payments)":"INVESTOR_FEE_FIXED_ROI_PAYMENTS",
    "Fixed ROI PMT (with payments)":"INVESTOR_FEE_ROI_PMT"

};

export const feesStructureObjReverse = {
    "INVESTOR_FEE_HNI_REGULAR": "Fixed Fee (with payments)",
    "INVESTOR_FEE_NBFC_REGULAR": "Fixed IRR (with payments)",
    "INVESTOR_FEE_NBFC_ADVANCE_FIRST_REPAYMENT": "Fixed IRR (advance with first repayment)",
    // "INVESTOR_FEE_NBFC_ADVANCE_PAYOUT": "Fixed IRR (advance with payout)"
    "INVESTOR_FEE_FIXED_ROI_PAYMENTS" : "Fixed ROI (with payments)",
    "INVESTOR_FEE_ROI_PMT" : "Fixed ROI PMT (with payments)"

};

export const FEE_STRUCTURE = {
    INVESTOR_FEE_ROI_PMT : "INVESTOR_FEE_ROI_PMT",
    INVESTOR_FEE_FIXED_ROI_PAYMENTS : "INVESTOR_FEE_FIXED_ROI_PAYMENTS"
}