import {Drawer} from "@material-ui/core";
import {DRAWER_ENUMS, getTdsStatusColors} from "../CustomerRequests.tsx";
import './TdsDrawer.scss'
import React, {useEffect} from "react";
import {
    fetchPresignedUrl,
    getClaim,
    sendTdsReminderToInv,
    updateClaim
} from "../../../../../common/ApiHelpers.ts";
import {GENERIC_ERROR, SHOW_NOTIFICATION} from "../../../../../constants/enums.js";
import {showNotification} from "../../../../../util/utility.js";
import {useSelector} from "react-redux";
import {getEmail} from "../../../../App/AppReducer.js";
import RejectionModal from "../ActionModals/RejectionModal.tsx";
import ApprovalModal from "../ActionModals/ApprovalModal.tsx";
import ClaimsErrorMessage from "../ClaimsErrorMessage/ClaimsErrorMessage.tsx";
import {isEmpty} from "lodash";


interface TdsDrawerProps {
    openDrawer: string,
    setOpenDrawer: React.Dispatch<React.SetStateAction<string>>
    claimData: any
    getClaimData: () => void
}

interface documents {
    docId: string,
    documentName: string
}

interface TdsClaim {
    claimStatus: string;
    investorOrganizationId: string;
    documents: documents[],
    bankAccount: string,
    customerSupportQueryId: string,
    approvedAmount: string,
    investeeName: string,
    investorName: string,
    form16AStatus?: string,
    rejectReason?: string,
    utrNumber?:string,
    expectedRefundDate: string,
    extractedData: {
        amount: string,
        fromDate: string,
        income: string,
        toDate: string,
        pan: string
    }
}

const TdsDrawer = (props: TdsDrawerProps) => {
    const emptyTdsClaim = {
        investorOrganizationId: '',
        documents: [],
        bankAccount: '',
        customerSupportQueryId: '',
        approvedAmount: '',
        investeeName: '',
        form16AStatus:'',
        investorName: '',
        expectedRefundDate: '',
        claimStatus: '',
        extractedData: {
            amount: '',
            fromDate: '',
            income: '',
            toDate: '',
            pan: ''
        }
    }
    const {openDrawer, setOpenDrawer, claimData} = props;
    const [tdsClaim, setTdsClaim] = React.useState<TdsClaim>(emptyTdsClaim);
    const [showRejectModal, setShowRejectModal] = React.useState<boolean>(false);
    const [showApproveModal, setShowApproveModal] = React.useState<boolean>(false);
    const [messageCodes, setMessageCodes] = React.useState<string[]>([]);
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const email = useSelector(getEmail)
    const RECUR_CLUB: string = "RECUR_CLUB";
    const FOUNDERLINK: string = "FOUNDERLINK";
    const getTdsClaim = async () => {
        const res = await getClaim(claimData.customerQueryId);
        if (res) {
            setTdsClaim(res);
        } else {
            setTdsClaim(emptyTdsClaim);
            setOpenDrawer('')
        }
    }
    useEffect(() => {
        if (claimData && openDrawer === DRAWER_ENUMS.TDS) {
            getTdsClaim();
        }
    }, [claimData,openDrawer]);
    useEffect(() => {
        if (tdsClaim) {
            setMessageCodes([claimData.status]);
        }
    }, [claimData, tdsClaim]);
    const getErrorMessages = (messageCode: string) => {
        switch (messageCode) {
            case 'REJECTED':
            case 'INV. REJECTED':
                return {
                    message: (
                        <>
                            <span
                                style={{fontFamily: 'Gilroy-bold,serif'}}>Reject Reason</span> : {tdsClaim.rejectReason}
                        </>
                    ),
                    status: 'Warning',
                }
            case 'APPROVED':
                return {
                    message: (
                        <>
                            <span style={{fontFamily: 'Gilroy-bold,serif'}}>TDS claim approved: ₹ {tdsClaim.approvedAmount}</span> will be credited to {tdsClaim.bankAccount} account by {tdsClaim.expectedRefundDate}.
                        </>
                    ),
                    status: 'Success',
                }
        }
    }
    const rejectClaim = async (rejectReason: string) => {
        const data = {
            claimStatus: 'REJECTED',
            customerSupportQueryId: tdsClaim.customerSupportQueryId,
            rejectReason: rejectReason,
            activityBy: email,
        };
        const res = await updateClaim(data);
        if (res) {
            showNotification(SHOW_NOTIFICATION.SUCCESS, 'Updated Successfully');
            setShowRejectModal(false);
            setOpenDrawer('');
            props.getClaimData();
        } else {
            showNotification(SHOW_NOTIFICATION.ERROR, 'Failed To Submit Claim');
            setShowRejectModal(false);
        }
    }
    const approveClaim = async (tdsClaim: any) => {
        let toggle = window.confirm(`Want to Approve TDS payment with amount ${tdsClaim.approvedAmount}?`);
        if (!toggle) return;

        const data = {
            claimStatus: 'APPROVED',
            customerSupportQueryId: tdsClaim.customerSupportQueryId,
            approvedAmount: tdsClaim.approvedAmount,
            activityBy: email,
        };
        const res = await updateClaim(data);
        if (res) {
            showNotification(SHOW_NOTIFICATION.SUCCESS, 'Updated Successfully');
            setShowApproveModal(false);
            setOpenDrawer('');
            props.getClaimData();
        } else {
            showNotification(SHOW_NOTIFICATION.ERROR, 'Failed To update Claim');
            setShowApproveModal(false);
        }
    }
    const verifyClaim = async (approvedLimit: string) => {
        const data = {
            claimStatus: 'VERIFIED',
            customerSupportQueryId: tdsClaim.customerSupportQueryId,
            approvedAmount: approvedLimit,
            activityBy: email,
        };
        const res = await updateClaim(data);
        if (res) {
            showNotification(SHOW_NOTIFICATION.SUCCESS, 'Updated Successfully');
            setShowApproveModal(false);
            setOpenDrawer('');
            props.getClaimData();
        } else {
            showNotification(SHOW_NOTIFICATION.ERROR, 'Failed To update Claim');
            setShowApproveModal(false);
        }
    }
    const downloadForm16A = async (docId:string) => {
        const res = await fetchPresignedUrl(docId);
        if (res) {
            window.open(res.signedUrl, '_blank');
        } else {
            showNotification(SHOW_NOTIFICATION.ERROR, GENERIC_ERROR);
        }

    }
    const sendReminderToInv = async () => {
        setDisabled(true);
        const res = await sendTdsReminderToInv(tdsClaim.customerSupportQueryId, email);
        if (res) {
            setDisabled(false);
            showNotification(SHOW_NOTIFICATION.SUCCESS, res.responseData.responseMessage);
        } else {
            setDisabled(false);
            showNotification(SHOW_NOTIFICATION.ERROR, res.responseData.responseMessage??GENERIC_ERROR);
        }
    }
    const renderButtons = () => {
        if(tdsClaim.form16AStatus==='PENDING'){
            return(
                <button onClick={() => setOpenDrawer('')} className={'FooterCloseBtn'}>Close</button>
            )
        }
        if ([RECUR_CLUB,FOUNDERLINK].includes(tdsClaim.investorOrganizationId)) {
            switch(claimData.status){
                case 'SUBMITTED': 
                    return (
                        <>
                            <button onClick={() => setShowRejectModal(true)} className={'FooterRejectBtn'}>Reject Claim</button>
                            <button onClick={() => setShowApproveModal(true)} className={'FooterSendReminderBtn'}>Proceed to Verify</button>
                        </>
                    )
                case 'VERIFIED': 
                return (
                    <>
                        <button onClick={() => approveClaim(tdsClaim)} className={'FooterSendReminderBtn'}>Mark as Approved
                        </button>
                    </>
                )
            }
        } else if ([RECUR_CLUB,FOUNDERLINK].includes(tdsClaim.investorOrganizationId) && (claimData.status === 'APPROVED' || claimData.status === 'REJECTED')) {
            return (
                <button onClick={() => setOpenDrawer('')} className={'FooterCloseBtn'}>Close</button>
            )
        }
        switch (claimData.status) {
            case 'REJECTED':
            case 'APPROVED':
            case 'INV. REJECTED':
                return (
                    <button onClick={() => setOpenDrawer('')} className={'FooterCloseBtn'}>Close</button>
                )
            default:
                return (
                    <>
                        <button disabled={disabled} onClick={() => setShowRejectModal(true)} className={'FooterRejectBtn'}>Reject Claim</button>
                        <button disabled={disabled} onClick={() => sendReminderToInv()} className={'FooterSendReminderBtn'}>Send Reminder to
                            Investor
                        </button>
                    </>
                )
        }
    }

    return (
        <>

            <Drawer
                anchor="right"
                open={openDrawer === DRAWER_ENUMS.TDS&& tdsClaim.customerSupportQueryId===claimData.customerQueryId}
                onClose={() => {
                    setOpenDrawer('')
                }}
                ModalProps={
                    {

                        style: {
                            zIndex: 100,
                        }
                    }}
                PaperProps={{
                    style: {
                        width: '37%',
                        zIndex: 100
                    },
                }}
                disableEnforceFocus
            >
                <div className={'NewTdsDrawerWrapper'}>
                    <div className={'DrawerHeading'}>
                        <div className={'HeadingText'}>
                            <div>View TDS Details</div>
                            <div className={'StatusBanner'}
                                 style={{
                                     color: getTdsStatusColors(claimData.status).color,
                                     background: getTdsStatusColors(claimData.status).banner,
                                 }}>
                                {claimData.status}
                            </div>
                        </div>
                        <div className={'CrossIcon'}>
                            <img onClick={() => setOpenDrawer('')} src="../../../../../assets/modal-cross.svg"
                                 alt=""></img>
                        </div>
                    </div>
                    <div className={'DrawerBody'}>
                        <div className={'FileContainer'}>
                            <h6>Uploaded Form 16A</h6>
                            {tdsClaim?.documents&&tdsClaim?.documents.map((document)=>{
                                return(
                                    <div className={'File'}>
                                        <div onClick={() => downloadForm16A(document.docId)} className={'FileName'}>
                                            {document.documentName}
                                        </div>
                                    </div>
                                )
                            })}
                            <div className={'TextContainer'}>
                                <div className={'Icon'}>
                                    <img src='https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/TickMarkGreen.svg'
                                         alt={'icon'}/>
                                </div>
                                <div className={'Text'}>
                                    <h4>Form 16A Details</h4>
                                    <p><span>Company: </span>{tdsClaim.investeeName??'-'}</p>
                                    <p><span>Investor: </span>{tdsClaim.investorName??'-'}</p>
                                    <p><span>TDS Amount Claimed: </span>{tdsClaim?.extractedData?.amount?`₹ ${tdsClaim?.extractedData?.amount}`:'-'}</p>
                                    <p>
                                        <span>TDS Filing Quarter: </span>{tdsClaim.extractedData?.fromDate}-{tdsClaim.extractedData?.toDate}
                                    </p>
                                    {tdsClaim.claimStatus === 'VERIFIED' &&<p><span>TDS Amount Verified: </span>₹ {tdsClaim.approvedAmount}</p>}
                                    {tdsClaim.utrNumber&&<p><span>UTR Number: </span>{tdsClaim.utrNumber}</p>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'TdsDrawerFooter'}>
                    <div>
                        {!isEmpty(tdsClaim) &&
                            messageCodes &&
                            messageCodes.length > 0 &&
                            messageCodes.map((messageCode: string, index: number) => {
                                if (
                                    getErrorMessages(messageCode)?.message &&
                                    getErrorMessages(messageCode)?.status
                                ) {
                                    return (
                                        <div style={{marginTop: '0.5rem'}} key={index}>
                                            <ClaimsErrorMessage
                                                message={getErrorMessages(messageCode)?.message ?? ''}
                                                status={getErrorMessages(messageCode)?.status ?? ''}
                                            />
                                        </div>
                                    );
                                } else {
                                    return;
                                }
                            })}
                    </div>
                    <div className={'FooterButtonsContainer'}>
                        {renderButtons()}
                    </div>
                </div>
            </Drawer>
            {showRejectModal && <RejectionModal show={showRejectModal} onClose={() => setShowRejectModal(false)}
                                                rejectClaim={rejectClaim}/>}
            {showApproveModal &&
                <ApprovalModal show={showApproveModal} onClose={() => setShowApproveModal(false)}
                               approveClaim={verifyClaim} amount={tdsClaim.extractedData.amount}/>
            }
        </>
    )
}
export default TdsDrawer;