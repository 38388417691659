import './ActionModals.scss'
import {Modal} from "react-bootstrap";
import React, {useEffect} from "react";
import {numberOnly} from "../../../../../util/utility.js";

interface ApprovalAllModalProps{
    show:boolean
    onClose:()=>void
    approveAllClaims:()=>void
    ticketCount:string
}
const ApprovalAllModalProps = (props:ApprovalAllModalProps)=>{
    const {show,onClose,approveAllClaims} = props;
    const [ticketCount,setTicketCount] = React.useState<string>(props.ticketCount??'');
    const [disabled,setDisabled] = React.useState<boolean>(false);
    const handleClick= async ()=>{
        setDisabled(true)
        await approveAllClaims()
    }
    return(
        <Modal
            show={show}
            onHide={onClose}
        >
            <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: '24px', fontFamily: 'Gilroy-Semibold, serif' }}>Approve TDS Tickets</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <p>Are you sure you want to approve all <b>{ticketCount}</b> verified tickets ?</p>
            </Modal.Body>
            <Modal.Footer style={{ gap: "0.8rem"}}>
                <button onClick={onClose} className={'RejectionAllBtn'}>No</button>
                <button disabled={disabled} onClick={()=>handleClick()} className={'ApproveAllBtn'}>Yes</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ApprovalAllModalProps;