import styles from "./Summary.module.scss";

const {
    options,
    progressBar,
    metPlusPartial,
    met,
    stats,
    statsMet,
    statsNotMet,
    statsPartially,
    notAvailable,
} = styles;

const Summary = ({
    isDataPresent,
    metValue,
    metUnverifiedValue,
    partialValue,
    notMetValue,
    totalValue,
    title,
}) => {
    const metPlusPartialValue = (metValue ?? 0) + (metUnverifiedValue ?? 0) + (partialValue ?? 0);
    const metPlusPartialPerc = () => {
        const perc = (metPlusPartialValue / totalValue) * 100;
        return {
            width: `${perc}%`,
        };
    };

    const metPerc = () => {
        const perc = (((metValue ?? 0) + (metUnverifiedValue ?? 0)) / metPlusPartialValue) * 100;
        return {
            width: `${perc}%`,
        };
    };

    return (
        <div>
            {title}{" "}<span className={options}> (Met/Partially Met/Not Met)</span>
            {isDataPresent ? (
                <>
                    <div className={stats}>
                        <span className={statsMet}>{metValue + (metUnverifiedValue ?? 0)} Met{metUnverifiedValue ? ` (${metUnverifiedValue} Unverified)` : ''}</span>
                        <span className={statsPartially}>{partialValue} Partially</span>
                        <span className={statsNotMet}>{notMetValue} Not Met</span>
                    </div>
                    <div className={progressBar}>
                        <div className={metPlusPartial} style={metPlusPartialPerc()}>
                            <div className={met} style={metPerc()}></div>
                        </div>
                    </div>
                </>
            ) : (
                <div className={notAvailable}>N/A</div>
            )}
        </div>
    );
};

export default Summary;
