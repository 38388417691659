import moment from "moment";
import { useEffect } from "react";
import { getAmountInLacs } from "../../../../../util/utility";
// import {Link} from 'react-router-dom';
import styles from './OrderTransactionListRow.module.scss'

const {
  repayments,
  topRow,
  repaymentBar,
  greenBar,
  paidAmount,
  remainingAmount
} = styles;

function OrderRepaymentsTableRow(props) {
  useEffect(() => {
    return () => {
      props.emptySelectedIds()
    }
  }, []);

  const setGreenBarWidth = () => {
    const totalAmount = props?.item?.transaction_amount;
    const paidAmount = props?.item?.actual_recur_payment_amount ?? 0;
    let perc = ((paidAmount/totalAmount)*100);
    if(totalAmount <= 0) perc = 0;
    else if(perc < 3.5) perc = 3.5;
    return {
      width: `${perc}%`
    }
  }

  const getRemainingAmount = () => {
    const totalAmount = props?.item?.transaction_amount ?? 0;
    const paidAmount = props?.item?.actual_recur_payment_amount ?? 0;
    const remainingAmount = totalAmount - paidAmount;
    return getAmountInLacs(remainingAmount);
  }

  return (
    <tr>
      <td style={{ maxWidth: '100px', width: '20%' }}>
        {props.item?.investor_organization_id?.name}
      </td>
      <td className="text-truncate" style={{ maxWidth: '100px', width: '16%' }}>
        <span data-bs-toggle="tooltip" data-bs-html="true" title={props.item?.investee_organization_id?.name}>
          {props.item?.investee_organization_id?.name}
        </span>
      </td>
      <td style={{ width: '9%', color: "#49A71B"}}>{props.item.order_id}</td>
      <td style={{ width: '14%', color: "#49A71B"}}>{props.item.payment_number}</td>
      <td style={{width: "20%"}}>
        <div className={repayments}>
            <div className={topRow}>
                <div className={paidAmount}>
                  ₹ { getAmountInLacs(props?.item?.actual_recur_payment_amount ?? 0)} lacs paid
                  </div>
                <div className={remainingAmount}>₹ {getRemainingAmount() ?? 0} lacs remaining</div>
            </div>
            <div className={repaymentBar}>
                <div className={greenBar} style={setGreenBarWidth()}></div>
            </div>
        </div>
      </td>
      <td style={{ width: '14%' }}>
        {props.item.transaction_date ? moment(props.item.transaction_date).utcOffset(0).format("DD MMM YYYY") : "NA"}
      </td>
      <td style={{ width: '9%', color: "#980D0D" }}>
        <span className='bold-table-cel'>{props.item.transaction_status}</span>
      </td>``
    </tr>
  );
}

export default OrderRepaymentsTableRow;
