import React, { useEffect, useState } from "react";
import PgIntegration from "./PgIntegrations/PgIntegration";
import styles from "../PgCollections/PgCollections.module.scss";
import { isEmpty, remove } from "lodash";
import { useSelector } from "react-redux";
import {
  getInvesteesOrganization,
  isEmptyConnectedPgList,
} from "../../../../../../../AdminReducers";
import useCallApi from "../../../../../../../../../util/useApiCaller";
import { showNotification } from "../../../../../../../../../util/utility";
import ConnectPgModal from "./ConnectPgModal";
import callApi from "../../../../../../../../../util/apiCaller";
import PgCollectionsTableRow from "./PgCollectionsTableRow";
import { mergeByProperty } from "../../../../../../../../../util/calculateTermUtil";
import PageLoader from "../../../../../../../../../components/loader/PageLoader";

const { ExecuteCollection, PGCollectionTable, ConnectNewPGBtn } = styles;

export default function PgCollections() {
  const investeeOrgId = useSelector(getInvesteesOrganization)?._id;
  const isPGIntegrationListEmpty = useSelector(isEmptyConnectedPgList);
  const [pgCollectionAmount, setPgCollectionAmount] = useState(null);
  const [isDisabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [
    {
      fetching: fetchingAllPgCollections,
      error: errorAtFetchingAllPgCollection,
    },
    fetchAllPgCollectionsApiCall,
  ] = useCallApi();

  const [pgCollectionsList, setPgCollectionsList] = useState({});
  const [selectedPgCollectionsArr, setSelectedPgCollectionsArr] = useState([]);
  const [showConnectPgModal, setShowConnectPgModal] = useState(false);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    fetchPgCollections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Api Call to fetch next page after pageNum is increase
  useEffect(() => {
    if (pageNum > 1) fetchPgCollections(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum]);

  useEffect(() => {
    if (!isPGIntegrationListEmpty && pgCollectionAmount) {
      setDisabled(false);
      return;
    }
    setDisabled(true);
  }, [pgCollectionAmount, isPGIntegrationListEmpty]);
  // Whenever there is an error notify
  useEffect(() => {
    if (errorAtFetchingAllPgCollection) {
      const message =
        errorAtFetchingAllPgCollection?.responseData?.responseMessage ??
        "Some error occurred";
      showNotification("error", message);
    }
  }, [errorAtFetchingAllPgCollection]);

  const fetchPgCollections = (shouldMerge = false, pgNum = pageNum) => {
    fetchAllPgCollectionsApiCall(
      "collection/all",
      "post",
      {
        companyId: investeeOrgId,
        requestId: `${investeeOrgId}${+new Date()}`,
        pageNumber: pgNum,
      },
      {},
      false,
      false,
      true
    ).then((res) => {
      if (res?.responseData?.responseCode === 20) {
        setHasMorePages(!isEmpty(res.pgCollections));
        setPgCollectionsList((prevList) => {
          if (shouldMerge)
            return mergeByProperty(
              [...prevList],
              res.pgCollections,
              "collectionId"
            );
          else return res.pgCollections;
        });
      }
    });
  };
  const executeCustomCollection = () => {
    setLoading(true);
    callApi(
      "/collection/amount",
      "post",
      {
        companyId: investeeOrgId,
        requestId: `${investeeOrgId}${+new Date()}`,
        collectionAmount: Number(pgCollectionAmount ?? null),
      },
      {},
      false,
      false,
      true
    ).then((res) => {
      if (res?.responseData?.responseCode === 20) {
        setLoading(false);
        showNotification(
          "success",
          `₹ ${res.collectedAmount} collected successfully`
        );
        fetchPgCollections(false, 1);
      } else {
        setLoading(false);
        showNotification("error", `${res.responseData.responseMessage}`);
      }
    });
  };

  const loadAnotherPage = () => {
    setPageNum((prevVal) => prevVal + 1);
  };

  const handleChange = (value, key, index, isNumber = false) => {
    let newList = [...pgCollectionsList];
    newList[index][key] = isNumber ? Number(value) : value;
    setPgCollectionsList(newList);
  };

  const handleCheckBoxClick = (isChecked, collectionId, index) => {
    setSelectedPgCollectionsArr((prevSelectedArr) => {
      let NewPgSelectedList = [...prevSelectedArr];
      if (isChecked) {
        NewPgSelectedList.push(collectionId);
      } else if (!isChecked)
        remove(NewPgSelectedList, (n) => n === collectionId);
      return NewPgSelectedList;
    });
  };

  const validateSelectedList = (list) => {
    let message = "";
    let isValid = true;
    list.forEach((collection) => {
      const amountAvailable =
        collection?.collectionAmount - collection?.amountCollected;
      if (!collection.amountToCollect) {
        message = "PLease enter amount to collect";
        isValid = false;
      } else if (collection.amountToCollect < 0) {
        message = "Amount to collect must be greater than zero";
        isValid = false;
        return;
      } else if (collection.amountToCollect > amountAvailable) {
        message =
          "Amount to collect must be less than equal to the amount available";
        isValid = false;
        return;
      }
    });
    return { message, isValid };
  };

  const executeSelectedCollections = () => {
    let pgCollectionListToExecute = pgCollectionsList.filter(
      ({ collectionId }) => selectedPgCollectionsArr.includes(collectionId)
    );

    const validationResult = validateSelectedList(pgCollectionListToExecute);
    if (!validationResult.isValid) {
      showNotification("error", validationResult.message);
      return;
    }

    callApi(
      "collection/execute",
      "post",
      {
        companyId: investeeOrgId,
        requestId: `${investeeOrgId}${+new Date()}`,
        pgCollections: pgCollectionListToExecute,
      },
      {},
      false,
      false,
      true
    ).then((res) => {
      if (res?.responseData.responseCode === 20) {
        setPageNum(1);
        setSelectedPgCollectionsArr([]);
        fetchPgCollections(false, 1);
        // dispatch(fetchConnectedPGList(investeeOrgId))
      } else {
        const message =
          res?.responseData?.responseMessage ?? "Some error occurred";
        showNotification("Error", message);
      }
    });
  };

  return (
    <>
      <ConnectPgModal
        show={showConnectPgModal}
        pgToEdit={{}}
        onHide={() => setShowConnectPgModal(false)}
      />

      {!isPGIntegrationListEmpty && <PgIntegration />}

      <div className="dflex justify-content-betweeen mt-5 mb-3 align-items-center">
        <span className="fs18 fw600">PG Collections</span>
        <div className="mt-2 dflex align-items-center">
          <input
            type="text"
            className={`${styles.AmountToCollectInput} mr-2`}
            value={pgCollectionAmount}
            placeholder="Amount to Collect"
            onChange={(e) =>
              setPgCollectionAmount(e.target.value.replace(/\D/g, ""))
            }
          />
          {loading && <PageLoader />}

          <button
            disabled={isDisabled}
            className={ExecuteCollection}
            onClick={() => executeCustomCollection()}
          >
            Collect Amount
          </button>
        </div>
        <div>
          {/* {isPGIntegrationListEmpty && (
            <button
              className={ConnectNewPGBtn}
              onClick={() => setShowConnectPgModal(true)}
            >
              Connect New PG
            </button>
          )} */}
          <button
            className={ExecuteCollection}
            disabled={isEmpty(selectedPgCollectionsArr)}
            style={
              isEmpty(selectedPgCollectionsArr)
                ? {
                    cursor: "no-drop",
                    backgroundColor: "#b7b7b7",
                    border: "none",
                    color: "rgba(0, 0, 0, 0.65)",
                  }
                : {}
            }
            onClick={executeSelectedCollections}
          >
            Execute Collection
          </button>
        </div>
      </div>
      <div className={PGCollectionTable}>
        <table className="table">
          <thead>
            <tr>
              <th />
              <th>Date</th>
              <th>Customer Email</th>
              <th>Payment Gateway</th>
              <th>Amount</th>
              <th>Amount Available</th>
              <th>Collection</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(pgCollectionsList) &&
              pgCollectionsList?.map((item, index) => (
                <PgCollectionsTableRow
                  index={index}
                  selectedPgCollectionsArr={selectedPgCollectionsArr}
                  handleCheckBoxClick={handleCheckBoxClick}
                  item={item}
                  hasMore={hasMorePages}
                  loadMore={loadAnotherPage}
                  loader={fetchingAllPgCollections}
                  dataLength={pgCollectionsList?.length}
                  handleChange={handleChange}
                />
              ))}
          </tbody>
        </table>
      </div>
      {fetchingAllPgCollections && (
        <div id="loading-spinner">
          <div
            className="spin-icon"
            style={{ width: "50px", height: "50px" }}
          ></div>
        </div>
      )}
    </>
  );
}
