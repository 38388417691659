import { CURRENT_TAB_DATA_TYPE, CURRENT_TAB_TYPE } from '../../../../../../../../../../common/ENUM';

function Tabs({ currentTab,
  setCurrentTab,
  setDataType,
  setEditClassification,
  setBurnClicked }) {

  const getTabName = (tab) => {
    switch (tab) {
      case CURRENT_TAB_TYPE.balance_sheets: return 'Balance Sheet';
      case CURRENT_TAB_TYPE.cashflows: return 'Cashflow';
      case CURRENT_TAB_TYPE.pnl: return 'PNL';
      case CURRENT_TAB_TYPE.invoices: return 'Invoicing';
      case CURRENT_TAB_TYPE.GST: return 'GST';
      case CURRENT_TAB_TYPE.bank_statements: return 'Bank Statement';
      case CURRENT_TAB_TYPE.contracts: return 'Contracts';
      case CURRENT_TAB_TYPE.debt_schedule: return 'Debt Schedule';
      default: return '-'
    }
  }

  const onTabClick = (tab) => {
    setCurrentTab(tab);
    setEditClassification(false);

    switch (tab) {
      case CURRENT_TAB_TYPE.balance_sheets:
      case CURRENT_TAB_TYPE.cashflows:
      case CURRENT_TAB_TYPE.pnl:
        setDataType(CURRENT_TAB_DATA_TYPE.classified);
        break;
      case CURRENT_TAB_TYPE.invoices:
        setDataType(CURRENT_TAB_DATA_TYPE.raw)
        break;
      case CURRENT_TAB_TYPE.GST:
        setDataType(CURRENT_TAB_DATA_TYPE.profile);
        break;
      case CURRENT_TAB_TYPE.bank_statements:
        setDataType("summary");
        setBurnClicked(false);
        break;
      case CURRENT_TAB_TYPE.contracts:
        setDataType(CURRENT_TAB_DATA_TYPE.contracts);
        setBurnClicked(false);
        break;
      case CURRENT_TAB_TYPE.debt_schedule:
        setDataType('summary');
        break;
      default: return;
    }
  }


  return (
    <ul className="nav nav-tabss" role="tablist">
      {Object.keys(CURRENT_TAB_TYPE).map((tab) => (
        <li className='nav-item'>
          <span
            className={
              "nav-linkk " + (currentTab === CURRENT_TAB_TYPE[tab] && "activeTab")
            }
            data-toggle="tab"
            onClick={() => onTabClick(CURRENT_TAB_TYPE[tab])}
            style={{ paddingBottom: "7px" }}
          >
            {getTabName(CURRENT_TAB_TYPE[tab])}
          </span>
        </li>
      ))}
    </ul>
  )
}

export default Tabs