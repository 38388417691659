export default function LoadingSpinner({
    className = 'mr-1',
    style = {},
    width = '8px',
    height = '8px',
}) {
    return (
        <div id="loading-spinner" className={className} style={style}>
            <div className="spin-icon" style={{ width: width, height: height }}></div>
        </div>
    );
}
