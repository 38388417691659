import React from 'react';
import './UploadCard.module.scss';


export const UploadAnotherFile = ({ handleClick }: any) => {
    return (
        <div className='width100'>
            <div className='UploadAnother' onClick={handleClick}>
                <img src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/plus-blue.svg" alt="Add" className="r-mr-2 r-mb-1" />
                <span>Upload another file</span>
            </div>
        </div>
    );
};

export const UploadDocBox = ({ handleClick, handleDrag, handleDrop, mainText }: any) => {
    return (
        <button
            className='bankStmtBtn'
            onClick={handleClick}
            onDrop={handleDrop}
            onDragOver={handleDrag}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
        >
            <span>
                <img
                    src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/cloud_upload.svg"
                    className={`r-mr-1 uploadImg`}
                    alt=""
                />
                <span className="fs-12 text-blue4">{mainText}</span>
            </span>
            <span className="fs-10 text-grey9">
                <i>Click or drag here to upload files</i>
            </span>
        </button>
    );
};
