import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import UnderwritingTableHeader from "./components/UnderwritingTableHeader";
import UnderwritingTableRow from "./components/UnderwritingTableRow";
import {
  fetchUnderwritingClassificationData,
  fetchUnderwritingData,
  synchronizeContracts,
} from "../../../../../../../../../../App/AppActions";
import {
  getUnderwritingLastDate,
  getUnderwritingCurrBalance,
  getUnderwritingAccNo,
  getUnderwritingDataHeaders,
  getUnderwritingDataRows,
  getIsFetchingUnderwritingData,
  getUnderwritingClassificationData,
  getIsFetchingUnderwritingClassificationData,
  getContractsSynced,
  getLastSyncedDate,
  getUnderwritingODAccounts,
  getUnderwritingODLimits,
  getUnderwritingCurrBalanceStr,
  getUnderwritingODLimitDetails,
  getFdBalance,
  getMutualFundAmount,
} from "../../../../../../../../../../App/AppReducer";
import styles from "../../bankstats.module.css";
import PageLoader from "../../../../../../../../../../../components/loader/PageLoader";
import callApi from "../../../../../../../../../../../util/apiCaller";
import {
  CURRENT_TAB_DATA_TYPE,
  CURRENT_TAB_TYPE,
  SORT_COL_DIRECTION,
} from "../../../../../../../../../../../common/ENUM";
import whitePlus from '../../../../../../../../../../../assets/white-plus.svg';

import "./UnderwrintingTable.scss";
import { showNotification } from "../../../../../../../../../../../util/utility";
import { BankAlerts } from "./components/AlertBox/BankAlerts";


export default function UnderwritingTable(props) {
  const dispatch = useDispatch();

  const [
    underwritingClassificationDataState,
    setUnderwritingClassificationDataState,
  ] = useState([]);

  const rows_limit = 2000;
  const [limited_data_rows, set_limited_data_rows] = useState([]);
  const [resetOffset, setResetOffset] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [viewBalance, setViewBalance] = useState(false);
  const [resp_view_balance, set_resp_view_balance] = useState(null);
  const [sortConfig, setSortConfig] = useState(null);
  const [invoicesNameListObj, setInvoicesNameListObj] = useState(null);

  const [editOd, setEditOd] = useState(null);

  const subclassificationIdRef = useRef(null);
  const rowIndexRef = useRef();
  const countRef = useRef();
  const table_select = useRef();
  const bottomRef = useRef();
  const bankBottomRef = useRef();
  const underwritingDataHeaders = useSelector((state) =>
    getUnderwritingDataHeaders(state)
  );
  const underwritingDataRows = useSelector((state) =>
    getUnderwritingDataRows(state)
  );
  const [headerData, setHeaderData] = useState([]);

  useEffect(() => {
    const headerTransform = underwritingDataHeaders?.filter(item => item.name !== "Name").map(item => item.name);
    setHeaderData(headerTransform);
  }, [underwritingDataHeaders])

  useEffect(() => {
    if (
      props.currentTab === CURRENT_TAB_TYPE.invoices ||
      props.currentTab === CURRENT_TAB_TYPE.bank_statements ||
      props.currentTab === CURRENT_TAB_TYPE.GST
    ) {
      if (!resetOffset && props.saveScroll) {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
        bankBottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }
      set_limited_data_rows([
        ...underwritingDataRows,
      ]);
    }
    if (props.currentTab === CURRENT_TAB_TYPE.invoices) {
      const tempObj = { ...invoicesNameListObj };
      for (let item of underwritingDataRows) if (!(item?.name in tempObj)) tempObj[item?.name] = true;
      setInvoicesNameListObj(tempObj);
    }
  }, [underwritingDataRows]);

  const underwritingaccno = useSelector((state) => getUnderwritingAccNo(state));

  const underwritinglastdate = useSelector((state) =>
    getUnderwritingLastDate(state)
  );
  const underwritingcurrbalance = useSelector((state) =>
    getUnderwritingCurrBalance(state)
  );

  const underwritingcurrbalanceStr = useSelector((state) =>
    getUnderwritingCurrBalanceStr(state))

  const underwritingODAccounts = useSelector((state) => getUnderwritingODAccounts(state))
  const underwritingODLimits = useSelector((state) => getUnderwritingODLimits(state))
  const odLimitDetails = useSelector((state) => getUnderwritingODLimitDetails(state))
  const fdBalance = useSelector((state) => getFdBalance(state))
  const mutualFundAmount = useSelector((state) => getMutualFundAmount(state));

  const isFetchingUnderwritingData = useSelector((state) =>
    getIsFetchingUnderwritingData(state)
  );
  const underwritingClassificationData = useSelector((state) =>
    getUnderwritingClassificationData(state)
  );
  const isFetchingUnderwritingClassificationData = useSelector((state) =>
    getIsFetchingUnderwritingClassificationData(state)
  );

  const syncedContracts = useSelector(getContractsSynced);
  const lastSyncedDate = useSelector(getLastSyncedDate);

  if (
    props.currentTab === CURRENT_TAB_TYPE.bank_statements &&
    props.dataType === CURRENT_TAB_DATA_TYPE.raw
  ) {
    if (sortConfig !== null) {
      if (sortConfig.key === "amount") {
        for (let obj of limited_data_rows) {
          if (typeof obj[sortConfig.key] === "string") {
            obj[sortConfig.key] = parseFloat(
              obj[sortConfig.key].toString().replace(/,/g, "")
            );
          }
        }
        limited_data_rows.sort((a, b) => {
          if (sortConfig.direction === SORT_COL_DIRECTION.asc)
            return a[sortConfig.key] - b[sortConfig.key];
          return b[sortConfig.key] - a[sortConfig.key];
        });
      } else if (sortConfig.key === "date") {
        limited_data_rows.sort((a, b) => {
          let da = new Date(a[sortConfig.key]),
            db = new Date(b[sortConfig.key]);
          if (sortConfig.direction === SORT_COL_DIRECTION.asc) return da - db;
          else return db - da;
        });
      } else {
        limited_data_rows.sort((a, b) => {
          if (sortConfig.direction === SORT_COL_DIRECTION.asc)
            return (a[sortConfig.key] > b[sortConfig.key]) ? 1 : ((b[sortConfig.key] > a[sortConfig.key]) ? -1 : 0);
          return (b[sortConfig.key]) > a[sortConfig.key] ? 1 : ((a[sortConfig.key] > b[sortConfig.key]) ? -1 : 0);
        })
      }
    }
  }
  const handleScroll = (e) => {
    props.setSaveScroll(false);
    setResetOffset(false);
    if (
      props.currentTab === CURRENT_TAB_TYPE.invoices ||
      props.currentTab === CURRENT_TAB_TYPE.bank_statements && props.dataType !== 'cashflow' ||
      props.currentTab === CURRENT_TAB_TYPE.GST
    ) {
      if (table_select.current) {
        const { scrollTop, scrollHeight, scrollWidth, scrollLeft, clientHeight, clientWidth } = table_select.current;
        if (scrollTop + clientHeight + 1 >= scrollHeight && scrollLeft + clientWidth + 1 >= scrollWidth) {
          if (underwritingDataRows.length <= rows_limit && hasMore) {
            dispatch(
              fetchUnderwritingData(
                props.investeeOrganization._id,
                props.currentTab,
                props.dataType,
                props.dates.start_date,
                props.dates.end_date,
                props.investeeOrganization.investee_onboarded_id,
                resetOffset,
                null,
                setHasMore
              )
            );
          }
        }
      }
    }
  };
  useEffect(() => {
    setHasMore(true);
    setResetOffset(true);
    props.setSaveScroll(true);
    set_limited_data_rows([]);
    dispatch(
        fetchUnderwritingData(
          props.investeeOrganization._id,
          props.currentTab,
          !props.editClassification ? props.dataType : 'raw',
          props.dates.start_date,
          props.dates.end_date,
          props.investeeOrganization.investee_onboarded_id,
          true,
          props.investeeOrganization.finbox_entity_id,
          setHasMore
        )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentTab, props.dataType]);

  useEffect(() => {
    props.updateData &&
      dispatch(
        !props.editClassification
          ? fetchUnderwritingData(
            props.investeeOrganization._id,
            props.currentTab,
            props.dataType,
            props.dates.start_date,
            props.dates.end_date,
            props.investeeOrganization.investee_onboarded_id
          )
          : fetchUnderwritingData(
            props.investeeOrganization._id,
            props.currentTab,
            "raw",
            props.dates.start_date,
            props.dates.end_date,
            props.investeeOrganization.investee_onboarded_id
          )
      );
    props.setUpdateData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.updateData]);

  useEffect(() => {
    setUnderwritingClassificationDataState(underwritingClassificationData);
  }, [props.editClassification, underwritingClassificationData]);

  useEffect(() => {
    setViewBalance(false);
    if (!props.isCancelling) {
      props.currentTab !== CURRENT_TAB_TYPE.invoices &&
        dispatch(
          fetchUnderwritingClassificationData(
            props.currentTab,
            false,
            true,
            null
          )
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editClassification, props.currentTab]);

  useEffect(() => {
    const getting_data = async () => {
      try {
        const resp = await callApi(
          `/bank/bank_stmt/view_balance?investeeOrgId=${props.investeeOrganization._id}`,
          "get",
          undefined,
          undefined,
          undefined,
          true
        );
        set_resp_view_balance(resp?.data?.data);
      } catch (e) {
        console.log(e, "error");
      }
    };
    getting_data();
  }, [viewBalance]);

  const getUnderwritingData = () => {
    dispatch(fetchUnderwritingData(
      props.investeeOrganization._id,
      props.currentTab,
      props.dataType,
      props.dates.start_date,
      props.dates.end_date,
      props.investeeOrganization.investee_onboarded_id,
      true,
      props.investeeOrganization.finbox_entity_id
    ))
  }


  if (underwritingDataHeaders?.length <= 0 || underwritingDataRows <= 0) {
    return (
      <div
        style={{
          border: "1px solid #E9E9E9",
          borderRadius: "6px",
          marginTop: "20px",
          padding: "30px 0px 29px",
          textAlign: "center",
          width: "20%",
        }}
      >
        Data Not Available
      </div>
    );
  }

  const handlethreshold = (value) => {
    if (value >= 0 && value <= 10) props.setEditedContractThreshold(value);
    else props.setEditedContractThreshold(props.getContractThresholdFromState)
  }

  const syncContracts = () => {
    dispatch(synchronizeContracts(props.investeeOrganization._id, getUnderwritingData))
  }

  const setOdValue = (value, accountNumber) => {
    setEditOd({
      accountNumber: accountNumber,
      value: value
    })
  }

  const updateODValue = async () => {
    try {
      const resp = await callApi(
        `/bank/updateOdLimit`,
        "POST",
        {
          orgId: props.investeeOrganization._id,
          accountNumber: editOd?.accountNumber,
          odLimit: parseInt(editOd?.value),
          source: "admin"
        },
        undefined,
        undefined,
        true
      );
      if (resp?.statusCode === 200) {
        showNotification("Success", `Updated OD Limit for ${editOd?.accountNumber}`)
      }
      getUnderwritingData();
      setEditOd(null);
    } catch (e) {
      showNotification("Error", "Error in setting OD Limit");
      setEditOd(null);
    }
  }

  const map_type =
    props.currentTab === CURRENT_TAB_TYPE.invoices ||
      (props.currentTab === CURRENT_TAB_TYPE.bank_statements && props.dataType !== 'cashflow') ||
      props.currentTab === CURRENT_TAB_TYPE.GST
      ? limited_data_rows
      : underwritingDataRows;


  return (
    <div style={{ clear: "both" }} >
      {isFetchingUnderwritingData ||
        isFetchingUnderwritingClassificationData ? (
        <PageLoader />
      ) : (
        <div
          className={props.currentTab === CURRENT_TAB_DATA_TYPE.contracts ? "underwriting_table__container" : ' '}
          onScroll={handleScroll}
          ref={table_select}
          style={{
            marginTop: "25px",
            overflow: "scroll",
            position: "relative",
            width: "100%",
          }}
        >
          <div
            className="m-0"
            style={{
              boxSizing: "content-box",
              width: "950px",
              paddingRight: "17px",
              borderRight: "0px",
              height: "600px",
            }}
          // style={{ marginBottom: "0px",
          // wordBreak:'break-all',width: "100%", overflow:'scroll'}}
          >
            {props.currentTab == CURRENT_TAB_TYPE.bank_statements &&
              props.dataType !== CURRENT_TAB_DATA_TYPE.pdf &&
              props.dataType !== CURRENT_TAB_DATA_TYPE.excel_report &&
              props.dataType !== CURRENT_TAB_DATA_TYPE.cashflow && (
                <div
                  className={
                    underwritingcurrbalance ||
                      underwritinglastdate ||
                      underwritingaccno
                      ? styles.curr_bal_info_box
                      : ""
                  }
                >
                  <div>
                    {underwritingaccno && (
                      <div className={styles.curr_bal_info_row}>
                        <span>Account No: </span>
                        <span>{underwritingaccno}</span>
                      </div>
                    )}

                    {(underwritingcurrbalanceStr || underwritingcurrbalance) ? (
                      <div className={styles.curr_bal_info_row}>
                        <span>Current Balance: </span>
                        <span>{underwritingcurrbalanceStr || underwritingcurrbalance}</span>
                      </div>
                    ) : (
                      <div className={styles.curr_bal_info_row}>
                        <span>Current Balance:</span>
                        <span>0</span>
                      </div>
                    )}

                    <div className={styles.curr_bal_info_row}>
                      <span>Fixed Deposits: </span>
                      <span>{fdBalance || "-"}</span>
                    </div>

                    {true && ( //add condition for MF investment render here
                      <div className={styles.curr_bal_info_row}>
                        <span>Mutual Fund Investments: </span>
                        <span>{mutualFundAmount ?? "-"}</span>
                      </div>
                    )}

                    {true && ( //add condition for Hard Cash render here
                      <div className={styles.curr_bal_info_row}>
                        <span>Hard Cash: </span>
                        <span>-</span>
                      </div>
                    )}

                    {/* {underwritinglastdate && (
                      <div className={styles.curr_bal_info_row}>
                        <span>Last Date:{" "}</span>
                        <span>{props.jsDate(props.formatDate(underwritinglastdate))}</span>
                      </div>
                    )} */}

                    {!isNaN(underwritingODAccounts) && (
                      <div className={styles.curr_bal_info_row}>
                        <span>Number of OD Accounts:{" "}</span>
                        <span>{underwritingODAccounts}</span>
                      </div>
                    )}
                  </div>

                  <button
                    onClick={() => {
                      setViewBalance(!viewBalance);
                    }}
                    className={styles.view_balances}
                  >
                    <span>
                      <span>
                        <img
                          src="/assets/create.png"
                          alt=""
                          style={{ height: "12.5px", marginRight: "3.5px" }}
                        />
                      </span>
                      View Balance
                    </span>
                  </button>
                </div>
              )}
            {viewBalance && (
              <div className={styles.balance_box}>
                <div className={styles.view_balance_headers}>
                  {resp_view_balance?.headers.map((header) => (
                    <span className={styles.width33}>{header.name}</span>
                  ))}
                </div>
                <div className={styles.view_balance_table_row}>
                  {resp_view_balance?.rows.map((obj) => (
                    <div className={styles.row_view_balance}>
                      {resp_view_balance?.headers.map((header) => (
                        <span className={styles.width33}>
                          {header.key === "bank_account.accountNumber"
                            ? obj.bank_account?.accountNumber
                            : obj[header.key]}
                        </span>
                      ))}
                    </div>
                  ))}
                  <hr className={styles.total_line} />
                  <div className={styles.curr_balance}>
                    <span className={styles.width33}>Total</span>
                    <span className={styles.width33}>{underwritingcurrbalance}</span>
                    <span className={styles.width33}></span>
                  </div>
                </div>
              </div>
            )}
            {props.dataType === 'summary' && props.currentTab === CURRENT_TAB_TYPE.bank_statements &&
              <>
                <BankAlerts
                  orgId={props.investeeOrganization._id}
                />
                {underwritingDataHeaders && underwritingDataHeaders.map((table, index) => {
                  return (<div className='my-5'>
                    <div className="ffg">Statement Summary of {table.bankName?.toUpperCase() ?? ''} Bank A/c: {table.accountNumber ?? ""}</div>

                    <div className={styles.od_container}>
                      <div className={styles.od_top}>
                        OD Limit:

                        {(editOd && editOd?.accountNumber === table.accountNumber) ? (
                          <div className={styles.od_value}>
                            <input className={styles.edit_od_input} type="number" value={editOd?.value ?? ''} onChange={(e) => setOdValue(e.target.value, table.accountNumber)} />
                            <img
                              src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/blue-check.svg"
                              alt=""
                              onClick={updateODValue}
                              className={styles.checkImg}
                            />
                            <img
                              src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/cross.svg"
                              alt=""
                              onClick={() => setEditOd(null)}
                              className={styles.checkImg}
                            />
                          </div>
                        )
                          : <span className={styles.od_value}>
                            <span>{odLimitDetails?.[table?.accountNumber]?.value ?? 0}</span>
                            <img
                              src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/create-blue.svg"
                              alt=""
                              onClick={() => setOdValue(odLimitDetails?.[table?.accountNumber]?.value, table.accountNumber)}
                              className={styles.editImg}
                            />
                          </span>}
                      </div>

                      {!!odLimitDetails?.[table?.accountNumber]?.updatedBy && <div className={styles.od_bottom}>
                        By:&nbsp;
                        <div className={styles.updated_by}>
                          {odLimitDetails?.[table?.accountNumber]?.updatedBy}
                        </div>
                      </div>}
                    </div>

                    <table
                      className="table"
                      style={{
                        width: "90px",
                        border: "1px solid #E9E9E9",
                        borderTop: 'none',
                        borderLeft: "none",
                        borderTopLeftRadius: "6px",
                        marginBottom: "0",
                        borderCollapse: "separate",
                        borderRadius: "6px",
                        borderSpacing: "0px",
                      }}
                    >
                      <UnderwritingTableHeader
                        startDate={props.dates.start_date}
                        endDate={props.dates.end_date}
                        underwritingDataHeaders={table.headers}
                        fetchUnderwritingData={fetchUnderwritingData}
                        currentTab={props.currentTab}
                        dataType={props.dataType}
                        editClassification={props.editClassification}
                        sortConfig={sortConfig}
                        setSortConfig={setSortConfig}
                      />
                      <tbody
                        className="team-body-underwriting ff of-a"
                        style={{ overflow: "auto" }}
                      >
                        {table.rows?.map((item, index) => {
                          return (
                            <UnderwritingTableRow
                              bottomRef={bottomRef}
                              bankBottomRef={bankBottomRef}
                              underwritingDataHeaders={underwritingDataHeaders}
                              investeeOrganization={props.investeeOrganization}
                              dates={props.dates}
                              currentTab={props.currentTab}
                              editClassification={props.editClassification}
                              setEditClassification={props.setEditClassification}
                              editInvoiceFrequency={props.editInvoiceFrequency}
                              editBankClassification={props.editBankClassification}
                              editContracts={props.editContracts}
                              dataType={props.dataType}
                              key={index}
                              row={item}
                              setEditedItems={props.setEditedItems}
                              editedItems={props.editedItems}
                              index={index}
                              subclassificationIdRef={subclassificationIdRef}
                              rowIndexRef={rowIndexRef}
                              countRef={countRef}
                              underwritingClassificationDataState={
                                underwritingClassificationDataState
                              }
                              setUnderwritingClassificationDataState={
                                setUnderwritingClassificationDataState
                              }
                              isCancelling={props.isCancelling}
                              setIsCancelling={props.setIsCancelling}
                              isSaving={props.isSaving}
                              setIsSaving={props.setIsSaving}
                              invoicesNameListObj={invoicesNameListObj}
                              headerData={headerData}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  )
                })}
              </>
            }
            {(props.dataType !== 'summary' || props.currentTab === CURRENT_TAB_TYPE.debt_schedule) &&
              <table
                className="table"
                style={{
                  width: "90px",
                  border: "1px solid #E9E9E9",
                  borderLeft: "none",
                  borderTopLeftRadius: "6px",
                  marginBottom: "0",
                  borderCollapse: "separate",
                  borderRadius: "6px",
                  borderSpacing: "0px",
                }}
              >
                <UnderwritingTableHeader
                  startDate={props.dates.start_date}
                  endDate={props.dates.end_date}
                  underwritingDataHeaders={underwritingDataHeaders}
                  fetchUnderwritingData={fetchUnderwritingData}
                  currentTab={props.currentTab}
                  dataType={props.dataType}
                  editClassification={props.editClassification}
                  sortConfig={sortConfig}
                  setSortConfig={setSortConfig}
                />

                <tbody
                  className="team-body-underwriting ff of-a"
                  style={{ overflow: "auto" }}
                >
                  {map_type?.map((item, index) => {
                    return (
                      <UnderwritingTableRow
                        bottomRef={bottomRef}
                        bankBottomRef={bankBottomRef}
                        underwritingDataHeaders={underwritingDataHeaders}
                        investeeOrganization={props.investeeOrganization}
                        dates={props.dates}
                        currentTab={props.currentTab}
                        editClassification={props.editClassification}
                        setEditClassification={props.setEditClassification}
                        editInvoiceFrequency={props.editInvoiceFrequency}
                        editBankClassification={props.editBankClassification}
                        editContracts={props.editContracts}
                        dataType={props.dataType}
                        key={index}
                        row={item}
                        setEditedItems={props.setEditedItems}
                        editedItems={props.editedItems}
                        index={index}
                        subclassificationIdRef={subclassificationIdRef}
                        rowIndexRef={rowIndexRef}
                        countRef={countRef}
                        underwritingClassificationDataState={
                          underwritingClassificationDataState
                        }
                        setUnderwritingClassificationDataState={
                          setUnderwritingClassificationDataState
                        }
                        isCancelling={props.isCancelling}
                        setIsCancelling={props.setIsCancelling}
                        isSaving={props.isSaving}
                        setIsSaving={props.setIsSaving}
                        invoicesNameListObj={invoicesNameListObj}
                        editedContractThreshold={props.editedContractThreshold}
                        headerData={headerData}
                      />
                    );
                  })}
                </tbody>
              </table>
            }
          </div>
          {props.currentTab === CURRENT_TAB_DATA_TYPE.contracts &&
            <div className="contract_setting__container">
              <div>
                <div className="heading">Contracts setting</div>
                <hr />
                <div className="score_container">Approved contract score
                  <input type="string" value={props.editContracts ? props.editedContractThreshold : props.getContractThresholdFromState} onChange={(e) => handlethreshold(e.target.value)} disabled={!props.editContracts}>
                  </input>
                </div>
                <div className="sync_contracts" onClick={syncContracts}>
                  <img src={whitePlus} alt="plus" />
                  Sync Contracts
                </div>
              </div>
              <div className="footer">
                <div className="synced">Contracts synced : {syncedContracts ?? "-"}</div>
                {lastSyncedDate ? <div className="last_synced">last synced {lastSyncedDate}</div> : null}
              </div>
            </div>}
        </div>
      )}
    </div>
  );
}
