import { isEmpty } from "lodash";
import React from "react";
import { RECUR_TABS_COLORS } from "../../constants/enums";
import styles from "./styles/RecurTabs.module.scss";

export default function RecurTabs({ tabs = [], currentTab, setCurrentTab, color, style }) {
  const { TabContainer, TabLinks, TabLinksActive, BlueTabLinks, BlueTabLinksActive } = styles;
  const handleClick = (label) => {
    setCurrentTab(label);
  };

  const tabsToMap = tabs.map((item, index) => {
    let obj = {};
    obj.id = index + 1;
    obj.label = item;
    return obj;
  });

  const getClass = (label) => {
    if (color === RECUR_TABS_COLORS.BLUE) {
        if (currentTab === label) return BlueTabLinksActive;
        return BlueTabLinks;
    }
    if (currentTab === label) {
        return TabLinksActive;
    }
    return TabLinks;
  };

  if (!isEmpty(tabs))
    return (
      <div className={TabContainer}>
        <ul role="tablist">
          {tabsToMap?.map((item, index) => (
            <li key={index}>
              <a
                data-toggle="tab"
                href={"menu" + item.id}
                onClick={() => handleClick(item.label)}
                className={getClass(item.label)}
                style={style}
                >
                <span>{item.label}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
}
