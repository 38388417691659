import isNil from 'lodash/isNil';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PageLoader from '../../../../../../../../../../../../../components/loader/PageLoader';
import { API_STATUS, DOC_SERVICE_RESPONSE, FLOW_ID, GENERIC_ERROR, SHOW_NOTIFICATION, documentationStatus, queryTabs, queryTypes, LOAN_TYPE } from '../../../../../../../../../../../../../constants/enums';
import useDidMountEffect from '../../../../../../../../../../../../../customHooks/useDidMountEffect';
import callApi from '../../../../../../../../../../../../../util/apiCaller';
import { showNotification } from '../../../../../../../../../../../../../util/utility';
import { getEmail, getUserId, getUserName } from '../../../../../../../../../../../../App/AppReducer';
import ChatBox from '../../ChatBox/ChatBox';
import { FILE_SIZE, MESSAGE_TYPES } from './consts/consts';
import ShowFiles from './ShowFiles/ShowFiles';
import styles from './UWQueries.module.scss';
import { updateDocumentationStatus } from '../../../../../../../../../../../../../common/ApiContainer.ts';
import { fetchQueryMessages } from '../../../../../../../../../../../../../common/ApiContainer';
import { BROADCAST_MAIL, KYC_PENDING_STATUS, LAST_ACTIVITY_STATUS, MARK_RESOLVED_SUCCESS, QUERY_RAISED_CONSTANTS, UW_PENDING_STATUS } from '../../../../../../../../../../../../../common/ENUM';
import _, { isEmpty } from 'lodash';
import { DEAL_CURRENT_STAGE_FOR_QUERIES_MODAL } from '../../../../../../../../../../../../../constants/commonConstants.js';
export default function UWQueries({ deal, currentTab, fetchDeals,getAllDealsData}) {
  const {
    UWQueriesContainer,
    InputContainer,
    Placeholder,
    FileUploadAndShow,
    Attachment,
    SubmitButton,
    CheckBoxAndFileContainer,
    CheckBoxPadding,
    QueryResolveText,
    QueryResolveTextFaded,
    ErrorText
  } = styles;

  const userId = useSelector(getUserId);
  const userName = useSelector(getUserName);
  const emailAddress = useSelector(getEmail);
  const textareaRef = useRef();
  const inputFileRef = useRef();
  const querySubmissionBoxRef = useRef();
  const [conversationData, setConversationData] = useState();
  const [shouldSendMessage, setShouldSendMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [typingTimeOut, setTypingTimeOut] = useState();
  const [docs, setDocs] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);
  const [isQueryMarkedToResolve, setIsQueryMarkedToResolve] = useState(false);
  const [isQueryResolved, setIsQueryResolved] = useState(false)
  const [isTabAndStatusMatching, setIsTabAndStatusMatching] = useState(false)
  const [activeTab, setActiveTab] = useState("")
  const [currentQueryStage, setCurrentQueryStage]= useState("")


  /**
   * As we need to enable KYC Queries only when Kyc deal is active and
   * UW Queries when Deal is in UW Stage, based on different status 
   * we calculate the curent scenario, as to which deal is currently active.
   * If deal is New Deals - UW Query
   * If in Evaluted But Kyc docs not shared - UW Query
   * If Kyc docs shared till Approved for Disbursal - KYC Query
   * After Approved for Disbursal - UW Query
   * If no query raised from admin - Broadcast Mail.
   * If query raised from investor - Query resolved mail.
   */
  useEffect(() => {
    fetchConversation();
    setIsQueryMarkedToResolve(false);
    setIsQueryResolved(false);
    //compare current tab with current Deal
    const currentStage = fetchCurrentQueryStage();
    setIsTabAndStatusMatching(currentTab === currentStage);
    let activeTab;

    switch (currentStage) {
      case queryTabs.KYC_QUERIES:
        activeTab = "KYC Queries";
        break;
      case queryTabs.UW_QUERIES:
        activeTab = "UW Queries";
        break;
      default:
        activeTab = "";
    }
    setActiveTab(activeTab);
    setCurrentQueryStage(currentStage);
  }, [currentTab]);

  useDidMountEffect(() => {
    if (shouldSendMessage && !isNil(message)) {
      if (typingTimeOut) {
        clearTimeout(typingTimeOut);
        setTypingTimeOut(null);
      }
      setTypingTimeOut(setTimeout(() => saveMessageOrDraft({
        message,
        messageType: MESSAGE_TYPES.DRAFT,
        conversationId: conversationData?.id,
        offerId: deal?.offerId,
        filesToUpdate: files,
      }), 500));
      setShouldSendMessage(false);
    }
  }, [message, shouldSendMessage]);

  useDidMountEffect(() => {
    if (docs?.length) {
      // calling API to save draft file metaData for retrieving the same in fetch API
      saveMessageOrDraft({
        message,
        messageType: MESSAGE_TYPES.DRAFT,
        conversationId: conversationData?.id,
        offerId: deal.offerId,
        filesToUpdate: [...docs, ...files],
        onSuccess: fetchConversation,
      });
    }
  }, [docs]);

  const fetchCurrentQueryStage = () => {
    if(deal?.lastActivityStatus){
    switch(deal.lastActivityStatus){
      case DEAL_CURRENT_STAGE_FOR_QUERIES_MODAL.DEAL_SENT:
        case DEAL_CURRENT_STAGE_FOR_QUERIES_MODAL.DEAL_VIEW:
        case DEAL_CURRENT_STAGE_FOR_QUERIES_MODAL.UW_QUERY_RECEIVED:
          case DEAL_CURRENT_STAGE_FOR_QUERIES_MODAL.ACCEPTED:
            case DEAL_CURRENT_STAGE_FOR_QUERIES_MODAL.MFA_SENT:
              case DEAL_CURRENT_STAGE_FOR_QUERIES_MODAL.MFA_SIGNED:
                case DEAL_CURRENT_STAGE_FOR_QUERIES_MODAL.KYC_DOCS_READY:
                  case DEAL_CURRENT_STAGE_FOR_QUERIES_MODAL.SANCTION_APPROVED:
        return queryTabs.UW_QUERIES 

      case DEAL_CURRENT_STAGE_FOR_QUERIES_MODAL.KYC_DOCS_SHARED:
        case DEAL_CURRENT_STAGE_FOR_QUERIES_MODAL.KYC_QUERY_RECEIVED:
          return queryTabs.KYC_QUERIES
    }
  }else{
    let dealStatus = deal.documentationStatus
     if(KYC_PENDING_STATUS.includes(dealStatus)){
      return queryTabs.KYC_QUERIES 
    }else if(UW_PENDING_STATUS.includes(deal.offerStatus)){
      return queryTabs.UW_QUERIES 
    }else if(deal.offerStatus !== "Not Sent" && (dealStatus === null || dealStatus === "Upcoming")){
      return queryTabs.UW_QUERIES 
    }
    }
  }
  

  const isMarkedResolveEnabled = () =>{
    if(deal?.lastActivityStatus === DEAL_CURRENT_STAGE_FOR_QUERIES_MODAL.UW_QUERY_RECEIVED || deal?.lastActivityStatus ===  DEAL_CURRENT_STAGE_FOR_QUERIES_MODAL.KYC_QUERY_RECEIVED){
      return true
    } else if( deal.documentationStatus === documentationStatus.QUERY_RAISED || deal.offerStatus === documentationStatus.QUERY_RAISED){
      return true
    } else{
      return false
    }  
  }

  const fetchConversation = async () => {
    const { offerId } = deal ?? {
      offerId: ''
    };
    if (!offerId) {
      return;
    }
    let queryType = null;
    if (currentTab === queryTabs.UW_QUERIES) {
      queryType = queryTypes.UW_QUERIES
    } else if (currentTab === queryTabs.KYC_QUERIES) {
      queryType = queryTypes.KYC_QUERIES
    }
    const res = await fetchQueryMessages(offerId, queryType);
    if (res?.data?.responseData?.responseCode === 20) {
      const data = res.data.conversationData;
      setConversationData(data);
      const { draftMessages } = data ?? {
        draftMessages: []
      };
      if (draftMessages.length) {
        const { message: draftMessage, additionalDocuments } = draftMessages[0] ?? {
          message: '',
          additionalDocuments: []
        };
        setMessage(draftMessage);
        setFiles(additionalDocuments);
      } else if (draftMessages) {
        if (message) setMessage('');
        if (files?.length) setFiles([]);
      }
    }
    else {
      const message = res?.data?.responseData?.responseMessage ?? GENERIC_ERROR;
      showNotification(SHOW_NOTIFICATION.ERROR, message);
    }
  };

  const saveMessageOrDraft = async ({
    message,
    messageType,
    conversationId,
    offerId,
    filesToUpdate,
    onSuccess
  }) => {
    const data = {
      identifierId: offerId,
      type: "Query",
      status: "resolved",
      senderId: userId,
      senderName: userName,
      messageType,
      senderType: "Admin",
      message,
      additionalDocuments: filesToUpdate ?? [],
      messageQueryType: (currentTab === queryTabs.UW_QUERIES) ? queryTypes.UW_QUERIES : queryTypes.KYC_QUERIES
    };
    setDocs([]);
    if (conversationId) data.id = conversationId;
    const res = await callApi("conversation/send-message", "post", data, {}, false, false, false, false, false, false, false, true);
    if (res?.responseData?.responseCode === 20) {
      if (onSuccess) onSuccess();
    }
    else {
      const message = res?.responseData?.responseMessage ?? GENERIC_ERROR;
      showNotification(SHOW_NOTIFICATION.ERROR, message);
    }
  };

  const handlePlaceholderClick = () => textareaRef.current?.focus();

  const getFileExtn = (file) => {
    if (file) {
      const splitter = file.name.split('.');
      return splitter[splitter.length - 1];
    }
    return '';
  }

  const handleFilesValidation = (files) => {
    const IMG_EXTNS = ["gif", "jpg", "jpeg", "png", "svg"];
    const EXCEL_EXTNS = ['csv', 'xls', 'xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xlt', 'xlam', 'xla', 'xlw', 'xlr'];
    const acceptedExtensions = [...IMG_EXTNS, "pdf", ...EXCEL_EXTNS, "zip"];
    let message = '';
    let extensionFailure = false;
    let sizeFailure = false;
    if (files?.length) {
      for (let file of files) {
        if (
          acceptedExtensions?.length &&
          !acceptedExtensions.includes(getFileExtn(file).toLowerCase())
        ) {
          extensionFailure = true;
          message = 'File format not supported (only accepts Image, PDF or Excel formats)';
        }
        if (file.size > FILE_SIZE) {
          sizeFailure = true;
          message = 'You cannot upload files greater than 20MB';
        }
      }
    }
    return { message, sizeFailure, extensionFailure, acceptedExtensions };
  };

  const onFileChangeCapture = (e) => {
    const filesArr = [...e.target.files];
    const { message, sizeFailure, extensionFailure, acceptedExtensions } =
      handleFilesValidation(filesArr);
    let filteredFiles = [...filesArr];
    if (extensionFailure) {
      showNotification(SHOW_NOTIFICATION.ERROR, message);
      filteredFiles = filesArr.filter((file) => {
        return acceptedExtensions?.includes(getFileExtn(file));
      });
    }
    if (sizeFailure) {
      showNotification(SHOW_NOTIFICATION.ERROR, message);
      filteredFiles = filesArr.filter((file) => file.size < FILE_SIZE);
    }
    if (filteredFiles?.length) handleSubmission(filteredFiles);
  };

  const handleSubmission = async (files) => {
    // API call to upload file
    setIsUploading(true);
    let index = 0;
    for (let file of files) {
      await uploadDocument({ file, isLastFile: index === files.length - 1 });
      index++;
    }
  };

  const getFileNameFromSignedUrl = (preSignedUrl) => decodeURIComponent(preSignedUrl)?.split('uw_queries/')[1]?.split('?')[0];

  const uploadDocument = async ({ file, isLastFile }) => {
    try {
      const data = { url: '', fileName: '' };
      const signResponse = await callApi("file/signedUrl", "post", {
        categoryId: Number(process.env.REACT_APP_UW_QUERIES_CATEGORY_ID),
        fileName: file.name,
        // investeeOrgName: deal.name,
      }, {}, false, false, false, false, false, true);
      if (signResponse?.responseData?.responseCode !== DOC_SERVICE_RESPONSE.SUCCESS) {
        const message = signResponse?.responseData?.responseMessage ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION.ERROR, message);
        return;
      }
      data.url = signResponse.signedUrl;
      const requestOptions = {
        method: 'put',
        body: file,
        headers: new Headers({
          'Content-Type': file.type,
        }),
      };
      if (data.url) {
        data.fileName = getFileNameFromSignedUrl(data.url);
        const uploadResponse = await fetch(data.url, requestOptions);
        if (uploadResponse.ok) {
          const input = {
            categoryId: Number(process.env.REACT_APP_UW_QUERIES_CATEGORY_ID),
            fileName: data.fileName,
            fileSizeKb: file.size / 1_000,
            fileType: file.type,
            uploadedBy: userName,
            emailAddress,
            flowId: FLOW_ID.UW_QUERIES,
          };
          const updateResponse = await callApi("file/saveMetadata", 'post', input, {}, false, false, false, false, false, true);
          if (updateResponse?.responseData?.responseCode === DOC_SERVICE_RESPONSE.SUCCESS) {
            const { fileMetadata } = updateResponse;
            const { id: docId } = fileMetadata ?? {
              id: null
            }
            if (docId) {
              // saving docId in state in order to send in saveDraft API
              const tempDocs = [...docs];
              tempDocs.push({ docLink: docId, docName: data.fileName });
              setDocs(tempDocs);
            } else throw new Error("Didn't get docId in response of save meta data of doc service API");
          } else {
            const message = updateResponse?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION.ERROR, message);
          }
        } else throw new Error(uploadResponse);
      } else throw new Error("Couldn't receive signed url!");
    } catch (error) {
      console.error('UploadDoc: ', error);
      showNotification(
        SHOW_NOTIFICATION.ERROR,
        'Failed to upload, something went wrong',
      );
    } finally {
      if (isLastFile) {
        if (inputFileRef.current) inputFileRef.current.value = '';
        setIsUploading(false);
      }
    }
  };

  const onAttchmentIconClick = () => {
    inputFileRef.current?.click();
  }

  const onDeleteSuccess = async (messageType, filesToUpdate) => {
    setIsDeleting(true);
    await saveMessageOrDraft({
      message,
      messageType,
      conversationId: conversationData?.id,
      offerId: deal?.offerId,
      filesToUpdate,
      onSuccess: fetchConversation,
    });
    setIsDeleting(false);
  };


  const markQueryAsResolved = async() => { 
    updateStatusAndSendMail()
    setIsQueryMarkedToResolve(false)
    setIsSavingData(true);
    setIsSavingData(false);
    showNotification(SHOW_NOTIFICATION.SUCCESS,MARK_RESOLVED_SUCCESS)
    setIsQueryResolved(true)
  }

  const fetchMailToTrigger = () =>{
    if(isQueryResolved || !isMarkedResolveEnabled()){
      return "BROAD_CAST";
    } else if(deal?.lastActivityStatus === DEAL_CURRENT_STAGE_FOR_QUERIES_MODAL.KYC_QUERY_RECEIVED || deal.documentationStatus === documentationStatus.QUERY_RAISED) {
      return "KYC";
    }else if (deal?.lastActivityStatus === DEAL_CURRENT_STAGE_FOR_QUERIES_MODAL.UW_QUERY_RECEIVED || deal.offerStatus === documentationStatus.QUERY_RAISED){
      return "UW";
    }
  }

  const updateStatusAndSendMail = async() => {

    const isMessageEmpty = _.isEmpty(message);
    const lastInvestorMessage = _.findLast(conversationData?.messages, {
      senderType: "Investor",
    });  
    /**
     * The deals whose status is query raised will be made to call the concered call,
     * if the status is not query raised, in both offer and Documentation
     * we will trigger a broadcast mail to investor
     */  

    switch (fetchMailToTrigger()) {
      case "KYC":
        await updateDocumentationStatus({
          senderId: lastInvestorMessage?.senderId,
          reviewedBy: userName,
          responderEmail: emailAddress,
          investorDealId: deal?.investorDealId ?? "",
          documentationStatus: documentationStatus.QUERY_RESOLVED,
          offerId: deal?.offerId,
          isQueryMarkedToResolve: isQueryMarkedToResolve,
          isMessageEmpty,
          queryType: "kyc_queries",
        });
        fetchConversation();
        break;
      case "UW":
        const res = await callApi("deals/investor/updateOfferStatus", "post", {
          offerId: deal?.offerId,
          offerStatus: documentationStatus.QUERY_RESOLVED,
          loanType: deal?.isDebtSyndication
            ? LOAN_TYPE.DEBT_SYNDICATION
            : "null",
          isQueryMarkedToResolve: isQueryMarkedToResolve,
          isMessageEmpty,
          queryType: queryTypes.UW_QUERIES,
        });
        if (res?.status === API_STATUS.SUCCESS) fetchConversation();
        else {
          const message = res?.data?.message ?? GENERIC_ERROR;
          showNotification(SHOW_NOTIFICATION.ERROR, message);
        }
        break;
      case "BROAD_CAST":
        const response = await callApi(
          "deals/investor/updateOfferStatus",
          "post",
          {
            offerId: deal?.offerId,
            offerStatus: BROADCAST_MAIL,
            loanType: deal?.isDebtSyndication
              ? LOAN_TYPE.DEBT_SYNDICATION
              : "null",
            isQueryMarkedToResolve: isQueryMarkedToResolve,
            isMessageEmpty,
          }
        );
        if (response?.status === API_STATUS.SUCCESS) fetchConversation();
        else {
          const message = response?.data?.message ?? GENERIC_ERROR;
          showNotification(SHOW_NOTIFICATION.ERROR, message);
        }
        break;
    }
      
  if(isQueryMarkedToResolve){
    setIsQueryResolved(true)  
  }
  setIsQueryMarkedToResolve(false)
  
  await fetchDeals()
  await getAllDealsData()
  
}

  const onSubmit = async () => {    
    if(!(message.trim() || files?.length)) return;

    const onSaveDraftSuccess = async () => {
      setIsSavingData(true);
      await updateStatusAndSendMail()
      setIsSavingData(false);
    };
    setIsSavingData(true);
    await saveMessageOrDraft({
      message,
      messageType: MESSAGE_TYPES.CHAT,
      conversationId: conversationData?.id,
      offerId: deal.offerId,
      filesToUpdate: files,
      onSuccess: onSaveDraftSuccess
    });
    setIsSavingData(false);
  }

  const getAcceptValue = () => {
    let acceptedStr = '';
    const { acceptedExtensions } = handleFilesValidation();
    acceptedExtensions &&
      acceptedExtensions.forEach((extn, index) => {
        if (index === acceptedExtensions.length - 1) {
          acceptedStr += '.' + extn;
        } else {
          acceptedStr += '.' + extn + ',';
        }
      });
    return acceptedStr;
  };

  const isSubmitButtonDisabled = (message, files) => {
        return (!(message.trim() || files?.length)); // active & completed deals means progression view
    }

  const shouldShowMarkResolved =() =>{  
    return ((deal?.lastActivityStatus === QUERY_RAISED_CONSTANTS.KYC_QUERY_RECEIVED) || (deal?.lastActivityStatus === QUERY_RAISED_CONSTANTS.UW_QUERY_RECEIVED)  ? true : false)
  }

  const shouldShowMarkResolvedButton = () => {
    return (!isQueryResolved && isQueryMarkedToResolve && shouldShowMarkResolved() && isSubmitButtonDisabled(message, files) )
  }

  const statusToTabMap = {
    [QUERY_RAISED_CONSTANTS.KYC_QUERY_RECEIVED]: queryTabs.KYC_QUERIES,
    [QUERY_RAISED_CONSTANTS.UW_QUERY_RECEIVED]: queryTabs.UW_QUERIES,
    
  };

  return (
    <div className={UWQueriesContainer}>
      {(isSavingData || isUploading || isDeleting) && <PageLoader />}
      <div
        style={{
          height: `calc(100vh - 400px)`,
          overflow: 'auto',
        }}
      >
        <ChatBox
          messages={conversationData?.messages}
          investorOrgName={deal?.investorName}
          onDeleteSuccess={onDeleteSuccess}
        />
      </div>
      <div ref={querySubmissionBoxRef} className={InputContainer}>
        <textarea
          ref={textareaRef}
          value={message}
          disabled={!isTabAndStatusMatching}
          onChange={e => {
            setMessage(e.target.value);
            setShouldSendMessage(true);
          }}
          onKeyDown={e => {
            if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) onSubmit();
          }}
        />
        {!message && (
          <span
            className={Placeholder}
            style={{ maxWidth: (textareaRef.current?.clientWidth - 30 || '') }}
            onClick={handlePlaceholderClick}
          >
            Enter queries response for this investor <span>(it’ll be autosave and available throughout this deal)</span>
          </span>
        )}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className={FileUploadAndShow} style={{display:'flex'}}>
            <input
              type="file"
              ref={inputFileRef}
              onChangeCapture={onFileChangeCapture}
              style={{ display: "none" }}
              accept={getAcceptValue()}
              disabled={!isTabAndStatusMatching}
              multiple
            />
            <span className={Attachment} onClick={() => onAttchmentIconClick()} disabled={!isTabAndStatusMatching}>
              <img src="/assets/file-attachment.svg" alt="" />
            </span>
            <ShowFiles
              messageType={MESSAGE_TYPES.DRAFT}
              files={files}
              onDeleteSuccess={onDeleteSuccess}
            />
          </div>
         {<div className={CheckBoxAndFileContainer}>
            <div className={CheckBoxPadding}>
            <input
              type="checkbox"
              onChange={(e) => setIsQueryMarkedToResolve(!isQueryMarkedToResolve)}
              disabled={isQueryResolved || !isTabAndStatusMatching || !isMarkedResolveEnabled() }
              checked={isQueryMarkedToResolve}
            />
            </div>
            <div className={  isTabAndStatusMatching ? QueryResolveText : QueryResolveTextFaded }>
            Mark as resolved
            </div>
          </div>}
        </div>
        {shouldShowMarkResolvedButton() ? 
        <button className={SubmitButton}  onClick={() => markQueryAsResolved()} disabled={isQueryResolved || !isTabAndStatusMatching || !isMarkedResolveEnabled()}>
        Mark Query as Resolved
        </button>
          :
          <button className={SubmitButton}  onClick={() => onSubmit()} disabled={isSubmitButtonDisabled(message, files) || !isTabAndStatusMatching}>
          Submit
          </button>
          }{!isTabAndStatusMatching && !isEmpty(activeTab) &&
            <p className={ErrorText}>{activeTab} is currently active</p>
          }
      </div>
    </div>
  );
}
