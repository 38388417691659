import React, { useState } from "react";
import ViewDocsModal from "./ViewDocsModal";
import styled from "styled-components";
import dayjs from "dayjs";
import isNil from "lodash/isNil";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { RECUR_INVESTOR_DEAL_NAME } from "../../../../DocumentVault/consts/consts";
import callApi from "../../../../../../../../../../../util/apiCaller";
import { API_STATUS, TOAST_TYPES } from "../../../../../../../../../../../constants/enums";
import { showNotification } from "../../../../../../../../../../../util/utility";

const Status = styled.span`
  width: 60px;
  float: left;
  text-align: center;
  border-radius: 4px;
  padding: 2px 0;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  font-size: 10px;
  font-weight: 600;
`;

export default function DealInvestor({
  downloadBothReports,
  isEditClicked,
  investor,
  fetchDeals,
  dealId,
  totId,
  dealsTotId,
  queryParams,
  investeeOrgId,
  resetQueryParams,
  dealName,
  totMaxTenure
}) {
  const {
    investor_name,
    offer_amount,
    is_deleted,
    mfa_status,
    sanction_status,
    daysDiff,
    expiration_date,
    offer_id,
    investor_deal_status,
    _id
  } = investor;
  const isDealSigned = mfa_status === "signed" && sanction_status === "signed";
  const isBidding =
    (isNil(mfa_status) && isNil(sanction_status)) ||
    (mfa_status === "open" && sanction_status === "open");
    
  const history = useHistory();
  const [investorDealStatus,setInvestorDealStatus] = useState(investor_deal_status);
  const[showViewDocsDrawer,setShowViewDocsDrawer]=useState(null);
  useEffect(() => {
    if (investor_deal_status) {
      setInvestorDealStatus(investor_deal_status);
    }
  }, [investor_deal_status])
  const isCompletelyDrawn = ()=>{
    if(Math.ceil(investor?.drawn) === investor?.allocated_amount && investor?.drawn>0)
      return true;
    else    
      return false;
  }
  const renderStatus = () => {
    if (isBidding) {
      return (
        <Status bgColor="#F2E8FF" color="#6021B3">
          Bidding
        </Status>
      );
    }
    if (!isDealSigned) {
      /**
       * SL : Sanction Limit
       * Time Remaining for sign : hours difference between expiration_date and current hour
       * Signing → When SL is sent for Signing and Time remaining for Sign ≥ 48 hours
       * Sign (Expiring) → When SL is sent for Signing and Time remaining for Sign < 48 hours
       * Sign (Expired) → When SL is sent for Signing and Time has expired
       * */
      let hoursDiff = dayjs(expiration_date).diff(dayjs(), "h");
      if (hoursDiff >= 48)
        return (
          <Status bgColor="#FFF7E9" color="#CA9D17">
            Signing
          </Status>
        );

      if (hoursDiff > 0)
        return (
          <Status bgColor="#FFF5EE" color="#FF6600">
            Sign (Expiring)
          </Status>
        );

      return (
        <Status bgColor="#F4F4F4" color="#989898">
          Sign (Expired)
        </Status>
      );
    } else {
      if(isCompletelyDrawn() && investor.isCompletedRepayments){
        return <Status bgColor="#E5FCDE" color="#49A71B">
                  Completed
                </Status>
      }
      if(daysDiff>=15 || isCompletelyDrawn()){
          return <Status bgColor="#E5FCDE" color="#49A71B">
            Active
          </Status>
      }
      if(daysDiff<0){
        return <Status bgColor="#F4F4F4" color="#989898">
          Expired
        </Status>
      }
      return <Status bgColor="#FFF5EE" color="#FF6600">
                Expiring
              </Status>
    }
  };
  
  useEffect(() => {
    if (queryParams?.isRedirect && offer_id === queryParams?.offerId) {
      document.querySelector(".btnTrigger")?.click();
    }
  }, []);
  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        let actions = document.querySelectorAll(".modal-backdrop")
          for(let action of actions){
            document.querySelector("body").removeChild(action);
          }
      }
    };
  }, [history])

  const handleToggle = (value) => {
    callApi("deals/investor_deals/investor_deal_update", "post", {
      investor_deal_id: _id,
      investor_deal_status : value
    }).then((res) => {
      if (res.status === API_STATUS.SUCCESS) {
        setInvestorDealStatus(value);
        showNotification(TOAST_TYPES.SUCCESS, "RI switched !");
      } else {
        showNotification(TOAST_TYPES.ERROR, "Some error occurred!");
      }
    });
  }
      
  const renderOnOffButton = () => {
    //create a switch button to toggle On/off state
    return (
      <div
          className='ri-on-off'
      >
          <span className={investorDealStatus === "Active" ? 'stat bgclr6' : 'stat'}
              onClick={() => handleToggle("Active")}
          >On</span>
          <span className={investorDealStatus === "Inactive" ? 'stat bgclr5' : 'stat'}
              onClick={() => handleToggle("Inactive")}
          >Off</span>
      </div> 
    );
  }

  return (
    <>
      {!is_deleted && (
        <div className="row bgclr2 p-2 mt-3 br4 mx-0">
          <div className="col-md-5">
            <div className="dflexJA">
              <div className="fs10 w-50">
                <i>Investor Name</i>
              </div>
              <div className="d-flex fs12 clr-purple fw300 w-50">
                <div
                  className="text-truncate expand mr-1"
                  style={{ wordBreak: "break-all" }}
                >
                  {investor_name}{" "}
                </div>
                {`(${offer_amount})\u0a00Lᴀᴄs`}
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="dflexJA">
              <div className="fs10">
                <i>Drawn / Approved (lacs)</i>
              </div>
              <span className="fs12 clr-purple fw300" style={{ width: "40%" }}>
                {investor?.drawn ? Number(investor?.drawn)?.toFixed(3) : 0}/
                {investor?.approved ?? 0}
              </span>
            </div>
          </div>
          {!isEditClicked && (
            <div className="col-md-2">
              <div className="dflex-je">
                {investor_name?.includes(RECUR_INVESTOR_DEAL_NAME) ? renderOnOffButton() : renderStatus()}
                {investor?.offer_status !== "Open" && (
                  <button
                    className={
                      "fs10 fw600 color2 border-0" +
                      " " +
                      (offer_id === queryParams?.offerId && "btnTrigger")
                    }
                    style={{ backgroundColor: "transparent" }}
                    data-toggle="modal"
                    data-target={`#${investor?._id}`}
                    /**
                     * Altering The below deal object will effect in Queries Modal.
                     * You may add, but do not remove any of the existing keys,
                     * doing this may break the feature.
                     */
                    onClick={()=>{
                      const deal={
                        offerId: offer_id,
                        documentationStatus: investor?.documentation_status ?? null,
                        offerStatus : investor.offer_status,
                        investorDealId: investor._id,
                        investeeOrganizationId: investor.investee_organization_id,
                        investorName:investor_name,
                        totId:dealsTotId,
                        isDebtSyndication: investor.loanType==="Debt Syndication" ? true:false,
                      }
                      setShowViewDocsDrawer(deal)
                    }}
                  >
                    {/* {'View\u0a00Documents'} */}
                    <img
                      src="/assets/docs_icon.svg"
                      alt="done"
                      style={{ color: "#4C8DFF" }}
                      className="ml-3"
                    />
                  </button>
                )}
                {showViewDocsDrawer&&(
                <ViewDocsModal
                  showViewDocsDrawer={showViewDocsDrawer}
                  closeDealDrawer={() => {
                    setShowViewDocsDrawer(null)
                  }}
                  setShowViewDocsDrawer={setShowViewDocsDrawer}
                  investor={investor}
                  fetchDeals={fetchDeals}
                  dealId={dealId}
                  totId={totId}
                  dealsTotId={dealsTotId}
                  investeeOrgId={investeeOrgId}
                  resetQueryParams={resetQueryParams}
                  dealName={dealName}
                  totMaxTenure={totMaxTenure}
                />
                )}
                {investor?.investment_report ? <span
                  className="ml-3"
                  onClick={() => {
                    downloadBothReports(
                      investor?.investment_report,
                      investor?.offer_status === "Open",
                      investor?._id
                    );
                  }}
                  style={{ cursor: "pointer", marginTop: "-5px" }}
                >
                  <img src="/assets/download-dv.svg" alt="" />
                </span> : 
                <span
                  className="ml-4"
                  style={{ cursor: "pointer", marginTop: "-5px" }}
                >
                </span>}
                <img
                  src="/assets/green-done.svg"
                  alt="done"
                  style={
                    !investor.mfa_status || investor?.mfa_status === "open"
                      ? { visibility: "hidden" }
                      : null
                  }
                  className="ml-3"
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
