import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import callApi from "../../../../../../../../../../util/apiCaller";
import { getCheckIfUserHasRelevantPermissions } from "../../../../../../../../../App/AppReducer";
import Deal from "./components/Deal";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import { showNotification } from "../../../../../../../../../../util/utility";
import { LIMIT_TYPES } from "../../../../../../../../../../common/ENUM";

const filterOutExpiredDeals = (deals) => {
  try {
    let filteredDeals = [];
    for (let deal of deals) {
      deal.investor_deal_offer = deal.investor_deal_offer?.filter(
        ({ daysDiff }) => daysDiff >= 0 || isNil(daysDiff) 
      );
      filteredDeals.push(deal);
    }
    return filteredDeals;
  } catch (e) {
    console.error(e);
  }
};

export default function DealsOverview({
  isMountedFromOverviewPage = false,
  investeeOrganizationName,
  investeeOrgId,
  displayName,
  tradingLimit,
  totId,
  new_unsanctioned_limit,
  fetchTotList,
  queryParams,
  resetQueryParams,
  tenure,
  totSize,
  totData,
  informChildComponent,
  isFromManageTot
}) {
  const [deals, setDeals] = useState([]);
  const [isCreateDealOrEditClicked, setIsCreateDealOrEditClicked] =
    useState(false);
  const isUserHasEditAccess = useSelector((state) =>
    getCheckIfUserHasRelevantPermissions(state, ["app:edit"])
  );
  const isUserHasUWViewAccess = useSelector((state) => getCheckIfUserHasRelevantPermissions(state, ["underwriting:edit"]));
  useEffect(() => {
    if (isMountedFromOverviewPage) fetchDeals();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    fetchDeals();
  }, [informChildComponent]);

  useEffect(() => {
    if (!isMountedFromOverviewPage) {
      if (!isNil(totId)) fetchDeals();
      setIsCreateDealOrEditClicked(false);
    }
    if (isNil(totId)) setDeals([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totId]);

  const fetchDeals = () => {
    fetchTotList && fetchTotList();
    // checking because we need totId to fetch deals in case of manage tot Page
    if(!totId && isFromManageTot) return;
    let obj = { investee_organization_id: investeeOrgId, tot_id: totId };

    const endpoint = "admin/deals/investee_organization/get_deals";
    callApi(endpoint, "post", obj).then((response) => {
      if (response.status === "Success") {
        if (isMountedFromOverviewPage) {
          setDeals(response.data);
          return;
        }
        setDeals(response.data);
      }
    });
  };

  const addDeal =  () => {
    if (!isEmpty(deals)) {
      showNotification(
        "error",
        "Only One Deal can be Created per Terms Of Trade"
      );
      return;
    }
    let obj = [...deals];
    obj.push({});
    setDeals(obj);
    setIsCreateDealOrEditClicked(true);
  };

  const handleCancelForCreatedDeal = (index) => {
    let obj = [...deals];
    obj.splice(index, 1);
    setDeals(obj);
    setIsCreateDealOrEditClicked(true);
  };

  return (
    <div className="row py-4 m-0">
      <div className="col-md-12 pl-0 pr-0">
        <div className="overview-border p-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="fs12 fs500">Deal Overview</div>
            {/* <KYCbtn onClick={() => handleKycDocsClick(true)}>KYC Documents</KYCbtn> */}
          </div>
          {deals &&
            deals.map((deal, index) => (
              <Deal
                totId={totId}
                isMountedFromOverviewPage={isMountedFromOverviewPage}
                initialDeal={deal}
                key={index}
                index={index}
                fetchDeals={fetchDeals}
                investeeOrgId={investeeOrgId}
                investeeOrganizationName={investeeOrganizationName}
                displayName={displayName}
                tradingLimit={tradingLimit}
                handleCancelForCreatedDeal={handleCancelForCreatedDeal}
                isCreateDealOrEditClicked={isCreateDealOrEditClicked}
                setIsCreateDealOrEditClicked={setIsCreateDealOrEditClicked}
                new_unsanctioned_limit={new_unsanctioned_limit}
                queryParams={queryParams}
                resetQueryParams={resetQueryParams}
                tenure={tenure}
                totSize={totSize}
              />
            ))}
          {!isCreateDealOrEditClicked &&
            (isUserHasEditAccess||isUserHasUWViewAccess) &&
            !isMountedFromOverviewPage && (
              <div
                className="bgclr7 fs12 fw500 br4 px-2 py-2 mt-4 text-center fw300"
                style={{ cursor: "pointer", width: "170px" }}
                onClick={() => addDeal()}>
                <img
                  src="../../../../../assets/CE.svg"
                  alt=""
                  style={{ width: "15px" }}
                  className="mr-2"
                />
                Create Deal
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
