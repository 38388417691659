import moment from "moment";
import React, { useCallback, useRef } from "react";
// import {Link} from 'react-router-dom';

function TransactionListRow(props) {
    const observer = useRef();
    const lastContractRef = useCallback(
        (node) => {
            console.log(props.loader, props.hasMore());
            if (props.loader) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && props.hasMore()) props.loadMore();
            });
            if (node) observer.current.observe(node);
        },
        [props.loader, props.hasMore()]
    );

    const renderer = (
        <>
            <td className='blue-table-cel text-center' style={{ width: '5%' }}>
                <input
                    type='checkbox'
                    value={props.index}
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                    checked={props.selected_repayment_index === props.index}
                    onChange={() => {
                        if (props.selected_repayment_index === props.index) props.onSelectTransaction(null)
                        else props.onSelectTransaction(props.index)
                    }}
                />
                {props.item.name}
            </td>
            <td style={{ width: '7%' }}>{props.item._id.investee_organization_name}</td>
            <td style={{ width: '10%' }}>{props.item._id.investor_organization_name}</td>
            <td style={{ width: '8%' }}>{props.item.contracts}</td>
            <td style={{ width: '11%' }}>
                <i class='fas fa-rupee-sign'></i>{props.item.total_repayment}
            </td>
            <td style={{ width: '9%' }}>
                {moment(props.item._id.scheduled_recur_payment_date).format("DD MMM YYYY")}
            </td>
            <td style={{ width: '2%', textAlign: 'center' }} onClick={() => props.handleRightArrowClick(props.item._id)}><img src='../../../../../assets/right-arrow-order.svg' alt=''></img></td>
        </>
    );

    if (props.dataLength === props.index + 1) {
        return <tr ref={lastContractRef}>{renderer}</tr>;
    } else {
        return <tr>{renderer}</tr>;
    }
}

export default TransactionListRow;
