import React, { useEffect, useState } from 'react';
import InputSlider from "../InputSlider";
import InputSelector from "../InputSelector";
import InputDatePicker from "../InputDatePicker";
import InputSearch from '../InputSearch';
import "../../css/Filters.css";
import _ from 'lodash';
import { getIdOnRightClick } from '../../modules/App/AppReducer';
import { showMenuOnRightClick } from '../../modules/App/AppActions';
import { useDispatch, useSelector } from 'react-redux';

export default function FilterHeaderComponent(props) {
    const selectorValue = props.value;
    let elementsArr;
    const statusElementsArray = [
        {
            label: "Approved",
            selected: true
        },
        {
            label: "Open",
            selected: true
        },
        {
            label: "Not Approved",
            selected: true
        }
    ];
    const clubElementsArray = [
        {
            label: "Company",
            selected: true
        },
        {
            label: "Investor",
            selected: true
        }
    ];
    const emailVerifyElementsArray = [
        {
            label: "Yes",
            selected: true
        },
        {
            label: "No",
            selected: true
        }
    ];
    // const termElementsArray = [
    //     {
    //         selected: true,
    //         label: 'Months'
    //     },
    //     {
    //         selected: true,
    //         label: 'Quarters'
    //     },
    //     {
    //         selected: true,
    //         label: 'Half-years'
    //     },
    //     {
    //         selected: true,
    //         label: 'Years'
    //     }
    // ];
    // const transactionStatusElementsArray = [
    //     {
    //         selected: true,
    //         label: 'Complete'
    //     },
    //     {
    //         selected: true,
    //         label: 'Delayed'
    //     },
    //     {
    //         selected: true,
    //         label: 'Default'
    //     },
    //     {
    //         selected: true,
    //         label: 'Scheduled'
    //     },
    //     {
    //         selected: true,
    //         label: 'Processing'
    //     }
    // ];
    // const contractTypeElementsArray = [
    //     {
    //         selected: true,
    //         label: 'Monthly'
    //     },
    //     {
    //         selected: true,
    //         label: 'Quarterly'
    //     },
    //     {
    //         selected: true,
    //         label: 'Half-yearly'
    //     },
    //     {
    //         selected: true,
    //         label: 'Yearly'
    //     }
    // ];
    // const investbookStatusElementsArray = [
    //     {
    //         label: "Processing",
    //         selected: true
    //     },
    //     {
    //         label: "Active",
    //         selected: true
    //     },
    //     {
    //         label: "Order Cancelled",
    //         selected: true
    //     },
    //     {
    //         label: "Completed",
    //         selected: true
    //     },
    //     {
    //         label: "Swapped",
    //         selected: true
    //     },
    //     {
    //         label: "Liquidated",
    //         selected: true
    //     }
    // ];

    switch (props.headerText) {
        case "Email Verify":
            elementsArr = emailVerifyElementsArray;
            break;
        case "Club":
            elementsArr = clubElementsArray;
            break;
        case "Status":
            elementsArr = statusElementsArray;
            // if (props.page === "Club Access") {
            //     elementsArr = statusElementsArray;
            // }
            // else if (props.page === "Trades") {
            //     elementsArr = statusElementsArray;
            // }
            break;
    //     case "Term":
    //         elementsArr = termElementsArray;
    //         selectorValue = props.termUnit;
    //         break;
    //     case "Status":
    //         if (props.page === "Tradebook") {
    //             elementsArr = statusElementsArray;
    //         }
    //         else if (props.page === "Investee-Transactions") {
    //             elementsArr = transactionStatusElementsArray;
    //         }
    //         else if (props.page === "Investbook") {
    //             elementsArr = investbookStatusElementsArray;
    //         }
    //         selectorValue = props.status;
    //         break;
    //     case "Repayments":
    //         elementsArr = contractTypeElementsArray;
    //         selectorValue = props.contractType;
    //         break;
        default:
            elementsArr = [];
            break;
    }

    const steps = {
        "Term" : 1,
        "Payments" : 1,
        "Payout" : 1,
        "Price" : 0.01,
        "Disbursed" : 0.1,
        "Payment No." : 1,
        "Gross Cashflow" : 1,
        "Fees" : 0.1,
        "# Contracts" : 1,
        "Investable ARR" : 100,
        "Repayments" : 100,
        "Total Repayments" : 1,
        "Investment" : 100,
        "IRR" : 1,
        "Received" : 1
    };

    const styles = props.style ? { ...props.style, position: 'unset' } : { position : 'unset' };

    const [inputMin, setInputMin] = useState(props.minVal);
    const [inputMax, setInputMax] = useState(props.maxVal);
    const [values, setValues] = useState([props.minVal, props.maxVal]);
    const [elementsArray, setElementsArray] = useState(elementsArr);
    const [selector, setSelector] = useState(selectorValue);
    const [date, setDate] = useState(props.date);
    // const [isFiltering, setIsFiltering] = useState(props.isFiltering);
    const dispatch = useDispatch();
    const id = useSelector((state) => getIdOnRightClick(state));

    useEffect(() => {
        document.addEventListener('click', function(e){
            const inside = (e.target.closest(".container"));
            if (!inside) {
                dispatch(showMenuOnRightClick(''));
            }
        });
        return (
            document.removeEventListener('click', function(e){
                const inside = (e.target.closest(".container"));
                if (!inside) {
                    dispatch(showMenuOnRightClick(''));
                }
            })
        )
    });

    const getFilterComponent = (filter_type) => {
        switch (filter_type) {
            case 'range_search':
                let termUnitSelector = undefined;
                if (props.headerText === "Term") {
                    termUnitSelector = <InputSelector
                        elementsArray = {elementsArray}
                        setElementsArray = {setElementsArray}
                        inputSelectorHandler = {props.inputSelectorHandler}
                        labelText = {props.labelText}
                        keyName = {props.keyName}
                        keyNameUnit = {props.keyNameUnit}
                        selector = {selector}
                        setSelector = {setSelector}
                    />
                }
                return <InputSlider
                    handleSliderFilter ={props.handleSliderFilter}
                    min = {props.minVal}
                    max = {props.maxVal}
                    inputMin = {inputMin}
                    inputMax = {inputMax}
                    values = {values}
                    setInputMin = {setInputMin}
                    setInputMax = {setInputMax}
                    setValues = {setValues}
                    keyName = {props.keyName}
                    labelText = {props.labelText}
                    step = {steps[props.headerText]}
                    termUnitSelector = {termUnitSelector}
                />

            case 'selector':
                return <InputSelector
                    elementsArray = {elementsArray}
                    setElementsArray = {setElementsArray}
                    inputSelectorHandler = {props.inputSelectorHandler}
                    labelText = {props.labelText}
                    keyName = {props.keyName}
                    selector = {selector}
                    setSelector = {setSelector}
                />

            case 'datepicker':
                return <InputDatePicker
                    handledatePicker = {props.handledatePicker}
                    date = {date}
                    setDate = {setDate}
                    labelText = {props.labelText}
                    keyName = {props.keyName}
                />

            default:
                return <InputSearch
                    handleSearchFilter = {props.handleSearchFilter}
                    keyName = {props.keyName}
                    value = {props.value}
                    labelText = {props.labelText}
                />
        }
    };

    const handleContextMenu = e => {
        e.preventDefault();
        if (e.target.innerText !== id) {
            dispatch(showMenuOnRightClick(e.target.innerText));
        }
    }

    if (!props.isSortAllowed && !props.isFilterAllowed) {
        return (
            <th scope="col">{props.headerText}</th>
        )
    } else if (props.isSortAllowed && !props.isFilterAllowed) {
        return (
            <th scope="col">
                {props.headerText}
                {props.sorting_order && props.sorting_order !== ''
                    ?
                    props.sorting_order === 'desc' ?
                        <img style={{width: '13px', marginLeft: '5px'}} src="/assets/down-arrow.svg"
                             alt=""></img>
                        :
                        <img style={{width: '13px', marginLeft: '5px'}} src="/assets/up-arrow.svg"
                             alt=""></img>
                    :
                    null
                }
            </th>
        )
    } else if (!props.isSortAllowed && props.isFilterAllowed) {
        return (
            <th scope="col" style={styles} colSpan={props.colSpan && props.colSpan} scope={props.scope && props.scope}>
                <span onContextMenu={e => handleContextMenu(e)}>{props.headerText}</span>
                {props.headerText === id && (
                    <>
                        <div className="container" style={{ position: 'fixed', zIndex: 10 }}>
                            {getFilterComponent(props.filter_type)}
                        </div>
                    </>
                )}
                {props.sorting_order && props.sorting_order !== ''
                    ?
                    props.sorting_order === 'desc' ?
                        <img style={{width: '13px', marginLeft: '5px'}} src="/assets/down-arrow.svg"
                            alt=""></img>
                        :
                        <img style={{width: '13px', marginLeft: '5px'}} src="/assets/up-arrow.svg"
                            alt=""></img>
                    :
                    null
                }
            </th>
        )
    } else {
        return (
            <th scope="col" style={styles} colSpan={props.colSpan && props.colSpan} scope={props.scope && props.scope}>
                <span className="applied-filter">
                    <span onContextMenu={e => handleContextMenu(e)}>{props.headerText}</span>
                    {/* {props.headerText === id && isFiltering && <span className="applied-filter-icon"><img src="/assets/applied-filter.svg" alt=""></img></span>} */}
                    {props.headerText === id && <div className="container" style={{ position: 'fixed', zIndex: 10 }}>
                        {getFilterComponent(props.filter_type)}
                    </div>}
                    {props.sorting_order && props.sorting_order !== ''
                        ?
                        props.sorting_order === 'desc' ?
                            <img style={{width: '13px', marginLeft: '5px'}} src="
                            // /assets/down-arrow.svg
                            "
                                alt=""></img>
                            :
                            <img style={{width: '13px', marginLeft: '5px'}} src="/assets/up-arrow.svg"
                                alt=""></img>
                        :
                        null
                    }
                </span>
            </th>
        )
    }
}
