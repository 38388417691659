import React from 'react';

export default function SaveSVG({ width, height, fill }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width ?? "12px"} height={height ?? "12px"} viewBox="0 0 18 18" version="1.1">
            <g id="Companies" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Companies---Manage-Profile_Integrations_Add-Finance-Manager" transform="translate(-788.000000, -385.000000)">
                    <g id="Group-7">
                        <g id="Group-8" transform="translate(479.000000, 231.000000)">
                            <g id="save_black_24dp" transform="translate(306.000000, 151.000000)">
                                <polygon id="Path" points="0 0 24 0 24 24 0 24" />
                                <path
                                    d="M17,3 L5,3 C3.89,3 3,3.9 3,5 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,7 L17,3 Z M19,19 L5,19 L5,5 L16.17,5 L19,7.83 L19,19 Z M12,12 C10.34,12 9,13.34 9,15 C9,16.66 10.34,18 12,18 C13.66,18 15,16.66 15,15 C15,13.34 13.66,12 12,12 Z M6,6 L15,6 L15,10 L6,10 L6,6 Z"
                                    id="Shape"
                                    fill={fill ?? "#ffffff"}
                                    fillRule="nonzero"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
