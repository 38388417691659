import isNil from "lodash/isNil";

export function getObjAndFileName(url) {
  if(Number.isInteger(Number(url))){
    const obj = { url};
    return {obj,fileName:"file"};
  }
  else if (!isNil(url)) {
    const commonStringInUrl = "amazonaws.com/";
    const indexOfCommonString = url?.indexOf?.(commonStringInUrl);
    const urlToSend = url.slice?.(indexOfCommonString + commonStringInUrl.length);
    const obj = { url: urlToSend };
    const fileName = urlToSend?.substr?.(urlToSend.indexOf("/") + 1);
    return { obj, fileName };
  }
}
