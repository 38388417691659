import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Routes from "./Routes";
import { connect } from "react-redux";
import {getApprovedState, getAuthenticatedState, getUserProfileFetchingState} from "../modules/App/AppReducer";
import {fetchUserProfile} from "../modules/App/AppActions";
import storage from "../util/storageService";

class PageContent extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        let userId = storage.get("user_id") ? storage.get("user_id") : "";
        if (userId && !this.props.isAuthenticated && !this.props.is_fetching_user) {
            this.props.dispatch(fetchUserProfile(userId));
        }
    }

    render() {
        return (
            <div>
                <Routes
                    isUserAdmin={true}
                    isAuthenticated={this.props.isAuthenticated}
                    isUserApproved={this.props.isUserApproved}
                    history={this.props.history} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: getAuthenticatedState(state),
        isUserApproved: getApprovedState(state),
        is_fetching_user: getUserProfileFetchingState(state),
    }
}

export default withRouter(connect(mapStateToProps)(PageContent));
