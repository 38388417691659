import React, {
    // Component
} from 'react';
// import {Link} from 'react-router-dom';
import FilterHeaderComponent from '../../../../../components/FilterHeaderComponent/FilterHeaderComponent';

function OrderRepaymentsTableHeader(props) {
    return (
        <thead>
            <tr>
                {/* <td></td> */}
                <FilterHeaderComponent
                    isSortAllowed={true}
                    sorting_order={'desc'}
                    isFilterAllowed={true}
                    headerText={'Investor Name'}
                    labelText={'Search Investor Name'}
                    filter_type={'search'}
                    handleSearchFilter={props.handleSearchFilter}
                    keyName={props.searchObjInvestorOrg.keyName}
                    value={props.searchObjInvestorOrg.value}
                />
                <FilterHeaderComponent
                    isSortAllowed={true}
                    sorting_order={'desc'}
                    isFilterAllowed={true}
                    headerText={'Company Name'}
                    labelText={'Search Company Name'}
                    filter_type={'search'}
                    handleSearchFilter={props.handleSearchFilter}
                    keyName={props.searchObjCompanyName.keyName}
                    value={props.searchObjContractID.value}
                />
                <FilterHeaderComponent
                    isSortAllowed={true}
                    sorting_order={'desc'}
                    isFilterAllowed={true}
                    headerText={'Order ID'}
                    labelText={'Search Order ID'}
                    filter_type={'search'}
                    handleSearchFilter={props.handleSearchFilter}
                    keyName={props.searchObjContractID.keyName}
                    value={props.searchObjContractID.value}
                />
                <FilterHeaderComponent
                    isFilterAllowed={true}
                    headerText={'Repayment No.'}
                    labelText={'Filter Repayment No.'}
                    filter_type={'range_search'}
                    handleSliderFilter={props.handleSliderFilter}
                    minVal={props.sliderObjRepaymentNo.minVal}
                    maxVal={props.sliderObjRepaymentNo.maxVal}
                    currentMin={props.sliderObjRepaymentNo.currentMin}
                    currentMax={props.sliderObjRepaymentNo.currentMax}
                    minValueBetween={props.sliderObjRepaymentNo.minValueBetween}
                    keyName={props.sliderObjRepaymentNo.keyName}
                />
                <FilterHeaderComponent
                    isSortAllowed={false}
                    isFilterAllowed={false}
                    headerText={'Repayments'}
                />
                <FilterHeaderComponent
                    isFilterAllowed={true}
                    headerText={'Due Date'}
                    labelText={'Due Date'}
                    filter_type={'datepicker'}
                    isSortAllowed={true}
                    sorting_order={'desc'}
                    handledatePicker={props.handledatePicker}
                    date={props.scheduledDateObj.date}
                    keyName={props.scheduledDateObj.keyName}
                />
                <FilterHeaderComponent
                    isSortAllowed={true}
                    sorting_order={'asc'}
                    isFilterAllowed={true}
                    headerText={'Status'}
                    labelText={'Filter Status'}
                    filter_type={'selector'}
                    inputSelectorHandler={props.inputSelectorHandler}
                    elementsArray={props.selectorObjStatus.elementsArray}
                    keyName={props.selectorObjStatus.keyName}
                />
            </tr>
        </thead>
    )
}

export default OrderRepaymentsTableHeader;
