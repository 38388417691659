import React from "react";
import { OVERVIEW_STATUS } from "../../../../../../../common/ENUM";
import styles from "../ProgessBar/ProgressBar.module.scss";

const ProgressBar = ({ status, height }) => {
  let progress,
    bgcolor = "#00AA00";
  let color = "#00AA00";
  switch (status) {
    case OVERVIEW_STATUS.sent:
      progress = "25";
      break;
    case OVERVIEW_STATUS.passed:
      case OVERVIEW_STATUS.missed:
      progress = "5";
      break;
    case  OVERVIEW_STATUS.deal_view:
      progress = "35";
      break;
    case OVERVIEW_STATUS.accepted:
      progress = "50";
      break;
    case OVERVIEW_STATUS.kyc_ready:
      progress = "75";
      break;
    case OVERVIEW_STATUS.kyc_shared:
      progress = "75";
      break;
    case OVERVIEW_STATUS.expired:
    case OVERVIEW_STATUS.expire_soon:
    case OVERVIEW_STATUS.drawdown:
    case OVERVIEW_STATUS.sanction:
    case OVERVIEW_STATUS.completed:
      progress = "100";
      break;
    default:
      progress = "0";
  }
  if (status === OVERVIEW_STATUS.expired) {
    bgcolor = "#818181";
    color = "#CCCCCC";
  } else if (
    status === OVERVIEW_STATUS.not_sent ||
    status === OVERVIEW_STATUS.passed ||
    status === OVERVIEW_STATUS.missed
  ) {
    bgcolor = "#818181";
    color = "#CCCCCC";
  }
  const Parentdiv = {
    height: height,
    width: "100%",
    maxWidth: "232px",
    backgroundColor: "whitesmoke",
    borderRadius: 40,
  };

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: "right",
    position: "relative",
  };

  const progresstext = {
    padding: 10,
    color: "black",
    fontWeight: 900,
  };

  return (
    <div className="mr-4">
      <div style={Parentdiv}>
        <div style={Childdiv}>
          <div
            className={
              status === OVERVIEW_STATUS.passed ||
              status === OVERVIEW_STATUS.missed ||
              status === OVERVIEW_STATUS.not_sent
                ? styles.Right_unset
                : styles.Right_0
            }
            style={{ color: color }}
          >
            {status}
          </div>
          <span style={progresstext}></span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
