import React from 'react';
import {showNotification} from "../util/utility";
import { Modal } from "react-bootstrap";
import banks from '../../src/constants/banks.json';
import accHolders from "../constants/accountHolders.json";
class AddNewBankModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bank:this.props.bank ?? {}
        };
    }

    handleChange = (keyName, keyValue) => {
        let bank = this.state.bank;
        bank[keyName] = keyValue;
        this.setState({
            bank: bank
        })
    };

    validateInput = () => {
        let isValid = true;
        let message = '';
        const {bank} = this.state;
        if(!bank.account_holder_name){
            message = "Please enter Account Holder Name"
            isValid = false;
        }
        else if (!bank.nick_name) {
            message = "Please enter nick name"
            isValid = false;
        } else if (!bank.ifsc_code) {
            message = "Please enter ifsc code"
        } else if (!bank.account_number) {
            message = "Please enter account number"
            isValid = false;
        } else if (!bank.ifsc_code.match(/[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/)) {
            message = "IFSC code format isn't valid";
            isValid = false;
        } else if (!bank.account_number.trim()) {
            message = 'Please enter valid account number';
            isValid = false;
        } 
        else if (!bank.bank_name) {
            message = "Please enter bank name"
            isValid = false;
        }
        else if (!bank.account_holder_type) {
            message = "Please select one of the account type"
            isValid = false;
        }
        return {
            isValid,
            message
        }
    };

    addBank = () => {
        let check = this.validateInput();
        if (check.isValid) {
            this.props.addBank(this.state.bank);
        } else {
            showNotification("warning", check.message);
        }
    };

    render() {
        const {bank} = this.state;
        return (

            <Modal
                show={this.props.show}
                onHide={this.props.closeModal}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered>
                <Modal.Header closeButton>
                    <Modal.Title><h4 className="modal-title">Add New Bank</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <input
                            value={bank?.account_holder_name}
                            required
                            onChange={(e) => this.handleChange('account_holder_name', e.target.value)}
                            className="admin-form-field mt-3" style={{color: 'black'}} type="text"
                            placeholder="Enter your Account holder name">
                        </input>
                        <input
                            value={bank?.nick_name}
                            required
                            onChange={(e) => this.handleChange('nick_name', e.target.value)}
                            className="admin-form-field mt-3" style={{color: 'black'}} type="text"
                            placeholder="Enter your bank nickname">
                        </input>
                        <select
                            value={bank?.bank_name}
                            onChange={(e) =>
                            this.handleChange("bank_name", e.target.value)
                            }
                            className="admin-form-field mt-3" style={{color: 'black'}}
                            placeholder='Name of Bank'
                            name=''
                            id=''>
                            <option value='' disabled selected>Name of Bank</option>
                            {banks.map((item, index) => (
                            <option key={index} value={item}>{item}</option>
                             ))}
                        </select>
                        <input
                            value={bank?.account_number}
                            required
                            onChange={(e) => this.handleChange('account_number', e.target.value)}
                            className="admin-form-field mt-3" style={{color: 'black'}} type="text"
                            placeholder="Enter your bank account number">
                        </input>
                        <input
                            value={bank?.ifsc_code}
                            required
                            onChange={(e) => this.handleChange('ifsc_code', e.target.value)}
                            className="admin-form-field mt-3" style={{color: 'black'}} type="text"
                            placeholder="Enter your bank IFSC Code">
                        </input>
                        <select
                            value={bank?.account_holder_type ? bank?.account_holder_type : ''}
                            onChange={(e) => this.handleChange("account_holder_type", e.target.value)}
                            className="admin-form-field mt-3" style={{color: 'black'}}
                            placeholder='Account Holder'
                            name=''
                            id=''
                        >
                            <option value='' disabled>Account Holder</option>
                            {accHolders.map((item, index) => (
                            <option
                                key={index}
                                value={item}
                            >{item}</option>
                            ))}
                        </select>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        disabled={this.props.isCreatingAccount}
                        onClick={() => this.addBank()}
                        className="admin-company-btn admin-modal-btn mt-3">
                        {this.props.isCreatingAccount ? "Verifying" : "Verify and Save"}
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default AddNewBankModal;
