import { useState } from 'react';
import { removeFirstSlash } from "./utility";

export const API_URL = process.env.REACT_APP_API_URL;

//export const API_URL = 'http://localhost:8070';
// export const API_URL = "https://apistaging.recur.club"; // for testing QA
// export const API_URL = 'http://localhost:8070'; // for testing QA
export const PG_COLLECTIONS_BASE_URL = process.env.REACT_APP_PG_COLLECTION_API_URL
export const UW_API_URL =process.env.REACT_APP_UW_API_URL || "https://uwdev.recur.club";
// export const UW_API_URL ='http://localhost:8000';

export default function useCallApi() {
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    let userId = window.localStorage.getItem('recur_user_id');
    let orgId = window.localStorage.getItem('recur_org_id');
    let cerberusToken = window.localStorage.getItem('recur_cerberus_token') ?? '';

    return [
        { fetching, error, data },
        (endpoint,
            method = "get",
            body,
            argHeaders = {},
            isLambda = false,
            underwritingData = false,
            pgCollectionsApi = false,
        ) => {
            setFetching(true);
            setError(null);
            setData(null);

            
            let headers = { ...argHeaders };
            headers["content-type"] = "application/json";
            if (cerberusToken && cerberusToken !== '') {
                if (userId && userId !== '') {
                    headers['x-user-id'] = userId;
                }
                if (orgId && orgId !== '') {
                    headers['x-organization-id'] = orgId;
                }
                headers['x-cerberus-token'] = cerberusToken;
            }
            let overallEndpoint;
            if (isLambda)
                overallEndpoint = endpoint;
            else if (underwritingData)
                overallEndpoint = `${UW_API_URL}/underwriting/${removeFirstSlash(endpoint)}`;
            else if (pgCollectionsApi) {
                overallEndpoint = `${PG_COLLECTIONS_BASE_URL}/pg/${removeFirstSlash(endpoint)}`;
            }else{
                overallEndpoint = `${API_URL}/api/${removeFirstSlash(endpoint)}`;
            }

            return fetch(overallEndpoint, {
                headers: headers,
                method,
                body: JSON.stringify(body),
            })
                .then((response) => response.json().then((json) => ({ json, response })))
                .then(({ json, response }) => {
                    if (!response.ok) {
                        return Promise.reject(json);
                    }
                    return json;
                })
                .then(
                    (response) => {
                        setData(response)
                        setFetching(false);
                        return response
                    },
                    (error) => {
                        setError(error);
                        setFetching(false);
                        return error
                    }
                );
        }
    ]
}