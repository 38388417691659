import React, { useEffect, useState } from 'react';
import "../../../../../../../../../css/Drawdowns.css";
import DrawdownBox from './DrawdownBox';

export default function DrawdownLetterDone({selectedInvestorName,
    selectedCompanyName,selectedPayOut,clearStatus,getChildInvestorDeals}) {
    
    useEffect(() => {
        getChildInvestorDeals();
    },[])
    return (
        <>
            <div className='DrawdownletterDone'>
                <div style = {{cursor:"pointer"}} onClick = {() => clearStatus()} className='text-right'><img src="/assets/clear-grey.svg" alt="" /></div>
                <div className='text-center mt-3'>
                    <img style={{width:'77px'}} src="/assets/DDDone.png" alt="" />
                </div>
                <div className='color6-1 fw600 fs26 text-center my-4'>
                    Drawdown Letter <br></br>Generated
                </div>
                <div className='clr13 fw500 fs14 text-center mb-5'>
                    For {(selectedPayOut/1_00000).toFixed(2)} lacs between {selectedCompanyName} and {selectedInvestorName}
                </div>
                <hr></hr>
                {/* <div className='dflex-jca py-1'>
                    <div className='mr-2 fs14 fw600 clr11'>Download Letter</div>
                    <img 
                    src="/assets/download-blue.svg" alt="" />
                </div> */}
            </div>
        </>
        )
}