import React from "react";

export const companiesTab = [
  {
    label: "Companies",
    segmentName: "companies",
  },
  {
    label: "Approved Limits",
    segmentName: "approved_limits",
  },
  {
    label: "Deals",
    segmentName: "deals"
  },
  {
    label: "Compliance",
    segmentName: "compliance"
  }
];
const CompaniesStatusBar = ({currentTab, setCurrentTab}) => {
  return (
    <ul className="nav nav-tabs company-tabs" id="myTab" role="tablist">
      <li style={{width: "26px"}}></li>
      {companiesTab.map((item, index) => (
        <li
          key={index}
          className={
            currentTab === item.segmentName
              ? "nav-item bgclr3 color9"
              : "nav-item"
          }
          onClick={() => setCurrentTab(item.segmentName)}
        >
            <button
              className={
                currentTab === item.segmentName ? "color9" : ""
              }
            >
              {item.label}
            </button>
        </li>
      ))}
    </ul>
  );
};

export default CompaniesStatusBar;
