
import React, { useEffect, useRef, useState } from 'react';
import callApi from '../../../../../../../util/apiCaller';
import { getTeamMembers } from '../../../../../AdminReducers';

export default function Contracts({ id, custom_tenure, contracts }) {
    const [contract, setContract] = useState([...contracts])
    const [pageNum, setPageNum] = useState(1)
    const [typingTimeout, setTypingTimeout] = useState(0)
    const keyValue = useRef()
    useEffect(() => {
        if (contracts.length > 0) {
            setContract([...contracts])
        }
    }
        , [contracts])
    const getPayouts = (data) => {
        return data?.contract_xnpv_price_terms[data?.keys_to_pick[custom_tenure - 1]]?.xnpv;
    }
    const getTerms = (data) => {
        let terms = data?.contract_xnpv_price_terms[data?.keys_to_pick[custom_tenure - 1]]?.terms;
        switch (data.contract_term_unit) {
            case "annually":
                terms = terms > 1 ? `${terms} years` : `${terms} year`;
                break;
            case "monthly":
                terms = terms > 1 ? `${terms} months` : `${terms} month`;
                break;
            case "quarterly":
                terms = terms > 1 ? `${terms} quarters` : `${terms} quarter`;
                break;
            case "semi-annually":
                terms = terms > 1 ? `${terms} half-years` : `${terms} half-year`;
                break;
        }
        return terms;
    }

    const handleSearchFilter = (keyValue) => {
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        setTypingTimeout(setTimeout(() => {
            setPageNum(1)
            callApi(`contract/get-filtered-contract`, "post", {
                custom_tenure: custom_tenure,
                pageNum: pageNum,
                pageSize: 25,
                filters: {
                    isFiltering: "true",
                    isSearching: 'true',
                    investee_organization_id: id,
                    is_contract_listed: false,
                    is_contract_traded: false,
                    name: keyValue
                },
            }).then(res => {
                if (res.status === "Success") {
                    setContract(res.data.contracts)
                }
            });
        }, 1000))

    }


    return (
        <>
            <div className='d-flex flex-column'>
                <div class="filter-searchbar ml-auto mr-4" style={{ width: '20%' }}>
                    <span class="filter-search-icon">
                        <img alt="" src="../../../../../assets/search.png" style={{ height: '14px', width: '14px' }} />
                    </span>
                    <input
                        ref={keyValue}
                        onChange={(e) => handleSearchFilter(keyValue.current.value)}
                        type="text" className="filter-search-input" placeholder="Search...">
                    </input>
                </div>
                <div className="admin-data-table1 ">
                    <table className="table team">
                        <thead className="team-head">
                            <tr>
                                <th scope="col" className="pl-2">
                                    Customer
                                </th>
                                <th scope="col">Contract ID</th>
                                <th scope="col">Term</th>
                                <th scope="col">Payments</th>
                                <th scope="col">Payouts</th>
                            </tr>
                        </thead>
                        <tbody className="team-body">
                            {contract.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        {item.is_contract_approved ? (
                                            <>
                                                <td className="Contract-With-Company pl-2">
                                                    {item.name}
                                                </td>
                                                <td>{item.contract_id_num}</td>
                                                <td>{getTerms(item)}</td>
                                                <td>
                                                    &#8377;{" "}
                                                    {item.recurring_payment_value.toLocaleString(
                                                        "en-IN"
                                                    )}
                                                </td>
                                                <td>
                                                    <b>
                                                        &#8377;{" "}
                                                        {getPayouts(item)}
                                                    </b>
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td className="Contract-With-Company-Sync pl-2">
                                                    <img
                                                        src="/assets/sync-black-24-dp.svg"
                                                        alt=""
                                                    ></img>
                                                    {item.name}
                                                </td>
                                                <td className="Contract-Table-Row-Sync">
                                                    {item.contract_id_num}
                                                </td>
                                                <td className="Contract-Table-Row-Sync">
                                                    {item.contract_term_value} {item.contract_term_unit}
                                                </td>
                                                <td className="Contract-Table-Row-Sync">
                                                    &#8377;{" "}
                                                    {item.recurring_payment_value.toLocaleString(
                                                        "en-IN"
                                                    )}
                                                </td>
                                                <td className="Contract-Table-Row-Sync">
                                                    <b>
                                                        &#8377;{" "}
                                                        {(
                                                            item.recurring_payment_value *
                                                            item.contract_term_value *
                                                            item.contract_price
                                                        ).toLocaleString("en-IN")}
                                                    </b>
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
