import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import ToggleButton from '../../../../../../../../../components/ToggleButton/ToggleButton';
import { DOC_SERVICE_RESPONSE, GENERIC_ERROR } from '../../../../../../../../../constants/enums';
import callApi from '../../../../../../../../../util/apiCaller';
import { showNotification } from '../../../../../../../../../util/utility';
import { EXPIRED, NEED_UPDATE, NOT_UPLOADED, RULE_STATUS, UPLOADED, VERIFIED } from '../consts/consts';
import styles from '../DocumentVault.module.scss';
import DocumentVaultModal from './DocumentVaultModal';
import ShowButton from './ShowButton';
import { CKYC_DETAILS, CKYC_FILE_PATTERN } from '../../../../../../../../../constants/commonConstants';

export default function DocumentVaultTableSectionRow({ docData, docTypeName, moderatorName, section, fetchData }) {
    const {
        TableBody,
        Circle,
        NotDone,
        Done,
        Status,
        Verified,
        NeedUpdate,
        NotUploaded,
        Uploaded,
        MaxWidth80,
        FlexBasis20,
    } = styles;

    const { rules, updatedAt, status, sharedWithInvestor, expiryDate } = docData ?? {
        rules: [],
        updatedAt: '-',
        status: '',
        sharedWithInvestor: false,
        expiryDate: null
    };

    const [isSharedWithInvestor, setIsSharedWithInvestor] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (!isNil(sharedWithInvestor)) setIsSharedWithInvestor(sharedWithInvestor);
    }, [docData]);

    const callApiToToggleSharedWithInvestor = (shareWithInvestor) => {
        const docId = docData?.docId;
        if (!docId) showNotification("Error", GENERIC_ERROR);
        callApi("file/toggleShareWithInvestor", "post", {
            docId,
            sharedWithInvestor : shareWithInvestor
        }, {}, false, false, false, false, false, true).then(res => {
            if (res?.responseData?.responseCode === DOC_SERVICE_RESPONSE.SUCCESS) setIsSharedWithInvestor(shareWithInvestor);
            else {
                const message = res.data?.message ?? GENERIC_ERROR;
                showNotification("Error", message);
            }
        });
    }

    const getStatusClass = (status) => {
        if (status?.toLowerCase() === EXPIRED?.toLowerCase()) return NotUploaded;
        if (status?.toLowerCase() === VERIFIED?.toLowerCase()) return Verified;
        if (status?.toLowerCase() === NEED_UPDATE?.toLowerCase()) return NeedUpdate;
        if (status?.toLowerCase() === NOT_UPLOADED?.toLowerCase()) return NotUploaded;
        if (status?.toLowerCase() === UPLOADED?.toLowerCase()) return Uploaded;
        return '';
    }

    const toggleSharedWithInvestor = () => {
        if (docData?.docId) callApiToToggleSharedWithInvestor(!isSharedWithInvestor);
        else showNotification("Error", "No document is uploaded yet");
    }

    const toggleModal = (val) => {
        setShowModal(val);
    }

    const openModal = () => {
        toggleModal(true);
    }

    const onSuccess = () => {
        if (fetchData) fetchData();
    }

    const filterStatus = (status) => {
        if (status === RULE_STATUS.VERIFIED) status = "Verified";
        return status;
    }
    if(docTypeName ===CKYC_DETAILS) {
        return <></>
    } else if (CKYC_FILE_PATTERN.test(docTypeName)){
        docTypeName =CKYC_DETAILS;
    }

    return (
        <tr className={TableBody}>
            <td>
                <span onClick={openModal}>{docTypeName}</span>
            </td>
            <td>
                <div className='dflex-ac'>
                    {!isEmpty(rules) && rules.map((rule) => (
                        <div key={rule.ruleId} className={`dflex-ac ${FlexBasis20}`} title={rule.name}>
                            {rule.status === RULE_STATUS.VERIFIED ? (
                                <span className={`mr-2 ${Circle} ${Done}`}></span>
                            ) : (
                                <span className={`mr-2 ${Circle} ${NotDone}`}></span>
                            )}
                            <span className={`text-truncate ${MaxWidth80}`}>{rule.name}</span>
                        </div>
                    ))}
                </div>
            </td>
            <td>{updatedAt ? moment(updatedAt).format("DD MMM YYYY") : ''}</td>
            <td>
                <div className={`${getStatusClass(filterStatus(status))} ${Status}`}>{filterStatus(status)}</div>
            </td>
            <td className='DocumentVaultSwitch'>
                {/* <ToggleButton
                    checked={isSharedWithInvestor}
                    onChange={toggleSharedWithInvestor}
                /> */}
            </td>
            <td>
                {status?.toLowerCase() !== VERIFIED?.toLowerCase() && (
                    <ShowButton status={status} openModal={openModal} />
                )}
                {showModal && (
                    <DocumentVaultModal
                        showModal={showModal}
                        toggleModal={toggleModal}
                        docData={docData}
                        docTypeName={docTypeName}
                        isSharedWithInvestor={isSharedWithInvestor}
                        moderatorName={moderatorName}
                        section={section}
                        toggleSharedWithInvestor={toggleSharedWithInvestor}
                        onUploadSuccess={onSuccess}
                        onModerateSuccess={onSuccess}
                        onDeleteSuccess={onSuccess}
                        descriptionProp = {docData?.description}
                        setShowModal = {setShowModal}
                    />
                )}
            </td>
        </tr>
    )
}