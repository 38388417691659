import { useEffect } from "react";
import styles from "./AdditionalInfo.module.scss";
import addnBtn from "../../../../../../../../../../../../assets/addnBtn.svg";
import crossAddnDoc from "../../../../../../../../../../../../assets/crossAddnDoc.svg";
import { ONBOARDING_STATUS } from "../../../../../../../../../../../../common/ENUM";
import ToggleButton from "../../../../../../../../../../../../components/ToggleButton/ToggleButton";
import { OTHERS,additionalOption } from "../../../../../../../../../../../../constants/enums";


function AdditionalInfo({
  setIsAddnInfo,
  addnInfo,
  setAddnInfo,
  isModerating,
}) {
  const {
    additional_info_btn,
    addnInfoInput,
    addInfoSelect,
    remove_btn,
    query_container,
    commentInput,
    addnInfoContainer,
    addnBtnImg,
    toggleContainer,
    dropdownWrapper,
  } = styles;
  const NEEDINFO = "Need Information";
  const APPROVED = "Approved";
  useEffect(() => {
    if (addnInfo?.length > 0) {
      setIsAddnInfo(true);
    }
  }, [addnInfo]);
  const handleClick = () => {
    setAddnInfo([
      ...addnInfo,
      {
        query: "",
        otherValue: "",
        comment: "",
        status: NEEDINFO,
        requireText: true,
        requireDocument: true,
      },
    ]);
  };
  const removeInfo = (index) => {
    let newAddnInfo = [...addnInfo];
    newAddnInfo.splice(index, 1);
    if (newAddnInfo.length < 1) {
      newAddnInfo = [];
    }
    setAddnInfo(newAddnInfo);
  };
  const handleInputChange = (keyName, keyValue, index) => {
    let obj = [...addnInfo];
    if (keyName === "query") {
      obj[index].query = keyValue;
    }
    if (keyName === OTHERS) {
      obj[index].otherValue = keyValue;
    }
    if (keyName === "approved") {
      obj[index].status = keyValue ? APPROVED : "";
    }
    if (keyName === "needInfo") {
      obj[index].status = keyValue ? NEEDINFO : "";
    }
    if (keyName === ONBOARDING_STATUS.NEEDINFOOPTIONAL) {
      obj[index].status = keyValue ? ONBOARDING_STATUS.NEEDINFOOPTIONAL : "";
    }
    if (keyName === "comment") {
      obj[index].comment = keyValue;
    }
    if (keyName === "requireText") {
      obj[index].requireText = keyValue;
    }
    if (keyName === "requireDocument") {
      obj[index].requireDocument = keyValue;
    }
    setAddnInfo(obj);
  };

  return (
    <div className={addnInfoContainer}>
      {addnInfo?.map((item, index) => {
        const checkOtherValue=item.query?.length ?  additionalOption.find((e)=>e.value===item.query):true
        return (
          <>
            <div key={index}>
              <div className={query_container}>
                <div className={dropdownWrapper}>
                  <select
                    className={addInfoSelect}
                    placeholder="Enter description"
                    value={!checkOtherValue ?   OTHERS:item.query }
                    disabled={!isModerating}
                    onChange={(e) =>
                      handleInputChange("query", e.target.value, index)
                    }
                  >
                    <option value={""}>Select Description</option>
                    {additionalOption.map((item) => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                  {item.query === OTHERS || !checkOtherValue ? (
                    <input
                      disabled={!isModerating}
                      onChange={(e) =>
                        handleInputChange(item.query, e.target.value, index)
                      }
                      placeholder="Description"
                      className={addnInfoInput}
                      value={!checkOtherValue ? item.query:item.otherValue }
                    ></input>
                  ) : null}
                </div>
                <button
                  disabled={!isModerating}
                  className={remove_btn}
                  onClick={() => removeInfo(index)}
                >
                  <img src={crossAddnDoc} alt="cross" height="10px" />
                  Remove
                </button>
                <span className="fs10 dflex-ac">
                  <input
                    disabled={!isModerating}
                    type="checkbox"
                    className="mr-2"
                    checked={item.status === APPROVED}
                    onChange={(e) =>
                      handleInputChange("approved", e.target.checked, index)
                    }
                  />
                  <span>
                    <i>Approve</i>
                  </span>
                </span>
                <div className="DocumentVaultSwitch ml-">
                  <div className={toggleContainer}>
                    <ToggleButton
                      checked={
                        item.status === ONBOARDING_STATUS.NEEDINFOOPTIONAL
                      }
                      onChange={(e) =>
                        isModerating
                          ? handleInputChange(
                              ONBOARDING_STATUS.NEEDINFOOPTIONAL,
                              e.target.checked,
                              index
                            )
                          : false
                      }
                    />
                  </div>
                </div>
                <span className="fs10 dflex-ac">
                  <input
                    disabled={!isModerating}
                    type="checkbox"
                    className="mr-2"
                    checked={item.status === NEEDINFO}
                    onChange={(e) =>
                      handleInputChange("needInfo", e.target.checked, index)
                    }
                  />
                  <span>
                    <i>Need more Info</i>
                  </span>
                </span>
              </div>
              <input
                disabled={!isModerating}
                className={commentInput}
                value={item.comment}
                onChange={(e) =>
                  handleInputChange("comment", e.target.value, index)
                }
              ></input>
              <div className="fs10 my-1 dflex-ac">
                <input
                  disabled={!isModerating}
                  type="checkbox"
                  className="mr-2"
                  checked={item.requireText}
                  onChange={(e) =>
                    handleInputChange("requireText", e.target.checked, index)
                  }
                />
                <span>
                  <i>Require Text</i>
                </span>
                <input
                  disabled={!isModerating}
                  type="checkbox"
                  className="mx-2"
                  checked={item.requireDocument}
                  onChange={(e) =>
                    handleInputChange(
                      "requireDocument",
                      e.target.checked,
                      index
                    )
                  }
                />
                <span>
                  <i>Require Document</i>
                </span>
              </div>
            </div>
          </>
        );
      })}
      <button
        disabled={!isModerating}
        onClick={() => handleClick()}
        className={additional_info_btn}
      >
        <img src={addnBtn} className={addnBtnImg} alt="addnBtn" />
        Additional Info
      </button>
    </div>
  );
}

export default AdditionalInfo;
