import React, { Component } from "react";
import "../css/Filters.css";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

class InputDatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            changed: false
        }
    }

    onChange = async(date) => {
        !this.state.changed && this.setState({ changed: true });
        this.props.setDate(date);
        this.props.handledatePicker({ keyName: this.props.keyName, date: date});
    }

    handleDateInput = e => {
        console.log("EVENT ", e.target.id);
    }

    render() {   
        return (
            <section>
                <div className="filter-calender border-shadow">
                    <div className="filter-type">{this.props.labelText}</div>
                    <div className="d-flex justify-content-between" style={{ margin: '20px 0px' }}>
                        <input 
                            className="filter-value"
                            type="text"
                            id="from"
                            name="comp-name"
                            placeholder="From"
                            value={this.props.date[0] ? this.props.date[0].toLocaleDateString('hi-IN') : new Date()}
                            onChange={this.handleDateInput}
                        />
                        <input
                            className="filter-value"
                            type="text"
                            id="to"
                            name="comp-name"
                            placeholder="To"
                            value={this.props.date[1] ? this.props.date[1].toLocaleDateString('hi-IN') : new Date()}
                            onChange={this.handleDateInput}
                        />
                    </div>
                    <Calendar 
                        onChange={this.onChange}
                        value={this.props.date}
                        returnValue = {"range"}
                        selectRange = {true}
                        formatLongDate = {this.formatLongDate}
                    />
                </div>
            </section>
        );
    }
}

export default InputDatePicker;
