import { useEffect, useState } from "react";
import { getUwRiskFlag } from "../../../../../../../../../../common/ApiHelpers";
import { isEmpty } from "lodash";
import ReactTooltip from "react-tooltip";
import { UwRiskFlagTooltip } from "../../../DataVault/Overview/components/ModerateBox/components/UwRiskFlag/UwRiskFlagTooltip";
import "./UwRiskFlag.scss"

export interface uwFlagType {
    uwSignal?: string,
    promoterCibilScore?: string,
    companyCibilScore?: string,
    receivables?: string,
    relatedPartyTransactions?: string,
    customerConcentration?: string,
    socialPresence?: string,
    proprietorshipFirm?: string,
    icpSector?: string,
    cashTransactions?: string,
    legalNonCompliance?: string,
    arr?: number | undefined,
    debtPercent?: number | undefined,
    growthPercent?: number | undefined,
    runway?: number | undefined,
    orgId?: string,
    systemData?: uwFlagType
}

export const UwRiskFlag = (props: any) => {
    const {orgId, requestId} = props
    const [uwFlagData, setUwFlagData] = useState<uwFlagType>({})

    const getUwFlagData = async (orgId : string, requestId : string)=> {
        
        const uwFlag = await getUwRiskFlag(orgId, undefined, requestId)
            setUwFlagData(uwFlag)
    }
    useEffect(() => {
        if(isEmpty(uwFlagData)){
            getUwFlagData(orgId, requestId)
        }
    },[])
  
    return (
        <div className={"uwFlagComponent"}>
           <div className={"uwHeading"}>
                <p className={`uwRiskFlag ${uwFlagData?.uwSignal?.toUpperCase()}`}>{uwFlagData?.uwSignal?.toUpperCase()}</p>
                <img className={"uwLogo"} src = "https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/info-icon-gree.svg" alt=""  data-tip='' data-for={'systemUwFlag'}/>
            </div>

            
            <ReactTooltip
                            id={'systemUwFlag'}
                            type="light"
                            place="bottom"
                            className ={"overviewUwFlagToolTip"}
            >
                <UwRiskFlagTooltip
                uwRiskTags={uwFlagData?.systemData}
                />
            </ReactTooltip>
        </div>

    );
  };